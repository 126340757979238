import React from 'react';
import PropTypes from 'prop-types';
import When from '../../core/components/condtionals/When';
import ComponentBuilder from '../../core/ComponentBuilder';
import isAce from '../../hubs/persona/selectors/isAce';

const WhenAce = props => {

  const {
    isAce
  } = props;

  return (
    <When condition={isAce}>
      {props.children}
    </When>
  );

};

export default ComponentBuilder
  .wrap(WhenAce)
  .props({
    isAce: PropTypes.bool.isRequired
  })
  .stateToProps(state => ({
    isAce: isAce(state)
  }))
  .build();