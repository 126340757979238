import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import AppButton from "../../../../core/components/AppButton/AppMuiButton";
import {Save, Undo} from "@material-ui/icons";
import OverallCosts from "./includes/OverallCosts";
import ConfirmedLockDate from "./includes/ConfirmedLockDate";
import FinancialNotes from "./includes/FinancialNotes";
import ShipmentTransactionModal from "./modals/ShipmentTransactionModal";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import FinancialTransactionCard from "./includes/FinancialTransactionCard";
import InvoiceAgingCard from "./includes/InvoiceAgingCard";
import _ from "lodash";
import useFieldValidator from "../../../../crm/components/customHooks/form/validation/useFieldValidator";
import {InvoiceValidationSchema} from "./includes/validation/InvoiceValidationSchema";

const FinancialForm = (props) => {

    const globalState = useSelector(state => state);
    const associate = globalState.persona.modifyingAssociate;
    const isAce = associate.roles[0].name === "Ace";

    const [activeModal, setActiveModal] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [disableEdit, setDisableEdit] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const personalDispatch = useDispatch();
    const urlParams = useParams();
    const bolNumber = parseInt(urlParams?.id);

    const [data, setData] = useState({
        initialCarrierCost: null,
        initialCustomerCost: null,
        adjustedCarrierCost: null,
        adjustedCustomerCost: null,
        carrierIncreases: [],
        carrierReductions: [],
        customerIncreases: [],
        customerReductions: [],
        shipment: {},
        invoice: {},
    });
    const [initialData, setInitialData] = useState(null);

    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadShipmentFinancialPage'));
            const shipment = await window.shell.gateway.getShipment(bolNumber);
            const results = await window.shell.gateway.getShipmentFinancials(bolNumber);

            setData({
                shipment: shipment,
                ...results,
            });

            const resultsClone = _.cloneDeep(results);
            setInitialData({
                shipment: shipment,
                ...resultsClone
            });

            if (shipment?.voidId) {
                setDisableEdit(true);
            }

        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Shipment Financials',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadShipmentFinancialPage'));
        }
    }

    const fieldErrors = {
        ...useFieldValidator(InvoiceValidationSchema, data),
        ...useFieldValidator(InvoiceValidationSchema, data.invoice)
    };

    useEffect(() => {
        InvoiceValidationSchema.validate(data)
          .then(() => setIsValid(true))
          .catch((e) => setIsValid(false));
    }, [data]);

    useEffect(() => {
        InvoiceValidationSchema.validate(data.invoice)
          .then(() => setIsValid(true))
          .catch((e) => setIsValid(false));
    }, [data.invoice]);


    useEffect(() => {
        loadPageDependencies()
    }, []);


    const reload = async () => {
        await loadPageDependencies();
    };

    const saveShipmentFinancials = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveShipmentFinancials'));
            const financialsData = {
                initialCarrierCost: data.initialCarrierCost ?? 0,
                initialCustomerCost: data.initialCustomerCost ?? 0,
                carrierIncreases: data.carrierIncreases,
                carrierReductions: data.carrierReductions,
                customerIncreases: data.customerIncreases,
                customerReductions: data.customerReductions,
                invoiceData: data.invoice,
                userDateTime: new Date(),
                customerId: data?.shipment?.customerId
            };

            await window.shell.gateway.saveShipmentFinancials(data.shipment.bolNumber, financialsData);
            await reload();
            setIsDirty(false);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: 'Shipment Financials Saved'}));
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Shipment Financials Update Failed',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveShipmentFinancials'));
        }
    }

    const onAddEditEntryClick = (data, entryType, activeIndex = null) => {
        setActiveModal({
            data: data,
            entryType: entryType,
            activeIndex: activeIndex
        });
    }

    const onCloseDialog = () => {
        setActiveModal(null)
    }

    const handleResetClick = () => {
        const initialDataClone = _.cloneDeep(initialData);
        setData(initialDataClone);
        setIsDirty(false);
    }

    return (
      <>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                      <Grid item>
                          <AppButton
                            type={'submit'}
                            startIcon={<Save/>}
                            disabled={!isDirty || !isValid}
                            onClick={saveShipmentFinancials}
                          >Save
                          </AppButton>
                      </Grid>
                      <Grid item>
                          <AppButton
                            type={'button'}
                            startIcon={<Undo/>}
                            color={'warning'}
                            disabled={!isDirty}
                            onClick={handleResetClick}
                          >Reset
                          </AppButton>
                      </Grid>
                  </Grid>
              </Grid>
              {/* cards */}
              <Grid item xs={4}>
                  <OverallCosts
                    data={data}
                    setData={setData}
                    setIsDirty={setIsDirty}
                    disableEdit={(!isAce && ![1,6,17,19].includes(data.shipment?.statusId)) || disableEdit}
                    fieldErrors={fieldErrors}
                  />
              </Grid>
              <Grid item xs={4}>
                  <FinancialTransactionCard
                    entryType={{type: "carrier", subType: "increase"}}
                    onAddEditEntryClick={onAddEditEntryClick}
                    data={data.carrierIncreases}
                    shipment={data.shipment}
                    disableEdit={disableEdit}
                    maxCount={10}
                  />
              </Grid>
              <Grid item xs={4}>
                  <FinancialTransactionCard
                    entryType={{type: "customer", subType: "increase"}}
                    onAddEditEntryClick={onAddEditEntryClick}
                    data={data.customerIncreases}
                    shipment={data.shipment}
                    disableEdit={disableEdit}
                    maxCount={10}
                  />
              </Grid>
              <Grid item xs={4}>
                  <Grid container spacing={2}>
                      {isAce &&
                        <Grid item xs={12}>
                            <ConfirmedLockDate
                              data={data}
                              setData={setData}
                              setIsDirty={setIsDirty}
                              disableEdit={disableEdit}
                            />
                        </Grid>
                      }
                      <Grid item xs={12}>
                          <InvoiceAgingCard
                            bolNumber={bolNumber}
                            data={data}
                            setData={setData}
                            setIsDirty={setIsDirty}
                            disableEdit={disableEdit}/>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={4}>
                  <FinancialTransactionCard
                    entryType={{type: "carrier", subType: "reduction"}}
                    onAddEditEntryClick={onAddEditEntryClick}
                    data={data.carrierReductions}
                    shipment={data.shipment}
                    disableEdit={disableEdit}
                    maxCount={5}
                  />
              </Grid>
              <Grid item xs={4}>
                  <FinancialTransactionCard
                    entryType={{type: "customer", subType: "reduction"}}
                    onAddEditEntryClick={onAddEditEntryClick}
                    data={data.customerReductions}
                    shipment={data.shipment}
                    disableEdit={disableEdit}
                    maxCount={5}
                  />
              </Grid>
              <Grid item xs={12}>
                  <FinancialNotes
                    data={data}
                    setData={setData}
                    setIsDirty={setIsDirty}
                    disableEdit={disableEdit}
                    fieldErrors={fieldErrors}
                    isAce={isAce}
                  />
              </Grid>
          </Grid>
          {/* modals */}
          {activeModal &&
            <ShipmentTransactionModal
              shipment={data.shipment}
              data={activeModal.data}
              entryType={activeModal.entryType}
              activeIndex={activeModal.activeIndex}
              onCloseDialog={onCloseDialog}
              parentSave={saveShipmentFinancials}
              disableEdit={disableEdit}
              setIsDirty={setIsDirty}
            />
          }

      </>
    )
}

export default FinancialForm;
