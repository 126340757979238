import React from "react";
import * as Yup from "yup";
import { Grid } from "@material-ui/core";

import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ComponentBuilder from "../../../core/ComponentBuilder";
import FormFactor from "../../../core/FormFactor/FormFactor";
import renderAnnouncementForm from "./renderAnnouncementForm";
import AppTitleBar from "../../../core/components/AppTitleBar";
import LeftNav from "../LeftNav";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import BirthdaysAndAnniversariesForm from "./includes/BirthdaysAndAnniversariesForm";

const LoadProcessName = 'HomeSettingsPage.Load';
const SaveProcessName = 'HomeSettingsPage.Save';

const HomeSettingsPage = (props) => {

  const {
    announcement,
    load,
    save,
    sendSnackbarMessage
  } = props;

  usePageTitle("TGF: Home Page Settings");

  React.useEffect(() => {
    load();
  }, [load]);

  const initialValues = {
    announcementNote: announcement
  };


  const handleAnnouncementSubmit = (values, formFactor) => {
    save(values.announcementNote)
      .then(() => {
        sendSnackbarMessage({ content: 'Announcement saved.' });
      });
  };

  return (
    <FullWidthLayout SideNav={LeftNav}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppTitleBar title={`Home Page Settings`} />
        </Grid>
        <Grid item xs={6}>
          <FormFactor
            initialValues={initialValues}
            onSubmit={handleAnnouncementSubmit}
            schema={AnnouncementSchema}>
            {renderAnnouncementForm}
          </FormFactor>
        </Grid>
        <Grid item xs={6}>
          <BirthdaysAndAnniversariesForm />
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
};

const AnnouncementSchema = Yup.object().shape({
  announcementNote: Yup.string().required('Announcement note is required.')
});

export default ComponentBuilder
  .wrap(HomeSettingsPage)
  .stateToProps((state, ownProps) => {
    return {
      announcement: state.persona.announcementNote
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load() {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.persona.loadAnnouncementNote());
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async save(note) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.persona.saveAnnouncementNote(note));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();