import AuthorityStatuses from '../AuthorityStatuses';
import AuthorizedOperatingStatuses from '../AuthorizedOperatingStatuses';
import ValidSafetyRatings from '../ValidSafetyRatings';

const createActiveAuthorityStatusRule = (fieldKey) => (carrier) => {
  return carrier.rmisDot &&
    carrier.rmisDot[fieldKey] === AuthorityStatuses.Active;
};

const CarrierComplianceRules = {

  passesOperatingStatusAuthorizedRule(carrier) {
    return carrier.rmisDotTesting &&
      carrier.rmisDotTesting.operatingStatus &&
      AuthorizedOperatingStatuses
        .some(s => carrier.rmisDotTesting.operatingStatus.toLowerCase().includes(s));
  },
  passesDotSafetyRatingRule(carrier) {
    return carrier.rmisDotTesting &&
      carrier.rmisDotTesting.operatingStatus &&
      ValidSafetyRatings
        .some(s => carrier.rmisDotTesting.safetyRating === s);
  },
  passesAuthorityRule(carrier) {
    return carrier.rmisDot &&
      (carrier.rmisDot.commonAuthority === AuthorityStatuses.Active ||
      carrier.rmisDot.contractAuthority === AuthorityStatuses.Active);
  },
  passesActiveCommonAuthorityStatus: createActiveAuthorityStatusRule('commonAuthority'),
  passesActiveContractAuthorityStatus: createActiveAuthorityStatusRule('contractAuthority'),
  passesActiveBrokerAuthorityStatus: createActiveAuthorityStatusRule('brokerAuthority')
};

export default CarrierComplianceRules;