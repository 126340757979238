import * as React from "react";

const DateTimeFromUTCDateTimeCell = (cellProps) => {
  const displayOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  let date = null;

  if(cellProps.dataItem[cellProps.field] !== null)
  {
    // UTC date string  => JS date obj w local time zone.
    date = new Date(cellProps.dataItem[cellProps.field]);

    // format to //mm/dd/yyyy
    date = new Intl.DateTimeFormat('en-US', displayOptions).format(date);
  }


  return (
    <td>
      {date !== null ? date : ""}
    </td>
  )
};

export default DateTimeFromUTCDateTimeCell;