import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadShipmentEquipmentTypes = async (shell) => {

  const shipmentEquipmentTypes = await shell.gateway.getEquipmentTypes();

  return {
    type: ActionTypes.Support.ShipmentEquipmentTypesLoaded,
    shipmentEquipmentTypes
  };

};

export default ActionBuilder
  .for(loadShipmentEquipmentTypes)
  .build();




