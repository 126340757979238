class ValueAssignmentEvent {
  constructor(pathSpec, newValue, oldValue) {
    this.pathSpec = pathSpec;
    this.newValue = newValue;
    this.oldValue = oldValue;
  }
}

class ValueTouchedEvent {
  constructor(pathSpec) {
    this.pathSpec = pathSpec;
  }
}

class FormResetEvent {
}

class ArrayValueAddedEvent {
  constructor(pathSpec, index, value) {
    this.pathSpec = pathSpec;
    this.index = index;
    this.value = value;
  }
}

class ArrayValueRemovedEvent {
  constructor(pathSpec, index, oldValue) {
    this.pathSpec = pathSpec;
    this.index = index;
    this.oldValue = oldValue;
  }
}

class ArrayValueReplacedEvent {
  constructor(pathSpec, index, newValue, oldValue) {
    this.pathSpec = pathSpec;
    this.index = index;
    this.newValue = newValue;
    this.oldValue = oldValue;
  }
}

export default {
  ValueAssignmentEvent,
  ValueTouchedEvent,
  FormResetEvent,
  ArrayValueAddedEvent,
  ArrayValueRemovedEvent,
  ArrayValueReplacedEvent
};