import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyAutofillFields = async (shell, companyId) => {

    const autofillFields = await shell.gateway.getCompanyAutofillFields(companyId);

    return {
        type: ActionTypes.AddressBook.Modification.CompanyAutofillFieldsLoaded,
        autofillFields
    };

};

export default ActionBuilder
    .for(loadCompanyAutofillFields)
    .build();