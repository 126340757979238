import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder2';

const clearCustomerShipmentsSearch = (shell, searchId) => {
  return {
    type: ActionTypes.Shipment.Customer.ClearedCustomerShipmentsSearch,
    searchId
  };
};

export default ActionBuilder
  .for(clearCustomerShipmentsSearch)
  .build();