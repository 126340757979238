import ActionTypes from '../../ActionTypes';

const modifyingAssociate = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Persona.NotIdentified:
      return null;
    case ActionTypes.Persona.Identified:
      return action.modifyingAssociate;
    default:
      return state;
  }
};

export default modifyingAssociate;