import ActionTypes from "../../../ActionTypes";

const standardReports = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Reporting.StandardReports.Loaded:
      return action.standardReports;
    default:
      return state;
  }
};

export default standardReports;