import ActionTypes from "../../../ActionTypes";

const customerCreditStatus = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Duplication.Disposed:
      return null;
    case ActionTypes.Shipment.Duplication.ShipmentLoaded:
      return action.customerCreditStatus;
    default:
      return state;
  }
};

export default customerCreditStatus;