import Core from "@atomos/core";

const trimNullifyObject = (target) => {
  const trimmedPairs = Core.Utils
    .toPairs(target)
    .map(trimStringValue)
    .map(nullifyEmptyStringValue);

  return Core.Utils.fromPairs(trimmedPairs);
};

const trimStringValue = ([key, value]) => {
  return [
    key,
    Core.Utils.isString(value) ? value.trim() : value
  ];
};

const nullifyEmptyStringValue = ([key, value]) => {
  return [
    key,
    Core.Utils.isString(value) && value.length === 0 ?
      null : value
  ];
};

export default trimNullifyObject;