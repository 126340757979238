import React from "react";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import _ from "lodash";
import FieldError from "../../../../../crm/components/inputs/FieldError";


const OverallCosts = (props) => {

    const handleChange = async (e) => {
        props.setData({
            ...props.data,
            [e.target.name]: e.target.value
        });
        props.setIsDirty(true);
    };

    return (
        <AppMuiCard title={"Overall Costs"}>
            <Grid container alignItems={"center"}>
                <Grid item xs={6}>
                    <p>Carrier Cost</p>
                </Grid>
                <Grid item xs={6}>
                    <NumericTextBox
                        id={"initialCarrierCost"}
                        name={"initialCarrierCost"}
                        onChange={handleChange}
                        value={props.data.initialCarrierCost}
                        spinners={false}
                        disabled={props.disableEdit}
                        format={"c2"}
                    />
                    <FieldError>{props.fieldErrors.initialCarrierCost}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <p>Customer Cost</p>
                </Grid>
                <Grid item xs={6}>
                    <NumericTextBox
                        id={"initialCustomerCost"}
                        name={"initialCustomerCost"}
                        onChange={handleChange}
                        value={props.data.initialCustomerCost}
                        spinners={false}
                        disabled={props.disableEdit}
                        format={"c2"}
                    />
                    <FieldError>{props.fieldErrors.initialCustomerCost}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <p>Adj. Carrier Cost</p>
                </Grid>
                <Grid item xs={6}>
                    <NumericTextBox
                        id={"adjustedCarrierCost"}
                        name={"adjustedCarrierCost"}
                        onChange={handleChange}
                        value={props.data.initialCarrierCost +
                          _.sumBy(props.data.carrierIncreases, x => x.amount) +
                          _.sumBy(props.data.carrierReductions, x => x.amount)}
                        spinners={false}
                        disabled={true}
                        format={"c2"}
                    />
                </Grid>
                <Grid item xs={6}>
                    <p>Adj. Customer Cost</p>
                </Grid>
                <Grid item xs={6}>
                    <NumericTextBox
                        id={"adjustedCustomerCost"}
                        name={"adjustedCustomerCost"}
                        onChange={handleChange}
                        value={props.data.initialCustomerCost +
                          _.sumBy(props.data.customerIncreases, x => x.amount) +
                          _.sumBy(props.data.customerReductions, x => x.amount)}
                        spinners={false}
                        disabled={true}
                        format={"c2"}
                    />
                </Grid>
            </Grid>

        </AppMuiCard>
    )
}

export default OverallCosts;
