import ActionTypes from "../../../ActionTypes";

const associateGrossMarginBreakdown = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.Reporting.MonthlyGrossMargin.Disposed:
      return {};
    case ActionTypes.Reporting.MonthlyGrossMargin.GrossMarginLoaded:
      return action.associateGrossMarginBreakdown;
    default:
      return state;
  }
};

export default associateGrossMarginBreakdown;