import getDate from "./getDate";

const dynamicSort = async (sort) => {

    let sortOrder = 0;
    let property = '';
    if(sort) {
        if (sort.includes(' asc')) {
            sortOrder = 1;
            property = sort.replace(' asc', '');
        } else if (sort.includes(' desc')) {
            sortOrder = -1;
            property = sort.replace(' desc', '');
        }
    }

    return function (a, b) {
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        if (sortOrder === 0) {
            return true
        } else {
            return result * sortOrder;
        }
    }
}

export default dynamicSort;
