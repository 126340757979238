import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyInvoiceSettings = async (shell, companyId) => {

  const companyInvoiceSettings = await shell.gateway.getCompanyInvoiceSettings(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyInvoiceSettingsLoaded,
    companyInvoiceSettings
  };
};

export default ActionBuilder
  .for(loadCompanyInvoiceSettings)
  .build();