import TgfDialog from "../../../core/kendo/dialog/TgfDialog";
import {Button, Grid} from "@material-ui/core";
import React, { useState} from "react";
import {useDispatch} from "react-redux";
import TgfCustomerAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfCustomerAutoComplete";
import AppAlertStatic from "../../../core/components/AppAlertStatic";
import ConfirmationModal from "../../../core/kendo/dialog/ConfimationModal";
import {useHistory} from "react-router-dom";

const DuplicationCompleteModal = (props) => {
    const history = useHistory();
    const handleLinkClick = () => {
        props.onClose();
        history.push(`/address-book/${props.dupclicateShipperConsginee.id}/details`)
    }
    return (
        <TgfDialog
            title={props?.title}
            onClose={props.onClose}
            width={600}
            height={180}
        >
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <span>Your new Shipper/Consignee has been created. Click the link below to view the new Shipper/Consignee</span>
                </Grid>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <span className={"shipper-consignee-duplication-link"} onClick={handleLinkClick}>{`${props.dupclicateShipperConsginee.name}`}</span>
                </Grid>
            </Grid>
        </TgfDialog>
    )
}

const DuplicateShipperConsigneeModal = (props) => {
    const personalDispatch = useDispatch();
    const [isDirty, setIsDirty] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [duplicatedShipperConsignee, setDuplicatedShipperConsignee] = useState(false);

    const save = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAssignedCollector'));

            const result = await window.shell.gateway.saveDuplicateShipperConsignee({
                currentCustomerId: props.currentAffiliateCustomer?.affiliateCompanyId,
                selectedCustomerId: selectedCustomer?.companyId,
                shipperConsigneeId: props.shipperConsignee?.id
            });

            if(result) setDuplicatedShipperConsignee(result);

        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Duplicate Shipper/consignee',
                color: 'error'
            }));
        } finally {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Duplicate Shipper/Consignee Saved'}));
            personalDispatch(window.shell.actions.sys.processComplete('saveAssignedCollector'));
        }
    }

    const handleSelectedCustomerChange = (e) =>  {
        setSelectedCustomer(e.target?.value ?? null)
        setIsDirty(Boolean(e.target?.value))
    }


    return (
        <TgfDialog title={"Duplicate Shipper/Consignee"}
                   onClose={props.onClose}
                   width={600}>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item xs={12}>
                    <AppAlertStatic>
                        <strong>When a Shipper/Consignee is duplicated, the information from the Details tab and Contacts tab will be duplicated under the customer you select below.</strong>
                    </AppAlertStatic>
                </Grid>
                <Grid item xs={12}>
                    <TgfCustomerAutoComplete
                        label={"Search Customer to duplicate under"}
                        id={"selectedCustomer"}
                        name={"selectedCustomer"}
                        onChange={handleSelectedCustomerChange}
                        agentIdThatOwnsCompany={props?.shipperConsignee?.associate?.id}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        type={"button"}
                        disabled={!isDirty}
                        onClick={() => setConfirmationModalOpen(true)}
                    >
                        Duplicate
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        className={"delete-button"}
                        type={"button"}
                        onClick={props.onClose}
                    >
                        Cancel
                    </Button>
                </Grid>
                {confirmationModalOpen &&
                    <ConfirmationModal
                        title={`Confirm Duplication`}
                        description={`Confirm you are wanting to duplicate ${props.shipperConsignee.name} to be a Shipper/Consignee under the customer: ${selectedCustomer.companyName}`}
                        action={save}
                        onClose={() => setConfirmationModalOpen(false)}
                        confirmBtnText={"CONFIRM"}
                        cancelBtnText={"CANCEL"}
                    />
                }
                {duplicatedShipperConsignee &&
                    <DuplicationCompleteModal
                        title={"Duplication Success"}
                        dupclicateShipperConsginee={duplicatedShipperConsignee}
                        onClose={props.onClose}
                    />
                }
            </Grid>
        </TgfDialog>
    )

}

export default DuplicateShipperConsigneeModal