const DocumentsActions = [
  'uploadDocument',
  'removeDocument'
];
const DocumentsFields = [
  'documentType',
  'description'
];

const PermissionSet = {
  name: 'documents',
  actions: DocumentsActions,
  fields: DocumentsFields
};

export default PermissionSet;