import React, {useRef, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import { Grid} from "@material-ui/core";
import LeftNav from "../LeftNav";
import {useSelector} from "react-redux";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import CollectorsAssignmentGrid from "./includes/CollectorsAssigmentGrid";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import {Checkbox} from "@progress/kendo-react-inputs";
import TgfAssociateAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";

const CollectorsAssignment = (props) => {
    const globalState = useSelector(state => state);
    const canViewCollectionsAssignment = globalState.persona.agent.canViewCollectionsAssignment;
    usePageTitle("TGF: Collectors Assignment");

    const [state, setState] = useState({
        searchInactiveOnly: false,
        filterAssociate: "",
    });

    const ref = useRef();

    const reloadData = () => {
        ref.current.refresh();
    }

    const handleStateChange = async (e) => {
        const name = e.target.name;
        const value = e.target.value ?? "";
        await setState({
            ...state,
            [name]: value,
        });
        await ref.current.refresh();
    }


    return (
        canViewCollectionsAssignment ?
            <FullWidthLayout SideNav={LeftNav} title={`Collectors Assignment`} className={"collectors-assignment"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems={"center"}>
                            <Grid item xs={12} md={2}>
                                <TgfAssociateAutoComplete
                                    id={"filterAssociate"}
                                    name={"filterAssociate"}
                                    value={state.customerName}
                                    onChange={handleStateChange}
                                    includeInactive={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Checkbox
                                    value={state.searchInactiveOnly}
                                    id={"searchInactiveOnly"}
                                    name={"searchInactiveOnly"}
                                    label={"Show Inactive Agents"}
                                    onChange={handleStateChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <CollectorsAssignmentGrid
                            ref={ref}
                            reloadData={reloadData}
                            {...state}
                        />
                    </Grid>
                </Grid>
            </FullWidthLayout> : <AccessDeniedPage />
    )
};

export default CollectorsAssignment;
