import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadShipmentCompany = async (shell, key, companyId) => {

  let shipmentCompany = null;

  if (companyId)
    shipmentCompany = await shell.gateway.getCompany(companyId);


  return {
    type: ActionTypes.Shipment.Modification.ShipmentCompanyLoaded,
    key,
    shipmentCompany
  }

};

export default ActionBuilder
  .for(loadShipmentCompany)
  .build();