import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const standardReports = async (shell) => {

  const result = await shell.gateway.getStandardReports();

  return {
    type: ActionTypes.Reporting.StandardReports.Loaded,
    standardReports: result,
  };
};

export default ActionBuilder
  .for(standardReports)
  .build();
