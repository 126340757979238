import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const listAutomationDocuments = async (shell, bolNumber) => {

  const { documents } = await shell.gateway.listAutomationDocuments(bolNumber);

  return {
    type: ActionTypes.Automation.ListAutomationDocuments,
    documents
  };
};

export default ActionBuilder
  .for(listAutomationDocuments)
  .build();
