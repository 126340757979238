import ActionBuilder from "../ActionBuilder2";


const convertThunksToActionBuilders = (thunks) => {
  if(thunks === null) {
    console.log('Error: Could not convert thunks to action builders')
    return;
  }
  for(let thunk in thunks) {
    if (typeof thunks[thunk] === 'function') {
      thunks[thunk] = ActionBuilder
        .for(thunks[thunk])
        .build();
    }
    else {
      console.log('Error: Could not convert thunk to action builder')
      return;
    }
  }
  // Return the converted thunks
  return thunks;
}

export default convertThunksToActionBuilders;