import React from "react";
import {TextArea} from "@progress/kendo-react-inputs";

const TgfTextArea = (props) => {
    return (
      <>
          <TextArea
            {...props}
            value={props.value ?? ""}
          />
      </>
    )
}

export default TgfTextArea