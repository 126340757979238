import * as Redux from 'redux';

import listing from './listing';
import modification from './modification';
import pending from './pending';

export default Redux.combineReducers({
  listing,
  modification,
  pending
});