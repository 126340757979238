import React from 'react';
import Grid from '@material-ui/core/Grid';
import Business from '@tgf-crm/business';
import {Assertion} from '@atomos/assertion';
import Qs from 'qs';

import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppButton from '../../../core/components/AppButton';
import DashboardListingTable from './includes/DashboardListingTable';

import DashboardFilterBar from "./includes/DashboardFilterBar";
import CrmTypedAddressBookListingComboBox from "../../../crm/components/CrmTypedAddressBookListingComboBox";
import AppMonthYear from "../../../core/components/inputs/AppDatePicker/AppMonthYear";
import BulkActionDrawer from "./includes/BulkActionDrawer";
import StatusSetNames from "./includes/StatusSetNames";
import RevertShipmentDrawer from './includes/RevertShipmentDrawer';
import ShipmentReverter from './includes/ShipmentReverter';

import LeftNav from '../LeftNav';
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
const LoadShipmentListingName = 'Dashboard.LoadShipmentListing';
const LoadShipmentStatusCountName = 'Dashboard.LoadShipmentStatusCount';
const SaveShipmentInvoiceName = 'Dashboard.SaveShipmentInvoice';
const FetchShipmentInvoiceName = 'Dashboard.FetchShipmentInvoice';
const MarkInvoicesGeneratedName = 'Dashboard.MarkInvoicesGenerated';
const MarkInvoicesSentName = 'Dashboard.MarkInvoicesSent';

const getDate = (monthYearValue) => monthYearValue && monthYearValue.isValid() ? monthYearValue.toDate() : null;
const getStartDate = (date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
};
const getEndDate = (date) => getStartDate(date).toMoment().utc().add(1, 'month').subtract(1, 'second').toDate();

const shipmentReverter = new ShipmentReverter();

const DashboardPage = (props) => {

  const {
    match,
    location,
    freightCategoryTypes,
    shipmentStatusTypes,
    shipments,
    shipmentCounts,
    statusSetCounts,
    saveShipmentInvoice,
    markInvoiceGenerate,
    markInvoiceSent,
    getCompany,
    getShipmentAndInvoice,
    loadShipmentListing,
    loadShipmentStatusCount,
    dispose,
    sendSnackbarMessage
  } = props;

  const {
    statusSet
  } = match.params;

  usePageTitle("TGF: Dashboard");

  const shipmentAssertions = Business.Shipment.ShipmentAssertions(shipmentStatusTypes, freightCategoryTypes);
  shipmentReverter.init(shipmentStatusTypes);

  const startingStatusesNotFlaggedProperly = Assertion.from(dashboardRecord => {
    return shipmentAssertions.isStatusNew
      .or(shipmentAssertions.isStatusInTransit)
      .or(shipmentAssertions.isStatusAwaitingPod)
      .or(shipmentAssertions.isStatusDelivered)
      .or(shipmentAssertions.isStatusHold)
      .matches(dashboardRecord) &&
      (dashboardRecord.customerPaid || dashboardRecord.invoiceGenerated || dashboardRecord.invoiceSent);
  });

  const isNotInvoiceGeneratedFlaggedProperly = Assertion.from(dashboardRecord => {
    return shipmentAssertions.isStatusInvoiceGenerated.matches(dashboardRecord) &&
      !dashboardRecord.invoiceGenerated;
  });
  const isInvoiceGeneratedAndAdditionalFlags = Assertion.from(dashboardRecord => {
    return shipmentAssertions.isStatusInvoiceGenerated.matches(dashboardRecord) &&
      (dashboardRecord.invoiceSent || dashboardRecord.customerPaid);
  });
  const isNotPaymentsPendingFlaggedProperly = Assertion.from(dashboardRecord => {
    return shipmentAssertions.isStatusPaymentsPending.matches(dashboardRecord) &&
      !(dashboardRecord.invoiceGenerated && dashboardRecord.invoiceSent);
  });
  const isPaymentsPendingAndFlaggedCustPaid = Assertion.from(dashboardRecord => {
    return shipmentAssertions.isStatusPaymentsPending.matches(dashboardRecord) &&
      dashboardRecord.customerPaid;
  });
  const isNotCustomerPaidFlaggedProperly = Assertion.from(dashboardRecord => {
    return shipmentAssertions.isStatusCustomerPaid.matches(dashboardRecord) &&
      !(dashboardRecord.customerPaid && dashboardRecord.invoiceGenerated && dashboardRecord.invoiceSent);
  });

  const flagRules = [
    [startingStatusesNotFlaggedProperly, 'Invoice Generated, Invoice Sent and Customer Paid checkboxes can\'t be checked.'],
    [isNotInvoiceGeneratedFlaggedProperly, 'Invoice Generated checkbox must be checked.'],
    [isInvoiceGeneratedAndAdditionalFlags, 'Invoice Sent and Customer Paid checkboxes can\'t be checked.'],
    [isNotPaymentsPendingFlaggedProperly, 'Invoice Generated and Invoice Sent checkboxes must be checked.'],
    [isPaymentsPendingAndFlaggedCustPaid, 'Customer Paid checkbox can\'t be checked.'],
    [isNotCustomerPaidFlaggedProperly, 'Invoice Generated, Invoice Sent and Customer Paid checkboxes must be checked.']
  ];

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(100);
  const [sort, setSort] = React.useState([['shipmentBolNumber', 'desc']]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('shipmentBolNumber');
  const [monthYearSelectedDate, setMonthYearSelectedDate] = React.useState(
    location.state?.year && location.state?.month ? new Date(location.state?.year, location.state?.month - 1, 1) : new Date()
  );
  const [selectedCustomer, setSelectedCustomer] = React.useState(location.state?.company || null);
  const [revertShipment, setRevertShipment] = React.useState(null);
  const [selectedShipments, setSelectedShipments] = React.useState(null);
  const [bulkActionStatus, setBulkActionStatus] = React.useState(null);

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {

    const startDate = getStartDate(monthYearSelectedDate);
    const endDate = getEndDate(monthYearSelectedDate);

    loadShipmentListing(startDate, endDate, selectedCustomer?.companyId, statusSet, offset, limit, sort);

  }, [statusSet, selectedCustomer, monthYearSelectedDate, offset, limit, sort, loadShipmentListing]);

  React.useEffect(() => {

    const startDate = getStartDate(monthYearSelectedDate);
    const endDate = getEndDate(monthYearSelectedDate);

    loadShipmentStatusCount(startDate, endDate, selectedCustomer?.companyId);
  }, [statusSet, selectedCustomer, monthYearSelectedDate, loadShipmentStatusCount])

  const handleMonthYearChange = (monthYearValue) => {
    setMonthYearSelectedDate(getDate(monthYearValue));
    setOffset(0);
  };

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleCustomerChange = (customer) => {
    setSelectedCustomer(customer);
    setOffset(0);
  };

  const handleRevertInvoiceSelectClick = (dashboardRecord) => {
    getShipmentAndInvoice(dashboardRecord.shipmentBolNumber)
      .then(([shipment, invoice]) => {
        setRevertShipment([dashboardRecord, shipment, invoice]);
      });
  };

  // Special row-level factory for creating a revert button click
  // handler if allowed.
  const rowRevertShipmentClickFactory = (dashboardRecord) =>
    shipmentReverter.canRevertStatus(dashboardRecord.statusId) && !dashboardRecord?.voidId ?
      () => handleRevertInvoiceSelectClick(dashboardRecord) :
      null;

  const handleSelectCustomerClick = (companyId) => {
    getCompany(companyId)
      .then((company) => {
        setSelectedCustomer(company);
        setOffset(0);
      });
  };

  const handleRevertInvoiceClick = (revertToStatus) => {

    // Grab the items involved in the shipment revert.
    // eslint-disable-next-line no-unused-vars
    const [dashboardRecord, shipment, invoice] = revertShipment;

    // Reverting can take place from:
    // Customer Paid -> Payments Pending -> Invoice Generated -> Hold
    // When going back through these statuses, the corresponding flags
    // must be disabled for proper visual reporting.
    // dashboardCustomerWasPaid -> dashboardInvoiceWasSent -> dashboardInvoiceWasGenerated
    const [changedShipment, changedInvoice] = shipmentReverter.revert(shipment, invoice, revertToStatus.id);

    setRevertShipment(null);

    saveShipmentInvoice(changedShipment, changedInvoice, true)
      .then(() => {

        const startDate = getStartDate(monthYearSelectedDate);
        const endDate = getEndDate(monthYearSelectedDate);

        return Promise.all([
          loadShipmentListing(startDate, endDate, selectedCustomer?.companyId, statusSet, offset, limit, sort),
          loadShipmentStatusCount(startDate, endDate, selectedCustomer?.companyId)
        ]);

      })
      .then(() => {
        setRevertShipment(null);
        sendSnackbarMessage({content: 'Shipment saved.'})
      });
  };

  const handleCancelRevertClick = () => {
    setRevertShipment(null);
  };

  const handleRemoveShipmentClick = (shipment) => {
    const newSelectedShipments = selectedShipments
      .filter(s => s.shipmentBolNumber !== shipment.shipmentBolNumber);

    setSelectedShipments(newSelectedShipments.length > 0 ? newSelectedShipments : null);
  };

  const handleBulkActionCancelClick = () => {
    setSelectedShipments(null);
  };

  const createInitiateHandler = (statusName) => () => {
    const statusType = shipmentStatusTypes.find(s => s.name === statusName);
    setBulkActionStatus(statusType);
    setSelectedShipments(shipments.filter(s => s.statusId === statusType.id));
  };

  const handleInitiateGenerateInvoicesClick = createInitiateHandler(Business.Shipment.ShipmentStatusNames.Hold);
  const handleInitiateMarkInvoiceSentClick = createInitiateHandler(Business.Shipment.ShipmentStatusNames.InvoiceGenerated);

  const createBulkActionHandler = (actionMethod, successMessage, additionalAction) => () => {
    actionMethod(selectedShipments.map(s => s.shipmentBolNumber))
      .then(() => {
        const startDate = getStartDate(monthYearSelectedDate);
        const endDate = getEndDate(monthYearSelectedDate);

        return Promise.all([
          loadShipmentListing(startDate, endDate, selectedCustomer?.companyId, statusSet, offset, limit, sort),
          loadShipmentStatusCount(startDate, endDate, selectedCustomer?.companyId)
        ]);
      })
      .then(() => additionalAction ? additionalAction() : null)
      .then(() => {
        setSelectedShipments(null);
        sendSnackbarMessage({ content: successMessage })
      });
  };

  // Navigates the user to the zip file containing the invoices.
  const navigateToDocGenerationPage = () => {
    const bolNumbers = selectedShipments.map(s => s.shipmentBolNumber);
    const qs = Qs.stringify({ bolNumbers });
    const a = window.document.createElement('a');
    a.href = `/generate/shipment/customer-invoices?${qs}`;
    a.target = '_blank';
    a.click();
    a.remove();
  };

  const handleGenerateInvoicesClick = createBulkActionHandler(markInvoiceGenerate, 'Invoices generated.', navigateToDocGenerationPage);
  const handleMarkInvoicesSentClick = createBulkActionHandler(markInvoiceSent, 'Invoices marked as sent.');

  const generateAction = (<AppButton onClick={handleGenerateInvoicesClick}>Generate</AppButton>);
  const markSentAction = (<AppButton onClick={handleMarkInvoicesSentClick}>Mark Sent</AppButton>);

  const bulkActionTitle = bulkActionStatus?.name === Business.Shipment.ShipmentStatusNames.Hold ?
    'Generate Invoices' : 'Mark Invoices Sent'

  const bulkConfirmAction = bulkActionStatus?.name === Business.Shipment.ShipmentStatusNames.Hold ?
    generateAction : markSentAction;

  const bulkCardActions = (
    <Grid container justify="flex-end" spacing={1}>
      <Grid item>{bulkConfirmAction}</Grid>
      <Grid item><AppButton onClick={handleBulkActionCancelClick}>Cancel</AppButton></Grid>
    </Grid>
  );

  const [dashboardRecord, shipment, invoice] = revertShipment || [];

  const processedShipments = shipments.map(dashboardRecord => {
    const statusType = shipmentStatusTypes.find(st => st.id === dashboardRecord.statusId);
    // eslint-disable-next-line no-unused-vars
    const [_, message] = flagRules.find(([assertion]) => assertion.matches(dashboardRecord)) || [];
    return {
      ...dashboardRecord,
      flagRuleMessage: message ? `Current status is ${statusType.name} - ${message}` : null
    };
  });

  return (
    <FullWidthLayout SideNav={LeftNav} title={'Dashboard'}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Grid container spacing={2}>

            <Grid item style={{marginTop: '17px'}}>
              <DashboardFilterBar
                trackedSet={null}
                counts={statusSetCounts}
                onClick={() => setOffset(0)}
              />
            </Grid>

            <Grid item>
              Month/Year:
              <AppMonthYear
                id="monthYear"
                openTo="year"
                views={["year", "month"]}
                inputVariant="outlined"
                value={monthYearSelectedDate}
                onChange={handleMonthYearChange}
              />
            </Grid>

            <Grid item style={{minWidth: '300px'}}>
              Customer:
              <CrmTypedAddressBookListingComboBox
                types={[CrmTypedAddressBookListingComboBox.Customer]}
                value={selectedCustomer}
                onChange={handleCustomerChange}
                popupIcon={' '}/>
            </Grid>

          </Grid>

          <Grid item>
            <Grid container spacing={1} justify="flex-end">
              {
                statusSet === StatusSetNames.HoldInvoiceGenerated &&
                <React.Fragment>
                  <Grid item>
                    <AppButton
                      onClick={handleInitiateGenerateInvoicesClick}
                      disabled={!(selectedCustomer && shipments.some(s => shipmentAssertions.isStatusHold.matches(s)))}>
                      Generate Invoices
                    </AppButton>
                  </Grid>
                  <Grid item>
                    <AppButton
                      onClick={handleInitiateMarkInvoiceSentClick}
                      disabled={!(selectedCustomer && shipments.some(s => shipmentAssertions.isStatusInvoiceGenerated.matches(s)))}>
                      Mark Invoices Sent
                    </AppButton>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          </Grid>

        </Grid>


        <Grid container item spacing={2} justify="center">
          <Grid item xs={12}>
            <DashboardListingTable
              shipmentCount={shipmentCounts}
              shipments={processedShipments}
              orderBy={orderBy}
              order={order}
              rowsPerPage={limit}
              page={offset / limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onSort={handleSortChange}
              rowRevertShipmentClickFactory={rowRevertShipmentClickFactory}
              onSelectCustomerClick={handleSelectCustomerClick}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        revertShipment &&
        <RevertShipmentDrawer
          customerName={dashboardRecord.customerName}
          invoice={invoice}
          statusOptions={shipmentReverter.listAllowedStatuses(shipment.statusId)}
          onRevertClick={handleRevertInvoiceClick}
          onCancelClick={handleCancelRevertClick}
        />
      }
      {
        selectedShipments && <BulkActionDrawer
          isOpen={Boolean(selectedShipments)}
          title={bulkActionTitle}
          customer={selectedCustomer}
          shipments={selectedShipments}
          actionButtons={bulkCardActions}
          onBulkActionCancelClick={handleBulkActionCancelClick}
          onRemoveShipmentClick={handleRemoveShipmentClick}
        />
      }
    </FullWidthLayout>
  )
}

export default ComponentBuilder
  .wrap(DashboardPage)
  .stateToProps((state, ownProps) => ({
    shipments: state.dashboard.shipments,
    shipmentCounts: state.dashboard.shipmentCount,
    statusSetCounts: state.dashboard.statusSetCounts,
    shipmentStatusTypes: state.support.shipmentStatusTypes,
    freightCategoryTypes: state.support.freightCategoryTypes,
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async loadShipmentListing(startDate, endDate, customerId, statusSet, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadShipmentListingName));
        dispatch(await shell.actions.dashboard.loadShipmentListing(startDate, endDate, customerId, statusSet, offset, limit, sort))
        dispatch(shell.actions.sys.processComplete(LoadShipmentListingName));
      },
      async loadShipmentStatusCount(startDate, endDate, customerId) {
        dispatch(shell.actions.sys.processStart(LoadShipmentStatusCountName));
        dispatch(await shell.actions.dashboard.loadShipmentStatusCount(startDate, endDate, customerId))
        dispatch(shell.actions.sys.processComplete(LoadShipmentStatusCountName));
      },
      async saveShipmentInvoice(shipment, invoice, shouldPerformDisablementCheck) {
        dispatch(shell.actions.sys.processStart(SaveShipmentInvoiceName));
        dispatch(await shell.actions.dashboard.saveShipmentInvoice(shipment, invoice, shouldPerformDisablementCheck))
        dispatch(shell.actions.sys.processComplete(SaveShipmentInvoiceName));
      },
      async dispose() {
        dispatch(await shell.actions.dashboard.dispose())
      },
      async getShipmentAndInvoice(bolNumber) {
        dispatch(shell.actions.sys.processStart(FetchShipmentInvoiceName));
        const result = await Promise.all([
          shell.gateway.getShipment(bolNumber),
          shell.gateway.getInvoice(bolNumber)
        ]);
        dispatch(shell.actions.sys.processComplete(FetchShipmentInvoiceName));
        return result;
      },
      async markInvoiceGenerate(bolNumbers) {
        dispatch(shell.actions.sys.processStart(MarkInvoicesGeneratedName));
        dispatch(await shell.actions.dashboard.markInvoicesGenerated(bolNumbers))
        dispatch(shell.actions.sys.processComplete(MarkInvoicesGeneratedName));
      },
      async markInvoiceSent(bolNumbers) {
        dispatch(shell.actions.sys.processStart(MarkInvoicesSentName));
        dispatch(await shell.actions.dashboard.markInvoicesSent(bolNumbers))
        dispatch(shell.actions.sys.processComplete(MarkInvoicesSentName));
      },
      async getCompany(companyId) {
        const options = {
          filter: {
            companyId
          },
          limit: 1
        };
        const {companies: [company]} = await shell.gateway.searchCompanies(options);
        return company;
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();