import ActionTypes from "../../../ActionTypes";

const associateCommissionRates = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Reporting.MonthlyGrossMargin.Disposed:
      return [];
    case ActionTypes.Reporting.MonthlyGrossMargin.MonthlyCommissionRateLoaded:
      return action.associateCommissionRates;
    default:
      return state;
  }
};

export default associateCommissionRates;