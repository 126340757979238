import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from "../../ActionTypes";

const loadShipmentDocumentTypes = async (shell) => {

  const shipmentDocumentTypes = await shell.gateway.getShipmentDocumentTypes();

  return {
    type: ActionTypes.Support.ShipmentDocumentTypes,
    shipmentDocumentTypes
  };
};

export default ActionBuilder
  .for(loadShipmentDocumentTypes)
  .build();