import React from "react";
import {Grid} from "@material-ui/core";
import {NumericTextBox} from "@progress/kendo-react-inputs";

const OriginalShipmentBreakdown = (props) => {

    return (
            <Grid container spacing={2} >
                <Grid item xs={4}>
                    <Grid container spacing={2} alignItems={"center"} wrap={"nowrap"}>
                        <Grid item>
                            <span>Original Shipment Current Adj. Carrier Cost</span>
                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"curAdjCarrierCost"}
                                name={"curAdjCarrierCost"}
                                // onChange={handleChange}
                                value={props.data.origAdjCarrierCost}
                                spinners={false}
                                disabled={true}
                                format={"c2"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2} alignItems={"center"} wrap={"nowrap"}>
                        <Grid item>
                            <span>Original Shipment Current Adj. Customer Cost</span>
                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"curAdjCustomerCost"}
                                name={"curAdjCustomerCost"}
                                // onChange={handleChange}
                                value={props.data.origAdjCustomerCost}
                                spinners={false}
                                disabled={true}
                                format={"c2"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2} alignItems={"center"} wrap={"nowrap"}>
                        <Grid item>
                            <span>Original Shipment Current Gross Margin</span>
                        </Grid>
                        <Grid item xs={6}>
                            <NumericTextBox
                                id={"curGrossMargin"}
                                name={"curGrossMargin"}
                                // onChange={handleChange}
                                value={props.data.origGrossMargin}
                                spinners={false}
                                disabled={true}
                                format={"c2"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default OriginalShipmentBreakdown;
