import React from "react";
import ComponentBuilder from "../../core/ComponentBuilder";

import AppDropdown from '../../core/components/dropdowns/AppDropdown/AppDropdown';

const CrmShippingThirdPartyDropDown = (props) => {
  const {
    onChange,
    getShipmentThirdParty,
    disabled,
    value
  } = props;

  const [thirdPartyCompanies, setThirdPartyCompanies] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    getShipmentThirdParty()
      .then((companies) => {
        if (mounted)
          setThirdPartyCompanies(companies);
      });

    return () => mounted = false;
  }, []);

  const handleOnChange = (e, value) => {
    onChange(value);
  }

  return (
    <AppDropdown
      label="Third-Party"
      id="thirdParty"
      field={'companyName'}
      fieldIndex='companyId'
      disabled={disabled}
      options={thirdPartyCompanies}
      onChange={handleOnChange}
      value={value}
    />
  )
};

export default ComponentBuilder
  .wrap(CrmShippingThirdPartyDropDown)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async getShipmentThirdParty() {

        const {
          support
        } = shell.stateStore.getState();

        const { companies } = await shell.gateway.searchCompanies({
          filter: {
            categoryTypeId: {
              $in: [support.thirdPartyCategoryType.id, support.billingCompanyCategoryType.id]
            },
            companyIsInactive: false
          }
        });

        return companies;
      }
    }
  })
  .build();