import React from 'react';
import AppMuiCard from '../../../core/components/cards/AppCard';
import {Grid} from '@material-ui/core';
import AppDropdown from '../../../core/components/dropdowns/AppDropdown';
import AppMuiInput from '../../../core/components/inputs/AppInput';
import AppDatePicker from '../../../core/components/inputs/AppDatePicker';
import AppValidationFailure from "../../../core/components/AppValidationFailure";

const CarrierNumbersCard = (props) => {

  const {
    errors,
    dotNumber,
    freightCategoryTypes,
    mcNumber,
    packetReceivedDate,
    selectedFreightCategory,
    setFieldValue,
    vendorTaxId,
    isDisabled,
    isNew
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value)
  };

  const handleFreightCategoryChange = async (e, value) => {
    const freightCategory = value;

    setFieldValue('selectedFreightCategory', freightCategory);
    setFieldValue('freightCategoryId', freightCategory.id);
  };

  const handlePacketReceivedDateChange = (date) => {
    const packetReceivedDate = date && date.isValid() ?
      date.toDate() : null;
    setFieldValue('packetReceivedDate', packetReceivedDate);
  };

  return (
    <AppMuiCard title={'General'}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <AppDropdown
            id={'freightCategory'}
            label={'Carrier Type'}
            disabled={isDisabled}
            field={'name'}
            fieldIndex={'id'}
            options={freightCategoryTypes}
            value={selectedFreightCategory || null}
            onChange={handleFreightCategoryChange}
          />
          <AppValidationFailure
            message={errors.selectedFreightCategory}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <AppMuiInput
                id={'mcNumber'}
                label={'MC #'}
                disabled={!isNew}
                value={mcNumber || ''}
                inputProps={{
                  maxLength: 25
                }}
                onChange={handleFieldChange('mcNumber')}
              />
              <AppValidationFailure
                message={errors.mcNumber}
              />
            </Grid>
            <Grid item md={6}>
              <AppMuiInput
                id={'dotNumber'}
                label={'DOT #'}
                disabled={isDisabled}
                value={dotNumber || ''}
                inputProps={{
                  maxLength: 15
                }}
                onChange={handleFieldChange('dotNumber')}
              />
              <AppValidationFailure
                message={errors.dotNumber}
              />
            </Grid>
            <Grid item md={6}>
              <AppMuiInput
                id={'vendorTaxId'}
                label={'Vendor Tax ID'}
                disabled={isDisabled}
                value={vendorTaxId || ''}
                inputProps={{
                  maxLength: 15
                }}
                onChange={handleFieldChange('vendorTaxId')}
              />
              <AppValidationFailure
                message={errors.vendorTaxId}
              />
            </Grid>
            <Grid item md={6}>
              <AppDatePicker
                id={'packetReceivedDate'}
                label={'Date Packet Received'}
                disabled={isDisabled}
                value={packetReceivedDate}
                onChange={handlePacketReceivedDateChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

export default CarrierNumbersCard;