import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';
import CategoryTypeNames from '../../addressBook/CategoryTypeNames';
import ensureCompanyCategoryType from './ensureCompanyCategoryType';

const loadCompanyCategoryTypes = async (shell) => {

  const companyCategoryTypes = await shell.gateway.getCompanyCategoryTypes();

  const customerCategoryType = ensureCompanyCategoryType(companyCategoryTypes, CategoryTypeNames.Customer);
  const shipperConsigneeCategoryType = ensureCompanyCategoryType(companyCategoryTypes, CategoryTypeNames.ShipperConsignee);
  const thirdPartyCategoryType = ensureCompanyCategoryType(companyCategoryTypes, CategoryTypeNames.ThirdParty);
  const leadCategoryType = ensureCompanyCategoryType(companyCategoryTypes, CategoryTypeNames.Lead);
  const billingCompanyCategoryType = ensureCompanyCategoryType(companyCategoryTypes, CategoryTypeNames.BillingCompany);
  const factoringCompanyCategoryType = ensureCompanyCategoryType(companyCategoryTypes, CategoryTypeNames.FactoringCompany);

  return {
    type: ActionTypes.Support.CompanyCategoryTypesLoaded,
    companyCategoryTypes,
    customerCategoryType,
    shipperConsigneeCategoryType,
    thirdPartyCategoryType,
    leadCategoryType,
    billingCompanyCategoryType,
    factoringCompanyCategoryType
  };

};

export default ActionBuilder
  .for(loadCompanyCategoryTypes)
  .build();