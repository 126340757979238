import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppMuiSwitch from "../../../../core/components/inputs/AppSwitch";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import StatusTypeNames from "../../../../hubs/shipment/StatusTypeNames";

const PreferredContactMethodSwitches = (props) => {

    const {
        tooltipTitle,
        showEmail,
        setFieldValue,
        type,
        selectedShipmentStatusName = '',
        callContactPreferred,
        textContactPreferred,
        emailContactPreferred = null
    } = props;

    const allowedShipmentStatuses = [StatusTypeNames.New, StatusTypeNames.InTransit, StatusTypeNames.AwaitingPod, StatusTypeNames.Delivered]

    const disablePreferredContactEdit = !allowedShipmentStatuses.includes(selectedShipmentStatusName);

    const onChangeHandler = (e, key) => {
        setFieldValue(key, e.target.checked);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                Preferred Contact Methods
                <InfoIconTooltip title={tooltipTitle} />
            </Grid>
            <Grid item>
                <AppMuiSwitch
                    label={'Call'}
                    checked={callContactPreferred || false}
                    onChange={(e) => onChangeHandler(e, type + 'CallContactPreferred')}
                    disabled={disablePreferredContactEdit}
                />
                <AppMuiSwitch
                    label={'Text'}
                    checked={textContactPreferred || false}
                    onChange={(e) => onChangeHandler(e, type + 'TextContactPreferred')}
                    disabled={disablePreferredContactEdit}
                />
                {showEmail &&
                    <AppMuiSwitch
                        label={'Email'}
                        checked={emailContactPreferred || false}
                        onChange={(e) => onChangeHandler(e, type + 'EmailContactPreferred')}
                        disabled={disablePreferredContactEdit}
                    />
                }
            </Grid>
        </Grid>
    )
};

export default PreferredContactMethodSwitches