import AppMuiCard from "../../../../core/components/cards/AppCard";
import {Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";

const HasDuplicatedShipment = (props) => {

    return (
      <>
          <Grid item xs={12}>
              <AppMuiCard>
                  <Grid container item xs={12} spacing={1}>
                      <Grid item xs={12}>
                          <Typography variant={'body2'}>New Shipments</Typography>
                      </Grid>
                      {
                          props.duplicateBolNumbers.map((number, index) => (
                            <Grid item key={index}><Link
                              to={`/shipment/${number}/details`}>{number}</Link></Grid>
                          ))
                      }
                  </Grid>
              </AppMuiCard>
          </Grid>
      </>
    );
}

export default HasDuplicatedShipment
