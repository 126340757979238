import React from 'react';
import FasterFormFactor from '../../../../core/FormFactor/FasterFormFactor';
import AppDialog from '../../../../core/components/AppDialog';
import * as Yup from 'yup';
import Core from '@atomos/core';
import {DialogActions, Grid} from '@material-ui/core';
import AppPhoneInput from '../../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput';
import FormFactorValueInput from '../../../../core/FormFactor/FormFactorValueInput';
import AppButton from '../../../../core/components/AppButton/AppMuiButton';
import FormFactorGraft from '../../../../core/FormFactor/FormFactorGraft';
import FormFactorValidationFailure from '../../../../core/FormFactor/FormFactorValidationFailure';

const BillingContactFormSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .nullable()
    .email('Email is invalid.'),
  phone: Yup.string()
    .nullable()
    .matches(Core.Text.PhoneRegExp, 'Business Phone is invalid.'),
  cellPhone: Yup.string()
    .nullable()
    .matches(Core.Text.PhoneRegExp, 'Mobile Phone is invalid.'),
  faxPhone: Yup.string()
    .nullable()
    .matches(Core.Text.PhoneRegExp, 'Fax Phone is invalid.')
});

const saveSlices = ['isDirty', 'isValid', 'submitForm'];
const saveGraftMap = ([isDirty, isValid, submitForm]) => ({
  disabled: !(isValid && isDirty),
  // NOTE: Manually calling submitForm due to dialog component
  //       hierarchy being relocated from the form.
  onClick: () => submitForm()
});

const cancelSlices = ['supportingValues.onCancelClick'];
const cancelGraftMap = ([onCancelClick]) => ({
  onClick: (e) => onCancelClick()
});

const ModifyContactDialog = ({
  contact,
  onSaveClick,
  onCancelClick }) => {

  if (!contact)
    return null;

  const formProps = {
    initialValues: {
      ...contact
    },
    supportingValues: {
      onSaveClick,
      onCancelClick
    },
    schema: BillingContactFormSchema,
    onSubmit: (values) => {
      onSaveClick(values);
    }
  };

  // Is this a saved contact?
  const title = contact?.id ?
    'Modify Billing Contact' : 'Add Billing Contact';

  return (
    <FasterFormFactor {...formProps}>
      <AppDialog
        title={title}
        open={true}
        onClose={onCancelClick}
        width={'sm'}
        AppDialogActions={DialogActionButtons}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormFactorValueInput
                  field={'firstName'}
                  id={'firstName'}
                  label={'First Name'}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item>
                <FormFactorValueInput
                  field={'lastName'}
                  id={'lastName'}
                  label={'Last Name'}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item>
                <FormFactorValueInput
                  field={'emailAddress'}
                  id={'emailAddress'}
                  label={'Email'}
                  inputProps={{ maxLength: 150 }}
                />
                <FormFactorValidationFailure field={'emailAddress'} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormFactorValueInput
                  as={AppPhoneInput}
                  field={'phone'}
                  id={'phone'}
                  label={'Business Phone'}
                />
                <FormFactorValidationFailure field={'phone'} />
              </Grid>
              <Grid item>
                <FormFactorValueInput
                  as={AppPhoneInput}
                  field={'cellPhone'}
                  id={'cellPhone'}
                  label={'Mobile Phone'}
                />
                <FormFactorValidationFailure field={'cellPhone'} />
              </Grid>
              <Grid item>
                <FormFactorValueInput
                  as={AppPhoneInput}
                  field={'faxPhone'}
                  id={'faxPhone'}
                  label={'Fax Phone'}
                />
                <FormFactorValidationFailure field={'faxPhone'} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </AppDialog>
    </FasterFormFactor>
  );
};

const DialogActionButtons = () => {
  return (
    <DialogActions>
      <FormFactorGraft
        as={AppButton}
        slices={cancelSlices}
        graftMap={cancelGraftMap}>
        Cancel
      </FormFactorGraft>
      <FormFactorGraft
        as={AppButton}
        type={'submit'}
        slices={saveSlices}
        graftMap={saveGraftMap}>
        Save
      </FormFactorGraft>
    </DialogActions>
  );
};

export default ModifyContactDialog;