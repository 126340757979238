import React, {useEffect, useState} from "react";
import ShipmentDetails from "../details/ShipmentDetails";
import EditShipment from "../edit-posting/EditShipment";
import EditDialogNav from "../navigation/EditDialogNav";
import AddEditTruckstopPosting from "../edit-posting/AddEditTruckstopPosting";
import AddEditDatPosting from "../edit-posting/AddEditDatPosting";
import {useDispatch} from "react-redux";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";

const EditDialog = (props) => {

    const personalDispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState("shipment");
    const [openLoad, setOpenLoad] = React.useState({});

    const loadPageDependencies = async () => {
        if (props.bolNumber) {
            try {
                personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));
                const result = await window.shell.gateway.getOpenLoad(props.bolNumber);
                if (!result?.shipmentId) {
                    setOpenLoad(null);
                    // when the current tab is shipment, but there is still no open load,
                    // we can assume the load is no longer open, and should try to clean up these postings.
                    if(currentTab === "shipment") {
                        await window.shell.gateway.deleteAllPostingsOnShipment(props.bolNumber);
                    }
                    personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
                    return
                }
                // else
                setOpenLoad(result);
            } catch(e) {
                console.log(e);
            } finally {
                personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
            }
        }
    }

    useEffect(() => {
        loadPageDependencies();
    }, [props.bolNumber]);

    const toggleDialog = () => {
        props.setEditDialogOpen(false)
        props.closeDialog();

    };

    useEffect(() => {
        if (props.openedFrom === "shipments") {
            setCurrentTab("shipment");
        } else if (props.openedFrom === "DAT") {
            setCurrentTab("dat");
        } else if (props.openedFrom === "ITS") {
            setCurrentTab("its");
        }
    }, []);

    const title = `Open Loads Board${openLoad?.shipmentId ? " - Shipments - " + openLoad.bolNumber : ""}
     ${props.openedFrom === 'DAT' || props.openedFrom === 'ITS' ? '- ' + props.openedFrom + ' Posting' : ''}`;

   const handleDialogUpdate = async () => {
       await loadPageDependencies();
    }

    return (
        <TgfDialog title={title} onClose={toggleDialog} width={1000} >
        {openLoad?.shipmentId ?
            <>
                <EditDialogNav
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    shipment={openLoad}
                    datPostingId={openLoad ? openLoad?.datLoadPostingId : props.datLoadPostingId}
                    truckstopPostingId={openLoad ? openLoad?.truckstopLoadPostingId : props.truckstopLoadPostingId}
                />
                <ShipmentDetails shipment={openLoad} editMode={true}/>
                {currentTab === "shipment" && (
                    <EditShipment
                        openLoad={openLoad}
                        onDialogUpdate={handleDialogUpdate}
                    />
                )}
           </> :
            !openLoad && currentTab === "shipment" && <p style={{color: "red", fontWeight: "bold"}}> The record you are trying to view is no longer available on the Open Loads page. Please close out of this dialog to refresh the page.</p>
        }

      {currentTab === "dat" && (
          <AddEditDatPosting
            datLoadPostingId={openLoad?.shipmentId ? openLoad?.datLoadPostingId : props.datLoadPostingId}
            openLoad={openLoad}
            closeDialog={toggleDialog}
            onDialogUpdate={handleDialogUpdate}
            datDuplicationFormValues={props.datDuplicationFormValues}
          />
      )}
      {currentTab === "its" && (
          <AddEditTruckstopPosting
            truckstopLoadPostingId={openLoad?.shipmentId ? openLoad?.truckstopLoadPostingId : props.truckstopLoadPostingId}
            openLoad={openLoad}
            closeDialog={toggleDialog}
            onDialogUpdate={handleDialogUpdate}
            truckstopDuplicationFormValues={props.truckstopDuplicationFormValues}
          />
        )}
      </TgfDialog>
    );
};

export default EditDialog;

