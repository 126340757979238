import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder2';

const dispose = async (shell) => {
  return {
    type: ActionTypes.Shipment.LoadBoard.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();