import React from "react";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import DateOnlyPicker from "../../../../../core/kendo/inputs/DateOnlyPicker";

const ConfirmedLockDate = (props) => {

    const invoice = props.data.invoice;

    const handleChange = async (e) => {
        props.setData({
            ...props.data,
            invoice: {
                ...invoice,
                [e.target.name]: e.target.value
            }
        });
        props.setIsDirty(true);
    };

    return (
        <AppMuiCard title={"Confirmed Lock Date"}>
            <DateOnlyPicker
                id={"actualDeliveryDate"}
                name={"actualDeliveryDate"}
                label={"Comfirmed Lock Date"}
                value={invoice?.actualDeliveryDate}
                max={new Date(new Date().setDate(new Date().getDate() + 5))}
                onChange={handleChange}
                disabled={props.disableEdit || invoice?.isAssociatePaidConfirmed}
            />
        </AppMuiCard>
    )
}

export default ConfirmedLockDate;