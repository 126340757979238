import * as Redux from 'redux';

import createDocs from './createDocs';
import customer from './customer';
import duplication from './duplication';
import loadBoard from './loadBoard';
import openLoad from './openLoad';
import modification from './modification';
import trackedSetCounts from './trackedSetCounts';

export default Redux.combineReducers({
  createDocs,
  customer,
  duplication,
  loadBoard,
  modification,
  openLoad,
  trackedSetCounts
});