import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import ComponentBuilder from "../../core/ComponentBuilder";
import { TextField} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import combineClassNames from '../../core/utils/combineClassNames';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.border,
      padding: '0 0 0 10px'
    }
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}));

const CrmAssociateComboBox = (props) => {

  const classes = useStyles();
  const {
    searchAssociates,
    onSelectAssociate,
    staticContext,
    placeholder,
    roleId,
    ...otherProps
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [associates, setAssociates] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSearchTermChange = async (e) => {
    setIsLoading(true);
    const associates = await searchAssociates(e.target.value);
    setAssociates(associates);
    setIsLoading(false);
  };

  const handleChange = (e, associate, reason) => {
    onSelectAssociate(associate);

    // If the change is to clear, empty our search results.
    if (reason === 'clear') {
      setAssociates([]);
    }
  };

  const filterOptions = createFilterOptions({
    // matchFrom: 'start', *** ('any' | 'start' [optional]): Defaults to 'any'.
    stringify: (option) => option.lastName + option.firstName
  });

  return (
    <Autocomplete
      open={isOpen}
      className={combineClassNames(classes.root, otherProps.disabled ? classes.disabled : null)}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={associates}
      loading={isLoading}
      onChange={handleChange}
      popupIcon={' '}
      {...otherProps}
      getOptionSelected={(associateListing, selectedAbListing) => associateListing.id === selectedAbListing.id}
      getOptionLabel={(associateListing) => { return associateListing.firstName +  ' ' + associateListing.lastName}}
      filterOptions={filterOptions}
      renderInput={
        (params) => (
          <TextField
            {...params}
            variant={'outlined'}
            onChange={handleSearchTermChange}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps
            }}
          />
        )
      }
      renderOption={
        (option, state) => {
          return (
            <React.Fragment>
              <span>{option.firstName}  {option.lastName} </span>
            </React.Fragment>
          )
        }
      }
    />
  );

}

export default ComponentBuilder
  .wrap(CrmAssociateComboBox)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async searchAssociates(searchTerm) {

        if (searchTerm.trim().length === 0)
          return [];

        const searchOptions = {
          searchTerm,
          roleId: ownProps.roleId,
          offset: 0,
          limit: ownProps.limit,
          sort: [['lastName', 'asc']]
        };

        const associates = await shell.gateway.searchAllAssociates(searchOptions);
        return associates;
      }
    }

  })
  .build();