import React, {useEffect, useState} from 'react';
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Refresh} from "@material-ui/icons";
import ConditionalWrapper from "../../../../core/kendo/ConditionalWrapper";
import {Tooltip} from "@material-ui/core";
import DateUtils from "../../../../core/utils/dateUtils/dateUtils";

const RefreshButton = ({isRefreshable, postId, refreshPosting, disabled, disabledReason, tooltip}) => {
    const isDisabled = disabled || !isRefreshable;
    const actualDisabledReason = !isRefreshable ? tooltip: disabledReason;

    return (

      <ConditionalWrapper
        condition={isDisabled}
        wrapper={children =>
          <Tooltip title={<div className={"tgf-kendo-grid-tooltip"}>
              <p>{actualDisabledReason}</p>
          </div>
          }>
              <span>{children}</span>
          </Tooltip>
        }
      >
          <div style={{textAlign: 'start', margin: 0}}>
              <AppIconButton
                style={{cursor: "pointer"}}
                Icon={Refresh}
                tooltip={!isDisabled && <span style={{fontSize: ".8rem"}}>Refresh Post</span>}
                onClick={() => refreshPosting(postId)}
                disabled={isDisabled}
              />
          </div>
      </ConditionalWrapper>
    )
}

const PostAge = (props) => {

    const getTimeTillRefresh = dateRefreshable => {

        const currentTime = new Date();

        if (dateRefreshable != null) {
            let seconds = Math.floor((dateRefreshable - currentTime) / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);

            hours = hours - (days * 24);
            minutes = minutes - (days * 24 * 60) - (hours * 60);
            seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

            return {
                isRefreshable: DateUtils.checkIfDateALessThanDateB(dateRefreshable, currentTime),
                minutes: minutes.toString().padStart(2, '0'),
                seconds: seconds.toString().padStart(2, '0')
            }
        }

        return {days: '', hours: '', minutes: '', seconds: ''}
    }

    const [timeTillRefresh, setTimeTillRefresh] = useState(getTimeTillRefresh(props.dateRefreshable));

    useEffect(() => {
        if (!timeTillRefresh.isRefreshable) {
            const interval = setTimeout(() => {
                setTimeTillRefresh(getTimeTillRefresh(props.dateRefreshable));
            }, 1000);
        }
    }, [timeTillRefresh, props.dateRefreshable]);

    // fix for sorting lag.
    useEffect(() => {
        setTimeTillRefresh(getTimeTillRefresh(props.dateRefreshable));
    }, [props.dateRefreshable])

    const getDisplayText = (showText, timeTillRefresh) => {
        if (showText === false) {
            return !timeTillRefresh.isRefreshable ? `${timeTillRefresh.minutes}:${timeTillRefresh.seconds}` : '00:00';
        } else {
            return !timeTillRefresh.isRefreshable ? `Refresh In - ${timeTillRefresh.minutes}:${timeTillRefresh.seconds}` : 'Refresh In - 00:00';
        }
    }

    return (
      props.dateRefreshable != null ?
        <div style={{display: "flex", alignItems: "center"}}>
            <span style={{margin: 2}}>{getDisplayText(props.showText, timeTillRefresh)}</span>
            <RefreshButton
              isRefreshable={timeTillRefresh.isRefreshable}
              postId={props.postId}
              refreshPosting={props.refreshPosting}
              disabled={props.disabled}
              disabledReason={props.disabledReason}
              tooltip={props.tooltip}
            />
        </div>
        :
        ""
    )
}

export default PostAge;
