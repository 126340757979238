import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import AppMuiButton from "../../../../../core/components/AppButton";
import {useDispatch} from "react-redux";
import TgfTextArea from "../../../../../core/kendo/form/inputs/TgfTextArea";
import _ from "lodash";
import "./CollectionHotNote.scss";

const CollectionHotNote = ({ companyId }) => {
    const personalDispatch = useDispatch();
    const [hotNote, setHotNote] = useState(null);
    const [hotNoteClone, setHotNoteClone] = useState(null);
    const [hotNoteId, setHotNoteId] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [editMode, setEditMode] = useState(false)

    const load = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart("loadCollectionHotNotes"));
            // load data
            const result = await window.shell.gateway.getCollectorHotNote(companyId);
            setHotNote(result ? result.note : null);
            setHotNoteId(result ? result.id : null);
            setHotNoteClone(result ? _.clone(result.note) : null);

        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Collection Hot Notes',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete("loadCollectionHotNotes"));
        }
    };

    const handleSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart("saveCollectionHotNotes"));
            const noteData = {
                id: hotNoteId,
                note: hotNote
            }
            await window.shell.gateway.saveCollectorHotNote(companyId, noteData);

            setEditMode(false);
            await load();
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save Collection Hot Notes',
                color: 'error'
            }));
        } finally {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Collection Hot Note Saved'}));
            personalDispatch(window.shell.actions.sys.processComplete("saveCollectionHotNotes"));
        }
    };

    const handleCancel = async () => {
        setHotNote(hotNoteClone ? _.clone(hotNoteClone) : null);
        setEditMode(false);
    }

    const handleChange = (e) => {
        setHotNote( e.target.value ? e.target.value : null);
        setIsDirty(true);
    };

    useEffect(() => {
        load();
    }, []);


    return (
        <AppMuiCard title={"Collection Hot Notes"} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TgfTextArea
                        id={"collectorHotNote"}
                        name={"collectorHotNote"}
                        className={"collectorHotNote"}
                        value={hotNote}
                        onChange={handleChange}
                        rows={8}
                        readOnly={!editMode}

                    />
                </Grid>
                {editMode ?
                    <>
                        <Grid item>
                            <AppMuiButton type={"submit"} onClick={handleSave} disabled={!isDirty}>
                                save
                            </AppMuiButton>
                        </Grid>
                        <Grid item>
                            <AppMuiButton color={'danger'} type={"button"} onClick={handleCancel}>
                                cancel
                            </AppMuiButton>
                        </Grid>
                    </>
                    :
                    <Grid item>
                        <AppMuiButton type={"button"} onClick={() => setEditMode(true)}>
                            edit
                        </AppMuiButton>
                    </Grid>
                }

            </Grid>
        </AppMuiCard>
    );
};

export default CollectionHotNote
