import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonGroup, Button } from '@material-ui/core';
import "./ListingButtonGroup.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  active: {
    textDecoration: 'none',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }
}
));

const ListingButtonGroup = ({ links }) => {
  const classes = useStyles();

  return (
          <ButtonGroup color="primary" style={{display: "flex"}}>
            {
              links.map((link, key) => {
                const activeClassName = link.isActive ? classes.active : classes.buttonLink;
                return (
                      <Button
                          component={Link}
                          className={activeClassName + " address-book_button-group_btn"}
                          key={key}
                          onClick={link.onClick}
                          to={link.url}
                      style={{ flexGrow: 1}}>
                        {link.title}
                      </Button>
                )
              })
            }

          </ButtonGroup>
  )
};

export default ListingButtonGroup;