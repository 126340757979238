import React from 'react';
import {Grid} from "@material-ui/core";
import ComponentBuilder from "../../../../core/ComponentBuilder";

import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiCard from "../../../../core/components/cards/AppCard";
import AppNumericInput from "../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";

import '../financialPage.scss';
import SecureField from "../../permissions/SecureField";

const AddedCarrierCosts = (props) => {

  const {
    detentionAmount,
    layoverAmount,
    reweighAdjustmentAmount,
    reclassAdjustmentAmount,
    carrierLumpersAmount,
    carrierOtherAmountLabel,
    carrierOtherAmount,
    detentionAmountError,
    layoverAmountError,
    reweighAdjustmentAmountError,
    reclassAdjustmentAmountError,
    carrierLumpersAmountError,
    carrierOtherAmountLabelError,
    carrierOtherAmountError,
    setFieldValue
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(`invoice.${key}`, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <AppMuiCard title={'Added Carrier Costs'}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={6}><label htmlFor={'carrierDetentionCost'}>Detention</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'detentionAmount'}
                as={AppNumericInput}
                id={'carrierDetentionCost'}
                decimalScale={2}
                value={detentionAmount}
                onChange={handleFieldChange('detentionAmount')}
              />
              <AppValidationFailure message={detentionAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'carrierLayoverCost'}>Layover</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'layoverAmount'}
                as={AppNumericInput}
                id={'carrierLayoverCost'}
                decimalScale={2}
                value={layoverAmount}
                onChange={handleFieldChange('layoverAmount')}
              />
              <AppValidationFailure message={layoverAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'carrierReweightCost'}>Reweigh</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'reweighAdjustmentAmount'}
                as={AppNumericInput}
                id={'carrierReweightCost'}
                decimalScale={2}
                value={reweighAdjustmentAmount}
                onChange={handleFieldChange('reweighAdjustmentAmount')}
              />
              <AppValidationFailure message={reweighAdjustmentAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'carrierReclassCost'}>Reclass</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'reclassAdjustmentAmount'}
                as={AppNumericInput}
                id={'carrierReclassCost'}
                decimalScale={2}
                value={reclassAdjustmentAmount}
                onChange={handleFieldChange('reclassAdjustmentAmount')}
              />
              <AppValidationFailure message={reclassAdjustmentAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'carrierLumpersCost'}>Lumpers</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierLumpersAmount'}
                as={AppNumericInput}
                id={'carrierLumpersCost'}
                decimalScale={2}
                value={carrierLumpersAmount}
                onChange={handleFieldChange('carrierLumpersAmount')}
              />
              <AppValidationFailure message={carrierLumpersAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierOtherAmountLabel'}
                as={AppInput}
                id={'carrierOtherDescription'}
                value={carrierOtherAmountLabel || ''}
                inputProps={{
                  maxLength: 30
                }}
                onChange={handleFieldChange('carrierOtherAmountLabel')}
              />
              <AppValidationFailure message={carrierOtherAmountLabelError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierOtherAmount'}
                as={AppNumericInput}
                id={'carrierOtherAmount'}
                decimalScale={2}
                value={carrierOtherAmount}
                onChange={handleFieldChange('carrierOtherAmount')}
              />
              <AppValidationFailure message={carrierOtherAmountError}/>
            </Grid>
          </Grid>
        </AppMuiCard>
      </Grid>
    </React.Fragment>
  )
};

const propsAreEqual = (prevProps, nextProps) => {
  const isEqual = prevProps.detentionAmount === nextProps.detentionAmount &&
    prevProps.layoverAmount === nextProps.layoverAmount &&
    prevProps.reweighAdjustmentAmount === nextProps.reweighAdjustmentAmount &&
    prevProps.reclassAdjustmentAmount === nextProps.reclassAdjustmentAmount &&
    prevProps.carrierLumpersAmount === nextProps.carrierLumpersAmount &&
    prevProps.carrierOtherAmountLabel === nextProps.carrierOtherAmountLabel &&
    prevProps.carrierOtherAmount === nextProps.carrierOtherAmount &&
    prevProps.detentionAmountError === nextProps.detentionAmountError &&
    prevProps.layoverAmountError === nextProps.layoverAmountError &&
    prevProps.reweighAdjustmentAmountError === nextProps.reweighAdjustmentAmountError &&
    prevProps.reclassAdjustmentAmountError === nextProps.reclassAdjustmentAmountError &&
    prevProps.carrierLumpersAmountError === nextProps.carrierLumpersAmountError &&
    prevProps.carrierOtherAmountLabelError === nextProps.carrierOtherAmountLabelError &&
    prevProps.carrierOtherAmountError === nextProps.carrierOtherAmountError;

  return isEqual;
};

export default React.memo(ComponentBuilder
    .wrap(AddedCarrierCosts)
    .build(),
  propsAreEqual
);