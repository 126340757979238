import ActionTypes from '../../ActionTypes';

const customerCategoryType = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.Support.CompanyCategoryTypesLoaded:
            return action.customerCategoryType;
        default:
            return state;
    }
};

export default customerCategoryType;