import ActionTypes from '../../../ActionTypes';

const listing = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Reporting.MissingCreditApp.Disposed:
      return [];
    case ActionTypes.Reporting.MissingCreditApp.Loaded:
      return action.companies;
    default:
      return state;
  }
};

export default listing;