import ActionTypes from "../../ActionTypes";

const associateTotalStatement = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.CommissionCenter.Disposed:
      return null;
    case ActionTypes.CommissionCenter.StatementLoaded:
      return action.associateTotalStatement;
    default:
      return state;
  }
};

export default associateTotalStatement;