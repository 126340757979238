import ActionTypes from '../../ActionTypes';

const leadCategoryType = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.Support.CompanyCategoryTypesLoaded:
            return action.leadCategoryType;
        default:
            return state;
    }
};

export default leadCategoryType;