import ActionTypes from '../../../ActionTypes';
import ActionBuilder from "../../../ActionBuilder2";

const load = async (shell, stateFrom, stateTo, offset, limit, sort, associateId, filter) => {
  // Filters are to be combined as a sql condition and query string with searchTerms and passed on to the api
  const { count, shipments } = await shell.gateway.getLaneLookup(stateFrom, stateTo, offset, limit, sort, associateId, filter);

  return {
    type: ActionTypes.Reporting.LaneLookup.Loaded,
    shipments,
    count
  };

};

export default ActionBuilder
  .for(load)
  .build();