import React, {useState} from "react"
import {DateRangePicker} from "@progress/kendo-react-dateinputs";

const TgfDateRangePicker = (props) => {

    const [shouldShow, setShouldShow] = useState(false);

    const handleShow = (bool) => {
        setShouldShow(bool);
    }

    const handleChange = (e) => {
        if(e.target.value?.start && e.target.value?.end) setShouldShow(false);
        props.onChange(e);
    }


    return (
        <DateRangePicker
            value={props.value}
            show={shouldShow}
            onFocus={() => handleShow(true)}
            onBlur={() => handleShow(false)}
            onChange={handleChange}
        />
    )
}

export default TgfDateRangePicker;