import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import ComponentBuilder from '../../../core/ComponentBuilder';
import * as Yup from 'yup';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import SelectCarrierAlert from './SelectCarrierAlert';
import CarrierToolBar from '../CarrierToolBar';
import PendingCarrierSetupCard from './PendingCarrierSetupCard';
import CarrierNav from '../CarrierNav';
import renderSetupCarrierFrom from './modals/SetupCarrier';

import FormFactor from "../../../core/FormFactor/FormFactor";
import AppDialog from "../../../core/components/AppDialog/AppMuiDialog";
import hasCarrierPrivilege from '../../../hubs/persona/selectors/hasCarrierPrivilege';
import "./CarrierListingPage.scss";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Carrier.Pending.Load';
const DeleteProcessName = 'Carrier.Pending.Delete';
const SaveProcessName = 'Carrier.Pending.Save';

const CarrierListingPage = (props) => {

  const {
    associateId,
    carriers,
    carrierCount,
    deletePendingCarrierContent,
    dispose,
    history,
    hasCarrierPrivilege,
    load,
    saveNewPendingCarrier,
    sendSnackbarMessage
  } = props;

  usePageTitle("TGF: Carriers Page");

  const [draftSetupCarrier, setDraftSetupCarrier] = React.useState(null);
  const [deletePendingCarrier, setDeletePendingCarrier] = React.useState(null);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = useState([[ 'mcNumber', 'desc' ]]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('mcNumber');

  React.useEffect(() => {
    load(offset, limit, sort);

    return () => {
      dispose();
    }
  }, [offset, limit, sort, dispose, load]);

  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  }

  const handleSelectCarrier = (selectedCarrier) => {
    if (selectedCarrier && selectedCarrier.mcNumber)
      history.replace(`/carrier/${selectedCarrier.mcNumber}/compliance`);
  };

  const handleDeletePendingCarrierClick = (content) => {
    setDeletePendingCarrier(content);
  };

  const handleDeletePendingCarrierClose = (e) =>
    setDeletePendingCarrier(null);

  const handleDeletePendingCarrierConfirm = (e) => {
    setDeletePendingCarrier(null);
    deletePendingCarrierContent(deletePendingCarrier.mcNumber, offset, limit, sort)
      .then(() => {
        sendSnackbarMessage({content: 'Pending carrier deleted.'});
      });
  };

  const deletePendingCarrierAction = [
    {
      title: 'Cancel',
      action: handleDeletePendingCarrierClose
    },
    {
      title: 'Confirm',
      action: handleDeletePendingCarrierConfirm
    }
  ];

  const handleSetupCarrierClick = () => {
    setDraftSetupCarrier({
      mcNumber: null,
      carrierLabel: null,
    });
  };

  const handleNewCarrierSetupClose = (e) => {
    setDraftSetupCarrier(null);
  }

  const handleNewCarrierSetupSubmit = (values, formFactor) => {
    setDraftSetupCarrier(null);
    saveNewPendingCarrier(values.mcNumber, values.carrierLabel, associateId, offset, limit, sort)
      .then(() => {
        sendSnackbarMessage({content: 'Pending carrier saved.'});
      });
  }

  const initialValues = {
    ...draftSetupCarrier,
    sort: sort,
    onClose: handleNewCarrierSetupClose,
    handlePageChange
  };

  let handleAddCarrierClick = null;
  if (hasCarrierPrivilege) {
    handleAddCarrierClick = () => {
      history.replace('/carrier/new/details');
    };
  }

  return (
    <FullWidthLayout leftComponent={CarrierNav} className="carrier-styles">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CarrierToolBar
            title={'Carriers Page'}
            onSelectCarrier={handleSelectCarrier}
            onSetupCarrierClick={handleSetupCarrierClick}
            onAddCarrierClick={handleAddCarrierClick}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectCarrierAlert />
        </Grid>
        <Grid item xs={12}>
          <PendingCarrierSetupCard
            count={carrierCount}
            data={carriers}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
            onDeleteClick={handleDeletePendingCarrierClick}
          />
        </Grid>
      </Grid>
      {
        draftSetupCarrier && <FormFactor
          initialValues={initialValues}
          schema={SetupCarrierSchema}
          onSubmit={handleNewCarrierSetupSubmit}>
          {renderSetupCarrierFrom}
        </FormFactor>
      }
      {
        deletePendingCarrier && <AppDialog
          title={'Pending Carrier Deletion'}
          open={true}
          onClose={handleDeletePendingCarrierClose}
          width='sm'
          actionButtons={deletePendingCarrierAction}>
          Confirm that you want to delete pending carrier [{deletePendingCarrier.mcNumber} - {deletePendingCarrier.label}]?
        </AppDialog>
      }
    </FullWidthLayout>
  )
}

const SetupCarrierSchema = Yup.object().shape({
  mcNumber: Yup.string()
    .nullable()
    .trim('MC Number is required.')
    .required('MC Number is required.'),
  carrierLabel: Yup.string()
    .nullable()
    .trim('Carrier Label is required.')
    .required('Carrier Label is required.')
});

export default ComponentBuilder
  .wrap(CarrierListingPage)
  .stateToProps((state, ownProps) => ({
    associateId: state.persona.modifyingAssociate.id,
    carriers: state.carrier.pending.carriers,
    carrierCount: state.carrier.pending.carrierCount,
    hasCarrierPrivilege: hasCarrierPrivilege(state),
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.carrier.pending.load({}, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.carrier.pending.dispose())
      },
      async deletePendingCarrierContent(mcNumber, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(DeleteProcessName));
        dispatch(await shell.actions.carrier.pending.deletePendingCarrier(mcNumber));
        dispatch(await shell.actions.carrier.pending.load({}, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(DeleteProcessName));
      },
      async saveNewPendingCarrier(mcNumber, carrierLabel, associateId, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.carrier.pending.savePendingCarrier(mcNumber, carrierLabel, associateId));
        dispatch(await shell.actions.carrier.pending.load({}, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();