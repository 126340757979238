import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const deleteCompanyContact = async (shell, companyId, contactId) => {

  // eslint-disable-next-line no-unused-vars
  const result = await shell.gateway.deleteCompanyContact(companyId, contactId);

  const contacts = await shell.gateway.getCompanyContacts(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyContactDeleted,
    contacts
  };

};

export default ActionBuilder
  .for(deleteCompanyContact)
  .build();