import { Button, Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TgfDialog from "../../../../../../../core/kendo/dialog/TgfDialog";
import TgfDropDownList from "../../../../../../../core/kendo/form/inputs/TgfDropDownList";
import TgfTextArea from "../../../../../../../core/kendo/form/inputs/TgfTextArea";
import useFieldValidator from "../../../../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../../../../crm/components/inputs/FieldError";
import { associateNoteValidationSchema } from "../Validation/associateNoteValidationSchema";

const NewAssociateNoteModal = (props) => {
    const personalDispatch = useDispatch();
    const actingAssociate = useSelector(state => state.persona.associate);
    const [isValid, setIsValid] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
    const [associateNoteTypes, setAssociateNoteTypes] = useState([]);

    const [formData, setFormData] = useState({
        typeId: null,
        content: null
    });

    const loadAssociateNoteTypes = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAssociateNoteTypes'));
            const result = await window.shell.actions.admin.manageUsers.loadAssociateNoteTypes();
            setAssociateNoteTypes(result.associateNoteTypes);
        } catch (e) {
            console.log(e);

        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAssociateNoteTypes'));
        }
    };

    const handleSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAssociateNote'));

            const note = {
                id: 0,
                typeId: formData.typeId,
                content: formData.content,
                associateId: props.associateId,
                createdByAssociateId: actingAssociate.id,
                createDate: new Date()
            };

            await window.shell.actions.admin.manageUsers.saveAssociateNote(note);

            setIsDirty(false);
            props.onCloseDialog();
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'Associate Note Saved' }));
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save Associate Note',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveAssociateNote'));
        }
    };

    useEffect(() => {
        loadAssociateNoteTypes();
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value ? e.target.value : null
        });
        setIsDirty(true);
    };

    const handleAssociateNoteTypeChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value?.id
        });
    };

    const fieldErrors = useFieldValidator(associateNoteValidationSchema, formData, setIsValid);

    return (
        <TgfDialog
            title={`${props?.cellData?.id !== null ? "Edit" : "Add"} Collection Contact`}
            onClose={props.onCloseDialog}
            width={600}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Label editorId={"typeId"}>Category</Label>
                    <TgfDropDownList
                        id={"typeId"}
                        name={"typeId"}
                        data={associateNoteTypes}
                        textField={"name"}
                        dataItemKey={"id"}
                        value={formData.typeId}
                        onChange={handleAssociateNoteTypeChange}
                    />
                    <FieldError >{fieldErrors.typeId}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <Label editorId={"content"}>Note</Label>
                    <TgfTextArea
                        id={"content"}
                        name={"content"}
                        value={formData.content}
                        onChange={handleChange}
                        rows={6}
                        autoFocus
                    />
                    <FieldError >{fieldErrors.content}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        type={"button"}
                        disabled={Boolean(!isValid || !isDirty)}
                        onClick={handleSave}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </TgfDialog>
    );
};

export default NewAssociateNoteModal;
