import Core from '@atomos/core';
/**
 * Identifies whether a mcNumber represents
 * a new Carrier.
 * @param {number|undefined|null} mcNumber - The carrier mcNumber.
 * @return {boolean}
 */
const isNewCarrier = mcNumber => Core.Utils.isUndefined(mcNumber) ||
  mcNumber === 0;

export default isNewCarrier;