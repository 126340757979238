import React from 'react';
import Core from '@atomos/core';
import FormFactorContext from './FormFactorContext';
import FormFactorEvents from './FormFactorEvents';

/**
 * FormFactor is a React context provider
 * that helps manage form state. It takes common form
 * concepts and wraps them in an opinionated way.
 * @param {object} [props.initialValues] - Optional initial values to set for the form.
 * @param {object} [props.schema] - Optional Yup validation schema for validating the form `values`.
 * @constructor
 */
class FormFactor extends React.Component {

  constructor(props) {
    super(props);

    this.changes = [];

    this.didMount = false;

    const startingState = {
      values: props.initialValues || {},
      touched: {},
      errors: Core.Utils.merge({}, this.validate(props.initialValues)),
      customErrors: {},
      supportingValues: props.supportingValues || {},
      isSubmitting: false,
      isDirty: false
    };

    startingState.isValid = Object.keys(startingState.errors).length === 0;

    this.state = startingState;

    // Bind methods to pass through provider context.
    const bind = (key) =>
      this[key] = this[key].bind(this);

    [
      'validate',
      'getFieldValue',
      'setFieldValue',
      'setFieldValues',
      'getFieldError',
      'setFieldError',
      'clearFieldError',
      'setFieldTouched',
      'setIsSubmitting',
      'resetForm',
      'submitForm',
      'addArrayValue',
      'replaceArrayValue',
      'removeArrayValue',
      'setSupportingValue',
      'getSupportingValue'
    ].forEach(bind);

    this.processChangeEvents = Core.Utils.debounce(this.processChangeEvents.bind(this), 5);

  }

  processChangeEvents() {
    const changeEvents = this.changes.slice();
    this.changes = [];
    changeEvents.forEach(changeEvent =>
      this.processChangeEvent(changeEvent));
  }

  processChangeEvent(changeEvent) {
    const rules = (this.props.rules || []);
    rules.forEach(rule =>
      rule.invoke(changeEvent, this.buildContext()));
  }

  /**
   * Enqueues the changEvent to be processed
   * by the rule processing cycle.
   * @param {ValueAssignmentEvent|ValueTouchedEvent|FormResetEvent|ArrayValueAddedEvent|ArrayValueRemovedEvent|ArrayValueReplacedEvent} changeEvent - The change that took place.
   */
  runRuleCycle(changeEvent) {

    if (Array.isArray(changeEvent)) {
      changeEvent.forEach(e => this.changes.push(e));
    }
    else {
      this.changes.push(changeEvent);
    }

    this.processChangeEvents();
  }

  componentDidMount() {
    this.didMount = true;
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const initialValuesChanged = prevProps.initialValues !== this.props.initialValues ||
      prevProps.supportingValues !== this.props.supportingValues;

    if (initialValuesChanged) {
      this.resetForm(this.props.initialValues, this.props.supportingValues);
    }
  }

  /**
   * Schema validator that expects `schema` to be a Yup object.
   * @param {object} values - The current values in the form.
   */
  validate(values) {

    const errors = {};

    if (this.props.schema) {

      try {
        this.props.schema.validateSync(values, {abortEarly: false});
      } catch (error) {

        if (error.inner) {
          // abortEarly gives an error with an `inner`
          // property containing all the errors found.
          // Attach them using their paths to build
          // a similar structure as values.
          error.inner.forEach(err => {
            Core.Utils.set(errors, err.path, err.message);
          });
        }
        else {
          console.error(error);
        }

      }

    }

    return errors;
  }

  /**
   * Sets a field's value by path spec.
   * @param {string} pathSpec - Path to where the value will be assigned.
   * @param {*} value - The value to assign.
   */
  setFieldValue(pathSpec, value) {
    return this.setFieldValues([[pathSpec, value]]);
  }

  setFieldValues(valueEntries) {

    return new Promise((resolve, reject) => {

      if (!Array.isArray(valueEntries) || valueEntries.length === 0) {
        reject(new Error(`[valueEntries] must be an array with pathSpec/value pairs.`));
      }

      this.setState(prevState => {

        // Make values immutable each time.
        // NOTE: This may need to change in the future
        //       if performance becomes an issue.
        const processedValues = Core.Utils.cloneDeep(prevState.values);

        let valueChanged = false;

        const events = valueEntries.map(([pathSpec, value]) => {

          // Capture the existing value.
          const oldValue = Core.Utils.get(processedValues, pathSpec);

          // Update the field to the new value.
          Core.Utils.set(processedValues, pathSpec, value);

          // If any single field causes change, capture that so we can mark isDirty later.
          if (!valueChanged)
            valueChanged = !Core.Utils.isEqual(oldValue, value);

          return new FormFactorEvents.ValueAssignmentEvent(pathSpec, value, oldValue);
        });

        const newState = {};

        // Update state with the change in values.
        newState.values = processedValues;

        // Validate and capture errors.
        newState.errors = Core.Utils.merge({}, this.validate(processedValues), prevState.customErrors);

        // Flag as invalid if necessary.
        newState.isValid = Object.keys(newState.errors).length === 0;

        // Mark the form dirty if it was already marked
        // dirty by something else, or if the old and new
        // values do not match.
        newState.isDirty = prevState.isDirty || valueChanged;

        // Inform the rule system that a value assignment has occurred.
        this.runRuleCycle(events);

        return newState;

      }, () => resolve());
    });
  }

  /**
   * Retrieves a field's value.
   * @param {string} pathSpec - The path to where the value will be retrieved.
   * @returns {*} The value found or undefined.
   */
  getFieldValue(pathSpec) {
    return Core.Utils.get(this.state.values, pathSpec);
  }

  /**
   * Sets an error message for the given path spec.
   * @param {string} pathSpec - The path to where the message is to be placed.
   * @param {string} errorMessage - The error message to assigne.
   */
  setFieldError(pathSpec, errorMessage) {

    this.setState(prevState => {

      const processedErrors = Core.Utils.cloneDeep(prevState.customErrors);

      const newState = {};

      if (errorMessage === undefined) {
        newState.customErrors = Core.Utils.omit(processedErrors, pathSpec);
      }
      else {
        newState.customErrors = Core.Utils.set(processedErrors, pathSpec, errorMessage);
      }

      newState.errors = Core.Utils.merge({}, prevState.errors, newState.customErrors);

      newState.isValid = Object.keys(newState.errors).length === 0;

      return newState;

    });

  }

  /**
   * Clears an error for a given path.
   * @param {string} pathSpec - The path to clear.
   */
  clearFieldError(pathSpec) {
    this.setFieldError(pathSpec, undefined);
  }

  /**
   * Retrieves an error for a field should it exist.
   * @param {string} pathSpec - The path for the field.
   * @returns {string|undefined} The error if it has one.
   */
  getFieldError(pathSpec) {
    return Core.Utils.get(this.state.errors, pathSpec);
  }

  /**
   * Marks a field as having been touched by a user.
   * @param {string} pathSpec - Path for the touched field.
   */
  setFieldTouched(pathSpec) {

    this.setState(prevState => {

      const processedTouched = Core.Utils.cloneDeep(prevState.touched);

      Core.Utils.set(processedTouched, pathSpec, true);


      const newState = {};

      newState.touched = processedTouched;

      // Validate and capture errors.
      newState.errors = Core.Utils.merge({}, this.validate(prevState.values), prevState.customErrors);

      // Flag as invalid if necessary.
      newState.isValid = Object.keys(newState.errors).length === 0;

      // Inform the rule system that a value touch has occurred.
      this.runRuleCycle(new FormFactorEvents.ValueTouchedEvent(pathSpec));

      return newState;

    });

  }

  setSupportingValue(pathSpec, value) {
    this.setState(prevState => {

      const processedValues = Core.Utils.cloneDeep(prevState.supportingValues);

      Core.Utils.set(processedValues, pathSpec, value);

      return {
        supportingValues: processedValues
      };

    });
  }

  getSupportingValue(pathSpec) {
    return Core.Utils.get(this.state.supportingValues, pathSpec);
  }

  setIsSubmitting(isSubmitting) {
    this.setState(prevState => {
      return { isSubmitting };
    });
  }

  /**
   * Resets the form to a new state.  If `values` is not specified
   * the `initialValues` provided to FormFactor will be used.
   * @param {object} [values] - Optional values to reset the FormFactor to.
   */
  resetForm(values, supportingValues) {

    this.setState((prevState, currProps) => {

      const newState = {};

      newState.values = Core.Utils.cloneDeep(values || currProps.initialValues);

      newState.touched = {};

      newState.customErrors = {};

      newState.supportingValues = Core.Utils.cloneDeep(supportingValues || currProps.supportingValues) || {};

      newState.errors = this.validate(newState.values);

      newState.isSubmitting = false;

      newState.isValid = Object.keys(newState.errors).length === 0;

      newState.isDirty = false;

      // Inform the rule system that a form reset occurred.
      this.runRuleCycle(new FormFactorEvents.FormResetEvent());

      return newState;

    });

  }

  /**
   * Submits the forms values for processing by the
   * provided `onSubmit` delegate.  The `onSubmit`
   * delegate must return a promise to ensure
   * submitting works as expected.
   */
  async submitForm(e) {

    if (e && e.preventDefault)
      e.preventDefault();

    this.setIsSubmitting(true);

    this.props.onSubmit(this.state.values, this.buildContext());

    this.setState(prevState => {
      return {
        isDirty: false,
        touched: {}
      };
    });

    this.setIsSubmitting(false);

  }

  addArrayValue(pathSpec, value) {

    this.setState(prevState => {

      const processedValues = Core.Utils.cloneDeep(prevState.values);
      const targetArray = Core.Utils.get(processedValues, pathSpec) || [];

      targetArray.push(value);

      Core.Utils.set(processedValues, pathSpec, targetArray);

      const newState = {};

      // Update state with the change in array entries.
      newState.values = processedValues;

      // Validate and capture errors,
      newState.errors = Core.Utils.merge({}, this.validate(processedValues), prevState.customErrors);

      // Flag as invalid if necessary.
      newState.isValid = Object.keys(newState.errors).length === 0;

      // Mark it dirty.
      newState.isDirty = true;

      // Inform the rule system that an array value was added.
      this.runRuleCycle(new FormFactorEvents.ArrayValueAddedEvent(pathSpec, value));

      return newState;
    });
  }

  replaceArrayValue(pathSpec, destIndex, value) {
    this.setState(prevState => {

      const processedValues = Core.Utils.cloneDeep(prevState.values);

      const targetArray = Core.Utils.get(processedValues, pathSpec) || [];

      if (targetArray.length <= destIndex) {
        throw new Error(`Index out of range to replace array value. Array has length [${targetArray.length}] but received index of [${destIndex}].`);
      }

      const oldValue = targetArray[destIndex];

      targetArray[destIndex] = value;

      Core.Utils.set(processedValues, pathSpec, targetArray);

      const newState = {};

      // Update state with the change in array entries.
      newState.values = processedValues;

      // Validate and capture errors,
      newState.errors = Core.Utils.merge({}, this.validate(processedValues), prevState.customErrors);

      // Flag as invalid if necessary.
      newState.isValid = Object.keys(newState.errors).length === 0;

      // Mark it dirty.
      newState.isDirty = true;

      // Inform the rule system that an array value was replaced.
      this.runRuleCycle(new FormFactorEvents.ArrayValueReplacedEvent(pathSpec, destIndex, value, oldValue));

      return newState;

    });
  }

  removeArrayValue(pathSpec, offendingIndex) {
    this.setState(prevState => {

      const processedValues = Core.Utils.cloneDeep(prevState.values);

      const targetArray = Core.Utils.get(processedValues, pathSpec) || [];

      const oldValue = targetArray[offendingIndex];

      Core.Utils.set(processedValues, pathSpec, targetArray.filter((item, index) => index !== offendingIndex));

      const newState = {};

      // Update state with the change in array entries.
      newState.values = processedValues;

      // Validate and capture errors,
      newState.errors = Core.Utils.merge({}, this.validate(processedValues), prevState.customErrors);

      // Flag as invalid if necessary.
      newState.isValid = Object.keys(newState.errors).length === 0;

      // Mark it dirty.
      newState.isDirty = true;

      // Inform the rule system that an array item was removed.
      this.runRuleCycle(new FormFactorEvents.ArrayValueRemovedEvent(pathSpec, offendingIndex, oldValue));

      return newState;

    });
  }

  buildContext() {
    return {
      values: this.state.values,
      originalValues: this.props.initialValues,
      errors: this.state.errors,
      touched: this.state.touched,
      supportingValues: this.state.supportingValues,
      isSubmitting: this.state.isSubmitting,
      isValid: this.state.isValid,
      isDirty: this.state.isDirty,
      getFieldError: this.getFieldError,
      setFieldError: this.setFieldError,
      clearFieldError: this.clearFieldError,
      getFieldValue: this.getFieldValue,
      setFieldValue: this.setFieldValue,
      setFieldValues: this.setFieldValues,
      setFieldTouched: this.setFieldTouched,
      setIsSubmitting: this.setIsSubmitting,
      resetForm: this.resetForm,
      submitForm: this.submitForm,
      addArrayValue: this.addArrayValue,
      replaceArrayValue: this.replaceArrayValue,
      removeArrayValue: this.removeArrayValue,
      setSupportingValue: this.setSupportingValue,
      getSupportingValue: this.getSupportingValue
    };
  }

  render() {

    const contextValue = this.buildContext();

    return (
      <FormFactorContext.Provider value={contextValue}>
        <form onSubmit={this.submitForm}>
          <FormFactorContext.Consumer>
            {(contextData) => this.props.children(contextData)}
          </FormFactorContext.Consumer>
        </form>
      </FormFactorContext.Provider>
    );

  }

}

export default FormFactor;