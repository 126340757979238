import ActionTypes from '../../../ActionTypes';

const companyCreditLimitChanges = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.Disposed:
      return null;
    case ActionTypes.AddressBook.Modification.CompanyCreditLimitChangesLoaded:
      return action.creditLimitChanges;
    default:
      return state;
  }
};

export default companyCreditLimitChanges;