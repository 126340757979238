import ActionTypes from "../../../ActionTypes";

const historyCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.Carrier.Modification.Disposed:
      return 0;
    case ActionTypes.Carrier.Modification.CarrierHistory.Loaded:
      return action.count;
    default:
      return state;
  }
};

export default historyCount;