import ActionTypes from "../../../ActionTypes";

const associates = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Admin.CommissionSummary.Disposed:
      return [];
    case ActionTypes.Admin.CommissionSummary.AssociatesLoaded:
      return action.associates;
    default:
      return state;
  }
};

export default associates;