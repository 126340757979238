import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';
import ShipmentDefaultThirdParty from "../../shipment/ShipmentDefaultThirdParty";

const loadTallgrassBillingCompany = async (shell, companyCategoryTypeId) => {

  const { companies } = await shell.gateway.searchCompanies({
    filter: {
      companyName: ShipmentDefaultThirdParty.name,
      categoryTypeId: companyCategoryTypeId
    }
  });
  if (companies.length !== 1)
    throw new Error(`Unable to find default billing company with name [${ShipmentDefaultThirdParty.name}].`);

  return {
    type: ActionTypes.AddressBook.TallgrassBillingCompany.Loaded,
    company: companies[0]
  };

};

export default ActionBuilder
  .for(loadTallgrassBillingCompany)
  .build();




