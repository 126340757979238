import AppMuiCard from "../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from 'react';
import AppSwitch from "../../../../core/components/inputs/AppSwitch/AppMuiSwitch";

const AdditionalDuplicationOptions = (props) => {

    const FormatDisplay = (props) => {
        return (
            <span className={props.isOn ? "mapping-item-on" : "mapping-item-off"}>
              <strong>
                  {props.value}
              </strong>
          </span>
        );
    };

    const FormatElement = (props) => {
        return (
            <Grid item xs={6} className="mapping-item-left">
                <div className="mapping-switch">
                    <AppSwitch
                        name={props.element.label}
                        checked={props.element.mapValue}
                        onChange={props.handleChange}
                        disabled={props.element.valueToMap === null || props.disabled}
                        item={props.element}
                    />
                    <strong> {props.element.label}: </strong>
                    <FormatDisplay value={props.element.valueToMap} isOn={props.element.mapValue}/>
                </div>
            </Grid>
        );
    };

    const handleMappingChange = (e, checked) => {
        for (let property in props.mappingConfig) {
            const item = props.mappingConfig[property].find(({label}) => label === e.currentTarget.name);

            if (item) {
                item.mapValue = checked;
            }
        }
        const updatableConfig = JSON.parse(JSON.stringify(props.mappingConfig));
        props.setMappingConfig(updatableConfig);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AppMuiCard title={'Times and Costs'}>
                    <Grid container className="mapping-container">
                        {
                            props.mappingConfig.timeAndCost.map((item) => {
                                return (
                                    <FormatElement
                                        element={item}
                                        key={item.label}
                                        handleChange={handleMappingChange}
                                        disabled={false}
                                    />
                                );
                            })
                        }
                    </Grid>
                </AppMuiCard>
            </Grid>
            <Grid item xs={12}>
                <AppMuiCard
                    title={props.carrierGoodToGo ? 'Carrier/Carrier Contact Detail' : <><span>Carrier/Carrier Contact Detail</span><strong
                        className="mapping-warning"> - Carrier is not good to go. Cannot assign carrier.</strong></>}>
                    <Grid container className="mapping-container">
                        {
                            props.mappingConfig.CarrierContactDetail.map((item) => {
                                return (
                                    <FormatElement
                                        element={item}
                                        handleChange={handleMappingChange}
                                        disabled={!props.carrierGoodToGo}
                                    />
                                );
                            })
                        }
                    </Grid>
                </AppMuiCard>
            </Grid>
            <Grid item xs={12}>
                <AppMuiCard title={props.carrierGoodToGo ? 'Driver Detail' : <>
                    <span>Carrier/Carrier Contact Detail</span><strong className="mapping-warning"> - Carrier is not
                    good to go. Cannot assign carrier.</strong></>}>
                    <Grid container className="mapping-container">
                        {
                            props.mappingConfig.DriverDetail.map((item) => {
                                return (
                                    <FormatElement
                                        element={item}
                                        handleChange={handleMappingChange}
                                        disabled={!props.carrierGoodToGo}
                                    />
                                );
                            })
                        }
                    </Grid>
                </AppMuiCard>
            </Grid>
            <Grid item xs={12}>
                <AppMuiCard title={'Reference Numbers'}>
                    <Grid container className="mapping-container">
                        {
                            props.mappingConfig.ReferenceNumbers.map((item) => {
                                return (
                                    <FormatElement
                                        element={item}
                                        handleChange={handleMappingChange}
                                        disabled={false}
                                    />
                                );
                            })
                        }
                    </Grid>
                </AppMuiCard>
            </Grid>
        </Grid>
    );
};

export default AdditionalDuplicationOptions;
