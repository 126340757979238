
import React from "react";
import computeColor from "./computeColor";


// Currency formatter
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const TeraCrunchDialogTable = ({activeRecord}) => {

  const {
    id,
    inputAddress,
    inputAddress2,
    inputAmount,
    inputCarrierName,
    inputCity,
    inputRemitName,
    inputState,
    inputZip,
    targetAddress,
    targetAddress2,
    targetAmount,
    targetCarrierName,
    targetCity,
    targetRemitName,
    targetState,
    targetZip,
  } = activeRecord;


  return (
    <table className={'qa-dialog-table'}>
      <tr>
        <td rowSpan="2"></td>
      </tr>
      <tr>
        {/*NOTE the separation / order of the columns!*/}
        {/*| TeraCrunch |    TGF    |*/}
        <th scope="col" style={{backgroundColor: "#0096d1", color: "white"}}>TeraCrunch</th>
        <th scope="col" style={{backgroundColor: "#438a01", color: "white"}}>TGF</th>
      </tr>
      <tr>
        <th scope="row">Carrier Name</th>
        <td style={{backgroundColor: computeColor("inputCarrierName", activeRecord)}}>{inputCarrierName}</td>
        <td style={{backgroundColor: computeColor("targetCarrierName", activeRecord)}}>{targetCarrierName}</td>
      </tr>
      <tr>
        <th scope="row">Remit Name</th>
        <td style={{backgroundColor: computeColor("inputRemitName", activeRecord)}}>{inputRemitName}</td>
        <td style={{backgroundColor: computeColor("targetRemitName", activeRecord)}}>{targetRemitName}</td>
      </tr>
      <tr>
        <th scope="row">Address 1</th>
        <td style={{backgroundColor: computeColor("inputAddress", activeRecord)}}>{inputAddress}</td>
        <td style={{backgroundColor: computeColor("targetAddress", activeRecord)}}>{targetAddress}</td>
      </tr>
      <tr>
        <th scope="row">Address 2</th>
        <td style={{backgroundColor: computeColor("inputAddress2", activeRecord)}}>{inputAddress2}</td>
        <td style={{backgroundColor: computeColor("targetAddress2", activeRecord)}}>{targetAddress2}</td>
      </tr>
      <tr>
        <th scope="row">City</th>
        <td style={{backgroundColor: computeColor("inputCity", activeRecord)}}>{inputCity}</td>
        <td style={{backgroundColor: computeColor("targetCity", activeRecord)}}>{targetCity}</td>
      </tr>
      <tr>
        <th scope="row">State</th>
        <td style={{backgroundColor: computeColor("inputState", activeRecord)}}>{inputState}</td>
        <td style={{backgroundColor: computeColor("targetState", activeRecord)}}>{targetState}</td>
      </tr>
      <tr>
        <th scope="row">Zip</th>
        <td style={{backgroundColor: computeColor("inputZip", activeRecord)}}>{inputZip}</td>
        <td style={{backgroundColor: computeColor("targetZip", activeRecord)}}>{targetZip}</td>
      </tr>
      <tr>
        <th scope="row">Matched Cost</th>
        <td style={{backgroundColor: computeColor("inputAmount", activeRecord)}}>{formatter.format(inputAmount)}</td>
        <td style={{backgroundColor: computeColor("targetAmount", activeRecord)}}>{formatter.format(targetAmount)}</td>
      </tr>
    </table>
  )
}

export default TeraCrunchDialogTable;