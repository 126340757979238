import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder2';

const dispose = () => {
  return {
    type: ActionTypes.Reporting.MissingCreditApp.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();