import React from "react";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";

const RenderCustomerAgent = ({selectedCustomer, loadCustomerAgent}) => {
  const [shipmentOwner, setShipmentOwner] = React.useState(null);
  const [shipmentOwnerId, setShipmentOwnerId] = React.useState(null);

  const loadShipmentOwner = async () => {
    const customerAgent = await loadCustomerAgent(selectedCustomer.associateId);
    return customerAgent;
  }

  React.useEffect(() => {
    if(selectedCustomer) {
      loadShipmentOwner().then(((customerAgent) => {
        setShipmentOwner(customerAgent.fullName)
        setShipmentOwnerId(customerAgent.id);
      }));
    }
  }, [selectedCustomer])

  return (
    <>
      {shipmentOwner ? <AgentTooltipComponent content={''} agentId={shipmentOwnerId}><label style={{padding: 0, margin: 2}}>Agent: <b>{shipmentOwner}</b></label></AgentTooltipComponent> : null}
    </>
  )
}

export default RenderCustomerAgent
