import ActionTypes from '../../../ActionTypes';

const laneLookupCount = (state = 0, action) => {
  switch(action.type) {
    case ActionTypes.Reporting.LaneLookup.Disposed:
      return 0;
    case ActionTypes.Reporting.LaneLookup.Loaded:
      return action.count;
    default:
      return state;
  }
};

export default laneLookupCount;