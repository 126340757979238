import Core from '@atomos/core';
import Permissions from './Permissions';
import RoleNames from '../../../hubs/persona/RoleNames';
import StatusTypeNames from '../../../hubs/shipment/StatusTypeNames';
import BuildMatrix from './BuildMatrix';
import AceDeciders from './AceDeciders';
import QueenJackDeciders from './QueenJackDeciders';

import DetailsPagePermissions from './DetailsPagePermissions';
import LoadContentsPagePermissions from './LoadContentsPagePermissions';
import LoadTrackingPagePermissions from './LoadTrackingPagePermissions';
import DocumentsPagePermissions from './DocumentsPagePermissions';
import FinancialPagePermissions from './FinancialPagePermissions';
import MultipleStopsPagePermissions from './MultipleStopsPagePermissions';

const PermissionSets = [
  DetailsPagePermissions,
  LoadContentsPagePermissions,
  LoadTrackingPagePermissions,
  DocumentsPagePermissions,
  FinancialPagePermissions,
  MultipleStopsPagePermissions,
];

const AceMatrix = BuildMatrix(
  Object.values(StatusTypeNames),
  PermissionSets,
  AceDeciders
);

const QueenJackMatrix = BuildMatrix(
  Object.values(StatusTypeNames),
  PermissionSets,
  QueenJackDeciders
);

class MatrixFacade {
  constructor(matrix) {
    this.matrix = matrix;
  }

  formatPath(roleName, statusName, pageName, elementType, elementName) {
    return `${roleName}.${statusName}.${pageName}.${elementType}.${elementName}`;
  }

  canModifyField(roleName, statusName, pageName, fieldName) {
    return Core.Utils.get(this.matrix, this.formatPath(roleName, statusName, pageName, 'field', fieldName)) === Permissions.Action;
  }

  canViewField(roleName, statusName, pageName, fieldName) {
    return [Permissions.Action, Permissions.View].includes(Core.Utils.get(this.matrix, this.formatPath(roleName, statusName, pageName, 'field', fieldName)));
  }

  canPerformAction(roleName, statusName, pageName, actionName) {
    return Core.Utils.get(this.matrix, this.formatPath(roleName, statusName, pageName, 'action', actionName)) === Permissions.Action;
  }

  canViewAction(roleName, statusName, pageName, actionName) {
    return [Permissions.Action, Permissions.View].includes(Core.Utils.get(this.matrix, this.formatPath(roleName, statusName, pageName, 'action', actionName)));
  }

}

export default new MatrixFacade({
  [RoleNames.Ace]: AceMatrix,
  [RoleNames.Queen]: QueenJackMatrix,
  [RoleNames.Jack]: QueenJackMatrix,
});