import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const loadAssociateCommissionAdjustments = async (shell, associateId, commissionDate, offset, limit, sort) => {

  const {adjustments, count} = await shell.gateway.getAssociatePayPeriodCommissionAdjustments(
    associateId,
    {
      commissionDate,
      offset,
      limit,
      sort
    }
  );

  return {
    type: ActionTypes.CommissionCenter.PayPeriodCommissionAdjustmentLoaded,
    payPeriodAdjustments: adjustments,
    payPeriodAdjustmentCount: count
  }

};

export default ActionBuilder
  .for(loadAssociateCommissionAdjustments)
  .build();