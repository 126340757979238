import { Grid } from "@material-ui/core";
import React from "react";
import AppDialog from "../../../../../core/components/AppDialog/AppMuiDialog";
import AppText from "../../../../../core/components/text/AppText";
import TeraCrunchDialogTable from "../../includes/TeraCrunchDialogTable";
import AppDateTimeText from "../../../../../core/components/text/AppDateTimeText";
import "./ClosedDetailsDialog.scss";
import { Link } from "react-router-dom";

const ClosedDetailsDialog = (props) => {
  const {
    listAutomationDocuments,
    activeRecord,
    onClosedClick,
    fetchHardFailReasons,
    checkIfShipmentExistsInCrm,
  } = props;

  const [hardFailReasons, setHardFailReasons] = React.useState(null);
  const [shipmentExistsInCrm, setShipmentExistsInCrm] = React.useState(false);

  React.useEffect(() => {
    checkIfShipmentExistsInCrm(activeRecord.bolNumber).then((data) => {
      data.count !== 0
        ? setShipmentExistsInCrm(true)
        : setShipmentExistsInCrm(false);
    });
  }, []);

  React.useEffect(() => {
    fetchHardFailReasons(activeRecord.bolNumber).then((data) => {
      data.length !== 0 ? setHardFailReasons(data) : setHardFailReasons(null);
    });
  }, []);

  const actions = [
    {
      title: "Close",
      action: onClosedClick,
    },
  ];

  const [documents, setDocuments] = React.useState([]);

  if (documents.length === 0) {
    listAutomationDocuments(activeRecord.bolNumber).then((data) => {
      setDocuments(data.documents);
    });
  }

  const conditionalShipmentLink = () => {
    return (
      <>
        {shipmentExistsInCrm ? (
          <Link
            to={`/shipment/${activeRecord.bolNumber}/details`}
            target={"_blank"}
          >
            {activeRecord.bolNumber}
          </Link>
        ) : (
          activeRecord.bolNumber
        )}
      </>
    );
  };

  const handleOpenDownloadFileClick = (document, shouldOpen) => {
    const a = window.document.createElement("a");

    shouldOpen ? (a.target = "_blank") : (a.download = document.filename);

    a.href = `/api/automation/${document.id}/document`;
    a.click();
    a.remove();
  };

  return (
    <AppDialog
      title="Closed Details"
      width="md"
      open={true}
      actionButtons={actions}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid style={{ padding: 0 }} item>
              <p style={{ padding: 0 }} className={"qa-dialog-shipment-p"}>
                TeraCrunch Shipment #{conditionalShipmentLink()}
              </p>
            </Grid>
            <Grid item xs={12}>
              <AppText value={"RECEIVED DOCUMENTS"} />
              {documents.map((doc) => (
                <Grid item xs={12} key={doc.id}>
                  <a
                    onClick={handleOpenDownloadFileClick.bind(this, doc, true)}
                  >
                    {doc.filename}
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <TeraCrunchDialogTable activeRecord={activeRecord} />
          <br />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3} >
          {hardFailReasons && (
            <div style={{ color: "red", paddingRight: 8 }}>
              <h3>Hard fail reasons</h3>
              {hardFailReasons.map((fail) => {
                return (
                  <>
                    <p style={{ margin: "4px 8px" }}>{fail}</p>
                    <hr
                      style={{
                        margin: "1px 16px 6px 1px",
                        height: 1,
                        background: "lightgray",
                        border: "none",
                      }}
                    />
                  </>
                );
              })}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          {activeRecord && (
            <div>
              <table style={{}} className={"closed-by-table"}>
                <tr>
                  <th scope="col" style={{ backgroundColor: "#f0f0f0" }}>
                    Closed By:
                  </th>
                  <th scope="col" style={{ backgroundColor: "#f0f0f0" }}>
                    Closed Date/Time
                  </th>
                  <th scope="col" style={{ backgroundColor: "#f0f0f0" }}>
                    Disposition Status
                  </th>
                  <th scope="col" style={{ backgroundColor: "#f0f0f0" }}>
                    Notes
                  </th>
                </tr>
                <tr>
                  <td>
                    <AppText value={activeRecord.associateInitials} />
                  </td>
                  <td>
                    <AppDateTimeText value={activeRecord.dateFailed} />
                  </td>
                  <td>
                    <AppText value={activeRecord.dispositionStatus} />
                  </td>
                  <td>
                    <AppText value={activeRecord.notes} />
                  </td>
                </tr>
              </table>
            </div>
          )}
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default ClosedDetailsDialog;
