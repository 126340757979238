const VisitationsStorageKey = 'mrv.visitations';

class VisitationStore {

  getVisitations() {
    return JSON.parse(localStorage.getItem(VisitationsStorageKey) || '[]');
  }

  saveVisitations(visitations) {
    localStorage.setItem(VisitationsStorageKey, JSON.stringify(visitations));
    return visitations;
  }

  clearVisitations() {
    return this.saveVisitations([]);
  }

}

export default VisitationStore;