import React, {useEffect, useState} from "react";
import ShipmentDetails from "../details/ShipmentDetails";
import {useDispatch} from "react-redux";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";

const DetailsDialog = (props) => {

  const personalDispatch = useDispatch();

  const {
    setDetailsDialogOpen,
    selectedOpenLoad,
  } = props;

  const [shipment, setShipment] = useState(null);

  const loadShipment = async () => {
    personalDispatch(window.shell.actions.sys.processStart('getOpenLoad'));
    const result = await window.shell.gateway.getOpenLoad(selectedOpenLoad.bolNumber);
    personalDispatch(window.shell.actions.sys.processComplete('getOpenLoad'));
    setShipment(result);
  };

  useEffect(() => {
    loadShipment(selectedOpenLoad?.bolNumber);
  }, [])

  const toggleDialog = () => {
    setDetailsDialogOpen(false)
  };

  const title = `Load Details${shipment !== null ? ' - ' + shipment.bolNumber : ''}`;

  return (
    <TgfDialog
      title={title}
      onClose={toggleDialog}
      width={1000}
    >
      {shipment &&
        <ShipmentDetails shipment={shipment} />
      }
    </TgfDialog>
  );
};

export default DetailsDialog;
