import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadDeliveredCount = async (shell) => {

  const counts = await shell.gateway.getDeliveryCount();

  return {
    type: ActionTypes.Persona.LoadedDeliveredCount,
    deliveredCount: counts.delivered
  };

};

export default ActionBuilder
  .for(loadDeliveredCount)
  .build();