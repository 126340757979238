import React from 'react';
import AppMuiCard from '../../../core/components/cards/AppCard';
import {Grid} from '@material-ui/core';
import AppMuiInput from '../../../core/components/inputs/AppInput';
import AppDropdown from '../../../core/components/dropdowns/AppDropdown';
import AppValidationFailure from "../../../core/components/AppValidationFailure";

const CarrierAddressCard = (props) => {

  const {
    address1,
    errors,
    city,
    postalCode,
    selectedStateProvince,
    setFieldValue,
    stateProvinces,
    isDisabled
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value)
  };

  const handleStateProvinceChange = (e, value) => {
    const stateProvince = value;
    if (stateProvince) {
      setFieldValue('stateProvince', stateProvince.abbreviation);
      setFieldValue('selectedStateProvince', stateProvince);
    } else {
      setFieldValue('stateProvince', null);
      setFieldValue('selectedStateProvince', null);
    }
  };

  return (
    <AppMuiCard title={'Address'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppMuiInput
            id={'address1'}
            label={'Address'}
            disabled={isDisabled}
            value={address1 || ''}
            inputProps={{
              maxLength: 50
            }}
            onChange={handleFieldChange('address1')}
          />
          <AppValidationFailure
            message={errors.address1}
          />
        </Grid>
        <Grid item xs={12}>
          <AppMuiInput
            id={'city'}
            label={'City'}
            disabled={isDisabled}
            value={city || ''}
            inputProps={{
              maxLength: 30
            }}
            onChange={handleFieldChange('city')}
          />
          <AppValidationFailure
            message={errors.city}
          />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} md={6}>
            <AppDropdown
              label={'State/Province'}
              disabled={isDisabled}
              field={'name'}
              fieldIndex={'abbreviation'}
              options={stateProvinces}
              value={selectedStateProvince}
              onChange={handleStateProvinceChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppMuiInput
              id={'postalCode'}
              label={'Zip/Postal Code'}
              disabled={isDisabled}
              minLength={5}
              maxLength={7}
              value={postalCode || ''}
              onChange={handleFieldChange('postalCode')}
            />
            <AppValidationFailure
              message={errors.postalCode}
            />
          </Grid>
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

export default CarrierAddressCard;