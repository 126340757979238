import React from 'react';
import SearchTermStore from './SearchTermStore';

const useSearchTermStore = () => {

  const searchTermStore = SearchTermStore.instance();

  const [searchTerms, setSearchTerms] = React.useState(searchTermStore.list());

  const add = (searchTerm = '') => {
    if (searchTerm.length > 0) {
      searchTermStore.add(searchTerm.toLowerCase());
      setSearchTerms(searchTermStore.list());
    }
  };

  const remove = (searchTerm) => {
    let newSearchList = [];

    if(searchTerm) {
      newSearchList = searchTerms.filter(item => item !== searchTerm);
      setSearchTerms(newSearchList);
      searchTermStore.remove(searchTerm.toLowerCase());
    }

  };

  const clear = () => {
    searchTermStore.clear();
    setSearchTerms(searchTermStore.list());
  };

  return [searchTerms, add, remove, clear];
};

export default useSearchTermStore;