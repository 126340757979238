import React, {forwardRef} from "react";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";

const BadDebtGrid =  forwardRef((props, ref) => {

    const personalDispatch = useDispatch();

    const gridColumns = [
        {
            title: 'Customer',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/financial-health`
        },
        {
            title: 'Agent',
            field: 'agentName',
            type: 'text'
        },
        {
            title: 'First Shipment Date',
            field: 'minShipmentDate',
            type: 'dateFromUTCDateTime'
        },
        {
            title: 'Last Shipment Date',
            field: 'maxShipmentDate',
            type: 'dateFromUTCDateTime'
        },
        {
            title: 'Collector',
            field: 'collectorName',
            type: 'text'
        }
    ];

    const loadGridData = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadDataPage'));

            return await window.shell.gateway.getBadDebtReportData(odata);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadDataPage'));
        }
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};
        if(props?.filters?.filterCustomerName?.length) filter.customerName = { substringof: props.filters.filterCustomerName };
        if(props?.filters?.filterAgentId) filter.agentId = props.filters.filterAgentId;

        odata.filter.and = filter;
        return odata;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"open-load-board_shipment-listing-grid"}
                    ref={ref}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    onLoadDataPage={loadGridData}
                    gridColumns={gridColumns}
                    sort={[{field: "customerName", dir: "asc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    )
})

export default BadDebtGrid
