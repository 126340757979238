import ActionTypes from "../../../ActionTypes";

const autoProcessed = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Automation.RetrieveAutoProcessed.Loaded:
      return action.autoProcessed;
    default:
      return state;
  }
};

export default autoProcessed;