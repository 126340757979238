import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import CrmDataInitializer from '../../crm/components/CrmDataInitializer';
import ComponentBuilder from '../../core/ComponentBuilder';
import isRole from '../../hubs/persona/selectors/isRole';
import AccessDeniedPage from "../errors/AccessDeniedPage";

const checkRole = (stateValue, roles) => {
  return roles.some((role) => isRole(stateValue, role));
};

const AuthorizedRoute = (props) => {

  const {
    component,
    render,
    isAuthorized,
    roles,
    ...routeProps
  } = props;

  const safeRender = (safeRenderProps) => {

    const DelegateComponent = !!component ?
      component : render;

    return (<CrmDataInitializer><DelegateComponent {...safeRenderProps} /></CrmDataInitializer>);

  };

  return (
    isAuthorized ?
      <Route
        {...routeProps}
        render={safeRender}
      />
      : <AccessDeniedPage />
  );

};

export default ComponentBuilder
  .wrap(AuthorizedRoute)
  .props({
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
  })
  .stateToProps((state, ownProps) => ({
    isAuthorized: checkRole(state, ownProps.roles),
  }))
  .build();