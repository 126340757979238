import ActionTypes from '../../../ActionTypes';

const firstShipment = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.Disposed:
      return null;
    case ActionTypes.AddressBook.Modification.CompanyFirstShipmentLoaded:
      return action.firstShipment;
    default:
      return state;
  }
};

export default firstShipment;