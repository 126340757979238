import React from 'react';
import Grid from '@material-ui/core/Grid';

import ComponentBuilder from "core/ComponentBuilder";
import AppInput from "core/components/inputs/AppInput/AppMuiInput";
import AppMuiSwitch from "core/components/inputs/AppSwitch";
import AppMuiCard from "core/components/cards/AppCard";
import HazardousStateNames from "hubs/shipment/ShipmentHazardousStateNames";
import SecureField from "areas/shipments/permissions/SecureField";

import styles from "./styles";

const ReferenceNumberCard = ({
    classes,
    theme,
    pickupNumber,
    deliveryNumber,
    refNum1Description,
    refNum1,
    refNum2Description,
    refNum2,
    refNum3Description,
    refNum3,
    refNum4Description,
    refNum4,
    isHazardousMaterialsShipment,
    isBlind,
    isDeliveryOrderRequired,
    hazardousStates,
    setFieldValue
}) => {

  const handleCheckboxChange = (key) => (e) => {
    setFieldValue(key, e.target.checked);
  };

  const handleHazardousChange = (key) => (e) => {
    const stateName = e.target.checked ?
      HazardousStateNames.Hazardous :
      HazardousStateNames.NonHazardous;
    const state = hazardousStates.find(hs => hs.name === stateName);
    setFieldValue(key, state.id);
  };

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  };

  return (
    <AppMuiCard title="Reference Numbers" contentClass={classes.content}>
      <Grid container>
        <Grid item xs={6} className={classes.title}>
          Description
        </Grid>
        <Grid item xs={6} className={classes.title}>
          Number
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.subContent}>
        <Grid item xs={12} md={6}>
          Pickup
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'pickupNumber'}
            as={AppInput}
            id={'pickupNumber'}
            inputProps={{
              maxLength: 30
            }}
            value={pickupNumber || ''}
            onChange={handleFieldChange('pickupNumber')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          Delivery
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'deliveryNumber'}
            as={AppInput}
            id={'deliveryNumber'}
            inputProps={{
              maxLength: 30
            }}
            value={deliveryNumber || ''}
            onChange={handleFieldChange('deliveryNumber')}
          />
        </Grid>
        <Grid item xs={12} md={12} className={classes.warning}>
          Fields below appear on the customer's invoice.
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum1Description'}
            as={AppInput}
            id={'refNum1Description'}
            inputProps={{
              maxLength: 15
            }}
            value={refNum1Description || ''}
            onChange={handleFieldChange('refNum1Description')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum1'}
            as={AppInput}
            id={'refNum1'}
            inputProps={{
              maxLength: 30
            }}
            value={refNum1 || ''}
            onChange={handleFieldChange('refNum1')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum2Description'}
            as={AppInput}
            id={'refNum2Description'}
            inputProps={{
              maxLength: 15
            }}
            value={refNum2Description || ''}
            onChange={handleFieldChange('refNum2Description')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum2'}
            as={AppInput}
            id={'refNum2'}
            value={refNum2 || ''}
            inputProps={{
              maxLength: 30
            }}
            onChange={handleFieldChange('refNum2')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum3Description'}
            as={AppInput}
            id={'refNum3Description'}
            inputProps={{
              maxLength: 15
            }}
            value={refNum3Description || ''}
            onChange={handleFieldChange('refNum3Description')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum3'}
            as={AppInput}
            id={'refNum3'}
            inputProps={{
              maxLength: 30
            }}
            value={refNum3 || ''}
            onChange={handleFieldChange('refNum3')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum4Description'}
            as={AppInput}
            id={'refNum4Description'}
            inputProps={{
              maxLength: 15
            }}
            value={refNum4Description || ''}
            onChange={handleFieldChange('refNum4Description')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'refNum4'}
            as={AppInput}
            id={'refNum4'}
            inputProps={{
              maxLength: 30
            }}
            value={refNum4 || ''}
            onChange={handleFieldChange('refNum4')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'hazardousState'}
            as={AppMuiSwitch}
            id={'hazardousShipment'}
            label={'Hazardous'}
            color={'primary'}
            checked={isHazardousMaterialsShipment || false}
            onChange={handleHazardousChange('hazardousStateId')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'isBlind'}
            as={AppMuiSwitch}
            id={'blindShipment'}
            label={'Blind'}
            color={'primary'}
            checked={isBlind || false}
            onChange={handleCheckboxChange('isBlind')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SecureField
            pageName={'details'}
            fieldName={'isDeliveryOrderRequired'}
            as={AppMuiSwitch}
            id={'deliveryOrderRequired'}
            label={'Generate Del. Order'}
            color={'primary'}
            checked={isDeliveryOrderRequired || false}
            onChange={handleCheckboxChange('isDeliveryOrderRequired')}
          />
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

export default ComponentBuilder
  .wrap(ReferenceNumberCard)
    .withStyles(styles, true)
    .build();