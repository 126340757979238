// For dynamic label links
import {Link} from "react-router-dom";
import React from "react";

const LabelOrLink = ({ labelText, selected, styles={color: 'black'} }) => {
  const labelStyle = {textDecoration: 'underline', color: styles.color, fontWeight: 600}

    if(selected && selected.mcNumber) {
      return (
          <label>
            <Link
              style={labelStyle} to={`/carrier/${selected.mcNumber}/compliance`}
              target={"_blank"}>
              {labelText}
            </Link>
          </label>
      )
    }

    else if(selected) {
      return (
          <label>
            <Link
              style={labelStyle} to={`/address-book/${selected.companyId}/details`}
              target={"_blank" }>
              {labelText}
            </Link>
          </label>
      );
    }

  return <label style={{fontWeight: 400, color: styles.color}}>{labelText}</label>
}

export default LabelOrLink;