import ActionTypes from "../../../ActionTypes";

const multiStopLocations = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.CreateDocs.Disposed:
      return [];
    case ActionTypes.Shipment.CreateDocs.ShipmentLoaded:
      return action.multiStopLocations || [];
    default:
      return state;
  }
};

export default multiStopLocations;