import ActionTypes from '../../../ActionTypes';

const carrierCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.Carrier.Pending.Disposed:
      return 0;
    case ActionTypes.Carrier.Pending.Loaded:
      return action.carrierCount;
    default:
      return state;
  }
};

export default carrierCount;