import React from 'react';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import {Delete, Edit} from '@material-ui/icons';


const makeValueMap = (column) => column.valueMap ?
  column.valueMap :
  (row, index, data, columns) => row[column.field];

const BillingContactListingGridColumns = (onEditContactClick, onDeleteContactClick, disableEdit) => {

  const gridButtons = (row, index, data, columns) => {

    const handleEditClick = (e) => {
      onEditContactClick(row);
    };

    const handleDeleteClick = (e) => {
      onDeleteContactClick(row);
    };

    return (
      <React.Fragment>
        <AppIconButton
          Icon={Edit}
          onClick={handleEditClick}
          disabled={disableEdit}
        />
        <AppIconButton
          Icon={Delete}
          color={'danger'}
          onClick={handleDeleteClick}
          disabled={disableEdit}
        />
      </React.Fragment>
    );
  };

  const ColumnDescriptors = [
    { title: "First Name", field: 'firstName' },
    { title: "Last Name", field: 'lastName' },
    { title: "Direct Phone", field: 'phone' },
    { title: "Mobile Phone", field: 'cellPhone' },
    { title: "Fax", field: 'faxPhone' },
    { title: "Email", field: 'emailAddress' },
    { title: '', valueMap: gridButtons }
  ];

  return ColumnDescriptors
    .map((c) => ({ ...c, valueMap: makeValueMap(c) }));
};

export default BillingContactListingGridColumns;