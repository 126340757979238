import React, {forwardRef, useState} from "react";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import {Edit} from "@material-ui/icons";
import LockedDatesDialog from "../LockedDatesDialog";
import AgentTooltipComponent from "../../../../../crm/components/agent-tooltip/AgentTooltipComponent";

const editCell = (props, onEditButtonClick) => {
    return <td>
        <AppIconButton
            Icon={Edit}
            tooltip={`Edit Date`}
            onClick={() => onEditButtonClick(props.dataItem)}/>
    </td>
}

const LockedDatesGrid =  forwardRef((props, ref) => {

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedBlockedDate, setSelectedBlockedDate] = useState(null);
    const personalDispatch = useDispatch();

    const loadBlockedDates = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadBlockedDates'));

            if(props.type === "SHIPMENT_LOCKING") {
                return await window.shell.gateway.getBlockedDatesShipmentLocking(odata);
            } else if (props.type === "AR_BILLING") {
                return await window.shell.gateway.getBlockedDatesArBilling(odata);
            }

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadBlockedDates'));
        }
    }

    const onClickEditDate = (data) => {
        setSelectedBlockedDate(data);
        setEditDialogOpen(true);
    };

    const onCloseDialog = () => {
        setEditDialogOpen(false);
        setSelectedBlockedDate(null);
        props.reloadData();
    }

    const gridColumns = [
        {
            title: 'Blocked Date',
            field: 'blockedDate',
            type: 'date',
            width: 110
        },
        {
            title: 'Reason',
            field: 'reasonBlocked',
            type: 'text',
        },
        {
            title: 'Date Created',
            field: 'dateCreated',
            type: 'date',
            width: 110
        },
        {
            title: 'Created By',
            field: 'createdByAgentFullName',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.createdByAgentFullName} agentId={props.dataItem.createdByAgentId}/>
                </span>
              </td>,
            width: 140
        },
        {
            title: 'Last Updated',
            field: 'lastUpdated',
            type: 'date',
            width: 110
        },
        {
            title: 'Updated By',
            field: 'updatedByAgentFullName',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.updatedByAgentFullName} agentId={props.dataItem.updatedByAgentId}/>
                </span>
              </td>,
            width: 140
        },
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) => editCell(props, onClickEditDate),
            width: 40,
            sortable: false
        },
    ];

    return (
        <Grid container spacing={2}>
            {editDialogOpen &&
                <LockedDatesDialog
                    onCloseDialog={onCloseDialog}
                    data={selectedBlockedDate}
                    type={props.type}
                />
            }
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"open-load-board_shipment-listing-grid"}
                    ref={ref}
                    onLoadDataPage={loadBlockedDates}
                    gridColumns={gridColumns}
                    sort={[{field: "blockedDate", dir: "desc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    )
})

export default LockedDatesGrid
