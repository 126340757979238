import ActionTypes from '../../../ActionTypes';

const visitations = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Persona.Mrv.VisitationsCleared:
      return [];
    case ActionTypes.Persona.Mrv.VisitationsLoaded:
      return action.visitations.slice();
    case ActionTypes.Persona.Mrv.RecentlyVisited:
      return [action.visitation].concat(state);
    default:
      return state;
  }
};

export default visitations;