import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import * as React from "react";

const TgfTextBox = React.forwardRef((props, ref) => {
    return (
        <>
            {props?.label && <Label editorId={props.id}>{props.label}</Label>}
            <TextBox
                {...props}
                className={"tgf-textbox"}
                ref={ref}
                value={props.value ?? ''}
                autoComplete="off"
            />
        </>
    );
});

export default TgfTextBox;
