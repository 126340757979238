import deleteCompanyContact from "./deleteCompanyContact";
import deleteCompanyDocument from "./deleteCompanyDocument";
import manualDisablement from './manualDisablement';
import dispose from "./dispose";
import loadCompany from "./loadCompany";
import loadCompanyContacts from "./loadCompanyContacts";
import loadCompanyAffiliate from "./loadCompanyAffiliate";
import loadCompanyNotes from "./loadCompanyNotes";
import loadCompanyAgingNotes from "./loadCompanyAgingNotes";
import loadCompanyCreditLimitChanges from "./loadCompanyCreditLimitChanges";
import loadCompanyCreditStatus from "./loadCompanyCreditStatus";
import loadCompanyDisablementHistory from './loadCompanyDisablementHistory';
import loadCompanyFirstShipment from "./loadCompanyFirstShipment";
import loadCompanyFinancial from "./loadCompanyFinancial";
import loadCompanyFinancialHealthSummary from "./loadCompanyFinancialHealthSummary";
import loadCompanyInvoiceSettings from "./loadCompanyInvoiceSettings";
import loadCompanyDocuments from "./loadCompanyDocuments";
import publishCompanyDocument from "./publishCompanyDocument";
import loadNewCompany from "./loadNewCompany";
import saveCompany from './saveCompany';
import saveCompanyAgingNote from './saveCompanyAgingNote';
import saveCompanyContact from './saveCompanyContact';
import saveCompanyFinancial from './saveCompanyFinancial';
import saveCompanyInvoiceSettings from './saveCompanyInvoiceSettings';
import saveCompanyNote from './saveCompanyNote';
import loadCompanyAutofillFields from "./loadCompanyAutofillFields";
import saveCompanyAutofillFields from "./saveCompanyAutofillFields";

export default {
    deleteCompanyContact,
    deleteCompanyDocument,
    manualDisablement,
    dispose,
    loadCompany,
    loadCompanyAffiliate,
    loadCompanyContacts,
    loadCompanyNotes,
    loadCompanyAgingNotes,
    loadCompanyCreditLimitChanges,
    loadCompanyCreditStatus,
    loadCompanyDisablementHistory,
    loadCompanyDocuments,
    loadCompanyFirstShipment,
    loadCompanyFinancial,
    loadCompanyFinancialHealthSummary,
    loadCompanyInvoiceSettings,
    loadNewCompany,
    publishCompanyDocument,
    saveCompany,
    saveCompanyAgingNote,
    saveCompanyContact,
    saveCompanyFinancial,
    saveCompanyInvoiceSettings,
    saveCompanyNote,
    loadCompanyAutofillFields,
    saveCompanyAutofillFields
};