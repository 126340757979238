import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import VisitationStore from './VisitationStore';

const loadVisitations = (shell) => {

  const visitStore = new VisitationStore();

  const visitations = visitStore.getVisitations();

  return {
    type: ActionTypes.Persona.Mrv.VisitationsLoaded,
    visitations
  };
};

export default ActionBuilder
  .for(loadVisitations)
  .build();