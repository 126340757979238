import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiAlert-icon': {
      alignItems: 'center',
      padding: 0
    },
    '& .MuiTypography-gutterBottom': {
      marginBottom: 0,
      paddingTop: 2
  }
  },
}));

const AppAlertStatic = (props) => {
  const classes = useStyles();
  const {
    title,
    color = 'info',
    children,
    ...otherProps
  } = props;

  return (
    <div className={classes.root}>
      <Alert
        severity={color}
        {...otherProps}
      >
        <AlertTitle>
          {title}
        </AlertTitle>
        {children}
      </Alert >
    </div>
  );
};

export default AppAlertStatic;