export default {
    root: {
        borderRight: 0,
        '& .MuiDrawer-paperAnchorDockedLeft': {
            borderRight: '0px solid'
        },
        '&:hover': {
            borderRight: '0px solid'
        }
    }
  };