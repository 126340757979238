import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const markShipmentsCustomerPaid = async (shell, bolNumbers) => {

  const shipments = await shell.gateway.markShipmentsCustomerPaid(bolNumbers);

  return {
    type: ActionTypes.Reporting.MarkedShipmentsCustomerPaid,
    shipments
  };
}

export default ActionBuilder
  .for(markShipmentsCustomerPaid)
  .build();