import React, {useState} from "react";
import AppButton from "../../../../core/components/AppButton/AppMuiButton";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import {Button, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";

const DisableShipmentAutomation = (props) => {

    const personalDispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const globalState = useSelector(state => state);
    const arReleaseHidden = globalState?.utility?.featureFlags?.arReleaseHidden;

    const handleSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart("saveShipmentAutomation"));
            await window.shell.gateway.saveShipmentAutomation(props.bolNumber, !props.automationDisabled);
        } catch (e) {
            console.log(e);
        } finally {
            setDialogOpen(false);
            await props.reload();
            personalDispatch(window.shell.actions.sys.processComplete("saveShipmentAutomation"));
        }
    }

    const handleButtonClick = (e) => {
        setDialogOpen(true);
    }

    const handleDialogClose = (e) => {
        setDialogOpen(false);
    }

    return (
        (arReleaseHidden === false) &&
        <Grid container spacing={2}>
            <Grid item>
                <AppButton
                    type={"button"}
                    color={!props.automationDisabled ? "primary" : "danger" }
                    onClick={handleButtonClick}
                    disabled={props.disabled}
                >
                    AR OVERRIDE
                </AppButton>
                {dialogOpen &&
                    <TgfDialog
                        title={`${props.automationDisabled ? "Disable" : "Enable"} Automation For Shipment #${props.bolNumber}`}
                        onClose={handleDialogClose}
                        width={600}
                    >
                        <Grid item xs={12}>
                            {props.automationDisabled ?
                                <p>Confirm you are wanting to turn off the AR Override for this shipment.</p>
                            :
                                <p>Confirm you are wanting to turn on the AR Override for this shipment. This means that the Shipment Status will be manually progressed as needed, that this shipment should not appear on the Locked Shipments Report when in Locked status, and that this shipment should appear on the Manual Invoicing Report while in Hold status.</p>
                            }
                        </Grid>
                        <Grid container spacing={2} style={{paddingTop: 10}}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type={"button"}
                                    onClick={handleSave}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className={"delete-button"}
                                    type={"button"}
                                    onClick={handleDialogClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </TgfDialog>
                }
            </Grid>
        </Grid>
    )
}

export default DisableShipmentAutomation