import { Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";
import FieldError from "../../../../crm/components/inputs/FieldError";

const DeliveryNotesCard = (props) => {

    const handleChange = async (e) => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                [e.target.name]: e.target.value
            }
        });
        props.setIsDirty(true);
    };

    return (
        <AppMuiCard title={"Delivery Method Notes"}>
            <Grid container alignItems={"center"} spacing={2}>
                <Grid item xs={12}>
                    <Label editorId={"deliveryMethodNote"}>Delivery Method Notes</Label>
                    <TgfTextArea
                        id={"deliveryMethodNote"}
                        name={"deliveryMethodNote"}
                        rows={10}
                        value={props.data?.entity?.deliveryMethodNote}
                        onChange={handleChange}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.deliveryMethodNote}</FieldError>
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};

export default DeliveryNotesCard;
