import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ButtonGroup, Button} from '@material-ui/core';
import combineClassNames from "../../../../core/utils/combineClassNames";
import {useHistory, useLocation} from "react-router-dom";
import {useQuery} from "../../../../core/hooks/usQuery";

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        buttonLink: {
            textDecoration: 'none',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.tgfGrey.light,
            },
        },
        active: {
            textDecoration: 'none',
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            }
        },
        badge: {
            borderRadius: 5,
            marginLeft: 5,
            padding: '0 3px',
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.main
        },
        activeBadge: {
            backgroundColor: theme.palette.white,
            color: theme.palette.primary.main
        },
        default: {
            backgroundColor: theme.palette.default.main,
        },
        warning: {
            backgroundColor: theme.palette.warning.main,
        },
        error: {
            backgroundColor: theme.palette.error.main,
        },
        primary: {
            backgroundColor: theme.palette.primary.main,
        }
    }
));

const LastShipmentReportNav = (props) => {

    const baseURL = "/reports/last-shipment-report";

    let history = useHistory();
    const classes = useStyles();
    const query = useQuery();



    const buttonList = [
        {
            title: '0 TO 30 DAYS',
            link: '0-30',
            value: `${props?.counts?._0To30 ?? 0}`,
            style: 'primary',
            visible: true
        },
        {
            title: '31 TO 60 DAYS',
            link: '31-60',
            value: `${props?.counts?._31To60 ?? 0}`,
            style: 'primary',
            visible: true
        },
        {
            title: '61 TO 90 DAYS',
            link: '61-90',
            value: `${props?.counts?._61To90 ?? 0}`,
            style: 'primary',
            visible: true
        },
        {
            title: '91 TO 120 DAYS',
            link: '91-120',
            value: `${props?.counts?._91To120 ?? 0}`,
            style: 'primary',
            visible: true
        },
        {
            title: '120+ DAYS',
            link: '120',
            value: `${props?.counts?._120Plus ?? 0}`,
            style: 'primary',
            visible: true
        },
    ];

    const [activeTab, setActiveTab] = useState(null);
    useEffect(() => {
        if(query.get("filter") !== activeTab && activeTab !== null) {
            props.refreshGrid();
        }
    }, [activeTab, query]);
    // set active tab based on query param
    useEffect(() => {
        const navFilter = query.get('filter');
        if (navFilter === "0-30") {
            setActiveTab("0-30");
        }
        else if (navFilter === "31-60") {
            setActiveTab("31-60");
        }
        else if (navFilter === "61-90") {
            setActiveTab("61-90");
        }
        else if (navFilter === "91-120") {
            setActiveTab("91-120");
        }
        else if (navFilter === "120") {
            setActiveTab("120");
        }
    }, [query]);

    // if button clicked, change filter query param.
    const onClickHandler = (linkTo) => {
        if (linkTo === "0-30") {
            history.push(`${baseURL}?filter=0-30`);
        }
        else if (linkTo === "31-60") {
            history.push(`${baseURL}?filter=31-60`);
        }
        else if(linkTo === "61-90") {
            history.push(`${baseURL}?filter=61-90`);
        }
        else if(linkTo === "91-120") {
            history.push(`${baseURL}?filter=91-120`);
        }
        else if(linkTo === "120") {
            history.push(`${baseURL}?filter=120`);
        }
    }

    return (
        <ButtonGroup style={{padding: '20px'}} role="group" aria-label="CRM toolbar button group.">
            {
                buttonList.map((item, key) => {
                    const activeClassName = item.link === activeTab ? classes.active : classes.buttonLink;
                    const activeBadge = item.link === activeTab ? classes.activeBadge : classes.badge;

                    return (
                        <Button
                            className={activeClassName}
                            key={key}
                            disabled={false}
                            onClick={() => onClickHandler(item.link)}
                        >
                            {item.title}<span className={combineClassNames(classes.badge, activeBadge)}>{item.value}</span>

                        </Button>);
                })
            }
        </ButtonGroup>
    );
}

export default LastShipmentReportNav;
