import FormFactorEvents from '../../../core/FormFactor/FormFactorEvents';
import Core from '@atomos/core';

const CarrierAddedCosts = [
  'invoice.carrierCost',
  'invoice.detentionAmount',
  'invoice.layoverAmount',
  'invoice.reweighAdjustmentAmount',
  'invoice.reclassAdjustmentAmount',
  'invoice.carrierLumpersAmount',
  'invoice.carrierOtherAmount'
];

const CarrierDeductedCosts = [
  'invoice.carrierDeductedCost1',
  'invoice.carrierDeductedCost2'
];

const CustomerAddedCosts = [
  'invoice.customerCost',
  'invoice.customerDetentionAmount',
  'invoice.customerLayoverAmount',
  'invoice.customerReweighAmount',
  'invoice.customerReclassAmount',
  'invoice.customerLumpersAmount',
  'invoice.customerOtherAmount'
];

const CustomerDeductedCosts = [
  'invoice.customerDeductedCost1',
  'invoice.customerDeductedCost2'
];

class ValueAssignmentEventRule {

  constructor(rule) {
    this.rule = rule;
  }

  invoke(event, context) {
    if (event instanceof FormFactorEvents.ValueAssignmentEvent) {
      this.rule(event, context);
    }
  }

}

class ComputedCostRule extends ValueAssignmentEventRule {

  constructor(destinationCostPath, addedCostPaths, deductedCostPaths) {
    super((...args) => this.processRule(...args));
    this.destinationCostPath = destinationCostPath;
    this.addedCostPaths = addedCostPaths;
    this.deductedCostPaths = deductedCostPaths;
  }

  calculateTotal(keys, context) {
    return keys
      .map(path => context.getFieldValue(path))
      .filter(value => !Core.Utils.isVoid(value))
      .map(value => Core.Utils.isNumber(value) ? value : parseFloat(value))
      .reduce((total, cost) => total + cost, 0);
  }

  processRule(event, context) {
    if (this.addedCostPaths.includes(event.pathSpec) || this.deductedCostPaths.includes(event.pathSpec)) {
      const totalCost = this.calculateTotal(this.addedCostPaths, context) -
        this.calculateTotal(this.deductedCostPaths, context);
      context.setFieldValue(this.destinationCostPath, totalCost);
    }
  }
}

export default [
  new ComputedCostRule('invoice.adjustedCarrierCost', CarrierAddedCosts, CarrierDeductedCosts),
  new ComputedCostRule('invoice.adjustedCustomerCost', CustomerAddedCosts, CustomerDeductedCosts),
];
