import { Button, Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { useDispatch } from "react-redux";
import AppButton from "../../../../core/components/AppButton/AppMuiButton";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import TgfDateRangePicker from "../../../../core/kendo/form/inputs/TgfDateRangePicker";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";

const LockedDatesForm = (props) => {

    const personalDispatch = useDispatch();
    const [dateRange, setDateRange] = useState(null);
    const [reasonBlocked, setReasonBlocked] = useState(null);

    const handleDateRangePickerChange = (e) => {
        setDateRange(e.target.value);
    };

    const handleReasonBlockedChange = (e) => {
        setReasonBlocked(e.target.value);
    };

    const getArrayOfDatesFromDateRange = (startDate, endDate) => {
        let arrayOfDates = [];
        // we dont want to alter the original values.
        let tempStartDate = new Date(startDate);
        let tempEndDate = new Date(endDate);

        const addDays = (date, days) => {
            date.setDate(date.getDate() + days);
            return date;
        };

        while (tempStartDate <= tempEndDate) {
            arrayOfDates.push(new Date(tempStartDate));
            tempStartDate = addDays(tempStartDate, 1);
        }
        return arrayOfDates;
    };

    const HandleSubmit = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveInvoiceDate'));

            const arrayOfDates = getArrayOfDatesFromDateRange(dateRange.start, dateRange.end);

            const data = {
                blockedDates: arrayOfDates,
                reasonBlocked: reasonBlocked,
                dateCreated: new Date(),
                lastUpdated: new Date(),
            };

            if (props.type === "SHIPMENT_LOCKING") {
                await window.shell.gateway.saveBlockedDatesShipmentLocking(data);
            } else if (props.type === "AR_BILLING") {
                await window.shell.gateway.saveBlockedDatesArBilling(data);
            }

            setDateRange(null);
            setReasonBlocked(null);
            props.reloadData();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveInvoiceDate'));
        }
    };

    const handleDateClear = () => {
        setDateRange(null);
    };


    return (
        <AppMuiCard title={"Save New Blocked Date"}>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item>
                    <TgfDateRangePicker
                        value={dateRange}
                        onChange={handleDateRangePickerChange}
                    />
                </Grid>
                <Grid item>
                    <AppButton
                        style={{ marginLeft: 10 }}
                        type={"button"}
                        color={'primary'}
                        onClick={handleDateClear}
                    >
                        clear date
                    </AppButton>
                </Grid>
                <Grid item xs={12}>
                    <Label editorId={"reasonBlocked"}>Reason</Label>
                    <TgfTextArea
                        id={"reasonBlocked"}
                        name={"reasonBlocked"}
                        value={reasonBlocked}
                        onChange={handleReasonBlockedChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        type={"button"}
                        disabled={!dateRange?.start || !dateRange?.end}
                        onClick={HandleSubmit}
                    >
                        Submit
                    </Button>

                </Grid>
            </Grid>
        </AppMuiCard>
    );
};

export default LockedDatesForm;