import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
  validationFailure: {
    color: theme.palette.error.main,
    position: 'relative',
    fontSize: '.75em',
    top: '-3px',
    marginBottom: '-18px',
    height: 18,
    marginLeft: 3
  }
}))

const AppValidationFailure = (props) => {
  const classes = useStyle();
  const {
    message
  } = props;
  return (
    <React.Fragment>
      {
        message &&
          <div className={classes.validationFailure}>
            {message}
          </div>
      }
    </React.Fragment>
  );
};

export default AppValidationFailure;