import ActionTypes from "../../../ActionTypes";

const shipmentContents = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.ShipmentContentsDisposed:
      return null;
    case ActionTypes.Shipment.Modification.ShipmentContentsLoaded:
    case ActionTypes.Shipment.Modification.ShipmentContentsSaved:
    case ActionTypes.Shipment.Modification.ShipmentContentsDeleted:
      return action.loadContents;
    default:
      return state;
  }
};

export default shipmentContents;