import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyDisablementHistory = async (shell, companyId, offset, limit, sort) => {

  const {disablementHistories, count} = await shell.gateway
    .getCompanyDisablementHistory(companyId, offset, limit, sort);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyDisablementHistoryLoaded,
    disablementHistories,
    count
  }
};

export default ActionBuilder
  .for(loadCompanyDisablementHistory)
  .build();