import React from 'react';
import AppValidationFailure from '../components/AppValidationFailure';
import FormFactorGraft from './FormFactorGraft';

const FormFactorValidationFailure = (props) => {

  const {
    field,
    ...otherProps
  } = props

  const slices = [`errors.${field}`];
  const graftMap = ([message]) => {
    return {
      message
    };
  };

  return (
    <FormFactorGraft
      slices={slices}
      graftMap={graftMap}
      as={AppValidationFailure}
      {...otherProps}
    />
  );
};

export default FormFactorValidationFailure;