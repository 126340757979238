import * as React from 'react';
import TgfHorizontalNavBar from "../../../../crm/components/navigation/TgfHorizontalNavBar/TgfHorizontalNavBar";
import {urlPathOptions, navBarTooltipOptions} from "./riskReviewReportOptions";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const DisplayWithInfoIconTooltip = (props) => {
    return (
        <>
            {props.display}
            <InfoIconTooltip title={props.tooltipTitle} />
        </>
    )
}

const RiskReviewReportNavBar = (props) => {
    const buttonList = [
        {
            display: <DisplayWithInfoIconTooltip display={"High GM Shipments"} tooltipTitle={navBarTooltipOptions.highGmShipments}  />,
            link: urlPathOptions.highGmShipments,
        },
        {
            display: <DisplayWithInfoIconTooltip display={"High GM Customers"} tooltipTitle={navBarTooltipOptions.highGmCustomers}  />,
            link: urlPathOptions.highGmCustomers,
        },
        {
            display: <DisplayWithInfoIconTooltip display={"Matching Ref #'s"} tooltipTitle={navBarTooltipOptions.matchingRefNumbers}  />,
            link: urlPathOptions.matchingRefNumbers,
        },
        {
            display: <DisplayWithInfoIconTooltip display={"Matching Carriers"} tooltipTitle={navBarTooltipOptions.matchingCarriers}  />,
            link: urlPathOptions.matchingCarriers,
        },
        {
            display: <DisplayWithInfoIconTooltip display={"Credit App Missing"} tooltipTitle={navBarTooltipOptions.creditAppMissing}  />,
            link: urlPathOptions.creditAppMissing,
        },
        {
            display: <DisplayWithInfoIconTooltip display={"5k+ Cust Cost"} tooltipTitle={navBarTooltipOptions.fiveKPlusCustomerCost}  />,
            link: urlPathOptions.fiveKPlusCustomerCost
        },
    ];

    return (
        <TgfHorizontalNavBar buttonList={buttonList} />
    );
};
export default RiskReviewReportNavBar;