import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const invalidate = async (shell) => {

  await shell.gateway.logout();

  return {
    type: ActionTypes.Persona.Invalidated
  };
};

export default ActionBuilder
  .for(invalidate)
  .build();