import * as Redux from 'redux';

import listing from './listing';
import customerReportCount from './customerReportCount';
import grossMarginBreakdown from './grossMarginBreakdown';

export default Redux.combineReducers({
    listing,
    customerReportCount,
    grossMarginBreakdown
});