import React, {forwardRef} from "react";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import HaltRevertInvoice from "../../../../crm/components/halt-revert-invoice/HaltRevertInvoice";
import haltRevertType from "../../../../crm/components/halt-revert-invoice/HaltRevertType";
import {useQuery} from "../../../../core/hooks/usQuery";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";

const AutoInvoiceGrid =  forwardRef((props, ref) => {

    const personalDispatch = useDispatch();
    const query = useQuery();

    const gridColumns = [
        {
            title: 'Rep',
            field: 'rep',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.associateId}/>
                </span>
              </td>,
            width: 40
        },
        {
            title: 'Ship #',
            field: 'bolNumber',
            type: 'link',
            url: (props) => `/shipment/${props.bolNumber}/details`,
            width: 65
        },
        {
            title: 'Customer',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
            width: 200
        },
        {
            title: 'Locked By',
            field: 'lockedBy',
            type: "custom",
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.lockedBy} agentId={props.dataItem?.lockedById}/>
                </span>
              </td>,
        },
        {
            title: 'Date/Time Locked',
            field: 'lockedDateTime',
            type: 'dateTimeFromUTCDateTime',
        },
        {
            title: 'Invoice Total', // curAdjCustomerCost cost from invoice
            field: 'invoiceTotal',
            type: 'currency',
        },
        {
            title: 'Invoice Will Be Sent To Customer At… ',
            field: 'forwardInvoiceDateTime',
            type: 'dateTimeFromUTCDateTime',
        },
        {
            title: 'Halted By',
            field: 'haltedBy',
            type: "custom",
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.haltedBy} agentId={props.dataItem?.haltedById}/>
                </span>
              </td>,
            width: 80
        },
        {
            title: 'Halted Date/Time',
            field: 'haltedDateTime',
            type: 'dateTimeFromUTCDateTime',
        },
        {
            title: 'Halted Reason',
            field: 'haltedReason',
            type: 'custom',
            cell: (props) => <td className={"k-table-td show-all-text"}><span>{props.dataItem.haltedReason ?? ' '}</span></td>,
            width: 300
        },
        {

            title: 'Halt/Revert',
            field: '',
            type: 'custom',
            cell: (props) => <td style={{textAlign: "center"}}><HaltRevertInvoice
                type={props.dataItem.isHalted ? haltRevertType.REVERT : haltRevertType.HALT}
                bolNumber={props.dataItem.bolNumber}
                reload={ref.current.refresh}
                buttonStyle={"grid"}
            /></td>,
            width: 80,
            sortable: false
        },
    ];

    const loadGridData = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadMultiTransPage'));

            const result =  await window.shell.gateway.getAutoInvoiceReportListing(odata);
            return result;

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadMultiTransPage'));
        }
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};
        if(query.get("show-halted-only") === "true") filter.isHalted = true;
        if(props?.filters?.filterAssociate) filter.associateId = props.filters.filterAssociate.id;

        odata.filter.and = filter;
        return odata;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"open-load-board_shipment-listing-grid"}
                    ref={ref}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    onLoadDataPage={loadGridData}
                    gridColumns={gridColumns}
                    sort={[{field: "lockedDateTime", dir: "desc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    )
})

export default AutoInvoiceGrid
