import React, { forwardRef } from "react";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {Grid} from "@material-ui/core";


const MultiTransGrid =  forwardRef((props, ref) => {

    const CreatedByDateCell = (props) => {
        return (
          <td>
              <div>
                  {props.dataItem.creator + ' ' + props.dataItem.created}
              </div>
          </td>
        );
    }

    const gridColumns = [
        {
            title: 'Ship #',
            field: 'label',
            type: 'text',
        },
        {
            title: 'Rep',
            field: 'rep',
            type: 'text',
        },
        {
            title: 'Date Entered',
            field: 'enteredDate',
            type: 'text',
        },
        {
            title: 'Reason for entry',
            field: 'reason',
            type: 'text',
        },
        {
            title: 'Last Updated',
            field: 'lastUpdated',
            type: 'text',
        },
        {
            title: 'Carrier Cost',
            field: 'carrierCost',
            type: 'currency',
        },
        {
            title: 'Customer Cost',
            field: 'customerCost',
            type: 'currency',
        },
        {
            title: 'Gross Margin',
            field: 'grossMargin',
            type: 'currency',
        },
        {
            title: 'Current Status',
            field: 'status',
            type: 'text',
        },
        {
            title: 'Locked Date',
            field: 'lockedDate',
            type: 'text',
        },
        {
            title: 'Created By/Date',
            field: '',
            type: 'custom',
            cell: (props) => CreatedByDateCell(props),
            sortable: false
        },
    ];

    const loadData = async (odata) => {
        if(props.data) {
            const dataPage = {
                totalItemCount: props.data.length,
                totalPageCount: 0,
                pageIndex: 0,
                pageSize: odata.take,
                items: props.data
            }
            return dataPage;
        }

        return {};
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"shipment_multi-trans-grid"}
                    ref={ref}
                    onLoadDataPage={loadData}
                    gridColumns={gridColumns}
                />
            </Grid>
        </Grid>
    )
})

export default MultiTransGrid
