import ActionTypes from "../../../ActionTypes";

const associateCommissionBreakdown = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Reporting.MonthlyGrossMargin.Disposed:
      return null;
    case ActionTypes.Reporting.MonthlyGrossMargin.MonthlyCommissionBreakdownLoaded:
      return action.associateCommissionBreakdown;
    default:
      return state;
  }
};

export default associateCommissionBreakdown;