import React from "react";
import {Grid, Tooltip} from "@material-ui/core";
import "./ShipmentDetails.scss";
import AppDateText from "../../../../core/components/text/AppDateText";
import AppCurrencyText from "../../../../core/components/text/AppCurrencyText";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import MultiStopTooltip from "./includes/MultiStopTooltip";

const ShipmentDetails = (props) => {
    const {
        shipment,
        editMode
    } = props;

    const {
        bolNumber,
        customerName,
        fromCity,
        fromState,
        fromZip,
        toCity,
        toState,
        toZip,
        equipmentType,
        customerCost,
        isMultiStop,
        isHazMat,
        isBlind,
        pickupDate,
        pickupTime,
        deliveryDate,
        deliveryTime,
        totalWeight,
        totalPackageCount,
        generalRcNotes,
        generalNotes,
        multiStops
    } = shipment;

    const useStyles = makeStyles((theme) => ({
        linkCell: {
            textDecoration: 'none', color: theme.palette.tgfLink.main, fontWeight: 'bold', '&:hover': {
                textDecoration: 'underline'
            }
        }
    }));

    const classes = useStyles();


    return (
      <>
        {shipment &&
          <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <p style={{color: "red"}}>The information below comes from the shipment. To change this
                        information, you must make changes within the shipment itself.</p>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} style={{border: "1px solid gray", padding: 4}}>
                    <Grid container>
                        <Grid item xs={6}>
                            Shipment #
                        </Grid>
                        <Grid item xs={6}>
                            <Link to={`/shipment/${bolNumber}/details`}
                                  target={"_blank"}
                                  className={classes.linkCell}>{bolNumber}</Link>
                        </Grid>

                        <Grid item xs={6}>
                            Customer Name
                        </Grid>
                        <Grid item xs={6}>
                            {customerName}
                        </Grid>

                        <Grid item xs={6}>
                            From City/State/Zip
                        </Grid>
                        <Grid item xs={6}>
                            {fromCity} {fromState} {fromZip}
                        </Grid>

                        <Grid item xs={6}>
                            To City/State/Zip
                        </Grid>
                        <Grid item xs={6}>
                            {toCity} {toState} {toZip}
                        </Grid>

                        <Grid item xs={6}>
                            Equipment Type
                        </Grid>
                        <Grid item xs={6}>
                            {equipmentType}
                        </Grid>

                        <Grid item xs={6}>
                            Customer Cost
                        </Grid>
                        <Grid item xs={6}>
                            <AppCurrencyText value={customerCost}/>
                        </Grid>

                        <Grid item xs={6}>
                            Multi-Stop?
                        </Grid>
                        <Grid item xs={6}>
                            {isMultiStop ?
                                <span>Yes {<MultiStopTooltip multiStops={multiStops} />}</span>
                              : "No"}
                        </Grid>

                        <Grid item xs={6}>
                            Blind?
                        </Grid>
                        <Grid item xs={6}>
                            {isBlind ? "Yes" : "No"}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} style={{border: "1px solid gray", padding: 4}}>
                    <Grid container>

                        <Grid item xs={6}>
                            Pickup Date
                        </Grid>
                        <Grid item xs={6}>
                            <AppDateText value={pickupDate}
                                         style={editMode ? {color: "#008800"} : {}}></AppDateText>
                        </Grid>

                        <Grid item xs={6}>
                            Pickup Time
                        </Grid>
                        <Grid item xs={6}>
                            <span style={editMode ? {color: "#008800"} : {}}>{pickupTime}</span>
                        </Grid>

                        <Grid item xs={6}>
                            Delivery Date
                        </Grid>
                        <Grid item xs={6}>
                            <AppDateText style={editMode ? {color: "#008800"} : {}} format={"L"} value={deliveryDate}/>
                        </Grid>

                        <Grid item xs={6}>
                            Delivery Time
                        </Grid>
                        <Grid item xs={6}>
                            <span style={editMode ? {color: "#008800"} : {}}>{deliveryTime}</span>
                        </Grid>

                        <Grid item xs={6}>
                            Total Weight
                        </Grid>
                        <Grid item xs={6}>
                            <span
                              style={editMode ? {color: "#008800"} : {}}>{totalWeight ? `${totalWeight.toLocaleString()} lbs` : "n/a"}</span>
                        </Grid>

                        <Grid item xs={6}>
                            Total Pkg Count
                        </Grid>
                        <Grid item xs={6}>
                            {totalPackageCount != null ? totalPackageCount : 'n/a'}
                        </Grid>

                        <Grid item xs={6}>
                            Haz-Mat?
                        </Grid>
                        <Grid item xs={6}>
                            {isHazMat ? "Yes" : "No"}
                        </Grid>

                        <Grid item xs={6}>
                            General / RC Notes
                        </Grid>
                        <Grid item xs={6}>
                            {(generalNotes || generalRcNotes) ? <Tooltip title={
                                <div style={{}}>
                                    <p style={{fontSize: ".9rem", margin: 0}}>General Notes</p>
                                    <hr style={{padding: 0, margin: 0, marginTop: 2, marginBottom: 2}}/>
                                    <p style={{fontSize: ".9rem", margin: 0}}>{generalNotes}</p>
                                    <p style={{fontSize: ".9rem", marginTop: 20, marginBottom: 0}}>Rate Con Notes</p>
                                    <hr style={{padding: 0, margin: 0, marginTop: 2, marginBottom: 2}}/>
                                    <p style={{fontSize: ".9rem", margin: 0}}>{generalRcNotes}</p>
                                </div>
                            }>
                                <span style={{color: "#0066ff", cursor: "pointer"}}>View</span>
                            </Tooltip> : <span>n/a</span>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>}
    </>);
};

export default ShipmentDetails;
