const LoadTrackingActions = [
  'addNote'
];
const LoadTrackingFields = [];

const PermissionSet = {
  name: 'loadTracking',
  actions: LoadTrackingActions,
  fields: LoadTrackingFields
};

export default PermissionSet;