import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import AppMuiCard from "../../../core/components/cards/AppCard";
import AppDrawer from "../../../core/components/AppDrawer";
import AppDropdown from "../../../core/components/dropdowns/AppDropdown";
import AppMuiInput from "../../../core/components/inputs/AppInput";
import AppMuiSwitch from "../../../core/components/inputs/AppSwitch";
import _ from "lodash";
import useFieldValidator from "../../../crm/components/customHooks/form/validation/useFieldValidator";
import {visibilityValidationSchema} from "./validation/visibilityValidationSchema";
import FieldError from "../../../crm/components/inputs/FieldError";
import AppButton from "../../../core/components/AppButton";

const useStyles = makeStyles(theme => ({
    container: {
        width: 400
    },
    fileInput: {
        display: 'none',
    }
}));

const HelpDocumentDrawer = ({ documentTypes, open, onClose, anchor, onPublish }) => {
    const classes = useStyles();
    const [fileUploadName, setFileUploadName] = React.useState(null);
    const [docType, setDocType] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [visibility, setVisibilty] = useState({permAllUsers: true, permBackOfficeOnly: false});
    const [isValid, setIsValid] = useState(false);
    const fieldErrors = useFieldValidator(visibilityValidationSchema, visibility, setIsValid);

    const element = documentTypes.find(({ name }) => name === "Other");
    const orderedDocumentTypes = [...documentTypes.filter(a => a.name !== element.name), element]

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const fileInfo = file.name;
        setFile(file);
        setFileUploadName(fileInfo)
    };

    const handleDocTypeChange = (e, documentType) => {
        setDocType(documentType)
    };
    const handleDescriptionChange = (e) => setDescription(e.target.value);
    const handlePublishDocument = () => {
        const document = {
            description,
            documentTypeId: docType.id,
            filename: fileUploadName,
            document: file,
            permAllUsers: visibility.permAllUsers,
            permBackOfficeOnly: visibility.permBackOfficeOnly
        };
        clearState();
        onPublish(document);
    };

    const handleCloseDrawer = () => {
        clearState();
        onClose();
    };

    const handleVisibilityChange = (e) => {
        // will eventually want to abstract this logic when these permissions expand.
        const visibilityClone = _.clone(visibility);
        if(e.target.name === "permAllUsers") {
            if(e.target.checked === true) {
                visibilityClone.permBackOfficeOnly = false;
                visibilityClone.permAllUsers = true;
            } else {
                visibilityClone.permAllUsers = false;
            }
        }
        if(e.target.name === "permBackOfficeOnly") {
            if(e.target.checked === true) {
                visibilityClone.permBackOfficeOnly = true;
                visibilityClone.permAllUsers = false;
            } else {
                visibilityClone.permBackOfficeOnly = false;
            }
        }
        setVisibilty(_.clone(visibilityClone));
    }

    const clearState = () => {
        setFileUploadName(null);
        setDocType(null);
        setDescription(null);
        setFile(null);
        setVisibilty({permAllUsers: true, permBackOfficeOnly: false});
    };

    return (
        <AppDrawer
            anchor={anchor}
            open={open}
            onClose={handleCloseDrawer}
        >
            <AppMuiCard title="Add Document">
                <div className={classes.container}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={4}>Doc. Type</Grid>
                        <Grid item xs={12} md={8}>
                            <AppDropdown
                                id={'docType'}
                                field={'name'}
                                fieldIndex={'id'}
                                options={orderedDocumentTypes}
                                onChange={handleDocTypeChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>Description</Grid>
                        <Grid item xs={12} md={8}>
                            <AppMuiInput
                                id="description"
                                placeholder="optional"
                                inputProps={{
                                    maxLength: 300
                                }}
                                onChange={handleDescriptionChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <span>Visibility</span>
                            <FieldError >{fieldErrors.permAllUsers}</FieldError>
                        </Grid >
                        <Grid item xs={6}>
                            <AppMuiSwitch
                                id={"permAllUsers"}
                                name={"permAllUsers"}
                                checked={visibility?.permAllUsers ?? false}
                                onChange={handleVisibilityChange}
                                label={"All Users"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AppMuiSwitch
                                id={"permBackOfficeOnly"}
                                name={"permBackOfficeOnly"}
                                checked={visibility?.permBackOfficeOnly ?? false}
                                onChange={handleVisibilityChange}
                                label={"Back Office Only"}
                            />
                        </Grid>
                        {fileUploadName && <>
                            <Grid item xs={12} md={4}>File</Grid>
                            <Grid item xs={12} md={8}>
                                {fileUploadName}
                            </Grid>
                        </>
                        }
                        <Grid container item xs={12} justify="flex-end">
                            <label htmlFor="fileUpload">
                                <AppMuiInput
                                    type="file"
                                    disabled={docType === null}
                                    className={classes.fileInput}
                                    id="fileUpload"
                                    onChange={handleFileUpload}
                                />
                                <AppButton
                                    color={'tgfGrey'}
                                    component="span"
                                    disabled={docType === null}
                                >
                                    Browse Files
                                </AppButton>
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <AppButton disabled={docType === null || fileUploadName === null || !isValid} onClick={handlePublishDocument}>
                                        Publish
                                    </AppButton>
                                </Grid>
                                <Grid item>
                                    <AppButton onClick={handleCloseDrawer}>
                                        Cancel
                                    </AppButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </AppMuiCard>
        </AppDrawer>
    )
}
export default HelpDocumentDrawer;
