import React from 'react';
import AppAlertStatic from '../../core/components/AppAlertStatic';
import {Grid} from '@material-ui/core';

const MissingOrDeniedShipmentAlert = (props) => {
  return (
    <Grid item xs={12}>
      <AppAlertStatic color={'warning'}>
        <p>You may not have access to view this shipment or the shipment cannot be found.  If you feel this is incorrect, please contact management.</p>
      </AppAlertStatic>
    </Grid>
  );
};

export default MissingOrDeniedShipmentAlert;