import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const saveCommissionRate = async (shell, associateId, commissionRate) => {

  const updatedCommissionRate = await shell.gateway
    .saveCommissionRate(associateId, commissionRate)

  return {
    type: ActionTypes.CommissionCenter.CommissionRateSaved,
    associateCommissionRate: updatedCommissionRate
  };

};

export default ActionBuilder
  .for(saveCommissionRate)
  .build();