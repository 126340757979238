import React, {useEffect, useState} from 'react';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import { Grid } from '@material-ui/core';
import AppMuiButton from '../../../core/components/AppButton';
import AppMuiCard from '../../../core/components/cards/AppCard';
import FailIcon from '../../../core/components/icons/FailIcon';
import {ArrowBack, Assignment} from '@material-ui/icons';
import SuccessIcon from '../../../core/components/icons/SuccessIcon';
import {Link} from 'react-router-dom';
import computeConstraints from './computeConstraints';
import DocumentTabs from './DocumentTabs';
import "./CreateDocsPage.scss";
import {useDispatch, useSelector} from "react-redux";
import WarningDialog from "./includes/WarningDialog";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const CreateDocsPage = (props) => {

  const {
    match
  } = props;

  const personalDispatch = useDispatch();
  const bolNumber = parseInt(match.params.id);
  usePageTitle(`Ship ${bolNumber}`);

  const globalState = useSelector((state) => state)

  const [state, setState] = useState({
    shipment: null,
    invoice: null,
    customer: null,
    shipper: null,
    consignee: null,
    customerContracts: null,
    shipperContract: null,
    consigneeContracts: null,
    loadContents: null,
    truckloadValueConfirmation: null,
    carrierContact: null,
    multiStopCompanies: null,
    multiStopCompanyContacts: [],
    multiStopLocations: [],
    shipmentStatusTypes: null,
    shipmentFreightCategoryTypes: null,
  });

  const loadPageDependencies = async () => {
    personalDispatch(window.shell.actions.sys.processStart('loadCreateDocsPageDependencies'));

    try {
      const shipment = await window.shell.gateway.getShipment(bolNumber);

      const companies = [
        shipment.customerId,
        shipment.shipperId,
        shipment.consigneeId
      ];

      const companyPromises = companies
          .map(companyId => companyId ? window.shell.gateway.getCompany(companyId) : null);

      const companyContactPromises = companies
          .map(companyId => companyId ? window.shell.gateway.getCompanyContacts(companyId) : []);

      const tlValueConfirmPromise = window.shell.gateway.getShipmentTruckLoadConfirmation(bolNumber);

      const loadContentPromise = window.shell.gateway.getAllShipmentContents(bolNumber);

      const carrierContactPromise = window.shell.gateway.getCarrierContact(bolNumber);

      const invoicePromise = window.shell.gateway.getInvoice(bolNumber)

      const allPromises = companyPromises
          .concat(companyContactPromises)
          .concat([tlValueConfirmPromise, loadContentPromise, carrierContactPromise, invoicePromise]);

      const [
        customer,
        shipper,
        consignee,
        customerContacts,
        shipperContacts,
        consigneeContacts,
        truckloadValueConfirmation,
        loadContents,
        carrierContact,
        invoice
      ] = await Promise.all(allPromises);


      if (shipment.isMultipleStop) {

        const multiStopLocations = await window.shell.gateway.getShipmentMultipleStop(bolNumber);

        const multiStopContactPromises = multiStopLocations
            .map(location => window.shell.gateway.getCompanyContacts(location.companyId));

        const multiStopCompanyContacts = await Promise.all(multiStopContactPromises);

        setState(prevState => {
          return {
            ...prevState,
            multiStopLocations: multiStopLocations,
            multiStopCompanyContacts: multiStopCompanyContacts
          }
        });

      }

      setState(prevState => {
        return {
          ...prevState,
          shipment,
          invoice,
          companies,
          customer,
          shipper,
          consignee,
          customerContacts,
          shipperContacts,
          consigneeContacts,
          truckloadValueConfirmation,
          loadContents,
          carrierContact
        }
      })
    } catch (e) {
      console.log(e)
    } finally {
      personalDispatch(window.shell.actions.sys.processComplete('loadCreateDocsPageDependencies'));
    }

  };

  useEffect(() => {
    loadPageDependencies();

    if(globalState) {
      setState(prevState => {
        return {
          ...prevState,
          shipmentStatusTypes: globalState.support.shipmentStatusTypes,
          shipmentFreightCategoryTypes: globalState.support.freightCategoryTypes
        }
      })
    }
  }, [bolNumber]);


  const [showGeneratedDocs, setShowGeneratedDocs] = React.useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  let constraintGroups = [];
  let isTlShipment = true;

  // Only build the constraints if the shipment is loaded.
  // No sense in showing any items until the shipment is loaded.
  if (state.shipment) {
    [constraintGroups, isTlShipment] = computeConstraints(state);
  }

  const canCreateDocs = constraintGroups.length > 0 &&
    constraintGroups.every(([category, constraints]) =>
      constraints.every(c => c.passed));

  const title = `Shipment - Create Docs - ${bolNumber}`;

  const handleCreateDocsClick = () => {
    // show warning message first if either adj cost is 0
    const {adjustedCarrierCost, adjustedCustomerCost} = state.invoice;
    if(!adjustedCarrierCost || !adjustedCustomerCost) {
      setShowWarningDialog(true);
    } else {
      setShowGeneratedDocs(true);
    }
  };

  const rulesContent = (
    <ConstraintsGroup constraintGroups={constraintGroups} />
  );

  let bolContent = null;
  let rateConContent = null;
  let deliveryOrderContent = null;

  const docHeight = window.innerHeight * .7;

  if (showGeneratedDocs) {

    bolContent = (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe src={`/generate/shipment/${bolNumber}/bill-of-lading`} frameBorder={0} style={{ width: '100%', height: docHeight }} />
    );

    if (isTlShipment)
      rateConContent = (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe src={`/generate/shipment/${bolNumber}/rate-confirmation`} frameBorder={0} style={{ width: '100%', height: docHeight }} />
      );

    if (state.shipment.isDeliveryOrderRequired)
      deliveryOrderContent = (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe src={`/generate/shipment/${bolNumber}/delivery-order`} frameBorder={0} style={{ width: '100%', height: docHeight }} />
      );
  }

  return (
      state.shipment &&
      <FullWidthLayout title={title} className='shipments-styles'>
        <Grid container spacing={1}>
          <Grid container item spacing={1} xs={12}>
            <Grid item>
              <AppMuiButton
                  type={'button'}
                  startIcon={<Assignment />}
                  disabled={!canCreateDocs}
                  onClick={handleCreateDocsClick}>
                Create
              </AppMuiButton>
            </Grid>
            <Grid item>
              <AppMuiButton
                  component={Link}
                  to={`/shipment/${bolNumber}/details`}
                  startIcon={<ArrowBack />}>
                Back to Shipment
              </AppMuiButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DocumentTabs
                rulesContent={rulesContent}
                bolContent={bolContent}
                rateConContent={rateConContent}
                deliveryOrderContent={deliveryOrderContent}
                invoice={state.invoice}
            />
          </Grid>
          <Grid item xs={12}>
            {showWarningDialog &&
            <WarningDialog setShowWarningDialog={setShowWarningDialog} setShowGeneratedDocs={setShowGeneratedDocs} invoice={state.invoice} />
            }
          </Grid>
        </Grid>
      </FullWidthLayout>
  );
};

const ConstraintsGroup = (props) => {
  const { constraintGroups } = props;
  return (
    <Grid container spacing={2}>
      {
        constraintGroups.map(([category, constraints], groupIndex) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={groupIndex}>
              <AppMuiCard title={category} key={groupIndex}>
                {
                  constraints.map((constraint, index) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs={2}>
                          { constraint.passed ? <SuccessIcon /> : <FailIcon />}
                        </Grid>
                        <Grid item xs={10}>
                          { constraint.title }
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </AppMuiCard>
            </Grid>
          );
        })
      }
    </Grid>
  )
}

export default CreateDocsPage;

