import ActionTypes from '../../ActionTypes';

const tgfMetrics = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Persona.LoadedTgfMonthlyMetrics:
      return action.metrics;
    default:
      return state;
  }
};

export default tgfMetrics;