import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { List, ListItem, ListItemIcon, ListItemText, Link, Collapse, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: theme.palette.tgfGrey.light
        },
        height: 50
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const SideMenuMulti = ({ data }) => {
    const classes = useStyles();
    const CompIcon = data.icon;
    return (
        <>
            <ListItem button onClick={data.action}>
                <ListItemIcon>
                    <Tooltip title={data.title}><CompIcon /></Tooltip>
                </ListItemIcon>
                <ListItemText primary={data.title} />
                {data.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={data.open} timeout="auto" unmountOnExit>
                {
                    data.subMenus.map((item, key) => {
                        const ItemIcon = item.icon;
                        return (
                            <List component="div" disablePadding key={key}>
                                <ListItem button className={classes.nested}
                                    component={Link}
                                    href={item.href || ''}
                                >
                                    <ListItemIcon>
                                        <Tooltip title={item.title}><ItemIcon /></Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} />
                                </ListItem>
                            </List>
                        )
                    }
                    )
                }
            </Collapse>
        </>
    )
};

export default SideMenuMulti;