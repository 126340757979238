import ActionTypes from "../../../ActionTypes";

const qaReviewCount = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Automation.RetrieveQaReview.Loaded:
      return action.count;
    default:
      return state;
  }
};

export default qaReviewCount;