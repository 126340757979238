import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyNotes = async (shell, companyId) => {

  const notes = await shell.gateway.getCompanyNotes(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyNotesLoaded,
    notes
  };

};

export default ActionBuilder
  .for(loadCompanyNotes)
  .build();