import Core from '@atomos/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

/**
 * Factory for an empty object mapper.
 * @return {{}}
 */
const EmptyPropMapper = () => ({});

/**
 * Builder that makes component wrapping consistent
 * for the CRM.
 */
class ComponentBuilder {

  /**
   * Helper to begin wrapping a component
   * in a ComponentBuilder instance.
   * @param {Component} baseComponent - The component to wrap.
   * @return {ComponentBuilder}
   */
  static wrap(baseComponent) {
    return new ComponentBuilder(baseComponent);
  }

  /**
   * Initializes a new instance of the ComponentBuilder class.
   * @param {Component} baseComponent - The component to wrap.
   */
  constructor(baseComponent) {
    this.baseComponent = baseComponent;
    this.propTypes = null;
    this.stateMapper = null;
    this.dispatchMapper = null;
    this.statePropMaps = [];
    this.styles = null;
    this.includeTheme = false;
  }

  /**
   * propType assignment.
   * @param {object} propTypes - Property types for the component.
   * @return {ComponentBuilder}
   */
  props(propTypes) {
    this.propTypes = propTypes;
    return this;
  }

  /**
   * Factory for creating a state-to-prop entry.
   * @param {string} stateKey - Key for the source property in the state tree.
   * @param {string} propKey - Key for the destination property.
   * @param {function} [map] - Function for additional property mapping.
   * @return {{ stateKey: string, propKey: string, map: function|undefined }}
   */
  createStateToPropMap(stateKey, propKey, map) {
    return {
      stateKey,
      propKey,
      map
    };
  }

  /**
   * Captures a state-to-prop value mapping.
   * @param {string} stateKey - Key for the source property in the state tree.
   * @param {string} propKey - Key for the destination property.
   * @param {function} [map] - Function for additional property mapping.
   * @return {ComponentBuilder}
   */
  stateToProp(stateKey, propKey, map) {
    this.statePropMaps.push(this.createStateToPropMap(stateKey, propKey, map));
    return this;
  }

  /**
   * Captures a custom function for creating a prop hash
   * built from the state provided.
   * @param {function} stateMapper - A function that receives `state` and outputs an object.
   * @return {ComponentBuilder}
   */
  stateToProps(stateMapper) {
    this.stateMapper = stateMapper;
    return this;
  }

  /**
   * Captures a custom function for creating a prop hash
   * built for dispatching actions.
   * @param {function} dispatchMapper - A function that receives `dispatch` and `getState`
   *                                    and returns a hash of functions.
   * @return {ComponentBuilder}
   */
  dispatchToProps(dispatchMapper) {
    this.dispatchMapper = dispatchMapper;
    return this;
  }

  /**
   * A component can opt into the style system and
   * include the site theme.
   * @param {object} styles - The style factory.
   * @return {ComponentBuilder}
   */
  withStyles(styles) {
    this.styles = styles;
    return this;
  }

  /**
   * Builds the component.
   * @return {React.ComponentType<any>}
   */
  build() {

    let component = this.baseComponent;

    // Tack on a propTypes to the component.
    component.propTypes = this.propTypes || {};

    // If styles have been specified, apply them.
    if (this.styles) {
      component = withStyles(this.styles)(component);
    }

    const baseStateMapper = this.stateMapper || EmptyPropMapper;
    let dispatchMapper;
    if (Core.Utils.isFunction(this.dispatchMapper)) {
      dispatchMapper = (dispatch, ownProps) => {
        const actualMapper = this.dispatchMapper || EmptyPropMapper;
        return actualMapper(window.shell, dispatch, ownProps);
      };
    }
    else {
      // If not a function then assume an object is used.  Be sure to
      // pass an empty object to avoid each component receiving a dispatch prop.
      // This prop can sometimes be passed through ...otherProps and cause errors.
      // https://react-redux.js.org/api/connect#mapdispatchtoprops-object--dispatch-ownprops--object
      dispatchMapper = this.dispatchMapper || {};
    }

    const statePropMaps = this.statePropMaps.slice();

    // Build a local state mapper to help with
    // individual key mapping.
    const stateMapper = (state, ownProps) => {
      const resultFromMapper = baseStateMapper(state, ownProps);
      const finalState = statePropMaps
        .reduce((acc, statePropMap) => {
          const stateValue = Core.Utils.get(state, statePropMap.stateKey);
          const stateMap = typeof(statePropMap.map) === 'function' ?
            statePropMap.map : x => x;
          acc[statePropMap.propKey] = stateMap(stateValue);
          return acc;
        }, resultFromMapper);
      return finalState;
    };

    // Apply redux connections.
    component = connect(stateMapper, dispatchMapper)(component);

    // Lastly give the component routing information.
    return withRouter(component);
  }

}

export default ComponentBuilder;