import React from 'react';
import { Button, DialogTitle, DialogContent, DialogActions, Dialog } from '@material-ui/core';

import styles from './styles';

const AppDialogDefaultTitle = ({ title }) => {
  if (!title) {
    return null;
  }
  const classes = styles();
  return (
    <DialogTitle className={classes.headerBackground}>
      {title}
    </DialogTitle>
  );
};

const AppDialogDefaultContent = ({ children }) =>
  ( <DialogContent dividers>{children}</DialogContent> );

const AppDialogDefaultActions = ({ actionButtons }) => {
  if (!actionButtons || actionButtons?.length === 0)
    return null;

  return (
    <DialogActions>
      {
        actionButtons.map((actionButton, key) =>
          <Button
            onClick={actionButton.action}
            color={actionButton.color || 'primary'}
            type={actionButton.type}
            disabled={actionButton.disabled}
            key={key}
          >
            {actionButton.title}
          </Button>
        )
      }
    </DialogActions>
  );
};

const AppDialog = ({
    children,
    title = '',
    actionButtons = [],
    width = 'lg',
    fullWidth = true,
    AppDialogTitle = AppDialogDefaultTitle,
    AppDialogContent = AppDialogDefaultContent,
    AppDialogActions = AppDialogDefaultActions,
    ...otherProps
  }) => {

  return (
    <Dialog
      {...otherProps}
      maxWidth={width}
      fullWidth={fullWidth}
    >
      <AppDialogTitle title={title} />
      <AppDialogContent>{children}</AppDialogContent>
      <AppDialogActions actionButtons={actionButtons} />
    </Dialog>
  )
};


export default AppDialog;