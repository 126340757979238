import ActionTypes from '../../ActionTypes';

const companyDocumentTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.CompanyDocumentTypes:
      return action.companyDocumentTypes;
    default:
      return state;
  }
};

export default companyDocumentTypes;