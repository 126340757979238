import ActionTypes from '../../ActionTypes';

const carrierDocumentTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.CarrierDocumentTypes:
      return action.carrierDocumentTypes;
    default:
      return state;
  }
};

export default carrierDocumentTypes;