import ActionTypes from '../../ActionTypes';

const freightCategoryTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.FreightCategoryTypesLoaded:
      return action.freightCategoryTypes;
    default:
      return state;
  }
};

export default freightCategoryTypes;