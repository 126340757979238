import ActionTypes from '../../ActionTypes';
import ActionBuilder from '../../ActionBuilder2';

const dispose = async (context) => {
  return {
    type: ActionTypes.CommissionCenter.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();