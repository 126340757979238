import React from "react";
import {Grid} from "@material-ui/core";
import AppCurrencyText from "../../../../core/components/text/AppCurrencyText";
import {Info} from "@material-ui/icons";
import "./css/CollectiveCostsBreakdown.css"

const CollectiveCostsBreakdown = (props) => {

    const Cell = ({label, value}) => {
        return (
            <div className={"collective-costs_cell"}>
                <span style={{color: "black"}}>{label}</span>
                <span style={{color: "blue"}}><AppCurrencyText value={value} /></span> {/* todo will probably need to compute color */}
            </div>
        )
    }

    return (
        <Grid container spacing={2} alignItems={"center"} >
            <Grid item xs={12}>
                <div className={"collective-costs_warning"} style={{}}>
                    <Info/>
                    <span>"Collective" fields below combine the original shipment's data from above with all displayed Multi-Transaction data</span>
                </div>
            </Grid>
            <Grid item xs={4}>
                <Cell label={"Collective Carrier Cost: "} value={props.data.curAdjCarrierCost}/>
            </Grid>
            <Grid item xs={4}>
                <Cell label={"Collective Customer Cost: "} value={props.data.curAdjCustomerCost}/>
            </Grid>
            <Grid item xs={4}>
                <Cell label={"Collective Gross Margin: "} value={props.data.curGrossMargin}/>
            </Grid>
        </Grid>
    )
}

export default CollectiveCostsBreakdown;
