import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import combineClassNames from '../../utils/combineClassNames';

const useStyles = color => makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette[color].main,
    borderColor: theme.palette[color].main,
    fontSize: 12,
    '&:hover': {
      borderColor: theme.palette[color].main,
      backgroundColor: theme.palette[color].main,
      color: theme.palette.white,
      outline: 'none'
    },
    '&:focus': {
      backgroundColor: theme.palette[color].main,
      borderColor: theme.palette[color].main,
      color: theme.palette.white,
      outline: 'none'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem'
    }
  },
  contained: {
    color: theme.palette.white,
    borderColor: theme.palette[color].main,
    backgroundColor: theme.palette[color].main,
    paddingLeft: 15,
    paddingRight: 15,
    '&:hover': {
      borderColor: theme.palette.white,
      backgroundColor: `${theme.palette[color].main}33`,
      color: theme.palette[color].main,
      outline: 'none'
    },
    '&:focus': {
      borderColor: theme.palette.white,
      backgroundColor: `${theme.palette[color].main}33`,
      color: theme.palette[color].main,
      outline: 'none'
    },
  },
  icon: {
    color: theme.palette['outlinedWarning'],
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

// TODO: Adrian: Per previous discussions, should `styles` be `classes`?
const AppMuiButton = ({ children, variant = 'outlined', color = 'primary', styles, ...otherProps }) => {

  const classes = useStyles(color)();

  const className = combineClassNames(
    variant === 'contained' ? classes.contained : classes.button,
    styles
  );

  return (
    <Button
      {...otherProps}
      className={className}
      variant={variant}
    >
      {children}
    </Button>
  )
}
export default AppMuiButton;