import React from 'react';
import AppText from './AppText';

const ensureDate = (value) =>
  value instanceof Date ?
    value : new Date(value);

const AppDateText = ({ value, format = 'L', ...otherProps }) => {

  const valueType = typeof(value);
  const shouldProcess = valueType === 'string' ||
    valueType === 'number' ||
    value instanceof Date;

  const dateValue = !shouldProcess ?
    value :
    ensureDate(value)
      .toMoment().utc()
      .format(format);

  return (
    <AppText value={dateValue} {...otherProps} />
  );
};

export default AppDateText;