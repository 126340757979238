import dispose from './dispose';
import loadAssociate from './loadAssociate'
import loadAssociateJacks from './loadAssociateJacks';
import loadAssociateNotes from './loadAssociateNotes';
import loadAssociateNoteTypes from './loadAssociateNoteTypes';
import loadRoles from './loadRoles';
import saveAssociate from './saveAssociate';
import saveAssociateNote from './saveAssociateNote';
import listAssociates from "./listAssociates";

export default {
  dispose,
  listAssociates,
  loadAssociate,
  loadAssociateJacks,
  loadAssociateNotes,
  loadAssociateNoteTypes,
  loadRoles,
  saveAssociate,
  saveAssociateNote,
};