import React from 'react';
import Core from '@atomos/core';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';

const AppMuiSimpleTable = (props) => {

  const {
    children,
    columns = [],
    data = [],
    tablePropMap = Core.Utils.identity
  } = props;

  const tableProps = tablePropMap({
    size: 'small'
  });

  const processedColumns = columns
    .map(makeColumnDescriptor);

  const hasData = Core.Utils.isArray(data) && data.length > 0;

  return (
    <TableContainer component={Paper}>
      <Table { ...tableProps }>
        {
          Core.Utils.isArray(processedColumns) && processedColumns.length > 0 &&
            <Header
              columns={processedColumns}
            />
        }
        {
          hasData &&
            <Body
              columns={processedColumns}
              data={data}
            />
        }
        {
          !hasData &&
            <TableBody>
              {children}
            </TableBody>
        }
      </Table>
    </TableContainer>
  );
}

const makeColumnDescriptor = (column) => {
  return !Core.Utils.isString(column) ?
    column : { title: column };
};

const Header = (props) => {

  const {
    columns
  } = props;

  const shouldDisplay = columns
    .some(c => Boolean(c.title));

  if (!shouldDisplay)
    return null;

  return (
    <TableHead>
      <TableRow>
        {
          columns.map((column, index) => {
            const {
              title,
              headerPropMap
            } = column;

            const headerProps = Core.Utils.isFunction(headerPropMap) ?
              headerPropMap(index, columns) : {};

            return (
              <TableCell key={index} {...headerProps}>{title}</TableCell>
            );
          })
        }
      </TableRow>
    </TableHead>
  );
};

const Body = (props) => {
  const {
    columns,
    data
  } = props;

  const shouldDisplay = columns
    .some(c => Core.Utils.isFunction(c.cellPropMap) ||
      Core.Utils.isFunction(c.valueMap));

  if (!shouldDisplay)
    return null;

  return (
    <TableBody>
      {
        data.map((item, itemIndex) => (
          <TableRow key={itemIndex}>
            {
              columns.map((column, colIndex) => {
                const { cellPropMap, valueMap } = column;
                const cellProps = Core.Utils.isFunction(cellPropMap) ?
                  cellPropMap(item, colIndex, data, columns) : {};
                const value = Core.Utils.isFunction(valueMap) ?
                  valueMap(item, colIndex, data, columns) : null;
                return (
                  <TableCell key={colIndex} { ...cellProps }>
                    { value }
                  </TableCell>
                )
              })
            }
          </TableRow>
        ))
      }
    </TableBody>
  );
};

export default AppMuiSimpleTable;