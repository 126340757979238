import ActionTypes from '../../../ActionTypes';
import ActionBuilder from "../../../ActionBuilder2";

const load = async (shell, offset, limit, sort) => {

  const { count, companies } = await shell.gateway.getCompaniesMissingPrimaryContact(offset, limit, sort);

  return {
    type: ActionTypes.Reporting.MissingPrimaryContacts.Loaded,
    companies,
    count
  };

};

export default ActionBuilder
  .for(load)
  .build();