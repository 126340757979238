import ActionTypes from '../../../ActionTypes';

const DefaultCounts = {
  working: 0,
  'awaiting-pod': 0,
  delivered: 0,
  dispute: 0,
  hold: 0,
  locked: 0
};

const trackedCounts = (state = DefaultCounts, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.LoadBoard.Disposed:
      return DefaultCounts;
    case ActionTypes.Shipment.LoadBoard.Loaded:
      return action.trackedCounts;
    default:
      return state;
  }
};

export default trackedCounts;