import { Button, Grid } from "@material-ui/core";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AppSwitch from "../../../../core/components/inputs/AppSwitch/AppMuiSwitch";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";
import useFieldValidator from "../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../crm/components/inputs/FieldError";
import { MultiTransValidationSchema } from "./MutliTransValidationSchema";

const MultiTransDialog = (props) => {

    const urlParams = useParams();
    const bolNumber = parseInt(urlParams?.id);

    const personalDispatch = useDispatch();
    const [isValid, setIsValid] = useState(false);

    const involvedCostOptions = [
        { display: "Both Carrier And Customer Cost", id: 1 },
        { display: "Customer Cost Only", id: 2 },
        { display: "Carrier Cost Only", id: 3 },
    ];

    const shipmentStatusOptions = [
        { display: "Delivered", id: 17 },
        { display: "Dispute", id: 10 },
        { display: "Claim", id: 20 },
    ];

    //invoiceData
    const [invoiceData, setInvoiceData] = useState(props.data.invoice);

    // form data
    const [formData, setFormData] = useState({
        involvedCostOption: involvedCostOptions[0],
        carrierCost: null,
        customerCost: null,
        reason: "",
        invoiceNotes: "",
        internalNotes: "",
        shipmentStatusOption: null,
        doNotPayCarrier: false
    });


    const fieldErrors = useFieldValidator(MultiTransValidationSchema, formData);

    useEffect(() => {
        MultiTransValidationSchema.validate(formData)
            .then(() => setIsValid(true))
            .catch((e) => setIsValid(false));
    }, [formData]);

    // Handlers
    const handleSubmit = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveNewMultiTransaction'));

            let submitDate = new Date();

            const adjustment = formData;
            adjustment.enteredDate = submitDate.toISOString().split('T')[0];
            adjustment.orderStatusId = formData.shipmentStatusOption.id;
            adjustment.bolNumber = bolNumber;
            adjustment.invoiceNotes = invoiceData.invoiceNotes;
            adjustment.internalNotes = invoiceData.financialNotes;

            await Promise.all([
                await window.shell.gateway.saveShipmentFinancialAdjustment(bolNumber, adjustment),
            ]);

        } catch (e) {
            console.log(e);
        } finally {
            props.onCloseDialog();
            personalDispatch(window.shell.actions.sys.processComplete('saveNewMultiTransaction'));
        }
    };

    const handleInvolvedCostOptionChange = (e) => {
        setFormData({
            ...formData,
            involvedCostOption: involvedCostOptions.find(option => option.id === e.target.value.id),
            carrierCost: e.target.value.id === 2 ? null : formData.carrierCost,
            customerCost: e.target.value.id === 3 ? null : formData.customerCost
        });
    };
    const handleShipmentStatusOptionChange = (e) => {
        setFormData({
            ...formData,
            shipmentStatusOption: shipmentStatusOptions.find(option => option.id === e.target.value.id)
        });
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleInvoiceChange = (e) => {
        setInvoiceData({
            ...invoiceData,
            [e.target.name]: e.target.value
        });
    };

    const handleDoNotPayCarrierChange = () => {
        setFormData({ ...formData, doNotPayCarrier: !formData.doNotPayCarrier });
    };

    return (
        <TgfDialog title={"New Mutli-Transaction"}
            onClose={props.onCloseDialog}
            width={1000}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Label editorId={"involvedCostOption"}>What costs are involved?</Label>
                    <DropDownList
                        id={"involvedCostOption"}
                        name={"involvedCostOption"}
                        data={involvedCostOptions}
                        textField="display"
                        dataItemKey="id"
                        value={formData.involvedCostOption}
                        onChange={handleInvolvedCostOptionChange}
                        style={{
                            width: "100%",
                        }}
                    // disabled={!isEditMode}
                    // tabIndex={12}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Label editorId={"carrierCost"}>Carrier Cost (Can be negative)</Label>
                    <NumericTextBox
                        id={"carrierCost"}
                        name={"carrierCost"}
                        onChange={handleChange}
                        value={formData.carrierCost}
                        format={"c2"}
                        disabled={Boolean(formData.involvedCostOption.id === 2)}
                        spinners={false}
                    />
                    <FieldError
                        shouldRender={Boolean(formData.involvedCostOption.id !== 2)}>{fieldErrors.carrierCost}</FieldError>
                </Grid>
                <Grid item xs={3}>
                    <Label editorId={"customerCost"}>Customer Cost (Can be negative)</Label>
                    <NumericTextBox
                        id={"customerCost"}
                        name={"customerCost"}
                        onChange={handleChange}
                        value={formData.customerCost}
                        format={"c2"}
                        disabled={(Boolean(
                            formData.involvedCostOption.id === 3
                        ))}
                        spinners={false}
                    />
                    <FieldError
                        shouldRender={Boolean(formData.involvedCostOption.id !== 3)}>{fieldErrors.customerCost}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <Label editorId={"reason"}>Reason For Entry (Max 400 characters)</Label>
                    <TgfTextArea
                        id={"reason"}
                        name={"reason"}
                        value={formData.reason}
                        maxLength={400}
                        onChange={handleChange}
                        rows={3}
                    />
                    <FieldError>{fieldErrors.reason}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"invoiceNotes"}>Invoice Notes (Appear on the customer's invoice)</Label>
                    <TgfTextArea
                        id={"invoiceNotes"}
                        name={"invoiceNotes"}
                        value={invoiceData.invoiceNotes}
                        maxLength={400}
                        onChange={handleInvoiceChange}
                        rows={3}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"financialNotes"}>Internal Notes (Available for internal viewing only)</Label>
                    <TgfTextArea
                        id={"financialNotes"}
                        name={"financialNotes"}
                        value={invoiceData.financialNotes}
                        maxLength={400}
                        onChange={handleInvoiceChange}
                        rows={3}
                    />
                </Grid>
                <Grid item xs={3} style={{ alignSelf: "end" }}>
                    <Label editorId={"shipmentStatusOption"}>What costs are involved?</Label>
                    <DropDownList
                        id={"shipmentStatusOption"}
                        name={"shipmentStatusOption"}
                        data={shipmentStatusOptions}
                        textField="display"
                        dataItemKey="id"
                        value={formData.shipmentStatusOption}
                        onChange={handleShipmentStatusOptionChange}
                        style={{
                            width: "100%",
                        }}
                    />
                    <FieldError>{fieldErrors.shipmentStatusOption}</FieldError>
                </Grid>
                <Grid item xs={9} style={{ paddingBottom: 4 }}>
                    <AppSwitch
                        checked={formData.doNotPayCarrier}
                        onChange={handleDoNotPayCarrierChange}
                        label={<span>Mark as "Do not pay Carrier" upon submitting this entry</span>}
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} style={{ paddingTop: 10 }}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type={"button"}
                                onClick={handleSubmit}
                                disabled={Boolean(!isValid)}
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className={"delete-button"}
                                type={"button"}
                                onClick={props.onCloseDialog}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </TgfDialog>
    );

};

export default MultiTransDialog;
