import React from 'react';
import { Card, CardContent, CardHeader, CardActions } from '@material-ui/core';

import fileCSS from './AppCard.appcss';
import combineClassNames from '../../../utils/combineClassNames';

const AppMuiCard = ({
  children,
  title,
  contentClass,
  height = '100%',
  cardActions,
  backgroundColor = false,
  bottomBorder = false,
  cardColor = 'white',
  cardHeader = false,
  leftBorder = false,
  rightBorder = false,
  topBorder = false,
  outline = false,
  cardContent = false,
  headerTextColor = null,
  ...props }) => {
  const useStyles = fileCSS(cardColor, height, headerTextColor);
  const classes = useStyles();

  return (
    <Card className={
      combineClassNames(
        classes.root,
        leftBorder && classes.leftBorder,
        rightBorder && classes.rightBorder,
        bottomBorder && classes.bottomBorder,
        topBorder && classes.topBorder,
        outline && classes.outline,
        backgroundColor && classes.backgroundColor,
      )}
    >
      {title && <CardHeader className={combineClassNames(classes.title, cardHeader && classes.headerColor)} title={title} {...props} />}
      <CardContent className={contentClass}>
        {children}
      </CardContent>
      {cardActions && <CardActions>
        {cardActions}
      </CardActions>
      }
    </Card>
  );
}

export default AppMuiCard;