import { Dialog } from "@progress/kendo-react-dialogs";
import PropTypes from "prop-types";
import { useEffect } from "react";

const TgfDialog = (props) => {
    useEffect(() => {
        document.body.classList.add("k-overflow-hidden");
        // cleanup
        return () => {
            document.body.classList.remove("k-overflow-hidden");
        };
    });

    return (
        <Dialog {...props}>
            {props.children}
        </Dialog>
    );
};

TgfDialog.propTypes = {
    // Header title.
    title: PropTypes.string,
    // onClose function to close the dialog box.
    onClose: PropTypes.func,
    // Desired with for dialog box
    width: PropTypes.number

};

export default TgfDialog;