import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";
import Core from '@atomos/core';

const loadAssociateMonthlyCommissionRates = async (shell, associateId, startDate, endDate) => {

  const associateCommissionRate = await shell.gateway.getMonthlyCommissionRate(
    associateId,
    startDate,
    endDate
  );

  return {
    type: ActionTypes.Reporting.MonthlyGrossMargin.MonthlyCommissionRateLoaded,
    associateCommissionRates: Core.Utils.orderBy(associateCommissionRate, ['commissionDate'], ['asc'])
  };

};

export default ActionBuilder
  .for(loadAssociateMonthlyCommissionRates)
  .build();