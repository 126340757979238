import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const deleteCompanyDocument = async (shell, companyId, documentId) => {
  await shell.gateway.deleteCompanyDocument(companyId, documentId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyDeleteDocument,
    documentId
  }
};

export default ActionBuilder
  .for(deleteCompanyDocument)
  .build();