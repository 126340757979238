import { Grid } from "@material-ui/core";
import { LibraryBooks } from "@material-ui/icons";
import { Label } from "@progress/kendo-react-labels";
import { useSelector } from "react-redux";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";

const BillingNotesCard = (props) => {
    const company = useSelector(state => state?.addressBook?.modification?.company);
    const handleChange = async (e) => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                [e.target.name]: e.target.value
            }
        });
        props.setIsDirty(true);
    };

    const handleCopyBillingNotesClick = async () => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                generalNote: company?.note
            }
        });
        props.setIsDirty(true);
    };

    const billingNotesCopyButton = (
        <AppIconButton
            Icon={LibraryBooks}
            tooltip={`Copy from company's primary notes field.`}
            onClick={handleCopyBillingNotesClick}
            disabled={!props.disableEdit}
        />
    );

    return (
        <AppMuiCard title={"General Billing Notes"} action={billingNotesCopyButton}>
            <Grid container alignItems={"center"} spacing={2}>
                <Grid item xs={12}>
                    <Label editorId={"generalNote"}>General Billing Notes</Label>
                    <TgfTextArea
                        id={"generalNote"}
                        name={"generalNote"}
                        rows={10}
                        value={props.data?.entity?.generalNote}
                        onChange={handleChange}
                        disabled={!props.disableEdit}
                    />
                </Grid>

            </Grid>
        </AppMuiCard>
    );
};

export default BillingNotesCard;
