import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider } from '@material-ui/core';

import AppCard from '../../../../../core/components/cards/AppCard';
import AppButton from '../../../../../core/components/AppButton';
import AppDrawer from '../../../../../core/components/AppDrawer';
import AppIntegerText from '../../../../../core/components/text/AppIntegerText';
import AppCurrencyText from '../../../../../core/components/text/AppCurrencyText';

const useStyles = makeStyles(theme => ({
  container: {
    width: 400,
    height: '100vh'
  },
  divider: {
    backgroundColor: theme.palette.tgfGrey.border,
    width: '100%',
    paddingTop: 1,
    paddingBottom: 1
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10
  },
  amount: {
    textAlign: 'right'
  }
}));

const PayProcessingDrawer = (props) => {
  const classes = useStyles();

  const {
    isOpen,
    shipmentCount,
    shipmentTotal,
    adjustmentCount,
    adjustmentTotal,
    onFinalizeClick,
    onCancelClick
  } = props;

  const cardActions = (
    <Grid container justify="flex-end" spacing={1}>
      <Grid item><AppButton onClick={onFinalizeClick}>Finalize</AppButton></Grid>
      <Grid item><AppButton onClick={onCancelClick}>Cancel</AppButton></Grid>
    </Grid>
  );

  return (
    <AppDrawer
      anchor="right"
      open={isOpen}
      onClose={onCancelClick}
      title="Finalize Statement"
      width="sm">
      <div className={classes.container}>
        <AppCard
          title="Finalized Statement"
          cardActions={cardActions}
        >
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} md={6} className={classes.title}><Typography variant="h5">Total Adjustments</Typography></Grid>
            <Grid item xs={12} md={6} className={classes.amount}>
              <Typography variant="h5" color="primary">
                <AppIntegerText value={adjustmentCount} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.title}><Typography variant="h5">Adjustment Payout</Typography></Grid>
            <Grid item xs={12} md={6} className={classes.amount}>
              <Typography variant="h5" color="primary">
                <AppCurrencyText value={adjustmentTotal} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.title}><Typography variant="h5">Total Shipments</Typography></Grid>
            <Grid item xs={12} md={6} className={classes.amount}>
              <Typography variant="h5" color="primary">
                <AppIntegerText value={shipmentCount} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.title}><Typography variant="h5">Shipment Payout</Typography></Grid>
            <Grid item xs={12} md={6} className={classes.amount}>
              <Typography variant="h5" color="primary">
                <AppCurrencyText value={shipmentTotal} />
              </Typography>
            </Grid>
            <Grid item xs={12}><Divider className={classes.divider} /></Grid>
            <Grid item xs={12} md={6} className={classes.title}><Typography variant="h5">Total Payout</Typography></Grid>
            <Grid item xs={12} md={6} className={classes.amount}>
              <Typography variant="h5" color="primary">
                <AppCurrencyText value={shipmentTotal + adjustmentTotal} />
              </Typography>
            </Grid>
          </Grid>
        </AppCard>
      </div>
    </AppDrawer>
  )
};

export default PayProcessingDrawer;