import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const deletePayPeriodAdjustment = async (shell, commissionAdjustment) => {

  // eslint-disable-next-line no-unused-vars
  const deleteAdjustment = await shell.gateway.deletePayPeriodAdjustment(commissionAdjustment.associateId, commissionAdjustment.id);

  // const {adjustments, count} = await shell.gateway.getAssociatePayPeriodCommissionAdjustments(commissionAdjustment.associateId, commissionAdjustment.startDate);

  return {
    type: ActionTypes.CommissionCenter.CommissionAdjustmentDeleted,
    // payPeriodAdjustments: adjustments,
    // payPeriodAdjustmentCount: count
  }
};

export default ActionBuilder
  .for(deletePayPeriodAdjustment)
  .build();
