import ActionTypes from "../../ActionTypes";

const payPeriodAdjustments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.CommissionCenter.Disposed:
      return [];
    case ActionTypes.CommissionCenter.PayPeriodCommissionAdjustmentLoaded:
    // case ActionTypes.CommissionCenter.CommissionAdjustmentDeleted:
      return action.payPeriodAdjustments;
    default:
      return state;
  }
};

export default payPeriodAdjustments;