import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";
import ShipmentDefaultCarrierMcNumbers from "../../ShipmentDefaultCarrierMcNumbers";
import ShipmentDefaultThirdParty from "../../ShipmentDefaultThirdParty";
import createMidnightDate from '../../../../core/utils/createMidnightDate';

const loadNewShipment = async (shell,
                               statusId,
                               freightCategoryTypeId,
                               companyCategoryTypeId,
                               hazardousStateId,
                               associate) => {

  const shipment = await shell.gateway.createShipment();
  shipment.statusId = statusId;
  shipment.freightCategoryId = freightCategoryTypeId;
  shipment.bolDate = createMidnightDate();
  shipment.associateId = associate.id;
  shipment.associate = associate;
  shipment.hazardousStateId = hazardousStateId;

  // retrieve OPEN-TL carrier as default for new shipment
  const carrier = await shell.gateway.getCarrier(ShipmentDefaultCarrierMcNumbers.Tl);
  if (!carrier)
    throw new Error(`Unable to find carrier with mcNumber [${ShipmentDefaultCarrierMcNumbers.Tl}].`);

  shipment.carrierMcNumber = carrier.mcNumber;
  shipment.carrier = carrier;

  // get the default third party company [Tallgrass Freight Co.]
  const { companies } = await shell.gateway.searchCompanies({
    filter: {
      companyName: ShipmentDefaultThirdParty.name,
      categoryTypeId: companyCategoryTypeId
    }
  });
  if (companies.length !== 1)
    throw new Error(`Unable to find billing company with name [${ShipmentDefaultThirdParty.name}].`);

  shipment.thirdPartyId = companies[0].companyId;

  return {
    type: ActionTypes.Shipment.Modification.NewShipmentLoaded,
    shipment
  }
}

export default ActionBuilder
  .for(loadNewShipment)
  .build();