import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { Typography, Grid, Divider } from '@material-ui/core';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppTooltip from '../../../../core/components/AppTooltip';

const useStyles = makeStyles((theme) => ({
  highlight: {
    fontWeight: 'bold',
    backgroundColor: '#FFFF00'
  },
  highlightTooltip: {
    fontWeight: 'bold',
    backgroundColor: '#FFFF00',
    color: theme.palette.black
  },
  highlightCheck: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.white
  },
  tooltipWidth: {
    width: 400,
    maxWidth: 400
  }
}));

const SearchLoadsListingTable = (props) => {

  const {
    searchTerm,
    ...otherProps
  } = props;

  const classes = useStyles();

  const notesTooltip = (generalNotes, rateConNotes) => {
    return (
      <Grid container spacing={2} alignItems="center" direction="column">
        {generalNotes && <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>General Notes</Typography>
          <Divider />
          {highlightCell(generalNotes, 'highlightTooltip')}
        </Grid>}
        {rateConNotes && <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>Rate Con Notes</Typography>
          <Divider />
          {highlightCell(rateConNotes, 'highlightTooltip')}
        </Grid>}
      </Grid>
    );
  };

  const highlightNoteCell = (highlightText, rateConNote) => {
    return searchTerm ?
      highlightText?.concat(rateConNote).includes(searchTerm) ? <div className={classes.highlightCheck}>
        <AppTooltip title={notesTooltip(highlightText, rateConNote)} classes={{ tooltip: classes.tooltipWidth }}>
          <CheckCircle />
        </AppTooltip>
      </div> : null
      : null
  };

  const highlightCell = (highlightText, highlightTooltip) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = highlightText?.split(new RegExp(`(${searchTerm})`, 'gi'));
    const isTooltip = highlightTooltip ? classes[highlightTooltip] : classes.highlight
    return (
      parts ?
        <span>
          {parts.map((part, i) =>
            <span key={i} className={part.toLowerCase() === searchTerm.toLowerCase() ? isTooltip : {}}>
              {part}
            </span>
          )}
        </span>
        : highlightText
    )
  };

  const GridColumns = [
    {
      title: 'Ship #',
      field: 'bolNumber',
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
    },
    {
      title: 'Pickup Date',
      field: 'bolDate',
      styles: {
        width: 80,
      },
      dataType: 'date'
    },
    {
      title: 'Customer',
      field: 'customerName',
      noSort: true,
      styles: {
        width: 150,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.customerId}/details`, record.customer?.name])
    },
    {
      title: 'Tracking #',
      field: 'proNumber',
      styles: {
        width: 90,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.proNumber)
    },
    {
      title: 'Ref #1',
      field: 'refNum1',
      styles: {
        width: 90,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.refNum1)
    },
    {
      title: 'Ref #2',
      field: 'refNum2',
      styles: {
        width: 90,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.refNum2)
    },
    {
      title: 'Ref #3',
      field: 'refNum3',
      styles: {
        width: 90,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.refNum3)
    },
    {
      title: 'Ref #4',
      field: 'refNum4',
      styles: {
        width: 90,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.refNum4)
    },
    {
      title: 'P/U Ref',
      field: 'pickupNumber',
      styles: {
        width: 90,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.pickupNumber)
    },
    {
      title: 'Del Ref',
      field: 'deliveryNumber',
      styles: {
        width: 100,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.deliveryNumber)
    },
    {
      title: 'Third Party #',
      field: 'ratingRefNumber',
      styles: {
        width: 100,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightCell(rowData.ratingRefNumber)
    },
    {
      title: 'Notes',
      field: 'note',
      noSort: true,
      styles: {
        width: 40,
      },
      dataType: 'component',
      component: ({ rowData }) => highlightNoteCell(rowData.note, rowData.rateConNote)
    }
  ];

  return (
    <AppDenseGrid
      columns={GridColumns}
      {...otherProps}
    />
  )
};

export default SearchLoadsListingTable;