import React from 'react';
import {Grid} from "@material-ui/core";
import ComponentBuilder from "../../../../core/ComponentBuilder";

import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiCard from "../../../../core/components/cards/AppCard";
import AppNumericInput from "../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";

import '../financialPage.scss';
import SecureField from "../../permissions/SecureField";

const AddedCustomerCosts = (props) => {

  const {
    customerDetentionAmount,
    customerLayoverAmount,
    customerReweighAmount,
    customerReclassAmount,
    customerLumpersAmount,
    customerOtherAmountLabel,
    customerOtherAmount,
    customerDetentionAmountError,
    customerLayoverAmountError,
    customerReweighAmountError,
    customerReclassAmountError,
    customerLumpersAmountError,
    customerOtherAmountLabelError,
    customerOtherAmountError,
    setFieldValue
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(`invoice.${key}`, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <AppMuiCard title={'Added Customer Costs'}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={6}><label htmlFor={'customerDetentionCost'}>Detention</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerDetentionAmount'}
                as={AppNumericInput}
                id={'customerDetentionCost'}
                decimalScale={2}
                value={customerDetentionAmount}
                onChange={handleFieldChange('customerDetentionAmount')}
              />
              <AppValidationFailure message={customerDetentionAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'customerLayoverCost'}>Layover</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerLayoverAmount'}
                as={AppNumericInput}
                id={'customerLayoverCost'}
                decimalScale={2}
                value={customerLayoverAmount}
                onChange={handleFieldChange('customerLayoverAmount')}
              />
              <AppValidationFailure message={customerLayoverAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'customerReweightCost'}>Reweigh</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerReweighAmount'}
                as={AppNumericInput}
                id={'customerReweightCost'}
                decimalScale={2}
                value={customerReweighAmount}
                onChange={handleFieldChange('customerReweighAmount')}
              />
              <AppValidationFailure message={customerReweighAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'customerReclassCost'}>Reclass</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerReclassAmount'}
                as={AppNumericInput}
                id={'customerReclassCost'}
                decimalScale={2}
                value={customerReclassAmount}
                onChange={handleFieldChange('customerReclassAmount')}
              />
              <AppValidationFailure message={customerReclassAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}><label htmlFor={'customerLumpersCost'}>Lumpers</label></Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerLumpersAmount'}
                as={AppNumericInput}
                id={'customerLumpersCost'}
                decimalScale={2}
                value={customerLumpersAmount}
                onChange={handleFieldChange('customerLumpersAmount')}
              />
              <AppValidationFailure message={customerLumpersAmountError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerOtherAmountLabel'}
                as={AppInput}
                id={'customerOtherDescription'}
                value={customerOtherAmountLabel || ''}
                inputProps={{
                  maxLength: 30
                }}
                onChange={handleFieldChange('customerOtherAmountLabel')}
              />
              <AppValidationFailure message={customerOtherAmountLabelError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerOtherAmount'}
                as={AppNumericInput}
                id={'customerOtherAmount'}
                decimalScale={2}
                value={customerOtherAmount}
                onChange={handleFieldChange('customerOtherAmount')}
              />
              <AppValidationFailure message={customerOtherAmountError}/>
            </Grid>
          </Grid>
        </AppMuiCard>
      </Grid>
    </React.Fragment>
  )
};

const propsAreEqual = (prevProps, nextProps) => {
  const isEqual = prevProps.customerDetentionAmount === nextProps.customerDetentionAmount &&
    prevProps.customerLayoverAmount === nextProps.customerLayoverAmount &&
    prevProps.customerReweighAmount === nextProps.customerReweighAmount &&
    prevProps.customerReclassAmount === nextProps.customerReclassAmount &&
    prevProps.customerLumpersAmount === nextProps.customerLumpersAmount &&
    prevProps.customerOtherAmountLabel === nextProps.customerOtherAmountLabel &&
    prevProps.customerOtherAmount === nextProps.customerOtherAmount &&
    prevProps.customerDetentionAmountError === nextProps.customerDetentionAmountError &&
    prevProps.customerLayoverAmountError === nextProps.customerLayoverAmountError &&
    prevProps.customerReweighAmountError === nextProps.customerReweighAmountError &&
    prevProps.customerReclassAmountError === nextProps.customerReclassAmountError &&
    prevProps.customerLumpersAmountError === nextProps.customerLumpersAmountError &&
    prevProps.customerOtherAmountLabelError === nextProps.customerOtherAmountLabelError &&
    prevProps.customerOtherAmountError === nextProps.customerOtherAmountError;

  return isEqual;
};

export default React.memo(ComponentBuilder
    .wrap(AddedCustomerCosts)
    .build(),
  propsAreEqual
);