import * as yup from "yup";
import Core from "@atomos/core";

export const associateValidationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    emailAddress: yup.string().required().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is invalid.'),
    password: yup.string().required(),
    businessPhone: yup.string().required().matches(Core.Text.PhoneRegExp, 'Business Phone is invalid.'),
    faxPhone: yup.string().nullable().transform((s) => s === '' ? null : s).matches(Core.Text.PhoneRegExp, 'Business Fax is invalid.'),
    roles: yup.array().required(),
    rateConBusinessPhone: yup.string().nullable().transform((s) => s === '' ? null : s).matches(Core.Text.PhoneRegExp, 'Rate Con phone is invalid.'),
    rateConFaxPhone:yup.string().nullable().transform((s) => s === '' ? null : s).matches(Core.Text.PhoneRegExp, 'Rate Con fax is invalid.'),
    rateConEmailAddress: yup.string().nullable().transform((s) => s === '' ? null : s).matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Rate Con email is invalid.'),
});
