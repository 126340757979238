import * as React from "react";
import Core from "@atomos/core";
import {TextBox} from "@progress/kendo-react-inputs";

const DebouncedTextBox = (props) => {
    const debounceWaitMs = props.debounceWaitMs ?? 400;

    const broadcastChange = React.useCallback(Core.Utils.debounce(props.onChange, debounceWaitMs), [props.onChange]);

    const handleChange = (e) => {
        broadcastChange(e.target.value);
    };

    return (
        <TextBox
            id={props.id ?? props.name} // set both id and name from either id or name
            name={props.name ?? props.id}
            onChange={handleChange}
            {...props}
        />
    );
};

export default DebouncedTextBox;