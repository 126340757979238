import ActionTypes from "../../ActionTypes";

const payPeriodShipmentCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.CommissionCenter.Disposed:
      return 0;
    case ActionTypes.CommissionCenter.PayPeriodShipmentLoaded:
      return action.payPeriodShipmentCount;
    default:
      return state;
  }
};

export default payPeriodShipmentCount;