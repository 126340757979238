import ActionTypes from '../../ActionTypes';

const tallgrassBillingCompany = (state = {}, action) => {
  switch(action.type) {
    case ActionTypes.AddressBook.TallgrassBillingCompany.Disposed:
      return null;
    case ActionTypes.AddressBook.TallgrassBillingCompany.Loaded:
      return action.company;
    default:
      return state;
  }
};

export default tallgrassBillingCompany;