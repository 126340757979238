import React from 'react';
import FullWidthLayout from '../../core/layouts/FullWidthLayout';
import LeftNav from './LeftNav';
import {usePageTitle} from "../../crm/components/customHooks/misc/usePageTitle";

const Admin = (props) => {
  usePageTitle("TGF: Admin Page");
  return (
    <FullWidthLayout SideNav={LeftNav} title={'Admin'}>

    </FullWidthLayout>
  );
}

export default Admin;