import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const loadRmisCarrierContacts = async (shell, mcNumber) => {

  const carrierContacts = await shell.gateway.getRmisCarrierContact({
    filter: {
      mcNumber: mcNumber
    }
  });

  return {
    type: ActionTypes.Carrier.Modification.RmisCarrierContacts.Loaded,
    carrierContacts
  }
};

export default ActionBuilder
  .for(loadRmisCarrierContacts)
  .build();