import dispose from './dispose';
import loadShipments from './loadShipments';
import loadAssociateMonthlyAdjustments from './loadAssociateMonthlyAdjustments';
import loadAssociateMonthlyCommissionRates from './loadAssociateMonthlyCommissionRates';
import loadAssociateMonthlyCommissionBreakdown from './loadAssociateMonthlyCommissionBreakdown';
import loadAssociateGrossMarginBreakdown from './loadAssociateGrossMarginBreakdown';

export default {
  dispose,
  loadShipments,
  loadAssociateMonthlyAdjustments,
  loadAssociateMonthlyCommissionRates,
  loadAssociateGrossMarginBreakdown,
  loadAssociateMonthlyCommissionBreakdown
};