import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const clearSnackbarMessage = (shell) => {
  return {
    type: ActionTypes.Sys.ClearSnackbarMessage
  };
};

export default ActionBuilder
  .for(clearSnackbarMessage)
  .build();