import autoProcessed from "./autoProcessed";
import qaFailed from "./qaFailed";
import qaReview from "./qaReview";
import automationDocuments from "./automationDocuments"
import closed from "./closed";
import doNotPayCarrier from "./doNotPayCarrier";

export default {
  autoProcessed,
  qaFailed,
  qaReview,
  automationDocuments,
  closed,
  doNotPayCarrier
};