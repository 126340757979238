import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import AppDialog from '../AppDialog';

const AppDocumentRemoveFile = ({ document, open, onClose, onConfirmClick }) => {

  const actionButtons = [
    {
      title: "Cancel",
      action: onClose
    },
    {
      title: "Confirm",
      action: onConfirmClick
    }
  ];
  const removeDocument = [
    {
      title: 'Doc. Type',
      value: document.type?.name
    },
    {
      title: 'Description',
      value: document.description
    },
    {
      title: 'Filename',
      value: document.filename,
      color: 'error'
    }
  ];

  return (
    <AppDialog
      title="Delete Confirmation"
      width={'xs'}
      open={open}
      onClose={onClose}
      actionButtons={actionButtons}
    >
      <Grid container alignItems="flex-start" spacing={1}>
        <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 15 }}>
          <Typography variant="h4">Are you sure you want to delete?</Typography>
        </Grid>
        {
          removeDocument.map((item, key) => {
            return (
              item.value && <React.Fragment key={key}>
                <Grid item xs={12} md={3}>
                  {item.title}
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography color={item.color}>{item.value}</Typography>
                </Grid>
              </React.Fragment>
            )
          }
          )
        }
      </Grid>
    </AppDialog >
  )
}
export default AppDocumentRemoveFile;