import React from 'react';
import ErrorPage from './ErrorPage';

const AccessDeniedPage = (props) => {

  return (
    <ErrorPage title={'Access Denied'}>
      You are not authorized to view this page.  If you feel this is in error
      please contact management.
    </ErrorPage>
  );
};

export default AccessDeniedPage;