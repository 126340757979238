import React from 'react';
import Grid from '@material-ui/core/Grid';
import Business from '@tgf-crm/business';

import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import ShipmentListingCard from './includes/ShipmentListingCard';
import DataSelectorCard from './includes/DataSelectorCard';
import PayProcessingCard from './includes/PayProcessingCard';
import PayConfigurationCard from './includes/PayConfigurationCard';
import PayProcessingDrawer from './includes/PayProcessingDrawer';
import AdjustmentListingCard from './includes/AdjustmentListingCard';
import AdjustmentDrawer from './includes/AdjustmentDrawer';
import './CommissionCenterContent.scss';
import ConfirmAdjustmentDeleteDialog from './includes/ConfirmAdjustmentDeleteDialog';
import ShipmentPaymentDrawer from './includes/ShipmentPaymentDrawer';
import ComponentBuilder from "../../../../core/ComponentBuilder";
import CommissionTypeNames from "../../../../hubs/commission/CommissionTypeNames";
import CommissionTypeLabels from './CommissionTypeLabels';
import PayConfigurationDrawer from "./includes/PayConfigurationDrawer";
import AdjustmentPreviousListDialog from "./includes/AdjustmentPreviousListDialog";
import isAce from "../../../../hubs/persona/selectors/isAce";
import {usePageTitle} from "../../customHooks/misc/usePageTitle";

const LoadTotalStatementProcessName = 'CommissionCenter.LoadTotalStatement';
const LoadCommissionRateProcessName = 'CommissionCenter.LoadCommissionRate';
const LoadAdjustmentsProcessName = 'CommissionCenter.LoadCommissionAdjustments';
const LoadShipmentsProcessName = 'CommissionCenter.LoadShipments';
const LoadPayStatementProcessName = 'CommissionCenter.LoadPayStatement';
const DeleteAdjustmentProcessName = 'CommissionCenter.DeleteAdjustment';
const SaveAdjustmentProcessName = 'CommissionCenter.SaveAdjustment';
const FinalizeStatementProcessName = 'CommissionCenter.FinalizeStatement';
const SaveCommissionRateProcessName = 'CommissionCenter.SaveCommissionRate';
const SaveInvoiceProcessName = 'CommissionCenter.SaveInvoice';

const CommissionShipment = () => {
  return {
    id: null,
    bolNumber: null,
    adjustedCarrierCost: null,
    fee: null,
    adjustedCustomerCost: null,
    totalMargin: null,
    loadType: null,
    carrierName: null,
    thirdPartyName: null,
    pickupDate: null,
    customerName: null,
    earnedPayout: null,
    lockedDate: null,
    repPaid: null,
    mcNumber: null,
    companyId: null
  };
};

const computePayPeriodOptions = (monthYearSelectedDate) => {
  const payPeriods = Business.Associate.PayPeriod.buildFromMonth(
    monthYearSelectedDate.getMonth(),
    monthYearSelectedDate.getFullYear());

  const payPeriodOptions = payPeriods.map(pp => {
    const range = pp.toString();
    return {
      title: range,
      value: range,
      startDate: pp.startDate,
      endDate: pp.endDate
    }
  });
  return payPeriodOptions;
}

const CommissionCenterPage = (props) => {
  const {
    isAce,
    associate,
    associateTotalStatement,
    associateCommissionRate,
    payPeriodAdjustments,
    payPeriodAdjustmentCount,
    payPeriodShipments,
    payPeriodShipmentCount,
    payStatementDocument,
    freightCategoryTypes,
    createCommissionAdjustment,
    deleteCommissionAdjustment,
    saveCommissionAdjustments,
    getPreviousCommissionAdjustments,
    finalizeStatement,
    saveCommissionRate,
    saveInvoice,
    loadAssociateTotalStatement,
    loadCommissionAdjustments,
    loadCommissionRate,
    loadPayStatementDocument,
    loadShipments,
    getPreviousCommissionRate,
    dispose,
    LeftNav,
    sendSnackbarMessage
  } = props;

  usePageTitle("TGF: Commission Center");

  const computePayPeriodShipment = (associateCommissionRate) => (shipment) => {

    const commissionShipment = CommissionShipment();

    let totalMargin = 0;
    let earnedPayout = 0;

    if (associateCommissionRate) {
      const commissionCalculator = new Business.Associate.CommissionCalculator(associateCommissionRate);
      earnedPayout = commissionCalculator.calculate(shipment.invoice);
      totalMargin = Business.Shipment.ShipmentMath.grossMargin(shipment.invoice);
    }

    const categoryType = freightCategoryTypes.find(fc => fc.id === shipment.freightCategoryId);

    commissionShipment.id = shipment.id;
    commissionShipment.bolNumber = shipment.bolNumber;
    commissionShipment.adjustedCarrierCost = shipment.invoice.adjustedCarrierCost;
    commissionShipment.adjustedCustomerCost = shipment.invoice.adjustedCustomerCost;
    commissionShipment.fee = totalMargin - earnedPayout;
    commissionShipment.totalMargin = totalMargin;
    commissionShipment.loadType = categoryType.name;
    commissionShipment.carrierName = shipment.carrier.name;
    commissionShipment.thirdPartyName = shipment.thirdParty?.name;
    commissionShipment.bolDate = shipment.bolDate;
    commissionShipment.customerName = shipment.customer.name;
    commissionShipment.earnedPayout = earnedPayout;
    commissionShipment.actualDeliveryDate = shipment.invoice.actualDeliveryDate;
    commissionShipment.repPaid = shipment.invoice.associateWasPaid || shipment.invoice.confirmedAssociateWasPaid;
    commissionShipment.mcNumber = shipment.carrier.mcNumber;
    commissionShipment.companyId = shipment.customer.id;

    return commissionShipment;
  }

  const computedShipments = payPeriodShipments.map(computePayPeriodShipment(associateCommissionRate))

  const [payProcessingDrawerIsOpen, setPayProcessingDrawerIsOpen] = React.useState(false);
  const [previousAdjustmentsOpen, setPreviousAdjustmentsOpen] = React.useState(false);
  const [payConfigurationDrawerIsOpen, setPayConfigurationDrawerIsOpen] = React.useState(false);
  const [editShipment, setEditShipment] = React.useState(null);
  const [commissionAdjustment, setCommissionAdjustment] = React.useState(null);
  const [deleteCandidateCommissionAdjustment, setDeleteCandidateCommissionAdjustment] = React.useState(null);
  const [previousCommissionAdjustment, setPreviousCommissionAdjustment] = React.useState(null);
  const [previousCommissionAdjustmentCount, setPreviousCommissionAdjustmentCount] = React.useState(0);
  const [monthYearSelectedDate, setMonthYearSelectedDate] = React.useState(new Date());
  const [payPeriodOptions, setPayPeriodOptions] = React.useState(computePayPeriodOptions(monthYearSelectedDate));
  const [payPeriod, setPayPeriod] = React.useState(new Date() <= payPeriodOptions[0].endDate ? payPeriodOptions[0] : payPeriodOptions[1]);
  const [selectedAssociate, setSelectedAssociate] = React.useState(!isAce ? associate : null);
  const [shipmentOffset, setShipmentOffset] = React.useState(0);
  const [shipmentLimit, setShipmentLimit] = React.useState(20);
  const [shipmentSort, setShipmentSort] = React.useState([['bolNumber', 'desc']]);
  const [adjustmentOffset, setAdjustmentOffset] = React.useState(0);
  const [adjustmentLimit, setAdjustmentLimit] = React.useState(20);
  const [adjustmentSort, setAdjustmentSort] = React.useState([['relatedBolNumber', 'desc']]);

  React.useEffect(() => {
    return dispose;
  }, []);

  React.useEffect(() => {
    if (payPeriod && selectedAssociate) {
      loadAssociateTotalStatement(selectedAssociate.id, payPeriod);
      loadCommissionRate(selectedAssociate.id, payPeriod);
      loadPayStatementDocument(selectedAssociate.id, payPeriod);
    }
  }, [payPeriod, selectedAssociate, loadAssociateTotalStatement, loadCommissionAdjustments, loadPayStatementDocument]);

  React.useEffect(() => {
    if (payPeriod && selectedAssociate) {
      loadShipments(selectedAssociate.id, payPeriod, shipmentOffset, shipmentLimit, shipmentSort);
    }
  }, [payPeriod, selectedAssociate, shipmentOffset, shipmentLimit, shipmentSort]);

  React.useEffect(() => {
    if (payPeriod && selectedAssociate) {
      loadCommissionAdjustments(selectedAssociate.id, payPeriod, adjustmentOffset, adjustmentLimit, adjustmentSort);
    }
  }, [payPeriod, selectedAssociate, adjustmentOffset, adjustmentLimit, adjustmentSort]);

  const unpaidShipmentAmount = associateTotalStatement?.unpaidShipmentAmount || 0;
  const unpaidShipmentCount = associateTotalStatement?.unpaidShipmentCount || 0;
  const unpaidAdjustmentAmount = associateTotalStatement?.unpaidAdjustmentAmount || 0;
  const unpaidAdjustmentCount = associateTotalStatement?.unpaidAdjustmentCount || 0;
  const paidShipmentAmount = associateTotalStatement?.paidShipmentAmount || 0;
  const paidShipmentCount = associateTotalStatement?.paidShipmentCount || 0;
  const paidAdjustmentAmount = associateTotalStatement?.paidAdjustmentAmount || 0;
  const paidAdjustmentCount = associateTotalStatement?.paidAdjustmentCount || 0;
  const paidAmount = paidShipmentAmount + paidAdjustmentAmount;
  const unpaidAmount = unpaidShipmentAmount + unpaidAdjustmentAmount;

  //Handles when the user changes pages within the table.
  const handleShipmentPageChange = (e, page) => {
    setShipmentOffset(page * shipmentLimit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleShipmentSortChange = (column) => {
    const [[columnName, order]] = shipmentSort;
    const changeOrder = (order === 'asc' && columnName === column) ? 'desc' : 'asc';

    setShipmentSort([[column, changeOrder]]);
  };
  const handleShipmentLimitChange = (e) => {
    setShipmentOffset(0);
    setShipmentLimit(e.target.value);
  };

  //Handles when the user changes pages within the table.
  const handleAdjustmentPageChange = (e, page) => {
    setAdjustmentOffset(page * adjustmentLimit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleAdjustmentSortChange = (column) => {
    const [[columnName, order]] = adjustmentSort;
    const changeOrder = (order === 'asc' && columnName === column) ? 'desc' : 'asc';

    setAdjustmentSort([[column, changeOrder]]);
  };
  const handleAdjustmentLimitChange = (e) => {
    setAdjustmentOffset(0);
    setAdjustmentLimit(e.target.value);
  };

  const handleMonthYearChange = (monthYearValue) => {
    const date = monthYearValue && monthYearValue.isValid() ?
      monthYearValue.toDate() : null;

    const payPeriods = computePayPeriodOptions(date);
    setMonthYearSelectedDate(date);
    setPayPeriodOptions(payPeriods);
    setPayPeriod(new Date() <= payPeriods[0].endDate ? payPeriods[0] : payPeriods[1]);
    setShipmentOffset(0);
    setAdjustmentOffset(0);
  };

  const handlePayPeriodChange = (payPeriodValue) => {
    setPayPeriod(payPeriodValue);
    setShipmentOffset(0);
    setAdjustmentOffset(0);
  };

  const handleAssociateChange = (associate) => {
    setSelectedAssociate(associate);
    setShipmentOffset(0);
    setAdjustmentOffset(0);
  };

  const handleAdjustmentEditClick = (adjustment) => {
    setCommissionAdjustment(adjustment);
  };

  const handleAdjustmentAddNewClick = () => {
    setCommissionAdjustment(createCommissionAdjustment(selectedAssociate.id, payPeriod));
  };

  const handleAdjustmentDeleteClick = (adjustment) => {
    setDeleteCandidateCommissionAdjustment(adjustment);
  };

  const handleCopyPreviousAdjustments = () => {
    const payPeriodDate = new Date(payPeriod.startDate);
    payPeriodDate.setDate(payPeriod.startDate.getDate() - 2);

    getPreviousCommissionAdjustments(selectedAssociate.id, payPeriodDate)
      .then(res => {
        setPreviousCommissionAdjustment(res.adjustments);
        setPreviousCommissionAdjustmentCount(res.count);
        setPreviousAdjustmentsOpen(true);
      });
  };

  const handleAdjustmentDrawerClose = () => {
    setCommissionAdjustment(null);
  };

  const handleAdjustmentDrawerSave = (adjustment) => {
    saveCommissionAdjustments(selectedAssociate.id, [adjustment])
      .then(() => {
        setCommissionAdjustment(null);
        return Promise.all([
          loadCommissionAdjustments(selectedAssociate.id, payPeriod, adjustmentOffset, adjustmentLimit, adjustmentSort),
          loadAssociateTotalStatement(selectedAssociate.id, payPeriod)
        ]);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Adjustment saved.' });
      });
  };

  const handleShowFinalizeStatementClick = () => {
    setPayProcessingDrawerIsOpen(true);
  };

  const handleFinalizeStatementClick = () => {
    finalizeStatement(selectedAssociate.id, payPeriod.startDate)
      .then(() => {
        setPayProcessingDrawerIsOpen(false);
        const loadPromises = [
          loadAssociateTotalStatement(selectedAssociate.id, payPeriod),
          loadCommissionAdjustments(selectedAssociate.id, payPeriod, adjustmentOffset, adjustmentLimit, adjustmentSort),
          loadPayStatementDocument(selectedAssociate.id, payPeriod),
          loadShipments(selectedAssociate.id, payPeriod, shipmentOffset, shipmentLimit, shipmentSort)
        ];
        return Promise.all(loadPromises);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Statement finalized.' })
      });
  };

  const handleFinalizeStatementCancelClick = () => {
    setPayProcessingDrawerIsOpen(false);
  };

  const handleEditConfigurationClick = () => {
    setPayConfigurationDrawerIsOpen(true);
  };

  const handleAdjustmentDeleteConfirmClick = () => {
    deleteCommissionAdjustment(deleteCandidateCommissionAdjustment)
      .then(() => {
        setDeleteCandidateCommissionAdjustment(null);
        const loadPromises = [
          loadCommissionAdjustments(selectedAssociate.id, payPeriod, adjustmentOffset, adjustmentLimit, adjustmentSort),
          loadAssociateTotalStatement(selectedAssociate.id, payPeriod)
        ];
        return Promise.all(loadPromises);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Adjustment deleted.' });
      });
  };

  const handleAdjustmentDeleteCancelClick = () => {
    setDeleteCandidateCommissionAdjustment(null);
  };

  const handlePayConfigurationDrawerClose = () => {
    setPayConfigurationDrawerIsOpen(false);
  }

  const handleEditShipmentClick = (shipment) => {
    setEditShipment(shipment);
  };

  const handleEditShipmentSaveClick = (shipment) => {
    const payPeriodShipment = payPeriodShipments.find(s => s.id === shipment.id);
    payPeriodShipment.invoice.associateWasPaid = shipment.repPaid;
    payPeriodShipment.invoice.confirmedAssociateWasPaid = shipment.repPaid;
    saveInvoice(payPeriodShipment.invoice)
      .then(() => {
        setEditShipment(null);
        const loadPromises = [
          loadAssociateTotalStatement(selectedAssociate.id, payPeriod),
          loadShipments(selectedAssociate.id, payPeriod, shipmentOffset, shipmentLimit, shipmentSort)
        ];
        return Promise.all(loadPromises);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Shipment saved.' });
      });
  };

  const handleEditShipmentCancelClick = () => {
    setEditShipment(null);
  };

  const handlePreviousAdjustmentCancelClick = () => {
    setPreviousAdjustmentsOpen(false);
  };

  const handlePayConfigurationSaveClick = (payConfiguration) => {
    saveCommissionRate(selectedAssociate.id, payConfiguration)
      .then(() => {
        setPayConfigurationDrawerIsOpen(false);
        loadAssociateTotalStatement(selectedAssociate.id, payPeriod)
          .then(() => {
            sendSnackbarMessage({ content: 'Pay configuration saved.' });
          });
      });
  };

  /**
   * Custom fetch for the previous commission rate record
   * to be dynamically loaded if the user clicks the copy button
   * in PayConfigurationDrawer.
   */
  const prevCommRateLoader = async () => {
    const prevCommDate = payPeriod.startDate
      .toMoment()
      .subtract(1, 'day')
      .toDate();
    return await getPreviousCommissionRate(selectedAssociate.id, prevCommDate);
  };

  const handleSavePreviousAdjustmentClick = (previousAdjustments) => {

    if (previousAdjustments && previousAdjustments.length > 0) {

      previousAdjustments.forEach(item => {
        item.id = 0;
        item.startDate = payPeriod.startDate;
        item.endDate = payPeriod.endDate;
        item.repPaid = 0;
        item.repPaidConfirmed = 0;
      });
      saveCommissionAdjustments(selectedAssociate.id, previousAdjustments)
        .then(() => {
          setPreviousAdjustmentsOpen(false);
          const loadPromises = [
            loadCommissionAdjustments(selectedAssociate.id, payPeriod, adjustmentOffset, adjustmentLimit, adjustmentSort),
            loadAssociateTotalStatement(selectedAssociate.id, payPeriod)
          ];
          return Promise.all(loadPromises);
        })
        .then(() => {
          sendSnackbarMessage({ content: 'Previous adjustments saved.' });
        });
    }

    setPreviousAdjustmentsOpen(false);
  };

  const canChangePayConfig = paidShipmentCount + paidAdjustmentCount === 0;
  const canFinalizeStatement = unpaidAdjustmentCount + unpaidShipmentCount > 0;

  return (
    <FullWidthLayout SideNav={LeftNav} title={'Commission Center'} >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DataSelectorCard
            onMonthYearChange={handleMonthYearChange}
            onPayPeriodChange={handlePayPeriodChange}
            onAssociateChange={isAce ? handleAssociateChange : null}
            monthYearSelectedDate={monthYearSelectedDate}
            payPeriodOptions={payPeriodOptions}
            payPeriod={payPeriod}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PayProcessingCard
            unpaidAmount={unpaidAmount}
            paidAmount={paidAmount}
            finalizeButtonDisabled={!canFinalizeStatement}
            onFinalizeStatementClick={isAce ? handleShowFinalizeStatementClick : null}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PayConfigurationCard
            paymentType={
              associateCommissionRate && associateCommissionRate.id > 0 && associateCommissionRate.commissionType ?
                associateCommissionRate.commissionType === CommissionTypeNames.Fee ?
                  CommissionTypeLabels.find(l => l.value === CommissionTypeNames.Fee).label :
                  CommissionTypeLabels.find(l => l.value === CommissionTypeNames.Margin).label :
                null
            }
            paymentPercentage={
              associateCommissionRate && associateCommissionRate.id > 0 && associateCommissionRate.commissionType ?
                associateCommissionRate.commissionType === CommissionTypeNames.Fee ?
                  associateCommissionRate.feePercent :
                  associateCommissionRate.marginPercent :
                null
            }
            showFeeInformation={associateCommissionRate?.id > 0 && associateCommissionRate?.commissionType === CommissionTypeNames.Margin}
            onEditConfigurationClick={isAce ? handleEditConfigurationClick : null}
            editButtonDisabled={!canChangePayConfig}
            payStatementDocument={payStatementDocument}
          />
        </Grid>
        <Grid item xs={12}>
          <AdjustmentListingCard
            adjustments={payPeriodAdjustments}
            adjustmentCount={payPeriodAdjustmentCount}
            associate={selectedAssociate}
            offset={adjustmentOffset}
            limit={adjustmentLimit}
            sort={adjustmentSort}
            onPageChange={handleAdjustmentPageChange}
            onLimitChange={handleAdjustmentLimitChange}
            onSortChange={handleAdjustmentSortChange}
            onEditClick={isAce ? handleAdjustmentEditClick : null}
            onDeleteClick={isAce ? handleAdjustmentDeleteClick : null}
            onAddNewAdjustment={isAce ? handleAdjustmentAddNewClick : null}
            onCopyPreviousAdjustment={isAce ? handleCopyPreviousAdjustments : null}
          />
        </Grid>
        <Grid item xs={12}>
          <ShipmentListingCard
            associateCommissionRate={associateCommissionRate}
            freightCategoryTypes={freightCategoryTypes}
            offset={shipmentOffset}
            limit={shipmentLimit}
            sort={shipmentSort}
            shipments={computedShipments}
            shipmentCount={payPeriodShipmentCount}
            onPageChange={handleShipmentPageChange}
            onLimitChange={handleShipmentLimitChange}
            onSortChange={handleShipmentSortChange}
            onEditShipmentClick={isAce ? handleEditShipmentClick : null}
          />
        </Grid>
      </Grid>
      <PayProcessingDrawer
        isOpen={payProcessingDrawerIsOpen}
        shipmentCount={unpaidShipmentCount}
        shipmentTotal={unpaidShipmentAmount}
        adjustmentCount={unpaidAdjustmentCount}
        adjustmentTotal={unpaidAdjustmentAmount}
        onFinalizeClick={handleFinalizeStatementClick}
        onCancelClick={handleFinalizeStatementCancelClick}
      />
      {
        Boolean(commissionAdjustment) &&
        <AdjustmentDrawer
          adjustment={commissionAdjustment}
          onCancelClick={handleAdjustmentDrawerClose}
          onSaveAdjustmentClick={handleAdjustmentDrawerSave}
        />
      }
      {
        Boolean(deleteCandidateCommissionAdjustment) &&
        <ConfirmAdjustmentDeleteDialog
          commissionAdjustment={deleteCandidateCommissionAdjustment}
          onConfirmClick={handleAdjustmentDeleteConfirmClick}
          onCancelClick={handleAdjustmentDeleteCancelClick}
        />
      }
      <ShipmentPaymentDrawer
        isOpen={Boolean(editShipment)}
        shipment={editShipment}
        onSaveClick={handleEditShipmentSaveClick}
        onCancelClick={handleEditShipmentCancelClick}
      />
      {
        previousAdjustmentsOpen &&
        <AdjustmentPreviousListDialog
          isOpen={previousAdjustmentsOpen}
          adjustments={previousCommissionAdjustment}
          adjustmentCount={previousCommissionAdjustmentCount}
          associate={selectedAssociate}
          onCancelClick={handlePreviousAdjustmentCancelClick}
          onSaveAdjustments={handleSavePreviousAdjustmentClick}
        />
      }
      <PayConfigurationDrawer
        isOpen={payConfigurationDrawerIsOpen}
        commissionRate={associateCommissionRate}
        onCancelClick={handlePayConfigurationDrawerClose}
        onSaveClick={handlePayConfigurationSaveClick}
        loadPrevCommissionRate={prevCommRateLoader}
      />
    </FullWidthLayout>
  )
}

export default ComponentBuilder
  .wrap(CommissionCenterPage)
  .stateToProps((state, ownProps) => ({
    associate: state.persona.associate,
    associateTotalStatement: state.commission.associateTotalStatement,
    associateCommissionRate: state.commission.associateCommissionRate,
    payPeriodAdjustments: state.commission.payPeriodAdjustments,
    payPeriodAdjustmentCount: state.commission.payPeriodAdjustmentCount,
    payPeriodShipments: state.commission.payPeriodShipments,
    payPeriodShipmentCount: state.commission.payPeriodShipmentCount,
    payStatementDocument: state.commission.payStatementDocument,
    freightCategoryTypes: state.support.freightCategoryTypes,
    isAce: isAce(state)
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async loadAssociateTotalStatement(associateId, payPeriod) {
        dispatch(shell.actions.sys.processStart(LoadTotalStatementProcessName));
        dispatch(await shell.actions.commission.loadAssociateTotalStatement(associateId, payPeriod.startDate));
        dispatch(shell.actions.sys.processComplete(LoadTotalStatementProcessName));
      },
      async loadCommissionRate(associateId, payPeriod) {
        dispatch(shell.actions.sys.processStart(LoadCommissionRateProcessName));
        dispatch(await shell.actions.commission.loadAssociateCommissionRate(associateId, payPeriod.startDate));
        dispatch(shell.actions.sys.processComplete(LoadCommissionRateProcessName));
      },
      async loadCommissionAdjustments(associateId, payPeriod, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadAdjustmentsProcessName));
        dispatch(await shell.actions.commission.loadPayPeriodAdjustments(associateId, payPeriod.startDate, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadAdjustmentsProcessName));
      },
      async loadShipments(associateId, payPeriod, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadShipmentsProcessName));
        dispatch(await shell.actions.commission.loadPayPeriodShipment(associateId, payPeriod.startDate, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadShipmentsProcessName));
      },
      async loadPayStatementDocument(associateId, payPeriod) {
        dispatch(shell.actions.sys.processStart(LoadPayStatementProcessName));
        dispatch(await shell.actions.commission.loadPayStatementDocument(associateId, payPeriod.startDate));
        dispatch(shell.actions.sys.processComplete(LoadPayStatementProcessName));
      },
      createCommissionAdjustment(associateId, payPeriod) {
        const commissionAdjustment = shell.gateway.createCommissionAdjustment();
        commissionAdjustment.associateId = associateId;
        commissionAdjustment.startDate = payPeriod.startDate;
        commissionAdjustment.endDate = payPeriod.endDate;
        return commissionAdjustment;
      },
      async deleteCommissionAdjustment(adjustment) {
        dispatch(shell.actions.sys.processStart(DeleteAdjustmentProcessName));
        dispatch(await shell.actions.commission.deletePayPeriodAdjustment(adjustment));
        dispatch(shell.actions.sys.processComplete(DeleteAdjustmentProcessName));
      },
      async saveCommissionAdjustments(associateId, adjustments) {
        dispatch(shell.actions.sys.processStart(SaveAdjustmentProcessName));
        dispatch(await shell.actions.commission.savePayPeriodAdjustment(associateId, adjustments));
        dispatch(shell.actions.sys.processComplete(SaveAdjustmentProcessName));
      },
      async getPreviousCommissionAdjustments(associateId, commissionDate, offset, limit, sort) {
        return await shell.gateway.getAssociatePayPeriodCommissionAdjustments(associateId, { commissionDate, offset, limit, sort });
      },
      async finalizeStatement(associateId, commissionStartDate) {
        dispatch(shell.actions.sys.processStart(FinalizeStatementProcessName));
        dispatch(await shell.actions.commission.finalizeStatement(associateId, commissionStartDate));
        dispatch(shell.actions.sys.processComplete(FinalizeStatementProcessName));
      },
      async saveCommissionRate(associateId, commissionRate) {
        dispatch(shell.actions.sys.processStart(SaveCommissionRateProcessName));
        dispatch(await shell.actions.commission.saveCommissionRate(associateId, commissionRate));
        dispatch(shell.actions.sys.processComplete(SaveCommissionRateProcessName));
      },
      async getPreviousCommissionRate(associateId, commissionDate) {
        return await shell.gateway.getCommissionRate(
          associateId,
          commissionDate
        );
      },
      async saveInvoice(invoice) {
        dispatch(shell.actions.sys.processStart(SaveInvoiceProcessName));
        dispatch(await shell.actions.commission.saveInvoice(invoice));
        dispatch(shell.actions.sys.processComplete(SaveInvoiceProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.commission.dispose());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();
