import Axios from 'axios';
import { EventEmitter } from 'events';

const SessionExpirationHeader = 'tgf-session-expiration';
const SessionExpiredEvent = 'session-expired';

/**
 * Provides communication to the CRM API gateway.
 */
class GatewayCommunicator extends EventEmitter {

  /**
   * Initializes a new instance of the communicator.
   */
  constructor(config) {
    super();
    this.config = config;

    const clientOptions = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const formDataClientOptions = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    clientOptions.baseURL = '/api/';
    formDataClientOptions.baseURL = '/api/';

    this.client = Axios.create(clientOptions);
    this.formDataClient = Axios.create(formDataClientOptions);
  }

  /**
   * Captures session expiration information to provide automatic
   * logging out of a user.
   * @param {object} headers - The response headers received.
   */
  async captureSessionHeaderResponse(headers) {
    if (headers[SessionExpirationHeader]) {
      const expSeconds = parseInt(headers[SessionExpirationHeader]);
      if (this.timerId) {
        clearTimeout(this.timerId)
      }
      this.timerId = setTimeout(() => {
        this.emit(SessionExpiredEvent);
      }, expSeconds * 1000)
    }
  }

  async invokeMethod(clientName, methodName, ...args) {
    try {
      const response = await this[clientName][methodName](...args);

      const {
        data,
        headers: resHeaders
      } = response;

      await this.captureSessionHeaderResponse(resHeaders);

      return data;
    }
    catch (error) {
      if (error.response?.status === 401) {
        this.emit(SessionExpiredEvent);
      }

      throw error;
    }

  }

  /**
   * Performs GET API requests to the CRM gateway.
   * @param {string} url - The URL to request.
   * @param {object} [params] - Optional params to apply to the query string.
   * @param {object} [headers] - Optional headers to apply to the request.
   */
  async get(url, params = {}, headers = {}) {
    return await this.invokeMethod('client', 'get', url, {
      params,
      headers
    });
  }

  /**
   * Performs GET API requests to the CRM gateway.
   * @param {string} url - The URL to request.
   * @param {object} [params] - Optional params to apply to the query string.
   * @param {object} [headers] - Optional headers to apply to the request.
   */
  async getBlob(url, params = {}, headers = {}) {
    return await this.invokeMethod('client', 'get', url, {
      params,
      headers,
      responseType: 'blob'
    });
  }

  /**
   * Performs POST API requests to the CRM gateway.
   * @param {string} url - The URL to post to.
   * @param {object} body - The data to post.
   * @param {object} [headers] - Optional headers to apply to the request.
   */
  async post(url, body, headers = {}) {
    return await this.invokeMethod('client', 'post', url, body, {
      headers
    });
  }

  /**
   * Performs POST API requests to the CRM gateway.
   * @param {string} url - The URL for the post operation.
   * @param {object} formData - The data to post using content type multipart/form-data for uploading files.
   * @param {object} [headers] - Optional headers to apply to the request.
   */
  async postFormData(url, formData, headers = {}) {
    return await this.invokeMethod('formDataClient', 'post', url, formData, {
      headers
    });
  }

  /**
   * Performs PUT API requests to the CRM gateway.
   * @param {string} url - The URL for the put operation.
   * @param {object} body - The data to put.
   * @param {object} [headers] - Optional headers to apply to the request.
   */
  async put(url, body, headers = {}) {
    return await this.invokeMethod('client', 'put', url, body, {
      headers
    });
  }

  /**
   * Performs DELETE API requests to the CRM gateway.
   * @param {string} url - The URL for the delete operation.
   * @param {object} [headers] - Optional headers to apply to the request.
   */
  async delete(url, headers = {}) {
    return await this.invokeMethod('client', 'delete', url, {
      headers
    });
  }

}

export default GatewayCommunicator;