import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

const AppMenuButton = (props) => {
  const {
    options, 
    document, 
    cardColor='default',
    disabled = false
  } = props;

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAction = (option) => () => {
    setAnchorEl(null);
    option.click(document);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{color: theme.palette[cardColor].contrastText}}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options
          .filter(option => option.isVisible)
          .map((option, key) => {
            if(!document.mimeType?.includes('pdf') && !document.mimeType?.includes('image') && option.label === 'Print') {
              return null;
            }
              return (
                  <MenuItem key={key} onClick={handleAction(option)}>
                    {option.label}
                  </MenuItem>
              )
            }
          )}
      </Menu>
    </div>
  );
}

export default AppMenuButton;