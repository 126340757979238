import React, {forwardRef} from "react";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Dashboard} from "@material-ui/icons";
import {Link} from "react-router-dom";
import "./CSS/ManualInvoiceReportPage.css"
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";

const DashboardLinkCell = (props) => {

    // UTC date string  => JS date obj w local time zone.
    const date = new Date(props.bolDate);
    // JS date obj w local time zone => JS Date w local time zone, but setting from UTC converted prev date obj values.
    const bolDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    const month = bolDate.getMonth() + 1;
    const year = bolDate.getFullYear();
    return (
        <td>

        <AppIconButton
            className={"manual-invoice-report_dashboard-link"}
            to={{
                pathname: `/admin/dashboard/hold-invoice-generated`,
                state: {
                    month,
                    year,
                    company: {
                        companyId: props.customerId,
                        companyName: props.customerName
                    }
                }
            }}
            Icon={Dashboard}
            component={Link}
            tooltip={'Process this customer on the dashboard.'}
        />
        </td>
    );
}

const ManualInvoicingReportGrid =  forwardRef((props, ref) => {
    const personalDispatch = useDispatch();

    const load = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadBlockedDates'));
            // DeliveryMethod === USPS || DeliveryMethod === OTHER ||

            return await window.shell.gateway.getManualInvoicedShipments(odata);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadBlockedDates'));
        }
    }

    const gridColumns = [
        {
            title: 'Rep',
            field: 'rep',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.associateId}/>
                </span>
              </td>,
            width: 40
        },
        {
            title: "Ship #",
            field: "bolNumber",
            type: "link",
            url: (props) => `/shipment/${props.bolNumber}/details`,
            width: 70,
        },
        {
            title: 'Pickup Date',
            field: 'bolDate',
            type: 'date',
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`
        },
        {
            title: 'Locked Date',
            field: 'confirmedLockDate',
            type: 'date',
        },
        {
            title: 'Cust. Cost',
            field: 'adjCustomerCost',
            type: 'currency',
        },
        {
            title: 'Type',
            field: 'deliveryMethod',
            type: 'text',
        },
        {
            title: 'Manual Review Reason',
            field: 'manualReviewDetails',
            type: 'custom',
            cell: (props) => <td className={"k-table-td show-all-text"}><span>{props.dataItem.manualReviewDetails ?? ' '}</span></td>
        },
        {
            title: 'AR Override',
            field: 'automationDisabled',
            type: 'custom',
            cell: (props) => {
                return <td><p style={{color: "red"}}>{props.dataItem.automationDisabled ? "YES" : null}</p></td>
            },
            width: 80,
        },
        {
            title: "",
            field: "",
            type: "custom",
            cell: (props) =>
                DashboardLinkCell(props.dataItem),
            width: 70,
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"manual-invoice_listing-grid"}
                    ref={ref}
                    onLoadDataPage={load}
                    gridColumns={gridColumns}
                    sort={[{field: "confirmedLockDate", dir: "asc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    )
})

export default ManualInvoicingReportGrid
