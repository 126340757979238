import ActionTypes from '../../../ActionTypes';

const usersCount = (state = 0, action) => {
  switch(action.type) {
    case ActionTypes.Admin.ManageUsers.Disposed:
      return 0;
    case ActionTypes.Admin.ManageUsers.ListingLoaded:
      return action.count;
    default:
      return state;
  }
};

export default usersCount;