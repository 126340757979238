import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadHelpDocuments = async (shell) => {

  const helpDocuments = await shell.gateway.getHelpDocuments();

  return {
    type: ActionTypes.Support.LoadHelpDocuments,
    helpDocuments
  };

};

export default ActionBuilder
  .for(loadHelpDocuments)
  .build();