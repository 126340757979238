import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {useSelector} from "react-redux";
import DateOnlyPicker from "../../../../../core/kendo/inputs/DateOnlyPicker";
import isAce from "../../../../../hubs/persona/selectors/isAce";
import "./ShipmentFinancialsInvoiceAgingCard.scss";

const ShipmentFinancialsInvoiceAgingCard = (props) => {
    const state = useSelector(state => state);
    const userIsAce = isAce(state);


    const formatDate = (date) => {
        // note this is displaying in UTC.
        const dateOptions = { timeZone: 'UTC', month: 'numeric', day: 'numeric', year: 'numeric' };
        const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
        return date ? dateFormatter.format(new Date(date)) : null;
    }

    const handleInvoiceSentDateChange = async (e) => {
        if(e.target.value) {
            props.setInvoiceSentDate(e.target.value);
        } else {
            props.setInvoiceSentDate(null);
        }
        props.setHasChanged(true);
    }

    useEffect(() => {
        if(props?.data?.sentDate) props.setInvoiceSentDate(new Date(props?.data?.sentDate));
    }, [props.data])

    return (
        <AppMuiCard
            title="Invoice Aging Information"
            height={"100px"}
        >
            <Grid container spacing={2} alignItems={"center"} className={"shipment-financials_invoice-aging-card"}>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={"shipment-financials_invoice-aging-card_row-header"}>
                        <Grid item xs={3} className={"row-header-item"}>
                            <p>Sent Date</p>
                        </Grid>
                        <Grid item xs={3} className={"row-header-item"}>
                            <p>Due Date</p>
                        </Grid>
                        <Grid item xs={3} className={"row-header-item"}>
                            <p>Past Due</p>
                        </Grid>
                        <Grid item xs={3} className={"row-header-item"}>
                            <p>Paid Off Date</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={"shipment-financials_invoice-aging-card_row"} alignItems={"center"}>
                        <Grid item xs={3} className={"row-item"}>
                            {userIsAce  && !props?.data?.paidOffDate ?
                                <DateOnlyPicker
                                    id={"earlyPickupDate"}
                                    name={"earlyPickupDate"}
                                    value={props.invoiceSentDate}
                                    onChange={handleInvoiceSentDateChange}
                                    disabled={props.disableEdit}
                                /> :
                                <p>{formatDate(props?.data?.sentDate) || "---"}</p>
                            }
                        </Grid>
                        <Grid item xs={3} className={"row-item"}>
                            <p>{formatDate(props?.data?.dueDate) || "---"}</p>
                        </Grid>
                        <Grid item xs={3} className={"row-item"}>
                            <p>{props?.data?.pastDue || "---"}</p>
                        </Grid>
                        <Grid item xs={3} className={"row-item"}>
                            <p>{formatDate(props?.data?.paidOffDate) || "---"}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default ShipmentFinancialsInvoiceAgingCard;