import ActionTypes from '../../../ActionTypes';

const associateNotes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Admin.ManageUsers.Disposed:
      return [];
    case ActionTypes.Admin.ManageUsers.AssociateNoteSaved:
      return [action.associateNote].concat(state);
    case ActionTypes.Admin.ManageUsers.AssociateNotesLoaded:
      return action.associateNotes;
    default:
      return state;
  }
};

export default associateNotes;