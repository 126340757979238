import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const deleteCarrierDocument = async (shell, mcNumber, documentId) => {
  await shell.gateway.deleteCarrierDocument(mcNumber, documentId);

  return {
    type: ActionTypes.Carrier.Modification.CarrierDeleteDocument,
    documentId
  }
};

export default ActionBuilder
  .for(deleteCarrierDocument)
  .build();