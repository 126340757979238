import ActionTypes from '../../../ActionTypes';

const carrierDocuments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Carrier.LoadBoard.Disposed:
      return [];
    case ActionTypes.Carrier.LoadBoard.LoadCarrierDocuments:
      return action.carrierDocuments;
    case ActionTypes.Carrier.Modification.CarrierDeleteDocument:
      return state.filter(sd => sd.id !== action.documentId);
    case ActionTypes.Carrier.Modification.CarrierPublishDocument:
      return [
        ...state,
        action.document
      ]
    default:
      return state;
  }
};

export default carrierDocuments;