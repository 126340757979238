import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const load = async (shell, offset, limit, sort) => {
  const { count, companies } = await shell.gateway.getMissingCreditApp(offset, limit, sort);

  return {
    type: ActionTypes.Reporting.MissingCreditApp.Loaded,
    count,
    companies
  };
};

export default ActionBuilder
  .for(load)
  .build();