import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import AgingShipmentHistoryPage from './areas/address-book/AgingShipmentHistoryPage';
import BillingSettingsPage from './areas/address-book/BillingSettingsPage';
import BillingSettingsPageNew from './areas/address-book/BillingSettingsPageNew/BillingSettingsPage';
import ContactsPage from './areas/address-book/ContactsPage';
import DetailsPage from './areas/address-book/DetailsPage';
import AddressBookDocumentsPage from './areas/address-book/DocumentsPage';
import FinancialHealthPage from './areas/address-book/FinancialHealthPage';
import AddressBookListingPage from './areas/address-book/ListingPage';
import NotesPage from './areas/address-book/NotesPage';
import AddressBookCompanyOutcomeStreamPage from './areas/address-book/OutcomeStreamPage/OutcomeStreamPage';
import ShipmentHistoryPage from './areas/address-book/ShipmentHistoryPage';
import CarrierCompliancePage from './areas/carriers/CompliancePage';
import CarrierDetailPage from './areas/carriers/DetailsPage';
import CarrierDocumentsPage from './areas/carriers/DocumentsPage';
import CarrierHistoryPage from './areas/carriers/HistoryPage';
import CarrierNotesPage from './areas/carriers/NotesPage';
import HomePage from './areas/home/HomePage';
import OpenLoadsPage from './areas/open-loads';
import ARShipmentFinancialsPage from "./areas/receivables/ar-shipment-financials";
import InvoiceMemoLineItemsPage from "./areas/receivables/invoice-memo-line-items";
import InvoiceMemosPage from "./areas/receivables/invoice-memos";
import PaymentApplicationsPage from "./areas/receivables/payment-applications";
import PaymentsPage from "./areas/receivables/payments";
import AuthorizedRoute from './areas/security/AuthorizedRoute';
import LoginPage from './areas/security/LoginPage';
import AccountingPage from "./areas/shipments/AccountingPage/AccountingPage";
import ShipmentAuditingPage from './areas/shipments/AuditingPage/AuditingPage';
import ShipmentContentsPage from './areas/shipments/ContentsPage/ContentsPage';
import ShipmentCreateDocsPage from './areas/shipments/CreateDocsPage/CreateDocsPage';
import ShipmentDetailsPage from './areas/shipments/DetailsPage/DetailsPage';
import ShipmentDocumentsPage from './areas/shipments/DocumentsPage/DocumentsPage';
import ShipmentDuplicationPage from './areas/shipments/DuplicationPage/DuplicationPage';
import ShipmentFinancialPage from './areas/shipments/FinancialPage/FinancialPage';
import NewShipmentFinancialPage from './areas/shipments/FinancialPageNew/FinancialPage';
import ShipmentListingPage from './areas/shipments/ListingPage/ListingPage';
import ShipmentLoadTrackingPage from './areas/shipments/LoadTrackingPage/LoadTrackingPage';
import MultiTransPage from "./areas/shipments/MultiTransPage/MultiTransPage";
import ShipmentMultipleStopsPage from './areas/shipments/MultipleStopsPage';


//Reporting route section
import CompanyGrossMarginPage from './areas/admin/CompanyGrossMargin';
import CarriersList from './areas/reporting/AllCarriers';
import CustomerReport from './areas/reporting/CustomerReport';
import InvoiceAging from './areas/reporting/InvoiceAging';
import InvoiceAgingSummaryPage from './areas/reporting/InvoiceAgingSummary/InvoiceAgingSummaryPage';
import LaneLookup from './areas/reporting/LaneLookUp';
import LastShipmentReport from "./areas/reporting/LastShipmentReport/LastShipmentReport";
import MissingCreditAppPage from './areas/reporting/MissingCreditApp';
import MissingPrimaryContactsPage from './areas/reporting/MissingPrimaryContacts';
import MonthlyGrossMarginPage from './areas/reporting/MonthlyGrossMargin';
import Reports from './areas/reporting/Reports';
import SearchLoads from './areas/reporting/SearchLoads';

//Admin route section
import Business from '@tgf-crm/business';
import Admin from './areas/admin';
import AutomatedInvoiceQueuePage from './areas/admin/AutomatedInvoiceQueue/AutomatedInvoiceQueuePage';
import AdminCommissionCenterPage from './areas/admin/CommissionCenter/CommissionsCenterPage';
import CommissionSummary from './areas/admin/CommissionsSummary';
import CustomerPaymentsPage from './areas/admin/CustomerPayments';
import DashboardPage from './areas/admin/Dashboard';
import HomeSettingsPage from "./areas/admin/HomeSettingsPage";
import InvoicingExceptionWorkflowQueuePage from './areas/admin/InvoicingExceptionWorkflowQueue/InvoicingExceptionWorkflowQueuePage';
import BOLs from './areas/bols';
import CarrierListingPage from './areas/carriers/CarrierListing/CarrierListingPage';
import Error404Page from './areas/errors/Error404Page';
import Help from './areas/help';
import ReportCommissionCenterPage from './areas/reporting/CommissionCenter/CommissionCenterPage';
import GlobalSnackbar from './crm/components/GlobalSnackbar';
import GlobalSpinner from './crm/components/GlobalSpinner';

import './App.scss';
import CollectionsPage from "./areas/address-book/CollectionsPage/CollectionsPage";
import FromToHistoryPage from "./areas/address-book/FromToHistory/FromToHistoryPage";
import NotesAutofillPage from "./areas/address-book/NoteAutofillPage/NotesAutofillPage";
import CollectorsAssignment from "./areas/admin/CollectorsAssignment/CollectorsAssignment";
import LockedDatesPage from "./areas/admin/LockedDates/LockedDatesPage";
import LockedDatesArBillingPage from "./areas/admin/LockedDatesArBilling/LockedDatesArBillingPage";
import ManageUsersDetailsPage from "./areas/admin/ManageUsers/DetailsPage/ManageUsersDetailsPage";
import ManageUsersListingPage from "./areas/admin/ManageUsers/ListingPage/ManageUsersListingPage";
import ManualInvoicingReportPage from "./areas/admin/ManualInvoiceingReport/ManualInvoicingReportPage";
import RiskReviewReport from "./areas/admin/RiskReviewReport/RiskReviewReport";
import StandardReportsPage from "./areas/admin/StandardReports/StandardReportsPage";
import AutoProcessedCarrierInvoicesReportPage from "./areas/admin/TeraCrunch/AutoProcessedCarrierInvoicesReport/AutoProcessedCarrierInvoicesReportPage";
import ClosedReportPage from "./areas/admin/TeraCrunch/Closed/ClosedReportPage";
import DoNotPayReportPage from "./areas/admin/TeraCrunch/DoNotPayReport/DoNotPayReportPage";
import QaFailedReportPage from "./areas/admin/TeraCrunch/QaFailedReport/QaFailedReportPage";
import QaManualReviewReportPage from "./areas/admin/TeraCrunch/QaManualReviewReport/QaManualReviewReportPage";
import UserSessions from "./areas/admin/UserSessions/UserSessions";
import AgentRewardsProgramReport from "./areas/admin/agentRewardsProgramReport";
import BadDebtReport from "./areas/admin/badDebtReport/BadDebtReport";
import OpenLoadsBoardPage from "./areas/open-load-board/open-load-board";
import AutoInvoiceReport from "./areas/reporting/AutoInvoiceReport/AutoInvoiceReport";
import MarginContribution from "./areas/reporting/MarginContribution/MarginContribution";
import VoidShipmentReport from "./areas/reporting/VoidShipmentReport/VoidShipmentReport";
import SecurityBlanket from "./areas/security/SecurityBlanket";
import ComponentBuilder from "./core/ComponentBuilder";
import './tgf-kendo-theme/dist/scss/index.scss';

// import { AgencyAdmin, ManageAgencyUsers } from "./areas/admin/AgencyAdmin"; // Franchise Mode

const RoleNames = Business.Associate.RoleNames;
const Noop = (props) => null;
const AdminOnlyRoles = [RoleNames.Ace];
const AllRoles = [RoleNames.Ace, RoleNames.Queen, RoleNames.Jack];

const App = (props) => {

    const {
        featureFlags,
        getFeatureState
    } = props;

    useEffect(() => {
        getFeatureState();
    }, []);

    return (
        <React.Fragment>
            <Switch>

                <Route path={'/login'} component={LoginPage} />

                <SecurityBlanket>
                    <Switch>
                        <AuthorizedRoute exact path={'/'} component={HomePage} roles={AllRoles} />
                        <AuthorizedRoute path={'/logout'} component={Noop} roles={AllRoles} />

                        <AuthorizedRoute path={'/address-book/listing/:categoryTypeId?'} component={AddressBookListingPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/details'} component={DetailsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/documents'} component={AddressBookDocumentsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/contacts'} component={ContactsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/notes'} component={NotesPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/billing'} component={BillingSettingsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/billing-new'} component={BillingSettingsPageNew} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/shipment-history'} component={ShipmentHistoryPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/aging-shipment-history'} component={AgingShipmentHistoryPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/financial-health'} component={FinancialHealthPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/from-to-history'} component={FromToHistoryPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/outcome-stream'} component={AddressBookCompanyOutcomeStreamPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/collections'} component={CollectionsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/address-book/:id/notes-autofill'} component={NotesAutofillPage} roles={AllRoles} />

                        <AuthorizedRoute path={'/carrier/listing'} component={CarrierListingPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/carrier/:id/details'} component={CarrierDetailPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/carrier/:id/compliance'} component={CarrierCompliancePage} roles={AllRoles} />
                        <AuthorizedRoute path={'/carrier/:id/documents'} component={CarrierDocumentsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/carrier/:id/history'} component={CarrierHistoryPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/carrier/:id/notes'} component={CarrierNotesPage} roles={AllRoles} />

                        {featureFlags?.loadPostings &&
                            <AuthorizedRoute path={'/open-loads-board/shipments'} component={OpenLoadsBoardPage} roles={AllRoles} />
                        }
                        {featureFlags?.loadPostings &&
                            <AuthorizedRoute path={'/open-loads-board/dat-postings'} component={OpenLoadsBoardPage} roles={AllRoles} />
                        }
                        {featureFlags?.loadPostings &&
                            <AuthorizedRoute path={'/open-loads-board/its-postings'} component={OpenLoadsBoardPage} roles={AllRoles} />
                        }

                        {!featureFlags?.loadPostings &&
                            <AuthorizedRoute path={'/open-loads'} component={OpenLoadsPage} roles={AllRoles} />
                        }

                        <AuthorizedRoute path={'/shipment/listing/:trackedSet'} component={ShipmentListingPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/create-docs'} component={ShipmentCreateDocsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/details'} component={ShipmentDetailsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/duplicate'} component={ShipmentDuplicationPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/contents'} component={ShipmentContentsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/load-tracking'} component={ShipmentLoadTrackingPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/documents'} component={ShipmentDocumentsPage} roles={AllRoles} />

                        {featureFlags?.newFinancials &&
                            <AuthorizedRoute path={'/shipment/:id/financial'} component={NewShipmentFinancialPage} roles={AllRoles} />
                        }
                        {!featureFlags?.newFinancials &&
                            <AuthorizedRoute path={'/shipment/:id/financial'} component={ShipmentFinancialPage} roles={AllRoles} />
                        }

                        <AuthorizedRoute path={'/shipment/:id/auditing'} component={ShipmentAuditingPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/multiple-stops'} component={ShipmentMultipleStopsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/accounting'} component={AccountingPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/shipment/:id/multi-transactions'} component={MultiTransPage} roles={AllRoles} />

                        <AuthorizedRoute exact path={'/bols'} component={BOLs} roles={AllRoles} />
                        <AuthorizedRoute exact path={'/support/help'} component={Help} roles={AllRoles} />

                        <AuthorizedRoute exact path={'/reports'} component={Reports} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/customer-report'} component={CustomerReport} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/invoice-aging'} component={InvoiceAging} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/invoice-aging-summary'} component={InvoiceAgingSummaryPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/lane-lookup'} component={LaneLookup} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/search-loads'} component={SearchLoads} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/monthly-gross-margin'} component={MonthlyGrossMarginPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/carriers-list'} component={CarriersList} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/missing-credit-app'} component={MissingCreditAppPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/commission-center'} component={ReportCommissionCenterPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/missing-primary-contacts'} component={MissingPrimaryContactsPage} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/margin-contribution'} component={MarginContribution} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/last-shipment-report'} component={LastShipmentReport} roles={AllRoles} />
                        <AuthorizedRoute path={'/reports/cancelled-voided-report'} component={VoidShipmentReport} roles={AllRoles} />
                        {featureFlags?.arReleaseHidden === false &&
                            <AuthorizedRoute path={'/reports/locked-shipments-report'} component={AutoInvoiceReport} roles={AllRoles} />
                        }

                        <AuthorizedRoute exact path={'/admin'} component={Admin} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/commission-summary'} component={CommissionSummary} roles={AdminOnlyRoles} />
                        <AuthorizedRoute exact path={'/admin/home-settings'} component={HomeSettingsPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/commission-center'} component={AdminCommissionCenterPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/customer-payments'} component={CustomerPaymentsPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/dashboard/:statusSet'} component={DashboardPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute exact path={'/admin/manage-users'} component={ManageUsersListingPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/manage-users-new/:id'} component={ManageUsersDetailsPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/company-gross-margin'} component={CompanyGrossMarginPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/automated-invoice-queue'} component={AutomatedInvoiceQueuePage} roles={AdminOnlyRoles} />
                        {featureFlags?.arReleaseHidden === false &&
                            <AuthorizedRoute path={'/admin/manual-invoicing-report'} component={ManualInvoicingReportPage} roles={AdminOnlyRoles} />
                        }
                        {featureFlags?.arReleaseHidden === true &&
                            <AuthorizedRoute path={'/admin/invoicing-exception-queue'} component={InvoicingExceptionWorkflowQueuePage} roles={AdminOnlyRoles} />
                        }
                        <AuthorizedRoute path={'/admin/agent-rewards-programs-report'} component={AgentRewardsProgramReport} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/qa-manual-review'} component={QaManualReviewReportPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/do-not-pay'} component={DoNotPayReportPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/qa-failed'} component={QaFailedReportPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/auto-processed'} component={AutoProcessedCarrierInvoicesReportPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/closed'} component={ClosedReportPage} roles={AdminOnlyRoles} />

                        <AuthorizedRoute path={'/admin/standard-reports'} component={StandardReportsPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/user-sessions'} component={UserSessions} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/locked-dates'} component={LockedDatesPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/locked-dates-billing'} component={LockedDatesArBillingPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/collectors-assignment'} component={CollectorsAssignment} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/bad-debt-report'} component={BadDebtReport} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/risk-review-report'} component={RiskReviewReport} roles={AdminOnlyRoles} />

                        <AuthorizedRoute path={'/receivables/invoice-memos'} component={InvoiceMemosPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/receivables/invoice-memo-line-items'} component={InvoiceMemoLineItemsPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/receivables/payments'} component={PaymentsPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/receivables/payment-applications'} component={PaymentApplicationsPage} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/receivables/ar-shipment-financials/:id'} component={ARShipmentFinancialsPage} roles={AdminOnlyRoles} />
                        {/* 
                        Franchise Mode Routes
                        <AuthorizedRoute exact path={'/admin/agency'} component={AgencyAdmin} roles={AdminOnlyRoles} />
                        <AuthorizedRoute path={'/admin/agency/manage-users'} component={ManageAgencyUsers} roles={AdminOnlyRoles} /> */}

                        <Route component={Error404Page} />
                    </Switch>
                </SecurityBlanket>
            </Switch>
            <GlobalSnackbar />
            <GlobalSpinner />
        </React.Fragment>
    );
};

export default ComponentBuilder.wrap(App)
    .stateToProps((state, ownProps) => ({ featureFlags: state.utility.featureFlags }))
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async getFeatureState() {
                dispatch(shell.actions.sys.processStart("App.featureFlags.load"));
                dispatch(await shell.actions.utility.loadFeatureFlags());
                dispatch(shell.actions.sys.processComplete("App.featureFlags.load"));
            }
        };
    })
    .build();
