import { createTheme } from '@material-ui/core';

import overrides from './overrides';
import palette from './palette';
import tgfStyles from './tgfStyles';
import typography from './typography';

const theme = createTheme({
    palette,
    typography,
    overrides,
    tgfStyles,
    zIndex: {
        appBar: 1200,
        drawer: 1100,
        snackbar: 12000,
        tooltip: 12000
    }
});

export default theme;