import React from 'react';
import AppText from './AppText';
import Core from '@atomos/core';

const AppBooleanText = (props) => {

  const {
    value = null,
    ...otherProps
  } = props;

  if (Core.Utils.isVoid(value)) {
    return (<AppText value={null} {...otherProps} />);
  }

  return (
    <AppText value={Boolean(value) ? 'Yes' : 'No'} {...otherProps} />
  );

};

export default AppBooleanText;