import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import "./ShipmentFinancialsCurrentBreakdownCard.scss";

const ShipmentFinancialsCurrentBreakdownCard = (props) => {

    const formatCurrency = (number) => {
        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(number);
    }
    const [mappedData, setMappedData] = useState({originalBalance: 0, amountReceived: 0, amountDue: 0});

    useEffect(() => {
        if(props?.data?.invoice?.invoiceDateTime) {
            setMappedData({
                originalBalance: props?.data?.invoice?.adjustedCustomerCost || 0,
                amountReceived: props?.data?.invoicesOnlyPaymentTotal || 0,
                amountDue: props?.data?.customerCostSubPayments || 0
            })
        } else {
            setMappedData({originalBalance: 0, amountReceived: 0, amountDue: 0})
        }
    }, [props?.data])

    return (
        <AppMuiCard
            title="Current Breakdown"
            height={"100px"}
        >
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={"shipment-financials_current-breakdown-card_row-header"}>
                        <Grid item xs={4} className={"row-header-item"}>
                            <p>Original Balance</p>
                        </Grid>
                        <Grid item xs={4} className={"row-header-item"}>
                            <p>Received</p>
                        </Grid>
                        <Grid item xs={4} className={"row-header-item"}>
                            <p>Remaining Balance</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={"shipment-financials_current-breakdown-card_row"}>
                        <Grid item xs={4} className={"row-item"}>
                            <p>{formatCurrency(mappedData.originalBalance)}</p>
                        </Grid>
                        <Grid item xs={4} className={"row-item"}>
                            <p>{formatCurrency(mappedData.amountReceived)}</p>
                        </Grid>
                        <Grid item xs={4} className={"row-item"}>
                            <p>{formatCurrency(mappedData.amountDue)}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default ShipmentFinancialsCurrentBreakdownCard;