import ActionTypes from '../../../ActionTypes';

const companyCount = (state = 0, action) => {
  switch(action.type) {
    case ActionTypes.AddressBook.Listing.Dispose:
      return 0;
    case ActionTypes.AddressBook.Listing.Loaded:
      return action.companyCount;
    default:
      return state;
  }
};

export default companyCount;