import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipment = async (shell, bolNumber) => {

  // Get the shipment's data for all the other queries.
  const shipment = await shell.gateway.getShipment(bolNumber);

  // Gather company and contacts data for the customer/shipper/consignee.
  const companies = [
    shipment.customerId,
    shipment.shipperId,
    shipment.consigneeId
  ];

  const companyPromises = companies
    .map(companyId => companyId && shell.gateway.getCompany(companyId));

  const companyContactPromises = companies
    .map(companyId => companyId && shell.gateway.getCompanyContacts(companyId));

  // Get the customer's credit data to determine credit remaining.
  const creditStatusPromise = shell.gateway.getCompanyCreditStatus(shipment.customerId);

  const allPromises = companyPromises
    .concat(companyContactPromises)
    .concat([creditStatusPromise]);

  const [
    customer,
    shipper,
    consignee,
    customerContacts,
    shipperContacts,
    consigneeContacts,
    customerCreditStatus
  ] = await Promise.all(allPromises);

  const action = {
    type: ActionTypes.Shipment.Duplication.ShipmentLoaded,
    shipment,
    customerCreditStatus,
    customer,
    shipper,
    consignee,
    customerContacts,
    shipperContacts,
    consigneeContacts
  };

  // If the shipment is multi-stop, gather the companies and their contacts.
  if (shipment.isMultipleStop) {

    const shipmentMultipleStops = await shell.gateway.getShipmentMultipleStop(bolNumber);

    const multiStopCompanies = shipmentMultipleStops
      .map(ms => ms.company);

    const multiStopContactPromises = multiStopCompanies
      .map(c => shell.gateway.getCompanyContacts(c.id));

    const multiStopCompanyContacts = await Promise.all(multiStopContactPromises);

    action.multiStopCompanies = multiStopCompanies;
    action.multiStopCompanyContacts = multiStopCompanyContacts;

  }

  return action;
};

export default ActionBuilder
  .for(loadShipment)
  .build();