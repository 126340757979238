import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const saveShipmentMultipleStop = async (shell, multipleStop, bolNumber) => {

  // eslint-disable-next-line no-unused-vars
  const saveShipmentMultipleStop = await shell.gateway.saveShipmentMultipleStop(multipleStop, bolNumber);

  const shipmentMultipleStops = await shell.gateway.getShipmentMultipleStop(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentMultipleStopsSaved,
    shipmentMultipleStops
  };

};

export default ActionBuilder
  .for(saveShipmentMultipleStop)
  .build();