import ActionTypes from '../../ActionTypes';

const trackedSetCounts = (state = InitialState, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.TrackedSetCountsLoaded:
      return action.trackedSetCounts;
    default:
      return state;
  }
};

const InitialState = {
  working: null,
  'awaiting-pod': null,
  delivered: null,
  dispute: null,
  hold: null,
  locked: null
};

export default trackedSetCounts;