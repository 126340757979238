import AppMuiCard from "../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from "react";
import SuccessIcon from "../../../../core/components/icons/SuccessIcon";
import FailIcon from "../../../../core/components/icons/FailIcon";

const RuleChecks = (props) => {

    return(
      <>
          <AppMuiCard title={'Rule Checks'}>
              <Grid container spacing={1}>
                  {
                      props.duplicationConstraints.map((constraint, index) => {
                          return (
                            <React.Fragment key={index}>
                                <Grid item xs={2}>
                                    {constraint.passed ? <SuccessIcon/> : <FailIcon/>}
                                </Grid>
                                <Grid item xs={10}>
                                    {constraint.title}
                                </Grid>
                            </React.Fragment>
                          );
                      })
                  }
              </Grid>
          </AppMuiCard>
      </>
    );
}

export default RuleChecks
