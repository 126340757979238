import React, {useEffect, useRef, useState} from "react";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {Grid} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import AppMuiButton from "../../../../../core/components/AppButton";
import {useDispatch} from "react-redux";
import CollectionContactsModal from "./CollectionContactsModal";

const editCell = (props, onClickEditContact) => {
    return <td>
        <AppIconButton
            Icon={Edit}
            tooltip={`Edit Date`}
            onClick={() => onClickEditContact(props.dataItem, props.dataIndex)}
        />
    </td>
}

const CollectionContacts = ({ companyId }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const personalDispatch = useDispatch();
  const ref = useRef();

  const refreshGrid = () => {
    ref.current.refresh();
  };

  const load = async (odata) => {
    try {
        personalDispatch(window.shell.actions.sys.processStart("loadCollectionContacts"));
          odata = odata ?? {
            top: 20,
            skip: 0,
            filter: {},
          };
          odata.filter.and = { customerId: companyId };
          // load data
          return await window.shell.gateway.getPageOfCollectorContacts(odata, companyId);
    } catch (e) {
        console.log(e);
        personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
          content: 'Failed To Load Collection Contacts',
          color: 'error'
        }));
    } finally {
        personalDispatch(window.shell.actions.sys.processComplete("loadCollectionContacts"));
    }
  };

  const onClickEditContact = (data) => {
    setSelectedContact(data);
    setEditDialogOpen(true);
  };

  const onClickAddContact = () => {
    setEditDialogOpen(true);
  };

  const onCloseDialog = () => {
    setEditDialogOpen(false);
    setSelectedContact(null);
    refreshGrid();
  };

  useEffect(() => {
      load();
  }, []);

  const gridColumns = [
    {
      title: "First Name",
      field: "firstName",
      type: "text",
    },
    {
      title: "Last Name",
      field: "lastName",
      type: "text",
    },
    {
      title: "Direct Phone",
      field: "directPhone",
      type: "text",
    },
    {
      title: "Mobile Phone",
      field: "mobilePhone",
      type: "text",
    },
    {
      title: "Fax",
      field: "fax",
      type: "text",
    },
    {
      title: "Email",
      field: "email",
      type: "text",
    },
    // edit cell
    {
      title: " ",
      field: "",
      type: "custom",
      cell: (cellProps) => editCell(cellProps, onClickEditContact),
      width: 40,
      sortable: false,
    },
  ];

  return (
    <AppMuiCard title={"Collection Contacts"}>
      <Grid container spacing={2}>
        <Grid item>
          <AppMuiButton type={"submit"} onClick={onClickAddContact}>
            + New Contact
          </AppMuiButton>
        </Grid>
        {editDialogOpen && (
          <CollectionContactsModal
            onCloseDialog={onCloseDialog}
            cellData={selectedContact}
            companyId={companyId}
          />
        )}
        <Grid item xs={12}>
          <TgfDataGrid
            className={"collection-contacts_listing-grid"}
            ref={ref}
            onLoadDataPage={load}
            gridColumns={gridColumns}
            // sort={[{ field: "firstName", dir: "desc" }]}
            pageable={{
              pageSize: 10,
              buttonCount: 10,
              pageSizes: [5, 10, 20, 50, 100, 500],
            }}
          />
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

export default CollectionContacts
