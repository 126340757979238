import ActionTypes from '../../../ActionTypes';

const companyAutofillFields = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.AddressBook.Modification.Disposed:
            return null;
        case ActionTypes.AddressBook.Modification.CompanyAutofillFieldsLoaded:
        case ActionTypes.AddressBook.Modification.CompanyAutofillFieldsSaved:
            return action.autofillFields;
        default:
            return state;
    }
};

export default companyAutofillFields;