import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadShipmentInvoice = async (shell, bolNumber) => {

  const invoice = await shell.gateway.getInvoice(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.InvoiceLoaded,
    invoice
  };
};

export default ActionBuilder
  .for(loadShipmentInvoice)
  .build();