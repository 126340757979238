import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CommandBuilder from '../../core/ComponentBuilder';
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from "../../core/hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    '& .MuiInputLabel-formControl': {
      marginTop: -18
    },
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.border,
      padding: '0 0 0 10px'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: '8px 4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.tgfGrey.border,
    }
  },
  inputRoot: {
    padding: 0
  },
  input: {
    width: '100%',
    position: 'relative',
    borderRadius: 4,
    backgroundColor: theme.palette.white,
    '& legend': {
      maxWidth: 0
    }
  },
}));

const CrmShipmentBolNumberSearchComboBox = (props) => {
  const classes = useStyles();

  const {
    staticContext,
    searchShipments,
    onChange,
    filterMap,
    placeholder,
    ...otherProps
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [shipmentBolNumbers, setShipmentBolNumbers] = React.useState([]);

  const handleSearchTermChange = async (value) => {
    setIsLoading(true);

    const searchTerm = value;
    let bolNumbers = [];

    if (searchTerm.trim().length > 1) {
      bolNumbers = await searchShipments(value);
    }

    setShipmentBolNumbers(bolNumbers);
    setIsLoading(false);
  };

  const handleOnChange = (e, bolNumberListing, reason) => {
    onChange(bolNumberListing);
  };

  const handleOnBlur = (e) => {
    const searchTerm = e.target.value;
    if (searchTerm.trim().length === 0) {
      setShipmentBolNumbers([]);
    }
  };

  const handleDebouncedTermChange = useDebounce(handleSearchTermChange, 450);

  return (
    <Autocomplete
      open={isOpen}
      classes={{
        root: classes.root
      }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={shipmentBolNumbers}
      loading={isLoading}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => option.toString()}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      clearOnBlur={false}
      {...otherProps}
      renderInput={
        (params) => (
          <TextField
            {...params}
            variant={'outlined'}
            onChange={(e) => handleDebouncedTermChange(e.target.value)}
            placeholder={placeholder}
            className={classes.input}
            InputProps={{
              ...params.InputProps
            }}
          />
        )
      }
    />
  );


};

export default CommandBuilder
  .wrap(CrmShipmentBolNumberSearchComboBox)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async searchShipments(searchTerm) {

        if (searchTerm.trim().length === 0)
          return [];

        const shipmentSearchOptions = {
          searchTerm
        };

        const shipmentBolNumbers = await shell.gateway.searchShipmentBolNumbers(shipmentSearchOptions);

        return shipmentBolNumbers;
      }
    }
  })
  .build();
