import React from 'react';
import FullWidthLayout from "../../core/layouts/FullWidthLayout";
import {Grid} from "@material-ui/core";
import TgfDataGrid from "../../core/kendo/dataGrid/TgfDataGrid";
import TgfLabeledInput from "../../core/kendo/inputs/TgfLabeledInput";
import DebouncedTextBox from "../../core/kendo/inputs/DebouncedTextBox";
import AppNumberText from "../../core/components/text/AppNumberText";
import {useLocation} from "react-router-dom";

const InvoiceMemosPage = (props) => {
    const ref = React.useRef();

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const initialFilter = query.get('filter') ? `=${query.get('filter')}` : '';
    //console.log(initialFilter);

    const [rowCount, setRowCount] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState(initialFilter);

    React.useEffect(() => {
        ref.current.refresh();
    }, [searchTerm]);

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 50,
            skip: 0
        };

        if(searchTerm != null && searchTerm.length > 0) {
            if(searchTerm.startsWith('=')) {
                odata.filter = {
                    odata: searchTerm.substring(1)
                };
            }
            else {
                odata.filter.or = {
                    // id: { : searchTerm},
                    // acumaticaId: { : searchTerm},
                    acumaticaRef: {substringof: searchTerm},
                    // shipmentId: { : searchTerm},
                    bolNumber: {eq: searchTerm},
                    shipmentStatus: {eq: searchTerm},
                    associateId: {eq: searchTerm},
                    // customerId: { : searchTerm},
                    customerAcumaticaRef: {substringof: searchTerm},
                    customerName: {substringof: searchTerm},
                    customerOrder: {substringof: searchTerm},
                    type: {eq: searchTerm},
                    hold: {eq: searchTerm},
                    amount: {eq: searchTerm},
                    balance: {eq: searchTerm},
                    taxTotal: {eq: searchTerm},
                    terms: {eq: searchTerm},
                    postPeriod: {eq: searchTerm},
                    isBillingPrinted: {eq: searchTerm},
                    billingSentDateTime: {eq: searchTerm},
                    dueDate: {eq: searchTerm},
                    status: {eq: searchTerm},
                    description: {substringof: searchTerm},
                    // createdDateTime: { : searchTerm},
                    // lastModifiedDateTime: { : searchTerm},
                    // acumaticaCreatedDateTime: { : searchTerm},
                    // acumaticaLastModifiedDateTime: { : searchTerm},
                    // acumaticaLastSyncDateTime
                };
            }
        }

        return odata;
    }

    const loadData = async (odata) => {
        let explicitFilter = null;
        if(typeof odata.filter.odata ) {
            explicitFilter = odata.filter.odata
        }

        const dataPage = await window.shell.gateway.getInvoiceMemos(odata, explicitFilter);
        setRowCount(dataPage.totalItemCount);
        setPageCount(dataPage.totalPageCount);
        return dataPage;
    }

    const GridColumns = [
        {field: "id", title: "Id", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaId", title: "Acumatica Id", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaRef", title: "Acumatica Ref", type: "link", url:(dataItem) => `/receivables/invoice-memo-line-items?filter=invoiceMemoAcumaticaRef eq '${dataItem.acumaticaRef}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerOrder", title: "Customer Order", type: "link", url:(dataItem) => `/receivables/payment-applications?filter=invoiceCustomerOrder eq '${dataItem.customerOrder}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "shipmentId", title: "Shipment Id", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "bolNumber", title: "Bol Number", type: "link", url:(dataItem) => `/shipment/${dataItem.bolNumber}/details`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "shipmentStatus", title: "Shipment Status", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "associateId", title: "Associate Id", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerId", title: "Customer Id", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerAcumaticaRef", title: "Customer Acumatica Ref", type: "link", url:(dataItem) => `/address-book/${dataItem.customerId}/details`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerName", title: "Customer Name", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "type", title: "Type", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "hold", title: "Hold", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "amount", title: "Amount", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "balance", title: "Balance", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "taxTotal", title: "Tax Total", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "terms", title: "Terms", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "postPeriod", title: "Post Period", type: "number" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "isBillingPrinted", title: "Is Billing Printed", type: "boolean" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "billingSentDateTime", title: "Date", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "dueDate", title: "Due Date", type: "dateFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "status", title: "Status", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "description", title: "Description", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "createdDateTime", title: "Created in CRM", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "lastModifiedDateTime", title: "Modified in CRM", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaCreatedDateTime", title: "Created in Acumatica", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaLastModifiedDateTime", title: "Modified in Acumatica", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaLastSyncDateTime", title: "Last Acumatica Data Sync", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"}
    ];

    const included = [
        // "id",
        // "acumaticaId",
        "acumaticaRef",
        // "shipmentId",
        "bolNumber",
        "shipmentStatus",
        "associateId",
        // "customerId",
        "customerAcumaticaRef",
        "customerName",
        "customerOrder",
        "type",
        "hold",
        "amount",
        "balance",
        "taxTotal",
        "terms",
        "postPeriod",
        "isBillingPrinted",
        "billingSentDateTime",
        "dueDate",
        "status",
        "description",
        // "createdDateTime",
        // "lastModifiedDateTime",
        // "acumaticaCreatedDateTime",
        // "acumaticaLastModifiedDateTime",
        // "acumaticaLastSyncDateTime"
    ];

    return (
        <FullWidthLayout title="Invoices and Memos">
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfLabeledInput
                        label={"Search"}
                        inputComponent={<DebouncedTextBox
                            id={"searchTerm"}
                            value={searchTerm}
                            onChange={handleSearchTerm}
                        />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfDataGrid
                        className={"tgf-data-grid"}
                        ref={ref}
                        onLoadDataPage={loadData}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                        onDataPageLoaded={props.onDataChanged}
                        gridColumns={GridColumns.filter(c => included.some(i => i === c.field))}
                        sort={[{field: "dueDate", dir: "desc"}]}
                        pageable={{
                            pageSize: 20,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500]
                        }}
                    />
                    <div style={{ padding: 10, backgroundColor: '#74747422' }}>
                        <span style={{float: 'left'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={rowCount} /> Rows
                        </span>
                        <span style={{float: 'right'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={pageCount} /> Pages
                        </span>
                        <span style={{clear: 'both'}}>&nbsp;</span>
                    </div>
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
};
export default InvoiceMemosPage