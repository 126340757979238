import React from 'react';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import LeftNav from '../../LeftNav';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {Grid} from '@material-ui/core';
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {CloseSharp, PriorityHigh} from "@material-ui/icons";
import QaFailedReportCloseDialog from "./includes/QaFailedReportCloseDialog";
import ListingFilterBar from "../Listing/ListingFilterBar";
import computeColor from "../includes/computeColor";

const LoadProcessName = 'Admin.QaFailedReport.Load';
const SetFailedProcessName = 'Admin.QaFailedReport.SetFailed';
// const ListDocumentsProcessName = 'Admin.QaFailedReport.ListDocuments';

const QaFailedReportPage = (props) => {

    const {
      loadFailed,
      setClosed,
      failedReview,
      qaFailedRecordCount,
      qaReviewRecordCount,
      listAutomationDocuments,
      listAllDispositionStatuses,
      fetchHardFailReasons,
      checkIfShipmentExistsInCrm
    } = props;


    const CloseCell = (partSelector) => {
      return ({rowData}) => {
        const value = partSelector(rowData);
        return (<AppIconButton
          style={{padding: 0}}
          Icon={CloseSharp}
          onClick={onCloseClick}
          value={value}
        />);
      }
    }

  const FailCell = (partSelector) => {
    return ({rowData}) => {
      return rowData.didHardFail ?
      < PriorityHigh style={{padding: 0, color: 'red'}}
      /> :
      null
    }
  }

    const onCloseClick = (e) => {
      setCurrentInteractiveRecord(JSON.parse(e.currentTarget.value))
    }

    const onCloseDialogCancelClick = () => {
      setCurrentInteractiveRecord(null)
    }

    const onCloseDialogSaveClick = async (disposition, notes) => {
      await setClosed(currentInteractiveRecord.id, disposition, notes)
      setCurrentInteractiveRecord(null)
      setModifyCount(modifyCount + 1);
    }


  const gridColumns = [
    {
      field: 'bolNumber',
      title: 'TC Ship #',
      styles: {
        width: '5%'
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
    },
    {
      field: 'inputCarrierName',
      title: 'TC Carrier Name',
      styles: {
        width: '8%',
        backgroundColor: computeColor('inputCarrierName')
      },
      dataType: 'string'
    },
    {
      field: 'targetCarrierName',
      title: 'Carrier Name Check',
      styles: {
        width: '8%',
        backgroundColor: computeColor('targetCarrierName')
      },
      dataType: 'string',
    },
    {
      field: 'inputRemitName',
      title: 'TC Remit Name',
      styles: {
        width: '8%',
        backgroundColor: computeColor("inputRemitName")
      },
      dataType: 'string'
    },
    {
      field: 'targetRemitName',
      title: 'Remit Name Check',
      styles: {
        width: '8%',
        backgroundColor: computeColor("targetRemitName")
      },
      dataType: 'string'
    },
    {
      field: 'inputAddress',
      title: 'TC Address 1',
      styles: {
        width: '7%',
        backgroundColor: computeColor("inputAddress")
      },
      dataType: 'string'
    },
    {
      field: 'targetAddress',
      title: 'Address 1 Check',
      styles: {
        width: '7%',
        backgroundColor: computeColor("targetAddress")
      },
      dataType: 'string'
    },
    {
      field: 'inputAddress2',
      title: 'TC Address 2',
      styles: {
        width: '7%',
        backgroundColor: computeColor("inputAddress2")
      },
      dataType: 'string'
    },
    {
      field: 'targetAddress2',
      title: 'Address 2 Check',
      styles: {
        width: '7%',
        backgroundColor: computeColor("targetAddress2")
      },
      dataType: 'string'
    },
    {
      field: 'inputCity',
      title: 'TC City',
      styles: {
        width: '5%',
        backgroundColor: computeColor("inputCity")
      },
      dataType: 'string'
    },
    {
      field: 'targetCity',
      title: 'City Check',
      styles: {
        width: '5%',
        backgroundColor: computeColor("targetCity")
      },
      dataType: 'string'
    },
    {
      field: 'inputState',
      title: 'TC State',
      styles: {
        width: '3%',
        backgroundColor: computeColor("inputState")
      },
      dataType: 'string'
    },
    {
      field: 'targetState',
      title: 'State Check',
      styles: {
        width: '3%',
        backgroundColor: computeColor("targetState")
      },
      dataType: 'string'
    },
    {
      field: 'inputZip',
      title: 'TC Zip',
      styles: {
        width: '3%',
        backgroundColor: computeColor("inputZip")
      },
      dataType: 'string'
    },
    {
      field: 'targetZip',
      title: 'Zip Check',
      styles: {
        width: '3%',
        backgroundColor: computeColor("targetZip")
      },
      dataType: 'string'
    },
    {
      field: 'didHardFail',
      title: 'Fail',
      styles: {
        width: '3%',
      },
      dataType: 'component',
      component: FailCell((record) => {
        return JSON.stringify({didHardFail: record.didHardFail})
      }, null)
    },
    {
      field: 'rejectedBy',
      title: 'Rejected By',
      styles: {
        width: '4%'
      },
      dataType: 'string'
    },
    {
      field: '',
      title: 'Close',
      styles: {
        width: '3%'
      },
      dataType: 'component',
      component: CloseCell((record) => {
        return JSON.stringify(record)
      }, null)
    }
  ];

    const [currentInteractiveRecord, setCurrentInteractiveRecord] = React.useState(null);

    const [filter, setFilter] = React.useState({});
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [sort, setSort] = React.useState([['bolNumber', 'asc']]);
    const [modifyCount, setModifyCount] = React.useState(0);

    React.useEffect(() => {
      loadFailed(
        {
          limit: limit,
          offset: offset,
          sort: sort,
        },
      );
      fetchHardFailReasons(1)

    }, [filter, offset, limit, sort, loadFailed, modifyCount]);

    const handleChangePage = (e, page) => {
      setOffset(page * limit);
    };
    const handleSortChange = (column) => {

      const [existingColumn, existingOrder] = sort[0];

      const order = column === existingColumn ?
        existingOrder === 'asc' ? 'desc' : 'asc' :
        'asc';

      setSort([[column, order]]);
    };
    const handleChangeRowsPerPage = (e) => {
      setOffset(0);
      setLimit(e.target.value);
    };

    return (
      <React.Fragment>
        <FullWidthLayout title={''} SideNav={LeftNav}>
          <ListingFilterBar index={2}
            qaReviewRecordCount={qaReviewRecordCount}
            qaFailedRecordCount={qaFailedRecordCount}>
          </ListingFilterBar>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AppDenseGrid
                  columns={gridColumns}
                  data={failedReview}
                  count={qaFailedRecordCount}
                  orderBy={sort[0][0]}
                  order={sort[0][1]}
                  rowsPerPage={limit}
                  page={offset / limit}
                  onSort={handleSortChange}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </FullWidthLayout>
        {currentInteractiveRecord &&
          <QaFailedReportCloseDialog
            onCancelClick={onCloseDialogCancelClick}
            onSaveClick={onCloseDialogSaveClick}
            listAutomationDocuments={listAutomationDocuments}
            activeRecord={currentInteractiveRecord}
            listAllDispositionStatuses={listAllDispositionStatuses}
            fetchHardFailReasons={fetchHardFailReasons}
            checkIfShipmentExistsInCrm={checkIfShipmentExistsInCrm}
          />}
      </React.Fragment>
    );
  }
;

export default ComponentBuilder
  .wrap(QaFailedReportPage)
  .stateToProps((state) => ({
    failedReview: state.automation.qaFailed.qaFailed,
    qaFailedRecordCount: state.automation.qaFailed.qaFailedCount,
    qaReviewRecordCount: state.automation.qaReview.qaReviewCount,
  }))
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async loadFailed(options) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.automation.qaFailed.loadQaFailed(options));
        // for record count
        dispatch(await shell.actions.automation.qaReview.loadQaReview());
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async setClosed(id, dispositionStatus, note) {
        dispatch(shell.actions.sys.processStart(SetFailedProcessName));
        dispatch(await shell.actions.automation.qaFailed.setQaFailed(id, dispositionStatus, note));
        dispatch(shell.actions.sys.processComplete(SetFailedProcessName));
      },
      async listAutomationDocuments(bolNumber) {
        return shell.gateway.listAutomationDocuments(bolNumber);
      },
      async listAllDispositionStatuses() {
        return shell.gateway.listAllDispositionStatuses();
      },
      async fetchHardFailReasons(bolNumber) {
        return (await shell.gateway.getHardFailReasons(bolNumber)).hardFails;
      },
      async checkIfShipmentExistsInCrm(bolNumber) {
        const shipmentNumberFilter = {
          bolNumber: {
            $like: `${bolNumber}%`
          }
        };

        const shipmentNumberSearchOptions = {
          filter: shipmentNumberFilter,
          limit: 1
        };

        return shell.gateway.getAllShipments(shipmentNumberSearchOptions);
      }
    }
  })
  .build();