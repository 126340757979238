import * as yup from "yup";
import DateUtils from "../../../../../core/utils/dateUtils/dateUtils";

const alphaOnlyRegex = /^([^0-9]*)$/

export const datValidationSchema =  yup.object().shape({
    earlyPickupDate: yup.date().required().test({
        test(value) {
            //if attempting to add a new record, and we set a value.
            if(!this.parent.id && value) {
                if(DateUtils.isPastDate(value)) {
                    return this.createError({message: "Cannot be a past date"});
                }
            }
            return true
        }
    }).transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    latePickupDate: yup.date().nullable().test({
        test(value) {
            if(value !== null && value !== undefined) {
                if (value < this.parent.earlyPickupDate) {
                    return this.createError({message: "Late Pickup Date cannot be before Early Pickup Date"})
                }
            }

            return true
        }
    }).transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    originCity: yup.string().required().matches(alphaOnlyRegex, 'No numeric characters allowed'),
    originState: yup.string().required(),
    destinationCity: yup.string().required().matches(alphaOnlyRegex, 'No numeric characters allowed'),
    destinationState: yup.string().required(),
    equipmentType: yup.string().required(),
    lengthFt: yup.number().required().min(1).max(199).transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    weightLbs: yup.number().required().min(1).max(999999).transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    comments: yup.string().required("Your TGF extension information is required to be entered here, at a minimum.").max(70),
    postedAmountUsd: yup.number().max(100000).test({
        test(value) {
            if (value && value < 1) {
                return this.createError({message: "Must be greater than or equal to $1.00"})
            }
            return true
        }
    }).nullable().transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    estMiles: yup.number().nullable(),
});
