import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const load = async (shell, filter, offset, limit, sort) => {

  const carriers = await shell.gateway.getAllCarriers({
    filter,
    offset,
    limit,
    sort
  });

  return {
    type: ActionTypes.Carrier.Listing.Loaded,
    carriers: carriers.carriers,
    carrierCount : carriers.count
  }
};

export default ActionBuilder
  .for(load)
  .build();