import React from 'react';
import {Grid} from '@material-ui/core';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import {useSelector} from "react-redux";
import TgfDataGrid from "../../../core/kendo/dataGrid/TgfDataGrid";
import isAceSelector from "../../../hubs/persona/selectors/isAce";
import {useEffect} from "react";
import "./UserSessions.scss"
import LeftNav from "../LeftNav";
import TgfUserAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfUserAutoComplete";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const UserSessions = () => {

    usePageTitle("TGF: User Sessions");

    const GridColumns = [
        {
            field: 'crmId',
            title: 'Rep',
            type: 'text',
            width: 40,
        },
        {
            field: 'firstName',
            title: 'First Name',
            type: 'text',
            width: 120,
        },
        {
            field: 'lastName',
            title: 'Last Name',
            type: 'text',
            width: 120,
        },
        {
            field: 'isCurrentlyActive',
            title: 'Is Currently Active',
            type: 'text',
            width: 200,
        },
        {
            field: 'startTime',
            title: 'Session Start',
            type: 'dateTimeFromUTCDateTime',
            width: 260,
        },
        {
            field: 'endTime',
            title: 'Session End',
            type: 'dateTimeFromUTCDateTime',
            width: 260,
        },
    ];

    const ref = React.useRef();

    const isAce = useSelector(state => isAceSelector(state));
    const [filterAssociate, setFilterAssociate] = React.useState(null);

    useEffect(() => {
        filterAssociate && ref.current.refresh();
    }, [filterAssociate]);

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};
        filter.agentId = filterAssociate;

        odata.filter = filter;
        return odata;
    }

    const loadGridData = async (odata) => {
        const page = await window.shell.gateway.getUserSessions(odata)
        return page;
    };

    const handleFilterAssociateChange = (e) => {
        if (!e.target?.value) return setFilterAssociate(null);
        setFilterAssociate(e.target.value.id);
    }

    return (
      <FullWidthLayout SideNav={LeftNav} title="User Sessions">
          <Grid container spacing={2} alignItems={"flex-end"} style={{marginBottom: 20}}>
              <Grid item xs={4}>
                  {
                    isAce &&
                    <TgfUserAutoComplete
                      label={"Search Users"}
                      id={"filterAssociate"}
                      name={"filterAssociate"}
                      onChange={handleFilterAssociateChange}
                    />
                  }
              </Grid>

          </Grid>
          <Grid container spacing={2} direction="column">
              <Grid item>
                  <TgfDataGrid
                    className={"user-sessions-grid"}
                    ref={ref}
                    onLoadDataPage={loadGridData}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    gridColumns={GridColumns}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500],
                    }}
                    sort={[{field: "startTime", dir: "desc"}]}
                  />
              </Grid>
          </Grid>
      </FullWidthLayout>
    )
}
export default UserSessions
