import ActionTypes from "../../../ActionTypes";

const shipmentLockedAssociate = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.Disposed:
      return null;
    case ActionTypes.Shipment.Modification.ShipmentLockedAssociateLoaded:
      return action.associate;
    default:
      return state;
  }
};

export default shipmentLockedAssociate;