import AppMuiCard from "../../../../core/components/cards/AppCard";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";

const renderCompanyOption = (company, index) => {
    return (
      <Grid container item xs={12} key={index}>
          <Grid item xs={3}>
              {company.type}
          </Grid>
          <Grid item xs={9}>
              <Link to={`/address-book/${company.id}/details`}>{company.name}</Link>
          </Grid>
      </Grid>
    );
};

const Companies = (props) => {

    return(
      <>
          <AppMuiCard title={'Companies'}>
              <Grid container spacing={1}>
                  {props.coreCompanyOptions.map(renderCompanyOption)}
              </Grid>
              {
                props.multiStopCompanyOptions?.length > 0 &&
                <Grid container spacing={1} style={{marginTop: 15}}>
                    <Typography variant={'h5'}>
                        Multi-Stop Locations:
                    </Typography>
                    {
                        props.multiStopCompanyOptions.map(renderCompanyOption)
                    }
                </Grid>
              }
          </AppMuiCard>
      </>
    );
}

export default Companies
