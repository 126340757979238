import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadTgfMonthlyMetrics = async (shell) => {

  const metrics = await shell.gateway.getTgfMonthlyMetrics();

  return {
    type: ActionTypes.Persona.LoadedTgfMonthlyMetrics,
    metrics
  };

};

export default ActionBuilder
  .for(loadTgfMonthlyMetrics)
  .build();