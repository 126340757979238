import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyFirstShipment = async (shell, companyId) => {

  const firstShipment = await shell.gateway.getCompanyFirstShipment(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyFirstShipmentLoaded,
    firstShipment
  };
};

export default ActionBuilder
  .for(loadCompanyFirstShipment)
  .build();