import React from "react";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import {Description} from "@material-ui/icons";
import AppSwitch from "../../../../../core/components/inputs/AppSwitch/AppMuiSwitch";
import AppCurrencyText from "../../../../../core/components/text/AppCurrencyText";
import AppNumberText from "../../../../../core/components/text/AppNumberText";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const computeStyle = (bool, color) => {
    return Boolean(bool) === true ? color : 'gray';
}

// Cells
export const postedToCell = (data) => {
    const datAmountUsd = data.dataItem.datPostedAmountUsd ? <AppCurrencyText value={data.dataItem.datPostedAmountUsd} /> : "No Amt";
    const datPosted = data.dataItem.extDatLoadPostingId ? <span>DAT - {datAmountUsd}</span> : false;

    const tsAmountUsd = data.dataItem.truckstopPostedAmountUsd ? <AppCurrencyText value={data.dataItem.truckstopPostedAmountUsd} /> : "No Amt";
    const truckstopPosted = data.dataItem.truckstopLoadPostingId ? <span>ITS - {tsAmountUsd}</span> : false;

    return (
      <td>
        <div>
          <p style={{color: computeStyle(datPosted, "blue"), marginTop: 2}}>{datPosted ? datPosted : "DAT"}</p>
          <p style={{color: computeStyle(truckstopPosted, "red"), marginBottom: 2}}>{truckstopPosted ? truckstopPosted : "ITS"}</p>
        </div>
      </td>

    )
};

export const mileageItsDatCell = (data) => {
    const dat = data.dataItem.datMiles ? <AppNumberText value={data.dataItem.datMiles}  minFractionDigits={0}  maxFractionDigits={0} /> : "";
    const its = data.dataItem.itsMiles ? <AppNumberText value={data.dataItem.itsMiles} minFractionDigits={0} maxFractionDigits={0} /> : "";

    return (
      <td>
          <div>
              <p style={{color: computeStyle(dat, "blue"), marginTop: 2}}><span>{dat} {data.dataItem.datMiles ? "mi." : ""}&nbsp;</span></p>
              <p style={{color: computeStyle(its, "red"), marginBottom: 2}}><span>{its} {data.dataItem.itsMiles ? "mi." : ""}&nbsp;</span></p>
          </div>
      </td>

    )
};

export const internalNoteCell = (props) => {
  const { internalNotes } = props.dataItem;
        return (
          <td className={props.dataItem.isCovered === 1 ? "is-covered" : null}>
            <div style={{textAlign: 'center'}}>
              <AppIconButton
                style={{cursor: "default"}}
                Icon={Description}
                tooltip={<span className={"tgf-kendo-grid-tooltip"}>{internalNotes}</span>}
                disabled={!internalNotes}
              />
            </div>
          </td>
        );
}

export const coveredCell = (props, handleCoveredChange, classes) => {
  const { isCovered } = props.dataItem;
  return (
    <td className={isCovered === 1 ? "is-covered" : null}>
      <AppSwitch
        checked={isCovered === 1}
        onChange={() => {
          handleCoveredChange(props.dataItem, {})
        }}
        label={''}
      />
    </td>
  )
};

export const callScriptNotesCell = (props) => {
  const { callScriptNotes } = props.dataItem;
  let classNames = "k-table-td show-all-text";
  if(props.dataItem.isCovered === 1) {
      classNames += " is-covered"
  }
  return (
    <td className={classNames}>
      <span style={{lineHeight: 1.4}}>{callScriptNotes}</span>
    </td>
  )
};

export const ShipmentPostedToHeaderCell = () => {
    return (
      <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
          <span>Posted? </span>
          <InfoIconTooltip title={`After a posting is completed on either DAT and/or ITS, the label(s) will colorize and advise the amount entered when the posting was submitted.  If no amount was entered, it will display "No Amt".`} />
      </div>
    )
}


export const ShipmentEstMilesHeaderCell = () => {
    return (
      <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
          <span>Est. Miles</span>
          <InfoIconTooltip title={`After a posting is completed, the miles that are displayed are the approximate driving miles according to Google Maps and are based off the involved From and To cities and states.  Review the individual posting to see the involved cities and states.`} />
      </div>
    )
}
