import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import LeftNav from '../LeftNav';
import AppDenseGrid from '../../../core/components/AppDenseGrid/AppDenseGrid';
import {Grid} from '@material-ui/core';
import Business from '@tgf-crm/business';
import {Dashboard} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import AppIconButton from '../../../core/components/AppButton/AppIconButton';
const LoadProcessName = 'Admin.InvoicingExceptionWorkflowQueuePage.Load';

const LoadRecords = async (shipmentStatusId, offset, limit, sort) => {
  const filter = {
    shipmentStatusId,
    $or: [
      {
        companyInvoiceDeliveryMethodTypeId: [
          Business.Company.CompanyInvoiceDeliveryMethodTypes.Usps.Id,
          Business.Company.CompanyInvoiceDeliveryMethodTypes.Other.Id
        ]
      },
      {
        companyInvoiceDeliveryMethodTypeId: null
      }
    ]
  };
  return await window.shell.gateway.getInvoicingExceptionWorkflowQueue(filter, offset, limit, sort);
};

const DashboardLinkCell = ({ rowData: record }) => {
  const month = record.shipmentBolDate.getMonth() + 1;
  const year = record.shipmentBolDate.getFullYear();
  return (
    <AppIconButton
      to={{
        pathname: `/admin/dashboard/hold-invoice-generated`,
        state: {
          month,
          year,
          company: {
            companyId: record.companyId,
            companyName: record.companyName
          }
        }
      }}
      Icon={Dashboard}
      component={Link}
      tooltip={'Process this customer on the dashboard.'}
    />
  );
}

const InvoicingExceptionWorkflowQueuePage = (props) => {

  const gridColumns = [
    {
      field: 'shipmentBolNumber',
      title: 'Ship #',
      styles: {
        width: '10%'
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.shipmentBolNumber}/details`, record.shipmentBolNumber])
    },
    {
      field: 'shipmentBolDate',
      title: 'Pickup Date',
      styles: {
        width: '10%'
      },
      dataType: 'date'
    },
    {
      field: 'associateSystemId',
      title: 'Rep.',
      styles: {
        width: '10%'
      },
      dataType: 'string'
    },
    {
      title: 'Customer Name',
      field: 'companyName',
      styles: {
        width: '35%'
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.companyName])
    },
    {
      field: 'invoiceConfirmedLockDate',
      title: 'Locked Date',
      styles: {
        width: '10%'
      },
      dataType: 'date'
    },
    {
      field: 'invoiceAdjustedCustomerCost',
      title: 'Cust. Cost',
      styles: {
        width: '10%'
      },
      dataType: 'currency'
    },
    {
      field: 'companyInvoiceDeliveryMethodTypeId',
      title: 'Type',
      styles: {
        width: '10%'
      },
      dataType: 'string'
    },
    {
      title: '',
      field: '',
      styles: {
        width: '5%'
      },
      dataType: 'component',
      component: DashboardLinkCell
    }
  ];

  const {
    loadRecords,
    shipmentStatusTypes
  } = props;

  const holdStatusType = shipmentStatusTypes.find(st => st.name === Business.Shipment.ShipmentStatusNames.Hold);

  const [recordCount, setRecordCount] = React.useState(0);
  const [records, setRecords] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['shipmentBolNumber', 'desc']]);

  React.useEffect(() => {
    if (holdStatusType) {
      loadRecords(holdStatusType.id, offset, limit, sort)
        .then(({ shipments, count }) => {
          setRecords(shipments);
          setRecordCount(count);
        })
        .catch((error) => console.error(error));
    }
  }, [holdStatusType, offset, limit, sort, loadRecords]);

  const handleChangePage = (e, page) => {
    setOffset(page * limit);
  };
  const handleSortChange = (column) => {

    const [existingColumn, existingOrder] = sort[0];

    const order = column === existingColumn ?
      existingOrder === 'asc' ? 'desc' : 'asc' :
      'asc';

    setSort([[column, order]]);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  return (
    <FullWidthLayout title={'Manual Invoicing'} SideNav={LeftNav}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppDenseGrid
            columns={gridColumns}
            data={records}
            count={recordCount}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            rowsPerPage={limit}
            page={offset / limit}
            onSort={handleSortChange}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(InvoicingExceptionWorkflowQueuePage)
  .stateToProps((state, ownProps) => ({
    shipmentStatusTypes: state.support.shipmentStatusTypes
  }))
  .dispatchToProps((shell, dispatch, getState) => ({
    loadRecords: async (statusId, offset, limit, sort) => {
      dispatch(shell.actions.sys.processStart(LoadProcessName));
      const results = await LoadRecords(statusId, offset, limit, sort);
      dispatch(shell.actions.sys.processComplete(LoadProcessName));
      return results;
    }
  }))
  .build();