import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

const AppMuiSwitch = ({ checked, onChange, label, color = 'primary', size='small', ...otherProps }) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    className="defaultSwitch"
                    checked={checked}
                    color={color}
                    onChange={onChange}
                    {...otherProps}
                />
            }
            label={label}
        />
    )
}
export default AppMuiSwitch;