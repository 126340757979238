import React, {useEffect, useState} from "react";
import ShipmentRecordNav from "../ShipmentRecordNav";
import {ShipmentTitle} from "../ShipmentTitle";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import {useParams} from "react-router-dom";
import {Grid} from "@material-ui/core";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import OriginalShipmentBreakdown from "./includes/OriginalShipmentBreakdown";
import CollectiveCostsBreakdown from "./includes/CollectiveCostsBreakdown";
import {useDispatch} from "react-redux";
import MultiTransGrid from "./includes/MultiTransGrid";
import MultiTransDialog from "./includes/MultiTransDialog";

const MultiTransPage = () => {
    const urlParams = useParams();
    const bolNumber = parseInt(urlParams?.id);
    const personalDispatch = useDispatch();

    const [data, setData] = useState({});
    const [disableEdit, setDisableEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const multiTransGrid = React.useRef();

    const onOpenDialog = () => {
        setDialogOpen(true);
    };

    const onCloseDialog = async () => {
        setDialogOpen(false);
        await loadPageDependencies();
    }


    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadMultiTransPage'));

            const results = await window.shell.gateway.getShipmentMultiTransData(bolNumber);
            setData(results);
            multiTransGrid.current.refresh();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadMultiTransPage'));
        }
    }

    useEffect(() => {
        loadPageDependencies()
    }, []);

    return (
      <FullWidthLayout SideNav={ShipmentRecordNav} title={<ShipmentTitle bolNumber={bolNumber}
                                                                         title={`Shipments - Multi Transactions - ${bolNumber ? bolNumber : 'New'}`}/>}
                       className={"shipments-styles"}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                      <Grid item>
                          <AppButton
                            type={'button'}
                            onClick={onOpenDialog}
                          >+ ADD MULTI-TRANSACTION
                          </AppButton>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                  <OriginalShipmentBreakdown data={data} />
              </Grid>
              <Grid item xs={12}>
                  <CollectiveCostsBreakdown data={data} />
              </Grid>
              <Grid item xs={12}>
                  <MultiTransGrid data={data.shipmentAdjustments} ref={multiTransGrid}/>
              </Grid>
              {dialogOpen &&
                <MultiTransDialog data={data} onCloseDialog={onCloseDialog} reload={loadPageDependencies}/>
              }
          </Grid>
      </FullWidthLayout>
    )
}

export default MultiTransPage;
