import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadBrokerAssociates = async (shell, queensOnly) => {

  const brokerAssociates = await shell.gateway.getAllBrokerAssociates(queensOnly);

  return {
    type: ActionTypes.Persona.BrokerAssociatesLoaded,
    brokerAssociates
  }
};

export default ActionBuilder
  .for(loadBrokerAssociates)
  .build();