import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const TgfComboBox = (props) => {
    const [data, setData] = useState(props.data);
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (data) setValue(data.find(x => x.id === props.value) || null);
    }, [props.value, data]);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const filterData = (filter) => {
        const data = props.data.slice();
        return filterBy(data, filter);
    };
    const onFilterChange = (event) => {
        setData(filterData(event.filter));
    };

    const onBlur = () => {
        setData(props.data);
        setIsOpen(false);
    };

    const onClose = () => {
        setData(props.data);
        setIsOpen(false);
    };

    const onFocus = () => {
        setIsOpen(true);
    };

    const onOpen = () => {
        setIsOpen(true);
    };

    return (
        <>
            {props.label && <Label editorId={props.id}>{props.label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox"}
                textField={"display"}
                dataItemKey={"id"}
                value={value}
                data={data}
                opened={isOpen}
                onBlur={onBlur}
                onOpen={onOpen}
                onClose={onClose}
                onFocus={onFocus}
                onFilterChange={onFilterChange}
                label={null} // we set this to null, because label is used in the Label component up above.
                filterable={props.filterable ?? true}
            />
        </>
    );
};

TgfComboBox.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input
    id: PropTypes.string.isRequired,
    // Name of the input, this is used to reference in the event handler.
    name: PropTypes.string.isRequired,
    // List of data for the dropdown IE: [{id: 1, display: "displayValue}, {id: 2, display: "displayValue}]
    data: PropTypes.array.isRequired,
    // The reference key to the value we want to display from the object/s.
    textField: PropTypes.string,
    // The reference key to the value we want to be chosen and stored as our actual form value from the object/s.
    dataItemKey: PropTypes.string,
    // The current value of the input
    value: PropTypes.any,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Bool to make the input filterable.
    filterable: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,

};

export default TgfComboBox;