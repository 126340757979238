import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipmentMultipleStops = async (shell, bolNumber) => {

  const shipmentMultipleStops = await shell.gateway.getShipmentMultipleStop(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentMultipleStopsLoaded,
    shipmentMultipleStops
  };
};

export default ActionBuilder
  .for(loadShipmentMultipleStops)
  .build();
