import { Grid } from '@material-ui/core';
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import TgfTextBox from "../../../core/kendo/form/inputs/TgfTextBox";
import TgfAssociateAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";
import TgfCustomerAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfCustomerAutoComplete";
import TgfEquipmentTypesComboBox from "../../../core/kendo/form/inputs/specialty/TgfEquipmentTypesComboBox";
import TgfStateProvinceComboBox from "../../../core/kendo/form/inputs/specialty/TgfStateProvinceComboBox";
import DateOnlyPicker from "../../../core/kendo/inputs/DateOnlyPicker";
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import { usePageTitle } from "../../../crm/components/customHooks/misc/usePageTitle";
import ReportNav from '../ReportNav';
import LaneLookupListingTable from './includes/LaneLookupListingTable';

const LaneLookup = (props) => {

  const laneLookupGridRef = useRef();
  usePageTitle("TGF: Lane Lookup/History");

  const globalState = useSelector(state => state);
  const associate = globalState.persona.modifyingAssociate;
  const isAce = associate.roles[0].name === "Ace";

  const [state, setState] = useState({
    fromCity: null,
    fromState: "",
    toCity: null,
    toState: "",
    filterAssociate: "",
    myShipmentsOnly: false,
    equipmentType: "",
    customerName: "",
    pickupDateFrom: null,
    pickupDateTo: null,
    isBlind: false,
    isHazMat: false,
    isMultiStop: false,
  });

  useEffect(() => {
    let delay;
    if (state.fromCity !== null || state.toCity !== null) {
      delay = setTimeout(() => {
        laneLookupGridRef.current.refresh();
      }, 600);
    }

    // clear timeout / action on unmount.
    return () => clearTimeout(delay);
  }, [state.fromCity, state.toCity]);


  const handleFromToCityChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value ?? "";
    await setState({
      ...state,
      [name]: value,
    });
  };

  const handleStateProvinceFromChange = async (e) => {
    const value = e.target.value ?? "";
    await setState({
      ...state,
      fromCity: null,
      fromState: value,
    });
    await laneLookupGridRef.current.refresh();
  };

  const handleStateProvinceToChange = async (e) => {
    const value = e.target.value ?? "";
    await setState({
      ...state,
      toCity: null,
      toState: value,
    });
    await laneLookupGridRef.current.refresh();
  };

  const handleCustomerChange = async (e) => {
    const value = e.target.value?.companyName ?? "";
    await setState({
      ...state,
      customerName: value
    });
    await laneLookupGridRef.current.refresh();
  };

  const handleStateChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value ?? "";
    await setState({
      ...state,
      [name]: value,
    });
    await laneLookupGridRef.current.refresh();
  };

  return (
    <FullWidthLayout SideNav={ReportNav} title="Lane Look Up/History">
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} md={2}>
              <TgfStateProvinceComboBox
                label={"State From"}
                id={"fromState"}
                name={"fromState"}
                onChange={handleStateProvinceFromChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TgfStateProvinceComboBox
                label={"State To"}
                id={"toState"}
                name={"toState"}
                onChange={handleStateProvinceToChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Label editorId={"fromCity"}>City From</Label>
              <TgfTextBox
                id={"fromCity"}
                name={"fromCity"}
                value={state.fromCity}
                onChange={(e) => handleFromToCityChange({ target: { value: e.target.value, name: e.target.element.name } })}
                disabled={!state.fromState}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Label editorId={"toCity"}>City To</Label>
              <TgfTextBox
                id={"toCity"}
                name={"toCity"}
                value={state.toCity}
                onChange={(e) => handleFromToCityChange({ target: { value: e.target.value, name: e.target.element.name } })}
                disabled={!state.toState}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TgfEquipmentTypesComboBox
                label={"Equipment Type"}
                id={"equipmentType"}
                name={"equipmentType"}
                value={state.equipmentType}
                onChange={handleStateChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TgfCustomerAutoComplete
                label={"Customer Search"}
                id={"customerName"}
                name={"customerName"}
                value={state.customerName}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item>
              <DateOnlyPicker
                value={state.pickupDateFrom}
                id={"pickupDateFrom"}
                name={"pickupDateFrom"}
                label={"Pickup Date From"}
                onChange={handleStateChange}
              />
            </Grid>
            <Grid item>
              <DateOnlyPicker
                value={state.pickupDateTo}
                id={"pickupDateTo"}
                name={"pickupDateTo"}
                label={"Pickup Date To"}
                onChange={handleStateChange}
              />
            </Grid>
            <Grid item>
              <Checkbox
                value={state.isBlind}
                id={"isBlind"}
                name={"isBlind"}
                label={"View Blind Only"}
                onChange={handleStateChange}
              />
            </Grid>
            <Grid item>
              <Checkbox
                value={state.isHazMat}
                id={"isHazMat"}
                name={"isHazMat"}
                label={"View Haz-mat Only"}
                onChange={handleStateChange}
              />
            </Grid>
            <Grid item>
              <Checkbox
                value={state.isMultiStop}
                id={"isMultiStop"}
                name={"isMultiStop"}
                label={"View Multi-stops Only"}
                onChange={handleStateChange}
              />
            </Grid>
            {isAce ? (
              <Grid item>
                <TgfAssociateAutoComplete
                  label={"Associate Search"}
                  id={"filterAssociate"}
                  name={"filterAssociate"}
                  value={state.filterAssociate}
                  onChange={handleStateChange}
                />
              </Grid>
            ) : (
              <Grid item>
                <Checkbox
                  value={state.myShipmentsOnly}
                  id={"myShipmentsOnly"}
                  name={"myShipmentsOnly"}
                  label={"View My Shipments Only"}
                  onChange={handleStateChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <LaneLookupListingTable
            ref={laneLookupGridRef}
            fromCity={state.fromCity}
            fromState={state.fromState}
            toCity={state.toCity}
            toState={state.toState}
            equipmentType={state.equipmentType}
            activeAssociateId={associate.id}
            filterAssociate={state.filterAssociate}
            myShipmentsOnly={state.myShipmentsOnly}
            customerName={state.customerName}
            pickupDateFrom={state.pickupDateFrom}
            pickupDateTo={state.pickupDateTo}
            isBlind={state.isBlind}
            isHazMat={state.isHazMat}
            isMultiStop={state.isMultiStop}
            isAce={isAce}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};
export default LaneLookup;