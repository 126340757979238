import ActionTypes from '../../../ActionTypes';

const inVoiceAgingCount = (state = 0, action) => {
  switch(action.type) {
    case ActionTypes.Reporting.InvoiceAging.Dispose:
      return 0;
    case ActionTypes.Reporting.InvoiceAging.Loaded:
      return action.count;
    default:
      return state;
  }
};

export default inVoiceAgingCount;