import React from 'react';
import {Grid, Divider} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import Core from '@atomos/core';

import AppTitleBar from '../../core/components/AppTitleBar';
import AppButton from '../../core/components/AppButton';
import When from '../../core/components/condtionals/When';
import ComponentBuilder from '../../core/ComponentBuilder';
import CrmShipmentCarrierComboBox from "../../crm/components/CrmShipmentCarrierComboBox";

const styles = (theme) => ({
  divider: {
    marginBottom: 10,
    marginTop: 10,
    backgroundColor: theme.palette.tgfGrey.border,
    width: '100%',
    marginLeft: 0
  }
});

const CarrierToolBar = (props) => {

  const {
    classes,
    title,
    onSelectCarrier,
    onAddCarrierClick,
    onSetupCarrierClick,
    selectedCarrier
  } = props;

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={6}>
          <AppTitleBar
            title={title}
            divider={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
            <Grid item>
              <CrmShipmentCarrierComboBox
                useDotRatherThanMc={true}
                placeholder={'Carrier name or DOT # ...'}
                value={selectedCarrier || null}
                onChange={onSelectCarrier}
                filterMap={Core.Utils.identity}
                popupIcon={' '}
                style={{minWidth: 300}}
              />
            </Grid>
            <Grid item>
              <CrmShipmentCarrierComboBox
                placeholder={'Carrier name or MC # ...'}
                value={selectedCarrier || null}
                onChange={onSelectCarrier}
                filterMap={Core.Utils.identity}
                popupIcon={' '}
                style={{minWidth: 300}}
              />
            </Grid>
            <Grid item>
              <AppButton onClick={onSetupCarrierClick}>
                Setup Carrier
              </AppButton>
            </Grid>
            <Grid item>
              <When condition={!!onAddCarrierClick}>
                <AppButton onClick={onAddCarrierClick}>
                  <Add/>
                </AppButton>
              </When>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider}/>
      </Grid>
    </Grid>
  )
}
export default ComponentBuilder
  .wrap(CarrierToolBar)
  .withStyles(styles)
  .build();