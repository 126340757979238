import ConditionalWrapper from "../../../../core/kendo/ConditionalWrapper";
import {Tooltip} from "@material-ui/core";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Refresh} from "@material-ui/icons";
import React from "react";
import AppAlertStatic from "../../../../core/components/AppAlertStatic";
import PostAge from "../listingCells/PostAge";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const AutoDeletePostWarning = (props) => {
    return (
      <>
          <AppAlertStatic color="warning">This posting will auto-delete on {new Date(props.dateString).toLocaleString()} (your local time). <InfoIconTooltip  title={props.tooltip}/></AppAlertStatic>

      </>
    )
}

export default AutoDeletePostWarning;
