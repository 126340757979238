import * as Redux from 'redux'
import shipment from './shipment';
import customer from './customer';
import shipper from './shipper';
import consignee from './consignee';
import customerContacts from './customerContacts';
import customerCreditStatus from './customerCreditStatus';
import shipperContacts from './shipperContacts';
import consigneeContacts from './consigneeContacts';
import duplicateBolNumbers from './duplicateBolNumbers';
import multiStopCompanies from './multiStopCompanies';
import multiStopCompanyContacts from './multiStopCompanyContacts';

export default Redux.combineReducers({
  shipment,
  customer,
  shipper,
  consignee,
  customerContacts,
  customerCreditStatus,
  shipperContacts,
  consigneeContacts,
  duplicateBolNumbers,
  multiStopCompanies,
  multiStopCompanyContacts
});