const LoadContentsActions = [
  'confirmTruckloadValue',
  'addContent',
  'editContent',
  'deleteContent'
];
const LoadContentsFields = [];

const PermissionSet = {
  name: 'loadContents',
  actions: LoadContentsActions,
  fields: LoadContentsFields
};

export default PermissionSet;