import * as Redux from 'redux'

import shipmentTrackingNotes from '../modification/shipmentTrackingNotes';
import shipmentInvoice from './shipmentInvoice';
import shipmentInvoiceAuditNotes from './shipmentInvoiceAuditNotes';
import shipmentContents from './shipmentContents';
import shipment from './shipment';
import shipmentTruckLoadConfirmation from './shipmentTruckLoadConfirmation';
import shipmentLockedAssociate from './shipmentLockedAssociate';
import shipmentMultipleStops from './shipmentMultipleStops';
import shipmentCompanies from './shipmentCompany';
import shipmentDocuments from './shipmentDocuments';
import shipmentCarrierContact from './shipmentCarrierContact';

export default Redux.combineReducers({
  shipment,
  shipmentContents,
  shipmentDocuments,
  shipmentInvoice,
  shipmentInvoiceAuditNotes,
  shipmentLockedAssociate,
  shipmentMultipleStops,
  shipmentTrackingNotes,
  shipmentTruckLoadConfirmation,
  shipmentCompanies,
  shipmentCarrierContact
});