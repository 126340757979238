import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadShipmentListing = async (shell, startDate, endDate, customerId, statusSet, offset, limit, sort) => {

  const listingOptions = {
    startDate, endDate, customerId, statusSet, offset, limit, sort
  };

  const shipmentList = await shell.gateway.getDashboardShipmentList(listingOptions);

  return {
    type: ActionTypes.Dashboard.ListingLoaded,
    shipmentCount: shipmentList.count,
    shipments: shipmentList.shipments
  };

};

export default ActionBuilder
  .for(loadShipmentListing)
  .build();