import ActionTypes from '../../ActionTypes';

const ytdMarginGoals = (state = [], action) => {
    switch (action.type) {
        case ActionTypes.Persona.ytdMarginGoals:
            return action.ytdMarginGoals;
        default:
            return state;
    }
};

export default ytdMarginGoals;