import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadHistory = async (shell, mcNumbers, offset, limit, sort, agentId = null) => {

  const filter = {
    carrierMcNumber: mcNumbers
  };

  if(agentId && agentId !== "") {
    filter.associateId = agentId;
  }

  const { shipments, count } = await shell.gateway.getCarrierHistory({
    filter: filter,
    offset,
    limit,
    sort
  });

  return {
    type: ActionTypes.Carrier.Modification.CarrierHistory.Loaded,
    shipments,
    count
  };
}

export default ActionBuilder
  .for(loadHistory)
  .build();