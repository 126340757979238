import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import * as Yup from 'yup';
import ComponentBuilder from '../../../core/ComponentBuilder';
import isAce from '../../../hubs/persona/selectors/isAce';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ShipmentRecordNav from "../ShipmentRecordNav";
import FormFactor from "../../../core/FormFactor/FormFactor";
import renderDetailForm from "./renderDetailForm";
import FreightCategoryTypeNames from "../../../hubs/shipment/FreightCategoryTypeNames";
import StatusTypeNames from "../../../hubs/shipment/StatusTypeNames";
import isNewShipment from "../../../hubs/shipment/actions/modification/isNewShipment";
import Core from "@atomos/core";
import ShipmentHazardousStateNames from "../../../hubs/shipment/ShipmentHazardousStateNames";
import When from "../../../core/components/condtionals/When";
import MissingOrDeniedShipmentAlert from '../MissingOrDeniedShipmentAlert';
import "./DetailsPage.scss";
import {useDispatch} from "react-redux";
import shipmentTrackingPhases from "../../../hubs/support/selectors/shipmentTrackingPhases";
import {ShipmentTitle} from "../ShipmentTitle";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Shipment.DetailPage.Load';
const SaveProcessName = 'Shipment.DetailPage.Save';

const DetailsPage = (props) => {
    const {
        isAce,
        associate,
        dispose,
        equipmentTypes,
        freightCategoryTypes,
        hazardousStates,
        match,
        history,
        shipment,
        invoice,
        carrierContact,
        currentCustomer,
        currentShipper,
        currentConsignee,
        currentThirdParty,
        shipmentStatus,
        tallgrassBillingCompany,
        lockedAssociate,
        load,
        loadCustomerAgent,
        getCarrier,
        getCreditStatus,
        createCarrierContact,
        saveShipment,
        sendSnackbarMessage,
        creditStatus,
        saveCompanyNote,
        trackingNotes,
        trackingPhases,
        voidOutShipment
    } = props;

    const bolNumber = match.params.id !== 'new' ?
      parseInt(match.params.id) : undefined;
    usePageTitle(`Ship ${bolNumber ? bolNumber : "New"}`);


    const [isAddingNote, setIsAddingNote] = React.useState(false);
    const [podRequired, setPodRequired] = useState(false);

    const personalDispatch = useDispatch();

    const loadPageDependencies = async () => {
        personalDispatch(window.shell.actions.sys.processStart('loadShipmentDetails'));
        try {
            await load(bolNumber);

        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadShipmentDetails'));
        }
    };

    useEffect(() => {
        const getInvoiceCommunicationConfig = async () => {
            const result = await window.shell.gateway.getInvoiceCommunicationConfig(currentCustomer.id);
            if(result) setPodRequired(result?.entity?.includePod === "REQUIRED");
        }
        if(currentCustomer?.id) getInvoiceCommunicationConfig();
    }, [currentCustomer?.id]);

    async function voidAndReload(voidReason, statusType, setFieldValues, setSupportingValue) {
        personalDispatch(window.shell.actions.sys.processStart('voidAndReloadShipmentDetails'));
        try {
            await voidOutShipment(bolNumber, voidReason, shipment.customerId);

            const fieldValues = [
                ["statusId", -1],
                ["selectedShipmentStatus", 'Cancelled/Voided'],
            ]

            setFieldValues(fieldValues).then(() => {
                setSupportingValue("showChangeStatusDialog", false);
            });

            // reload the page
            await loadPageDependencies();
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('voidAndReloadShipmentDetails'));
        }
    }



    useEffect(() => {
        loadPageDependencies();

        return () => dispose();
    }, [bolNumber, dispose, load]);

    const coerceCompany = (company) => {
        return {
            affiliateCompanyId: company.affiliateId,
            affiliateCompanyName: null,
            associateFirstName: null,
            associateId: company.associateId,
            associateLastName: null,
            categoryTypeId: company.categoryTypeId,
            categoryTypeName: null,
            companyAddress1: company.address1,
            companyAddress2: company.address2,
            companyBusinessPhone: company.businessPhone,
            companyCity: company.city,
            companyId: company.id,
            companyIsDisabled: company.isDisabled,
            companyIsInactive: company.isInactive,
            companyIsPodRequired: podRequired,
            companyName: company.name,
            companyPostalCode: company.postalCode,
            companyStateProvince: company.stateProvince,
            primaryContactFirstName: null,
            primaryContactId: null,
            primaryContactLastName: null,
            hotNote: company.hotNote
        }
    };

    const isTruckloadCategory = () => {
        if (shipment) {
            const freightCategoryNominee = freightCategoryTypes
              .find(fct => fct.id === shipment.freightCategoryId)
            return freightCategoryNominee ?
              freightCategoryNominee.name === FreightCategoryTypeNames.Truckload :
              true;
        }
        return true;
    };

    const handleSubmit = async (values, formFactor) => {
        // get latest shipment data
        const shipmentData = Object.assign({},
          values.shipment, values)

        // collect carrier contact data
        const carrierContactData = Core.Utils.merge({}, createCarrierContact(), values.carrierContact);
        if (!carrierContactData.id) {
            carrierContactData.bolNumber = bolNumber;
        }
        carrierContactData.repName = values.repName;
        carrierContactData.repEmail = values.repEmail;
        carrierContactData.repPhone = values.repPhone;
        carrierContactData.carrierCallContactPreferred = values.carrierCallContactPreferred;
        carrierContactData.carrierTextContactPreferred = values.carrierTextContactPreferred;
        carrierContactData.carrierEmailContactPreferred = values.carrierEmailContactPreferred;
        carrierContactData.experienceNote = values.experienceNote;

        const isNew = isNewShipment(bolNumber);

        saveShipment(shipmentData, carrierContactData, isNew, values.hasCustomerChanged, values.hasFreightCategoryChanged)
          .then((updatedShipment) => {
              sendSnackbarMessage({content: 'Shipment details saved.'})
              if (isNew) {
                  const newNote = {
                      bolNumber: updatedShipment.bolNumber,
                      associateId: associate.id,
                      note: `Shipment Created By: ${associate.fullName}`,
                      loadTrackingPhaseId: 8,
                  };
                  saveCompanyNote(newNote)
                    .then(() => {
                        load(bolNumber).then(() => {history.replace(`/shipment/${updatedShipment.bolNumber}/details`)});
                    });
              } else {
                  load(bolNumber);
              }
          });

    };

    const initialValues = {
        isAce,
        associate,
        bolNumber,
        bolDate: shipment && shipment.bolDate,
        candidateCarriers: shipment && shipment.candidateCarriers,
        candidateEquipmentTypes: shipment ?
            equipmentTypes.filter(et => et.freightCategoryId === shipment.freightCategoryId) :
            [],
        carrierContact,
        carrierMcNumber: shipment && shipment.carrierMcNumber,
        consigneeId: shipment && shipment.consigneeId,
        customerId: shipment && shipment.customerId,
        customerRep: shipment && shipment.customerRep,
        tallgrassBillingCompany,
        deliveryNumber: shipment && shipment.deliveryNumber,
        deliveryTerminalNumber: shipment && shipment.deliveryTerminalNumber,
        deliveryTime: shipment && shipment.deliveryTime,
        driverName: shipment && shipment.driverName,
        driverPhone: shipment && shipment.driverPhone,
        driverCallContactPreferred: shipment && shipment.driverCallContactPreferred,
        driverTextContactPreferred: shipment && shipment.driverTextContactPreferred,
        equipmentType: shipment && shipment.equipmentType,
        equipmentTypes,
        estimatedDeliveryDate: shipment && shipment.estimatedDeliveryDate,
        freightCategoryTypes,
        freightCategoryTypeId: shipment && shipment.freightCategoryId,
        hasCustomerChanged: false,
        hasFreightCategoryChanged: false,
        hazardousStates,
        hazardousStateId: shipment && shipment.hazardousStateId,
        invoice,
        isBlind: shipment && shipment.isBlind,
        isDeliveryOrderRequired: shipment && shipment.isDeliveryOrderRequired,
        isLoadingCandidateCarriers: false,
        isMultipleStop: shipment && shipment.isMultipleStop,
        isNew: isNewShipment(bolNumber),
        isTlCategory: isTruckloadCategory(),
        lockedDate: shipment?.lockedDate,
        lockedAssociateId: shipment?.lockedAssociateId,
        lockedAssociate,
        ltlCarrierPickupNumber: shipment && shipment.ltlCarrierPickupNumber,
        note: shipment && shipment.note,
        pickupNumber: shipment && shipment.pickupNumber,
        pickupTerminalNumber: shipment && shipment.pickupTerminalNumber,
        pickupTime: shipment && shipment.pickupTime,
        proNumber: shipment && shipment.proNumber,
        rateConNote: shipment && shipment.rateConNote,
        ratingRefNumber: shipment && shipment.ratingRefNumber,
        refNum1Description: shipment && shipment.refNum1Description,
        refNum1: shipment && shipment.refNum1,
        refNum2Description: shipment && shipment.refNum2Description,
        refNum2: shipment && shipment.refNum2,
        refNum3Description: shipment && shipment.refNum3Description,
        refNum3: shipment && shipment.refNum3,
        refNum4Description: shipment && shipment.refNum4Description,
        refNum4: shipment && shipment.refNum4,
        repName: carrierContact && carrierContact.repName,
        repPhone: carrierContact && carrierContact.repPhone,
        repEmail: carrierContact && carrierContact.repEmail,
        carrierCallContactPreferred: carrierContact && carrierContact.carrierCallContactPreferred,
        carrierTextContactPreferred: carrierContact && carrierContact.carrierTextContactPreferred,
        carrierEmailContactPreferred: carrierContact && carrierContact.carrierEmailContactPreferred,
        experienceNote: carrierContact && carrierContact.experienceNote,
        getCarrier,
        selectedCarrier: shipment && shipment.carrier,
        selectedConsignee: currentConsignee && coerceCompany(currentConsignee),
        selectedCustomer: currentCustomer && coerceCompany(currentCustomer),
        selectedEquipmentType: shipment && equipmentTypes.find(et => et.name === shipment.equipmentType),
        selectedFreightCategory: shipment && freightCategoryTypes.find(fct => fct.id === shipment.freightCategoryId),
        selectedShipmentStatus: shipment && shipmentStatus.find(s => s.id === shipment.statusId),
        selectedThirdParty: currentThirdParty && coerceCompany(currentThirdParty),
        selectedShipper: currentShipper && coerceCompany(currentShipper),
        shipperId: shipment && shipment.shipperId,
        shipment,
        shipmentStatus,
        statusId: shipment && shipment.statusId,
        setIsAddingNote,
        saveCompanyNote,
        sendSnackbarMessage,
        thirdPartyId: shipment && shipment.thirdPartyId,
        trailerNumber: shipment && shipment.trailerNumber,
        getCreditStatus,
        creditStatus: currentCustomer && creditStatus,
        isAddingNote,
        trackingNotes,
        trackingPhases,
        truckNumber: shipment && shipment.truckNumber,
        loadCustomerAgent,
        voidOutShipment,
        voidAndReload: voidAndReload,
        reload: loadPageDependencies
    };

    const title = <ShipmentTitle bolNumber={bolNumber} title={`Shipments - Details - ${bolNumber ? bolNumber : 'New'}`}/>
    const leftNav = isNewShipment(bolNumber) ? null : ShipmentRecordNav;

    return (
      <FullWidthLayout title={title} SideNav={leftNav} className='shipments-styles'>
          <When condition={bolNumber && !shipment}>
              <MissingOrDeniedShipmentAlert/>
          </When>
          {shipment &&
              <Grid container spacing={1}>
                  <Grid item xs={12}>
                      <FormFactor
                        initialValues={(initialValues)}
                        schema={DetailFormSchema}
                        onSubmit={handleSubmit}>
                          {renderDetailForm}
                      </FormFactor>
                  </Grid>
              </Grid>
          }
      </FullWidthLayout>
    );

};

const DetailFormSchema = Yup.lazy(values => {
    const schema = {
        selectedEquipmentType: Yup.object()
          .nullable()
          .required('Equipment Type must be selected'),
        selectedCustomer: Yup.object()
          .nullable()
          .required('Customer must be selected.'),
        selectedCarrier: Yup.object()
          .nullable()
          .required('Carrier must be selected.'),
        bolDate: Yup.date()
          .nullable()
          .required('Pickup date is required.'),
        repEmail: Yup.string()
          .nullable()
          .email('Email is invalid.'),
        repPhone: Yup.string()
          .nullable()
          .matches(Core.Text.PhoneRegExp, {message: 'Rep. Phone is invalid.', excludeEmptyString: true}),
        driverPhone: Yup.string()
          .nullable()
          .matches(Core.Text.PhoneRegExp, {message: 'Driver Phone is invalid.', excludeEmptyString: true}),
        pickupTerminalNumber: Yup.string()
            .nullable()
            .matches(Core.Text.PhoneRegExp, {message: 'Phone number is invalid.', excludeEmptyString: true}),
        deliveryTerminalNumber: Yup.string()
            .nullable()
            .matches(Core.Text.PhoneRegExp, {message: 'Phone number is invalid.', excludeEmptyString: true})

    };
    return Yup.object().shape(schema);
});

export default ComponentBuilder
  .wrap(DetailsPage)
  .stateToProps((state, ownProps) => ({
      associate: state.persona.associate,
      lockedAssociate: state.shipment.modification.shipmentLockedAssociate,
      shipment: state.shipment.modification.shipment,
      invoice: state.shipment.modification.shipmentInvoice,
      shipmentStatus: state.support.shipmentStatusTypes,
      freightCategoryTypes: state.support.freightCategoryTypes,
      hazardousStates: state.support.shipmentHazardousStates,
      equipmentTypes: state.support.shipmentEquipmentTypes,
      carrierContact: state.shipment.modification.shipmentCarrierContact,
      currentCustomer: state.shipment.modification.shipmentCompanies.customer,
      currentShipper: state.shipment.modification.shipmentCompanies.shipper,
      currentConsignee: state.shipment.modification.shipmentCompanies.consignee,
      currentThirdParty: state.shipment.modification.shipmentCompanies.thirdParty,
      tallgrassBillingCompany: state.addressBook.tallgrassBillingCompany,
      creditStatus: state.addressBook.modification.creditStatus,
      isAce: isAce(state),
      trackingNotes: state.shipment.modification.shipmentTrackingNotes,
      trackingPhases: shipmentTrackingPhases(state)

  }))
  .dispatchToProps((shell, dispatch, getState) => {
      return {
          async load(bolNumber) {
              // Grab current state to reference supporting data.
              const {
                  support
              } = shell.stateStore.getState();

              const billingCompanyCategoryType = support.billingCompanyCategoryType;

              dispatch(shell.actions.sys.processStart(LoadProcessName));

              dispatch(await shell.actions.addressBook.loadTallgrassBillingCompany(billingCompanyCategoryType.id));

              const isNew = isNewShipment(bolNumber);

              if (isNew) {
                  const newStatusType = support.shipmentStatusTypes.find(st => st.name === StatusTypeNames.New);
                  const ltCategoryType = support.freightCategoryTypes.find(x => x.name === FreightCategoryTypeNames.Truckload);
                  const defaultHazardState = support.shipmentHazardousStates.find(h => h.name === ShipmentHazardousStateNames.NonHazardous);
                  const associate = shell.stateStore.getState().persona.associate;
                  const [newShipment, ...basicActions] = await Promise.all([
                      shell.actions.shipment.modification.loadNewShipment(newStatusType.id,
                        ltCategoryType.id,
                        billingCompanyCategoryType.id,
                        defaultHazardState.id,
                        associate)
                  ]);
                  dispatch(newShipment);
                  basicActions.forEach(dispatch);


                  dispatch(await shell.actions.shipment.modification.loadShipmentCompany('thirdParty', newShipment.shipment.thirdPartyId))
              } else {
                  const shipment = await shell.gateway.getShipment(bolNumber);
                  if (!shipment) {
                      dispatch(await shell.actions.shipment.modification.dispose());
                      dispatch(shell.actions.sys.processComplete(LoadProcessName));
                      return;
                  }
                  const [shipmentAction, ...actions] = await Promise.all([
                      shell.actions.shipment.modification.loadShipment(bolNumber),
                      shell.actions.shipment.modification.loadShipmentInvoice(bolNumber),
                      shell.actions.shipment.modification.loadShipmentCarrierContact(bolNumber),
                      shell.actions.addressBook.modification.loadCompanyCreditStatus(shipment.customerId)
                  ]);
                  dispatch(shipmentAction);
                  actions.forEach(dispatch);


                  if (shipmentAction.shipment) {
                      dispatch(await shell.actions.shipment.modification.loadShipmentTrackingNotes(bolNumber));
                      const actionPromises = [
                          shell.actions.shipment.modification.loadShipmentCompany('customer', shipmentAction.shipment.customerId),
                          shell.actions.shipment.modification.loadShipmentCompany('shipper', shipmentAction.shipment.shipperId),
                          shell.actions.shipment.modification.loadShipmentCompany('consignee', shipmentAction.shipment.consigneeId),
                          shell.actions.shipment.modification.loadShipmentCompany('thirdParty', shipmentAction.shipment.thirdPartyId)
                      ];

                      // Optionally load the associate that locked the shipment to display on screen.
                      if (Core.Utils.isNumber(shipmentAction.shipment.lockedAssociateId)) {
                          actionPromises.push(shell.actions.shipment.modification.loadShipmentLockedAssociate(shipmentAction.shipment.lockedAssociateId));
                      }

                      const additionalActions = await Promise.all(actionPromises);

                      additionalActions.forEach(dispatch);
                  }
              }


              dispatch(shell.actions.sys.processComplete(LoadProcessName));
          },
          async saveShipment(shipment, carrierContact, isNewShipment, hasCustomerChanged, hasFreightCategoryChanged) {
              dispatch(shell.actions.sys.processStart(SaveProcessName));

              if (shipment.hasOwnProperty('voidId')) {
                  await shell.gateway.restoreVoidShipment(shipment.bolNumber, shipment.customerId);
              }

              // clear out the prev credit status
              const state = shell.stateStore.getState();
              state.addressBook.modification.creditStatus = null;

              // IF customer changed or freight category changed && is not a new shipment && isMultipleStop,
              // then reset the multipleStops data, and set isMultipleStop to false.
              if (!isNewShipment && shipment.isMultipleStop && hasCustomerChanged || !isNewShipment && shipment.isMultipleStop && hasFreightCategoryChanged) {
                  dispatch(await shell.actions.shipment.modification.resetShipmentMultipleStop(shipment.bolNumber))
                  shipment.isMultipleStop = false;
              }
              // save shipment
              const saveShipmentAction = await shell.actions.shipment.modification.saveShipment(shipment);
              dispatch(saveShipmentAction);

              // Update Carrier Contacts
              const canSaveContact = carrierContact.id || carrierContact.repEmail || carrierContact.repPhone ||
                carrierContact.repName || carrierContact.carrierCallContactPreferred ||
                  carrierContact.carrierTextContactPreferred || carrierContact.carrierEmailContactPreferred ||
                  carrierContact.experienceNote;

              if (canSaveContact) {
                  if (isNewShipment)
                      carrierContact.bolNumber = saveShipmentAction.shipment.bolNumber;
                  dispatch(await shell.actions.shipment.modification.saveShipmentCarrierContact(carrierContact));
              }

              // Reload shipment customer, shipper, consignee & thirdParty
              const companyActions = await Promise.all([
                  shell.actions.shipment.modification.loadShipmentCompany('customer', saveShipmentAction.shipment.customerId),
                  shell.actions.shipment.modification.loadShipmentCompany('shipper', saveShipmentAction.shipment.shipperId),
                  shell.actions.shipment.modification.loadShipmentCompany('consignee', saveShipmentAction.shipment.consigneeId),
                  shell.actions.shipment.modification.loadShipmentCompany('thirdParty', saveShipmentAction.shipment.thirdPartyId)
              ]);

              companyActions.forEach(dispatch);

              // Optionally load the associate that locked the shipment to display on screen.
              if (Core.Utils.isNumber(saveShipmentAction.shipment.lockedAssociateId)) {
                  dispatch(await shell.actions.shipment.modification.loadShipmentLockedAssociate(saveShipmentAction.shipment.lockedAssociateId));
              }

              // Reload the credit status
              if (shipment.customerId) {
                  dispatch(await shell.actions.addressBook.modification.loadCompanyCreditStatus(shipment.customerId));
              }


              dispatch(shell.actions.sys.processComplete(SaveProcessName));
              return saveShipmentAction.shipment;
          },
          async voidOutShipment(bolNumber, voidReason, customerId) {
              await shell.gateway.voidShipment(bolNumber, voidReason, customerId);
          },
          async createCarrierContact() {
              return shell.gateway.createCarrierContact();
          },
          async getCarrier(mcNumber) {
              const carrier = await shell.gateway.getCarrier(mcNumber);
              return carrier;
          },
          async getCreditStatus(companyId) {
              return await shell.gateway.getCompanyCreditStatus(companyId);
          },
          async dispose() {
              dispatch(await shell.actions.shipment.modification.dispose());
          },
          async loadCustomerAgent(agentId) {
              return await shell.gateway.getAssociate(agentId);
          },
          async sendSnackbarMessage(message) {
              dispatch(await shell.actions.sys.sendSnackbarMessage(message));
          },
          async saveCompanyNote(note) {
              dispatch(shell.actions.sys.processStart(SaveProcessName));
              dispatch(await shell.actions.shipment.modification.saveShipmentTrackingNote(note));
              dispatch(shell.actions.sys.processComplete(SaveProcessName));
          },
      }
  })
  .build();
