import ActionTypes from "../../../ActionTypes";

const monthlyAdjustmentCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.Reporting.MonthlyGrossMargin.Disposed:
      return 0;
    case ActionTypes.Reporting.MonthlyGrossMargin.MonthlyCommissionAdjustmentLoaded:
      return action.monthlyAdjustmentCount;
    default:
      return state;
  }
};

export default monthlyAdjustmentCount;