import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/** Notes
 JS: This is as close to good combo box behavior as I could get (so far). This will form the base for future combo boxes until further notice.
 So please try not to alter / deconstruct this code. Make a clone to test on if you want to try and improve upon this code.
 PSA This component does currently rely on the Equipment Types that are set in the global redux state.
 **/

const TgfEquipmentTypesComboBox = (props) => {
    const {
        label,
        id,
        onChange
    } = props;

    const state = useSelector(state => state);
    let equipmentTypes = state.support.shipmentEquipmentTypes;
    equipmentTypes = equipmentTypes.map((type) => { return { id: type.id, display: type.name, freightCategoryId: type.freightCategoryId }; });
    const extData = equipmentTypes;

    const [data, setData] = useState(extData);
    const [typedValue, setTypedValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (props.value) {
            const matched = extData.find(type => type.id === props.value);
            if (matched) {
                setTypedValue(matched);
            } else {
                console.error(`Could not find equipment type id of: ${props.value} in list of equipment types.`);
            }
        }
    }, []);

    const filterData = (filter) => {
        const data = extData.slice();
        return filterBy(data, filter);
    };
    const onFilterChange = (e) => {
        setData(filterData(e.filter));
    };

    const handleOnChange = (e) => {
        const value = e.target.value;
        // If value is selected (clicked)
        if (e.nativeEvent.type === "click") {
            // If value is valid, or null
            if (extData.some(type => type.id === value?.id) || !value) {
                setTypedValue(value);
                setSelectedValue(value);
                onChange({ target: { value: value?.id ?? "", name: props.name } });
            }
        }

        // if value is selected (Enter key pressed)
        if (e.nativeEvent.key === "Enter") {
            // If value is valid
            if (extData.some(type => type.id === value?.id)) {
                setTypedValue(value);
                setSelectedValue(value);
                onChange({ target: { value: value?.id ?? "", name: props.name } });
            }
        }

        // If arrow up or down
        if (e.nativeEvent.key === "ArrowDown" || e.nativeEvent.key === "ArrowUp") {
            // If list is open
            if (isOpen) {
                setTypedValue(value);
            }
        }
    };

    const onClose = (e) => {
        e.nativeEvent.key === "Enter" && handleOnChange(e);
        setIsOpen(false);
    };

    const onBlur = async (e) => {
        setTypedValue(selectedValue);
        setData(equipmentTypes);
        setIsOpen(false);
    };

    const onFocus = () => {
    };

    const onOpen = () => {
        setIsOpen(true);
    };

    return (
        <>
            {label && <Label editorId={id}>{props.label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox"}
                textField={"display"}
                dataItemKey={"id"}
                value={typedValue}
                data={data}
                opened={isOpen}
                onChange={handleOnChange}
                onBlur={onBlur}
                onOpen={onOpen}
                onClose={onClose}
                onFocus={onFocus}
                onFilterChange={onFilterChange}
                filterable={true}
                clearButton={true}
                label={null} // we set this to null, because label is used in the Label component up above.
            />
        </>
    );
};

TgfEquipmentTypesComboBox.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input.
    id: PropTypes.string.isRequired,
    // Name of the input, this is sometimes used to reference in the event handler.
    name: PropTypes.string.isRequired,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,
};

export default TgfEquipmentTypesComboBox;