/**
 * Creates a new Date instance that drops any timestamp, defaulting
 * the time to midnight.
 * @param {Date} [date] - The date to build from, if not specified, today's date is used.
 * @return {Date}
 */
const createMidnightDate = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export default createMidnightDate;