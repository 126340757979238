import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import React from "react";
import AppPercentText from "../../../../core/components/text/AppPercentText";

export const gridColumnOptions = {
    highGmShipments: [
        {
            title: 'Rep',
            field: 'rep',
            type: 'custom',
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId}/>
                </span>
                </td>,
            width: 50
        },
        {
            title: 'Ship #',
            field: 'shipmentNumber',
            type: 'link',
            url: (props) => `/shipment/${props.shipmentNumber}/details`,
            width: 65
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
            width: 250
        },
        {
            title: 'Pickup Date',
            field: 'pickupDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Shipment Status',
            field: 'shipmentStatus',
            type: 'text',
            width: 120,
        },
        {
            title: 'Adj Carrier Cost',
            field: 'adjCarrierCost',
            type: 'currency',
        },
        {
            title: 'Adj Customer Cost',
            field: 'adjCustomerCost',
            type: 'currency',
        },
        {
            title: 'Gross Margin',
            field: 'grossMargin',
            type: 'currency',
        },
        {
            title: 'Locked Date',
            field: 'lockedDate',
            type: 'date',
            width: 80,
        },
        {
            title: 'Carrier Name',
            field: 'carrierName',
            type: 'link',
            url: (props) => `/carrier/${props.carrierId}/compliance`,
            width: 300
        },
    ],
    highGmCustomers: [
        {
            title: 'Rep',
            field: 'rep',
            type: "custom",
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId}/>
                </span>
                </td>,
            width: 50
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
            width: 250
        },
        {
            title: 'Lifetime Shipments',
            field: 'lifetimeShipments',
            type: 'number',
        },
        {
            title: 'Avg. GM %',
            field: 'averageMarginPercentageLifetime',
            type: 'custom',
            cell: (props) =>{
                return (
                    <td>
                        <AppPercentText value={props.dataItem.averageMarginPercentageLifetime} />
                    </td>
                )
            }
        },
        {
            title: 'Last Pickup Date',
            field: 'lastPickupDate',
            type: 'date',
        },
    ],
    matchingRefNumbers: [
        {
            title: 'Rep',
            field: 'rep',
            type: "custom",
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId}/>
                </span>
                </td>,
            width: 50
        },
        {
            title: 'Ship #',
            field: 'shipmentNumber',
            type: 'link',
            url: (props) => `/shipment/${props.shipmentNumber}/details`,
            width: 65
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
            width: 250
        },
        {
            title: 'Pickup Date',
            field: 'pickupDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Shipment Status',
            field: 'shipmentStatus',
            type: 'text',
        },
        {
            title: 'Adj Carrier Cost',
            field: 'adjCarrierCost',
            type: 'currency',
        },
        {
            title: 'Adj Customer Cost',
            field: 'adjCustomerCost',
            type: 'currency',
        },
        {
            title: 'Gross Margin',
            field: 'grossMargin',
            type: 'currency',
        },
        {
            title: 'Locked Date',
            field: 'lockedDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Carrier Name',
            field: 'carrierName',
            type: 'link',
            url: (props) => `/carrier/${props.carrierId}/compliance`,
            width: 300
        },
    ],
    matchingCarriers: [
        {
            title: 'Rep',
            field: 'rep',
            type: "custom",
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId}/>
                </span>
                </td>,
            width: 50
        },
        {
            title: 'Ship #',
            field: 'shipmentNumber',
            type: 'link',
            url: (props) => `/shipment/${props.shipmentNumber}/details`,
            width: 65
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
            width: 250
        },
        {
            title: 'Pickup Date',
            field: 'pickupDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Shipment Status',
            field: 'shipmentStatus',
            type: 'text',
        },
        {
            title: 'Adj Carrier Cost',
            field: 'adjCarrierCost',
            type: 'currency',
        },
        {
            title: 'Adj Customer Cost',
            field: 'adjCustomerCost',
            type: 'currency',
        },
        {
            title: 'Gross Margin',
            field: 'grossMargin',
            type: 'currency',
        },
        {
            title: 'Locked Date',
            field: 'lockedDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Carrier Name',
            field: 'carrierName',
            type: 'link',
            url: (props) => `/carrier/${props.carrierId}/compliance`,
            width: 300
        },
    ],
    creditAppMissing: [
        {
            title: 'Rep',
            field: 'rep',
            type: "custom",
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId}/>
                </span>
                </td>,
            width: 50
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
            width: 250
        },
        {
            title: 'First Pickup Date',
            field: 'firstPickupDate',
            type: 'date',
        },
        {
            title: 'Last Pickup Date',
            field: 'lastPickupDate',
            type: 'date',
        },
    ],
    fiveKPlusCustomerCost: [
        {
            title: 'Rep',
            field: 'rep',
            type: "custom",
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId}/>
                </span>
                </td>,
            width: 50
        },
        {
            title: 'Ship #',
            field: 'shipmentNumber',
            type: 'link',
            url: (props) => `/shipment/${props.shipmentNumber}/details`,
            width: 65
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
            width: 250
        },
        {
            title: 'Pickup Date',
            field: 'pickupDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Shipment Status',
            field: 'shipmentStatus',
            type: 'text',
        },
        {
            title: 'Adj Carrier Cost',
            field: 'adjCarrierCost',
            type: 'currency',
        },
        {
            title: 'Adj Customer Cost',
            field: 'adjCustomerCost',
            type: 'currency',
        },
        {
            title: 'Gross Margin',
            field: 'grossMargin',
            type: 'currency',
        },
        {
            title: 'Locked Date',
            field: 'lockedDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Carrier Name',
            field: 'carrierName',
            type: 'link',
            url: (props) => `/carrier/${props.carrierId}/compliance`,
            width: 300
        },
    ],
};

export const urlPathOptions = {
    highGmShipments: "/admin/risk-review-report/high-gm-shipments",
    highGmCustomers: "/admin/risk-review-report/high-gm-customers",
    matchingRefNumbers: "/admin/risk-review-report/matching-ref-numbers",
    matchingCarriers: "/admin/risk-review-report/matching-carriers",
    creditAppMissing: "/admin/risk-review-report/credit-app-missing",
    fiveKPlusCustomerCost: "/admin/risk-review-report/5k-plus-customer-cost",
};

export const defaultSortOptions = {
    highGmShipments: [{field: "pickupDate", dir: "asc"}],
    highGmCustomers: [{field: "lastPickupDate", dir: "asc"}],
    matchingRefNumbers: [{field: "shipmentNumber", dir: "desc"}],
    matchingCarriers: [{field: "carrierName", dir: "asc"}],
    creditAppMissing: [{field: "lastPickupDate", dir: "asc"}],
    fiveKPlusCustomerCost: [{field: "shipmentNumber", dir: "asc"}]

};

export const navBarTooltipOptions = {
    highGmShipments: "To Qualify: Shipment Status must be New, In Transit, Delivered, Awaiting POD, Claim, or Dispute. Current GM must be $2000 or more. A carrier must be assigned to the shipment.",
    highGmCustomers: "To Qualify: Average Lifetime Gross Margin % must be 30% or more. Must have a shipment with a pickup date within the last 30 days (or in the future). Involved agency cannot be disabled in the CRM.",
    matchingRefNumbers: "To Qualify: Data entered in any field of a shipment’s Reference Numbers section matches what is in any field of another shipment’s Reference Numbers section. Matching shipments must involve the same agency. At least one of the matching shipments must have a Shipment Status of New, In Transit, Delivered, Awaiting POD, Claim, or Dispute.",
    matchingCarriers: "To Qualify: Freight Category must be Truckload. Shipments must involve the same agency. Shipments must have a Shipment Status of New, In Transit, Delivered, Awaiting POD, Claim, or Dispute. Shipments must have the same carrier on at least four shipments and the carrier cannot be Open-TL.",
    creditAppMissing: "To Qualify: The credit app toggle on a customer’s Details page must not be toggled on. Involved agency cannot be disabled in the CRM. The customer’s first shipment must have been at least 30 days or more in the past. The customer must have a shipment with a pickup date within the last 30 days (or in the future).",
    fiveKPlusCustomerCost: "To Qualify: Shipment Status must be New, In Transit, Delivered, Awaiting POD, Claim, or Dispute. Customer Cost must be $5000 or more.",
};
