import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import AppAlertStatic from '../../../core/components/AppAlertStatic';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    searchIcon: {
        fontSize: 65
    }
}));

const SelectCarrierAlert = (props) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                <AppAlertStatic icon={false}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Search className={classes.searchIcon} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h3">Select Carrier</Typography>
                            <Typography variant="body1">
                                Please search for a carrier by name or MC# in the text box above.
                        </Typography>
                        </Grid>
                    </Grid>
                </AppAlertStatic>
            </Grid>
        </Grid>
    )
};

export default SelectCarrierAlert;