import React from 'react';
import { Grid } from '@material-ui/core';
import {CheckCircle, Edit} from '@material-ui/icons';
import {makeStyles} from "@material-ui/core/styles";
import AppIconButton from '../../../../../core/components/AppButton/AppIconButton';
import AppCard from '../../../../../core/components/cards/AppCard';
import AppDenseGrid from '../../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppCurrencyText from '../../../../../core/components/text/AppCurrencyText';

const useStyles = makeStyles((theme) => ({
  tdStyles: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  checkIcon: {
    color: theme.palette.tgfGreen.main
  },
  lessThanZero: {
    color: theme.palette.error.main
  }
}));

const AmountCell = (classes, propName) => ({ rowData: record }) => {
  const amount = record[propName];
  return (
    <AppCurrencyText className={amount < 0 ? classes.lessThanZero : null} value={amount} />
  );
};

const RepPaidCell = (classes) => ({ rowData: shipment }) => {
  return shipment.repPaid ?
    (<CheckCircle className={classes.checkIcon}/>) :
    null;
};

const ControlsCell = (onEditShipmentClick) => ({ rowData: shipment }) => {
  return (
    <div style={{textAlign: 'center'}}>
      <AppIconButton Icon={Edit} tooltip={'Edit Adjustment'} onClick={() => onEditShipmentClick(shipment)} />
    </div>
  );
};

const ShipmentListingCard = (props) => {
  const classes = useStyles();

  const {
    onEditShipmentClick,
    shipments,
    shipmentCount,
    onPageChange,
    onLimitChange,
    onSortChange,
    offset,
    limit,
    sort,
  } = props;

  const gridColumns = [
    {
      title: 'Ship #',
      field: 'bolNumber',
      styles: {
        textAlign: 'center',
        width: 50,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
    },
    {
      title: 'Carrier Cost',
      field: 'adjustedCarrierCost',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: 70,
      },
      dataType: 'component',
      component: AmountCell(classes, 'adjustedCarrierCost')
    },
    {
      title: 'TGF Fee',
      field: '',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: 70,
      },
      dataType: 'component',
      component: AmountCell(classes, 'fee')
    },
    {
      title: 'Customer Cost',
      field: 'adjustedCustomerCost',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: 70,
      },
      dataType: 'component',
      component: AmountCell(classes, 'adjustedCustomerCost')
    },
    {
      title: 'Total Margin',
      field: 'totalMargin',
      noSort: true,
      styles: {
        width: 70,
      },
      dataType: 'component',
      component: AmountCell(classes, 'totalMargin')
    },
    {
      title: 'Type of Load',
      field: 'loadType',
      noSort: true,
      styles: {
        width: 50,
      },
      dataType: 'string'
    },
    {
      title: 'Carrier Name',
      field: 'carrierName',
      noSort: true,
      styles: {
        width: 150,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/carrier/${record.mcNumber}/details`, record.carrierName])
    },
    {
      title: 'Third Party',
      field: 'thirdPartyName',
      noSort: true,
      styles: {
        width: 80,
      },
      dataType: 'string'
    },
    {
      title: 'Pickup Date',
      field: 'bolDate',
      styles: {
        width: 80,
      },
      dataType: 'date'
    },
    {
      title: 'Customer Name',
      field: 'customerName',
      noSort: true,
      styles: {
        width: 150,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.customerName])
    },
    {
      title: 'Earned Payout',
      field: 'earnedPayout',
      noSort: true,
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: AmountCell(classes, 'earnedPayout')
    },
    {
      title: 'Locked Date',
      field: 'actualDeliveryDate',
      noSort: true,
      styles: {
        width: 70,
      },
      dataType: 'date'
    },
    {
      title: 'Rep Paid',
      field: '',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: 50,
      },
      dataType: 'component',
      component: RepPaidCell(classes)
    }
  ];

  if (onEditShipmentClick) {
    gridColumns.push({
      title: '',
      field: '',
      noSort: true,
      dataType: 'component',
      component: ControlsCell(onEditShipmentClick),
      styles: {
        width: 40
      }
    });
  }

  return (
    <AppCard title="Shipments">
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <AppDenseGrid
            count={shipmentCount}
            data={shipments}
            columns={gridColumns}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={onPageChange}
            onChangeRowsPerPage={onLimitChange}
            onSort={onSortChange}
          />
        </Grid>
      </Grid>
    </AppCard>
  )
};

export default ShipmentListingCard;