import React from 'react';
import {
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';

import ComponentBuilder from '../../../core/ComponentBuilder';
import AppSearch from '../../../core/components/inputs/AppSearch';

const HelpSearchDrawer = (props) => {

  const {
    classes,
    searchTerm,
    permanentSearchTerms,
    searchTerms,
    onSearch,
    onSelect,
    onRemove,
    onClear
  } = props;

  const drawerClasses = {
    paper: classes.drawer
  };

  const handleSearchClick = (searchTerm) => () => {
    onSelect(searchTerm)
  };

  const handleClearClick = () => {
    onClear();
  };

  const resetResults = () => {
    onSearch('');
  }

  const handleDeleteClick = (searchTerm) => () => {
    onRemove(searchTerm);
  }

  const handleSearchTermChange = e => {
    const searchTerm = e.target.value;
    onSearch(searchTerm);
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant={'permanent'}
        classes={drawerClasses}>
        <div className={classes.content}>
          <Grid container spacing={1} direction="column" justify="space-evenly">
            <Grid item>
              <Typography variant="h5">Search and Filters</Typography>
              <Divider />
            </Grid>
            <Grid item>
              <AppSearch
                autoFocus={true}
                onChange={handleSearchTermChange}
                value={searchTerm}
              />
            </Grid>
            <Grid item>
              <Grid item className={classes.searchTitle}>
                <Typography variant="h6">Popular Searches</Typography>
                <Divider />
              </Grid>
              <Grid item className={classes.searchListPerm}>
                <List className={classes.listParent}>
                  {
                    permanentSearchTerms.map((st, key) =>
                      <ListItem
                        key={key}
                        button
                        divider
                        selected={st.value === searchTerm}
                        component={'li'}
                        onClick={handleSearchClick(st.value)}
                        className={classes.listItem}
                      >
                        <ListItemIcon className={classes.listIcon}><Search /></ListItemIcon>
                        <ListItemText>{st.label}</ListItemText>
                      </ListItem>
                    )
                  }
                </List>
              </Grid>
              <Grid item className={classes.searchTitle}>
                <Typography variant="h6">Your Searches</Typography>
                <Divider />
              </Grid>
              {
                searchTerms.length > 0 ?
                  <Grid item className={classes.searchList}>
                    <List className={classes.listParent}>
                      {
                        searchTerms.map((st, index) => {
                          return (
                            <ListItem
                              key={index}
                              button
                              divider
                              selected={st === searchTerm}
                              component={'li'}
                              onClick={handleSearchClick(st)}
                              className={classes.listItem}
                            >
                              <ListItemIcon className={classes.listIcon}><Search /></ListItemIcon>
                              <ListItemText>{st}</ListItemText>
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={handleDeleteClick(st)}>
                                  <Clear />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })
                      }
                    </List>
                  </Grid>
                  : <Grid item className={classes.searchTerms}>
                    <Typography variant="h5">No Searches Found</Typography>
                  </Grid>
              }
            </Grid>
            <Grid item className={classes.clearButton}>
              <BottomNavigation
                value={0}
                showLabels
                className={classes.bottomNav}
              >
                <BottomNavigationAction onClick={resetResults} label="Reset Search" className={classes.clearAction} component={Button} />
                <BottomNavigationAction onClick={handleClearClick} label="Clear All" className={classes.clearAction} component={Button} />
              </BottomNavigation>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </div>
  );
};
const drawerWidth = 300;
const styles = (theme) => ({
  root: {
    display: 'flex',
    width: drawerWidth,
    '& .MuiDrawer-root .MuiDrawer-paperAnchorDockedLeft': {
      boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
    }
  },
  active: {
    backgroundColor: theme.palette.tgfGrey.main,
    color: theme.palette.white
  },
  bottomNav: {
    maxHeight: 35,
    width: '100%'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  clearAction: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 5,
    margin: 2,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white
    },
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: 12
    }
  },
  clearButton: {
    position: 'fixed',
    bottom: 10,
    fontSize: 12,
    width: 275,
    padding: '0 !important',
  },
  content: {
    padding: 15
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 60
  },
  drawerPaper: {
    width: drawerWidth,
  },
  listIcon: {
    minWidth: 35
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4
  },
  listParent: {
    paddingTop: 0
  },
  searchList: {
    overflow: 'hidden',
    overflowY: 'scroll',
    height: 'calc(80vh - 282px)'
  },
  searchListPerm: {
    overflowY: 'scroll'
  },
  searchTitle: {
    marginTop: 20,
    marginBottom: 0
  },
  searchTerms: {
    marginTop: 60, 
    textAlign: 'center', 
    opacity: 0.6
  },
  title: {
    textAlign: 'center',
    paddingTop: 20
  }
});

export default ComponentBuilder
  .wrap(HelpSearchDrawer)
  .withStyles(styles)
  .build();