import ActionTypes from '../../ActionTypes';

const associateMetrics = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Persona.LoadedAssociateMonthlyMetrics:
      return action.metrics;
    default:
      return state;
  }
};

export default associateMetrics;