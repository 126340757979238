import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const publishHelpDocument = async (shell, helpDocument ) => {
  const document = await shell.gateway.publishHelpDocument(helpDocument);

  return {
    type: ActionTypes.Support.HelpPublishDocument,
    document
  }
};

export default ActionBuilder
  .for(publishHelpDocument)
  .build();