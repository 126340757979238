import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const loadCarrier = async (shell, mcNumber) => {

  const carrier = await shell.gateway.getCarrier(mcNumber);

  return {
    type: ActionTypes.Carrier.Modification.CarrierLoaded,
    carrier
  }
};

export default ActionBuilder
  .for(loadCarrier)
  .build();