import React from 'react';
import ErrorPage from './ErrorPage';

const Error404Page = (props) => {

  return (
    <ErrorPage title={'Page Not Found (404 Error)'}>
      The page you navigated to was not found.
    </ErrorPage>
  );

};

export default Error404Page;