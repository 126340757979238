import Core from "@atomos/core";
import React from "react";

const DebounceDelegateFactory = (func, delay) => {
  return Core.Utils.debounce(func, delay);
};

const useDebounce = (functionToDebounce, delay) => {
  return React.useCallback(DebounceDelegateFactory(functionToDebounce, delay), []);
};

export default useDebounce;