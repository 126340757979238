import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import moment from 'moment';
import Core from '@atomos/core';

import AppCard from '../../../core/components/cards/AppCard';
import AppIconButton from '../../../core/components/AppButton/AppIconButton';
import {CheckCircle, Cancel, Delete} from '@material-ui/icons';
import {makeStyles} from "@material-ui/core/styles";
import AppMUITable from "../../../core/components/AppDenseGrid/AppMUITable";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";

const useStyles = makeStyles(theme => ({
  centerCells: {
    textAlign: 'center'
  },
  editButton: {
    "&:hover": {
      backgroundColor: "transparent",
      color: '#FFFFFF'
    }
  },
  checkIcon: {
    color: theme.palette.tgfGreen.main
  },
  cancelIcon: {
    color: "red"
  }
}));

const PendingCarrierSetupCard = (props) => {

  const {
    count,
    data,
    onDeleteClick,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    order,
    orderBy,
    rowsPerPage,
    page
  } = props;

  const classes = useStyles();

  const removeButton = (value) => {
    const item = value.rowData;
    return (
      <div style={{textAlign: 'center'}}>
        <AppIconButton Icon={Delete} color="danger" onClick={() => onDeleteClick(item)}/>
      </div>
    )
  }

  // TODO: column widths.
  // TODO: Don't display REP column for Queen/Jack; Ace should see the column.
  // TODO: No BOL # links for Queen/Jack; Ace should have linkage.
  // TODO: Contact column should display tooltip when hovered; email and phone
  // TODO: Move shipment count, shipment date, power units to bar: ORDER: power unites, shipment count, shipment date

  const RepCell = ({ rowData: carrierListing }) => {

    const systemIdText = carrierListing.associateSystemId ?
      `(${carrierListing.associateSystemId})` : null;

    return (
      <div>
        <AgentTooltipComponent content={`${carrierListing.associateFirstName} ${carrierListing.associateLastName} ${systemIdText}`} agentId={carrierListing.associateId}/>
      </div>
    );
  };

  const NullableFlagCell = (fieldKey) =>
    ({ rowData }) => {

      const value = rowData[fieldKey];

      // Nothing to render if the boolean isn't set.
      if (Core.Utils.isVoid(value))
        return null;

      return Boolean(value) ?
        <CheckCircle className={classes.checkIcon}/> :
        <Cancel className={classes.cancelIcon}/>;

    };

  const TimeLeftCell = (value) => {
    const item = value.rowData;
    const dayInMs = 86400000;
    const createDate = new Date(item.createDate);
    const ms = moment(new Date().toMoment(), "DD/MM/YYYY HH:mm:ss").diff(moment(createDate, "DD/MM/YYYY HH:mm:ss"));
    const d = moment.duration(ms);
    const timeElapsed = dayInMs - d;
    const timeLeft = moment.utc(timeElapsed).format('HH:mm:ss');
    return (
      <div className={classes.centerCells}>{timeLeft}</div>
    )
  }

  const GridColumns = [
    {
      title: 'Associate',
      field: 'associateLastName',
      component: RepCell,
      styles: {
        width: '15%'
      },
      dataType: 'component'
    },
    {
      title: 'Carrier Label',
      field: 'label',
      styles: {
        width: '33%'
      },
      dataType: 'string'
    },
    {
      title: 'MC #',
      field: 'mcNumber',
      styles: {
        textAlign: 'center',
        width: '8%'
      },
      dataType: 'string'
    },
    {
      title: 'Carrier in DB',
      field: 'isInDatabase',
      component: NullableFlagCell('isInDatabase'),
      styles: {
        textAlign: 'center',
        width: '8%'
      },
      dataType: 'component'
    },
    {
      title: 'RMIS Compliant',
      field: 'isRmisCertified',
      component: NullableFlagCell('isRmisCertified'),
      styles: {
        textAlign: 'center',
        width: '10%'
      },
      dataType: 'component'
    },
    {
      title: 'Good to Go',
      field: 'isGoodToGo',
      component: NullableFlagCell('isGoodToGo'),
      styles: {
        textAlign: 'center',
        width: '8%'
      },
      dataType: 'component'
    },
    {
      title: 'Time Left',
      field: 'createDate',
      component: TimeLeftCell,
      styles: {
        textAlign: 'center',
        width: '8%'
      },
      dataType: 'component'
    },
    {
      title: '',
      field: '',
      component: removeButton,
      styles: {
        textAlign: 'center',
        width: '5%'
      },
      dataType: 'component'
    }
  ];

  return (
    <AppCard title="Pending Carrier Setups">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            After 24 hours, the carrier will fall off this page automatically. You can add them back to this list if
            need be. If you no longer want to see their progress on this page, you can remove them via the "Remove"
            button. Once the carrier shows Yes under the "Good to Go" column, they are fully setup, can be assigned to a
            shipment, and documents for the shipment can be created.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AppMUITable
            count={count || 0}
            data={data}
            orderBy={orderBy}
            order={order}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
            columns={GridColumns}/>
        </Grid>
      </Grid>
    </AppCard>
  )
}
export default PendingCarrierSetupCard;
