import * as React from "react";

const DateFromUTCDateTimeCell = (cellProps) => {
    let date = null;
    if(cellProps.dataItem[cellProps.field] !== null)
    {
        // UTC date time string  => Date time obj @ local time zone.
        date = new Date(cellProps.dataItem[cellProps.field]);

        date = new Intl.DateTimeFormat('en-US').format(date); // display as mm/dd/yyyy (only displaying date)
    }

    return (
        <td>
            {date !== null ? date : ""}
        </td>
    )
};

export default DateFromUTCDateTimeCell;