import React from "react";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import AppCurrencyText from "../../../../../core/components/text/AppCurrencyText";
import "./css/FinancialAdjustmentCard.scss";
import {useSelector} from "react-redux";
import isAce from "../../../../../hubs/persona/selectors/isAce";
import permissions from "./permissions/financialPagePermissions";

const FinancialAdjustmentCell = ({data, onAddEditEntryClick, entryType, activeIndex}) => {
    return (
        <Grid container alignItems={"center"} className={"financial-adjustment_row"}>
            <Grid item xs={6}>
                {data[activeIndex].reason}
            </Grid>
            <Grid item xs={3} style={{color: entryType.subType === "reduction" ? "red" : "inherit"}}>
                <AppCurrencyText value={data[activeIndex].amount}/>
            </Grid>
            <Grid item xs={3}>
                <a
                    style={{color: "blue"}}
                    onClick={() => onAddEditEntryClick(data, entryType, activeIndex)}
                >View Entry</a>
            </Grid>
        </Grid>
    )
}

const FinancialTransactionCard = (props) => {
    const state = useSelector(state => state)
    const userIsAce = isAce(state);

    return (
        <AppMuiCard title={`${props.entryType.subType === "increase" ? "Added" : "Deducted"} ${props.entryType.type === "carrier" ? "Carrier" : "Customer"} Costs`}>
            <Grid container direction={"column"} >
                { permissions.canAddNewShipmentAdj({userIsAce, ...props}) &&
                <Grid item>
                    <a
                        style={{color: "blue"}}
                        onClick={() => props.onAddEditEntryClick(props.data, props.entryType)}
                    >+ Add {props.entryType.type === "carrier" ? "Carrier" : "Customer"} {props.entryType.subType === "increase" ? "Cost" : "Reduction"}</a>
                </Grid>
                }
                <Grid item>
                        <Grid container className={"financial-adjustment_header"}>
                            <Grid item xs={6}>
                                <p>Reason / Description</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>Cost</p>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                    {props.data.length !== 0 ? props.data.map((cellData, index) => {
                            return <FinancialAdjustmentCell
                                key={cellData.id}
                                data={props.data}
                                onAddEditEntryClick={props.onAddEditEntryClick}
                                activeIndex={index}
                                entryType={props.entryType}
                            />
                        }) :
                        <p style={{fontSize: ".9rem"}}>{`No added ${props.entryType.type === "carrier" ? "Carrier" : "Customer"} ${props.entryType.subType === "increase" ? "costs" : "reductions"} at this time`}</p>
                    }

                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default FinancialTransactionCard;
