import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import AppDialog from '../../../../../core/components/AppDialog';
import AppCurrencyText from '../../../../../core/components/text/AppCurrencyText';

const ConfirmAdjustmentDeleteDialog = (props) => {

  const {
    commissionAdjustment,
    onConfirmClick,
    onCancelClick
  } = props;

  const actions = [
    {
      title: 'Cancel',
      action: onCancelClick
    },
    {
      title: 'Confirm',
      action: onConfirmClick
    }
  ];

  const adjustmentValues = [
    {
      title: 'Related to BOL',
      value: commissionAdjustment.relatedBolNumber
    },
    {
      title: 'Reason',
      value: commissionAdjustment.reason
    },
    {
      title: 'Amount',
      value: commissionAdjustment.additionAmount ?
        <AppCurrencyText value={commissionAdjustment.additionAmount} /> :
        <AppCurrencyText value={-commissionAdjustment.deductionAmount} />
    }
  ];

  return (
    <AppDialog
      title='Confirm Adjustment Delete'
      width={'md'}
      fullWidth={false}
      open={true}
      onClose={onCancelClick}
      actionButtons={actions}
    >
      <Grid container alignItems="flex-start" spacing={1}>
        <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 15 }}>
          <Typography variant="h4">Are you sure you want to delete this adjustment?</Typography>
        </Grid>
        {
          adjustmentValues.map(({ title, value }, index) =>
            <TitleValue title={title} value={value} key={index} />
          )
        }
      </Grid>
    </AppDialog>
  );
};

const TitleValue = (props) => {

  const { title, value } = props;

  if (!value)
    return null;

  return (
    <React.Fragment>
      <Grid item xs={12} md={3}>
        {title}
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography>
          {value}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export default ConfirmAdjustmentDeleteDialog;