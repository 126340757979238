import React from "react";
import "./TgfGradientTitleBar.scss";

const TgfGradientTitleBar = (props) => {
    return (
        <div className={"tgf-gradient-title-bar"}>
            <p>{props.title}</p>
        </div>
    )
};

export default TgfGradientTitleBar;