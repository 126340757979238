import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import VisitationStore from './VisitationStore';

const clearVisitations = (shell) => {

  const visitStore = new VisitationStore();

  visitStore.clearVisitations();

  return {
    type: ActionTypes.Persona.Mrv.VisitationsCleared
  };
};

export default ActionBuilder
  .for(clearVisitations)
  .build();