import React from 'react';
import { LibraryBooks } from '@material-ui/icons';

import FormFactorGraft from 'core/FormFactor/FormFactorGraft';
import AppIconButton from 'core/components/AppButton/AppIconButton';

const CopyAddressTooltip = `Copy from company's primary address.`;

const buttonSlices = ['setFieldValues', 'supportingValues.company', 'supportingValues.stateProvinces'];
const buttonGraftMap = ([setFieldValues, company, stateProvinces]) => ({
  onClick: () => {
    const selectedStateProvince = stateProvinces.find(sp => sp.abbreviation === company.stateProvince)
    setFieldValues([
      ['billingAddress1', company?.address1],
      ['billingAddress2', company?.address2],
      ['billingCity', company?.city],
      ['billingStateProvince', company?.stateProvince],
      ['selectedStateProvince', selectedStateProvince],
      ['billingPostalCode', company?.postalCode]
    ])
  }
});

const AddressCopyButton = (props) => {

  return (
    <FormFactorGraft
      as={AppIconButton}
      color={'primary'}
      key={'copy'}
      Icon={LibraryBooks}
      tooltip={CopyAddressTooltip}
      slices={buttonSlices}
      graftMap={buttonGraftMap}
      disabled={props.disableEdit}
    />
  )
};

export default AddressCopyButton;