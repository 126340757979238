import React from 'react';
import AppNumberText from "../../../../core/components/text/AppNumberText";
import isNewShipment from "../../../../hubs/shipment/actions/modification/isNewShipment";


const CurrentCreditRemaining = ({creditStatus, bolNumber}) => {
  let conditionalStyles;
  if(creditStatus) {
    if (creditStatus.companyCreditRemaining <= 0) {
      conditionalStyles = {
        color: 'red',
      }
    } else {
      conditionalStyles = {
        color: 'green',
      }
    }
  }
    return (
      <div className={'current-available-credit-div'}>
        {creditStatus ?
          <>
            <span>Current Avail. Credit: $</span>
            <AppNumberText style={conditionalStyles} value={creditStatus.companyCreditRemaining}/>
          </>
          :
          isNewShipment(bolNumber) ? null : <span>Current Avail. Credit: <span style={{color: '#f57e00'}}>Must Click "SAVE"</span></span>
        }
      </div>
    )
}

export default CurrentCreditRemaining;