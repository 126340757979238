import React from 'react';
import {Grid} from "@material-ui/core";
import ComponentBuilder from "../../../../core/ComponentBuilder";

import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiCard from "../../../../core/components/cards/AppCard";
import AppNumericInput from "../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";

import '../financialPage.scss';
import SecureField from "../../permissions/SecureField";

const DeductedCustomerCosts = (props) => {

  const {
    isAce,
    customerDeductedCost1Label,
    customerDeductedCost1,
    customerDeductedCost2Label,
    customerDeductedCost2,
    customerDeductedCost1LabelError,
    customerDeductedCost1Error,
    customerDeductedCost2LabelError,
    customerDeductedCost2Error,
    setFieldValue
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(`invoice.${key}`, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <AppMuiCard title={'Deducted Customer Costs'}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerDeductedCost1Label'}
                as={AppInput}
                id={'customerDeductedCost1Description'}
                disabled={!isAce}
                value={customerDeductedCost1Label || ''}
                inputProps={{
                  maxLength: 30
                }}
                onChange={handleFieldChange('customerDeductedCost1Label')}
              />
              <AppValidationFailure message={customerDeductedCost1LabelError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerDeductedCost1'}
                as={AppNumericInput}
                id={'customerDeductedCost1Value'}
                disabled={!isAce}
                decimalScale={2}
                type={'text'}
                value={customerDeductedCost1}
                onChange={handleFieldChange('customerDeductedCost1')}
              />
              <AppValidationFailure message={customerDeductedCost1Error}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerDeductedCost2Label'}
                as={AppInput}
                id={'customerDeductedCost2Description'}
                disabled={!isAce}
                value={customerDeductedCost2Label || ''}
                inputProps={{
                  maxLength: 30
                }}
                onChange={handleFieldChange('customerDeductedCost2Label')}
              />
              <AppValidationFailure message={customerDeductedCost2LabelError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerDeductedCost2'}
                as={AppNumericInput}
                id={'customerDeductedCost2Value'}
                disabled={!isAce}
                decimalScale={2}
                type={'text'}
                value={customerDeductedCost2}
                onChange={handleFieldChange('customerDeductedCost2')}
              />
              <AppValidationFailure message={customerDeductedCost2Error}/>
            </Grid>
          </Grid>
        </AppMuiCard>
      </Grid>
    </React.Fragment>
  )
};

const propsAreEqual = (prevProps, nextProps) => {
  const isEqual = prevProps.customerDeductedCost1Label === nextProps.customerDeductedCost1Label &&
    prevProps.customerDeductedCost1 === nextProps.customerDeductedCost1 &&
    prevProps.customerDeductedCost2Label === nextProps.customerDeductedCost2Label &&
    prevProps.customerDeductedCost2 === nextProps.customerDeductedCost2 &&
    prevProps.customerDeductedCost1LabelError === nextProps.customerDeductedCost1LabelError &&
    prevProps.customerDeductedCost1Error === nextProps.customerDeductedCost1Error &&
    prevProps.customerDeductedCost2LabelError === nextProps.customerDeductedCost2LabelError &&
    prevProps.customerDeductedCost2Error === nextProps.customerDeductedCost2Error;

  return isEqual;
};

export default React.memo(ComponentBuilder
    .wrap(DeductedCustomerCosts)
    .build(),
  propsAreEqual
);