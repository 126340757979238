import ActionTypes from "../../../ActionTypes";

const shipmentContents = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.Disposed:
      return [];
    case ActionTypes.Shipment.Modification.ShipmentMultipleStopsLoaded:
    case ActionTypes.Shipment.Modification.ShipmentMultipleStopsSaved:
    case ActionTypes.Shipment.Modification.ShipmentMultipleStopsReset:
      return action.shipmentMultipleStops;
    default:
      return state;
  }
};

export default shipmentContents;