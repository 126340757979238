import React from 'react';
import Core from '@atomos/core';
import AppDrawer from '../../core/components/AppDrawer';
import {
  CircularProgress,
  Grid, IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core';
import AppMuiCard from '../../core/components/cards/AppCard';
import {Close as CloseIcon, Description as DescriptionIcon} from '@material-ui/icons';
import ComponentBuilder from '../../core/ComponentBuilder';
import {Link} from 'react-router-dom';

const DocumentLoader = (methodKey) => async (id) =>
  await window.shell.gateway[methodKey](id);

const DocumentListHeader = ComponentBuilder.wrap(({ children, classes }) =>
  (<ListSubheader className={classes.header}>{children}</ListSubheader>))
  .withStyles((theme) => ({
    header: {
      fontSize: '1.2em',
      backgroundColor: '#809080',
      color: '#fff',
      borderRadius: 4,
      lineHeight: '40px'
    }
  }))
  .build();

const DocumentLink = ComponentBuilder.wrap(({ classes, url, title, subTitle }) => {
    const iconElement = (<a href={url} target={'_blank'} rel='noopener noreferrer' className={classes.link}><DescriptionIcon /></a>);
    const titleElement = (<a href={url} target={'_blank'} rel='noopener noreferrer' className={classes.link}>{title}</a>);
    const subTitleElement = subTitle ? (<a href={url} target={'_blank'} rel='noopener noreferrer' className={classes.link}>{subTitle}</a>) : null;

    return (
      <React.Fragment>
        <ListItemIcon className={classes.icon}>
          {iconElement}
        </ListItemIcon>
        <ListItemText
          primary={titleElement}
          secondary={subTitleElement}
        />
      </React.Fragment>
    );
  })
  .withStyles((theme) => ({
    link: {
      textDecoration: 'none',
      color: theme.palette.tgfLink.main,
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    icon: {
      minWidth: 35
    }
  }))
  .build();

const DocumentList = (deriveLinkProps, deriveNoDocumentsText) => (props) => {
  const {
    documents,
    id
  } = props;

  const groupedDocs = Core.Utils.groupBy(documents, 'type.name');
  const groupedPairs = Array.from(Object.entries(groupedDocs));

  if (groupedPairs.length === 0) {
    return (
      <Typography variant={'h5'}>
        {deriveNoDocumentsText(id)}
      </Typography>
    );
  }

  return (
   <List>
     {
       groupedPairs
         .map(([name, docs], groupIndex) => {
           return (
             <List subheader={<DocumentListHeader>{name}</DocumentListHeader>} key={groupIndex}>
               {
                 docs.map((doc, index) => {

                   return (
                     <ListItem key={index}>
                       <DocumentLink {...deriveLinkProps(id, doc)} />
                     </ListItem>
                   );

                 })
               }
             </List>
           )
         })
     }
   </List>
  );
};

const RecordLoaders = {
  carrierId: DocumentLoader('getCarrierDocuments'),
  companyId: DocumentLoader('getCompanyDocuments'),
  shipmentId: DocumentLoader('getShipmentDocuments')
};

const getLinkTitles = (doc) => {
  const title = doc.description || doc.filename;
  const subTitle = doc.description ? doc.filename : null;
  return {
    title,
    subTitle
  };
};

const getCarrierLinkProps = (id, doc) => {
  return {
    url: `/file/carrier/${id}/document/${doc.id}`,
    ...getLinkTitles(doc)
  };
};

const getCompanyLinkProps = (id, doc) => {
  return {
    url: `/file/company/${id}/document/${doc.id}`,
    ...getLinkTitles(doc)
  };
};

const getShipmentLinkProps = (id, doc) => {
  return {
    url: `/file/shipment/${id}/document/${doc.id}`,
    ...getLinkTitles(doc)
  };
};

const getCarrierNoDocsAvailable = (id) => {
  return (
    <React.Fragment>
      No documents available. See <Link to={`/carrier/${id}/documents`}>Carrier Document Page</Link> for details.
    </React.Fragment>
  )
};

const getCompanyNoDocsAvailable = (id) => {
  return (
    <React.Fragment>
      No documents available. See <Link to={`/company/${id}/documents`}>Company Document Page</Link> for details.
    </React.Fragment>
  )
};

const getShipmentNoDocsAvailable = (id) => {
  return (
    <React.Fragment>
      No documents available. See <Link to={`/shipment/${id}/documents`}>Shipment Document Page</Link> for details.
    </React.Fragment>
  )
};

const Comps = {
  carrierId: DocumentList(getCarrierLinkProps, getCarrierNoDocsAvailable),
  companyId: DocumentList(getCompanyLinkProps, getCompanyNoDocsAvailable),
  shipmentId: DocumentList(getShipmentLinkProps, getShipmentNoDocsAvailable)
};

const loaderInvocation = async ([key, id]) => {
  const delegate = RecordLoaders[key];
  if (!delegate)
    throw new Error(`RecordLoader for [${key}] not found.`);
  return [key, id, await delegate(id)];
};

const CrmDocumentQuickViewDrawer = (props) => {

  const [isLoading, setIsLoading] = React.useState(true);
  const [docSets, setDocSets] = React.useState([]);

  const tuples = Object.keys(props)
    .filter(key => key.endsWith('Id'))
    .map((key) => [key, props[key]]);

  // Stringify just the IDs cared about.  It will
  // create a single string dependency regardless of
  // the number of IDs specified.
  const dependency = JSON.stringify(Object.fromEntries(tuples));

  React.useEffect(() => {

    const promises = tuples
      .map(loaderInvocation);

    Promise.all(promises)
      .then((documentSets) => {
        setIsLoading(false);
        setDocSets(documentSets);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });

  }, [dependency, tuples]);

  const drawerCloseAction = (
    <IconButton
      color={'primary'}
      onClick={props.onClose}
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    <AppDrawer open={true} onClose={props.onClose} anchor={'right'} style={{ minWidth: 400, maxWidth: 400 }}>
      <div style={{width: 500, height: '100vh'}}>
        <AppMuiCard title={'Document Quick View'} action={drawerCloseAction}>
          <Grid container spacing={1}>
            {
              isLoading &&
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            }
            {
              !isLoading &&
              docSets.map(([key, id, documents]) => {
                const Comp = Comps[key];
                return (
                  <Grid item xs={12} key={key}>
                    <Comp documents={documents} id={id} />
                  </Grid>
                );
              })
            }
          </Grid>
        </AppMuiCard>
      </div>

    </AppDrawer>
  )

};

export default CrmDocumentQuickViewDrawer;