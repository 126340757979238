import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveCompany = async (shell, companyInvoiceSettings) => {
  const updatedSettings = await shell.gateway.saveCompanyInvoiceSettings(companyInvoiceSettings);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyInvoiceSettingsSaved,
    companyInvoiceSettings: updatedSettings
  };

};

export default ActionBuilder
  .for(saveCompany)
  .build();