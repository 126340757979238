import ActionTypes from '../../../ActionTypes';

const associates = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Admin.ManageUsers.Disposed:
      return [];
    case ActionTypes.Admin.ManageUsers.ListingLoaded:
      return action.associates;
    default:
      return state;
  }
};

export default associates;