import * as yup from "yup";
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const billingContactValidationSchema = yup.object().shape({
    firstName: yup.string().required(),
    email: yup.string().email().nullable(),
    directPhone: yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
    mobilePhone: yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
    fax: yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
    emailOption: yup.string().required(),
});
