import { Button, Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { useDispatch } from "react-redux";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import TgfTextArea from "../../../../../core/kendo/form/inputs/TgfTextArea";
import useFieldValidator from "../../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import { collectionNoteValidationSchema } from "./collectionNotesValidationSchema";

const CollectionNotesModal = (props) => {
    const personalDispatch = useDispatch();
    const [isValid, setIsValid] = useState(true);
    const [isDirty, setIsDirty] = useState(false);

    const [formData, setFormData] = useState({
        note: null
    });

    const handleSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveCollectionContact'));

            const collectorNoteData = {
                note: formData.note,
                companyId: props.companyId,
            };

            await window.shell.gateway.saveCollectorNote(collectorNoteData, props.companyId);

            setIsDirty(false);
            props.onCloseDialog();
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'Collection Note Saved' }));
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Collection Note Save Failed',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveCollectionContact'));
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value ? e.target.value : null
        });
        setIsDirty(true);
    };

    // TODO: create schema
    const fieldErrors = useFieldValidator(collectionNoteValidationSchema, formData, setIsValid);

    return (
        <TgfDialog
            title={`${props?.cellData?.id !== null ? "Edit" : "Add"} Collection Contact`}
            onClose={props.onCloseDialog}
            width={600}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Label editorId={"note"}>Note</Label>
                    <TgfTextArea
                        id={"note"}
                        name={"note"}
                        value={formData.note}
                        onChange={handleChange}
                        rows={6}
                    />
                    <FieldError >{fieldErrors.note}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        type={"button"}
                        disabled={Boolean(!isValid || !isDirty)}
                        onClick={handleSave}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </TgfDialog>
    );
};

export default CollectionNotesModal;
