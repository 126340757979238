import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const manualDisablement = async (shell, companyId, reasonText) => {

  const updatedCompany = await shell.gateway.disableCompany(companyId, reasonText);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyDisabled,
    company: updatedCompany
  };

};

export default ActionBuilder
  .for(manualDisablement)
  .build();