import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadAssociateMonthlyMetrics = async (shell) => {

  const metrics = await shell.gateway.getAssociateMonthlyMetrics();

  return {
    type: ActionTypes.Persona.LoadedAssociateMonthlyMetrics,
    metrics
  };

};

export default ActionBuilder
  .for(loadAssociateMonthlyMetrics)
  .build();