import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveShipmentCarrierContact = async (shell, carrierContact) => {

  const shipmentCarrierContact = await shell.gateway.saveCarrierContact(carrierContact);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentCarrierContactSaved,
    shipmentCarrierContact
  }
};

export default ActionBuilder
  .for(saveShipmentCarrierContact)
  .build();
