import {useEffect, useState} from "react";
const useFieldValidator = (schema, formValues, setIsValid = null) => {
  const [fieldErrors, setFieldErrors] = useState({});
  useEffect(() => {
    const check = async () => {
      try {
        if(schema === null) return;
        await schema.validate(formValues, {abortEarly: false});
        setFieldErrors({});
      } catch (e) {
        const errors = {};
        for (let key in formValues) {
          for(let error of e.inner) {
            if(error.path === key) {
              errors[key] = error.message.replace(`${key}`, "");
            }
          }
        }
        setFieldErrors(errors);
      }
    }
    check();
  }, [formValues])

  useEffect(() => {
    if(setIsValid === null) return;
    if(Object.keys(fieldErrors).length !== 0) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [fieldErrors])

  return fieldErrors
}

export default useFieldValidator;
