/**
 * Computes the start and end dates and times for a given month.  The ending date will
 * be computed to 11:59:59:999 PM.
 * @param {Date} [sourceDate] - Date value to derive the month/year from and compute range for.
 * @param {boolean} [makeUtc] - The starting of the range starts at UTC midnight when true,
 *                              otherwise midnight local TZ. Default: true
 * @return {[Date, Date]}
 */
const computeMonthDateRange = (sourceDate = new Date(), makeUtc = true) => {

  const startDateFactory = makeUtc ?
    utcStartDateFactory : localTzDateFactory;
  const endMomentFactory = makeUtc ?
    utcEndMomentFactory : localTzMomentFactory;

  const startDate = startDateFactory(sourceDate);
  const endDate = endMomentFactory(startDate).add(1, 'month').subtract(1, 'ms').toDate();

  return [startDate, endDate];
};

const dateParts = (date) => [date.getFullYear(), date.getMonth(), 1];
const utcStartDateFactory = (date) => new Date(Date.UTC(...dateParts(date)));
const utcEndMomentFactory = (date) => date.toMoment().utc();
const localTzDateFactory = (date) => new Date(...dateParts(date));
const localTzMomentFactory = (date) => date.toMoment();

export default computeMonthDateRange;
