import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadDeliveredCount = async (shell) => {

  const trackedSetCounts = await shell.gateway.getDeliveryCount();

  return {
    type: ActionTypes.Shipment.TrackedSetCountsLoaded,
    trackedSetCounts
  };

};

export default ActionBuilder
  .for(loadDeliveredCount)
  .build();