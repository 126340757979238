import ActionTypes from '../../../ActionTypes';

const notes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.Disposed:
      return [];
    case ActionTypes.AddressBook.Modification.Loaded:
    case ActionTypes.AddressBook.Modification.Saved:
      return action.notes;
    default:
      return state;
  }
};


export default notes;