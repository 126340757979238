import deletePendingCarrier from "./deletePendingCarrier";
import dispose from "./dispose";
import load from "./load";
import savePendingCarrier from './savePendingCarrier';

export default {
  deletePendingCarrier,
  dispose,
  load,
  savePendingCarrier
};