import GatewayCommunicator from '../clients/GatewayCommunicator';
import createStateStoreReducers from './createStateStoreReducers';
import config from '../config';
import createStateStoreActions from './createStateStoreActions';
import createStateStoreMiddleware from './createStateStoreMiddleware';
import createStateStore from './createStateStore';
import GatewayFacade from '../../hubs/GatewayFacade';
import CacheBuster from '../../core/caching/CacheBuster';

const SessionExpiredEvent = 'session-expired';

const start = async () => {

  if (config.isCloud() && !config.isSecure()) {
    const newUrl = `https://${window.location.host}`;
    return window.location.replace(newUrl);
  }

  const communicator = new GatewayCommunicator(config);
  const gateway = new GatewayFacade(communicator);

  const cacheBuster = new CacheBuster();

  const initialState = {};

  const rootReducer = createStateStoreReducers(config);
  const middleware = createStateStoreMiddleware(config);
  const stateStore = createStateStore(config, rootReducer, middleware, initialState);
  const actions = createStateStoreActions();

  const shell = {
    actions,
    cacheBuster,
    config,
    communicator,
    gateway,
    stateStore
  };

  window.shell = shell;

  // Hub initialization goes here.  For now, attempt to identify
  // the associate to engage security.
  shell.stateStore.dispatch(await shell.actions.persona.identify());
  
  communicator.on(SessionExpiredEvent, () =>
    shell.stateStore.dispatch(shell.actions.persona.sessionExpired()));

  return shell;
};

export default start;