import ActionTypes from "../../../ActionTypes";

const closedCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.Automation.RetrieveClosed.Loaded:
      return action.count;
    default:
      return state;
  }
};

export default closedCount;