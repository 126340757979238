import React from 'react';

const AppText = (props) => {

  const {
    value = null,
    ...otherProps
  } = props;

  const isDecorated = Boolean(Object.keys(otherProps).length);

  return isDecorated ?
    <span {...otherProps}>{value}</span> :
    value;

};

export default AppText;