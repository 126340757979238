import React from 'react';
import PropTypes from 'prop-types';
import Core from '@atomos/core';
import FormFactorContext from './FormFactorContext';

const FormFactorSlice = (props) => {

  const {
    slices,
    children
  } = props;

  if (!Core.Utils.isFunction(children)) {
    throw new TypeError(`FormFactorSlice requires a single child of type function. That function will receive the data defined by the slices property.`)
  }

  return (
    <FormFactorContext.Consumer>
      {
        (formFactorContext) => {
          return (
            <FormFactorRenderGate
              formFactorContext={formFactorContext}
              slices={slices}
              children={children}
            />
          );
        }
      }
    </FormFactorContext.Consumer>
  );
};

class FormFactorRenderGate extends React.Component {

  static getSlicesMap(slices) {
    const pairs = Core.Utils.isArray(slices) ?
      slices.filter(Core.Utils.isString).map((slice, key) => [key, slice]) :
      Object.entries(slices).filter(([key, slice]) => Core.Utils.isString(slice));

    return new Map(pairs);
  }

  static getSlicesArrayData(slicesMap, context) {
    return Array.from(slicesMap)
      .map(([_, slicePath]) => Core.Utils.get(context, slicePath));
  }

  static getSlicesObjectData(slicesMap, context) {
    const dataPairs = Array.from(slicesMap)
      .map(([key, slicePath]) => [key, Core.Utils.get(context, slicePath)]);
    return Object.fromEntries(dataPairs);
  }

  static getSlicesData(slices, context) {
    const slicesMap = FormFactorRenderGate.getSlicesMap(slices);
    // Reassemble the data as an array if the first key is a number.
    const assembleAsArray = Core.Utils.isNumber(Array.from(slicesMap.keys())[0]);

    return assembleAsArray ?
      FormFactorRenderGate.getSlicesArrayData(slicesMap, context) :
      FormFactorRenderGate.getSlicesObjectData(slicesMap, context);
  }

  static slicesHaveChanged(origSlicesMap, newSlicesMap) {

    if (origSlicesMap.size !== newSlicesMap.size)
      return true;

    const origSliceMapKeys = Array.from(origSlicesMap.keys());
    const newSliceMapKeys = Array.from(newSlicesMap.keys());

    return origSliceMapKeys.some((origSliceKey, index) => {
      const newSliceKey = newSliceMapKeys[index];
      return origSliceKey !== newSliceKey ||
        origSlicesMap.get(origSliceKey) !== newSlicesMap.get(newSliceKey);
    });
  }

  static slicesDataHasChanged(slicesMap, origContext, newContext) {
    const slicePaths = Array.from(slicesMap.values());
    const origSlicesData = slicePaths
      .map(slice => Core.Utils.get(origContext, slice));
    const newSlicesData = slicePaths
      .map(slice => Core.Utils.get(newContext, slice));

    return origSlicesData.some((origValue, index) => origValue !== newSlicesData[index]);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {

    const origSlicesMap = FormFactorRenderGate.getSlicesMap(this.props.slices);
    const newSlicesMap = FormFactorRenderGate.getSlicesMap(nextProps.slices);

    // If the slices property has changed key/string pairs, then we need to update.
    return FormFactorRenderGate.slicesHaveChanged(origSlicesMap, newSlicesMap) ||
      // Otherwise, if any data values changed allow a rerender.
      FormFactorRenderGate.slicesDataHasChanged(newSlicesMap, this.props.formFactorContext, nextProps.formFactorContext);
  }

  render() {

    const {
      formFactorContext,
      slices,
      children
    } = this.props;

    const slicesData = FormFactorRenderGate.getSlicesData(slices, formFactorContext);
    return children(slicesData) || null;
  }

}

FormFactorSlice.propTypes = {
  slices: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object
  ]).isRequired,
  children: PropTypes.func.isRequired
};

export default FormFactorSlice;