import ActionTypes from "../../../ActionTypes";

const shipmentCarrierContact = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.Disposed:
      return null;
    case ActionTypes.Shipment.Modification.shipmentCarrierContactLoaded:
    case ActionTypes.Shipment.Modification.ShipmentCarrierContactSaved:
      return action.shipmentCarrierContact;
    default:
      return state;
  }
};

export default shipmentCarrierContact;