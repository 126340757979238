import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadMonthlyAssociatePayments = async (shell, paymentDate) => {

  const {monthlyAssociatePayments, count} = await shell.gateway
    .getMonthlyAssociatePayments(paymentDate);

  return {
    type: ActionTypes.Admin.CommissionSummary.PaymentsLoaded,
    monthlyAssociatePayments,
    monthlyAssociatePaymentCount: count
  }
};

export default ActionBuilder
  .for(loadMonthlyAssociatePayments)
  .build();