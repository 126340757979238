import ActionTypes from '../../ActionTypes';

const snackbarMessage = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Sys.ReceiveSnackbarMessage:
      return action.message;
    case ActionTypes.Sys.ClearSnackbarMessage:
      return null;
    default:
      return state;
  }
};

export default snackbarMessage;