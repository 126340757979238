import Core from '@atomos/core';

const config = {
    env: process.env.NODE_ENV || 'production',
    // Allowed TGF environments: local, development, testing, production
    tgfEnv: process.env.REACT_APP_TGF_ENV || 'production',
    // Set the TGF_CRM_URL variable when developing locally.
    crmUrl: process.env.REACT_APP_TGF_CRM_URL
};

// Identifies whether the app is running under SSL/TLS.
config.isSecure = () => Core.Utils.startsWith(window.location.protocol, 'https');

// Setup up TGF environmental functions.  Business logic and UI
// changes should be driven from this, not config.env (NODE_ENV).
config.isLocal = () => config.tgfEnv === 'local';
config.isCloud = () => !config.isLocal();
config.isDevelopment = () => config.tgfEnv === 'development';
config.isTesting = () => config.tgfEnv === 'testing';
config.isProduction = () => config.tgfEnv === 'production';

if (config.isCloud()) {
    // Build the gateway using same-host information as ingress
    // handles routing.
    config.crmUrl = `${window.location.protocol}//${window.location.host}/api`;
}

if (config.isLocal) {
    config.disableReduxLogger = process.env.REACT_APP_DISABLE_REDUX_LOGGING?.toLowerCase() || 'false';
}

export default config;