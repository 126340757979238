import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const setQaFailed = async (shell, teraCrunchInputId, dispositionStatus, note) => {

  await shell.gateway.setQaFailed(teraCrunchInputId, dispositionStatus, note);

  return {
    type: ActionTypes.Automation.QaFailed,
    teraCrunchInputId
  };
};

export default ActionBuilder
  .for(setQaFailed)
  .build();