import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadGrossMarginBreakdown = async (shell, associateId, customerId, startDate, endDate) => {

  const grossMarginBreakdown = await shell.gateway.getAssociateGrossMarginBreakdown(startDate, endDate, associateId, customerId);

  return {
    type: ActionTypes.Reporting.CustomerReport.GrossMarginBreakdownLoaded,
    grossMarginBreakdown
  };
}

export default ActionBuilder
  .for(loadGrossMarginBreakdown)
  .build();