import ActionTypes from '../../../ActionTypes';

const shipmentCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.LoadBoard.Disposed:
      return 0;
    case ActionTypes.Shipment.LoadBoard.Loaded:
      return action.shipmentCount;
    default:
      return state;
  }
};

export default shipmentCount;