import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const loadPayPeriodShipment = async (shell, associateId, startDate, offset, limit, sort) => {

  const {shipments, count} = await shell.gateway.getAssociatePayPeriodShipments(
    associateId,
    {
      startDate,
      offset,
      limit,
      sort
    }
  );

  return {
    type: ActionTypes.CommissionCenter.PayPeriodShipmentLoaded,
    payPeriodShipments: shipments,
    payPeriodShipmentCount: count
  }

};

export default ActionBuilder
  .for(loadPayPeriodShipment)
  .build();