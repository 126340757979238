import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from '../../ActionTypes';

const loadPaymentTerms = async ({ shell }) => {

  const paymentTerms = await shell.gateway.getPaymentTerms();

  return {
    type: ActionTypes.Support.PaymentTermsLoaded,
    paymentTerms
  };

};

export default ActionBuilder
  .for(loadPaymentTerms)
  .build();