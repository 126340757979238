import React, {useEffect, useState} from "react";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import ReactQuill from "react-quill";
import NamedColors from "../NamedColors";
import {useDispatch} from "react-redux";
import AppButton from "../../../../core/components/AppButton/AppMuiButton";
import {Save, Undo} from "@material-ui/icons";
import _ from "lodash";

const BirthdaysAndAnniversariesForm = (props) => {
    const personalDispatch = useDispatch();
    const [value, setValue] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [originalSpecialOccasionNote, setOriginalSpecialOccasionNote] = useState('<p><br></p>');

    const load = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadSpecialOccasionNote'));
            const result = await window.shell.gateway.getSpecialOccasionNote();
            if(result) {
                setValue(result.note);
                setOriginalSpecialOccasionNote(result.note);
            } else {
                setValue('<p><br></p>');
            }
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadSpecialOccasionNote'));
        }
    }

    const handleChange = (e) => {
        // change if event exists, event is not equal to the current value, and
        // event does not match the original note (i.e. reset has not been clicked)
        if(e && e !== value && e !== originalSpecialOccasionNote) {
            setValue(e ?? "");
            // when we load the page, this handle is ran
            // we only set isDirty to true if value exists
            if (value) {
                setIsDirty(true);
            }
        } else if (e === originalSpecialOccasionNote) {
            // if the event is changed back to the original note, set the value to the event and
            // change dirty to false (i.e. disable buttons)
            setValue(e ?? "");
            setIsDirty(false);
        }
    };

    const handleSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadSpecialOccasionNote'));
            await window.shell.gateway.saveSpecialOccasionNote({id: 1, note: value});
            await load();
            setIsDirty(false);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: 'Birthday/Anniversary Note Saved'}));
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save Birthday/Anniversary Note',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadSpecialOccasionNote'));
        }

    }

    const handleReset = () => {
        setValue(originalSpecialOccasionNote);
        setIsDirty(false);
    };

    useEffect(() => {
        load()
    }, []);

    const ReactQuillModules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', { color: NamedColors }, { background: NamedColors }],
            ['blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link']
        ],
    };

    return (
      <AppMuiCard title={"Birthdays and Anniversaries"}>
          <Grid container>
              <Grid item xs={12}>
                  <ReactQuill
                    theme={'snow'}
                    style={{ height: 250, marginBottom: 45 }}
                    modules={ReactQuillModules}
                    value={value}
                    onChange={handleChange}
                  />
              </Grid>
          </Grid>
          <Grid container spacing={2}>
              <Grid item>
                  <AppButton
                    type={'submit'}
                    startIcon={<Save />}
                    disabled={!isDirty}
                    onClick={handleSave}>
                      Save
                  </AppButton>
              </Grid>
              <Grid item>
                  <AppButton
                    startIcon={<Undo />}
                    disabled={!isDirty}
                    onClick={handleReset}
                  >
                      Reset
                  </AppButton>
              </Grid>
          </Grid>
      </AppMuiCard>
    )
}

export default BirthdaysAndAnniversariesForm;

