import React from 'react';
import { Grid } from '@material-ui/core';

import AppCard from '../../../../../core/components/cards/AppCard';
import AppDenseGrid from '../../../../../core/components/AppDenseGrid/AppDenseGrid';
import {CheckCircle, Delete, Edit, Add, PlaylistAdd} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import AppCurrencyText from '../../../../../core/components/text/AppCurrencyText';

const useStyles = makeStyles(theme => ({
  centerCells: {
    textAlign: 'center'
  },
  checkIcon: {
    color: theme.palette.tgfGreen.main
  },
  lessThanZero: {
    color: theme.palette.error.main
  }
}));

const AssociateCell = (associate) => () => {
  return associate ?
    (<div>{associate.firstName} {associate.lastName} ({associate.systemId})</div>) :
    null;
};

const AdjustmentTotalCell = (classes) => ({ rowData: commissionAdjustment }) => {
  const amount = commissionAdjustment.additionAmount ?
    commissionAdjustment.additionAmount :
    -commissionAdjustment.deductionAmount;
  return <AppCurrencyText value={amount} className={amount < 0 ? classes.lessThanZero : null} />
};

const RepPaidCell = (classes) => ({ rowData: commissionAdjustment }) => {
  const repWasPaid = commissionAdjustment?.repPaid > 0;
  const repConfirmedPaid = commissionAdjustment?.repPaidConfirmed > 0;
  return repWasPaid || repConfirmedPaid ?
    <CheckCircle className={classes.checkIcon}/> :
    null;
};

const ControlsCell = (onEditClick, onDeleteClick) => ({ rowData: adjustment }) => {
  return (
    <div style={{textAlign: 'center'}}>
      <AppIconButton Icon={Edit} tooltip="Edit Adjustment" onClick={() => onEditClick(adjustment)} />
      <AppIconButton Icon={Delete} tooltip="Delete Adjustment" color="danger" onClick={() => onDeleteClick(adjustment)} />
    </div>
  );
};

const AdjustmentListingCard = (props) => {
  const classes = useStyles();

  const {
    adjustments,
    adjustmentCount,
    associate,
    onAddNewAdjustment,
    onCopyPreviousAdjustment,
    onDeleteClick,
    onEditClick,
    onPageChange,
    onLimitChange,
    onSortChange,
    offset,
    limit,
    sort,
  } = props;

  const cardActions = [
    onAddNewAdjustment && <AppIconButton key={'add'} Icon={Add} tooltip="Add new Adjustment" onClick={onAddNewAdjustment} />,
    onCopyPreviousAdjustment && <AppIconButton key={'copy'} Icon={PlaylistAdd} tooltip="Copy Previous Adjustments" onClick={onCopyPreviousAdjustment} />
  ];

  const gridColumns = [
    {
      title: 'Rep',
      field: 'rep',
      noSort: true,
      styles: {
        width: '10%',
      },
      dataType: 'component',
      component: AssociateCell(associate)
    },
    {
      title: 'Related Shipment(s)',
      field: 'relatedBolNumber',
      styles: {
        width: '8%',
      },
      dataType: 'string'
    },
    {
      title: 'Amount',
      field: '',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: '8%',
      },
      dataType: 'component',
      component: AdjustmentTotalCell(classes)
    },
    {
      title: 'Reason for Change',
      field: 'reason',
      styles: {
        width: '23%',
      },
      dataType: 'string'
    },
    {
      title: 'Notes',
      field: 'note',
      styles: {
        width: '35%',
      },
      dataType: 'string'
    },
    {
      title: 'Rep Paid',
      field: 'repPaid',
      styles: {
        textAlign: 'center',
        width: '5%',
      },
      dataType: 'component',
      component: RepPaidCell(classes)
    }
  ];

  if (onEditClick && onDeleteClick) {
    gridColumns.push({
      title: '',
      field: '',
      dataType: 'component',
      component: ControlsCell(onEditClick, onDeleteClick),
      styles: {
        width: '8%',
      }
    });
  }

  return (
    <AppCard title="Adjustments" action={cardActions}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <AppDenseGrid
            count={adjustmentCount}
            data={adjustments}
            columns={gridColumns}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={onPageChange}
            onChangeRowsPerPage={onLimitChange}
            onSort={onSortChange}
          />
        </Grid>
      </Grid>
    </AppCard>)
};

export default AdjustmentListingCard;