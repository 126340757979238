import ActionTypes from '../../ActionTypes';

const shipments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Dashboard.Disposed:
      return [];
    case ActionTypes.Dashboard.ListingLoaded:
      return action.shipments;
    default:
      return state;
  }
};

export default shipments;