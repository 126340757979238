import React from 'react';
import { Grid } from '@material-ui/core';

import AppCard from '../../../../core/components/cards/AppCard';
import AppMonthYear from '../../../../core/components/inputs/AppDatePicker/AppMonthYear';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';

const SearchCard = (props) => {
  const {
    onMonthYearChange,
    onPayPeriodChange,
    monthYearSelectedDate,
    payPeriodOptions,
    payPeriod
  } = props;

  return (
    <AppCard>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <label htmlFor="monthYear">Month/Year:</label>
          <AppMonthYear
            id="monthYear"
            openTo="year"
            views={["year", "month"]}
            inputVariant="outlined"
            value={monthYearSelectedDate}
            onChange={onMonthYearChange}
          />
        </Grid>
        <Grid item xs={6}>
          <AppDropdown
            label={'Pay Period'}
            id={'payPeriod'}
            field={'title'}
            fieldIndex={'value'}
            options={payPeriodOptions}
            value={payPeriod || null}
            onChange={onPayPeriodChange} />
        </Grid>
      </Grid>
    </AppCard>
  )
};

export default SearchCard;