import ActionTypes from '../../ActionTypes';

const shipmentPackageTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.ShipmentPackageTypesLoaded:
      return action.shipmentPackageTypes;
    default:
      return state;
  }
};

export default shipmentPackageTypes;