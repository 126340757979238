import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
    header: {
        marginBottom: '1rem',
    },
    buttonGroup: {
        '& > *': {
            marginRight: '1rem',
        },
        '& > *:last-child': {
            marginRight: 0,
        }
    },
    appButton: {
        marginBottom: '1rem',
    }
});