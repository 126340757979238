import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ButtonGroup, Button} from '@material-ui/core';
import combineClassNames from "../../../../core/utils/combineClassNames";
import {useHistory, useLocation} from "react-router-dom";

/**
 * Provides the navigation bar for the LoadBoard page.
 */
const useStyles = makeStyles((theme) => ({
      root: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
      },
      buttonLink: {
          textDecoration: 'none',
          color: theme.palette.primary.main,
          '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.tgfGrey.light,
          },
      },
      active: {
          textDecoration: 'none',
          color: theme.palette.white,
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
              color: theme.palette.primary.main,
          }
      },
      badge: {
          borderRadius: 5,
          marginLeft: 5,
          padding: '0 3px',
          color: theme.palette.white,
        backgroundColor: theme.palette.primary.main
      },
      activeBadge: {
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main
      },
      default: {
          backgroundColor: theme.palette.default.main,
      },
      warning: {
          backgroundColor: theme.palette.warning.main,
      },
      error: {
          backgroundColor: theme.palette.error.main,
      },
      primary: {
        backgroundColor: theme.palette.primary.main,
      }
  }
));

const OpenLoadBoardListingFilterBar = (props) => {

    let history = useHistory();
    let location = useLocation();
    let currentPath = location.pathname;
    const classes = useStyles();

    const buttonList = [
        {
            title: 'Shipments',
            link: 'SHIPMENT',
            value: `${props.shipmentCount}`,
            style: 'primary',
            visible: true
        },
        {
            title: 'DAT Postings',
            link: 'DAT',
            value: `${props.datPostingCount}`,
            style: 'primary',
            visible: true
        },
        {
            title: 'ITS Postings',
            link: 'ITS',
            value: `${props.truckstopPostingCount}`,
            style: 'primary',
            visible: true
        },
    ];

    const [activeTab, setActiveTab] = useState(null);

    // set active tab based on URL.
    useEffect(() => {
        if (currentPath === "/open-loads-board/shipments") {
            setActiveTab("SHIPMENT");
        }
        else if (currentPath === "/open-loads-board/dat-postings") {
            setActiveTab("DAT");
        }
        else if (currentPath === "/open-loads-board/its-postings") {
            setActiveTab("ITS");
        }
    }, [currentPath]);

    // if button clicked, push correlating url.
    const onClickHandler = (linkTo) => {
        if (linkTo === "SHIPMENT") {
            history.push("/open-loads-board/shipments");
        }
        else if (linkTo === "DAT") {
            history.push("/open-loads-board/dat-postings");
        }
        else if(linkTo === "ITS") {
            history.push("/open-loads-board/its-postings");
        }
    }

    return (
        <ButtonGroup style={{padding: '20px'}} role="group" aria-label="CRM toolbar button group.">
            {
                buttonList.map((item, key) => {
                    const activeClassName = item.link === activeTab ? classes.active : classes.buttonLink;
                    const activeBadge = item.link === activeTab ? classes.activeBadge : classes.badge;

                    return (
                        <Button
                            className={activeClassName}
                            key={key}
                            disabled={false}
                            onClick={() => onClickHandler(item.link)}
                        >
                        {item.title}<span className={combineClassNames(classes.badge, activeBadge)}>{item.value}</span>

                    </Button>);
                })
            }
        </ButtonGroup>
    );
}

export default OpenLoadBoardListingFilterBar;
