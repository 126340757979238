import {useHistory, useLocation} from "react-router-dom";
import * as React from "react";
import "./TgfHorizontalNavBar.scss";
import PropTypes from "prop-types";

const TgfHorizontalNavBar = ({buttonList, onClickHandler}) => {
    const location = useLocation();
    const history = useHistory();

    const checkIfActive = (path) => {
        return location.pathname === path ? "tgf-horizontal-nav-bar_link-btn-active" : null;
    };

    const redirect = (e) => {
        history.push(e.target.value.link);
    }

    return (
        <div className={"tgf-horizontal-nav-bar_container"}>
            <div className={"tgf-horizontal-nav-bar"}>
                {
                    buttonList.map((btn, idx) => {
                        const btnIsActive = checkIfActive(btn.link);

                        return (
                            <span
                                className={`tgf-horizontal-nav-bar_link-btn ${btnIsActive}`}
                                key={idx}
                                onClick={onClickHandler ? () => onClickHandler({target: {value: btn}}) : () => redirect({target: {value: btn}})}
                            >
                                {btn.display}
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

TgfHorizontalNavBar.propTypes = {
    /* Array of button objects => [{display: "btn1", link: "/some/url/path", filter: "1"}, {...}].
    The entire btn object will get passed back in the event, so you can add additional values if needed IE
    A filter if you need to handle query params (if using an onClickHandler) */
    buttonList: PropTypes.arrayOf(PropTypes.object).isRequired,
    // (Optional) Handler function that takes an event, performs additional actions, and then redirects.
    // If one is not supplied, the links will simply redirect.
    onClickHandler: PropTypes.func,
}

export default TgfHorizontalNavBar;