import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const saveAnnouncementNote = async (shell, announcementNote) => {

  const updatedAnnouncementNote = await shell.gateway.updateAnnouncementNote(announcementNote);

  return {
    type: ActionTypes.Persona.SavedAnnouncementNote,
    announcementNote: updatedAnnouncementNote
  };

};

export default ActionBuilder
  .for(saveAnnouncementNote)
  .build();