import * as React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const LinkCell = (cellProps) => {
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);

    return (
        <td>
            <Link
                className={"tgf-grid-link-cell"}
                to={cellProps.url(cellProps.dataItem)}
                target={openLinksInNewTab ? "_blank" : ""}
            >{cellProps.dataItem[cellProps.field]}</Link>
        </td>
    )
}

export default LinkCell;