import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from "../../ActionTypes";

const loadCarrierDocumentTypes = async (shell) => {

  const carrierDocumentTypes = await shell.gateway.getCarrierDocumentTypes();

  return {
    type: ActionTypes.Support.CarrierDocumentTypes,
    carrierDocumentTypes
  };
};

export default ActionBuilder
  .for(loadCarrierDocumentTypes)
  .build();