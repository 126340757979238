import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadAssociateMonthlyCommissionBreakdown = async (shell, associateId, startDate, endDate) => {

  const associateCommissionBreakdown = await shell.gateway.getMonthlyCommissionBreakdown(
    associateId,
    startDate,
    endDate
  );

  return {
    type: ActionTypes.Reporting.MonthlyGrossMargin.MonthlyCommissionBreakdownLoaded,
    associateCommissionBreakdown
  }
}

export default ActionBuilder
  .for(loadAssociateMonthlyCommissionBreakdown)
  .build();