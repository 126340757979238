import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Paper, Tooltip, Typography, List, ListItem, Grid } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import ComponentBuilder from '../../../../core/ComponentBuilder';
import AppCard from '../../../../core/components/cards/AppCard';
import AppDrawer from '../../../../core/components/AppDrawer';
import AppSearch from '../../../../core/components/inputs/AppSearch';

const CloseSelectorTooltip = 'Close select window.';

const useStyles = makeStyles((theme) => ({
  search: {
    ...theme.tgfStyles.search,
    width: '100%'
  },
  paper: {
    height: '80vh',
    overflowY: 'scroll',
    marginTop: 10
  },
  listContainer: {
    width: 350,
    maxWidth: 350
  }
}))

const FactoringCompanySelector = (props) => {
  const classes = useStyles();
  const {
    isOpen=false,
    onClose,
    onChange,
    factoringCompanies = []
  } = props;

  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearchTermChange = (e) =>
    setSearchTerm(e.target.value);

  const handleCompanyClick = (company) => () => {
    setSearchTerm('');
    onChange(company);
  };

  const handleCloseClick = () => {
    setSearchTerm('');
    onClose();
  };

  const filteredCompanies = factoringCompanies
    .filter(fc => searchTerm === '' ||
      fc.companyName.toLowerCase().startsWith(searchTerm.toLowerCase()));

  return (
    <AppDrawer
      anchor={'right'}
      onClose={handleCloseClick}
      open={isOpen}>
      <AppCard title="Select Factoring Company" action={<IconButton
        className={classes.closeButton}
        color={'primary'}
        aria-label={CloseSelectorTooltip}
        onClick={handleCloseClick}
      >
        <Tooltip title={CloseSelectorTooltip}>
          <CloseIcon />
        </Tooltip>
      </IconButton>}>
        <AppSearch onChange={handleSearchTermChange} />
        <Paper className={classes.paper}>
          <List className={classes.listContainer}>
            {
              filteredCompanies.map((fc, i) =>
                <FactoringCompany key={i} company={fc} onClick={handleCompanyClick(fc)} />)
            }
          </List>
        </Paper>
      </AppCard>
    </AppDrawer>
  );
};

const FactoringCompany = (props) => {
  const {
    company,
    onClick
  } = props;

  return (
    <ListItem onClick={onClick} button divider>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>
            {company.companyName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
            {company.companyAddress1}
        </Grid>
        {
          company.companyAddress2 && <Grid item xs={12}>
            {company.companyAddress2}
          </Grid>
        }
        <Grid item xs={12}>
            {company.companyCity}, {company.companyStateProvince}, {company.companyPostalCode}
        </Grid>
      </Grid>
    </ListItem>
  );
};

const styles = (theme) => {
  return {
    closeButton: {
      padding: 0
    }
  };
};

export default ComponentBuilder
  .wrap(FactoringCompanySelector)
  .withStyles(styles)
  .build();