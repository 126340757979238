import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';

import MenuSingle from '../AppMenus/SideMenuSingle';
import MenuMulti from '../AppMenus/SideMenuMulti';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
}));

const MenuItems = ({ currentLocation, menuList, open }) => {
  const classes = useStyles();

  return (
    <List
      component="nav"
      className={classes.root}
    >
      {
        menuList.map((menuItem, key) => {
          let isVisible = true
          if (menuItem.isVisible !== undefined) {
            isVisible = menuItem.isVisible
          }

          return (
            menuItem.subMenus ?
              <MenuMulti key={key} open={open} data={menuItem} currentLocation={currentLocation} /> : isVisible &&
              <MenuSingle key={key} open={open} data={menuItem} currentLocation={currentLocation === menuItem.key} />
          )
        })}
    </List>
  );
}

export default MenuItems;
