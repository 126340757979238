import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipmentTruckLoadConfirmation = async (shell, bolNumber) => {

  const truckloadValueConfirmation = await shell.gateway.getShipmentTruckLoadConfirmation(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentTruckLoadConfirmationLoaded,
    truckloadValueConfirmation
  };
};

export default ActionBuilder
  .for(loadShipmentTruckLoadConfirmation)
  .build();
