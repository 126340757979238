import * as yup from "yup";

export const MultiTransValidationSchema = yup.object().shape({
    carrierCost: yup.number().test({
        test(value) {
            if (this.parent.involvedCostOption.id === 1 || this.parent.involvedCostOption.id === 3) {
                if (value === null) {
                    return this.createError({message: "Carrier Cost Is Required"})
                }
            }
            return true
        }
    }).nullable(),
    customerCost: yup.number().test({
        test(value) {
            if (this.parent.involvedCostOption.id === 1 || this.parent.involvedCostOption.id === 2) {
                if (value === null) {
                    return this.createError({message: "Customer Cost Is Required"})
                }
            }
            return true
        }
    }).nullable(),
    reason: yup.string().required("Reason is required"),
    shipmentStatusOption: yup.object().test({
        test(value) {
            if (value === null) {
                return this.createError({message: "Required"})
            }
            return true
        }
    }).nullable()
});
