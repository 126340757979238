import React from 'react';
import { Grid } from '@material-ui/core';
import AppCard from "../../../core/components/cards/AppCard/AppMuiCard";
import AppDenseGrid from "../../../core/components/AppDenseGrid/AppDenseGrid";
import AppAlertStatic from "../../../core/components/AppAlertStatic";

const AssociateCell = ({ rowData: disablementHistory }) => {
  return disablementHistory.associate.id === 10000 ? // system account ID
    null : disablementHistory.associate.fullName;
};

const CustomerDisableHistory = (props) => {

  const {
    companyDisabled,
    disabledHistory,
    disabledHistoryCount,
    onSortChange,
    offset,
    limit,
    sort
  } = props;

  const gridColumns = [
    {
      title: 'Date Created',
      field: 'createDate',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: '130px',
      },
      dataType: 'date-time'
    },
    {
      title: 'Associate',
      field: 'associate.systemId',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: '130px',
      },
      dataType: 'component',
      component: AssociateCell
    },
    {
      title: 'Reason',
      field: 'reasonText',
      noSort: true,
      styles: {
        textAlign: 'center',
      },
      dataType: 'text'
    },
    {
      title: 'Change',
      field: 'classificationCode',
      noSort: true,
      styles: {
        textAlign: 'center',
        width: '75px',
      },
      dataType: 'text'
    }
  ];

  return (
    <AppCard title="Customer Disable History">
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
        {
          companyDisabled &&
          <Grid item xs={12}>
            <AppAlertStatic color="error" icon={false}>
              Customer is disabled.  See reasons below for details.
            </AppAlertStatic>
          </Grid>
        }
        <Grid item>
          <AppDenseGrid
            count={disabledHistoryCount}
            data={disabledHistory}
            columns={gridColumns}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            rowsPerPage={limit}
            page={offset / limit}
            onSort={onSortChange}
          />
        </Grid>
      </Grid>
    </AppCard>
  )
};

export default CustomerDisableHistory;