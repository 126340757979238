const compare = require("fast-levenshtein");

const computeDistanceFactoringMatch = (inputValue, targetValue) => {
  if(!inputValue || !targetValue) return "#ffffe4"
  // convert, trim, and lowercase inputs
  inputValue = inputValue.toString();
  targetValue = targetValue.toString();
  inputValue = inputValue.trim().toLowerCase();
  targetValue = targetValue.trim().toLowerCase();
  const distance = compare.get(inputValue, targetValue);

  if (distance <= 2) return "#ecffe4";
  if (distance > 2) return '#ffffe4'
}

const computeDistanceCarrierMatch = (inputValue, targetValue) => {
  if(!inputValue || !targetValue) return '#ffe4e4';
  // convert, trim, and lowercase inputs
  inputValue = inputValue.toString();
  targetValue = targetValue.toString();
  inputValue = inputValue.trim().toLowerCase();
  targetValue = targetValue.trim().toLowerCase();
  const distance = compare.get(inputValue, targetValue);

  if (distance <= 2) return "#ecffe4";
  if (distance > 2) return '#ffe4e4';
}

const isExactMatch = (inputValue, targetValue) => {
  if(inputValue === targetValue) return '#ecffe4';
  // else
  return '#ffe4e4';
}


const computeAddress1 = (inputValue, targetValue) => {
  if(!inputValue || inputValue?.length < 8) {
    return '#ffe4e4';
  }
  //else
  return '#ecffe4';
}

const computeCity = (inputValue, targetValue) => {
  if(!inputValue || /[0-9]/.test(inputValue) === true) {
    return '#ffe4e4';
  }
  //else
  return '#ecffe4';
}

const computeState = (inputValue, targetValue) => {
  if(!inputValue || /[0-9]/.test(inputValue) === true || inputValue?.length !== 2) {
    return '#ffe4e4';
  }
  //else
  return '#ecffe4'
}

const computeZip = (inputValue, targetValue) => {
  if(!inputValue || inputValue < 5) {
    return '#ffe4e4'
  }
  //else
  return '#ecffe4'
}

const computeColorCase = (rowData, targetValue) => {

  // Carrier IS remit to
  if(rowData['inputCarrierName'] === rowData['inputRemitName']) {
    if(targetValue === 'inputCarrierName') {
      return computeDistanceCarrierMatch(rowData['inputCarrierName'], rowData['targetCarrierName']);
    }
    if(targetValue === 'inputRemitName') {
      return computeDistanceCarrierMatch(rowData['inputRemitName'], rowData['targetRemitName']);
    }
    if(targetValue === 'inputAddress') {
      return computeAddress1(rowData['inputAddress'], rowData['targetAddress']);
    }
    if(targetValue === 'inputCity') {
      return computeCity(rowData['inputCity'], rowData['targetCity'])
    }
    if(targetValue === 'inputState') {
      return computeState(rowData['inputState'], rowData['targetState'])
    }
    if(targetValue === 'inputZip') {
      return computeZip(rowData['inputZip'], rowData['targetZip'])
    }
    if(targetValue === 'inputAmount') {
      return isExactMatch(rowData['inputAmount'], rowData['targetAmount'])
    }
  }

  // Factoring Co IS remit to
  if(rowData['inputCarrierName'] !== rowData['inputRemitName']) {
    if(targetValue === 'targetCarrierName') {
      return computeDistanceFactoringMatch(rowData['inputCarrierName'], rowData['targetCarrierName']);
    }
    if(targetValue === 'targetRemitName') {
      return computeDistanceFactoringMatch(rowData['inputRemitName'], rowData['targetRemitName']);
    }
    if(targetValue === 'targetAddress') {
      return computeDistanceFactoringMatch(rowData['inputAddress'], rowData['targetAddress']);
    }
    if(targetValue === 'targetCity') {
      return computeDistanceFactoringMatch(rowData['inputCity'], rowData['targetCity'])
    }
    if(targetValue === 'targetState') {
      return isExactMatch(rowData['inputState'], rowData['targetState'])
    }
    if(targetValue === 'targetZip') {
      return isExactMatch(rowData['inputZip'], rowData['targetZip'])
    }
    if(targetValue === 'targetAmount') {
      return isExactMatch(rowData['inputAmount'], rowData['targetAmount'])
    }
  }
  return null
}

/**
 * function to compute the color of an MUI table column using the levenshtien algorithm.
 * @param {string} targetValue - set to be same as field string
 * @param {object} [record] - (optional) for use with tables that are not MUI tables
 */
const computeColor = (targetValue, record) => {
  if(record) {
    return computeColorCase(record, targetValue)
  }
  // else
  return function(index, row) {
    return computeColorCase(row, targetValue)
  }
}

export default computeColor