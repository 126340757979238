import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipment = async (shell, bolNumber) => {

  const shipment = await shell.gateway.getShipment(bolNumber);

  const companies = [
    shipment.customerId,
    shipment.shipperId,
    shipment.consigneeId
  ];

  const companyPromises = companies
    .map(companyId => companyId ? shell.gateway.getCompany(companyId) : null);

  const companyContactPromises = companies
    .map(companyId => companyId ? shell.gateway.getCompanyContacts(companyId) : []);

  const tlValueConfirmPromise = shell.gateway.getShipmentTruckLoadConfirmation(bolNumber);

  const loadContentPromise = shell.gateway.getAllShipmentContents(bolNumber);

  const carrierContactPromise = shell.gateway.getCarrierContact(bolNumber);

  const allPromises = companyPromises
    .concat(companyContactPromises)
    .concat([tlValueConfirmPromise, loadContentPromise, carrierContactPromise]);

  const [
    customer,
    shipper,
    consignee,
    customerContacts,
    shipperContacts,
    consigneeContacts,
    truckloadValueConfirmation,
    loadContents,
    carrierContact
  ] = await Promise.all(allPromises);

  const action = {
    type: ActionTypes.Shipment.CreateDocs.ShipmentLoaded,
    shipment,
    customer,
    shipper,
    consignee,
    customerContacts,
    shipperContacts,
    consigneeContacts,
    truckloadValueConfirmation,
    loadContents,
    carrierContact
  };

  if (shipment.isMultipleStop) {

    const multiStopLocations = await shell.gateway.getShipmentMultipleStop(bolNumber);

    const multiStopContactPromises = multiStopLocations
      .map(location => shell.gateway.getCompanyContacts(location.companyId));

    const multiStopCompanyContacts = await Promise.all(multiStopContactPromises);

    action.multiStopLocations = multiStopLocations;
    action.multiStopCompanyContacts = multiStopCompanyContacts;

  }

  return action;
};

export default ActionBuilder
  .for(loadShipment)
  .build();