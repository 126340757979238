/**
 * Disposes of data used by modification.
 */
import ActionTypes from '../../../ActionTypes';

const dispose = (context) => {

    return {
        type: ActionTypes.AddressBook.Modification.Disposed
    };

};

export default dispose;