import React, {forwardRef, useState} from "react";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Edit} from "@material-ui/icons";
import CollectorsAssignmentDialog from "./CollectorsAssignmentDialog";

const editCell = (props, onEditButtonClick) => {
    return <td>
        <AppIconButton
            Icon={Edit}
            tooltip={`Edit Date`}
            onClick={() => onEditButtonClick(props.dataItem)}/>
    </td>
}

const CollectorsAssignmentGrid = forwardRef((props, ref) => {

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const personalDispatch = useDispatch();

    const load = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadCollectorsAssigment'));

            const result = await window.shell.gateway.getCollectorsAssignmentListing(odata);
            return result;

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadCollectorsAssigment'));
        }
    }

    const onEditClick = (data) => {
        setSelectedAgent(data);
        setEditDialogOpen(true);
    };

    const onCloseDialog = () => {
        setEditDialogOpen(false);
        setSelectedAgent(null);
        props.reloadData();
    }

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};
        if(props.filterAssociate) filter.agentId = props.filterAssociate.id;
        filter.agentIsActive = !props.searchInactiveOnly;
        odata.filter.and = filter;
        return odata;
    }

    const gridColumns = [
        {
            title: 'First Name',
            field: 'agentFirstName',
            type: 'text',
        },
        {
            title: 'Last Name',
            field: 'agentLastName',
            type: 'text',
        },
        {
            title: 'System ID',
            field: 'agentCrmInitials',
            type: 'text',
        },
        {
            title: 'EXT',
            field: 'agentEXT',
            type: 'text',
        },
        {
            title: 'Email',
            field: 'agentEmail',
            type: 'text',
        },
        {
            title: 'Assigned Collector',
            field: 'assignedCollectorFullName',
            type: 'custom',
            cell: (props) => <td>{props.dataItem?.assignedCollectorId ? props.dataItem.assignedCollectorFullName : "Unassigned At This Time"}</td>
        },
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) => editCell(props, onEditClick),
            width: 40,
            sortable: false
        },
    ];


    return (
        <Grid container spacing={2}>
            {editDialogOpen &&
                <CollectorsAssignmentDialog
                    onCloseDialog={onCloseDialog}
                    data={selectedAgent}
                />
            }
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"collectors-assignment_shipment-listing-grid"}
                    ref={ref}
                    onLoadDataPage={load}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    gridColumns={gridColumns}
                    sort={[{field: "agentFirstName", dir: "asc"}]}
                    pageable={{
                        info: true,
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    )
})

export default CollectorsAssignmentGrid