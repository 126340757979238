import ActionTypes from '../../ActionTypes';

const billingCompanyCategoryType = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.Support.CompanyCategoryTypesLoaded:
            return action.billingCompanyCategoryType;
        default:
            return state;
    }
};

export default billingCompanyCategoryType;