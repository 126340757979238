import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const doNotPayCarrier = async (shell, options) => {

  const { doNotPayCarrier, count } = await shell.gateway.getDoNotPayCarrier(options);

  return {
    type: ActionTypes.Automation.DoNotPayCarrier.Loaded,
    doNotPayCarrier,
    count
  };
};

export default ActionBuilder
  .for(doNotPayCarrier)
  .build();
