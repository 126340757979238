import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TablePagination
} from '@material-ui/core';

import AppPagination from './AppPagination';

const AppTablePagination = (props) => {
  const {
    count,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    page
  } = props;

  return (<Table>
    <TableBody>
      <TableRow>
        <TablePagination
          count={count}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page}
          ActionsComponent={() => <AppPagination
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
          />}
        />
      </TableRow>
    </TableBody>
  </Table>
  )
};

export default AppTablePagination;