import React from 'react';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppDialog from "../../../core/components/AppDialog/AppMuiDialog";
import AppValidationFailure from "../../../core/components/AppValidationFailure";
import FormFactor from "../../../core/FormFactor/FormFactor";
import * as Yup from "yup";

const DisableReasonSchema = Yup.object().shape({
  reasonText: Yup.string().required('Reason is required.')
});

const DisableCustomerReason = (props) => {

  const {
    onClose,
    onConfirmClick
  } = props

  const initialValues = {
    reasonText: '',
    onClose
  }

  const handleReasonChange = (values, formFactor) => {
    onConfirmClick(values.reasonText.trim());
  };

  return (
    <FormFactor
      initialValues={initialValues}
      schema={DisableReasonSchema}
      onSubmit={handleReasonChange}>
      {renderDialogForm}
    </FormFactor>
  )
};

const renderDialogForm = ({values, errors, isValid, isDirty, setFieldValue, submitForm}) => {

  const disableReasonActions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleReasonNoteChange = (e) =>
    setFieldValue('reasonText', e.target.value);

  return (
    <AppDialog
      title={'Disable Customer Reason'}
      open={true}
      onClose={values.onClose}
      width={'sm'}
      actionButtons={disableReasonActions}>
      <AppInput
        id={'note'}
        rows={8}
        cols={45}
        multiline={true}
        maxLength={10000}
        onChange={handleReasonNoteChange}/>
      <AppValidationFailure message={errors.reasonText}/>
    </AppDialog>
  );
};

export default DisableCustomerReason;