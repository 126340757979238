import ActionTypes from '../../../ActionTypes';

const associateNoteTypes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Admin.ManageUsers.Disposed:
      return []
    case ActionTypes.Admin.ManageUsers.AssociateNoteTypesLoaded:
      return action.associateNoteTypes;
    default:
      return state;
  }
};

export default associateNoteTypes;