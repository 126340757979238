import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCustomerPaymentShipments = async (shell, bolNumbers) => {

  if (bolNumbers.length === 0) {
    return {
      type: ActionTypes.Reporting.CustomerPaymentShipmentsLoaded,
      shipments: []
    };
  }

  const options = {
    filter: { bolNumber: { $in: bolNumbers } },
    offset: 0,
    limit: 100,
    sort: [["bolNumber", "desc"]]
  };

  const { shipments } = await shell.gateway.getAllShipments(options);

  return {
    type: ActionTypes.Reporting.CustomerPaymentShipmentsLoaded,
    shipments
  };
};

export default ActionBuilder
  .for(loadCustomerPaymentShipments)
  .build();