import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import Business from '@tgf-crm/business';

const searchCustomerShipments = async (shell, searchId, customerId = null, fromDate = null, toDate = null, bolNumber = null, filterToAging = false, offset, limit, sort) => {

  const {
    shipments, count
  } = await shell.gateway.getCustomerShipmentListings(customerId, fromDate, toDate, bolNumber, filterToAging, offset, limit, sort);

  const processedShipments = await calculateShipmentFinancials(shipments, shell.gateway);

  return {
    type: ActionTypes.Shipment.Customer.SearchedCustomerShipments,
    searchId,
    customerId,
    fromDate,
    toDate,
    bolNumber,
    filterToAging,
    shipments: processedShipments,
    count
  };

};


const distinctPayPeriodStartDates = (actualDeliveryDates) => {

  // Build pay periods for actual delivery dates.
  const payPeriods = actualDeliveryDates
    .filter(x => x)
    .map(dd => Business.Associate.PayPeriod.buildFromDate(dd));

  // Filter down to just start dates of periods.
  const payPeriodStartDates = payPeriods.map(pp => pp.startDate.toMoment().utc().format('YYYY-MM-DD'));

  return Array.from(new Set(payPeriodStartDates))
};

const createInvoiceLike = (shipmentRecord) => ({
  associateId: shipmentRecord.companyAssociateId,
  actualDeliveryDate: shipmentRecord.invoiceActualDeliveryDate,
  adjustedCustomerCost: shipmentRecord.customerCost,
  adjustedCarrierCost: shipmentRecord.carrierCost
});

const calculateShipmentFinancials = async (shipments, gateway) => {

  const associateGroups = shipments.reduce((result, shipmentRecord) => {
    (result[shipmentRecord.companyAssociateId] = result[shipmentRecord.companyAssociateId] || [])
      .push(shipmentRecord.invoiceActualDeliveryDate);
    return result;
  }, {});

  Object.entries(associateGroups).forEach(([associateId, actualDeliveryDates]) => {
    associateGroups[associateId] = distinctPayPeriodStartDates(actualDeliveryDates);
  });

  const commRatePairPromises = Object.entries(associateGroups).map(([associateId, payPeriodStartDates]) => {
    const commissionRatePromises = payPeriodStartDates.map(pp => gateway.getCommissionRate(associateId, pp));
    return Promise.all(commissionRatePromises)
      .then(commissionRates => [associateId, commissionRates]);
  });

  const commRatePairs = await Promise.all(commRatePairPromises);
  const associateCommRateGroups = Object.fromEntries(commRatePairs);

  const calculatedShipments = shipments.map(shipment => {

    if (shipment.invoiceActualDeliveryDate) {

      const invoiceLike = createInvoiceLike(shipment);
      const commissionRates = associateCommRateGroups[invoiceLike.associateId].filter(Boolean);

      const payPeriod = Business.Associate.PayPeriod.buildFromDate(shipment.invoiceActualDeliveryDate);
      const commissionRate = commissionRates.find(cr => payPeriod.includes(cr.commissionDate));

      if (commissionRate) {
        const [ calculator ] = Business.Associate.CommissionCalculator.compose([invoiceLike], commissionRates);
        shipment.invoiceEarnedPayout = calculator.calculate(invoiceLike);
        shipment.totalMargin = Business.Shipment.ShipmentMath.grossMargin(invoiceLike);
        shipment.invoiceTgfFee = shipment.totalMargin - shipment.invoiceEarnedPayout;
      }
    }
    return shipment;

  });

  return calculatedShipments;

};

export default ActionBuilder
  .for(searchCustomerShipments)
  .build();