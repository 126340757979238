import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const DenseTable = ({ headers, data, size = "small", ...otherProps }) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size={size}>
                <TableHead>
                    <TableRow>
                        {
                            headers.map((item, key) =>
                                <TableCell key={key}>{item.title}</TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, key) =>
                        <TableRow key={key}>
                            {
                                Object.keys(row).map((item, ndx) =>
                                    <TableCell component="th" scope="row" key={ndx}>
                                        {row[item]}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                   )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DenseTable;