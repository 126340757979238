import ActionTypes from '../../ActionTypes';

const shipmentEquipmentTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.ShipmentEquipmentTypesLoaded:
      return action.shipmentEquipmentTypes;
    default:
      return state;
  }
};

export default shipmentEquipmentTypes;