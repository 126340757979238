import React from "react";
import ComponentBuilder from "../../../../core/ComponentBuilder";
import FullWidthLayout from "../../../../core/layouts/FullWidthLayout";
import LeftNav from "../../LeftNav";
import AppDenseGrid from "../../../../core/components/AppDenseGrid/AppDenseGrid";
import { Grid } from "@material-ui/core";
import AppDatePicker from "../../../../core/components/inputs/AppDatePicker";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import { CloseSharp, Visibility } from "@material-ui/icons";
import ClosedDetailsDialog from "./includes/ClosedDetailsDialog";
import ListingFilterBar from "../Listing/ListingFilterBar";
import CrmAssociateComboBoxAcesOnly from "../../../../crm/components/CrmAssociateComboBoxAcesOnly";

const LoadProcessName = "Admin.ClosedReport.Load";

const ClosedReportPage = (props) => {
  const {
    records,
    closedRecordCount,
    qaReviewRecordCount,
    qaFailedRecordCount,
    loadRecords,
    listAutomationDocuments,
    fetchHardFailReasons,
    checkIfShipmentExistsInCrm,
  } = props;

  const CloseCell = (partSelector) => {
    return ({ rowData }) => {
      const value = partSelector(rowData);
      return (
        <AppIconButton
          Icon={Visibility}
          /*className={classes.accept}*/
          onClick={onCloseClick}
          value={value}
        />
      );
    };
  };

  const onCloseClick = (e) => {
    setCurrentInteractiveRecord(JSON.parse(e.currentTarget.value));
  };

  const gridColumns = [
    {
      field: "bolNumber",
      title: "Ship #",
      styles: {
        width: "10%",
      },
      dataType: "component",
      component: AppDenseGrid.Cells.LinkCell((record) => [
        `/shipment/${record.bolNumber}/details`,
        record.bolNumber,
      ]),
    },
    {
      field: "associateInitials",
      title: "Closed By",
      styles: {
        width: "10%",
      },
      dataType: "string",
    },
    {
      field: "dateFailed",
      title: "Closed Date",
      styles: {
        width: "10%",
      },
      dataType: "date-time",
    },
    {
      field: "dispositionStatus",
      title: "Disposition Status",
      styles: {
        width: "10%",
      },
      dataType: "string",
    },
    {
      title: "Notes",
      field: "notes",
      styles: {
        width: "50%",
      },
      dataType: "string",
    },
    {
      field: "",
      title: "View",
      styles: {
        width: "10%",
      },
      dataType: "component",
      component: CloseCell((record) => {
        return JSON.stringify(record);
      }, null),
    },
  ];

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([["dateFailed", "desc"]]);

  const [closedStartDate, setClosedStartDate] = React.useState(null);
  const [closedEndDate, setClosedEndDate] = React.useState(null);
  const [byAgentId, setByAgentId] = React.useState(null);
  const [currentInteractiveRecord, setCurrentInteractiveRecord] =
    React.useState(null);

  React.useEffect(() => {
    loadRecords({
      limit: limit,
      offset: offset,
      sort: sort,
      startDate: closedStartDate,
      endDate: closedEndDate,
      byAgentId,
    });
  }, [
    offset,
    limit,
    sort,
    closedStartDate,
    closedEndDate,
    byAgentId,
    loadRecords,
  ]);

  const handleChangePage = (e, page) => {
    setOffset(page * limit);
  };
  const handleSortChange = (column) => {
    const [existingColumn, existingOrder] = sort[0];

    const order =
      column === existingColumn
        ? existingOrder === "asc"
          ? "desc"
          : "asc"
        : "asc";

    setSort([[column, order]]);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleClosedStartDateChange = (e) => {
    setClosedStartDate(
      e && e.toISOString() ? e.toISOString().substring(0, 10) : ""
    );
  };

  const handleClosedEndDateChange = (e) => {
    setClosedEndDate(
      e && e.toISOString() ? e.toISOString().substring(0, 10) : ""
    );
  };

  const onSelectAssociate = (e) => {
    setByAgentId(e ? e.id : null);
  };

  const onClosedDialoglClick = () => {
    setCurrentInteractiveRecord(null);
  };

  return (
    <React.Fragment>
      <FullWidthLayout title={""} SideNav={LeftNav}>
        <ListingFilterBar
          index={3}
          qaReviewRecordCount={qaReviewRecordCount}
          qaFailedRecordCount={qaFailedRecordCount}
        ></ListingFilterBar>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={2}>
                From:
              </Grid>
              <Grid item xs={12} md={10}>
                <AppDatePicker
                  value={closedStartDate}
                  onChange={handleClosedStartDateChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={2}>
                To:
              </Grid>
              <Grid item xs={12} md={10}>
                <AppDatePicker
                  value={closedEndDate}
                  onChange={handleClosedEndDateChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={2}>
                Closed By:
              </Grid>
              <Grid item xs={12} md={10}>
                <CrmAssociateComboBoxAcesOnly
                  byAgentId={byAgentId}
                  placeholder={'Search agents...'}
                  onSelectAssociate={onSelectAssociate}
                  style={{minWidth: '250px'}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppDenseGrid
                columns={gridColumns}
                data={records}
                count={closedRecordCount}
                orderBy={sort[0][0]}
                order={sort[0][1]}
                rowsPerPage={limit}
                page={offset / limit}
                onSort={handleSortChange}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </FullWidthLayout>
      {currentInteractiveRecord && (
        <ClosedDetailsDialog
          onClosedClick={onClosedDialoglClick}
          listAutomationDocuments={listAutomationDocuments}
          activeRecord={currentInteractiveRecord}
          fetchHardFailReasons={fetchHardFailReasons}
          checkIfShipmentExistsInCrm={checkIfShipmentExistsInCrm}
        />
      )}
    </React.Fragment>
  );
};
export default ComponentBuilder.wrap(ClosedReportPage)
  .stateToProps((state) => ({
    records: state.automation.closed.closed,
    closedRecordCount: state.automation.closed.closedCount,
    qaReviewRecordCount: state.automation.qaReview.qaReviewCount,
    qaFailedRecordCount: state.automation.qaFailed.qaFailedCount,
  }))
  .dispatchToProps((shell, dispatch) => {
    return {
      async loadRecords(options) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.automation.closed.loadClosed(options));
        // for record count
        dispatch(await shell.actions.automation.qaReview.loadQaReview());
        dispatch(await shell.actions.automation.qaFailed.loadQaFailed());
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async listAutomationDocuments(bolNumber) {
        return shell.gateway.listAutomationDocuments(bolNumber);
      },
      async fetchHardFailReasons(bolNumber) {
        return (await shell.gateway.getHardFailReasons(bolNumber)).hardFails;
      },
      async checkIfShipmentExistsInCrm(bolNumber) {
        const shipmentNumberFilter = {
          bolNumber: {
            $like: `${bolNumber}%`,
          },
        };

        const shipmentNumberSearchOptions = {
          filter: shipmentNumberFilter,
          limit: 1,
        };

        return shell.gateway.getAllShipments(shipmentNumberSearchOptions);
      },
    };
  })
  .build();
