import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadRoles = async (shell) => {
  const roles = await shell.gateway.getAllRoles();

  return {
    type: ActionTypes.Admin.ManageUsers.RolesLoaded,
    roles
  }
};

export default ActionBuilder
  .for(loadRoles)
  .build();