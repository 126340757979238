import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const load = async (shell, trackedSet, searchTerm, associateId, offset, limit, sort) => {

  const countTrackedParams = {};
  const listTrackedParams = {
    offset,
    limit,
    sort
  };

  if (searchTerm) {
    listTrackedParams.searchTerm = searchTerm;
  }

  if (associateId) {
    countTrackedParams.associateId = listTrackedParams.associateId = associateId;
  }

  const [
    trackedCounts,
    listTrackedResult
  ] = await Promise.all([
    shell.gateway.getTrackedShipmentCounts(countTrackedParams),
    shell.gateway.getTrackedShipments(listTrackedParams, trackedSet)
  ]);

  return {
    type: ActionTypes.Shipment.LoadBoard.Loaded,
    trackedCounts,
    shipmentCount: listTrackedResult.count,
    shipments: listTrackedResult.shipments
  };

};

export default ActionBuilder
  .for(load)
  .build();