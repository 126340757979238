import { Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import TgfTextArea from "../../../../../core/kendo/form/inputs/TgfTextArea";
import FieldError from "../../../../../crm/components/inputs/FieldError";

const FinancialNotes = (props) => {

    const invoice = props.data.invoice;

    const handleChange = async (e) => {
        props.setData({
            ...props.data,
            invoice: {
                ...invoice,
                [e.target.name]: e.target.value
            }
        });
        props.setIsDirty(true);
    };

    return (
        <AppMuiCard title={"Notes"}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Label editorId={"invoiceNotes"}>Invoice Notes (Appear on the customer’s invoice.)</Label>
                    <TgfTextArea
                        id={"invoiceNotes"}
                        name={"invoiceNotes"}
                        value={invoice?.invoiceNotes}
                        onChange={handleChange}
                        rows={5}
                        disabled={props.disableEdit || (!props.isAce && ![1, 6, 17, 19].includes(props.data.shipment.statusId))}
                    />
                    <FieldError>{props.fieldErrors.invoiceNotes}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"financialNotes"}>Internal Notes (Available for internal viewing only.)</Label>
                    <TgfTextArea
                        id={"financialNotes"}
                        name={"financialNotes"}
                        value={invoice?.financialNotes}
                        onChange={handleChange}
                        rows={5}
                        max={500}
                        disabled={props.disableEdit || (!props.isAce && ![1, 6, 17, 19].includes(props.data.shipment.statusId))}
                    />
                    <FieldError>{props.fieldErrors.financialNotes}</FieldError>
                </Grid>
            </Grid>

        </AppMuiCard>
    );
};

export default FinancialNotes;