import ActionTypes from "../../../ActionTypes";

const multiStopCompanies = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Duplication.Disposed:
      return [];
    case ActionTypes.Shipment.Duplication.ShipmentLoaded:
      return action.multiStopCompanies || [];
    default:
      return state;
  }
};

export default multiStopCompanies;