import React, {useEffect, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ReportNav from "../ReportNav";
import {Grid} from "@material-ui/core";
import AutoInvoiceGrid from "./includes/AutoInvoiceGrid";
import TgfAssociateAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";
import TgfButtonBasic from "../../../core/kendo/buttons/TgfButtonBasic";
import {Checkbox} from "@progress/kendo-react-inputs";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../core/hooks/usQuery";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const AutoInvoiceReport = () => {
    const gridRef = React.useRef();
    const history = useHistory();
    const query = useQuery();
    const globalState = useSelector(state => state);
    const associate = globalState.persona.associate;
    const associateRole = associate.roles[0].name;
    usePageTitle("TGF: Locked Shipments Report");

    const [filterAssociate, setFilterAssociate] = useState(null);
    const [showHaltedOnly, setShowHaltedOnly] = useState(false);

    const handleFilterAssociate = async (e) => {
        const value = e.target.value ?? "";
        await setFilterAssociate(value);
        await reload();
    }

    const handleOnlyShowHalted = async (e) => {
        const value = e.target.value ?? null;
        await setShowHaltedOnly(value);
        history.push(`/reports/locked-shipments-report?show-halted-only=${value}`);
        await reload();
    }

    const handleRerunLastInvoiceDistributionClicked = async (e) => {
        const result = await window.shell.gateway.retryLastInvoiceDistribution();
    }

    const reload = async () => {
      gridRef.current.refresh()
    };

    useEffect(() => {
        setShowHaltedOnly((query.get("show-halted-only") === "true"));
        reload();
    }, [query]);

    return (
        <FullWidthLayout SideNav={ReportNav} title="Locked Shipments Report">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {associateRole === "Ace" &&
                            <Grid item xs={3} >
                                <TgfAssociateAutoComplete
                                    id={"filterAssociate"}
                                    name={"filterAssociate"}
                                    onChange={handleFilterAssociate}
                                />
                            </Grid>
                        }
                        <Grid item>
                            <Checkbox
                                value={showHaltedOnly}
                                id={"hideDisabled"}
                                name={"hideDisabled"}
                                label={"Display Halted Shipments Only"}
                                onChange={handleOnlyShowHalted}
                            />
                        </Grid>
                        {associateRole === "Ace" && associate.canRetryInvoiceDistribution &&
                            <Grid item xs={3} >
                                <TgfButtonBasic
                                    id={"retryLastInvoiceDistributionButton"}
                                    name={"retryLastInvoiceDistributionButton"}
                                    onClick={handleRerunLastInvoiceDistributionClicked}
                                >
                                    Rerun Last Invoice Distribution
                                </TgfButtonBasic>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AutoInvoiceGrid ref={gridRef} filters={{filterAssociate, showHaltedOnly}} reload={reload} />
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
}

export default AutoInvoiceReport;
