import ActionTypes from '../../../ActionTypes';

const contacts = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.Disposed:
      return [];
    case ActionTypes.AddressBook.Modification.CompanyContactsLoaded:
    case ActionTypes.AddressBook.Modification.CompanyContactSaved:
    case ActionTypes.AddressBook.Modification.CompanyContactDeleted:
      return action.contacts;
    default:
      return state;
  }
};

export default contacts;