import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from "@material-ui/pickers";

const useStyle = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-input': {
      padding: 10,
      backgroundColor: theme.palette.white
    }
  },
  disabled: {
    '& .MuiOutlinedInput-input': {
      padding: 10,
      backgroundColor: theme.palette.tgfGrey.disabled
    }
  }
}));

const AppMonthYear = (props) => {
  const classes = useStyle();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker {...props} className={props.disabled ? classes.disabled : classes.input} />
    </MuiPickersUtilsProvider>
  )
}
export default AppMonthYear;