// Readies the incoming shipment for
// use in state.
import Lodash from 'lodash';
import washDate from './washDate';

const shipmentWash = shipment => {
  const washedShipment = Lodash.cloneDeep(shipment);

  washDate(washedShipment, 'bolDate');
  washDate(washedShipment, 'estimatedDeliveryDate');
  washDate(washedShipment, 'lockedDate');

  return washedShipment;
};

export default shipmentWash;