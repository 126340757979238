import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import LeftNav from '../LeftNav';
import {Grid} from '@material-ui/core';
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Admin.StandardReport.Load';
const DownloadProcessName = 'Admin.StandardReport.Download';

const DoNotPayReportPage = (props) => {

      const {
          standardReports,
          loadReportList,
          startDownload,
          endDownload
      } = props;

    usePageTitle("TGF: Standard Reports");

      React.useEffect(() => {
          loadReportList();
      }, []);

    const handleOpenDownloadFileClick = (report) => {
        startDownload();

        return fetch(`/api/standard-reports/${report.id}`, {

        })
          .then((response) => {
              return response.blob()
          })
          .then(blob => {

              const getDate = (date) => {
                  function pad2(n) {
                      return n < 10 ? '0' + n : n
                  }

                  return date.getFullYear().toString() +
                    pad2(date.getMonth() + 1).toString() +
                    pad2(date.getDate()).toString();
              }

              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = `${report.viewName}-${getDate(new Date())}.${report.isXlsx ? 'xlsx' : 'csv'}`;
              document.body.appendChild(a);
              a.click();
              a.remove();
              endDownload();
          });
    };

      function ReportList(props) {

          const reports = props.reports.sort(function(a, b) {
              const textA = a.name.toUpperCase();
              const textB = b.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });

          const standardReportList = reports.length ? reports.map((report) => <li key={report.id}>
              <a
                key={report.id}
                onClick={handleOpenDownloadFileClick.bind(this, report)}>{report.name}</a>
          </li>) : '';
          return <ul>{standardReportList}</ul>
      }

      return (
        <FullWidthLayout title={'Standard Reports'} SideNav={LeftNav}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12}>
                    <ReportList reports={standardReports}></ReportList>
                </Grid>
            </Grid>
        </FullWidthLayout>
      );
  }
;

export default ComponentBuilder
  .wrap(DoNotPayReportPage)
  .stateToProps((state, ownProps) => ({
      standardReports: state.reporting.standardReports.standardReports,
  }))
  .dispatchToProps((shell, dispatch, getState) => ({
      async loadReportList() {
          dispatch(shell.actions.sys.processStart(LoadProcessName));
          dispatch(await shell.actions.reporting.standardReports.standardReports());
          dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      startDownload: async () => {
          dispatch(shell.actions.sys.processStart(DownloadProcessName));
      },
      endDownload: async () => {
          dispatch(shell.actions.sys.processComplete(DownloadProcessName));
      }
  }))
  .build();