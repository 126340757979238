import {Button, Grid, Tooltip} from "@material-ui/core";
import React, {useState} from "react";
import TgfTextArea from "../../../core/kendo/form/inputs/TgfTextArea";
import TgfDialog from "../../../core/kendo/dialog/TgfDialog";
import {makeStyles} from "@material-ui/styles";
import combineClassNames from "../../../core/utils/combineClassNames";
import {useDispatch, useSelector} from "react-redux";
import haltRevertType from "./HaltRevertType";
import "./css/HaltReverInvoice.css";
import {AiFillStop} from "react-icons/ai";
import {ImUndo} from "react-icons/im";
import isAceSelector from "../../../hubs/persona/selectors/isAce";

const HaltRevertModal = (props) => {
    return (
        <>
            <TgfDialog title={(props.type === haltRevertType.HALT ? "Halt" : "Revert") + ` Shipment #${props.bolNumber}`}
                       onClose={props.closeModal}
                       width={500}>
                <Grid container spacing={2}>
                    {props.type === haltRevertType.HALT ? <>
                          <Grid item xs={12}>
                              Please provide the reason for halting.
                              <TgfTextArea
                                id={"reason"}
                                name={"reason"}
                                value={props.reason}
                                maxLength={200}
                                onChange={props.handleReasonChange}
                                rows={3}
                              />
                              <p className={"required-warning"}>*200 max characters, required</p>
                          </Grid>
                      </>
                        :
                        <Grid item xs={12}>
                            Confirm you are wanting to revert this invoice and relock the shipment for customer invoicing
                        </Grid>
                    }

                    <Grid item>
                        <Grid container spacing={2} style={{paddingTop: 10}}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type={"button"}
                                    onClick={props.save}
                                    disabled={props.type === haltRevertType.HALT ? props.reason.length <= 0 : false}
                                >
                                    {props.type === haltRevertType.HALT ? "Halt" : "Revert"}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    className={"delete-button"}
                                    type={"button"}
                                    onClick={props.closeModal}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TgfDialog>
        </>
    )
}

const GridButton = ({title, icon}) => {
    return (
        <Tooltip
            title={
                <div style={{padding: 0, margin: 0, fontSize: ".9rem"}}>
                    <p style={{margin: 5, lineHeight: "120%"}}>{title}</p>
                </div>
            }>
            <span>
                {icon}
            </span>
        </Tooltip>
    )
}

const HaltRevertButton = (props) => {

    const personalDispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reason, setReason] = useState('');
    const isAce = useSelector(state => isAceSelector(state));

    const handleHaltRevertClick = () => {
        setReason("");
        setIsModalOpen(true);
    }

    const closeModal = async () => {
        setIsModalOpen(false);
        await props.reload();
    }

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    }

    const save = async () => {
        let endpointPromise;
        let processName;
        if (props.type === haltRevertType.HALT) {
            endpointPromise = () => window.shell.gateway.haltShipment(props.bolNumber, reason);
            processName = "haltShipment";
        }
        if (props.type === haltRevertType.REVERT) {
            endpointPromise = () => window.shell.gateway.revertShipment(props.bolNumber);
            processName = "revertShipment";
        }

        try {
            personalDispatch(window.shell.actions.sys.processStart(processName));
            await endpointPromise();
        } catch (e) {
            console.log(e);
        } finally {
            await closeModal();
            personalDispatch(window.shell.actions.sys.processComplete(processName));
        }
    }

    return (
      <>
          {props.buttonStyle === "grid" ?
              (props.type === haltRevertType.HALT ?
                  <GridButton
                      title={`Halt Shipment #${props.bolNumber}`}
                      icon={
                              <AiFillStop
                                  className={"halt-revert-invoice_grid-button halt-revert-invoice_grid-button_halt"}
                                  onClick={handleHaltRevertClick}
                              />
                          }
                      />
                    :
                  isAce ?
                  <GridButton
                      title={`Revert Shipment #${props.bolNumber}`}
                      icon={
                                <ImUndo
                                 className={"halt-revert-invoice_grid-button halt-revert-invoice_grid-button_revert"}
                                 onClick={handleHaltRevertClick}
                                />
                           }
                  />
                     :
                    <></>
              )
              :
              <Button
                  className={props.className}
                  variant={props.variant}
                  type={"button"}
                  onClick={handleHaltRevertClick}
                  disabled={props.disabled || props.type === haltRevertType.REVERT && !isAce}
              >
                  {props.type === haltRevertType.HALT && "HALT"}
                  {props.type === haltRevertType.REVERT && "REVERT"}
              </Button>
          }

          {isModalOpen && <HaltRevertModal
                  reason={reason}
                  bolNumber={props.bolNumber}
                  save={save}
                  closeModal={closeModal}
                  handleReasonChange={handleReasonChange}
                  type={props.type}
              />
          }
      </>
    )
}

const useStyles = color => makeStyles(theme => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette[color].main,
        borderColor: theme.palette[color].main,
        fontSize: 12,
        '&:hover': {
            borderColor: theme.palette[color].main,
            backgroundColor: theme.palette[color].main,
            color: theme.palette.white,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: theme.palette[color].main,
            borderColor: theme.palette[color].main,
            color: theme.palette.white,
            outline: 'none'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.3rem'
        }
    },
    contained: {
        color: theme.palette.white,
        borderColor: theme.palette[color].main,
        backgroundColor: theme.palette[color].main,
        paddingLeft: 15,
        paddingRight: 15,
        '&:hover': {
            borderColor: theme.palette.white,
            backgroundColor: `${theme.palette[color].main}33`,
            color: theme.palette[color].main,
            outline: 'none'
        },
        '&:focus': {
            borderColor: theme.palette.white,
            backgroundColor: `${theme.palette[color].main}33`,
            color: theme.palette[color].main,
            outline: 'none'
        },
    },
    icon: {
        color: theme.palette['outlinedWarning'],
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));

const HaltRevertInvoice = (props) => {
    const globalState = useSelector(state => state);
    const arReleaseHidden = globalState?.utility?.featureFlags?.arReleaseHidden;

    let variant = 'outlined';
    const classes = useStyles('primary')();

    const className = combineClassNames(
      variant === 'contained' ? classes.contained : classes.button
    );

    return (
        (arReleaseHidden === false) &&
        <HaltRevertButton
          bolNumber={props.bolNumber}
          className={className}
          variant={variant}
          reload={props.reload}
          buttonStyle={props.buttonStyle}
          type={props.type}
          disabled={props.disabled}
        ></HaltRevertButton>
    )
}

export default HaltRevertInvoice;
