import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyDocuments = async (shell, comopanyId) => {

  const companyDocuments = await shell.gateway.getCompanyDocuments(comopanyId);

  return {
    type: ActionTypes.AddressBook.Listing.LoadCompanyDocuments,
    companyDocuments
  };

};

export default ActionBuilder
  .for(loadCompanyDocuments)
  .build();