import Core from '@atomos/core';
import ActionTypes from './ActionTypes';

/**
 * ActionBuilder2 enhances, or standardizes, complex action
 * invocation and error handling.
 */
class ActionBuilder2 {

  /**
   * Factory for creating the uncaught error action.
   * @param {string} actionName - The name of the action that failed.
   * @param {Error} error - The error encountered.
   * @return {{processName: *, type: *, error: *}}
   */
  static createUncaughtProcessErrorAction(actionName, error) {
    return {
      type: ActionTypes.Sys.UncaughtActionError,
      actionName,
      error
    };
  }

  /**
   * Wrapper method for fluent creation of actions.
   * @param {function} delegate - The core function of the action.
   * @return {ActionBuilder2}
   */
  static for(delegate) {

    if (!Core.Utils.isFunction(delegate)) {
      throw new Error(`[delegate] is required.`);
    }

    return new ActionBuilder2(delegate);
  }

  /**
   * Initializes a new instance specifying the delegate
   * involved in carrying out the action.
   * @param {function} delegate - The core function of the action.
   */
  constructor(delegate) {
    this.delegate = delegate;
    this.uncaughtErrorMap = null;
  }

  /**
   * Assigns a function that can customize uncaught error
   * action messages before they are dispatched.
   * @param {function} actionMap - The map function for action messages.
   * @return {ActionBuilder2}
   */
  uncaughtErrorActionMap(actionMap) {
    this.uncaughtErrorMap = actionMap;
    return this;
  }

  /**
   * Constructs the final function that will be used when
   * invoking the action.
   * @return {function}
   */
  build() {

    const delegate = this.delegate;
    const uncaughtErrorMap = this.uncaughtErrorMap || Core.Utils.identity;

    /**
     * Action that receives N args needed for the delegate.
     * This is the function invoked by the developer.
     */
    return async (...actionArgs) => {

      // Grab the shell for the action context.
      const shell = window.shell;

      // Be friendly for troubleshooting.
      try {

        // Invoke the delegate with the crm and any
        // args received.
        const actionResult = await delegate(shell, ...actionArgs);

        return actionResult;
      }
      catch (actionError) {

        if (process.env.NODE_ENV === 'development')
          console.error(actionError);

        // Allow adjustment of the final message.
        const errorActionResult = uncaughtErrorMap(ActionBuilder2.createUncaughtProcessErrorAction(delegate.name, actionError));

        return errorActionResult
      }
    };
  }

}

export default ActionBuilder2;