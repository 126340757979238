import { Grid } from "@material-ui/core";
import { Switch } from '@progress/kendo-react-inputs';
import { Label } from "@progress/kendo-react-labels";
import "../tgf-form.scss";
const TgfToggleButton = (props) => {
    return (
        <Grid container spacing={2} direction={"row"} alignItems={"center"}>
            <Grid item xs={4}>
                <Switch
                    {...props}
                    className={"tgf-toggle-button"}
                    onChange={props.onChange}
                    checked={props.checked ?? false}
                    label={null}
                    size={"small"}
                    offLabel={null}
                    onLabel={null}
                />
            </Grid>
            <Grid item xs={8}>
                {props.label && <Label editorId={props.id}>{props.label}</Label>}
            </Grid>
        </Grid>
    );
};

export default TgfToggleButton;