import Core from '@atomos/core';
import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import shipmentWash from './support/shipmentWash';
import invoiceWash from './support/invoiceWash';

const load = async (shell, bolNumber) => {

  let shipment = {};
  let invoice = {};
  let bolCarrierContact = {};
  let loadTrackingNotes = [];
  let loadContents = [];
  let multiStopLocations = [];
  let truckloadValueConfirmation = null;
  let invoiceAuditNotes = [];

  const isNew = !Core.Utils.isNumber(bolNumber);

  if (!isNew) {

    [
      shipment,
      invoice,
      bolCarrierContact,
      loadTrackingNotes,
      loadContents,
      multiStopLocations,
      truckloadValueConfirmation,
      invoiceAuditNotes
    ] = await Promise.all([
      shell.gateway.getShipment(bolNumber),
      shell.gateway.getInvoice(bolNumber),
      shell.gateway.getCarrierContact(bolNumber),
      shell.gateway.getAllShipmentTrackingNotes(bolNumber),
      shell.gateway.getShipmentLoadContent(bolNumber),
      shell.gateway.getShipmentMultipleStop(bolNumber),
      shell.gateway.getShipmentTruckLoadConfirmation(bolNumber),
      shell.gateway.getShipmentInvoiceAuditNotes(bolNumber)
    ]);

  }

  const loadedAction = {
    type: ActionTypes.Shipment.Modification.Loaded,
    shipment: shipmentWash(shipment),
    invoice: invoiceWash(invoice),
    bolCarrierContact,
    loadTrackingNotes,
    loadContents,
    multiStopLocations,
    truckloadValueConfirmation,
    invoiceAuditNotes
  };

  const actions = [loadedAction];

  if (!isNew) {
    if (Core.Utils.isNumber(shipment.customerId))
      actions.push(shell.actions.addressBook.entries.identifyCompany(shipment.customerId));
    if (Core.Utils.isNumber(shipment.shipperId))
      actions.push(shell.actions.addressBook.entries.identifyCompany(shipment.shipperId));
    if (Core.Utils.isNumber(shipment.consigneeId))
      actions.push(shell.actions.addressBook.entries.identifyCompany(shipment.consigneeId));
    if (Core.Utils.isNumber(shipment.thirdPartyId))
      actions.push(shell.actions.addressBook.entries.identifyCompany(shipment.thirdPartyId));
    if (shipment.carrierMcNumber)
      actions.push(shell.actions.carrierDirectory.entries.identifyCarrier(shipment.carrierMcNumber));
  }

  return actions;

};

export default ActionBuilder
  .for(load)
  .build();