import ActionTypes from '../../../ActionTypes';

const shipments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Reporting.CustomerPaymentsDisposed:
      return [];
    case ActionTypes.Reporting.CustomerPaymentShipmentsLoaded:
      return action.shipments;
    case ActionTypes.Reporting.MarkedShipmentsCustomerPaid:
      return state.map(shipment => {
        const updateShipment = action.shipments.find(s => s.bolNumber === shipment.bolNumber);
        return updateShipment || shipment;
      });
    default:
      return state;
  }
};

export default shipments;