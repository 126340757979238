import React from 'react';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AllCarriersListing from './includes/AllCarriersListing';
import { Grid } from '@material-ui/core';
import ComponentBuilder from "../../../core/ComponentBuilder";
import ReportNav from "../ReportNav";

const LoadProcessName = 'AllCarrier.AdminPage.Load';

const AllCarriers = (props) => {

  const {
    carriers,
    carrierCount,
    dispose,
    load,
  } = props;

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([[ 'carrierName', 'asc' ]]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('carrierName');

  React.useEffect(() => {
    load(offset, limit, sort);

    return () => {
      dispose();
    }
  }, [offset, limit, sort, load, dispose]);

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  return (
    <FullWidthLayout SideNav={ReportNav} title="All Carriers">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AllCarriersListing
            count={carrierCount}
            data={carriers}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(AllCarriers)
  .stateToProps((state, ownProps) => ({
    carriers: state.carrier.listing.carriers,
    carrierCount: state.carrier.listing.carrierCount,
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        dispatch(await shell.actions.carrier.listing.load(null, offset, limit, sort));

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.carrier.listing.dispose());
      }
    }
  })
  .build();