import React from "react";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import TgfGradientTitleBar from "../../../../../core/kendo/title/TgfGradientTitleBar.js/TgfGradientTitleBar";
import "./ShipmentFinancialsPaymentHistoryGrid.scss";

const ShipmentPaymentHistoryGrid = (props) => {

    const ref = React.useRef();

    const loadPaymentData = async (odata) => {
        if(!props.data) return {
            totalItemCount: 0,
            totalPageCount: 0,
            pageIndex:0,
            pageSize: odata.take,
            items: []
        };
        const dataPage = {
            totalItemCount: props.data.payments.length,
            totalPageCount: 0,
            pageIndex:0,
            pageSize: odata.take,
            items: props.data.payments
        }
        return dataPage;
    }

    React.useEffect(() => {
        ref.current.refresh();
    }, [props.data]);

    const GridColumns = [
        {field: "applicationDate", title: "Pay Date", type: "date" },
        {field: "paymentType", title: "Pay Type", type: "text"},
        {field: "amountPaidOnInvoice", title: "Pay Amount", type: "currency"},
        {field: "paymentMethod", title: "Pay Method", type: "text"},
        {field: "paymentRef", title: "Pay Reference", type: "text"},
        {field: "createdDateTime", title: "Logged Date", type: "dateFromUTCDateTime"},
    ];

    return (
        <>
            <TgfGradientTitleBar title={"Payment History"} />
            <TgfDataGrid
                className={"shipment-financials-payment-history-grid"}
                ref={ref}
                onLoadDataPage={loadPaymentData}
                gridColumns={GridColumns}
                pageable={false}
                scrollable={"none"}
            />
        </>
    )
};

export default ShipmentPaymentHistoryGrid;