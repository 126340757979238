import React from 'react';
import AppTooltip from "../../../core/components/AppTooltip";

const ComplianceToolTip = ({title, value, ...otherProps}) => {
  return (
    <AppTooltip title={title}>
      <React.Fragment>
        {value}
      </React.Fragment>
    </AppTooltip>
  )
};

export default ComplianceToolTip;