import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import AppDrawer from '../../../../core/components/AppDrawer';
import AppMuiInput from '../../../../core/components/inputs/AppInput';
import AppCard from '../../../../core/components/cards/AppCard/AppMuiCard';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppMuiButton from '../../../../core/components/AppButton';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';

const RevertShipmentDrawer = (props) => {

  const {
    customerName,
    invoice,
    statusOptions,
    onRevertClick,
    onCancelClick
  } = props;

  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const handleStatusChange = (e, statusType) =>{
    setSelectedStatus(statusType)
  };

  const handleRevertClick = () => {
    onRevertClick(selectedStatus);
  };

  return (
    <AppDrawer anchor={'right'} open={true} onClose={onCancelClick}>
      <AppCard title={'Revert Invoice'}>
        <div style={{width: 400, height: '100vh'}}>
          <Grid container spacing={1} justify="flex-end" alignItems="center">
            <Grid item xs={12}>
              <AppMuiInput
                readOnly
                disabled
                label={'Customer'}
                value={customerName || ''} />
            </Grid>
            <Grid item xs={12} md={6}>Shipment #:</Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'h6'}>
                {invoice.bolNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>Customer Cost:</Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'h6'}>
                <AppCurrencyText value={invoice.adjustedCustomerCost} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              Revert to:
            </Grid>
            <Grid item xs={12} md={6}>
              <AppDropdown
                field={'name'}
                fieldIndex={'id'}
                options={statusOptions}
                onChange={handleStatusChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item><AppMuiButton disabled={!selectedStatus} onClick={handleRevertClick}>Revert</AppMuiButton></Grid>
              <Grid item><AppMuiButton onClick={onCancelClick}>Cancel</AppMuiButton></Grid>
            </Grid>
          </Grid>
        </div>
      </AppCard>
    </AppDrawer>
  );
};

export default RevertShipmentDrawer;