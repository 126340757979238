import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import AppInput from '../../../core/components/inputs/AppInput';
import AppDropdown from '../../../core/components/dropdowns/AppDropdown';
import AppDialog from '../../../core/components/AppDialog';

const ChangeCategory = ({ categoryTypes, selectedCategoryTypeId, open, onClose, onConfirmClick }) => {
    const selectedCategory = categoryTypes.filter(val => val.id === selectedCategoryTypeId)[0];
    const [categoryType, setCategoryType] = useState(selectedCategory);
    const [reasonText, setReasonText] = useState('');

const actionButtons = [
    {
      title: "Cancel",
      action: onClose
    },
    {
      title: "Confirm",
      action: () => onConfirmClick(categoryType, reasonText)
    }
  ];

  const handleCategoryTypeChange = (e, value) => setCategoryType(value);
  const handleReasonChange = (e) => setReasonText(e.target.value);

    return (
        <AppDialog 
            title="Change Category"
            width={'xs'}
            open={open}
            onClose={onClose}
            actionButtons={actionButtons}
        >
            <Grid container direction="column" spacing={2}>
                <Grid item >
                    <AppDropdown
                        label="Company Type"
                        field={'name'}       
                        fieldIndex={'id'}           
                        disableClearable
                        options={categoryTypes}
                        value={categoryType}
                        onChange={handleCategoryTypeChange}
                    />
                </Grid>
                <Grid item>
                    <AppInput
                        label="Reason"
                        multiline={true}
                        rows={3}
                        onChange={handleReasonChange}
                    />
                </Grid>
            </Grid>
        </AppDialog>
    )
}
export default ChangeCategory;