import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const deleteHelpDocument = async (shell, documentId) => {
  await shell.gateway.deleteHelpDocument(documentId);

  return {
    type: ActionTypes.Support.HelpDeleteDocument,
    documentId
  }
};

export default ActionBuilder
  .for(deleteHelpDocument)
  .build();