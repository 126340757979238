import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const deleteShipmentDocument = async (shell, bolNumber, documentId) => {
  await shell.gateway.deleteShipmentDocument(bolNumber, documentId);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentDeleteDocument,
    documentId
  }
};

export default ActionBuilder
  .for(deleteShipmentDocument)
  .build();