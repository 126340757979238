import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import AppDenseGrid from "../../../core/components/AppDenseGrid/AppDenseGrid";
import AppCard from '../../../core/components/cards/AppCard/AppMuiCard';
import AppCurrencyText from '../../../core/components/text/AppCurrencyText';

const TypeLabels = {
  ltl: 'LTL Shipments',
  tl: 'TL Shipments',
  openLtl: 'Open LTL Shipments',
  openTl: 'Open TL Shipments',
  total: 'TOTAL'
};

const useStyles = makeStyles((theme) => ({
  averageCell: {
    color: theme.palette.tgfLink.dark
  }
}));

const getMonthName = (date) => date.toLocaleString('default', { month: 'long' });

const AverageCell = (classes, propName) => ({ rowData: record }) => {
  return (
    <AppCurrencyText value={record[propName]} className={classes.averageCell} />
  );
};

const GrossMarginTable = (props) => {

  const classes = useStyles();

  const {
    date = new Date(),
    grossMarginData
  } = props;

  const monthYear = `${getMonthName(date)} ${date.getFullYear()}`;

  const data = Object.entries(grossMarginData || {})
    .map(([key, record]) => ({
      ...record,
      title: TypeLabels[key]
    }));

  const GridColumns = [
    {
      title: monthYear,
      field: 'title',
      noSort: true,
      styles: {
        width: '15%'
      },
      dataType: 'string'
    },
    {
      title: "Shipment Count",
      field: 'shipmentCount',
      noSort: true,
      styles: {
        width: '12%',
        textAlign: 'center'
      },
      dataType: 'string'
    },
    {
      title: "Carrier Cost",
      field: 'carrierCost',
      noSort: true,
      styles: {
        width: '12%',
        textAlign: 'center'
      },
      dataType: 'currency'
    },
    {
      title: "Carrier Cost Avg",
      field: 'averageCarrierCost',
      noSort: true,
      styles: {
        width: '12%',
        textAlign: 'center'
      },
      dataType: 'component',
      component: AverageCell(classes, 'averageCarrierCost')
    },
    {
      title: "Customer Cost",
      field: 'customerCost',
      noSort: true,
      styles: {
        width: '12%',
        textAlign: 'center'
      },
      dataType: 'currency'
    },
    {
      title: "Customer Cost Avg",
      field: 'averageCustomerCost',
      noSort: true,
      styles: {
        width: '12%',
        textAlign: 'center'
      },
      dataType: 'component',
      component: AverageCell(classes, 'averageCustomerCost')
    },
    {
      title: "Margin",
      field: 'margin',
      noSort: true,
      styles: {
        width: '12%',
        textAlign: 'center'
      },
      dataType: 'currency'
    },
    {
      title: "Margin Avg",
      field: 'averageMargin',
      noSort: true,
      styles: {
        width: '12%',
        textAlign: 'center'
      },
      dataType: 'component',
      component: AverageCell(classes, 'averageMargin')
    }
  ]
  const rowClass = (data) => {
    return data.title === 'TOTALS' ? 'bold' : null;
  };

  return (
    <AppCard title={'Gross Margin Breakdown'}>
      <AppDenseGrid
        title=""
        count={1}
        data={data || []}
        columns={GridColumns}
        rowProps={rowClass}
        orderBy={''}
        order={'asc'}
        rowsPerPage={20}
        page={0 / 20}
      />
    </AppCard>
  )
}

export default GrossMarginTable;
