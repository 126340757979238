import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const secondary = '#ffc107';
const success = '#28a745';
const info = '#17a2b8';
const danger = '#dc3545';
const tgf_green = '#708c37';
const tgf_blue = '#007bff';
const tgf_grey_border = '#e3e4e4';
const tgf_grey_dark = '#747474';
const tgf_grey_field_border = '#c4c4c4';
const defaultFont = '#546e7a';

export default {
  black,
  white,
  default: {
    contrastText: defaultFont,
    main: white
  },
  primary: {
    contrastText: white,
     main: tgf_green,
     dark: '#c4d0b6',
     light: `${tgf_green}88`
  },
  secondary: {
    contrastText: black,
    main: secondary,
    light: `${secondary}88`
  },
  success: {
    contrastText: white,
    main: success,
    light: `${success}88`
  },
  info: {
    contrastText: white,
    main: info,
    light: `${info}88`
  },
  warning: {
    contrastText: white,
    main: secondary,
    light: `${secondary}88`,
    medium: `${secondary}66`
  },
  danger: {
    contrastText: white,
    main: danger,
    light: `${danger}88`
  },
  error: {
    contrastText: white,
    main: danger,
    light: `${danger}88`
  },
  text: {
    primary: defaultFont,
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  tgfGreen: {
    contrastText: white,
     main: tgf_green,
     secondary: '#c4d0b6',
     light: `${tgf_green}88`
  },
  tgfLink: {
    contrastText: white,
    main: tgf_blue,
    dark: '#075ebc'
  },
  tgfBlue: {
    main: tgf_blue
  },
  tgfBlack: {
    contrastText: white,
    main: '#111111',
    light: '#989898',
    dark: black
  },
  tgfGrey: {
    contrastText: black,
    main: tgf_grey_dark,
    border: tgf_grey_border,
    disabled: '#e9ebed',
    light: `${tgf_grey_dark}22`,
    dark: tgf_grey_dark,
    fieldBorder: tgf_grey_field_border
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  linearTop: '#f6f6f6',
  linearBottom: '#ececec'
};