import Lodash from 'lodash';
import Moment from 'moment';

const washDate = (shipment, pathSpec) => {
  const value = Lodash.get(shipment, pathSpec, null);
  if (value === null) return;

  Lodash.set(shipment, pathSpec, Moment(value).toDate());
};

export default washDate;