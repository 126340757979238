import ActionTypes from "../../ActionTypes";

const payPeriodAdjustmentCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.CommissionCenter.Disposed:
      return 0;
    case ActionTypes.CommissionCenter.PayPeriodCommissionAdjustmentLoaded:
    // case ActionTypes.CommissionCenter.CommissionAdjustmentDeleted:
      return action.payPeriodAdjustmentCount;
    default:
      return state;
  }
};

export default payPeriodAdjustmentCount;