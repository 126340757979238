import * as yup from "yup";

export const InvoiceValidationSchema = yup.object().shape({
    invoiceNotes: yup.string().max(500).nullable(),
    financialNotes: yup.string().max(500).nullable(),
    initialCarrierCost: yup
      .number()
      .min(0)
      .nullable(true)
      // checking self-equality works for NaN, transforming it to null
      .transform((_, val) => val === Number(val) ? val : null),
    initialCustomerCost: yup
      .number()
      .min(0)
      .nullable(true)
      // checking self-equality works for NaN, transforming it to null
      .transform((_, val) => val === Number(val) ? val : null),
});
