import ActionTypes from "../../../ActionTypes";

const carrierContact = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.CreateDocs.Disposed:
      return null;
    case ActionTypes.Shipment.CreateDocs.ShipmentLoaded:
      return action.carrierContact;
    default:
      return state;
  }
};

export default carrierContact;