import React, {useEffect, useRef, useState} from "react";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import AppMuiButton from "../../../../../core/components/AppButton";
import {useDispatch} from "react-redux";
import CollectionNotesModal from "./CollectionNotesModal";

const CollectionNotes = ({ companyId }) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    // const [selectedContact, setSelectedContact] = useState(null);
    const personalDispatch = useDispatch();
    const ref = useRef();

    const refreshGrid = () => {
        ref.current.refresh();
    };

    const load = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart("loadCollectionNotes"));
            odata = odata ?? {
                top: 20,
                skip: 0,
                filter: {},
            };
            odata.filter.and = { customerId: companyId };
            // load data
            return await window.shell.gateway.getPageOfCollectorNotes(odata, companyId);
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Collection Notes',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete("loadCollectionNotes"));
        }
    };

    const onClickAddContact = () => {
        setEditDialogOpen(true);
    };

    const onCloseDialog = () => {
        setEditDialogOpen(false);
        refreshGrid();
    };

    useEffect(() => {
        load();
    }, []);

    const gridColumns = [
        {
            title: "Note",
            field: "note",
            type: 'custom',
            cell: (props) => <td className={"k-table-td show-all-text"}><span>{props.dataItem.note ?? ' '}</span></td>
        },
        {
            title: "Date",
            field: "createDate",
            type: "dateTimeFromUTCDateTime",
            width: 150
        },
        {
            title: "Associate",
            field: "agentFullName",
            type: "text",
            width: 180
        }
    ];

    return (
        <AppMuiCard title={"Collection Notes"}>
            <Grid container spacing={2}>
                <Grid item>
                    <AppMuiButton type={"submit"} onClick={onClickAddContact}>
                        + New Note
                    </AppMuiButton>
                </Grid>
                {editDialogOpen && (
                    <CollectionNotesModal
                        onCloseDialog={onCloseDialog}
                        companyId={companyId}
                    />
                )}
                <Grid item xs={12}>
                    <TgfDataGrid
                        className={"collection-notes_listing-grid"}
                        ref={ref}
                        onLoadDataPage={load}
                        gridColumns={gridColumns}
                        // sort={[{ field: "firstName", dir: "desc" }]}
                        pageable={{
                            pageSize: 10,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500],
                        }}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};

export default CollectionNotes
