import {createSlice} from "@reduxjs/toolkit";
import convertThunksToActionBuilders from "../helpers/convertThunksToActionBuilders";

// Slice
const UtilitySlice = createSlice({
  name: 'utility',
  initialState: {
    featureFlags: {}
  },
  reducers: {
    featureFlagsReceived: (utility, action) => {
      utility.featureFlags = action.payload;
    },
  }
});

// Destructure the slice's actions for ease of access
const {
  featureFlagsReceived,
} = UtilitySlice.actions;

/*
// Thunks Summary:
Redux Thunk is middleware that allows you to return functions, rather than just actions, within Redux. This allows for
delayed actions, including working with promises.
The reason we are wrapping our thunks in an object, is so that we can easily convert them all to action builders.
*/
let thunks = {
  loadFeatureFlags: async (shell) => {
    const result = await shell.gateway.communicator.get('/security/featureFlags');
    return {
      type: featureFlagsReceived.type,
      payload: result
    };
  },

};

// thunks must be converted to work with our system!!
thunks = convertThunksToActionBuilders(thunks);

// export thunk actions and reducer
export default {
  actions: thunks,
  reducer: UtilitySlice.reducer
};