import React, {useEffect, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import AddressBookRecordNav from "../AddressBookRecordNav";
import {useDispatch, useSelector} from "react-redux";
import useStyles from "../BillingSettingsPage/styles";
import {Grid} from "@material-ui/core";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import FromToHistoryGrid from "./includes/FromToHistoryGrid";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import isAceSelector from "../../../hubs/persona/selectors/isAce";
import {useParams} from "react-router-dom";

const FromToHistoryPage = () => {
    const classes = useStyles();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);
    const personalDispatch = useDispatch();
    const isAce = useSelector(state => isAceSelector(state));
    const modifyingAssociate = useSelector(state => state.persona.modifyingAssociate);
    usePageTitle(`TGF: From/To History`);
    const [company, setCompany] = useState(null);

    const loadCompany = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));
            personalDispatch(await window.shell.actions.addressBook.modification.loadCompany(companyId));

            if (companyId) setCompany(await window.shell.gateway.getCompany(companyId));
        } catch(e) {
            console.error(e.message);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    }

    useEffect(() => {
        loadCompany();
    }, []);

    return (
        <FullWidthLayout SideNav={AddressBookRecordNav} title={`Address Book - From/To History - ${company?.name || ""}`} className={`${classes.header} address-book-styles`}>
            {company?.name ?
                <CheckIfAuthorized
                    isAce={isAce}
                    modifyingAssociate={modifyingAssociate}
                    company={company}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FromToHistoryGrid />
                        </Grid>
                    </Grid>
                </CheckIfAuthorized>
                :
                <MissingOrDeniedCompanyAlert />
            }
        </FullWidthLayout>
    )
}

export default FromToHistoryPage;
