import * as Redux from 'redux'
import shipment from './shipment';
import customer from './customer';
import shipper from './shipper';
import consignee from './consignee';
import customerContacts from './customerContacts';
import shipperContacts from './shipperContacts';
import consigneeContacts from './consigneeContacts';
import loadContents from './loadContents';
import truckloadValueConfirmation from './truckloadValueConfirmation';
import carrierContact from './carrierContact';
import multiStopCompanyContacts from './multiStopCompanyContacts';
import multiStopLocations from './multiStopLocations';

export default Redux.combineReducers({
  shipment,
  customer,
  shipper,
  consignee,
  customerContacts,
  shipperContacts,
  consigneeContacts,
  loadContents,
  truckloadValueConfirmation,
  carrierContact,
  multiStopCompanyContacts,
  multiStopLocations
});