import React, {useRef} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import { Grid} from "@material-ui/core";
import LeftNav from "../LeftNav";
import ManualInvoicingReportGrid from "./Includes/ManualInvoicingReportGrid";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const ManualInvoicingReportPage = (props) => {

    const ref = useRef();
    usePageTitle("TGF: Manual Invoicing");

    const reloadData = () => {
        ref.current.refresh();
    }


    return (
            <FullWidthLayout SideNav={LeftNav} title={`Manual Invoicing`} className={"manual-invoicing-page"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ManualInvoicingReportGrid ref={ref} reloadData={reloadData} type={'SHIPMENT_LOCKING'}/>
                    </Grid>
                </Grid>
            </FullWidthLayout>
    )
};

export default ManualInvoicingReportPage;
