import React from 'react';
import Core from '@atomos/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import { TextField, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles((theme) => ({
  root: {
    ...theme.tgfStyles.numberField,
    'label + &': {
      marginTop: -2,
    },
    '&.MuiInputBase-multiline': {
      padding: 0
    },
    '& *, *::before, *::after, *:hover': {
      transition: 'none',
      borderBottom: 0,
      padding: 0,
      content: 'none'
    },
  }
}))(TextField);

const useStyles = makeStyles(theme => ({
  labelPosition: {
    position: 'relative'
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}));

function ExtensibleNumberFormat(props) {
  const { inputRef, onChange, ...otherProps } = props;
  return (
    <NumberFormat
      {...otherProps}
      getInputRef={inputRef}
      isNumericString
      onValueChange={(values) => {
        // BUG: onValueChange is called upon initial render of the component.  This causes any handlers
        //      connected to our onChange property to fire.  This is not how React components should work.
        //      https://github.com/s-yadav/react-number-format/issues/317
        onChange({
          target: {
            name: otherProps.name,
            value: values.value || null
          }
        });
      }}
    />
  );
}

const AppMuiNumericInput = ({ label, thousandSeparator = true, decimalScale, labelColor=null, ...otherProps }) => {
  const classes = useStyles();
  const scaleProps = {
    decimalScale,
    fixedDecimalScale: Core.Utils.isNumber(decimalScale)
  };
  const className = otherProps.disabled ?
    classes.disabled : undefined;

  return (
    <FormControl>
      {label && <InputLabel shrink htmlFor={otherProps.id} className={classes.labelPosition} style={labelColor}>
        {label}
      </InputLabel>}
      <StyledTextField
        className={className}
        InputProps={{
          inputComponent: ExtensibleNumberFormat
        }}
        inputProps={{
          ...otherProps,
          ...scaleProps,
          thousandSeparator: thousandSeparator
        }}
      />
    </FormControl>
  );
}

export default AppMuiNumericInput;