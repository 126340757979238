import React, {useEffect, useState} from 'react';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import LeftNav from '../../LeftNav';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {Grid, Typography} from '@material-ui/core';
import AppDatePicker from "../../../../core/components/inputs/AppDatePicker";
import ListingFilterBar from "../Listing/ListingFilterBar";
import AppMuiButton from "../../../../core/components/AppButton";
import CrmAssociateComboBoxAcesOnly from "../../../../crm/components/CrmAssociateComboBoxAcesOnly";
import AutoProcessedInvolvedDocumentsDialog from "./includes/AutoProcessedInvolvedDocumentsDialog";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {FindInPage as FindInPageIcon} from '@material-ui/icons';
import AppMuiCheckbox from "../../../../core/components/inputs/AppCheckbox";

const LoadProcessName = 'Admin.AutoProcessedCarrierInvoicesReport.Load';

const AutoProcessedCarrierInvoicesReportPage = (props) => {
    const {
      autoProcessed,
      autoProcessedRecordCount,
      loadRecords,
      listAutomationDocuments,
      qaReviewRecordCount,
      qaFailedRecordCount
    } = props;

    const DocumentsCell = (partSelector) => {
      return ({rowData}) => {
        const value = partSelector(rowData);
        return (<AppIconButton
          Icon={FindInPageIcon}
          onClick={onDocumentsClick}
          value={value}
        />);
      }
    }

    const onDocumentsClick = (e) => {
      setCurrentInteractiveRecord(JSON.parse(e.currentTarget.value))
    }

    const gridColumns = [
      {
        field: 'rep',
        title: 'Rep',
        styles: {
          width: '5%'
        },
        dataType: 'string',
      },
      {
        field: 'bolNumber',
        title: 'Ship #',
        styles: {
          width: '10%'
        },
        dataType: 'component',
        component: AppDenseGrid.Cells
          .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
      },
      {
        field: 'pickupDate',
        title: 'Pickup Date',
        dataType: 'dateUTC',
        styles: {
          width: '10%'
        },
      },
      {
        field: 'invoicedAmount',
        title: 'Invoiced Amount',
        styles: {
          width: '10%'
        },
        dataType: 'currency'
      },
      {
        field: 'auditDate',
        title: 'Audit Date',
        styles: {
          width: '10%'
        },
        dataType: 'dateUTC'
      },
      {
        field: 'invoiceDueDate',
        title: 'Invoice Due Date',
        styles: {
          width: '10%'
        },
        dataType: 'dateUTC'
      },
      {
        title: 'Carrier Name',
        field: 'carrierName',
        styles: {
          width: '20%'
        },
        dataType: 'string'
      },
      {
        title: 'Remit Name',
        field: 'factoringName',
        styles: {
          width: '10%'
        },
        dataType: 'string'
      },
      {
        field: 'approvedDate',
        title: 'Approved Date',
        styles: {
          width: '10%'
        },
        dataType: 'date-time'
      },
      {
        field: 'approvedBy',
        title: 'Approved By',
        styles: {
          width: '10%'
        },
        dataType: 'string'
      },
      {
        field: 'Documents',
        title: 'Documents/Review',
        styles: {
          width: '10%'
        },
        dataType: 'component',
        component: DocumentsCell((record) => {
          return JSON.stringify(record)
        }, null)
      }
    ];

    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [sort, setSort] = React.useState([['auditDate', 'desc']]);

    const [approvedFilterStartDate, setApprovedFilterStartDate] = React.useState(null);
    const [approvedFilterEndDate, setApprovedFilterEndDate] = React.useState(null);
    const [byAgentId, setByAgentId] = React.useState(null);
    const [currentInteractiveRecord, setCurrentInteractiveRecord] = React.useState(null);
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    React.useEffect(() => {
      loadRecords(
        {
          limit: limit,
          offset: offset,
          sort: sort,
          startDate: approvedFilterStartDate,
          endDate: approvedFilterEndDate,
          byAgentId,
        }
      );
    }, [offset, limit, sort, approvedFilterStartDate, approvedFilterEndDate, byAgentId, loadRecords]);

    const handleChangePage = (e, page) => {
      setOffset(page * limit);
    };
    const handleSortChange = (column) => {

      const [existingColumn, existingOrder] = sort[0];

      const order = column === existingColumn ?
        existingOrder === 'asc' ? 'desc' : 'asc' :
        'asc';

      setSort([[column, order]]);
    };
    const handleChangeRowsPerPage = (e) => {
      setOffset(0);
      setLimit(e.target.value);
    };

    const handleApprovedFilterStartDateChange = (e) => {
      setApprovedFilterStartDate(e && e.toISOString() ? e.toISOString().substring(0, 10) : '');
    }

    const handleApprovedFilterEndDateChange = (e) => {
      setApprovedFilterEndDate(e && e.toISOString() ? e.toISOString().substring(0, 10) : '');
    }

    const onSelectAssociate = (e) => {
      setByAgentId(e ? e.id : null);
    }

    const handleClearDatesClick = () => {
      setApprovedFilterStartDate(null);
      setApprovedFilterEndDate(null);
    }

    const onExitDialogClick = () => {
      setCurrentInteractiveRecord(null)
    }

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
    if(e.target.checked === true) {
      setByAgentId(1);
    } else {
      setByAgentId(null);
    }
    setOffset(0);
  }

  useEffect(() => {
    if(byAgentId !== 1 && byAgentId) {
      setCheckboxChecked(false);
    }
  }, [byAgentId]);

    return (
      <React.Fragment>
        <FullWidthLayout title={''} SideNav={LeftNav}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={2}>
                <Grid item>
                  <ListingFilterBar
                    index={0}
                    qaReviewRecordCount={qaReviewRecordCount}
                    qaFailedRecordCount={qaFailedRecordCount}
                  ></ListingFilterBar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} md={'auto'}>
              <Typography noWrap={true} align='left'>Audit Date From:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <AppDatePicker
                value={approvedFilterStartDate}
                onChange={handleApprovedFilterStartDateChange}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <Typography align='left'>Audit Date To:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <AppDatePicker
                value={approvedFilterEndDate}
                onChange={handleApprovedFilterEndDateChange}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <AppMuiButton onClick={handleClearDatesClick}>Clear Dates</AppMuiButton>
            </Grid>
            <Grid item xs={12} md={"auto"}>
              <Typography align='left'>Approved By:</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CrmAssociateComboBoxAcesOnly
                byAgentId={byAgentId}
                placeholder={'Search agents...'}
                onSelectAssociate={onSelectAssociate}
                style={{minWidth: '250px'}}
              />
            </Grid>
            <Grid item>
              <AppMuiCheckbox
                label={"View auto-processed only"}
                checked={checkboxChecked}
                onChange={(e) => handleCheckboxChange(e)}>

              </AppMuiCheckbox>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AppDenseGrid
                  columns={gridColumns}
                  data={autoProcessed}
                  count={autoProcessedRecordCount}
                  orderBy={sort[0][0]}
                  order={sort[0][1]}
                  rowsPerPage={limit}
                  page={offset / limit}
                  onSort={handleSortChange}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Grid>
        </FullWidthLayout>
        {currentInteractiveRecord &&
          <AutoProcessedInvolvedDocumentsDialog
            onExitClick={onExitDialogClick}
            listAutomationDocuments={listAutomationDocuments}
            activeRecord={currentInteractiveRecord}
          />}
      </React.Fragment>
    );
  };

export default ComponentBuilder
  .wrap(AutoProcessedCarrierInvoicesReportPage)
  .stateToProps((state) => ({
    autoProcessed: state.automation.autoProcessed.autoProcessed,
    autoProcessedRecordCount: state.automation.autoProcessed.autoProcessedCount,
    qaReviewRecordCount: state.automation.qaReview.qaReviewCount,
    qaFailedRecordCount: state.automation.qaFailed.qaFailedCount
  }))
  .dispatchToProps((shell, dispatch) => {
    return {
      async loadRecords(options) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.automation.autoProcessed.loadAutoProcessed(options));
        // for record count
        dispatch(await shell.actions.automation.qaReview.loadQaReview());
        dispatch(await shell.actions.automation.qaFailed.loadQaFailed());
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async listAutomationDocuments(bolNumber) {
        return shell.gateway.listAutomationDocuments(bolNumber);
      },
    }
  })
  .build();