import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadNewCarrier = async (shell, associate) => {
  const carrier = await shell.gateway.createCarrier();
  carrier.associateId = associate.id;
  carrier.createDate = new Date();
  carrier.updateDate = carrier.createDate;

  return {
    type: ActionTypes.Carrier.Modification.CarrierLoaded,
    carrier
  };
}

export default ActionBuilder
  .for(loadNewCarrier)
  .build();