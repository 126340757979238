import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {CheckCircle} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.tgfGreen.main
  }
}));

const SuccessIcon = (props) => {
  const classes = useStyles();
  return (
    <CheckCircle className={classes.success} />
  );
};

export default SuccessIcon;