import * as yup from "yup";
import DateUtils from "../../../../../core/utils/dateUtils/dateUtils";

const alphaOnlyRegex = /^([^0-9]*)$/

export const truckstopValidationSchema = yup.object().shape({
    equipmentTypeId: yup.number().required(),
    originEarlyDateTime: yup.date().required().test({
        test(value) {
            //if attempting to add a new record, and we set a value.
            if (!this.parent.id && value) {
                if(DateUtils.isPastDate(value)) {
                    return this.createError({message: "Cannot be a past date"});
                }
            }
            return true
        }
    }),
    originLateDateTime: yup.date().nullable().test({
        test(value) {
            if (value !== null && value !== undefined) {
                if(DateUtils.isPastDate(value)) {
                    return this.createError({message: "Cannot be a past date"});
                }
                if(this.parent.originEarlyDateTime !== null && this.parent.originEarlyDateTime !== undefined && DateUtils.checkIfDateALessThanDateB(value, this.parent.originEarlyDateTime)) {
                    return this.createError({message: "Late Pickup Date cannot be before Early Pickup Date"});
                }
            }
            return true
        }
    }),
    destinationEarlyDateTime: yup.date().nullable().test({
        test(value) {
            if (value !== null && value !== undefined) {
                if(DateUtils.isPastDate(value)) {
                    return this.createError({message: "Cannot be a past date"});
                }
                if(this.parent.originEarlyDateTime !== null && this.parent.originEarlyDateTime !== undefined && DateUtils.checkIfDateALessThanDateB(value, this.parent.originEarlyDateTime)) {

                    return this.createError({message: "Early Delivery Date cannot be before Early Pickup Date"});
                }
            }
            return true
        }
    }).transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    destinationLateDateTime: yup.date().nullable().test({
        test(value) {
            if (value !== null && value !== undefined ) {
                if(DateUtils.isPastDate(value)) {
                    return this.createError({message: "Cannot be a past date"});
                }
                if(this.parent.destinationEarlyDateTime !== null && this.parent.destinationEarlyDateTime !== undefined && DateUtils.checkIfDateALessThanDateB(value, this.parent.destinationEarlyDateTime)) {
                    return this.createError({message: "Late Delivery Date cannot be before Early Delivery Date"});
                }
            }
            return true
        }
    }),
    originCity: yup.string().required().matches(alphaOnlyRegex, 'No numeric characters allowed'),
    originState: yup.string().required(),
    destinationCity: yup.string().required().matches(alphaOnlyRegex, 'No numeric characters allowed'),
    destinationState: yup.string().required(),
    transportationModeId: yup.number().required(),
    note: yup.string().required("Your TGF extension information is required to be entered here, at a minimum.").max(255),
    length: yup.number().nullable().min(1).max(999),
    width: yup.number().nullable().min(1).max(999), // max 3 chars. integers only.
    height: yup.number().nullable().min(1).max(999), // max 3 chars. integers only.
    weight: yup.number().nullable().min(1).max(9999999), // max 7 chars. integers only.
    pieceCount: yup.number().nullable().min(0).max(99999), // max 5 chars. integers only.
    palletCount: yup.number().min(0).max(99).nullable(), // max 2 chars. integers only.
    postedAllInRateUsd: yup.number().nullable().max(100000).test({
        test(value) {
            if (value && value < 1) {
                return this.createError({message: "Must be greater than or equal to $1.00"})
            }
            return true
        }
    }).transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    estMiles: yup.number().nullable(),
});
