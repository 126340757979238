import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const publishCompanyDocument = async (shell, companyId, companyDocument ) => {
  const document = await shell.gateway.publishCompanyDocument(companyId, companyDocument);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyPublishDocument,
    document
  }
};

export default ActionBuilder
  .for(publishCompanyDocument)
  .build();