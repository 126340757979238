import * as Redux from 'redux';

/**
 * Creates a state store for managing the state of the
 * entire application.
 * @param {object} config - Runtime configuration info.
 * @param {function} rootReducer - The root reducers for the state store.
 * @param {function[]} middleware - A collection of middleware to apply to the state store.
 * @param {object} initialState - The initial state to apply to the state store.
 */
const createStateStore = (config, rootReducer, middleware, initialState) => {

  const enhancers = [];

  if (config.isLocal() || config.isDevelopment()) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof(devToolsExtension) === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const composedEnhancers = Redux.compose(Redux.applyMiddleware(...middleware), ...enhancers);

  const stateStore = Redux.createStore(rootReducer, initialState, composedEnhancers);

  return stateStore;
};

export default createStateStore;