import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import AppButton from '../../../../../core/components/AppButton';
import AppCard from '../../../../../core/components/cards/AppCard';
import AppCurrencyText from '../../../../../core/components/text/AppCurrencyText';

const useStyles = makeStyles(theme => ({
  unPaid: {
    fontSize: '.8rem',
    fontWeight: 'bold',
    color: theme.palette.error.main
  },
  paid: {
    fontSize: '.8rem',
    fontWeight: 'bold',
    color: theme.palette.success.main
  },
  finalize: {
    marginTop: 0
  },
  divider: {
    backgroundColor: 'red',
    width: '100%'
  }
}));

const PayProcessingCard = (props) => {

  const {
    finalizeButtonDisabled,
    onFinalizeStatementClick,
    paidAmount,
    unpaidAmount
  } = props;

  const classes = useStyles();

  return (<>
    <AppCard title="Statement Processing">
      <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h4">Unpaid</Typography>
        </Grid>
        <Grid item className={classes.unPaid}>
          <Typography variant="h4" color="error">
            <AppCurrencyText value={unpaidAmount} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">Paid</Typography>
        </Grid>
        <Grid item className={classes.paid}>
          <Typography variant="h4" color="primary">
            <AppCurrencyText value={paidAmount} />
          </Typography>
        </Grid>
        {
          onFinalizeStatementClick &&
          <Grid item className={classes.finalize}>
            <AppButton variant="contained" onClick={onFinalizeStatementClick} disabled={finalizeButtonDisabled} disableElevation>
              Finalize Statement
            </AppButton>
          </Grid>
        }
      </Grid>
    </AppCard>
  </>
  )
}
export default PayProcessingCard;