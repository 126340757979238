import ActionTypes from '../../ActionTypes';

const brokerAssociates = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Persona.BrokerAssociatesLoaded:
      return action.brokerAssociates;
    default:
      return state;
  }
};

export default brokerAssociates;