import { Grid, Tooltip } from "@material-ui/core";
import { FileCopy, Visibility, VisibilityOff } from '@material-ui/icons';
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { useDispatch } from "react-redux";

const TgfPasswordInput = (props) => {
    const personalDispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const handleCopyPasswordClick = async () => {
        await navigator.clipboard.writeText(props?.value);
        personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
            content: 'Password copied to clipboard.',
        }));
    };

    return (
        <>
            {props?.label && <Label editorId={props.id}>{props.label}</Label>}
            <Grid container spacing={2}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Input
                        {...props}
                        value={props.value ?? ""}
                        type={"text"}
                        style={{
                            width: "100%",
                            fontFamily: visible ? "" : "text-security-disc",
                            WebkitTextSecurity: visible ? "" : "disc"
                        }}
                        label={null}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item>
                    {visible === false ?
                        <Tooltip title={"Show Password"}>
                            <VisibilityOff onClick={() => setVisible(true)} fontSize={'small'} />
                        </Tooltip>
                        :
                        <Tooltip title={"Hide Password"}>
                            <Visibility onClick={() => setVisible(false)} fontSize={'small'} />
                        </Tooltip>
                    }
                </Grid>
                <Grid item>
                    <Tooltip title={"Copy To Clipboard"}>
                        <FileCopy onClick={handleCopyPasswordClick} />
                    </Tooltip>
                </Grid>
            </Grid>


        </>
    );
};

export default TgfPasswordInput

