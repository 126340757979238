import Core from '@atomos/core';
import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadAssociate = async (shell, associateId) => {

  let associate = shell.gateway.createEmptyAssociate();
  let parentAssociate = null;

  if (Core.Utils.isNumber(associateId) && associateId > 0) {
    associate = await shell.gateway.getAssociate(associateId, true);
    if (associate.parentAssociateId) {
      parentAssociate = await shell.gateway.getAssociate(associate.parentAssociateId);
    }
  }

  return {
    type: ActionTypes.Admin.ManageUsers.AssociateLoaded,
    associate,
    parentAssociate
  };
};

export default ActionBuilder
  .for(loadAssociate)
  .build();