import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const publishCarrierDocument = async (shell, mcNumber, carrierDocument ) => {
  const document = await shell.gateway.publishCarrierDocument(mcNumber, carrierDocument);

  return {
    type: ActionTypes.Carrier.Modification.CarrierPublishDocument,
    document
  }
};

export default ActionBuilder
  .for(publishCarrierDocument)
  .build();