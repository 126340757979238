import React from 'react';
import {Grid} from '@material-ui/core';
import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';

const SubmitPaymentsConfirmationDialog = (props) => {

  const {
    bolNumbers = [],
    onCancelClick,
    onConfirmClick
  } = props;

  const actionButtons = [
    {
      title: 'Cancel',
      action: onCancelClick
    },
    {
      title: 'Submit',
      action: onConfirmClick
    }
  ];

  return (
    <AppDialog title={'Confirm Payment Submission'}
               width={'xs'}
               open={bolNumbers.length > 0}
               onClose={onCancelClick}
               actionButtons={actionButtons}
    >
      <Grid container spacing={2} direction={'column'}>
        <Grid item xs={12}>
          Are you sure you want to mark these shipments as paid?
        </Grid>
        <Grid item xs={12}>
          <AppAlertStatic color={'warning'} title={bolNumbers.join(', ')} />
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default SubmitPaymentsConfirmationDialog;