import React from "react";
import moment from "moment/moment";
import {Tooltip} from "@material-ui/core";

const MultiStopTooltip = ({ multiStops }) => {
  return (
      <Tooltip
        title={
          multiStops.map((stop, index) => {
            return (
              <div key={`stop-${index}`} style={{fontSize: ".9rem"}} className={"tooltip-multiStop"}>
                <p>Stop {index + 1}</p>
                <p>{stop.city}, {stop.state} {stop.zip}</p>
                <p>{stop.stopType} - {stop.arrivalDate && moment.utc(stop.arrivalDate).format('l')} - {stop.arrivalTime}</p>
                <hr/>
              </div>
            )
          })
        }>
        <span style={{color: "#0066ff", cursor: "pointer"}}> View</span>
      </Tooltip>
    )
}

export default MultiStopTooltip;