import ActionTypes from "../../../ActionTypes";

const customerContacts = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Duplication.Disposed:
      return [];
    case ActionTypes.Shipment.Duplication.ShipmentLoaded:
      return action.customerContacts;
    default:
      return state;
  }
};

export default customerContacts;