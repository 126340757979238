import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {
  ArtTrack, DonutSmall, Restore, Notes
} from '@material-ui/icons';

import AppSideBar from '../../core/components/AppSideBar';

const CarrierNav = (props) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const {
    match
  } = props;

  // Get the current bol number from the URL params.
  const shipmentNumber = match.params.id;

  const NavLinks = [
    {title: 'Compliance', icon: DonutSmall, key: 'compliance', href: `/carrier/${shipmentNumber}/compliance`},
    {title: 'Details', icon: ArtTrack, key: 'details', href: `/carrier/${shipmentNumber}/details`},
    {title: 'Documents', icon: Notes, key: 'documents', href: `/carrier/${shipmentNumber}/documents`},
    {title: 'History', icon: Restore, key: 'history', href: `/carrier/${shipmentNumber}/history`},
    {title: 'Notes', icon: Notes, key: 'notes', href: `/carrier/${shipmentNumber}/notes`}
  ];
  return (
    <AppSideBar menuList={NavLinks} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}
                open={open}/>
  );
}

export default withRouter(CarrierNav);
