import ActionTypes from '../../ActionTypes';

const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.Persona.Authenticating:
    case ActionTypes.Persona.Session.Expired:
    case ActionTypes.Persona.Invalidated:
      return false;
    case ActionTypes.Persona.Authenticated:
      return action.status === 'success';
    case ActionTypes.Persona.Identified:
      return !!action.associate;
    default:
      return state;
  }
};

export default isAuthenticated;