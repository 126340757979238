import { createRoot } from 'react-dom/client';
import './core/jsExtensions'; // must be first.

import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import oe from './oe';
import theme from './theme';


const startAndRender = async () => {
    const shell = await oe.start();

    createRoot(document.getElementById('root')).render(
        <Provider store={shell.stateStore}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );

};

startAndRender()
    .catch(err => console.error(err));