import React from 'react';
import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { InputBase, InputLabel, FormControl } from '@material-ui/core';

const StyledTextField = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    'label + &': {
      marginTop: 18,
    },
    '&.MuiInputBase-multiline': {
      padding: 0
    }
  },
  input: {
    ...theme.tgfStyles.inputField,
    inputBaseMultiline: {
      padding: 0
    }
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  }
}));

const AppMuiInput = ({ label, ...otherProps }) => {
  const classes = useStyles();

  const className = otherProps.disabled ?
    classes.disabled : undefined;

  return (
    <FormControl className={className}>
      {label && <InputLabel shrink htmlFor={otherProps.id}>
        {label}
      </InputLabel>}
      <StyledTextField {...otherProps} disabled={otherProps.disabled}/>
    </FormControl>
  )
}

export default AppMuiInput;