import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadNewCompany = async (shell) => {

  const company = shell.gateway.createCompany();

  // Assign the default payment terms.
  const paymentTerms = await shell.gateway.getPaymentTerms();

  const defaultPaymentTerm = paymentTerms.find(pt => pt.isDefault);

  if (!defaultPaymentTerm)
    throw new Error('Cannot get a default payment term for creating a new company.');

  company.paymentTerms = defaultPaymentTerm.id;
  company.creditLimit = 10000.00;

  return {
    type: ActionTypes.AddressBook.Modification.CompanyLoaded,
    company
  };
};

export default ActionBuilder
  .for(loadNewCompany)
  .build();