import ActionTypes from "../../../ActionTypes";

const carriers = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Carrier.Pending.Disposed:
      return [];
    case ActionTypes.Carrier.Pending.Loaded:
      return action.carriers;
    default:
      return state;
  }
};

export default carriers;