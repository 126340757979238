import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from "../../ActionTypes";

const loadShipmentPackageTypes = async (shell) => {

  const shipmentPackageTypes = await shell.gateway.getShipmentPackageTypes();

  return {
    type: ActionTypes.Support.ShipmentPackageTypesLoaded,
    shipmentPackageTypes
  };
};

export default ActionBuilder
  .for(loadShipmentPackageTypes)
  .build();