/**
 * Selector to identify whether the current associate
 * has the specified role.
 * @param {object} state - The current state tree.
 * @param {string} roleName - The name of the role.
 * @return {boolean} Whether the associate has the role.
 */
const isRole = (state, roleName) => {
  const associate = state.persona.associate;
  return associate &&
    hasRole(associate.roles, roleName);
};

/**
 * Helper function to ease searching for a role by name in
 * the associate's list of roles.
 * @param {Role[]} roles - A collection of Role model from the gateway.
 * @param {string} roleName - The name of the role to check.
 * @return {boolean} - Whether the role is found.
 */
const hasRole = (roles = [], roleName) => {
  return roles.some(r => r.name === roleName);
};

export default isRole;