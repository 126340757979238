import React from 'react';
import { Delete, Edit } from '@material-ui/icons';

import ContactListing from '../includes/ContactListing';

const gridColumns = [
  { title: "First Name", field: 'firstName', dataType: 'string' },
  { title: "Last Name", field: 'lastName', dataType: 'string' },
  { title: "Primary", field: 'isPrimary', dataType: 'component',
    component: ({ rowData }) => rowData.isPrimary ? 'Yes' : 'No'
  },
  { title: "Direct Phone", field: 'phone', dataType: 'string' },
  { title: "Mobile Phone", field: 'cellPhone', dataType: 'string' },
  { title: "Email", field: 'emailAddress', dataType: 'string' },
];

const ListingGrid = ({ contacts, onDeleteClick, onEditClick }) => {
  const options = [
    {
      label: 'Edit',
      click: (document) => onEditClick(document, true),
      isVisible: true,
      icon: <Edit />
    },
    {
      label: 'Remove',
      click: (document) => onDeleteClick(document, false),
      isVisible: true,
      icon: <Delete color="error" />,
      color: "error"
    }
  ];

  return (
    <ContactListing
      contacts={contacts}
      gridColumns={gridColumns}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
      options={options}
    />
  )
}
export default ListingGrid;