import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDebouncedValue } from "../../../../hooks/useDebouncedValue";

/* Notes
 JS: This is as close to good auto-complete behavior as I could get (so far). This will form the base for future auto-completes till further notice.
 So please try not to alter / deconstruct this code. Make a clone to test on if you want to try and improve upon this code.
 */

const TgfAssociateAutoComplete = (props) => {
    const {
        label,
        id,
        onChange
    } = props;

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [typedValue, setTypedValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [shouldLoad, setShouldLoad] = useState(true);
    const [tempData, setTempData] = useState(null);

    const debouncedTypedValue = useDebouncedValue(typedValue, 600);

    useEffect(() => {
        if (props.value) {
            setShouldLoad(false);
            setTypedValue(props.value);
        }
    }, []);

    // this whole gateway call eventually needs to be converted to use Odata / matts new tools.
    const load = async (searchTerm) => {

        const options = {
            includeInactive: props.includeInactive ?? false,
            searchTerm,
            roleId: props.roleId || [8], // queen by default.
            offset: 0,
            limit: 25,
            sort: [['lastName', 'asc']]
        };

        const associates = await window.shell.gateway.searchAllAssociates(options);
        setData(associates);
        setTempData(associates);
        setIsLoading(false);
        setIsOpen(true);
    };

    useEffect(() => {
        if (debouncedTypedValue && shouldLoad === true) {
            load(debouncedTypedValue);
        } else {
            setIsLoading(false);
            setIsOpen(false);
        }
    }, [debouncedTypedValue]);

    const handleOnChange = async (e) => {
        const value = e.target.value;
        if (e.nativeEvent.type === "click" || e.nativeEvent.key === "Enter") {
            setShouldLoad(false);
            setTypedValue(value);
            setSelectedValue(value);
            // this is hacky, but should work fine unless we have 2 users with the exact same full name.
            const user = tempData.find(user => user.fullName === value);
            onChange({ target: { value: user ?? "", name: props.name } }); // value = full user obj || empty string
        } else {
            setShouldLoad(true);
            setIsLoading(true);
            setIsOpen(false);
            setTypedValue(value);
        }
    };

    const onBlur = () => {
        setTypedValue(selectedValue);
        setShouldLoad(false);
        setIsOpen(false);
        setData([]);
    };
    const onFocus = () => {
        data.length > 0 && setIsOpen(true);
    };

    return (
        <>
            {label && <Label editorId={id}>{props.label}</Label>}
            <AutoComplete
                {...props}
                placeholder={"Search Agents..."}
                className={"tgf-kendo-combobox"}
                textField={"fullName"}
                value={typedValue}
                data={data}
                loading={isLoading}
                onChange={handleOnChange}
                opened={isOpen}
                onBlur={onBlur}
                onFocus={onFocus}
                clearButton={Boolean(selectedValue)}
                label={null} // we set this to null, because label is used in the Label component up above.
            />
        </>
    );
};

TgfAssociateAutoComplete.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input.
    id: PropTypes.string.isRequired,
    // Name of the input, this is sometimes used to reference in the event handler.
    name: PropTypes.string.isRequired,
    //
    value: PropTypes.string,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,
};

export default TgfAssociateAutoComplete;

