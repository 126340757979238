import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: 10,
    marginTop: 10,
    backgroundColor: theme.palette.tgfGrey.border,
    width: '100%',
    marginLeft: 0
  }
}))

const AppTitleBar = ({ title, divider = true }) => {
  const classes = useStyles();

  return (
    <>
      {
        typeof(title) === 'string' ?
          <Typography variant="h3">{title}</Typography> :
          title
      }
      {divider && <Divider variant="middle" className={classes.divider} />}
    </>
  )
}
export default AppTitleBar;