import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveCompanyFinancial = async (shell, companyFinancial) => {

  const updatedCompanyFinancial = await shell.gateway.saveCompanyFinancial(companyFinancial);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyFinancialSaved,
    companyFinancial: updatedCompanyFinancial
  };

};

export default ActionBuilder
  .for(saveCompanyFinancial)
  .build();