import ActionTypes from '../../ActionTypes';

const shipmentTruckLoadValueConfirmationType = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.ShipmentTruckloadValueConfirmationTypeLoaded:
      return action.shipmentTruckloadValueConfirmationTypes;
    default:
      return state;
  }
};

export default shipmentTruckLoadValueConfirmationType;