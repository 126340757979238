import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadAnnouncementNote = async (shell) => {

  const announcementNote = await shell.gateway.getAnnouncement();

  return {
    type: ActionTypes.Persona.LoadedAnnouncementNote,
    announcementNote
  };

};

export default ActionBuilder
  .for(loadAnnouncementNote)
  .build();