import ActionTypes from '../../../ActionTypes';

const companies = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.AddressBook.Listing.Disposed:
      return [];
    case ActionTypes.AddressBook.Listing.Loaded:
      return action.companies;
    default:
      return state;
  }
};

export default companies;