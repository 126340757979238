import React from 'react';
import Core from '@atomos/core';
import CrmAddressBookListingComboBox from './CrmAddressBookListingComboBox';
import ComponentBuilder from '../../core/ComponentBuilder';

const CrmTypedAddressBookListingComboBox = (props) => {

  const {
    ...otherProps
  } = props;

  return (
    <CrmAddressBookListingComboBox
      {...otherProps}
    />
  )
};

const formatStateName = (typeName) =>
  `${typeName}CategoryType`;

const selectCategoryType = (state, typeName) =>
  state.support[formatStateName(typeName)];

const FinalComponent = ComponentBuilder
  .wrap(CrmTypedAddressBookListingComboBox)
  .stateToProps((state, ownProps) => {
    const {
      types = []
    } = ownProps;

    const categoryTypes = types
      .map(type => selectCategoryType(state, type));

    const filterMap = (filter) => {
      let currentFilter = categoryTypes.length > 0 ?
        Core.Utils.merge({}, filter, {
          categoryTypeId: {
            $in: categoryTypes.map(c => c.id)
          }
        }) :
        null;

      if (Core.Utils.isFunction(ownProps.filterMap)) {
        currentFilter = ownProps.filterMap(currentFilter)
      }

      return currentFilter;
    };

    return {
      filterMap
    };
  })
  .build();

FinalComponent.BillingCompany = 'billingCompany';
FinalComponent.Customer = 'customer';
FinalComponent.FactoringCompany = 'factoringCompany'
FinalComponent.Lead = 'lead';
FinalComponent.ShipperConsignee = 'shipperConsignee';
FinalComponent.ThirdParty = 'thirdParty';

export default FinalComponent;