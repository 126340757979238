import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer, Divider, IconButton, Tooltip} from '@material-ui/core';
import Core from '@atomos/core';

import AppSideItem from './AppSideItem';
import {DoubleArrow} from '@material-ui/icons';

import combineClassNames from '../../utils/combineClassNames';

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawerPaper: {
    zIndex: 0,
    position: 'relative',
    whiteSpace: 'normal',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    paddingTop: 55
  },
  drawerPaperClose: {
    zIndex: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 55,
    [theme.breakpoints.up('sm')]: {
      width: 55
    }
  },
  toolbarIcon: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  closeToggle: {
    color: theme.palette.primary.main,
    padding: 0,
    marginBottom: 24,
    '&:hover': {
      backgroundColor: theme.palette.white,
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: theme.palette.white,
      boxShadow: 'none',
      outline: 'none'
    }
  },
  collapse: {
    fontSize: '1rem',
    fontWeight: 400
  },
  collapseArrow: {
    transform: 'rotate(180deg)'
  },
  leftNav: {
    position: 'sticky',
    height: '100vh',
    zIndex: 600,
    top: 0,
    bottom: 0
  }
}));

const menuIsSelected = (menuItem) =>
  typeof(menuItem.isSelected) === 'boolean' ?
    menuItem.isSelected :
    typeof(menuItem.isSelected) === 'function' ?
      Boolean(menuItem.isSelected(menuItem)) :
      false;

const getSelectedMenuKey = (menuList) => {

  // Any menu items that have isSelected take priority.
  let matchingMenuItem = menuList
    .find(menuIsSelected);

  // Menu not found via isSelected property, do basic url comparison.
  if (!matchingMenuItem) {

    // Score menu items by length if they match.
    const scoredMenuItems = menuList
      .filter(menuItem => window.location.pathname.startsWith(menuItem.href))
      .map(menuItem => ({ score: menuItem.href.length, menuItem }));

    // Biggest score wins.
    matchingMenuItem = Core.Utils
      .maxBy(scoredMenuItems, 'score')?.menuItem;

  }

  return matchingMenuItem?.key;
};

const AppSideContainer = ({handleDrawerOpen, handleDrawerClose, open, menuList, ...otherProps}) => {

  const classes = useStyles();
  const currentLoc = getSelectedMenuKey(menuList);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: combineClassNames(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}
      open={open}
      className={classes.leftNav}
    >
      <Divider/>
      <AppSideItem currentLocation={currentLoc} menuList={menuList || []} open={open}/>
      <div className={classes.toolbarIcon}>
        {open && <IconButton onClick={handleDrawerClose} className={classes.closeToggle}>
          <DoubleArrow className={classes.collapseArrow}/> <span style={{display: "block"}} className={classes.collapse}>Collapse</span>
        </IconButton>}
        {!open && <IconButton onClick={handleDrawerOpen} className={classes.closeToggle}>
          <Tooltip title="Expand"><DoubleArrow/></Tooltip>
        </IconButton>}
      </div>
    </Drawer>
  )
};

export default AppSideContainer;