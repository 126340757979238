import React from 'react';
import * as Yup from 'yup';

import AppValidationFailure from '../../../../core/components/AppValidationFailure';
import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';
import FormFactor from '../../../../core/FormFactor/FormFactor';
import AppInput from '../../../../core/components/inputs/AppInput';

const NewNoteSchema = Yup.object().shape({
  content: Yup.string().required('Note is required.')
});

const InvoiceAuditNoteDialog = (props) => {

  const {
    onSave,
    onClose
  } = props;

  const initialValues = {
    content: '',
    onClose
  };

  const handleSubmit = (values, formFactor) => {
    onSave(values.content.trim());
  };

  return (
    <FormFactor
      initialValues={initialValues}
      schema={NewNoteSchema}
      onSubmit={handleSubmit}>
      { renderDialogForm }
    </FormFactor>
  );
};

const renderDialogForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {

  const newNoteActions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleNoteChange = (e) =>
    setFieldValue('content', e.target.value);

  return (
    <AppDialog
      title={'New Audit Note'}
      open={true}
      onClose={values.onClose}
      width={'sm'}
      actionButtons={newNoteActions}>
            <AppInput
              id="note"
              rows="8"
              cols="45"
              multiline={true}
              maxLength="10000"
              onChange={handleNoteChange} />
            <AppValidationFailure message={errors.content} />
    </AppDialog>
  );
};

export default InvoiceAuditNoteDialog;