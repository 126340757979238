import React from 'react';
import {Box, Grid, Typography, TextField} from '@material-ui/core';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {makeStyles} from "@material-ui/core/styles";
import AppCard from "../../../../core/components/cards/AppCard/AppMuiCard";
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import AppText from "../../../../core/components/text/AppText";
import AppPercentText from "../../../../core/components/text/AppPercentText";


const TotalMarginTable = (props) => {

    const {
        date = new Date(),
        grossMarginData
    } = props;
    return (
        grossMarginData.total ? <AppCard>
            <h1><AppText value={' Margin Total: '} style={ grossMarginData.total.margin < 0 ? {color : "red" } : {color : "green" }  }></AppText>
            <AppCurrencyText value={grossMarginData.total.margin} ></AppCurrencyText>
            </h1>
        </AppCard> : <React.Fragment></React.Fragment>
    )
}

export default TotalMarginTable;
