import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const loadShipmentFreightClasses = async (shell) => {


  const shipmentFreightClasses = await shell.gateway.getShipmentFreightClasses();

  return {
    type: ActionTypes.Support.ShipmentFreightClassesLoaded,
    shipmentFreightClasses
  };
};

export default ActionBuilder
  .for(loadShipmentFreightClasses)
  .build();