import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const loadShipmentHazardousStates = async (shell) => {


  const shipmentHazardousStates = await shell.gateway.getHazardousStates();

  return {
    type: ActionTypes.Support.ShipmentHazardousStatesLoaded,
    shipmentHazardousStates
  };
};

export default ActionBuilder
  .for(loadShipmentHazardousStates)
  .build();