import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const setQaAccepted = async (shell, teraCrunchInputId, remitToInputData) => {

  await shell.gateway.setQaAccepted(teraCrunchInputId, remitToInputData);

  return {
    type: ActionTypes.Automation.QaAcepted,
    teraCrunchInputId
  };
};

export default ActionBuilder
  .for(setQaAccepted)
  .build();