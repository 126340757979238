import ActionTypes from '../../ActionTypes';

/**
 * Used to flag that a process is being started.
 * @param {string} processName - The name to give the process.
 * @return {object}
 */
const processStart = (processName) => {
  return {
    type: ActionTypes.Sys.ProcessStarting,
    processName
  };
};

export default processStart;