import ActionTypes from '../../../ActionTypes';

const companyDisablementHistories = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.CompanyDisablementHistoryLoaded:
      return action.disablementHistories;
    case ActionTypes.AddressBook.Modification.Disposed:
      return [];
    default:
      return state;
  }
};

export default companyDisablementHistories;