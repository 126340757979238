import Core from '@atomos/core';
import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadAssociateNotes = async (shell, associateId) => {
  const associateNotes = await shell.gateway.getAssociateNotes(associateId);

  return {
    type: ActionTypes.Admin.ManageUsers.AssociateNotesLoaded,
    associateNotes: Core.Utils.sortBy(associateNotes, ['createDate']).reverse()
  };

};
export default ActionBuilder
  .for(loadAssociateNotes)
  .build();