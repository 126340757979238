import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ErrorOutline } from '@material-ui/icons';

import AppTable from '../../../../core/components/AppDenseGrid';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppTooltip from '../../../../core/components/AppTooltip';
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  disabledText: {
    color: theme.palette.tgfGrey.main
  },
  tdStyles: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  warning: {
    color: theme.palette.error.main
  }
}));

const UnpaidShipmentsTable = (props) => {
  const classes = useStyles();
  const {
    data,
    actionButtons = null
  } = props;
  const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
  const buildLink = (path, title) => <Link to={path} target={ openLinksInNewTab ? "_blank" : "" } className={classes.tdStyles}>{title}</Link>;

  const WarningMessageCell = ({ rowData: shipmentRecord }) => {

    if (!shipmentRecord.warningMessage)
      return null;

    return (
      <AppTooltip title={shipmentRecord.warningMessage}>
        <ErrorOutline className={classes.warning} />
      </AppTooltip>
    );
  };

  const GridColumns = [
    {
      field: 'bolNumber',
      title: "Ship. #",
      styles: {
        width: 50,
        textAlign: 'center',
      },
      dataType: 'component',
      component: ({ rowData }) => buildLink(`/shipment/${rowData.bolNumber}/details`, rowData.bolNumber || '[BLANK]'),
      noSort: true
    },
    {
      field: 'customerName',
      title: 'Customer Name',
      styles: {
        textAlign: 'center',
      },
      noSort: true
    },
    {
      field: 'paymentTotal',
      title: "Amount",
      styles: {
        width: 80,
        textAlign: 'center'
      },
      dataType: 'currency',
      noSort: true
    },
    {
      field: 'statusType',
      title: 'Status',
      styles: {
        textAlign: 'center'
      },
      dataType: 'string',
      noSort: true
    },
    {
      field: 'warningMessage',
      title: '',
      styles: {
        width: 50,
        textAlign: 'center'
      },
      dataType: 'component',
      component: WarningMessageCell,
      noSort: true
    }
  ];

  return (
    <AppMuiCard title={'Unpaid'} action={actionButtons}>
      <Grid container direction="column" alignItems="center">
        <Grid item container xs={12}>
          <span className={classes.disabledText}>
            All entries here will be marked as paid by the customer.
          </span>
        </Grid>
        <Grid item container xs={12}>
          <AppTable
            count={1}
            data={data}
            columns={GridColumns}
          />
        </Grid>
      </Grid>
    </AppMuiCard>
  )
};

export default UnpaidShipmentsTable;