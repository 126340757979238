import * as Redux from 'redux';

import glitch from './glitch';
import process from './process';
import snackbarMessage from './snackbarMessage';

export default Redux.combineReducers({
  glitch,
  process,
  snackbarMessage
});