import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import AppMuiTable from '../../../../core/components/AppDenseGrid/AppMUITable';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  tdStyles: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  highlight: {
    fontWeight: 'bold',
    backgroundColor: '#FFFF00'
  },
  highlightTooltip: {
    fontWeight: 'bold',
    backgroundColor: '#FFFF00',
    color: theme.palette.black
  },
  highlightCheck: {
    textAlign: 'center'
  },
  title: {
    color: theme.palette.white
  },
  tooltipWidth: {
    width: 400,
    maxWidth: 400
  }
}));

const CompanyLinkCell = (classes, idProp, nameProp, openLinksInNewTab) => ({ rowData: addressBookListing }) =>
  (<Link to={`/address-book/${addressBookListing[idProp]}/details`} target={ openLinksInNewTab ? "_blank" : "" } className={classes.tdStyles}>{addressBookListing[nameProp] || '[BLANK]'}</Link>);


const PrimaryContactsListing = (props) => {
const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
  const {
    searchTerm,
    ...otherProps
  } = props;

  const classes = useStyles();

  const GridColumns = [
    {
      title: 'Name',
      field: 'companyName',
      styles: {
        width: 160,
      },
      dataType: 'component',
      component: CompanyLinkCell(classes, 'companyId', 'companyName', openLinksInNewTab)
    },
    {
      title: 'Type',
      field: 'categoryTypeName',
      styles: {
        width: 100,
      },
      dataType: 'string',
    },
    {
      title: "Phone",
      field: "companyBusinessPhone",
      styles: {
        width: 70
      },
      dataType: 'string'
    },
    {
      title: "Address 1",
      field: "companyAddress1",
      styles: {
        width: 160
      },
      dataType: 'string'
    },
    {
      title: "City",
      field: "companyCity",
      styles: {
        width: 100
      },
      dataType: 'string'
    },
    {
      title: "St/Prov",
      field: "companyStateProvince",
      styles: {
        width: 50
      },
      dataType: 'string'
    },
    {
      title: "Zip",
      field: "companyPostalCode",
      styles: {
        width: 40
      },
      dataType: 'string'
    },
    {
      title: 'Affiliate',
      field: 'affiliateCompanyName',
      styles: {
        width: 160,
      },
      dataType: 'component',
      component: CompanyLinkCell(classes, 'affiliateCompanyId', 'affiliateCompanyName', openLinksInNewTab)
    },
  ];

  return (
    <AppMuiTable
      columns={GridColumns}
      {...otherProps}
    />
  )
}

export default PrimaryContactsListing;