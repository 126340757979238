import React from 'react';
import { Grid } from '@material-ui/core';

import AppAlertStatic from '../../../core/components/AppAlertStatic';
import AppTitleBar from '../../../core/components/AppTitleBar';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ReportNav from '../ReportNav';
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const Reports = (props) =>{
    usePageTitle("TGF: Reports Page");
    return (
        <FullWidthLayout SideNav={ReportNav}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AppTitleBar
                        title="Reports"
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppAlertStatic title="Welcome to the Reports section.">
                        Use the navigation to the left to visit reports you might need.
                    </AppAlertStatic>
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
}


export default Reports;