import ActionTypes from '../../../ActionTypes';

const customerReportCount = (state = 0, action) => {
  switch(action.type) {
    case ActionTypes.Reporting.CustomerReport.Disposed:
      return 0;
    case ActionTypes.Reporting.CustomerReport.Loaded:
      return action.count;
    default:
      return state;
  }
};

export default customerReportCount;