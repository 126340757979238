import ActionTypes from '../../ActionTypes';

const roles = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Persona.RolesLoaded:
      return action.roles;
    default:
      return state;
  }
};

export default roles;