import React from 'react';
import Core from '@atomos/core';
import ComponentBuilder from '../../../core/ComponentBuilder';
import ShipmentPermissionMatrix from './ShipmentPermissionMatrix';

const SecureAction = (props) => {

  const {
    staticContext,
    associate,
    shipment,
    shipmentStatusTypes,
    pageName,
    actionName,
    as: DestinationComponent,
    disabled,
    ...otherProps
  } = props;

  const roleName = Core.Utils.get(associate, 'roles[0].name')
  const status = shipment && shipmentStatusTypes ?
    shipmentStatusTypes.find(st => st.id === shipment.statusId) :
    null;
  const statusName = status ?
    status.name : null;

  const shouldRender = associate && shipmentStatusTypes && shipment &&
    ShipmentPermissionMatrix.canViewAction(roleName, statusName, pageName, actionName);

  const isDisabled = disabled || !associate ||
    !shipment ||
    !shipmentStatusTypes ||
    !ShipmentPermissionMatrix.canPerformAction(roleName, statusName, pageName, actionName);

  return !shouldRender ?
    null :
    (
      <DestinationComponent
        disabled={isDisabled}
        {...otherProps}
      />
    );

};

export default ComponentBuilder
  .wrap(SecureAction)
  .stateToProps((state, ownProps) => {
    return {
      associate: state.persona.associate,
      shipment: state.shipment.modification.shipment,
      shipmentStatusTypes: state.support.shipmentStatusTypes
    };
  })
  .build();