import ActionTypes from '../../ActionTypes';

const shipmentStatusTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.ShipmentStatusTypesLoaded:
      return action.shipmentStatusTypes;
    default:
      return state;
  }
};

export default shipmentStatusTypes;