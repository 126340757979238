import ActionTypes from '../../../ActionTypes';
import ActionBuilder from "../../../ActionBuilder2";

const load = async (shell, searchTerm, offset, limit, sort) => {

  const { count, shipments } = await shell.gateway.getSearchLoads(searchTerm, offset, limit, sort);

  return {
    type: ActionTypes.Reporting.SearchLoads.Loaded,
    shipments,
    count
  };

};

export default ActionBuilder
  .for(load)
  .build();