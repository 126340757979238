import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Business from '@tgf-crm/business';

import ComponentBuilder from '../../core/ComponentBuilder';

import AppDrawer from '../../core/components/AppDrawer';
import EditOpenLoad from './includes/EditOpenLoad';
import FullWidthLayout from '../../core/layouts/FullWidthLayout';
import OpenLoadsListingTable from './includes/OpenLoadsListingTable';

import WhenAce from '../security/WhenAce';
import CrmAssociateComboBox from '../../crm/components/CrmAssociateComboBox';
import Core from '@atomos/core';
import RoleNames from '../../hubs/persona/RoleNames';
import FreightCategoryTypeNames from '../../hubs/shipment/FreightCategoryTypeNames';

const LoadProcessName = 'Shipment.OpenLoadsPage.Load';
const SaveProcessName = 'Shipment.OpenLoadsPage.Save';

const OpenLoadsPage = (props) => {

  const {
    associateRoles,
    shipments,
    shipmentCount,
    load,
    save,
    dispose,
    sendSnackbarMessage
  } = props;

  const queenRoleId = Core.Utils.get(Core.Utils.find(associateRoles, r => r.name === RoleNames.Queen), 'id', null);

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['shipmentBolDate', 'asc']]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('shipmentBolDate');
  const [selectedShipment, setSelectedShipment] = React.useState(null);
  const [filterAssociate, setFilterAssociate] = React.useState(null);

  useEffect(() => {
    load(filterAssociate, offset, limit, sort);

    return () => {
      dispose();
    };
  }, [filterAssociate, offset, limit, sort, dispose, load])

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const selectShipment = (shipment) => () => {
    setSelectedShipment(shipment);
  };

  const unselectShipment = () =>
    setSelectedShipment(null);

  const handleSave = (uncoveredDetails) => {
    save(uncoveredDetails)
      .then(() => {
        unselectShipment();
        sendSnackbarMessage({ content: `Shipment ${uncoveredDetails.bolNumber} saved.` });
      });
  };

  const onSelectAssociate = (associate) => {
    const associateValue = associate ? associate.id : null;
    setFilterAssociate(associateValue);
    setOffset(0);
  };

  return (
    <FullWidthLayout title="Open Loads Board" >
      <Grid container spacing={1} direction="column" justify="flex-start">
        <WhenAce>
          <Grid item>
            <CrmAssociateComboBox
              placeholder={'Search agents...'}
              roleId={queenRoleId}
              onSelectAssociate={onSelectAssociate}
              style={{ minWidth: '250px', maxWidth: '350px' }}
            />
          </Grid>
        </WhenAce>
        <Grid item>
          <OpenLoadsListingTable
            count={shipmentCount}
            data={shipments}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
            onSelectShipment={selectShipment}
            onSave={handleSave}
          />
        </Grid>
      </Grid>
      {
        !!selectedShipment &&
        <AppDrawer
          anchor="right"
          open={!!selectedShipment}
          onClose={unselectShipment}>
          <EditOpenLoad
            shipment={selectedShipment}
            onClose={unselectShipment}
            onSave={handleSave}
          />
        </AppDrawer>
      }
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(OpenLoadsPage)
  .stateToProps((state, ownProps) => ({
    shipments: state.shipment.openLoad.shipments,
    shipmentCount: state.shipment.openLoad.shipmentCount,
    associateRoles: state.persona.roles
  }))
  .dispatchToProps((shell, dispatch, getSate) => {
    return {
      async load(filterAssociate, offset, limit, sort) {

        const filter = {
          shipmentFreightCategoryTypeName: FreightCategoryTypeNames.Truckload,
          shipmentCarrierMcNumber: Business.Carrier.OpenCarriers.Tl.McNumber
        };

        if (filterAssociate) {
          filter.associateId = filterAssociate;
        }

        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.shipment.openLoad.load(filter, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.openLoad.dispose());
      },
      async save(uncoveredDetails) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.shipment.openLoad.save(uncoveredDetails));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();