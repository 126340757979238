import React from "react";
import {AccessTime} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import {commentsIcon, loadCommodityIcon} from "../../../../../core/media/icons/exportCustomIcons";

// Cells
export const datPickupDeliveryHoursCell = (cellProps) => {
    const pickupHours = cellProps.dataItem.pickupHours;
    const dropOffHours = cellProps.dataItem.dropOffHours;
    return (
        <td className={"center"}>
            <Tooltip
                title={
                <div className={"tgf-kendo-grid-tooltip"}>
                    <p>Pickup Hours </p>
                    <hr />
                    <p>{pickupHours ? pickupHours : ''}</p>
                    <br/>
                    <p> Delivery Hours </p>
                    <hr />
                    <p>{dropOffHours ? dropOffHours : ''}</p>
                </div>}>
              <AccessTime />
            </Tooltip>
        </td>
    )
}

export const datCommentsCell = (cellProps) => {
    const {comments} = cellProps.dataItem;
    return (
      <td className={"center"}>
        <Tooltip title={
          <div className={"tgf-kendo-grid-tooltip"}>
            <p>Comments</p>
            <hr />
            <p>{comments ? comments : ""}</p>
          </div>
        }>
          <img style={{height: 32, width: 32}} src={commentsIcon} alt={"comments"}/>
        </Tooltip>
      </td>

    )
}

export const datCommodityCell = (cellProps) => {
    const commodity = cellProps.dataItem.commodity;
    return (
      <td className={"center"}>
        <Tooltip title={
          <div className={"tgf-kendo-grid-tooltip"}>
            <p>Commodity</p>
            <hr />
            <p>{commodity ? commodity : ""}</p>
          </div>

        }>
          <img style={{height: 32, width: 32}} src={loadCommodityIcon} alt={commodity}/>
        </Tooltip>
      </td>
    )
}

export const fullPartialCell = (cellProps) => {
  return <td className={"center"}>
    <span>{cellProps.dataItem.fullPartial === "FULL" ? "F" : "P"}</span>
  </td>
}