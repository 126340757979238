import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadCompanyInvoiceDeliveryMethodTypes = async (shell) => {

  const companyInvoiceDeliveryMethodTypes = await shell.gateway.getCompanyInvoiceDeliveryMethodTypes();

  return {
    type: ActionTypes.Support.CompanyInvoiceDeliveryMethodTypesLoaded,
    companyInvoiceDeliveryMethodTypes
  };

};

export default ActionBuilder
  .for(loadCompanyInvoiceDeliveryMethodTypes)
  .build();