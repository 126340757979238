import React from 'react';
import {Link, useParams} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonGroup, Button } from '@material-ui/core';

import combineClassNames from '../../../../core/utils/combineClassNames';
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.tgfGrey.light,
      },
    },
    active: {
      textDecoration: 'none',
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    badge: {
      borderRadius: 5,
      marginLeft: 5,
      padding: '0 3px',
      color: theme.palette.white,
    },
    activeBadge: {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    },
    default: {
      backgroundColor: theme.palette.default.main,
    },
    all: {
      backgroundColor: '#fff',
      color: '#000'
    },
    hold: {
      backgroundColor: '#459e00'
    },
    invoiceGenerated: {
      backgroundColor: '#df03fc'
    },
    paymentsPending: {
      backgroundColor: '#f00'
    },
    customerPaid: {
      backgroundColor: '#00ccff'
    },
    dispute: {
      backgroundColor: '#ffff00',
      color: '#000'
    },
    void: {
        backgroundColor: '#484848',
        color: '#ffffff'
    }
  }
));

const DashboardFilterBar = ({ counts, statusSet, onClick }) => {
  const classes = useStyles();
  const params = useParams();

  const buttonList = [
    {
      title: 'All',
      link: '/admin/dashboard/all',
      value: counts.all,
      location: 'all',
      style: 'all',
      visible: true
    },
    {
      title: 'Hold / Invoice Generated',
      link: '/admin/dashboard/hold-invoice-generated',
      value: counts.hold,
      location: 'hold-invoice-generated',
      style: 'hold',
      visible: true,
      component: item => (
        <React.Fragment>
          Hold <span className={combineClassNames(classes.badge, classes.hold)}>{counts.hold}</span> <span style={{marginLeft: 4, marginRight: 4}}>/</span>
          Invoice Generated  <span className={combineClassNames(classes.badge, classes.invoiceGenerated)}>{counts.invoiceGenerated}</span>
        </React.Fragment>
      )
    },
    {
      title: 'Payments Pending',
      link: '/admin/dashboard/payments-pending',
      value: counts.paymentsPending,
      location: 'payments-pending',
      style: 'paymentsPending',
      visible: true
    },
    {
      title: 'Customer Paid',
      link: '/admin/dashboard/customer-paid',
      value: counts.customerPaid,
      location: 'customer-paid',
      style: 'customerPaid',
      visible: true
    },
    {
      title: 'Dispute/Claim',
      link: '/admin/dashboard/dispute',
      value: counts.dispute,
      location: 'dispute',
      style: 'dispute',
      visible: true
    },
  {
      title: 'Canceled/Voided',
      link: '/admin/dashboard/void',
      value: counts.void,
      location: 'void',
      style: 'void',
      visible: true,
      component: () => (
          <React.Fragment>
              CANCELLED/VOIDED <span className={combineClassNames(classes.badge, classes.void)}>{counts.void}</span>
              <InfoIconTooltip style={{whiteSpace: 'pre-line', marginLeft: 10, marginRight: 0}} title={"Shipments in Cancelled/Voided status are not included and are not visible within the “All” shipments on the Dashboard."} />
          </React.Fragment>
      )
  }
  ];

  return (
    <ButtonGroup role="group" aria-label="CRM toolbar button group.">
      {
        buttonList.map((item, key) => {
          const activeClassName = item.location === params.statusSet ? classes.active : classes.buttonLink;
          const activeBadge = classes[item.style];
          return (
            item.visible &&
            <Button
              component={Link}
              className={activeClassName}
              key={key}
              to={item.link}
              onClick={onClick}>
              {
                item.component ?
                  item.component(item) :
                  <React.Fragment>
                    {item.title}
                    <span className={combineClassNames(classes.badge, activeBadge)}>{item.value}</span>
                  </React.Fragment>
              }
            </Button>
          )
        })
      }
    </ButtonGroup>
  );
}

export default DashboardFilterBar;
