import React from "react";
import {Typography} from "@material-ui/core";
import ToggleShipmentPin from "./ShipmentPin/ToggleShipmentPin";

export const ShipmentTitle = (props) => {
    return (
      <div style={{display:"inline-flex"}}><Typography variant="h3" style={{paddingRight:"30px", paddingTop:"5px"}}>{props.title}</Typography>
          {props.bolNumber !== undefined && <><ToggleShipmentPin bolNumber={props.bolNumber}></ToggleShipmentPin></> }
      </div>
    )
};

export default ToggleShipmentPin;
