import ActionTypes from "../../../ActionTypes";

const ShipmentTruckLoadConfirmation = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.ShipmentTruckLoadConfirmationDeleted:
      return null;
    case ActionTypes.Shipment.Modification.ShipmentTruckLoadConfirmationLoaded:
    case ActionTypes.Shipment.Modification.ShipmentTruckLoadConfirmationSaved:
      return action.truckloadValueConfirmation;
    default:
      return state;
  }
};

export default ShipmentTruckLoadConfirmation;