import { Button, Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../../../../core/kendo/dialog/ConfimationModal";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import TgfComboBox from "../../../../../core/kendo/form/inputs/TgfComboBox";
import TgfPhoneNumberInput from "../../../../../core/kendo/form/inputs/TgfPhoneNumberInput";
import TgfTextBox from "../../../../../core/kendo/form/inputs/TgfTextBox";
import useFieldValidator from "../../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import { billingContactValidationSchema } from "../validation/billingContactValidationSchema";

const BillingContactsModal = (props) => {
    const personalDispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(Boolean(props?.activeDataIndex !== null));
    const [isValid, setIsValid] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const emailOptions = [
        { id: "TO", display: "TO" },
        { id: "CC", display: "CC" }
    ];

    const [formData, setFormData] = useState({
        firstName: props?.cellData?.firstName || null,
        lastName: props?.cellData?.lastName || null,
        email: props?.cellData?.email || null,
        directPhone: props?.cellData?.directPhone || null,
        mobilePhone: props?.cellData?.mobilePhone || null,
        fax: props?.cellData?.fax || null,
        emailOption: props?.cellData?.emailOption || emailOptions[0].id,
    });

    const handleSave = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveShipmentAdjustment'));

            if (props.activeDataIndex === null) {
                props.data.additionalData.sendToContacts.push({
                    ...formData,
                });
            } else {
                props.data.additionalData.sendToContacts.splice(props.activeDataIndex, 1, {
                    ...props.cellData,
                    ...formData,
                });
            }

            setIsEditMode(true);
            props.setIsDirty(true);

            await props.onCloseDialog();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveShipmentAdjustment'));
        }
    };

    const handleDelete = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveShipmentAdjustment'));

            props.data.additionalData.sendToContacts.splice(props.activeDataIndex, 1);

            props.setIsDirty(true);

            await props.onCloseDialog();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveShipmentAdjustment'));
        }

    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.element.name]: e.target.value ? e.target.value : null
        });
        setIsDirty(true);
    };

    const handleEmailOptionChange = async (e) => {
        setFormData({
            ...formData,
            emailOption: e.target.value ? emailOptions.find(state => state.id === e.target.value.id).id : ""
        });
        setIsDirty(true);
    };

    const fieldErrors = useFieldValidator(billingContactValidationSchema, formData, setIsValid);

    return (
        <TgfDialog
            title={`${props?.activeDataIndex !== null ? "Edit" : "Add"} Billing Contact`}
            onClose={props.onCloseDialog}
            width={600}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Label editorId={"firstName"}>First Name</Label>
                    <TgfTextBox
                        id={"firstName"}
                        name={"firstName"}
                        value={formData.firstName}
                        // disabled={userCanEditDelete}
                        onChange={handleChange}
                    />
                    <FieldError >{fieldErrors.firstName}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"lastName"}>Last Name</Label>
                    <TgfTextBox
                        id={"lastName"}
                        name={"lastName"}
                        value={formData.lastName}
                        // disabled={userCanEditDelete}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"email"}>Email Address</Label>
                    <TgfTextBox
                        id={"email"}
                        name={"email"}
                        value={formData.email}
                        // disabled={userCanEditDelete}
                        onChange={handleChange}
                    />
                    <FieldError >{fieldErrors.email}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"directPhone"}>Business Phone</Label>
                    <TgfPhoneNumberInput
                        id={"directPhone"}
                        name={"directPhone"}
                        value={formData.directPhone}
                        onChange={handleChange}
                    />
                    <FieldError >{fieldErrors.directPhone}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"mobilePhone"}>Mobile Phone</Label>
                    <TgfPhoneNumberInput
                        id={"mobilePhone"}
                        name={"mobilePhone"}
                        value={formData.mobilePhone}
                        onChange={handleChange}
                    />
                    <FieldError >{fieldErrors.mobilePhone}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <Label editorId={"fax"}>Fax</Label>
                    <TgfPhoneNumberInput
                        id={"fax"}
                        name={"fax"}
                        value={formData.fax}
                        onChange={handleChange}
                    />
                    <FieldError >{fieldErrors.fax}</FieldError>
                </Grid>
                <Grid item xs={6}>
                    <TgfComboBox
                        label={"Email Option"}
                        id={"emailOption"}
                        name={"emailOption"}
                        data={emailOptions}
                        value={formData.emailOption}
                        onChange={handleEmailOptionChange}
                        clearButton={false}
                    />
                    <FieldError >{fieldErrors.emailOption}</FieldError>
                </Grid>
                {isEditMode ?
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type={"button"}
                                onClick={handleSave}
                                disabled={Boolean(!isValid || !isDirty)}
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                className={"delete-button"}
                                type={"button"}
                                onClick={() => setDeleteModalOpen(true)}
                            >
                                Delete
                            </Button>
                        </Grid>
                        {deleteModalOpen &&
                            <ConfirmationModal
                                title={`Confirm Deletion`}
                                description={"Are you sure you want to delete this contact?"}
                                action={handleDelete}
                                onClose={() => setDeleteModalOpen(false)}
                            />
                        }
                    </Grid>
                    :
                    <>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                type={"button"}
                                disabled={Boolean(!isValid || !isDirty)}
                                onClick={handleSave}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        </TgfDialog>
    );
};

export default BillingContactsModal;
