import React from "react";
import {Button, Tooltip} from "@material-ui/core";
import {useSelector} from "react-redux";
import ConditionalWrapper from "../../../../core/kendo/ConditionalWrapper";

const styles = {
    color: "#718C37",
}
const NewPostingButton = ({setNewPostingDialogOpen, integrationType}) => {

    const hasDatCredentials = useSelector(state => state.persona.associate.hasDatCredentials);
    const hasTruckstopCredentials = useSelector(state => state.persona.associate.hasTruckstopCredentials);

    const disabled = ((integrationType === "DAT" && !hasDatCredentials) || (integrationType === "Truckstop" && !hasTruckstopCredentials))

    return (
        <ConditionalWrapper
            condition={disabled}
            wrapper= {children =>
                <Tooltip title={<div className={"tgf-kendo-grid-tooltip"}>
                                    <p>{`You currently do not have valid ${integrationType === "Truckstop" ? "ITS" : "DAT"} credentials.`}</p>
                                </div>
                    }>
                    <span>{children}</span>
                </Tooltip>
            }
        >
            <Button
                onClick={() => setNewPostingDialogOpen(true)}
                variant="outlined"
                style={!disabled ? styles : {}}
                disabled={disabled}
            >
                New {integrationType === "Truckstop" ? "ITS" : "DAT"} Posting
            </Button>
        </ConditionalWrapper>
    )
}

export default NewPostingButton