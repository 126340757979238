import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const resetShipmentMultipleStop = async (shell, bolNumber) => {

  const shipmentMultipleStops = await shell.gateway.resetShipmentMultipleStop(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentMultipleStopsReset,
    shipmentMultipleStops
  };

};

export default ActionBuilder
  .for(resetShipmentMultipleStop)
  .build();