import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCarrierDocuments = async (shell, mcNumber) => {

  const carrierDocuments = await shell.gateway.getCarrierDocuments(mcNumber);

  return {
    type: ActionTypes.Carrier.LoadBoard.LoadCarrierDocuments,
    carrierDocuments
  };

};

export default ActionBuilder
  .for(loadCarrierDocuments)
  .build();