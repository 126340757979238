import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveShipmentContent = async (shell, content) => {

  // eslint-disable-next-line no-unused-vars
  const updateContent = await shell.gateway.saveShipmentContent(content);

  const loadContents = await shell.gateway.getAllShipmentContents(content.bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentContentsSaved,
    loadContents
  }
};

export default ActionBuilder
  .for(saveShipmentContent)
  .build();
