import React from "react";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import {Button, Grid} from "@material-ui/core";

const WarningDialog = (props) => {

    const {
        setShowWarningDialog,
        setShowGeneratedDocs,
        invoice,
    } = props;


    // compute the message, and display accordingly
    const WarningMessage = () => {
        let displayMessage;

        if(!invoice.adjustedCarrierCost) {
            displayMessage = "The adjust Carrier Cost is currently $0";
        }
        if(!invoice.adjustedCustomerCost) {
            displayMessage = "The adjusted Customer Cost is currently $0";
        }

        if(!invoice.adjustedCarrierCost && !invoice.adjustedCustomerCost) {
            displayMessage = "Both the Adjusted Carrier and Customer Costs are currently $0";
        }
        return (
            <p>{displayMessage}. Are you sure you want to create docs?</p>
        )
    };


    const toggleDialog = () => {
        setShowWarningDialog(false)
    };

    const handleCancelButtonClick = () => {
        setShowWarningDialog(false);
    };

    function handleCreateButtonClick() {
        setShowGeneratedDocs(true);
        setShowWarningDialog(false);
    }

    return (
        <TgfDialog
            // title={title}
            onClose={toggleDialog}
            width={506}
        >
            {invoice &&
                <>
                    <WarningMessage />
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => handleCreateButtonClick()}
                            >
                                Create
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => handleCancelButtonClick()}
                            >
                                Cancel
                            </Button>
                        </Grid>

                    </Grid>

                </>
            }
        </TgfDialog>
    );
};

export default WarningDialog;
