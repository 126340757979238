import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

const TgfPhoneNumberInput = (props) => {
    const handleChange = (e) => {
        const valid = /\d/.test(e.target.value);
        props.onChange(valid ? e : { target: { element: { name: props.name }, value: "" } });
    };

    return (
        <>
            {props?.label && <Label editorId={props.id}>{props.label}</Label>}
            <MaskedTextBox
                mask={"(999) 000-0000"}
                {...props}
                value={props?.value || ""}
                onChange={handleChange}
                label={null}
            />
        </>

    );
};

export default TgfPhoneNumberInput;