import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadShipmentTrackingPhases = async (shell) => {

  const shipmentTrackingPhases = await shell.gateway.getLoadTrackingPhases();

  return {
    type: ActionTypes.Support.ShipmentTrackingPhasesLoaded,
    shipmentTrackingPhases
  };

};

export default ActionBuilder
  .for(loadShipmentTrackingPhases)
  .build();