import Core from "@atomos/core";
import * as Yup from "yup";

const DetailsFormSchema = Yup.lazy(values => {

    const schema = {
        name: Yup.string()
            .required('Name is required.'),
        selectedAssociate: Yup.object()
            .required('Associate must be selected.'),
        selectedCategoryType: Yup.object()
            .required('Company Type must be selected.'),
        businessPhone: Yup.string()
            .required('Business Phone is required.')
            .matches(Core.Text.PhoneRegExp, 'Business Phone is invalid.'),
        faxPhone: Yup.string()
            .nullable()
            .matches(Core.Text.PhoneRegExp, 'Fax Phone is invalid.'),
        address1: Yup.string()
            .required('Address Line 1 is required.'),
        city: Yup.string()
            .required('City is required.'),
        selectedStateProvince: Yup.object()
            .required('State/Province must be selected.'),
        paymentTerms: Yup.number()
            .required('Payment terms is required.')
            .typeError('Must be a number.')
            .min(0, 'Cannot be negative value.')
            .max(120, 'Cannot be over 120.'),
        postalCode: Yup.string()
            .min(5, 'Zip/Postal Code must be at least 5 digits.')
            .max(7, 'Zip/Postal Code cannot be more than 7 digits.')
            .required('Zip/Postal Code is required.')
    };
    if (values.selectedCategoryType && values.selectedCategoryType.requiresCredit) {
        schema.creditLimit = Yup.number()
            .min(0, 'Cannot be negative value.')
            .typeError('Credit Limit is required.')
            .required('Credit Limit is required.');
    }
    // The categoryType property should be a full CategoryType
    // instance that also
    if (values.selectedCategoryType && values.selectedCategoryType.requiresAffiliate) {
        schema.selectedAffiliate = Yup.object()
            .required('Affiliate is required for Shipper/Consignee.');
    }

    return Yup.object().shape(schema);
});

export default DetailsFormSchema;
