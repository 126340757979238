import React from "react";
import { Save, Undo } from '@material-ui/icons';

import AppButton from "../../../core/components/AppButton";
import AppCard from '../../../core/components/cards/AppCard';
import AppValidationFailure from "../../../core/components/AppValidationFailure";
import NamedColors from "./NamedColors";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { Grid } from "@material-ui/core";

const renderAnnouncementForm = (formFactor) => {

  const {
    values,
    errors,
    isValid,
    isDirty,
    resetForm,
    setFieldValue
  } = formFactor;

  const ReactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', { color: NamedColors }, { background: NamedColors }],
      ['blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link']
    ],
  };

  const handleChange = announcementNote => {
    setFieldValue('announcementNote', announcementNote);
  };

  return (
    <AppCard title="Announcement">
      <ReactQuill theme={'snow'}
        style={{ height: 250, marginBottom: 45 }}
        modules={ReactQuillModules}
        value={values.announcementNote || ''}
        onChange={handleChange} />
      <AppValidationFailure message={errors.announcementNote} />
      <Grid container spacing={2}>
        <Grid item>
          <AppButton
            type={'submit'}
            startIcon={<Save />}
            disabled={!isValid || !isDirty}>
            Save
          </AppButton>
        </Grid>
        <Grid item>
          <AppButton
            startIcon={<Undo />}
            disabled={!isDirty}
            onClick={() => resetForm()}
          >Reset</AppButton>
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default renderAnnouncementForm;