import React from 'react';
import FormFactorSlice from './FormFactorSlice';

const FormFactorGraft = (props) => {

  const {
    as: AsComponent,
    slices,
    graftMap,
    ...otherProps
  } = props;

  return (
    <FormFactorSlice slices={slices}>
      {(formFactorCtx) => {

        const allProps = {
          ...graftMap(formFactorCtx),
          ...otherProps
        };
        return (
          <AsComponent {...allProps} />
        );
      }}
    </FormFactorSlice>
  );
};

export default FormFactorGraft;