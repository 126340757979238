import StatusTypeNames from '../../../hubs/shipment/StatusTypeNames';
import Permissions from './Permissions';
import DetailsPagePermissions from './DetailsPagePermissions';
import LoadTrackingPagePermissions from './LoadTrackingPagePermissions';
import DocumentsPagePermissions from './DocumentsPagePermissions';
import FinancialPagePermissions from './FinancialPagePermissions';

const AnyElementType = Symbol('AnyElementType');
const AnyElementName = Symbol('AnyElementName');

const AlwaysActionableStatuses = [
  StatusTypeNames.New,
  StatusTypeNames.InTransit,
  StatusTypeNames.AwaitingPod,
  StatusTypeNames.Delivered
];

const PartiallyActionableStatuses = [
  StatusTypeNames.Locked,
  StatusTypeNames.InvoiceGenerated,
  StatusTypeNames.Hold,
  StatusTypeNames.PaymentsPending,
  StatusTypeNames.CustomerPaid,
  StatusTypeNames.Dispute,
  StatusTypeNames.Claim
];

const PartiallyActionablePermissions = [
  [DetailsPagePermissions, 'field', 'experienceNote'],
  [LoadTrackingPagePermissions, AnyElementType, AnyElementName],
  [DocumentsPagePermissions, AnyElementType, AnyElementName],
  [FinancialPagePermissions, AnyElementType, AnyElementName]
];



const Deciders = [

  // Initialize all Ace elements to viewable.
  (statusName, permissionSet, elementType, elementName, currentPermission) => {
    return Permissions.View;
  },

  // 1. All fields and actions are actionable: New, In Transit, Awaiting POD, Delivered
  (statusName, permissionSet, elementType, elementName, currentPermission) => {

    return AlwaysActionableStatuses.some(s => s === statusName) ?
        Permissions.Action : currentPermission;

  },

  // 2. All fields and actions are viewable: LOCKED, Hold, Invoice Generated, Payments Pending, Customer Paid, Dispute
  //    Actionable exceptions:
  //    2e1.  Load Tracking Notes
  //    2e2.  Experience Notes
  //    2e3.  Generate BOL
  //    2e4.  Generate Rate Con
  //    2e5.  All financial fields
  //    2e6.  Confirmed Locked Date
  //    2e7.  Clone shipment
  //    2e8.  All Documents actions and fields
  //    2e9.  Invoice Notes
  //    2e10. Internal Notes
  (statusName, permissionSet, elementType, elementName, currentPermission) => {

    return PartiallyActionableStatuses.some(s => s === statusName) &&
      PartiallyActionablePermissions.some(([permSet, eType, eName]) => {
        return permSet === permissionSet &&
          (eType === AnyElementType || eType === elementType) &&
          (eName === AnyElementName || eName === elementName);
      }) ?
      Permissions.Action : currentPermission;

  }


];

export default Deciders;
