import ActionTypes from '../../ActionTypes';

const helpDocuments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Support.Disposed:
      return [];
    case ActionTypes.Support.LoadHelpDocuments:
      return action.helpDocuments;
    case ActionTypes.Support.HelpDeleteDocument:
      return state.filter(sd => sd.id !== action.documentId);
    case ActionTypes.Support.HelpPublishDocument:
      return [
        ...state,
        action.document
      ]
    default:
      return state;
  }
};

export default helpDocuments;