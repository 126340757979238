import React from 'react';
import AppMuiCard from '../../../core/components/cards/AppCard';
import {Grid} from '@material-ui/core';
import AppMuiInput from '../../../core/components/inputs/AppInput';
import AppPhoneInput from "../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput";
import AppValidationFailure from "../../../core/components/AppValidationFailure";

const CarrierContactCard = (props) => {

  const {
    alternatePhone,
    errors,
    businessPhone,
    emailAddress,
    faxPhone,
    repFirstName,
    repLastName,
    setFieldValue,
    isDisabled
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value)
  };

  return (
    <AppMuiCard title={'Contact'}>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} md={6}>
            <AppMuiInput
              id={'repFirstName'}
              label={'First Name'}
              disabled={isDisabled}
              value={repFirstName || ''}
              inputProps={{
                maxLength: 30
              }}
              onChange={handleFieldChange('repFirstName')}
            />
            <AppValidationFailure
              message={errors.repFirstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppMuiInput
              id={'repLastName'}
              label={'Last Name'}
              disabled={isDisabled}
              value={repLastName || ''}
              inputProps={{
                maxLength: 30
              }}
              onChange={handleFieldChange('repLastName')}
            />
            <AppValidationFailure
              message={errors.repLastName}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AppMuiInput
            id={'emailAddress'}
            label={'Email'}
            disabled={isDisabled}
            value={emailAddress || ''}
            inputProps={{
              maxLength: 150
            }}
            onChange={handleFieldChange('emailAddress')}
          />
          <AppValidationFailure
            message={errors.emailAddress}
          />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} md={6}>
            <AppPhoneInput
              id={'businessPhone'}
              label={'Primary Phone'}
              disabled={isDisabled}
              value={businessPhone || ''}
              onChange={handleFieldChange('businessPhone')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppPhoneInput
              id={'alternatePhone'}
              label={'Secondary Phone'}
              disabled={isDisabled}
              value={alternatePhone || ''}
              onChange={handleFieldChange('alternatePhone')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} md={6}>
            <AppPhoneInput
              id={'faxPhone'}
              label={'Fax Phone'}
              disabled={isDisabled}
              value={faxPhone || ''}
              onChange={handleFieldChange('faxPhone')}
            />
          </Grid>
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

export default CarrierContactCard;