import Lodash from 'lodash';

const When = props => {

  const {
    condition
  } = props;

  if (Boolean(condition)) {
    return Lodash.isFunction(props.children) ?
      props.children() : props.children;
  }

  return null;

};

export default When;