import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const loadAssociateTotalStatement = async (shell, associateId, commissionDate) => {

  const associateTotalStatement = await shell.gateway.getPaidAndUnpaidAssociateStatement(
    associateId,
    commissionDate
  );

  return {
    type: ActionTypes.CommissionCenter.StatementLoaded,
    associateTotalStatement
  }

};

export default ActionBuilder
  .for(loadAssociateTotalStatement)
  .build();