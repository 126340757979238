import createDocs from './createDocs';
import customer from './customer';
import duplication from './duplication';
import loadBoard from './loadBoard';
import loadTrackedSetCounts from './loadTrackedSetCounts';
import modification from './modification';
import openLoad from './openLoad';

export default {
  createDocs,
  customer,
  duplication,
  loadBoard,
  loadTrackedSetCounts,
  modification,
  openLoad,
};