import {Whatshot} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import React from "react";

const HotNote = (props) => {

    const toolTip = <>
        <div className={"tgf-kendo-grid-tooltip"}>
            <strong>{props.company?.hotNote}</strong>
        </div>
    </>

    return (
      <Tooltip title={toolTip}>
          <Whatshot style={{color: "red", fontSize: "small"}}/>
      </Tooltip>
    )
}

export default HotNote
