import ActionTypes from '../../ActionTypes';

const agent = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.Persona.NotIdentified:
            return null;
        case ActionTypes.Persona.Identified:
            return action.agent;
        default:
            return state;
    }
};

export default agent;