import React, {useState} from 'react';
import {
  ArtTrack,
  DonutSmall,
  Notes,
  Restore
} from '@material-ui/icons';

import ComponentBuilder from '../../core/ComponentBuilder';
import AppSideBar from '../../core/components/AppSideBar';
import isNewCarrier from "../../hubs/carrier/actions/modification/isNewCarrier";

const CarrierUrl = '/carrier/';

const CarrierLinks = (mcNumber) => [
  {title: 'Compliance', icon: DonutSmall, key: 'compliance', href: `${CarrierUrl}${mcNumber}/compliance`},
  {title: 'Details', icon: ArtTrack, key: 'details', href: `${CarrierUrl}${mcNumber}/details`},
  {title: 'Documents', icon: Notes, key: 'documents', href: `${CarrierUrl}${mcNumber}/documents`},
  {title: 'History', icon: Restore, key: 'history', href: `${CarrierUrl}${mcNumber}/history`},
  {title: 'Notes', icon: Notes, key: 'notes', href: `${CarrierUrl}${mcNumber}/notes`}
];

const Wrapper = (props) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppSideBar menuList={props.links || []} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open}/>
  );
};

export default ComponentBuilder
  .wrap(Wrapper)
  .stateToProps((state, ownProps) => {
    const carrier = state.carrier.modification.carrier;

    const isNew = !carrier || isNewCarrier(carrier.mcNumber);

    if (isNew) {
      return {links: []};
    }

    return {links: CarrierLinks(carrier.mcNumber)};
  })
  .build();