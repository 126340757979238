import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const loadRoles = async (shell) => {

  const roles = await shell.gateway.getAllRoles();

  return {
    type: ActionTypes.Persona.RolesLoaded,
    roles
  }
};

export default ActionBuilder
  .for(loadRoles)
  .build();