import ActionTypes from '../../../ActionTypes';

const firstShipments = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Customer.RetrievedCustomerFirstShipment:
      return mergeRetrieveResults(state, action);
    case ActionTypes.Shipment.Customer.ClearedCustomerFirstShipment:
      return removeRetrieveResults(state, action);
    default:
      return state;
  }
};

const mergeRetrieveResults = (state, action) => {

  const {
    customerId,
    firstShipment
  } = action;

  return Object.assign({}, state, {
    [customerId]: Object.assign({}, firstShipment)
  });

};

const removeRetrieveResults = (state, action) => {

  const {
    customerId
  } = action;

  return Object.assign({}, state, {
    [customerId]: null
  });

};

export default firstShipments;