import * as Redux from 'redux';

import shipments from './shipments';
import shipmentCount from './shipmentCount';
import associateCommissionRates from './associateCommissionRates';
import associateGrossMarginBreakdown from './associateGrossMarginBreakdown';
import associateCommissionBreakdown from './associateCommissionBreakdown';
import monthlyAdjustments from './monthlyAdjustments';
import monthlyAdjustmentCount from './monthlyAdjustmentCount';

export default Redux.combineReducers({
  shipments,
  shipmentCount,
  associateCommissionRates,
  associateGrossMarginBreakdown,
  associateCommissionBreakdown,
  monthlyAdjustments,
  monthlyAdjustmentCount
});