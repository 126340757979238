import * as Redux from 'redux';

import associates from './associates';
import monthlyAssociatePayments from './monthlyAssociatePayments';
import monthlyAssociatePaymentsCount from './monthlyAssociatePaymentsCount';

export default Redux.combineReducers({
  associates,
  monthlyAssociatePayments,
  monthlyAssociatePaymentsCount
});