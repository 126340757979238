import ActionTypes from '../../ActionTypes';

const stateProvinces = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.StateProvinces.Loaded:
      return action.stateProvinces;
    default:
      return state;
  }
};

export default stateProvinces;