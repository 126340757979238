import ActionTypes from "../../../ActionTypes";

const shipperContacts = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.CreateDocs.Disposed:
      return [];
    case ActionTypes.Shipment.CreateDocs.ShipmentLoaded:
      return action.shipperContacts;
    default:
      return state;
  }
};

export default shipperContacts;