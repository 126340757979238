import React from 'react';
import Core from '@atomos/core';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import {Description, Edit} from '@material-ui/icons';
import AppIconButton from '../../../../../core/components/AppButton/AppIconButton';

import AppCard from '../../../../../core/components/cards/AppCard';
import AppTooltip from "../../../../../core/components/AppTooltip";
import AppMuiButton from '../../../../../core/components/AppButton';
import AppPercentText from '../../../../../core/components/text/AppPercentText';

const PayConfigurationCard = (props) => {

  const {
    editButtonDisabled,
    onEditConfigurationClick,
    paymentPercentage,
    paymentType,
    payStatementDocument,
    showFeeInformation
  } = props;

  const cardActions = [
    onEditConfigurationClick && <AppIconButton key={'edit'} Icon={Edit} tooltip="Edit Configuration" onClick={onEditConfigurationClick} disabled={editButtonDisabled} />
  ];

  const payPercentValue = Core.Utils.isNumber(paymentPercentage) ?
    paymentPercentage : 'N/A';

  const payTypeText = paymentType || 'N/A';

  return (
    <AppCard title="Pay Configuration" action={cardActions}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Payment Type</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">{payTypeText}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Percentage</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" color="primary">
            <AppPercentText value={payPercentValue} minFractionDigits={1} maxFractionDigits={1} />
          </Typography>
        </Grid>
        {
          showFeeInformation && <React.Fragment>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Minimum TGF Fee</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" color="primary"><AppTooltip title={'For all shipments, Tallgrass collects this minimum fee for every $1000 of carrier cost.'}><span>$10.00</span></AppTooltip></Typography>
            </Grid>
          </React.Fragment>
        }
        {
          payStatementDocument &&
          <React.Fragment>
            <Grid item xs={12} style={{marginTop: '8px'}}>
              <Typography variant="h4">Pay Statement:</Typography>
            </Grid>
            <Grid item xs={12}>
              <AppMuiButton
                component={'a'}
                target={'_blank'}
                href={`/file/associate/${payStatementDocument.associateId}/document/${payStatementDocument.id}`}
                startIcon={<Description />}
                >
                {payStatementDocument.filename}
              </AppMuiButton>
            </Grid>
          </React.Fragment>
        }
      </Grid>
    </AppCard>
  );
};

PayConfigurationCard.propTypes = {
  paymentPercentage: PropTypes.number,
  paymentType: PropTypes.string,
  onUploadDocumentClick: PropTypes.func,
  onEditConfigurationClick: PropTypes.func
};

export default PayConfigurationCard;