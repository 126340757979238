import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveAssociateNote = async (shell, note) => {

  const associateNote = await shell.gateway.saveAssociateNote(note);

  return {
    type: ActionTypes.Admin.ManageUsers.AssociateNoteSaved,
    associateNote
  };

};

export default ActionBuilder
  .for(saveAssociateNote)
  .build();