import ActionTypes from '../../../ActionTypes';

const convertError = error => {
  return {
    message: error.message,
    stack: error.stack
  };
};

const uncaughtActionErrors = (state = [], action) => {

  switch (action.type) {
    case ActionTypes.Sys.UncaughtActionError:
      return state.concat({
        error: convertError(action.error),
        processName: action.processName
      });
    default:
      return state;
  }
};

export default uncaughtActionErrors;