import ActionTypes from '../../ActionTypes';

const authenticationStatus = (state = null, action) => {
  switch(action.type) {
    case ActionTypes.Persona.Authenticating:
    case ActionTypes.Persona.Invalidated:
      return null;
    case ActionTypes.Persona.Authenticated:
      return action.status;
    default:
      return state;
  }
};

export default authenticationStatus;