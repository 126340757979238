import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: theme.palette.tgfGrey.light
        },
        height: 50
    },
    listItemIcon: {
        maxWidth: 40,
        minWidth: 40
    }
}));

const SideMenuSingle = ({ data, currentLocation, open }) => {
    const classes = useStyles();
    const CompIcon = data.icon;

    return (
        <ListItem button
            component={Link}
            to={data.href || ''}
            selected={currentLocation}
            className={classes.root}
        >
           {CompIcon && <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title={data.title}><CompIcon /></Tooltip>
            </ListItemIcon>}
           {!CompIcon && !open && <ListItemIcon className={classes.listItemIcon}>
                <Tooltip title={data.title}><NavigateNext /></Tooltip>
            </ListItemIcon>}
            {open && <ListItemText primary={data.title} />}
        </ListItem>
    )
};

export default SideMenuSingle;