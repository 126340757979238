import { Button, Grid } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import * as yup from "yup";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import AppCurrencyText from "../../../../core/components/text/AppCurrencyText";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";
import TgfTextBox from "../../../../core/kendo/form/inputs/TgfTextBox";
import "../../../../core/kendo/form/tgf-form.scss";
import useFieldValidator from "../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../crm/components/inputs/FieldError";

const DatItsPostedText = ({ openLoad }) => {
    const datAmountUsd = openLoad.datPostedAmountUsd ?
        <AppCurrencyText value={openLoad.datPostedAmountUsd} /> : "No Amount";
    const datPosted = openLoad.extDatLoadPostingId ? <span>Posted @ {datAmountUsd}</span> : "Not Posted";
    const tsAmountUsd = openLoad.truckstopPostedAmountUsd ?
        <AppCurrencyText value={openLoad.truckstopPostedAmountUsd} /> : "No Amount";
    const tsPosted = openLoad.extTruckstopPostingId ? <span>Posted @ {tsAmountUsd}</span> : "Not Posted";
    return (
        <>
            <p style={{ color: "blue", margin: 4, marginTop: 12 }}>DAT: {datPosted}</p>
            <p style={{ color: "red", margin: 4 }}>ITS: {tsPosted}</p>
        </>
    );
};


const EditShipment = (props) => {

    const defaultValues = {
        commodity: props.openLoad?.commodity ?? null,
        targetRate: props.openLoad?.targetRate != null ? parseFloat(props.openLoad?.targetRate) : null,
        callScriptNotes: props.openLoad?.callScriptNotes ?? null,
        internalNotes: props.openLoad?.internalNotes ?? null
    };

    const [formValues, setFormValues] = useState(defaultValues);
    //// for field validation
    const [fieldErrors, setFieldErrors] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [enablePost, setEnablePost] = useState(false);

    const autoFocusRef = React.useRef(null);

    useEffect(() => {
        if (isEditMode) {
            autoFocusRef.current.element.focus();
        }
    }, [isEditMode]);

    const personalDispatch = useDispatch();

    let editShipmentSchema = yup.object().shape({
        targetRate: yup.number().nullable().max(99999999).transform((value, originalValue) => (String(originalValue).trim() === "" ? null : value)),
    });

    useFieldValidator(editShipmentSchema, formValues, setFieldErrors);

    useEffect(() => {
        editShipmentSchema.validate(formValues)
            .then(() => setEnablePost(true))
            .catch(() => setEnablePost(false));
    }, [formValues]);

    const handleEditModeCancel = () => {
        const resetValues = {
            commodity: props.openLoad?.commodity ?? '',
            targetRate: props.openLoad?.targetRate != null ? parseFloat(props.openLoad?.targetRate) : null,
            callScriptNotes: props.openLoad?.callScriptNotes ?? '',
            internalNotes: props.openLoad?.internalNotes ?? ''
        };

        setFormValues(resetValues);

        setIsEditMode(false);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.props ? e.target.props.name
            : e.target.element.current ? e.target.element.current.name :
                e.target.element ? e.target.element.name : console.log('error: missing name');
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setIsEditMode(false);
            personalDispatch(window.shell.actions.sys.processStart('saveUncoveredShipmentDetails'));
            await window.shell.gateway.saveUncoveredShipmentDetails(props.openLoad.bolNumber, formValues);
            if (props.onDialogUpdate) {
                props.onDialogUpdate();
            }
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'Shipment details successfully saved' }));
        } catch (e) {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'Shipment details update failed', color: 'error' }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveUncoveredShipmentDetails'));
        }
    };

    const handleAutoFillClick = () => {

        const getValue = (val, text) => {
            const isEmpty = (val) => {
                return val === null || val === "";
            };
            return !isEmpty(val) ? " " + val + text : '';
        };

        const getDate = (val, text) => {
            const isEmpty = (val) => {
                return val === null || val === "";
            };
            return !isEmpty(val) ? ' ' + text + new Date(val).toMoment().format('L') : '';
        };

        setFormValues({
            ...formValues,
            callScriptNotes: (`${getValue(props.openLoad.totalWeight, ' lbs.')}${getValue(formValues.commodity, '.')}${getDate(props.openLoad.pickupDate, 'Pickup ')}${getValue(props.openLoad.pickupTime, '.')}${getDate(props.openLoad.deliveryDate, 'Delivery ')}${getValue(props.openLoad.deliveryTime, '.')}`).trim()
        });
    };

    const enterEditMode = () => {
        setIsEditMode(true);
    };

    useEffect(() => {
        const { commodity, targetRate, callScriptNotes, internalNotes } = props.openLoad; // additional shipment values
        if (!commodity && !targetRate && !callScriptNotes && !internalNotes) setIsEditMode(true);
    }, []);

    return (
        <>
            <p style={{ color: "red" }}>The fields below are optional for your visual reference.</p>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Label editorId={"commodity"}>Commodity</Label>
                        <TgfTextBox
                            id={"commodity"}
                            name={"commodity"}
                            value={formValues.commodity}
                            onChange={handleInputChange}
                            style={{ color: "#008800" }}
                            width={"100%"}
                            maxLength={50}
                            disabled={!isEditMode}
                            ref={autoFocusRef} //set focus to the modal
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Label editorId={"targetRate"}>Target Rate</Label>
                        <NumericTextBox
                            id={"targetRate"}
                            name={"targetRate"}
                            onChange={handleInputChange}
                            value={formValues.targetRate}
                            width={"100%"}
                            format={"c2"}
                            disabled={!isEditMode}
                            spinners={false}
                        />
                        {fieldErrors.targetRate && <FieldError>{fieldErrors.targetRate}</FieldError>}
                    </Grid>
                    <Grid item xs={3}>
                        <DatItsPostedText openLoad={props.openLoad} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <AppIconButton
                                    disabled={!isEditMode}
                                    Icon={Description}
                                    tooltip={"auto fill call script"}
                                    onClick={handleAutoFillClick}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <span
                                    style={{ color: "#008800" }}>Clicking this icon will generate a formatted script into the Call Script Notes below based off the green-highlighted information above.<br /> You can still edit/change the call script to your liking before saving.</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Label editorId={"callScriptNotes"}>Call Script Notes (200 max characters)</Label>
                        <TgfTextArea
                            id={"callScriptNotes"}
                            name={"callScriptNotes"}
                            onChange={handleInputChange}
                            value={formValues.callScriptNotes}
                            maxLength={200}
                            rows={3}
                            style={{ fontWeight: "bold" }}
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Label editorId={"internalNotes"}>Internal Notes (200 max characters)</Label>
                        <TgfTextArea
                            id={"internalNotes"}
                            name={"internalNotes"}
                            onChange={handleInputChange}
                            value={formValues.internalNotes}
                            maxLength={200}
                            rows={3}
                            style={{ fontWeight: "bold", color: "#FF0000" }}
                            disabled={!isEditMode}
                        />
                    </Grid>

                    {!isEditMode &&
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" type="submit" onClick={() => enterEditMode()}>
                                Edit
                            </Button>
                        </Grid>
                    }

                    {isEditMode &&
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" type="submit" disabled={!enablePost}>
                                Submit
                            </Button>
                            <span> </span>
                            <Button variant="contained" color="primary" type="submit" onClick={() => handleEditModeCancel()}>
                                Cancel
                            </Button>
                        </Grid>
                    }
                </Grid>
            </form>
        </>
    );
};

export default EditShipment;
