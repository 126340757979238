import ActionTypes from "../../ActionTypes";

const associateCommissionRate = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.CommissionCenter.Disposed:
      return null;
    case ActionTypes.CommissionCenter.PayStatementLoaded:
      return action.document;
    default:
      return state;
  }
};

export default associateCommissionRate;