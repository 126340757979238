import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppDialog from "../../../../../core/components/AppDialog/AppMuiDialog";
import "./QaManualReviewDialog.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import TeraCrunchDialogTable from "../../includes/TeraCrunchDialogTable";
import RemitAddressPicker from "./RemitAddressPicker";
import FactoringCompanySelectorNoFormFactor from "./FactoringCompanySelectorNoFormFactor";

const AutoProcessedInvolvedDocumentsDialog = (props) => {
  const {
    onAccept,
    onReject,
    handleCloseDialog,
    listAutomationDocuments,
    activeRecord,
    getFactoringCompanies,
  } = props;

  const [dropDownValue, setDropDownValue] = useState("");
  const [documents, setDocuments] = React.useState(null);
  const [factoringCoSelectorIsOpen, setFactoringCoSelectorIsOpen] =
    useState(false);

  useEffect(() => {
    if (!documents) {
      listAutomationDocuments(activeRecord.bolNumber).then((data) => {
        setDocuments(data.documents);
      });
    }
  }, []);

  const handleFactoringCoSelectShow = () => {
    setFactoringCoSelectorIsOpen(!factoringCoSelectorIsOpen);
  };

  const handleChange = (event) => {
    setDropDownValue(event.target.value);
  };


  const handleSaveClick = async () => {
    const tempRecord = activeRecord;
    if (dropDownValue === "accept") {
      handleCloseDialog();
      await onAccept(tempRecord, remitFormValues);
    } else if (dropDownValue === "reject") {
      handleCloseDialog();
      await onReject(tempRecord.id);
    }
  };

  const handleOpenDownloadFileClick = (document, shouldOpen) => {
    const a = window.document.createElement("a");

    shouldOpen ? (a.target = "_blank") : (a.download = document.filename);

    a.href = `/api/automation/${document.id}/document`;
    a.click();
    a.remove();
  };

  const calculateDropDownStyle = () => {
    if (dropDownValue === "accept") {
      return "green";
    } else if (dropDownValue === "reject") {
      return "red";
    }
  };

  const dialogActions = [
    {
      title: "Save",
      action: handleSaveClick,
    },
    {
      title: "Cancel",
      action: handleCloseDialog,
    },
  ];

  const [remitFormValues, setRemitFormValues] = useState({
    remitName: "",
    remitAddress: "",
    remitAddress2: "",
    remitCity: "",
    remitState: "",
    remitZip: "",
  });

  const populateRemitFormValues = async (company) => {
    await setRemitFormValues((prev) => {
      return {
        remitName: company.companyName ? company.companyName : "",
        remitAddress: company.companyAddress1 ? company.companyAddress1 : "",
        remitAddress2: company.companyAddress2 ? company.companyAddress2 : "",
        remitCity: company.companyCity ? company.companyCity : "",
        remitState: company.companyStateProvince ? company.companyStateProvince : "",
        remitZip: company.companyPostalCode ? company.companyPostalCode : "",
      };
    });
  };

  const handleRemitFieldChange = async (e) => {
    const nextFormState = {
      ...remitFormValues,
      [e.target.name]: e.target.value,
    };
    await setRemitFormValues(nextFormState);
  };

  return (
    <AppDialog
      title="QA Review - Auto-process Or Fail Dialog"
      width="md"
      open={true}
      actionButtons={dialogActions}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Grid container direction={"column"}>
            <Grid item>
              <p className={"qa-dialog-shipment-p"}>
                TeraCrunch Shipment #{" "}
                <Link
                  to={`/shipment/${activeRecord.bolNumber}/details`}
                  target={"_blank"}
                >
                  {activeRecord.bolNumber}
                </Link>
              </p>
            </Grid>
            <Grid item>
              <p style={{ margin: 0 }}>Received Documents</p>
            </Grid>
            {documents &&
              documents.map((doc) => (
                <Grid item xs={12} key={doc.id}>
                  <a
                    onClick={handleOpenDownloadFileClick.bind(this, doc, true)}
                  >
                    {doc.filename}
                  </a>
                </Grid>
              ))}
          </Grid>
        </Grid>
        {activeRecord && (
          <Grid item xs={9}>
            <TeraCrunchDialogTable activeRecord={activeRecord} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container direction={"column"} alignContent={"center"}>
            <Grid item xs={6}>
              <RemitAddressPicker
                activeRecord={activeRecord}
                handleFactoringCoSelectShow={handleFactoringCoSelectShow}
                handleRemitFieldChange={handleRemitFieldChange}
                values={remitFormValues}
              />
            </Grid>
            <FactoringCompanySelectorNoFormFactor
                isOpen={factoringCoSelectorIsOpen}
                getFactoringCompanies={getFactoringCompanies}
                onChange={populateRemitFormValues}
                handleFactoringCoSelectShow={handleFactoringCoSelectShow}
              />
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel className="qa-review-dialog-label">
                  Accept or Reject invoice
                </InputLabel>
                <Select
                  style={{
                    color: calculateDropDownStyle(),
                    backgroundColor: "white",
                  }}
                  disableUnderline
                  className={"qa-review-dialog-select-input"}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dropDownValue}
                  label="Accept or Reject invoice"
                  onChange={handleChange}
                >
                  <MenuItem style={{ color: "green" }} value={"accept"}>
                    Accept and Auto-process this invoice
                  </MenuItem>
                  <MenuItem style={{ color: "red" }} value={"reject"}>
                    Reject this invoice and send it to the QA Failed Queue
                  </MenuItem>

                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default AutoProcessedInvolvedDocumentsDialog;
