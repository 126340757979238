import ActionTypes from "../../../ActionTypes";

const shipmentInvoice = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.Disposed:
      return null;
    case ActionTypes.Shipment.Modification.InvoiceLoaded:
    case ActionTypes.Shipment.Modification.InvoiceSaved:
      return action.invoice;
    default:
      return state;
  }
};

export default shipmentInvoice;