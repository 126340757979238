import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 18,
    padding: 0,
    backgroundColor: theme.palette.white,
    '& .MuiInputLabel-formControl': {
      marginTop: -18
    },
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.fieldBorder,
      padding: '0 0 0 10px'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      //   padding: '8px 4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.tgfGrey.fieldBorder,
    },
  },
  rootNoLabel: {
    padding: 0,
    '& .MuiInputLabel-formControl': {
      marginTop: -18
    },
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.fieldBorder,
      padding: '0 0 0 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.tgfGrey.fieldBorder,
    },
  },
  inputRoot: {
    padding: 0
  },
  input: {
    width: '100%',
    position: 'relative',
    borderRadius: 4,
    '& legend': {
      maxWidth: 0
    }
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}));

const AppDropdown = (props) => {

  const {
    label = null,
    field,
    options,
    fieldIndex,
    disabled = false,
    disableClearable = true,
    defaultValue = null,
    ...otherProps
  } = props;

  const classes = useStyles();

  return (
    <Autocomplete
      {...otherProps}
      disabled={disabled}
      disableClearable={disableClearable}
      defaultValue={defaultValue}
      options={options}
      className={disabled ? classes.disabled : classes.root}
      classes={{
        root: label ? classes.root : classes.rootNoLabel,
      }}
      autoHighlight
      getOptionLabel={(option) => option[field].toString()}
      getOptionSelected={(option, selectedOption) => option[fieldIndex] === selectedOption[fieldIndex]}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          inputProps={{
            ...params.inputProps
          }}
          className={classes.input}
        />
      )}
    />
  );
}

AppDropdown.propTypes = {
  field: PropTypes.string.isRequired,
  fieldIndex: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default AppDropdown;