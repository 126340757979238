import ActionTypes from '../../../ActionTypes';

const companyDisablementHistoriesCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.CompanyDisablementHistoryLoaded:
      return action.count;
    case ActionTypes.AddressBook.Modification.Disposed:
      return 0;
    default:
      return state;
  }
};

export default companyDisablementHistoriesCount;