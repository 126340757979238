import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import { CheckCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    color: theme.palette.tgfGreen.main
  }
}));

const OnFileCell = (classes) => ({ rowData: companyRecord }) =>
  companyRecord.companyCreditAppOnFile ?
    <CheckCircle className={classes.checkIcon}/> : null;

const MissingCreditAppListing = (props) => {

  const {
    searchTerm,
    ...otherProps
  } = props;

  const classes = useStyles();

  const GridColumns = [
    {
      title: 'Customer',
      field: 'companyName',
      styles: {},
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.companyName || '[BLANK]'])
    },
    {
      title: 'First Name',
      field: 'associateFirstName',
      styles: {},
      dataType: 'string'
    },
    {
      title: 'Last Name',
      field: 'associateLastName',
      styles: {},
      dataType: 'string'
    },
    {
      title: "On File?",
      field: "companyCreditAppOnFile",
      styles: {
        width: 75,
        textAlign: 'center'
      },
      dataType: 'component',
      component: OnFileCell(classes)
    },
    {
      title: "Received Date",
      field: "companyCreditAppReceivedDate",
      styles: {
        width: 100,
        textAlign: 'center'
      },
      dataType: 'date'
    },
    {
      title: "Credit Limit",
      field: "companyCreditLimit",
      styles: {
        width: 100,
        textAlign: 'center'
      },
      dataType: 'currency'
    },
    {
      title: "First Ship.",
      field: "firstShipmentDate",
      styles: {
        width: 100,
        textAlign: 'center'
      },
      dataType: 'date'
    },
    {
      title: "Last Ship.",
      field: "lastShipmentDate",
      styles: {
        width: 100
      },
      dataType: 'date'
    }
  ];

  return (
    <AppDenseGrid
      columns={GridColumns}
      {...otherProps}
    />
  )
}

export default MissingCreditAppListing;