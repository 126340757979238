import React from 'react';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import { Grid } from '@material-ui/core';
import { LibraryBooks as LibraryBooksIcon, Store as StoreIcon } from '@material-ui/icons';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";

const UseCarrierAddressTooltip = 'Remit to carrier profile address.';
const UseFactoringCompanyAddressTooltip = 'Select a factoring company address.';

const RemitAddress = (props) => {

  const {
    remitToName,
    remitToAddress1,
    remitToAddress2,
    remitToCity,
    remitToStateProvince,
    remitToPostalCode,
    shipmentFinalized,
    onFieldChange,
    onUseCarrierAddressClick,
    onSelectFactoringCompanyAddressClick,
    values
  } = props;

  const handleFieldChange = (key) => (e) =>
    onFieldChange(key, e.target.value);

  return (
    <AppMuiCard
      title="Remit To Address"
      action={[
        <AppIconButton
          color={'primary'}
          key={'carrierAddress'}
          disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
          Icon={LibraryBooksIcon}
          tooltip={UseCarrierAddressTooltip}
          onClick={onUseCarrierAddressClick}/>,
        <AppIconButton
          color={'primary'}
          key={'companyAddress'}
          disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
          Icon={StoreIcon}
          tooltip={UseFactoringCompanyAddressTooltip}
          onClick={onSelectFactoringCompanyAddressClick}/>
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <label htmlFor={'invoicedAmount'}>
                Remit Name
              </label>
            </Grid>
            <Grid item xs={12}>
              <AppInput
                id={'name'}
                labe={'Remit Name'}
                type={'text'}
                disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
                value={remitToName || ''}
                inputProps={{
                  maxLength: 300
                }}
                onChange={handleFieldChange('remitToName')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AppInput
            id={'address1'}
            label={'Address'}
            type={'text'}
            disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
            value={remitToAddress1 || ''}
            inputProps={{
              maxLength: 255
            }}
            onChange={handleFieldChange('remitToAddress1')}
          />
        </Grid>
        <Grid item xs={12}>
          <AppInput
            id={'address2'}
            type={'text'}
            disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
            value={remitToAddress2 || ''}
            onChange={handleFieldChange('remitToAddress2')}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <AppInput
                id={'city'}
                label={'City'}
                type={'text'}
                disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
                value={remitToCity || ''}
                inputProps={{
                  maxLength: 30
                }}
                onChange={handleFieldChange('remitToCity')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                id={'stateProvince'}
                label={'State/Province'}
                type={'text'}
                disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
                value={remitToStateProvince || ''}
                inputProps={{
                  maxLength: 50
                }}
                onChange={handleFieldChange('remitToStateProvince')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                id={'postalCode'}
                label={'Postal Code'}
                type={'text'}
                disabled={shipmentFinalized || values.shipment.hasOwnProperty('voidId')}
                value={remitToPostalCode || ''}
                inputProps={{
                  maxLength: 15
                }}
                onChange={handleFieldChange('remitToPostalCode')}
              />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

const styles = (theme) => ({
  headerButton: {
    padding: 0,
    marginLeft: 8
  },
});

export default ComponentBuilder
  .wrap(RemitAddress)
  .withStyles(styles)
  .build();
