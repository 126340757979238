const AuthorityStatuses = {
  Active: 'A',
  Inactive: 'I',
  None: 'N',
  getStatusName(statusCode) {
    return Object.keys(AuthorityStatuses)
      .find(k => AuthorityStatuses[k] === statusCode);
  }
};

export default AuthorityStatuses;