import React, {useEffect, useState} from "react";

const useMinWidth = (gridColumns, makeColumn) =>{
    const [applyMinWidth, setApplyMinWidth] = React.useState(false);
    const [mappedGridColumns, setMappedGridColumns] = useState(gridColumns.map(c => makeColumn(c)));

    const getMinGridWidth = () => {
        let minGridWidth = 0;
        gridColumns.map((item) => {
            if (item.width !== undefined) {
                minGridWidth += item.width;
            }
            if(item.minWidth !== undefined) {
                minGridWidth += item.minWidth;
            }
        });

        return minGridWidth + 20;
    }

    const minGridWidth = getMinGridWidth();

    const handleResize = () => {
        const grid = document.querySelector(".k-grid");
        if(!grid) return;
        if (grid.offsetWidth < minGridWidth) {
            setApplyMinWidth(true);
        } else if (grid.offsetWidth > minGridWidth) {
            setApplyMinWidth(false);
        }
    }

    useEffect(() => {
        setMappedGridColumns(gridColumns.map(c => makeColumn(c)));
    }, [gridColumns]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
    }, []);

    useEffect(() => {
        setMappedGridColumns(gridColumns.map(c => {
            if(c.minWidth && applyMinWidth) c.width = c.minWidth;
            return makeColumn(c);
        }));

    }, [applyMinWidth]);

    return mappedGridColumns;
}

export default useMinWidth;
