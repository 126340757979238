import dispose from './dispose';
import disposeHistory from './disposeHistory';
import loadCarrier from './loadCarrier'
import loadCarrierCompliance from './loadCarrierCompliance';
import loadCarrierNotes from './loadCarrierNotes';
import loadHistory from './loadHistory';
import loadNewCarrier from './loadNewCarrier'
import loadRmisCarrier from "./loadRmisCarrier";
import loadRmisCarrierContacts from './loadRmisCarrierContacts';
import saveCarrier from './saveCarrier';
import saveCarrierNote from './saveCarrierNote';
import deleteCarrierDocument from './deleteCarrierDocument';
import loadCarrierDocuments from './loadCarrierDocuments';
import publishCarrierDocument from './publishCarrierDocument';

export default {
  dispose,
  deleteCarrierDocument,
  loadCarrierDocuments,
  disposeHistory,
  loadCarrier,
  loadCarrierCompliance,
  loadCarrierNotes,
  loadHistory,
  loadNewCarrier,
  loadRmisCarrier,
  loadRmisCarrierContacts,
  publishCarrierDocument,
  saveCarrier,
  saveCarrierNote
};
