import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import LeftNav from '../LeftNav';
import AppDenseGrid from '../../../core/components/AppDenseGrid/AppDenseGrid';
import {Grid} from '@material-ui/core';
import Business from '@tgf-crm/business';
import {Block} from '@material-ui/icons';
import AppIconButton from '../../../core/components/AppButton/AppIconButton';
import CrmAssociateDropDown from '../../../crm/components/CrmAssociateDropDown';
import CrmTypedAddressBookListingComboBox from '../../../crm/components/CrmTypedAddressBookListingComboBox';
const LoadProcessName = 'Admin.AutomatedInvoiceQueuePage.Load';

const LoadRecords = async (filter, offset, limit, sort) => {
  return await window.shell.gateway.getAutomatedInvoicingWorkflowQueue(filter, offset, limit, sort);
};

const HaltInvoicingCell = ({ rowData: record }) => {
  return (
    <AppIconButton
      Icon={Block}
      tooltip={'Some magical halting of the invoicing.'}
    />
  );
}

const AutomatedInvoiceQueuePage = (props) => {

  const gridColumns = [
    {
      field: 'shipmentBolNumber',
      title: 'Ship #',
      styles: {
        width: '10%'
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.shipmentBolNumber}/details`, record.shipmentBolNumber])
    },
    {
      field: 'associateFirstName',
      title: 'Rep. First',
      styles: {
        width: '10%'
      },
      dataType: 'string'
    },
    {
      field: 'associateLastName',
      title: 'Rep. Last',
      styles: {
        width: '10%'
      },
      dataType: 'string'
    },
    {
      title: 'Customer Name',
      field: 'companyName',
      styles: {
        width: '35%'
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.companyName])
    },
    {
      field: 'lockingAssociateFirstName',
      title: 'Lock Rep. First',
      styles: {
        width: '10%'
      },
      dataType: 'string'
    },
    {
      field: 'lockingAssociateLastName',
      title: 'Lock Rep. Last',
      styles: {
        width: '10%'
      },
      dataType: 'string'
    },
    {
      field: 'shipmentLockedDate',
      title: 'Lock Date',
      styles: {
        width: '10%'
      },
      dataType: 'date'
    },
    {
      field: 'adjustedCustomerCost',
      title: 'Cust. Cost',
      styles: {
        width: '10%'
      },
      dataType: 'currency'
    },
    {
      field: 'scheduledSendDate',
      title: 'Sched. Send',
      styles: {
        width: '10%'
      },
      dataType: 'date'
    },
    {
      field: '',
      title: '',
      styles: {
        width: '5%'
      },
      dataType: 'component',
      component: HaltInvoicingCell
    }
  ];

  const {
    loadRecords,
    shipmentStatusTypes
  } = props;

  const holdStatusType = shipmentStatusTypes.find(st => st.name === Business.Shipment.ShipmentStatusNames.Hold);
  const statusFilter = {
    shipmentStatusId: holdStatusType.id
  };
  const deliveryMethodFilter = {
    companyInvoiceDeliveryMethodTypeId: Business.Company.CompanyInvoiceDeliveryMethodTypes.Email.Id
  };

  const [recordCount, setRecordCount] = React.useState(0);
  const [filter, setFilter] = React.useState({});
  const [records, setRecords] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['shipmentBolNumber', 'desc']]);

  React.useEffect(() => {
    loadRecords(filter, offset, limit, sort)
      .then(({ shipments, count }) => {
        setRecords(shipments);
        setRecordCount(count);
      })
      .catch((error) => console.error(error));
  }, [filter, offset, limit, sort, loadRecords]);

  const handleChangePage = (e, page) => {
    setOffset(page * limit);
  };
  const handleSortChange = (column) => {

    const [existingColumn, existingOrder] = sort[0];

    const order = column === existingColumn ?
      existingOrder === 'asc' ? 'desc' : 'asc' :
      'asc';

    setSort([[column, order]]);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleAssociateChange = (associate) => {
    setOffset(0);
    setFilter({
      ...filter,
      associateId: associate?.id || undefined,
      ...statusFilter,
      ...deliveryMethodFilter
    });
  };

  const handleCustomerChange = (customer) => {
    setOffset(0);
    setFilter({
      ...filter,
      companyId: customer?.companyId || undefined,
      ...statusFilter,
      ...deliveryMethodFilter
    });
  };

  return (
    <FullWidthLayout title={'Automatic Invoicing'} SideNav={LeftNav}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <label htmlFor="associate">Associate</label>
          <CrmAssociateDropDown
            id={'associate'}
            onChangeAssociate={handleAssociateChange}
            autoSelectFirst={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <label htmlFor="search">Customer:</label>
          <CrmTypedAddressBookListingComboBox
            types={[CrmTypedAddressBookListingComboBox.Customer]}
            value={null}
            onChange={handleCustomerChange}
            popupIcon={' '}
          />
        </Grid>
        <Grid item xs={12}>
          <AppDenseGrid
            columns={gridColumns}
            data={records}
            count={recordCount}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            rowsPerPage={limit}
            page={offset / limit}
            onSort={handleSortChange}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(AutomatedInvoiceQueuePage)
  .stateToProps((state, ownProps) => ({
    shipmentStatusTypes: state.support.shipmentStatusTypes
  }))
  .dispatchToProps((shell, dispatch, getState) => ({
    loadRecords: async (filter, offset, limit, sort) => {
      dispatch(shell.actions.sys.processStart(LoadProcessName));
      const results = await LoadRecords(filter, offset, limit, sort);
      dispatch(shell.actions.sys.processComplete(LoadProcessName));
      return results;
    }
  }))
  .build();