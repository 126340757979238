import React from 'react';
import Grid from '@material-ui/core/Grid';

import AppCard from '../../../../../core/components/cards/AppCard';
import AppDropdown from '../../../../../core/components/dropdowns/AppDropdown';
import AppMonthYear from '../../../../../core/components/inputs/AppDatePicker/AppMonthYear';
import CrmAssociateDropDown from "../../../../components/CrmAssociateDropDown";

const DataSelectorCard = ({onSelectionChange, ...otherProps}) => {

  const {
    payPeriodOptions,
    onMonthYearChange,
    onPayPeriodChange,
    onAssociateChange,
    monthYearSelectedDate,
    payPeriod
  } = otherProps;

  const dateSetPayPeriod = (e, payPeriodValue) => {
    onPayPeriodChange(payPeriodValue);
  };

  const dateSetMonthYear = (dateValue) => {
    onMonthYearChange(dateValue);
  };

  return (<AppCard title={'Statement Filter'}>
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <label htmlFor="monthYear">Month/Year:</label>
        <AppMonthYear
          id="monthYear"
          openTo="year"
          views={["year", "month"]}
          inputVariant="outlined"
          value={monthYearSelectedDate}
          onChange={dateSetMonthYear}
        />
      </Grid>
      <Grid item>
        <AppDropdown
          label={'Pay Period'}
          id={'payPeriod'}
          field={'title'}
          fieldIndex={'value'}
          options={payPeriodOptions}
          value={payPeriod || null}
          onChange={dateSetPayPeriod} />
      </Grid>
      <Grid item>
        {
          onAssociateChange &&
          <React.Fragment>
            <label htmlFor="associate">Associate</label>
            <CrmAssociateDropDown
              id={'associate'}
              disableClearable={true}
              onChangeAssociate={onAssociateChange}
            />
          </React.Fragment>
        }
      </Grid>
    </Grid>
  </AppCard>
  )
};

export default DataSelectorCard;