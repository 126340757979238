/**
 * Retrieves a customer shipments search entry
 * from the state tree.
 * @param {object} state - The full state tree.
 * @param {string} searchId - The unique search identifier.
 * @return {{
 *   searchId: string,
 *   customerId: number,
 *   fromDate: null|Date,
 *   toDate: null|Date,
 *   bolNumber: null|number,
 *   filterToAging: boolean,
 *   shipments: object[],
 *   count: number,
 *   pageSize: number,
 *   pageNumber: number,
 *   sort: Array.<string[]>
 * }}
 */
const selectCustomerShipmentsSearch = (state, searchId) => {
  const {
    [searchId]: searchEntry
  } = state.shipment.customer.shipmentSearches;
  return searchEntry;
};

export default selectCustomerShipmentsSearch;