import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const markInvoicesGenerated = async (shell, bolNumbers) => {

  await shell.gateway.markInvoicesGenerated(bolNumbers);

  return {
    type: ActionTypes.Dashboard.MarkedInvoicesGenerated
  };

};

export default ActionBuilder
  .for(markInvoicesGenerated)
  .build();