/**
 * Helper method to merge styles together for cells in the table.
 * @param {object} [styles] - Optional base styles.
 * @param {object} [overrideStyles] - Optional style overrides.
 * @return {object}
 * @example
 *   computeCellStyle({ fontSize: 2});
 *   // { fontSize: 2 }
 *
 * @exampl
 *   computeCellStyle({ fontSize: 2, color: 'red' }, { color: 'blue' });
 *   // { fontSize: 2, color: 'blue' }
 */
const computeCellStyle = (styles = {}, overrideStyles = {}) =>
  Object.assign({}, styles, overrideStyles);

export default computeCellStyle;