import ActionTypes from "../../../ActionTypes";

const monthlyAssociatePaymentsCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.Admin.CommissionSummary.Disposed:
      return 0;
    case ActionTypes.Admin.CommissionSummary.PaymentsLoaded:
      return action.monthlyAssociatePaymentCount;
    default:
      return state;
  }
};

export default monthlyAssociatePaymentsCount;