import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const setQaRejected = async (shell, teraCrunchInputId) => {

  await shell.gateway.setQaRejected(teraCrunchInputId);

  return {
    type: ActionTypes.Automation.QaRejected,
    teraCrunchInputId
  };
};

export default ActionBuilder
  .for(setQaRejected)
  .build();