import ActionTypes from "../../../ActionTypes";

const compliance = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Carrier.Modification.Disposed:
      return null;
    case ActionTypes.Carrier.Modification.CarrierCompliance.Loaded:
      return action.compliance;
    default:
      return state;
  }
};

export default compliance;