import React from 'react';
import AppText from './AppText';

const AppCurrencyText = (props) => {

  const {
    value,
    currencyType = 'USD',
    minFractionDigits = 2,
    maxFractionDigits = 2,
    ...otherProps
  } = props;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits
  });

  const numberValue =  typeof(value) === 'number' ?
    formatter.format(value) : value;

  return (
    <AppText value={numberValue} {...otherProps} />
  );
};

export default AppCurrencyText;