import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const save = async (shell, uncoveredDetails) => {

  const [
    shipment,
    invoice
  ] = await Promise.all([
    shell.gateway.getShipment(uncoveredDetails.bolNumber),
    shell.gateway.getInvoice(uncoveredDetails.bolNumber)
  ]);

  shipment.openLoadNote = uncoveredDetails.notes && uncoveredDetails.notes.trim();
  shipment.isCovered = uncoveredDetails.isCovered;
  invoice.postedFor = uncoveredDetails.postedFor;

  await Promise.all([
    shell.gateway.saveShipment(shipment),
    shell.gateway.saveInvoice(invoice)
  ]);

  const { shipments } = await shell.gateway.getUncoveredShipmentListing({
    filter: { shipmentBolNumber: uncoveredDetails.bolNumber },
    offset: 0,
    limit: 1
  });

  const shipmentListingItem = shipments[0];

  return {
    type: ActionTypes.Shipment.Uncovered.Saved,
    shipment: shipmentListingItem
  };
};

export default ActionBuilder
  .for(save)
  .build();