import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCarrierNotes = async (shell, mcNumber) => {

  const notes = await shell.gateway.getAllCarrierNotes(mcNumber);

  return {
    type: ActionTypes.Carrier.Modification.CarrierNotesLoaded,
    notes
  };

};

export default ActionBuilder
  .for(loadCarrierNotes)
  .build();