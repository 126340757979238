import React from 'react';
import {Redirect} from "react-router-dom";


const CheckIfAuthorized = (props) => {
  const {
    isAce,
    modifyingAssociate,
    company,
    children
  } = props

  if(!isAce && modifyingAssociate.id !== company.associateId && !isAce && modifyingAssociate.parentAssociateId !== company.associateId) {
    return <Redirect to={"404"}/>;
  }
  // else
  return (
    children
  )
}

export default CheckIfAuthorized;