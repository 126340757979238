import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const deleteShipmentContent = async (shell, bolNumber, shipmentContentId) => {

  // eslint-disable-next-line no-unused-vars
  const deleteContent = await shell.gateway.deleteShipmentContent(bolNumber, shipmentContentId);

  const loadContents = await shell.gateway.getAllShipmentContents(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentContentsDeleted,
    loadContents
  }
};

export default ActionBuilder
  .for(deleteShipmentContent)
  .build();
