import dispose from './dispose';
import loadCompanyCategoryTypes from './loadCompanyCategoryTypes';
import loadCompanyInvoiceDeliveryMethodTypes from './loadCompanyInvoiceDeliveryMethodTypes'
import loadCompanyDocumentTypes from './loadCompanyDocumentTypes'
import loadFreightCategoryTypes from "./loadFreightCategoryTypes";
import loadPaymentTerms from './loadPaymentTerms';
import loadShipmentTrackingPhases from './loadShipmentTrackingPhases';
import loadStateProvinces from './loadStateProvinces';
import loadShipmentDocumentTypes from './loadShipmentDocumentTypes';
import loadCarrierDocumentTypes from './loadCarrierDocumentTypes';
import loadShipmentEquipmentTypes from "./loadShipmentEquipmentTypes";
import loadShipmentFreightClasses from './loadShipmentFreightClasses';
import loadShipmentHazardousStates from "./loadShipmentHazardousStates";
import loadShipmentPackageTypes from './loadShipmentPackageTypes';
import loadShipmentStatusTypes from "./loadShipmentStatusTypes";
import loadShipmentTruckLoadValueConfirmationType from "./loadShipmentTruckLoadValueConfirmationType";
import loadHelpDocuments from './loadHelpDocuments';
import deleteHelpDocument from './deleteHelpDocument';
import publishHelpDocument from './publishHelpDocument';

export default {
  dispose,
  deleteHelpDocument,
  loadCompanyCategoryTypes,
  loadCompanyInvoiceDeliveryMethodTypes,
  loadFreightCategoryTypes,
  loadPaymentTerms,
  loadShipmentTrackingPhases,
  loadStateProvinces,
  loadCompanyDocumentTypes,
  loadCarrierDocumentTypes,
  loadHelpDocuments,
  loadShipmentDocumentTypes,
  loadShipmentEquipmentTypes,
  loadShipmentFreightClasses,
  loadShipmentHazardousStates,
  loadShipmentPackageTypes,
  loadShipmentStatusTypes,
  loadShipmentTruckLoadValueConfirmationType,
  publishHelpDocument
};