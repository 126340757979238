import ActionTypes from '../../../ActionTypes';

const associateJacks = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Admin.ManageUsers.Disposed:
      return [];
    case ActionTypes.Admin.ManageUsers.AssociateJacksLoaded:
      return action.associateJacks;
    default:
      return state;
  }
};

export default associateJacks;