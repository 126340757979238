import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import { useState } from "react";

const TgfAssociateRoleDropDownList = (props) => {

    const roleOptions = [
        {
            id: 6,
            name: "Ace"
        },
        {
            id: 8,
            name: "Queen"
        },
        {
            id: 9,
            name: "Jack"
        },
    ];

    const [data, setData] = useState(roleOptions);
    const [filter, setFilter] = useState(null);

    const onBlur = () => {
        setData(data);
        setFilter(null);
    };

    const onClose = () => {
        setData(data);
        setFilter(null);
    };

    const filterDropdownListData = (filter) => {
        const filteredData = data.slice();
        return filterBy(filteredData, filter);
    };
    const onFilterChange = (event) => {
        setFilter(event.filter.value);
        setData(filterDropdownListData(event.filter));
    };

    return (
        <>
            {props.label && <Label editorId={props.id}>{props.label}</Label>}
            <DropDownList
                {...props}
                id={props.id}
                name={props.name}
                data={data}
                textField="name"
                dataItemKey="id"
                value={data.find(x => x.id === props.value?.id)}
                onChange={props.onChange}
                disabled={props.disabled}
                tabIndex={props.tabIndex}
                style={props.style}
                filterable={props.filterable}
                onFilterChange={onFilterChange}
                label={null} // we set this to null, because label is used in the Label component up above.
                onBlur={onBlur}
                onClose={onClose}
                filter={filter}
            />
        </>
    );
};

TgfAssociateRoleDropDownList.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input
    id: PropTypes.string.isRequired,
    // Name of the input, this is used to reference in the event handler.
    name: PropTypes.string.isRequired,
    // The current value of the input
    value: PropTypes.object,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Bool to make the input filterable.
    filterable: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,

};

export default TgfAssociateRoleDropDownList;