const MultiStopActions = [
  'clearStop'
];
const MultiStopFields = [
  'isMultiStop',
  'company',
  'stopType',
  'arrivalDate',
  'arrivalTime',
  'reference',
  'note'
];

const PermissionSet = {
  name: 'multipleStops',
  actions: MultiStopActions,
  fields: MultiStopFields
};

export default PermissionSet;