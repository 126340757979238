import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from "../../ActionTypes";

const loadShipmentTruckLoadValueConfirmationType = async (shell) => {

  const shipmentTruckloadValueConfirmationTypes = await shell.gateway.getShipmentTruckLoadConfirmationTypes();

  return {
    type: ActionTypes.Support.ShipmentTruckloadValueConfirmationTypeLoaded,
    shipmentTruckloadValueConfirmationTypes
  };
};

export default ActionBuilder
  .for(loadShipmentTruckLoadValueConfirmationType)
  .build();