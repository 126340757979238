import Core from '@atomos/core';
import ActionTypes from '../../../ActionTypes';

const updateProcessState = (state, processName, isActive, keySelector, metadata) => {


  if (keySelector) {

    const existingEntries = Core.Utils.get(state, processName, {});
    const entryKey = keySelector(metadata);

    return Object.assign({}, state, {
      [processName]: Object.assign({}, existingEntries, {
        [entryKey]: {
          isActive,
          ...metadata
        }
      })
    });
  }
  else {
    return Object.assign({}, state, {
      [processName]: {
        isActive
      }
    });
  }

};

const process = (state = {}, action) => {

  const {
    type,
    processName,
    keySelector,
    ...metadata
  } = action;

  switch (type) {
    case ActionTypes.Sys.ProcessStarting:
      return updateProcessState(state, processName, true, keySelector, metadata);
    case ActionTypes.Sys.ProcessComplete:
      return updateProcessState(state, processName, false, keySelector, metadata);
    default:
      return state;
  }
};

export default process;