import Core from '@atomos/core';
import {Grid, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import AppDialog from "../../../../../core/components/AppDialog/AppMuiDialog";
import AppCheckbox from "../../../../../core/components/inputs/AppCheckbox/AppMuiCheckbox";
import AppMuiSimpleTable from "../../../../../core/components/AppTable/AppMuiSimpleTable";
import AppButton from "../../../../../core/components/AppButton/AppMuiButton";

const widthHeaderMap = (width, textAlign='center') => (index, columns) =>
  ({ width, style: { padding: '3px 6px', textAlign } });

const makeTableFixed = (baseProps) =>
  Core.Utils.merge({}, baseProps, {
    style: { tableLayout: 'fixed' },
    width: '100%'
  });

const Columns = [
  {
    title: 'Rep',
    headerPropMap: widthHeaderMap('20%', 'left')
  },
  {
    title: 'Related BOL',
    headerPropMap: widthHeaderMap('15%', 'left')
  },
  {
    title: 'Adjustment Total',
    headerPropMap: widthHeaderMap('20%')
  },
  {
    title: 'Reason for Change',
    headerPropMap: widthHeaderMap('35%', 'left')
  },
  {
    title: '',
    headerPropMap: widthHeaderMap('10%')
  }
];

const baseCellStyle = {
  padding: '3px 6px',
  textAlign: 'center'
};

const leftCellStyle = {
  ...baseCellStyle,
  textAlign: 'left'
};

const reasonCellStyle = {
  ...leftCellStyle,
  overflowWrap: 'anywhere'
};

const AdjustmentPreviousListDialog = (props) => {

  const {
    adjustments,
    associate,
    onCancelClick,
    onSaveAdjustments
  } = props;

  const [selectedIds, setSelectedIds] = React.useState([]);

  const actions = [
    {
      title: 'Cancel',
      action: onCancelClick
    },
    {
      title: 'Save',
      action: () => handleSaveAdjustments()
    }
  ];

  const handleSaveAdjustments = () => {
    const selectedAdjustments = adjustments.filter(a => selectedIds.includes(a.id))
    onSaveAdjustments(selectedAdjustments)
  }

  const handleSelectionChanged = (id) => (e) => {
    const newSelectedIds = e.target.checked ?
      selectedIds.concat(id) :
      selectedIds.filter(sid => sid !== id);

    setSelectedIds(newSelectedIds);
  };

  const chooseAdjustmentAmount = (adjustment) => {
    return adjustment.additionAmount ?
      adjustment.additionAmount :
      adjustment.deductionAmount;
  };

  const handleSelectAllAdjustmentsClick = () => {
    setSelectedIds(adjustments.map(a => a.id));
  };

  const handleUnselectAllAdjustmentsClick = () => {
    setSelectedIds([]);
  };

  const selectorButton = adjustments.length === selectedIds.length ?
    (
      <AppButton
        tooltip="Unselect all adjustments."
        onClick={handleUnselectAllAdjustmentsClick}>
        Unselect All
      </AppButton>
    ) :
    (
      <AppButton
        tooltip="Select all adjustments."
        onClick={handleSelectAllAdjustmentsClick}>
        Select All
      </AppButton>
    );

  return (

    <AppDialog
      title='Previous Pay Period Adjustments'
      width='md'
      open={true}
      actionButtons={actions}
    >
      <Grid item xs={12} style={{ marginBottom: 8 }}>
        {selectorButton}
      </Grid>
      <AppMuiSimpleTable
        columns={Columns}
        tablePropMap={makeTableFixed}>
        {
          adjustments.map((adjustment, index) =>
            <TableRow key={index}>
              <TableCell style={leftCellStyle}>
                {associate?.firstName} {associate?.lastName} ({associate?.systemId})
              </TableCell>
              <TableCell style={leftCellStyle}>
                {adjustment.relatedBolNumber}
              </TableCell>
              <TableCell style={baseCellStyle}>
                {chooseAdjustmentAmount(adjustment)}
              </TableCell>
              <TableCell style={reasonCellStyle}>
                {adjustment.reason}
              </TableCell>
              <TableCell style={baseCellStyle}>
                <AppCheckbox
                  type="checkbox"
                  checked={selectedIds.includes(adjustment.id)}
                  onChange={handleSelectionChanged(adjustment.id)}
                />
              </TableCell>
            </TableRow>
          )
        }
      </AppMuiSimpleTable>
    </AppDialog>
  )
};

export default AdjustmentPreviousListDialog;