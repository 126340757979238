import ActionTypes from '../../ActionTypes';

const companyInvoiceDeliveryMethodTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.CompanyInvoiceDeliveryMethodTypesLoaded:
      return action.companyInvoiceDeliveryMethodTypes;
    default:
      return state;
  }
};

export default companyInvoiceDeliveryMethodTypes;