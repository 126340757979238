import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const markInvoicesSent = async (shell, bolNumbers) => {

  await shell.gateway.markInvoicesSent(bolNumbers);

  return {
    type: ActionTypes.Dashboard.MarkedInvoicesSent
  };

};

export default ActionBuilder
  .for(markInvoicesSent)
  .build();