import React from "react";
import { Button } from "@progress/kendo-react-buttons";

const TgfButtonBasic = (props) => {

    return (
            <Button
                {...props}
                themeColor={"primary"}
                className={"tgf-button"}
            >{props.children}</Button>
    )
}

export default TgfButtonBasic