import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const dispose = async (shell) => {
    return {
        type: ActionTypes.Reporting.LaneLookup.Disposed
    };
};

export default ActionBuilder
    .for(dispose)
    .build();