import ShipmentHazardousStateNames from "../../ShipmentHazardousStateNames";

/**
 * Identifies whether the hazardousStateId is Hazardous or Not
 * @param [hazardousStates] hazardousStates - list of hazardous states
 * @param {number|undefined|null} hazardousStateId - the Id of current hazardous flag
 * @returns {boolean}
 */
const isHazardous = (hazardousStates, hazardousStateId) => {
  const matchingState = hazardousStates.find(hs => hs.id === hazardousStateId);
  return Boolean(matchingState) && matchingState.name === ShipmentHazardousStateNames.Hazardous;
}

export default isHazardous;
