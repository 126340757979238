import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveCompany = async (shell, company) => {

  const updatedCompany = await shell.gateway.saveCompany(company);

  return {
    type: ActionTypes.AddressBook.Modification.CompanySaved,
    company: updatedCompany
  };

};

export default ActionBuilder
  .for(saveCompany)
  .build();