import Core from '@atomos/core';
/**
 * Identifies whether a bolNumber represents
 * a new Shipment.
 * @param {number|undefined|null} bolNumber - The shipment bolNumber.
 * @return {boolean}
 */
const isNewShipment = bolNumber => Core.Utils.isUndefined(bolNumber) ||
  bolNumber === 0;

export default isNewShipment;