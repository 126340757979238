import React, {useEffect, useState} from 'react';
import ComponentBuilder from "../../../../../core/ComponentBuilder";
import AppMuiInput from "../../../../../core/components/inputs/AppInput";
import { Grid } from '@material-ui/core';
import {Store as StoreIcon } from '@material-ui/icons';
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";

const UseFactoringCompanyAddressTooltip = 'Select a factoring company address.';

const RemitAddressPicker = (props) => {
  const {
    shipmentFinalized,
    handleFactoringCoSelectShow,
    values,
    handleRemitFieldChange
  } = props;


  const onSelectFactoringCompanyAddressClick = async () => {
    handleFactoringCoSelectShow();
  }

  return (
    <AppMuiCard
      title="Remit To Address"
      action={[
        <AppIconButton
          color={'primary'}
          key={'companyAddress'}
          disabled={shipmentFinalized}
          Icon={StoreIcon}
          tooltip={UseFactoringCompanyAddressTooltip}
          onClick={onSelectFactoringCompanyAddressClick}/>
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <AppMuiInput
                id={'remitName'}
                name={'remitName'}
                label={'Remit To Name'}
                type={'text'}
                value={values.remitName}
                onChange={handleRemitFieldChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AppMuiInput
            id={'address1'}
            name={'remitAddress'}
            label={'Address'}
            type={'text'}
            value={values.remitAddress}
            onChange={handleRemitFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <AppMuiInput
            id={'address2'}
            label={'Address 2'}
            name={'remitAddress2'}
            type={'text'}
            disabled={shipmentFinalized}
            value={values.remitAddress2}
            onChange={handleRemitFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <AppMuiInput
                id={'city'}
                name={'remitCity'}
                label={'City'}
                type={'text'}
                disabled={shipmentFinalized}
                value={values.remitCity}
                inputProps={{
                  maxLength: 30
                }}
                onChange={handleRemitFieldChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppMuiInput
                id={'stateProvince'}
                name={'remitState'}
                label={'State/Province'}
                type={'text'}
                disabled={shipmentFinalized}
                value={values.remitState}
                inputProps={{
                  maxLength: 50
                }}
                onChange={handleRemitFieldChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppMuiInput
                id={'postalCode'}
                name={'remitZip'}
                label={'Postal Code'}
                type={'text'}
                disabled={shipmentFinalized}
                value={values.remitZip}
                inputProps={{
                  maxLength: 15
                }}
                onChange={handleRemitFieldChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};


export default ComponentBuilder
  .wrap(RemitAddressPicker)
  .build();