import React from 'react';
import Core from '@atomos/core';
import { Grid } from '@material-ui/core';

import AppInput from '../../../../../core/components/inputs/AppInput';
import AppSwitch from '../../../../../core/components/inputs/AppSwitch';
import AppButton from '../../../../../core/components/AppButton';
import AppDrawer from '../../../../../core/components/AppDrawer';
import AppCard from '../../../../../core/components/cards/AppCard';
import AppNumericInput from '../../../../../core/components/inputs/AppNumericInput';
import FormFactor from "../../../../../core/FormFactor/FormFactor";
import * as Yup from "yup";
import AppValidationFailure from "../../../../../core/components/AppValidationFailure";

const AdjustmentDrawer = (props) => {

  const {
    onSaveAdjustmentClick,
    onCancelClick,
    adjustment,
  } = props;

  const initialValues = {
    ...adjustment,
    onCancelClick: onCancelClick,
    onSaveAdjustmentClick: onSaveAdjustmentClick
  };

  const AdjustmentSchema = Yup.lazy(values => {
    const schema = {
      reason: Yup.string()
        .nullable()
        .required('Reason is required.')
    };

    const hasDeductionAmount = Core.Utils.isNumber(values.deductionAmount);
    const hasAdditionAmount = Core.Utils.isNumber(values.additionAmount);

    // Apply an always-failing test when the user has entered values for both
    // amount options.
    if (hasAdditionAmount && hasDeductionAmount && values.deductionAmount !== 0 && values.additionAmount !== 0) {
      schema.deductionAmount = Yup.number()
        .typeError('Must be a number.')
        .test('two-amounts', 'Only deduction or addition amount can be set.', (value) => false);
      schema.additionAmount = Yup.number()
        .typeError('Must be a number.')
        .test('two-amounts', 'Only deduction or addition amount can be set.', (value) => false);
    }
    // Otherwise ensure either amounts are positive amounts.
    else if (!(hasAdditionAmount || hasDeductionAmount)) {
      schema.deductionAmount = Yup.number()
        .typeError('Must be a number.')
        .min(0.01, 'Must be greater than 0.');
      schema.additionAmount = Yup.number()
        .typeError('Must be a number.')
        .min(0.01, 'Must be greater than 0.');
    }

    return Yup.object().shape(schema);
  });

  const handleAdjustmentSubmit = (values, formFactor) => {
    
    // Clone the values into a new adjustment object for manipulation.
    const adjustment = {...values};

    // If either deduction or addition amount have a 0, default it to null.
    adjustment.deductionAmount = Core.Utils.isNumber(adjustment.deductionAmount) && adjustment.deductionAmount === 0 ?
      null : adjustment.deductionAmount;
    adjustment.additionAmount = Core.Utils.isNumber(adjustment.additionAmount) && adjustment.additionAmount === 0 ?
      null : adjustment.additionAmount;
    adjustment.repPaidConfirmed = adjustment.repPaid;

    values.onSaveAdjustmentClick(adjustment);
  };

  return (
    <AppDrawer
      anchor={'right'}
      open={true}
      onClose={onCancelClick}
    >
      <FormFactor
        initialValues={initialValues}
        schema={AdjustmentSchema}
        onSubmit={handleAdjustmentSubmit}
      >
        {renderAdjustmentForm}
      </FormFactor>
    </AppDrawer>
  );
};

const renderAdjustmentForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {
  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  }

  const handleNumericFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value ? Number(e.target.value) : null);
  }

  const handleSwitchChange = (key) => (e) => {
    setFieldValue(key, e.target.checked);
  };

  return (
    <div style={{ width: 400, height: '100vh' }}>
      <AppCard title={'Adjustment'}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AppInput
              id={'relatedBolNumber'}
              label={'Related to Shipment(s)'}
              value={values.relatedBolNumber || ''}
              onChange={handleFieldChange('relatedBolNumber')}
              multiline
              rows={3}
              inputProps={{
                maxLength: 200
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInput
              id={'reason'}
              label={'Adjustment Reason'}
              value={values.reason || ''}
              onChange={handleFieldChange('reason')}
            />
            <AppValidationFailure
              message={errors.reason}
            />
          </Grid>
          <Grid item xs={12}> 
            <AppNumericInput
              id={'deductionAmount'}
              labelColor={{color: '#dc3545'}}
              label={'Deduction Amount'}
              style={{color: '#dc3545'}}
              allowNegative={false}
              decimalScale={2}
              value={values.deductionAmount}
              onChange={handleNumericFieldChange('deductionAmount')}
            />
            <AppValidationFailure
              message={errors.deductionAmount}
            />
          </Grid>
          <Grid item xs={12}>
            <AppNumericInput
              id={'additionAmount'}
              label={'Added Amount'}
              decimalScale={2}
              allowNegative={false}
              value={values.additionAmount}
              onChange={handleNumericFieldChange('additionAmount')}
            />
            <AppValidationFailure
              message={errors.additionAmount}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInput
              id={'note'}
              label={'Notes'}
              multiline
              rows={3}
              value={values.note || ''}
              onChange={handleFieldChange('note')}
            />
          </Grid>
          <Grid item xs={12}>
            <AppSwitch
              id={'adj'}
              label={'Paid'}
              checked={Boolean(values.repPaid)}
              onChange={handleSwitchChange('repPaid')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item><AppButton onClick={submitForm} disabled={!isValid || !isDirty}>Save</AppButton></Grid>
              <Grid item><AppButton onClick={values.onCancelClick}>Cancel</AppButton></Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppCard>
    </div>
  )
}

export default AdjustmentDrawer;