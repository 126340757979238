import ActionTypes from '../../ActionTypes';

const shipmentDocumentTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.ShipmentDocumentTypes:
      return action.shipmentDocumentTypes;
    default:
      return state;
  }
};

export default shipmentDocumentTypes;