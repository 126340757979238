import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipmentDocuments = async (shell, bolNumber) => {

  const shipmentDocuments = await shell.gateway.getShipmentDocuments(bolNumber);

  return {
    type: ActionTypes.Shipment.LoadBoard.LoadShipmentDocuments,
    shipmentDocuments
  };

};

export default ActionBuilder
  .for(loadShipmentDocuments)
  .build();