import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FullWidthLayout from "../../../../core/layouts/FullWidthLayout";
import { usePageTitle } from "../../../../crm/components/customHooks/misc/usePageTitle";
import LeftNav from "../../LeftNav";
import ManageUsersForm from "./ManageUsersForm/ManageUsersForm";

const ManageUsersDetailsPage = (props) => {
    usePageTitle("TGF: Manage Users");

    const personalDispatch = useDispatch();
    const associateId = parseInt(props.match.params?.id);
    const [associate, setAssociate] = useState({});
    const [featureFlags, setFeatureFlags] = useState({});

    useEffect(() => {
        const loadPageDeps = async (associateId) => {
            try {
                personalDispatch(window.shell.actions.sys.processStart('loadingManageUsersPage'));
                if (associateId) {
                    setAssociate(await window.shell.gateway.getAssociateFormData(associateId));
                    personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: "Associate Details Loaded" }));
                } else {
                    setAssociate({});
                }
                setFeatureFlags(await window.shell.gateway.communicator.get('/security/featureFlags'));
            }
            catch (e) {
                console.error(e);
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                    content: 'Failed To Load Associate Details',
                    color: 'error'
                }));

            } finally {

                personalDispatch(window.shell.actions.sys.processComplete('loadingManageUsersPage'));
            }
        };

        loadPageDeps(associateId);
    }, [associateId]);


    // TODO:  27 Mar 24 JWL
    // title prop below really should set from a state hook/variable, however now not the time.
    return (
        <FullWidthLayout
            SideNav={LeftNav}
            title={`Manage Users - ${`${associate?.firstName || ''} ${associate?.lastName || ''}`.trim() || 'Adding New Associate'}`}
            className={"manage-users"}
        >
            <ManageUsersForm
                associate={associate}
                setAssociate={setAssociate}
                featureFlags={featureFlags}
                associateId={associateId}
            />
        </FullWidthLayout>
    );
};

export default ManageUsersDetailsPage;
