import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveCarrier = async (shell, carrier) => {

  const updateCarrier = await shell.gateway.saveCarrier(carrier);

  return {
    type: ActionTypes.Carrier.Modification.CarrierSaved,
    carrier: updateCarrier
  };
};

export default ActionBuilder
  .for(saveCarrier)
  .build();
