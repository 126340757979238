import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const finalizeStatement = async (shell, associateId, startDate) => {

  await shell.gateway.finalizeStatement(associateId, startDate)

  return {
    type: ActionTypes.CommissionCenter.FinalizedStatement
  };

};

export default ActionBuilder
  .for(finalizeStatement)
  .build();