import * as ReduxLogger from 'redux-logger';
import Thunk from 'redux-thunk';

const createLogger = () =>
    ReduxLogger.createLogger({
        duration: true,
        level: 'info',
        diff: true,
        collapsed: true
    });

/**
 * Creates an array of middleware to be used by the state store.
 * @param {object} config - Runtime configuration.
 * @return {function[]}
 */
const createStateStoreMiddleware = (config) => {
    const middleware = [
        Thunk
    ];

    // Only allow Redux action logging if we're developing locally or
    // in the shared development environment AND it is not disabled via env value
    if (config.disableReduxLogger === 'false' && config.isLocal() || config.isDevelopment()) {
        middleware.push(createLogger());
    }

    return middleware;
};

export default createStateStoreMiddleware;