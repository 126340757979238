import { Grid } from "@material-ui/core";
import { LibraryBooks } from "@material-ui/icons";
import { Label } from "@progress/kendo-react-labels";
import { useSelector } from "react-redux";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import TgfComboBox from "../../../../core/kendo/form/inputs/TgfComboBox";
import TgfTextBox from "../../../../core/kendo/form/inputs/TgfTextBox";
import FieldError from "../../../../crm/components/inputs/FieldError";

const BillingAddressCard = (props) => {
    const stateProvinces = useSelector(state => state.support.stateProvinces).map((tuple) => { return { id: tuple[0], display: `${tuple[0]} - ${tuple[1]}` }; });
    const company = useSelector(state => state?.addressBook?.modification?.company);

    const handleChange = async (e) => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                [e.target.element.name]: e.target.value
            }
        });
        props.setIsDirty(true);
    };

    const handleStateProvinceChange = async (e) => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                state: e.target.value ? stateProvinces.find(state => state.id === e.target.value.id).id : ""
            }
        });
        props.setIsDirty(true);
    };

    const handleAddressCopyClick = async () => {
        props.setData({
            ...props.data,
            entity: {
                ...props.data.entity,
                address1: company?.address1,
                address2: company?.address2,
                city: company?.city,
                state: company?.stateProvince,
                postalCode: company?.postalCode
            }
        });
        props.setIsDirty(true);
    };

    const addressCopyButton = (
        <AppIconButton
            Icon={LibraryBooks}
            tooltip={`Copy from company's primary address.`}
            onClick={handleAddressCopyClick}
            disabled={!props.disableEdit}
        />
    );

    return (
        <AppMuiCard title={"Billing Address"} action={addressCopyButton}>
            <Grid container alignItems={"center"} spacing={2}>
                <Grid item xs={12}>
                    <Label editorId={"address1"}>Address Line 1</Label>
                    <TgfTextBox
                        id={"address1"}
                        name={"address1"}
                        value={props.data?.entity?.address1}
                        onChange={handleChange}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.address1}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <Label editorId={"address2"}>Address Line 2</Label>
                    <TgfTextBox
                        id={"address2"}
                        name={"address2"}
                        value={props.data?.entity?.address2}
                        onChange={handleChange}
                        disabled={!props.disableEdit}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Label editorId={"city"}>City</Label>
                    <TgfTextBox
                        id={"city"}
                        name={"city"}
                        value={props.data?.entity?.city}
                        onChange={handleChange}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.city}</FieldError>
                </Grid>
                <Grid item xs={8}>
                    <TgfComboBox
                        label={"State/Province"}
                        id={"state"}
                        name={"state"}
                        data={stateProvinces}
                        value={props.data?.entity?.state}
                        onChange={handleStateProvinceChange}
                        clearButton={false}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.state}</FieldError>
                </Grid>
                <Grid item xs={4}>
                    <Label editorId={"postalCode"}>Zip/Postal Code</Label>
                    <TgfTextBox
                        id={"postalCode"}
                        name={"postalCode"}
                        onChange={handleChange}
                        value={props.data?.entity?.postalCode}
                        format={"c2"}
                        disabled={!props.disableEdit}
                    />
                    <FieldError shouldRender={props.disableEdit}>{props.fieldErrors.postalCode}</FieldError>
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};

export default BillingAddressCard;
