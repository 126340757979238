import ActionTypes from "../../../ActionTypes";

const doNotPayCarrier = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Automation.DoNotPayCarrier.Loaded:
      return action.doNotPayCarrier;
    default:
      return state;
  }
};

export default doNotPayCarrier;