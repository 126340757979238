import React from 'react';
import {Grid} from '@material-ui/core';
import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppMuiInput from '../../../../core/components/inputs/AppInput';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';

const ChangeStatusDialog = (props) => {

  const {
    currentStatusType,
    statusTypeOptions,
    bolNumber,
    onSave,
    onCancel,
    values
  } = props;

  const [canChangeStatus, setCanChangeStatus] = React.useState(false);
  const [isRequired, setIsRequired] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [statusType, setStatusType] = React.useState(null);
  const [voidReason, setVoidReason] = React.useState(null);

  const handleSaveClick = () => {
    onSave(statusType, voidReason);
  };

  const dialogActions = [
    {
      title: 'Cancel',
      action: onCancel
    },
    {
      title: 'Save',
      action: handleSaveClick,
      disabled: !canChangeStatus || (isRequired && (voidReason === null || voidReason === ''))
    }
  ];

  const title = `Change Status for Shipment #${bolNumber}`;

  const statusTypes = statusTypeOptions.map(st => st.statusType);

  const handleVoidReasonBlur = (e) => {
    setVoidReason(e.target.value);
  }

  const handleStatusTypeChange = (e, statusType) => {
    const statusTypeOption = statusTypeOptions.find(sto => sto.statusType.id === statusType.id);
    setCanChangeStatus(statusTypeOption.canChange);
    setMessage(statusTypeOption.message);
    setStatusType(statusType);
    if(statusType.id === -1){
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  };

  return (
    <AppDialog
      title={title}
      open={true}
      onClose={onCancel}
      width={'sm'}
      actionButtons={dialogActions}>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AppMuiInput
            readOnly
            label={'Current Status'}
            value={values.shipment.hasOwnProperty('voidId') ? 'Cancelled/Voided' :(currentStatusType?.name || '')}
            />
        </Grid>
        <Grid item xs={6}>
          <AppDropdown
            label={'New Status'}
            fieldIndex={'id'}
            field={'name'}
            options={statusTypes}
            onChange={handleStatusTypeChange}
          />
        </Grid>
        <Grid item xs={12}>
          {
            Boolean(message) &&
            <AppAlertStatic
              title={message}
              color={statusType?.messageType}
            />
          }
        </Grid>
        <Grid item xs={12}>
          { statusType?.id === -1 &&
              <>
                <AppMuiInput
                    name={'voidReason'}
                    label={'Reason for Cancel/Void (200 max characters)'}
                    value={voidReason}
                    rows="4"
                    multiline={true}
                    inputProps={{
                      maxLength: 200,
                    }}
                    required={statusType?.id === -1}
                    onChange={handleVoidReasonBlur}
                />
                {statusType?.id === -1 && !voidReason && <span style={{color: "red"}}>Reason is required</span>}
              </>

          }
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default ChangeStatusDialog;
