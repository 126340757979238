import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const saveShipment = async (shell, shipment) => {

  const updateShipment = await shell.gateway.saveShipment(shipment);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentSaved,
    shipment: updateShipment
  }
};

export default ActionBuilder
  .for(saveShipment)
  .build();
