import React, { useRef } from "react";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import {
    defaultSortOptions,
    gridColumnOptions,
    urlPathOptions
} from "./riskReviewReportOptions";


const RiskReviewReportListingGrid = (props) => {
    const ref = useRef();
    const location = useLocation();
    const personalDispatch = useDispatch();

    const loadGridData = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadRiskReviewReport'));
            return await window.shell.gateway.getRiskReviewReport(odata, location.pathname);
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Risk Review Report',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadRiskReviewReport'));
        }
    }

    const otherGridProps = {
        className: "open-load-board_risk-review-report-grid",
        ref: ref,
        onLoadDataPage: loadGridData,
        pageable: {
            pageSize: 20,
            buttonCount: 10,
            pageSizes: [5, 10, 20, 50, 100, 500]
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {location.pathname === urlPathOptions.highGmShipments &&
                <TgfDataGrid
                    gridColumns={gridColumnOptions.highGmShipments}
                    sort={defaultSortOptions.highGmShipments}
                    {...otherGridProps}
                />
                }
                {location.pathname === urlPathOptions.highGmCustomers &&
                    <TgfDataGrid
                        gridColumns={gridColumnOptions.highGmCustomers}
                        sort={defaultSortOptions.highGmCustomers}
                        {...otherGridProps}
                    />
                }
                {location.pathname === urlPathOptions.matchingRefNumbers &&
                    <TgfDataGrid
                        gridColumns={gridColumnOptions.matchingRefNumbers}
                        sort={defaultSortOptions.matchingRefNumbers}
                        {...otherGridProps}
                    />
                }
                {location.pathname === urlPathOptions.matchingCarriers &&
                    <TgfDataGrid
                        gridColumns={gridColumnOptions.matchingCarriers}
                        sort={defaultSortOptions.matchingCarriers}
                        {...otherGridProps}
                    />
                }
                {location.pathname === urlPathOptions.creditAppMissing &&
                    <TgfDataGrid
                        gridColumns={gridColumnOptions.creditAppMissing}
                        sort={defaultSortOptions.creditAppMissing}
                        {...otherGridProps}
                    />
                }
                {location.pathname === urlPathOptions.fiveKPlusCustomerCost &&
                    <TgfDataGrid
                        gridColumns={gridColumnOptions.fiveKPlusCustomerCost}
                        sort={defaultSortOptions.fiveKPlusCustomerCost}
                        {...otherGridProps}
                    />
                }
            </Grid>
        </Grid>
    )
}

export default RiskReviewReportListingGrid;
