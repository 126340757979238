import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import combineClassNames from '../../../../core/utils/combineClassNames';
import AppCard from '../../../../core/components/cards/AppCard/AppMuiCard';
import {Link} from 'react-router-dom';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';

const useStyles = makeStyles((theme) => ({
  alignRight: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'right'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    },
  },
  lessThanZero: {
    color: theme.palette.error.main
  },
  valueContainer: {
    textAlign: 'right',
    display: 'block'
  },
  totalRow: {
    fontWeight: 'bold',
    borderTop: `1px solid ${theme.palette.tgfGrey.fieldBorder}`,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}))

const CommissionBreakdownCard = (props) => {
  const classes = useStyles();

  const {
    commissionBreakdown
  } = props;

  const breakdownRecords = [
    {
      title: '1st through 10th Locked Shipment Payout:',
      amount: commissionBreakdown?.firstPeriodAmount || 0
    },
    {
      title: '11th through 25th Locked Shipment Payout:',
      amount: commissionBreakdown?.secondPeriodAmount || 0
    },
    {
      title: 'Post 25th Locked Shipment Payout:',
      amount: commissionBreakdown?.thirdPeriodAmount || 0
    },
    {
      title: 'Adjustments Total:',
      amount: commissionBreakdown?.adjustmentAmount || 0
    },
    {
      title: 'TOTAL COMMISSION PAYOUT:',
      amount: commissionBreakdown?.totalAmount || 0,
      class: classes.totalRow
    }
  ];

  return (
    <AppCard title={'Commission Breakdown'}>
      <Grid container spacing={2}>
        <Grid item>
          <Grid container>
            {
              breakdownRecords.map((item, index) =>
                <DetailRow item={item} key={index} classes={classes} />)
            }
          </Grid>
        </Grid>
        <Grid container item justify={'center'}>
          <span>
            See the appropriate pay period within your <Link to={'/reports/commission-center'}>Commission Center</Link> for full details.
          </span>
        </Grid>
      </Grid>
    </AppCard>
  );
};

const DetailRow = ({ item, classes }) => {
  const {
    title, amount
  } = item;

  const titleClass = item.class;
  const valueClass = combineClassNames(
    amount < 0 ? classes.lessThanZero : null,
    item.class
  );

  return (
    <React.Fragment>
      <Grid item xs={12} md={9}>
        <Typography variant="h6" className={titleClass}>
          {title}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={3} className={classes.valueContainer}>
        <Typography variant="h6" display={'block'} className={valueClass}>
          <AppCurrencyText value={amount} />
        </Typography>
      </Grid>
    </React.Fragment>
  )
};
export default CommissionBreakdownCard;