import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const loadCarrierCompliance = async (shell, mcNumber) => {

  const compliance = await shell.gateway.getCarrierCompliance(mcNumber);

  return {
    type: ActionTypes.Carrier.Modification.CarrierCompliance.Loaded,
    compliance
  }
};

export default ActionBuilder
  .for(loadCarrierCompliance)
  .build();