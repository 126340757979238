import React from "react";
import { Grid } from '@material-ui/core';
import ComponentBuilder from "../../../../core/ComponentBuilder";

import AppDatePicker from '../../../../core/components/inputs/AppDatePicker';
import AppValidationFailure from '../../../../core/components/AppValidationFailure'

import Core from "@atomos/core";
import SecureField from "../../permissions/SecureField";
import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppMuiTable from '../../../../core/components/AppDenseGrid';
import AppDateText from '../../../../core/components/text/AppDateText';
import AppIntegerText from '../../../../core/components/text/AppIntegerText';

const rowClass = () => 'removeHover';

// Custom cell that shows a date-picker only when the user
// is an ace and there is no customerPaidDate value set (implying its been paid)
const InvoiceDateCell = (isAce, handleChange, values) => ({ rowData }) =>
  isAce && !rowData.customerPaidDate ?
    <AppDatePicker
      style={{ padding: 5 }}
      value={rowData.invoiceDate}
      onChange={handleChange}
      disabled={values.shipment.hasOwnProperty('voidId')}
    /> :
    rowData.invoiceDate ?
      <AppDateText value={rowData.invoiceDate} /> :
      '-';

const EmptyDateCell = (fieldName) => ({ rowData }) => {
  return rowData[fieldName] ?
    <AppDateText value={rowData[fieldName]} />  :
    '-';
};
const EmptyAmountCell = (fieldName) => ({ rowData }) => {
  return rowData[fieldName] ?
    <AppIntegerText value={rowData[fieldName]} />  :
    '-';
};

const StatusDates = (props) => {

  const {
    isAce,
    actualDeliveryDate,
    confirmedAssociateWasPaid,
    invoiceDate,
    customerPaidDate,
    paymentTerms,
    customerHasPaid,
    actualDeliveryDateError,
    setFieldValue,
    setFieldValues,
    values
  } = props;

  const invoiceDueDate = invoiceDate?.toMoment().add(paymentTerms, 'days').toDate();

  const handleDeliveryDateChange = (date) => {
    if (date && date.isValid()) {
      setFieldValue('invoice.actualDeliveryDate', date.toDate());
    }
    else {
      setFieldValue('invoice.actualDeliveryDate', null);
    }
  };

  const handleInvoiceDateChange = (date) => {
    const fieldValues = [];
    if (!date) {
      fieldValues.push(['invoice.invoiceDate', null]);
    }
    else if (date.isValid()) {
      fieldValues.push(['invoice.invoiceDate', date.toDate()]);
    }
    setFieldValues(fieldValues);
  };

  const maxLockedDate = (new Date())
    .toMoment()
    .add(5, 'days')
    .toDate();

  const gridColumns = [
    {
      title: "Invoice Sent Date",
      field: 'invoiceDate',
      styles: {
        textAlign: 'center'
      },
      noSort: true,
      dataType: 'component',
      component: InvoiceDateCell(isAce, handleInvoiceDateChange, values)
    },
    {
      field: "invoiceDueDate",
      title: "Due Date",
      styles: {
        width: 100,
        textAlign: 'center'
      },
      noSort: true,
      dataType: 'component',
      component: EmptyDateCell('invoiceDueDate')
    },
    {
      field: "aging",
      title: "Past Due",
      styles: {
        width: 40,
        textAlign: 'center'
      },
      noSort: true,
      dataType: 'component',
      component: EmptyAmountCell('aging')
    },
    {
      field: "customerPaidDate",
      title: "Paid Date",
      styles: {
        width: 100,
        textAlign: 'center'
      },
      noSort: true,
      dataType: 'component',
      component: EmptyDateCell('customerPaidDate')
    }
  ];

  // Calculate the number of days the invoice is past due.
  let dueDateDiff = invoiceDueDate ?
    new Date().toMoment().diff(invoiceDueDate, 'days') :
    0;

  // Include the due date in the difference if we aren't due yet.
  if (dueDateDiff < 0) {
    dueDateDiff -= 1;
  }

  // Only show the days past due when the paid date isn't specified and we have a positive value.
  const daysPastDueText = !customerHasPaid ? dueDateDiff.toString() : null;

  // Create a single element array for the date table's data.
  const gridData = [
    {
      invoiceDate,
      invoiceDueDate: invoiceDueDate?.toMoment().format('L'),
      aging: daysPastDueText,
      customerPaidDate: customerPaidDate?.toMoment().format('L')
    }
  ];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <AppMuiTable
          columns={gridColumns}
          data={gridData}
          count={gridData.length}
          rowProps={rowClass}
        />
      </Grid>
      <Grid item xs={12}>
        {
          isAce &&
          <AppMuiCard title={'Confirmed Locked Date'} headerTextColor="tgfBlue">
            <SecureField
              pageName={'financial'}
              fieldName={'actualDeliveryDate'}
              as={AppDatePicker}
              id={'lockedDate'}
              maxDate={maxLockedDate}
              disabled={confirmedAssociateWasPaid}
              value={actualDeliveryDate}
              onChange={handleDeliveryDateChange}
            />
            <AppValidationFailure message={actualDeliveryDateError} />
          </AppMuiCard>
        }
      </Grid>
    </React.Fragment>
  )
};

const propsAreEqual = (prevProps, nextProps) => {
  const isEqual = prevProps.signedForBy === nextProps.signedForBy &&
    Core.Utils.isEqual(prevProps.actualDeliveryDate, nextProps.actualDeliveryDate) &&
    prevProps.invoiceDate === nextProps.invoiceDate &&
    prevProps.actualDeliveryDateError === nextProps.adjustedCustomerCostError &&
    prevProps.customerWasPaidError === nextProps.customerWasPaidError;

  return isEqual;
};

export default React.memo(ComponentBuilder
  .wrap(StatusDates)
  .build(),
  propsAreEqual
);
