import React from 'react';
import When from '../../../../core/components/condtionals/When';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import {Typography} from '@material-ui/core';
import AppMuiSwitch from "../../../../core/components/inputs/AppSwitch";
import SecureField from "../../permissions/SecureField";
import AppDialog from "../../../../core/components/AppDialog";
import {useState} from "react";

const ConfirmDialog = (props) => {

  const {
    confirmClick,
    cancelClick,
    ...otherProps
  } = props;

  const dialogActions = [
    {
      title: "Confirm", action: confirmClick
    },
    {
      title: 'Cancel', action: cancelClick
    }
  ];

  const {} = props;

  return (
    <AppDialog title="Do Not Pay Carrier"
               width='xs'
               open={true}
               actionButtons={dialogActions}>
      Please confirm the carrier for this shipment can now be paid.
    </AppDialog>
  );
};

const DoNotPayToggle = (props) => {

  const {
    canEdit,
    onChange,
    initialValue,
    disabled,
    ...otherProps
  } = props;

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [doNotPayCarrier, setDoNotPayCarrier] = useState(initialValue);

  const confirmClick = () => {
    setDoNotPayCarrier(false);
    onChange(false);
    setShowConfirmDialog(false);
  }

  const cancelClick = () => {
    setShowConfirmDialog(false);
  }

  const onClick = (event) => {
    if (event.target.checked) {
      setDoNotPayCarrier(true);
      onChange(true);
    } else {
      setShowConfirmDialog(true);
    }
  }

  return (
    <AppMuiCard title="Do Not Pay Carrier">
      <AppMuiSwitch
        id={'doNotPayCarrier'}
        label={'Do Not Pay Carrier'}
        color={'primary'}
        checked={doNotPayCarrier}
        onChange={onClick}
        disabled={disabled}
      />

      {showConfirmDialog && <ConfirmDialog confirmClick={confirmClick} cancelClick={cancelClick}></ConfirmDialog>}

    </AppMuiCard>
  );
};

export default DoNotPayToggle;