import ActionTypes from '../../../ActionTypes';

const companyNotes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.CompanyNotesLoaded:
    case ActionTypes.AddressBook.Modification.CompanyNoteSaved:
      return action.notes;
    case ActionTypes.AddressBook.Modification.Disposed:
      return [];
    default:
      return state;
  }
};

export default companyNotes;