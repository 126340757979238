import ActionTypes from '../../../ActionTypes';

const shipmentTrackingNotes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.LoadTrackingNotes.Disposed:
      return [];
    case ActionTypes.Shipment.LoadTrackingNotes.Loaded:
    case ActionTypes.Shipment.SaveTrackingNote.Saved:
      return action.loadTrackingNotes;
    default:
      return state;
  }
};

export default shipmentTrackingNotes;