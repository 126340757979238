import React from 'react';
import Grid from '@material-ui/core/Grid';

import ComponentBuilder from "../../../../core/ComponentBuilder";
import AppInput from "../../../../core/components/inputs/AppInput/AppMuiInput";
import SecureField from "../../permissions/SecureField";
import AppMuiPhoneInput from "../../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";

const GeneralNotesCard = (props) => {

  const {
    note,
    rateConNote,
    ltlCarrierPickupNumber,
    pickupTerminalNumber,
    deliveryTerminalNumber,
    ratingRefNumber,
    proNumber,
    allowRatingNote,
    allowRatingRef,
    setFieldValue,
    isTlCategory,
    errors
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
            <Grid item xs={12} md={isTlCategory ? 12 : 6}>
                <SecureField
                    pageName={'details'}
                    fieldName={'note'}
                    as={AppInput}
                    id={'generalNotes'}
                    label={'General Notes (Appear on the Bill of Lading and Rate Con)'}
                    multiline={true}
                    rows={'6'}
                    value={note || ''}
                    inputProps={{
                        maxLength: 850
                    }}
                    onChange={handleFieldChange('note')}
                />
            </Grid>
            {
                allowRatingRef &&
                <Grid item md={6} spacing={2}>
                    <Grid container item spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'ratingRefNumber'}
                                as={AppInput}
                                id={'ratingRefNumber'}
                                label={'Third Party Shipment #'}
                                inputProps={{
                                    maxLength: 25
                                }}
                                value={ratingRefNumber || ''}
                                onChange={handleFieldChange('ratingRefNumber')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'ltlCarrierPickupNumber'}
                                as={AppInput}
                                id={'ltlCarrierPickupNumber'}
                                label={'LTL Carrier Pickup #'}
                                inputProps={{
                                    maxLength: 25
                                }}
                                value={ltlCarrierPickupNumber || ''}
                                onChange={handleFieldChange('ltlCarrierPickupNumber')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'pickupTerminalNumber'}
                                as={AppMuiPhoneInput}
                                id={'pickupTerminalNumber'}
                                label={'Pickup Terminal #'}
                                value={pickupTerminalNumber || ''}
                                onChange={handleFieldChange('pickupTerminalNumber')}
                            />
                            <AppValidationFailure
                                message={errors.pickupTerminalNumber}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'deliveryTerminalNumber'}
                                as={AppMuiPhoneInput}
                                id={'deliveryTerminalNumber'}
                                label={'Delivery Terminal #'}
                                value={deliveryTerminalNumber || ''}
                                onChange={handleFieldChange('deliveryTerminalNumber')}
                            />
                            <AppValidationFailure
                                message={errors.deliveryTerminalNumber}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
        <Grid container item spacing={2}>
          {
              allowRatingNote &&
              <Grid item xs={12} md={isTlCategory ? 12 : 6}>
                  <SecureField
                      pageName={'details'}
                      fieldName={'rateConNote'}
                      as={AppInput}
                      id={'rateConfirmationNotes'}
                      label={'Rate Con Notes (Appear only on the Rate Con)'}
                      multiline={true}
                      rows={'6'}
                      value={rateConNote || ''}
                      inputProps={{
                          maxLength: 850
                      }}
                      onChange={handleFieldChange('rateConNote')}
                  />
              </Grid>
          }
        </Grid>
        <Grid container item spacing={2}>
            <Grid item xs={12} md={isTlCategory ? 12 : 6}>
                <SecureField
                    pageName={'details'}
                    fieldName={'proNumber'}
                    as={AppInput}
                    id={'proNumber'}
                    label={'Tracking #'}
                    value={proNumber || ''}
                    inputProps={{
                        maxLength: 30
                    }}
                    onChange={handleFieldChange('proNumber')}
                />
            </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
};

export default ComponentBuilder
  .wrap(GeneralNotesCard)
  .build();