import Core from '@atomos/core';
import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const identify = async (shell) => {

  const associate = await shell.gateway.whoami();

  let modifyingAssociate;

  // If the associate has a parent, go ahead and fetch
  // it to have it throughout the life of the app.
  if (Core.Utils.isNumber(associate.parentAssociateId)) {
    modifyingAssociate = await shell.gateway.getAssociate(associate.parentAssociateId);
  }

  modifyingAssociate = modifyingAssociate || associate;

  return {
    type: ActionTypes.Persona.Identified,
    associate,
    modifyingAssociate,
    agent:modifyingAssociate ?? associate
  };

};

export default ActionBuilder
  .for(identify)
  .build();