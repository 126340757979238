import {Box, Grid} from "@material-ui/core";
import React from "react";
import AppDialog from "../../../../../core/components/AppDialog/AppMuiDialog";
import AppDropdown from "../../../../../core/components/dropdowns/AppDropdown";
import AppInput from "../../../../../core/components/inputs/AppInput/AppMuiInput";
import AppText from "../../../../../core/components/text/AppText";
import AppMuiCheckbox from "../../../../../core/components/inputs/AppCheckbox";
import './QaFailedReportCloseDialog.scss';
import {Link} from "react-router-dom";
import TeraCrunchDialogTable from "../../includes/TeraCrunchDialogTable";

const QaFailedReportCloseDialog = (props) => {

  const {
    onCancelClick,
    onSaveClick,
    listAutomationDocuments,
    activeRecord,
    listAllDispositionStatuses,
    fetchHardFailReasons,
    checkIfShipmentExistsInCrm
  } = props;

  const [checkRequired, setCheckRequired] = React.useState(true);
  const [acknowledgedChecked, setAcknowledgedCheckedChanged] = React.useState(false);
  const [closeNote, setCloseNote] = React.useState('');
  const [documents, setDocuments] = React.useState([]);

  const [selectedValue, setSelectedValue] = React.useState(null);

  const [values, setValues] = React.useState([]);
  const [hardFailReasons, setHardFailReasons] = React.useState(null);
  const [shipmentExistsInCrm, setShipmentExistsInCrm] = React.useState(false);

  React.useEffect(() => {
    checkIfShipmentExistsInCrm(activeRecord.bolNumber).then((data) => {
      data.count !== 0 ? setShipmentExistsInCrm(true) : setShipmentExistsInCrm(false);
    })
  },[])

  React.useEffect(() => {
    if (values.length === 0) {
      listAllDispositionStatuses().then((data) => {
        setValues(data);
      });
    }
  }, []);

  React.useEffect(() => {
    fetchHardFailReasons(activeRecord.bolNumber).then((data) => {
      data.length !== 0 ? setHardFailReasons(data) : setHardFailReasons(null)
    })
  }, []);

  React.useEffect(() => {
    if (documents.length === 0) {
      listAutomationDocuments(activeRecord.bolNumber).then((data) => {
        setDocuments(data.documents);
      });
    }
  }, []);

  const conditionalShipmentLink = () => {
    return (
      <>
        {shipmentExistsInCrm ?
          <Link to={`/shipment/${activeRecord.bolNumber}/details`} target={"_blank"}>{activeRecord.bolNumber}</Link>
          :
          activeRecord.bolNumber}
      </>
    )
  }

  const saveClick = () => {
    onSaveClick(selectedValue.id, closeNote);
  }

  const actions = [{
    title: 'Save', action: saveClick, disabled: checkRequired ? !closeNote || !acknowledgedChecked : false
  }, {
    title: 'Cancel', action: onCancelClick
  }];

  const onCloseStatusChange = (e, closeStatus) => {
    setSelectedValue(closeStatus);

    if (closeStatus.dispositionStatus === 'Invoice Disregarded') {
      setCheckRequired(true);
    } else {
      setCheckRequired(false);
    }
  }

  const onCloseNoteChange = (e) => {
    setCloseNote(e.target.value);
  }

  const acknowledgedCheckedChanged = (e, value) => {
    setAcknowledgedCheckedChanged(value);
  }

  const handleOpenDownloadFileClick = (document, shouldOpen) => {
    const a = window.document.createElement('a');

    shouldOpen ? a.target = '_blank' : a.download = document.filename;

    a.href = `/api/automation/${document.id}/document`;
    a.click();
    a.remove();
  };

  return (
    <AppDialog
      title='QA Failed Report - Close Dialog'
      width='md'
      open={true}
      actionButtons={actions}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid style={{padding: 0}} item>
              <p style={{padding: 0}} className={"qa-dialog-shipment-p"}>TeraCrunch Shipment #{conditionalShipmentLink()}</p>
            </Grid>
            <AppText value={'RECEIVED DOCUMENTS'}/>
            {
              documents.map(doc =>
                <Grid item xs={12} key={doc.id}>
                  <a
                    onClick={handleOpenDownloadFileClick.bind(this, doc, true)}>
                    {doc.filename}
                  </a>
                </Grid>
              )
            }
          </Grid>
        </Grid>
        {activeRecord &&
          <Grid item xs={9}>
            <TeraCrunchDialogTable activeRecord={activeRecord} />
          </Grid>
        }
        <Grid container xs={12}>
            <Grid item xs={12} md={3}>
              {hardFailReasons &&
                <div style={{color: "red", paddingRight: 8}}>
                  <h3>Hard fail reasons</h3>
                  {hardFailReasons.map((fail) => {
                    return (
                      <>
                        <p style={{margin: '4px 8px'}}>{fail}</p>
                        <hr style={{margin: '1px 16px 6px 1px', height: 1, background: "lightgray", border:  "none"}} />
                      </>
                    )
                  })}
                </div>
              }
            </Grid>
          <Grid item xs={12} md={8} alignItems={"center"} style={{marginTop: 8}}>
            <AppDropdown
              label={'Failure Disposition: '}
              field={'dispositionStatus'}
              key={selectedValue}
              defaultValue={selectedValue}
              options={values}
              onChange={onCloseStatusChange}
            ></AppDropdown>
            <AppInput
              label={'Disposition Notes'}
              multiline={true}
              rows={'6'}
              value={closeNote || ''}
              inputProps={{
                maxLength: 850
              }}
              onChange={onCloseNoteChange}
            />
            <AppText value={'* required'}
                     style={{visibility: checkRequired ? 'visible' : 'hidden', color: 'red'}}></AppText>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Box display="flex" justifyContent="flex-end">
                <AppMuiCheckbox
                  style={{visibility: checkRequired ? 'visible' : 'hidden'}}
                  label={'I confirm this record was disregard.'}
                  onChange={acknowledgedCheckedChanged}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppDialog>
  )
};

export default QaFailedReportCloseDialog;
