import React from 'react';

import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {makeStyles} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {ErrorOutline, FindInPage as FindInPageIcon} from '@material-ui/icons';
import AppTooltip from '../../../../core/components/AppTooltip';
import combineClassNames from '../../../../core/utils/combineClassNames';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";

const useStyles = makeStyles((theme) => ({
  daysPastDueContainer: {
    '& svg': {
      marginTop: 1,
      height: 16,
      color: theme.palette.error.main
    }
  },
  lessThanZero: {
    color: theme.palette.error.main
  }
}));

const TotalMarginCell = ({ rowData: shipment }) => {
  const carrierCost = typeof(shipment.carrierCost) === 'number' ? shipment.carrierCost : 0;
  const customerCost = typeof(shipment.customerCost) === 'number' ? shipment.customerCost : 0;
  const totalMargin = customerCost - carrierCost;
  return (
    <AppCurrencyText value={totalMargin} />
  );
};

const DaysPastDueCell = (classes) => ({ rowData: shipment }) => {
  const pastDueClass = shipment.isPastDue ?
    classes.lessThanZero : null;
  const valueClass = combineClassNames('value', pastDueClass);
  const disputeIcon = shipment.isDisputed ?
    (<Grid item><Disputed /></Grid>) : null;
  const claimIcon = shipment.shipmentStatusName === "Claim" ?
      (<Grid  item><InClaimStatusIconTooltip /></Grid>) : null;
  return (
    <React.Fragment>
      <Grid container className={classes.daysPastDueContainer}>
        <Grid item className={valueClass}>
          { shipment.daysPastDue }
        </Grid>
        {disputeIcon}
        {claimIcon}
      </Grid>
    </React.Fragment>
  );
};

const InvoiceAgingListingTable = (props) => {

  const classes = useStyles();

  const {
    count,
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    onDocumentQuickViewClick
  } = props;

  const GridColumns = [
    {
      title: 'Rep',
      field: 'repName',
      styles: {
        width: '4%',
      },
      dataType: 'component',
      component: ({ rowData: shipmentRecord }) => {
        return (
          <AgentTooltipComponent content={shipmentRecord.repName} agentId={shipmentRecord.associateId}/>
        );
      },
    },
    {
      title: 'Ship #',
      field: 'bolNumber',
      dataType: 'component',
      styles: {
        width: '5%'
      },
      component: AppDenseGrid.Cells
        .LinkCell((shipment) => [`/shipment/${shipment.bolNumber}/details`, shipment.bolNumber])
    },
    {
      field: 'carrierCost',
      title: 'Carrier Cost',
      dataType: 'currency',
      styles: {
        width: '6%'
      },
    },
    {
      field: 'customerCost',
      title: 'Customer Cost',
      dataType: 'currency',
      styles: {
        width: '6%'
      },
    },
    {
      title: 'Total Margin',
      field: 'totalMargin',
      styles: {
        width: '6%',
      },
      noSort: true,
      dataType: 'component',
      component: TotalMarginCell
    },
    {
      title: 'Freight Cat.',
      field: 'freightCategory',
      dataType: 'string',
      styles: {
        width: '3%'
      },
    },
    {
      field: 'carrierName',
      title: 'Carrier Name',
      dataType: 'component',
      styles: {
        width: '19%'
      },
      component: AppDenseGrid.Cells
        .LinkCell((shipment) => [`/carrier/${shipment.carrierMcNumber}/details`, shipment.carrierName])
    },
    {
      title: 'Third Party',
      field: 'thirdPartyCompanyName',
      dataType: 'string',
      styles: {
        width: '9%'
      },
    },
    {
      title: 'Pickup Date',
      field: 'bolDate',
      dataType: 'date',
      styles: {
        width: '6%'
      }
    },
    {
      field: 'customerName',
      title: 'Customer Name',
      dataType: 'component',
      styles: {
        width: '19%'
      },
      component: AppDenseGrid.Cells
        .LinkCell((shipment) => [`/address-book/${shipment.customerId}/details`, shipment.customerName])
    },
    {
      field: 'invoiceDate',
      title: 'Inv. Date',
      dataType: 'date',
      styles: {
        width: '6%'
      }
    },
    {
      field: 'customerInvoiceDueDate',
      title: 'Inv. Due Date',
      dataType: 'date',
      styles: {
        width: '6%'
      }
    },
    {
      field: 'daysPastDue',
      title: 'Past Due',
      dataType: 'component',
      styles: {
        width: '5%'
      },
      component: DaysPastDueCell(classes)
    },
    {
      field: '',
      title: '',
      component: ({ rowData: shipmentRecord }) => {
        return (
          <div style={{textAlign: 'center'}}>
            <AppIconButton Icon={FindInPageIcon} tooltip={'Quick view the shipment\'s documents.'} onClick={() => onDocumentQuickViewClick(shipmentRecord)}/>
          </div>
        );
      },
      styles: {
        width: 40
      },
      dataType: 'component'
    }
  ];

  return (
    <AppDenseGrid
      count={count}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

const Disputed = (props) => (
  <AppTooltip title={'This shipment is in Dispute status.'}>
    <ErrorOutline style={{color: '#ffb700'}} />
  </AppTooltip>
);

const InClaimStatusIconTooltip = (props) => (
    <AppTooltip title={'This shipment is in Claim status.'}>
      <ErrorOutline style={{color: '#8c00ff'}}/>
    </AppTooltip>
);

export default InvoiceAgingListingTable;
