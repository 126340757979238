import {
    loadFlagBlindActiveIcon, loadFlagBlindInactiveIcon,
    loadFlagHazardousActiveIcon, loadFlagHazardousInactiveIcon,
    loadFlagMultistopInactiveIcon,
    loadFlagMutlistopActiveIcon
} from "../../../media/icons/exportCustomIcons";
import React from "react";

const computeIcon = (bool, activeIcon, inactiveIcon, altMessage1, altMessage2) => {
    return bool ?
        <img style={{height: 19, width: 19}} src={activeIcon} alt={altMessage1}/> :
        <img style={{height: 19, width: 19}} src={inactiveIcon} alt={altMessage2}/>;
}

export const LoadFlagsCell = (props) => {
    const isMultiStop = props.dataItem?.isMultiStop;
    const isHazardous = props.dataItem?.isHazMat;
    const isBlind = props.dataItem?.isBlind;
    return (
        <td>
            <>
                <div className={"load-flags-cell"}>
                    <span>{computeIcon(isMultiStop, loadFlagMutlistopActiveIcon, loadFlagMultistopInactiveIcon, "is multistop", "is not multistop")}</span>
                    <span>{computeIcon(isHazardous, loadFlagHazardousActiveIcon, loadFlagHazardousInactiveIcon, "is hazardous", "is not hazardous")}</span>
                    <span>{computeIcon(isBlind, loadFlagBlindActiveIcon, loadFlagBlindInactiveIcon, "is blind", "is not blind")}</span>
                </div>
            </>
        </td>
    )
}