import palette from '../palette';

export default {
  root: {
    '& tr.MuiTableRow-root:nth-child(even)': {
      backgroundColor: palette.tgfGrey.light
    },
    '& tr.MuiTableRow-root:hover, & tr.MuiTableRow-root:nth-child(even):hover': {
      backgroundColor: palette.tgfGreen.light
    },
  }
};
