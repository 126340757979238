import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveCarrierNote = async (shell, mcNumber, noteText) => {
  const state = shell.stateStore.getState();
  const associate = state.persona.associate;
  const today = new Date();

  const note = shell.gateway.createCarrierNote();
  note.content = noteText;
  note.mcNumber = mcNumber;
  note.associateId = associate.id;
  note.createDate = today;

  // eslint-disable-next-line no-unused-vars
  const updatedNoteData = await shell.gateway.saveCarrierNote(note);

  const notes = await shell.gateway.getAllCarrierNotes(mcNumber);

  return {
    type: ActionTypes.Carrier.Modification.CarrierNoteSaved,
    notes
  };

};

export default ActionBuilder
  .for(saveCarrierNote)
  .build();