import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadShipmentStatusTypes = async (shell) => {

  const shipmentStatusTypes = await shell.gateway.getStatusTypes();

  return {
    type: ActionTypes.Support.ShipmentStatusTypesLoaded,
    shipmentStatusTypes
  };

};

export default ActionBuilder
  .for(loadShipmentStatusTypes)
  .build();