import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipmentTrackingNotes = async (shell, bolNumber) => {

  const loadTrackingNotes = await shell.gateway.getAllShipmentTrackingNotes(bolNumber);

  return {
    type: ActionTypes.Shipment.LoadTrackingNotes.Loaded,
    loadTrackingNotes
  };
};

export default ActionBuilder
  .for(loadShipmentTrackingNotes)
  .build();
