import * as Redux from 'redux';
import billingCompanyCategoryType from './billingCompanyCategoryType';
import companyCategoryTypes from './companyCategoryTypes';
import companyInvoiceDeliveryMethodTypes from './companyInvoiceDeliveryMethodTypes';
import customerCategoryType from './customerCategoryType';
import factoringCompanyCategoryType from './factoringCompanyCategoryType';
import freightCategoryTypes from "./freightCategoryTypes";
import helpDocuments from './helpDocuments';
import leadCategoryType from './leadCategoryType';
import paymentTerms from './paymentTerms';
import shipmentTrackingPhases from './shipmentTrackingPhases';
import shipperConsigneeCategoryType from './shipperConsigneeCategoryType';
import stateProvinces from './stateProvinces';
import thirdPartyCategoryType from './thirdPartyCategoryType';
import shipmentDocumentTypes from './shipmentDocumentTypes';
import companyDocumentTypes from './companyDocumentTypes';
import carrierDocumentTypes from './carrierDocumentTypes';
import shipmentEquipmentTypes from "./shipmentEquipmentTypes";
import shipmentFreightClasses from './shipmentFreightClasses';
import shipmentHazardousStates from "./shipmentHazardousStates";
import shipmentPackageTypes from './shipmentPackageTypes';
import shipmentStatusTypes from "./shipmentStatusTypes";
import shipmentTruckLoadValueConfirmationTypes from "./shipmentTruckLoadValueConfirmationType";

export default Redux.combineReducers({
  billingCompanyCategoryType,
  companyDocumentTypes,
  companyInvoiceDeliveryMethodTypes,
  companyCategoryTypes,
  customerCategoryType,
  factoringCompanyCategoryType,
  freightCategoryTypes,
  helpDocuments,
  leadCategoryType,
  paymentTerms,
  shipmentTrackingPhases,
  shipperConsigneeCategoryType,
  stateProvinces,
  thirdPartyCategoryType,
  carrierDocumentTypes,
  shipmentDocumentTypes,
  shipmentEquipmentTypes,
  shipmentFreightClasses,
  shipmentHazardousStates,
  shipmentPackageTypes,
  shipmentStatusTypes,
  shipmentTruckLoadValueConfirmationTypes
});