import ActionTypes from '../../../ActionTypes';

const companyFinancialHealthSummary = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.Disposed:
      return null;
    case ActionTypes.AddressBook.Modification.CompanyFinancialHealthSummaryLoaded:
      return action.companyFinancialHealthSummary;
    default:
      return state;
  }
};

export default companyFinancialHealthSummary;