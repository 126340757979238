import React from 'react';
import FullWidthLayout from "../../core/layouts/FullWidthLayout";
import {Grid} from "@material-ui/core";
import TgfDataGrid from "../../core/kendo/dataGrid/TgfDataGrid";
import TgfLabeledInput from "../../core/kendo/inputs/TgfLabeledInput";
import DebouncedTextBox from "../../core/kendo/inputs/DebouncedTextBox";
import AppNumberText from "../../core/components/text/AppNumberText";
import {useLocation} from "react-router-dom";

const InvoiceMemoLineItemsPage = (props) => {
    const ref = React.useRef();

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const initialFilter = query.get('filter') ? `=${query.get('filter')}` : '';
    console.log(initialFilter);

    const [rowCount, setRowCount] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState(initialFilter);

    React.useEffect(() => {
        ref.current.refresh();
    }, [searchTerm]);

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 50,
            skip: 0
        };

        if(searchTerm != null && searchTerm.length > 0) {
            if(searchTerm.startsWith('=')) {
                odata.filter = {
                    odata: searchTerm.substring(1)
                };
            }
            else {
                odata.filter.or = {
                    //lineItemId: {eq: searchTerm},
                    //lineItemAcumaticaId: {eq: searchTerm},
                    //invoiceMemoId: {eq: searchTerm},
                    invoiceMemoAcumaticaRef: {substringof: searchTerm},
                    invoiceAmount: {eq: searchTerm},
                    invoiceCustomerOrder: {eq: searchTerm},
                    invoiceType: {eq: searchTerm},
                    invoiceHold: {eq: searchTerm},
                    invoicePostPeriod: {eq: searchTerm},
                    invoiceBalance: {eq: searchTerm},
                    invoiceDueDate: {eq: searchTerm},
                    //shipmentId: {eq: searchTerm},
                    bolNumber: {eq: searchTerm},
                    shipmentStatus: {eq: searchTerm},
                    associateId: {eq: searchTerm},
                    //customerId: {eq: searchTerm},
                    customerAcumaticaRef: {substringof: searchTerm},
                    customerName: {substringof: searchTerm},
                    account: {eq: searchTerm},
                    subAccount: {eq: searchTerm},
                    branch: {eq: searchTerm},
                    inventoryId: {eq: searchTerm},
                    description: {substringof: searchTerm},
                    quantity: {eq: searchTerm},
                    discount: {eq: searchTerm},
                    extendedPrice: {eq: searchTerm},
                    unitPrice: {eq: searchTerm},
                    lineNumber: {eq: searchTerm},
                    unitOfMeasure: {eq: searchTerm},
                    // createdDateTime: {eq: searchTerm},
                    // lastModifiedDateTime: {eq: searchTerm},
                    // acumaticaLastModifiedDateTime: {eq: searchTerm},
                };
            }
        }

        return odata;
    }

    const loadData = async (odata) => {
        let explicitFilter = null;
        if(typeof odata.filter.odata ) {
            explicitFilter = odata.filter.odata
        }

        const dataPage = await window.shell.gateway.getInvoiceMemoLineItems(odata, explicitFilter);
        setRowCount(dataPage.totalItemCount);
        setPageCount(dataPage.totalPageCount);
        return dataPage;
    }

    const GridColumns = [
        {field: "lineItemId", title: "Line Item ID", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "lineItemAcumaticaId", title: "Line Item Acumatica ID", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceMemoId", title: "Invoice Memo Id", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceMemoAcumaticaRef", title: "Invoice Acumatica ID", type: "link", url:(dataItem) => `/receivables/invoice-memos?filter=acumaticaRef eq '${dataItem.invoiceMemoAcumaticaRef}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceAmount", title: "Invoice Amount", type: "currency", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceType", title: "Invoice Type", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceHold", title: "Invoice Hold", type: "boolean", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoicePostPeriod", title: "Post Period", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceBalance", title: "Invoice Balance", type: "currency", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceDueDate", title: "Invoice Due Date", type: "dateFromUTCDateTime", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceCustomerOrder", title: "Customer Order", type: "link", url:(dataItem) => `/receivables/payment-applications?filter=invoiceCustomerOrder eq '${dataItem.invoiceCustomerOrder}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "shipmentId", title: "Shipment ID", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "bolNumber", title: "Bol Number", type: "link", url:(dataItem) => `/shipment/${dataItem.bolNumber}/details`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "shipmentStatus", title: "Shipment Status", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "associateId", title: "Associate ID", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerId", title: "Customer ID", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerAcumaticaRef", title: "Customer Acumatica Ref", type: "link", url:(dataItem) => `/address-book/${dataItem.customerId}/details`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerName", title: "Customer", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "account", title: "Account", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "subAccount", title: "Sub Account", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "branch", title: "Branc", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "inventoryId", title: "Inventory ID", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "description", title: "Description", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "quantity", title: "Quantity", type: "number", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "discount", title: "Discount", type: "currency", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "extendedPrice", title: "Extended Price", type: "currency", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "unitPrice", title: "Unit Price", type: "currency", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "lineNumber", title: "Line Number", type: "number", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "unitOfMeasure", title: "Unit Of Measure", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "createdDateTime", title: "CRM Created DateTime", type: "dateTimeFromUTCDateTime", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "lastModifiedDateTime", title: "CRM Modified DateTime", type: "dateTimeFromUTCDateTime", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaLastModifiedDateTime", title: "Acumatica Modified DateTime", type: "dateTimeFromUTCDateTime", className: "show-all-text", headerClassName: "show-all-text"}
    ];

    const included = [
        //"lineItemId",
        //"lineItemAcumaticaId",
        //"invoiceMemoId",
        "invoiceMemoAcumaticaRef",
        "invoiceAmount",
        "invoiceCustomerOrder",
        "invoiceType",
        "invoiceHold",
        "invoicePostPeriod",
        "invoiceBalance",
        "invoiceDueDate",
        //"shipmentId",
        "bolNumber",
        "shipmentStatus",
        "associateId",
        //"customerId",
        "customerAcumaticaRef",
        "customerName",
        "account",
        //"subAccount",
        //"branch",
        "inventoryId",
        "description",
        "quantity",
        "discount",
        "extendedPrice",
        "unitPrice",
        "lineNumber",
        //"unitOfMeasure",
        // "createdDateTime",
        // "lastModifiedDateTime",
        // "acumaticaLastModifiedDateTime"
    ];

    return (
        <FullWidthLayout title="Invoices and Memos - Line Items">
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfLabeledInput
                        label={"Search"}
                        inputComponent={<DebouncedTextBox
                            id={"searchTerm"}
                            value={searchTerm}
                            onChange={handleSearchTerm}
                        />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfDataGrid
                        className={"tgf-data-grid"}
                        ref={ref}
                        onLoadDataPage={loadData}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                        onDataPageLoaded={props.onDataChanged}
                        gridColumns={GridColumns.filter(c => included.some(i => i === c.field))}
                        sort={[{field: "invoiceDueDate", dir: "desc"}]}
                        pageable={{
                            pageSize: 20,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500]
                        }}
                    />
                    <div style={{ padding: 10, backgroundColor: '#74747422' }}>
                        <span style={{float: 'left'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={rowCount} /> Rows
                        </span>
                        <span style={{float: 'right'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={pageCount} /> Pages
                        </span>
                        <span style={{clear: 'both'}}>&nbsp;</span>
                    </div>
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
};
export default InvoiceMemoLineItemsPage