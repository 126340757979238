import React from 'react';
import {Grid} from '@material-ui/core';
import {Save, Undo} from '@material-ui/icons';
import AppButton from 'core/components/AppButton/AppMuiButton';
import ComponentBuilder from 'core/ComponentBuilder';
import FormFactorGraft from 'core/FormFactor/FormFactorGraft';



const FormButtonBar = (props) => {

  const {
    classes,
    disableEdit,
  } = props;

  const saveSlices = ['isDirty', 'isValid'];
  const saveGraftMap = ([isDirty, isValid]) => ({
    disabled: (!isValid || !isDirty || disableEdit)
  });

  const resetSlices = ['isDirty', 'resetForm'];
  const resetGraftMap = ([isDirty, resetForm]) => ({
    disabled: (!isDirty || disableEdit),
    onClick: () => resetForm()
  });



  return (
    <Grid container className={classes.buttonGroup}>
      <Grid item>
        <FormFactorGraft
          as={AppButton}
          startIcon={<Save />}
          type={'submit'}
          slices={saveSlices}
          graftMap={saveGraftMap}

        >
          Save
        </FormFactorGraft>
      </Grid>
      <Grid item>
        <FormFactorGraft
          as={AppButton}
          startIcon={<Undo />}
          color={'warning'}
          slices={resetSlices}
          graftMap={resetGraftMap}
        >
          Reset
        </FormFactorGraft>
      </Grid>
    </Grid>
  );
};

export default ComponentBuilder
  .wrap(FormButtonBar)
  .withStyles({
    buttonGroup: {
      '& > *': {
        marginRight: '1rem',
      },
      '& > *:last-child': {
        marginRight: 0,
      }
    }
  })
  .build();