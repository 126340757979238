import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const dispose = (context) => {
  return {
    type: ActionTypes.Carrier.Modification.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();