import React, {useEffect} from 'react';


export function usePageTitle(title) {

    useEffect(() => {
        document.title = title ?
            `${title}` :
            `TGF: CRM Workspace`;
        return () => {
            document.title = `TGF: CRM Workspace`;
        }
    }, [title]);

    return null;
}