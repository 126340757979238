import React from 'react';
import {Grid} from "@material-ui/core";
import ComponentBuilder from "../../../../core/ComponentBuilder";

import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppValidationFailure from "../../../../core/components/AppValidationFailure";

import '../financialPage.scss';
import SecureField from "../../permissions/SecureField";

const FinancialNotes = (props) => {

  const {
    invoiceNote,
    financialNote,
    invoiceNoteError,
    financialNoteError,
    setFieldValue
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(`invoice.${key}`, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={6}>
        <SecureField
          pageName={'financial'}
          fieldName={'invoiceNote'}
          as={AppInput}
          id={'invoiceNote'}
          label={'Invoice Notes (Appear on the customer\'s invoice.)'}
          multiline={true}
          rows={'6'}
          value={invoiceNote || ''}
          inputProps={{
            maxLength: 500
          }}
          onChange={handleFieldChange('invoiceNote')}
        />
        <AppValidationFailure message={invoiceNoteError}/>
      </Grid>
      <Grid item xs={12} md={6}>
        <SecureField
          pageName={'financial'}
          fieldName={'financialNote'}
          as={AppInput}
          id={'financialNote'}
          label={'Internal Notes (Available for internal viewing only.)'}
          multiline={true}
          rows={'6'}
          inputProps={{
            maxLength: 500
          }}
          value={financialNote || ''}
          onChange={handleFieldChange('financialNote')}
        />
        <AppValidationFailure message={financialNoteError}/>
      </Grid>
    </React.Fragment>
  )
};

const propsAreEqual = (prevProps, nextProps) => {
  const isEqual = prevProps.invoiceNote === nextProps.invoiceNote &&
    prevProps.financialNote === nextProps.financialNote &&
    prevProps.invoiceNoteError === nextProps.invoiceNoteError &&
    prevProps.financialNoteError === nextProps.financialNoteError;

  return isEqual;
};

export default React.memo(ComponentBuilder
    .wrap(FinancialNotes)
    .build(),
  propsAreEqual
);