import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import AppCard from '../../../../../core/components/cards/AppCard';
import AppDrawer from '../../../../../core/components/AppDrawer';
import AppMuiButton from '../../../../../core/components/AppButton';
import AppSwitch from '../../../../../core/components/inputs/AppSwitch';
import FormFactor from "../../../../../core/FormFactor/FormFactor";
import AppCurrencyText from '../../../../../core/components/text/AppCurrencyText';
import AppDateText from '../../../../../core/components/text/AppDateText';

const useStyle = makeStyles(theme => ({
  lessThanZero: {
    color: theme.palette.error.main
  }
}));

const ShipmentPaymentDrawer = (props) => {
  const classes = useStyle();
  const {
    isOpen,
    shipment,
    onSaveClick,
    onCancelClick
  } = props;

  const initialValues = {
    ...shipment,
    onCancelClick: onCancelClick,
    onSaveClick: onSaveClick
  };

  const handleShipmentSubmit = (values, formFactor) => {
    values.onSaveClick(values);
  };

  const buildAmount = (amount) =>
    <AppCurrencyText className={parseFloat(amount) < 0 ? classes.lessThanZero : null} value={amount} />;

  const buildLabel = (amount, label) => {
    return (
      <span className={parseFloat(amount) < 0 ? classes.lessThanZero : null}>
        { label }
      </span>
    )
  };

  const buildDate = (date) =>
    <AppDateText value={date} />;

  const shipmentData = shipment ? [
    {
      title: "Ship #",
      value: shipment.bolNumber
    },
    {
      title: buildLabel(shipment.adjustedCarrierCost, "Carrier Cost"),
      value: buildAmount(shipment.adjustedCarrierCost)
    },
    {
      title: buildLabel(shipment.fee, "TGF Fee"),
      value: buildAmount(shipment.fee)
    },
    {
      title: buildLabel(shipment.adjustedCustomerCost, "Customer Cost"),
      value: buildAmount(shipment.adjustedCustomerCost)
    },
    {
      title: buildLabel(shipment.totalMargin, "Total Margin"),
      value: buildAmount(shipment.totalMargin)
    },
    {
      title: "Type of Load",
      value: shipment.loadType
    },
    {
      title: "Carrier Name",
      value: shipment.carrierName
    },
    {
      title: "Third Party",
      value: shipment.thirdPartyName
    },
    {
      title: "Pickup Date",
      value: buildDate(shipment.bolDate)
    },
    {
      title: "Customer Name",
      value: shipment.customerName
    },
    {
      title: buildLabel(shipment.earnedPayout, "Earned Payout"),
      value: buildAmount(shipment.earnedPayout)
    },
    {
      title: "Locked Date",
      value: buildDate(shipment.actualDeliveryDate)
    }
  ] : [];

  return (
    <AppDrawer
      anchor="right"
      open={isOpen}
      onClose={onCancelClick}
    >
      <div style={{ width: 400, height: '100vh' }}>
        <AppCard title="Shipment">
          <Grid container spacing={1} justify="flex-start" alignItems="center">
            {
              shipmentData.map((item, key) => <React.Fragment key={key}>
                  <Grid item xs={12} md={6}>{item.title}</Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">
                      {item.value}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <FormFactor
                  initialValues={initialValues}
                  onSubmit={handleShipmentSubmit}
                >
                  {renderShipmentPaymentForm}
                </FormFactor>
              </Grid>
          </Grid>
        </AppCard>
      </div>
    </AppDrawer>
  );
};

const renderShipmentPaymentForm = ({values, errors, isValid, isDirty, setFieldValue, submitForm}) => {

  const handleRepPaidChange = (e) => {
    setFieldValue('repPaid', e.target.checked);
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12} md={6}>Rep Paid</Grid>
      <Grid item xs={12} md={6}>
        <AppSwitch
          id={'repPaid'}
          checked={Boolean(values.repPaid)}
          onChange={handleRepPaidChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item><AppMuiButton onClick={submitForm} disabled={!isValid || !isDirty}>Save</AppMuiButton></Grid>
          <Grid item><AppMuiButton onClick={values.onCancelClick}>Cancel</AppMuiButton></Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ShipmentPaymentDrawer;