import React from 'react';
import { Grid } from '@material-ui/core';
import ComponentBuilder from '../../../core/ComponentBuilder';
import ComplianceToolTip from "./ComplianceToolTip";

const ComplianceValueField = (props) => {

  const {
    classes,
    title,
    titleTooltip,
    value,
    valueTooltip,
    type
  } = props;

  const casedType = type.toLowerCase();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={7}>
          {
            titleTooltip ?
              <ComplianceToolTip title={titleTooltip} value={title}/> :
              title
          }
        </Grid>
        <Grid item xs={12} sm={5} className={classes[casedType]}>
          {
            valueTooltip ?
              <ComplianceToolTip title={valueTooltip} value={value}/> :
              value
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  pass: {
    color: theme.palette.success.main,
    textAlign: 'right',
    fontWeight: 600
  },
  fail: {
    color: theme.palette.danger.main,
    textAlign: 'right',
    fontWeight: 600
  },
  warning: {
    color: theme.palette.warning.main,
    textAlign: 'right',
    fontWeight: 600
  },
  none: {
    color: theme.palette.tgfBlack.main,
    textAlign: 'right',
    fontWeight: 600
  }
});

export default ComponentBuilder
  .wrap(ComplianceValueField)
  .withStyles(styles)
  .build();