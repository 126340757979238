import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";
import CommissionTypeNames from '../CommissionTypeNames';

const loadAssociateCommissionRate = async (shell, associateId, commissionDate) => {

  let associateCommissionRate = await shell.gateway.getCommissionRate(
    associateId,
    commissionDate
  );

  if (!associateCommissionRate) {
    associateCommissionRate = shell.gateway.createCommissionRate();
    associateCommissionRate.associateId = associateId;
    associateCommissionRate.commissionDate = commissionDate;
    associateCommissionRate.commissionType = CommissionTypeNames.Margin;
  }

  return {
    type: ActionTypes.CommissionCenter.CommissionRateLoaded,
    associateCommissionRate
  }

};

export default ActionBuilder
  .for(loadAssociateCommissionRate)
  .build();