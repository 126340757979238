import React from 'react';
import { Grid } from '@material-ui/core';

import AppCard from 'core/components/cards/AppCard';
import FormFactorValueInput from 'core/FormFactor/FormFactorValueInput';
import FormFactorValueDropdown from 'core/FormFactor/FormFactorValueDropdown';
import FormFactorValidationFailure from '../../../../core/FormFactor/FormFactorValidationFailure';
import AddressCopyButton from './AddressCopyButton';

const BillingAddressCard = (props) => {
  const {
    disableEdit
  } = props;


  const addressCopyAction = (
    <AddressCopyButton disableEdit={disableEdit} />
  );

  return (
    <AppCard title={'Billing Address'} action={addressCopyAction}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormFactorValueInput
            field={'billingAddress1'}
            id={'billingAddress1'}
            label={'Address Line 1'}
            inputProps={{ maxLength: 50 }}
            disabled={disableEdit}
          />
          <FormFactorValidationFailure field={'billingAddress1'} />
        </Grid>
        <Grid item xs={12}>
          <FormFactorValueInput
            field={'billingAddress2'}
            id={'billingAddress2'}
            label={'Address Line 2'}
            inputProps={{ maxLength: 50 }}
            disabled={disableEdit}
          />
          <FormFactorValidationFailure field={'billingAddress2'} />
        </Grid>
        <Grid item xs={12}>
          <FormFactorValueInput
            field={'billingCity'}
            id={'billingCity'}
            label={'City'}
            inputProps={{ maxLength: 50 }}
            disabled={disableEdit}
          />
          <FormFactorValidationFailure field={'billingCity'} />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormFactorValueDropdown
            formField={'selectedStateProvince'}
            optionsFormField={'stateProvinces'}
            optionField={'name'}
            optionFieldIndex={'abbreviation'}
            id={'selectedStateProvince'}
            label={'State/Province'}
            disabled={disableEdit}
          />
          <FormFactorValidationFailure field={'selectedStateProvince'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormFactorValueInput
            field={'billingPostalCode'}
            id={'billingPostalCode'}
            label={'Zip/Postal Code'}
            inputProps={{ minLength: 5, maxLength: 7 }}
            disabled={disableEdit}
          />
          <FormFactorValidationFailure field={'billingPostalCode'} />
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default BillingAddressCard;