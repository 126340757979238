import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

import AppButton from '../../../core/components/AppButton';
import AppCard from '../../../core/components/cards/AppCard';
import AppInput from '../../../core/components/inputs/AppInput';
import AppTitleBar from '../../../core/components/AppTitleBar';
import AppSwitch from '../../../core/components/inputs/AppSwitch';
import AppMuiNumericInput from '../../../core/components/inputs/AppNumericInput/AppMuiNumericInput';

const useStyles = makeStyles(theme => ({
  container: {
    width: 400,
    padding: 10
  },
  success: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}))

const formatCityState = (city, stateProvince) => {
  return city && stateProvince ?
    `${city}, ${stateProvince}` :
    '';
};

const EditOpenLoad = ({shipment, onClose, onSave}) => {
  const classes = useStyles();
  const [isCovered, setIsCovered] = React.useState(shipment.shipmentIsCovered);
  const [postedFor, setPostedFor] = React.useState(shipment.postedFor);
  const [notes, setNotes] = React.useState(shipment.shipmentOpenLoadNotes);

  const handleCoveredChange = (e) => {
    setIsCovered(e.target.checked);
  };

  const handlePostedForChange = (e) => {
    setPostedFor(e.target.value ? e.target.value : null);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value ? e.target.value : null);
  };

  const loadData = [
    {
      label: "REP",
      value: shipment.associateSystemId
    },
    {
      label: "Ship #",
      value: shipment.shipmentBolNumber
    },
    {
      label: "Pickup Date",
      value: shipment.shipmentBolDate
        .toMoment()
        .format('L')
    },
    {
      label: "Customer Name",
      value: shipment.customerCompanyName
    },
    {
      label: "From City, State",
      value: formatCityState(shipment.shipperCompanyCity, shipment.shipperCompanyStateProvince)
    },
    {
      label: "To City, State",
      value: formatCityState(shipment.consigneeCompanyCity, shipment.consigneeCompanyStateProvince)
    },
    {
      label: "Epuipment Type",
      value: shipment.shipmentEquipmentType
    },
    {
      label: "Customer Cost",
      value: shipment.customerCost
    }
  ];

  const handleSaveClick = (e) => {
    onSave({
      bolNumber: shipment.shipmentBolNumber,
      isCovered,
      postedFor,
      notes
    });
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppTitleBar title="Open Load Details"/>
        </Grid>
        <Grid item xs={12}>
          <AppCard>
            <Grid container spacing={1}>
              {
                loadData.map((item, key) =>
                  <React.Fragment key={key}>
                    <Grid item xs={4}>{item.label}</Grid>
                    <Grid item xs={8}>{item.value}</Grid>
                  </React.Fragment>
                )
              }
            </Grid>
          </AppCard>
        </Grid>
        <Grid item xs={12}>
          <AppCard title="Edit Load">
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} md={4}>
                    Covered
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <AppSwitch
                      checked={isCovered}
                      onChange={handleCoveredChange}
                      label={''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <AppMuiNumericInput
                  label={'Posted For'}
                  decimalScale={2}
                  value={postedFor}
                  onChange={handlePostedForChange}
                />
              </Grid>
              <Grid item>
                <AppInput
                  label={'Notes'}
                  multiline={true}
                  rows={4}
                  value={notes || ''}
                  inputProps={{
                    maxLength: 300
                  }}
                  onChange={handleNotesChange}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <AppButton onClick={handleSaveClick}>Save</AppButton>
                  </Grid>
                  <Grid item>
                    <AppButton onClick={onClose}>Cancel</AppButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AppCard>
        </Grid>

      </Grid>
    </div>
  )
}
export default EditOpenLoad;