import palette from '../palette';

export default {
    switchBase: {
        color: palette.white,
        '&$checked': {
            color: palette.primary.main
        },
        '&$checked + $track': {
            backgroundColor: palette.primary.main
        }
    }
  };