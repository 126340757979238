import * as yup from "yup";

export const visibilityValidationSchema =  yup.object().shape({
    permAllUsers: yup.boolean().test({
        test() {
            if(Object.values(this.parent).every((v) => v === false)) return this.createError({message: "At least 1 Required"});
            return true
        }
    }),
});

