import React from 'react';
import Core from '@atomos/core';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (width) => makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.tgfGreen.main,
    textAlign: 'center',
    color: theme.palette.white,
    width,
    maxWidth: width,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.tgfGrey.light}`,
  },
}))();

const AppTooltip = ({ children, title, placement = "bottom", width = 220, ...otherProps }) => {

  const classes = useStyles(width);

  if (!title)
    return children;

  const actualTitle = Core.Utils.isString(title) ?
    (<Typography color="inherit">{title}</Typography>) :
    title;

  return (
    <Tooltip
      title={actualTitle}
      classes={{ tooltip: classes.tooltip }}
      placement={placement}
      {...otherProps}>
      <span>
        {children}
      </span>
    </Tooltip>
  )
}

AppTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  children: PropTypes.element.isRequired,
  placement: PropTypes.string
};

export default AppTooltip;