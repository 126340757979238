const StatusTypeNames = {
  New: 'New',
  InTransit: 'In Transit',
  Hold: 'Hold',
  Dispute: 'Dispute',
  PaymentsPending: 'Payments Pending',
  InvoiceGenerated: 'Invoice Generated',
  CustomerPaid: 'Customer Paid',
  Delivered: 'Delivered',
  Locked: 'LOCKED',
  AwaitingPod: 'Awaiting POD',
  Claim: 'Claim',
  Void: "Void"
};

export default StatusTypeNames;
