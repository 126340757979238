import React from 'react';
import FormFactor from './FormFactor';
import FormFactorContext from './FormFactorContext';

class FasterFormFactor extends FormFactor {

  render() {

    const contextValue = this.buildContext();

    return (
      <FormFactorContext.Provider value={contextValue}>
        <form onSubmit={this.submitForm}>
          {this.props.children}
        </form>
      </FormFactorContext.Provider>
    );

  }

}

export default FasterFormFactor;