import React from 'react';
import { Grid } from '@material-ui/core';
import AppDrawer from "../../../../../core/components/AppDrawer";
import AppNumericInput from "../../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput";
import AppButton from "../../../../../core/components/AppButton/AppMuiButton";
import AppCard from "../../../../../core/components/cards/AppCard/AppMuiCard";
import AppDropdown from "../../../../../core/components/dropdowns/AppDropdown";
import FormFactor from "../../../../../core/FormFactor/FormFactor";
import * as Yup from "yup";
import When from "../../../../../core/components/condtionals/When";
import AppValidationFailure from "../../../../../core/components/AppValidationFailure";
import CommissionTypeLabels from '../CommissionTypeLabels';
import CommissionTypeNames from '../../../../../hubs/commission/CommissionTypeNames';
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import {FileCopy} from "@material-ui/icons";


const PayConfigurationDrawer = (props) => {

  const {
    isOpen,
    commissionRate,
    loadPrevCommissionRate,
    onCancelClick,
    onSaveClick
  } = props;

  const getCurrentCommissionType = () => {
    return commissionRate && commissionRate.commissionType ?
    CommissionTypeLabels.find(ct => ct.value === commissionRate.commissionType):
    CommissionTypeLabels[0];
  };

  const initialValues = {
    id: commissionRate?.id,
    associateId: commissionRate?.associateId,
    commissionDate: commissionRate?.commissionDate,
    commissionType: commissionRate?.commissionType || CommissionTypeNames.Margin,
    feePercent: commissionRate?.feePercent * 100,
    marginThreshold: commissionRate?.marginThreshold,
    feeGuaranteedAmount: commissionRate?.feeGuaranteedAmount,
    marginPercent: commissionRate?.marginPercent * 100,
    marginGuaranteedAmount: commissionRate?.marginGuaranteedAmount,
    isActive: commissionRate?.isActive,
    payConfigurationNumber: commissionRate?.payConfigurationNumber,
    selectedCommissionType: getCurrentCommissionType(),
    onCancelClick: onCancelClick,
    onSavePayConfigurationClick: onSaveClick,
    loadPrevCommissionRate,
    isCopy: false
  };

  const handlePayConfigurationSubmit = (values, formFactor) => {
    values.onSavePayConfigurationClick(values);
  };

  const PayConfigurationSchema = Yup.lazy(values => {
    const schema = {};

    if(values.commissionType === 'fee'){
      schema.feePercent = Yup.number()
        .typeError('Must be a number')
        .nullable()
        .min(0.1, 'Must enter value');
    }

    if(values.commissionType === 'margin'){
      schema.marginPercent = Yup.number()
        .typeError('Must be a number')
        .nullable()
        .min(0.1, 'Must enter value')
    }

    return Yup.object().shape(schema);
  });

  return (
    <AppDrawer
      anchor={'right'}
      open={isOpen}
      onClose={onCancelClick}
    >
      <FormFactor
        initialValues={initialValues}
        schema={PayConfigurationSchema}
        onSubmit={handlePayConfigurationSubmit}
      >
        {renderPayConfigurationFrom}
      </FormFactor>
    </AppDrawer>
  );
}

const renderPayConfigurationFrom = ({values, errors, isValid, isDirty, setFieldValue, submitForm}) => {

  const handleNumericFieldChange = (key) => (e) => {
    const value = e.target.value ?
      Number(e.target.value) : null;
    setFieldValue(key, value);

  };

  const handleCopyPreviousCommissionRate = () => {

    values.loadPrevCommissionRate()
      .then(prevCommRate => {

        if (prevCommRate) {
          setFieldValue('commissionType', prevCommRate.commissionType);
          setFieldValue('marginPercent', prevCommRate.marginPercent * 100);
          setFieldValue('feePercent', prevCommRate.feePercent * 100);
          setFieldValue('isCopy', true);
          setFieldValue('selectedCommissionType', CommissionTypeLabels
            .find(ct => ct.value === prevCommRate.commissionType));
        }

      });
  }

  const handleCommissionTypeChange = (e, value) => {
    const commissionType = value;
    setFieldValue('selectedCommissionType', commissionType);
    setFieldValue('commissionType', commissionType.value);

    if(commissionType.value === 'fee'){
      setFieldValue('marginPercent', 0.00);
      setFieldValue('marginGuaranteedAmount', 0.00);
      setFieldValue('marginThreshold', 0.00);
    } else {
      setFieldValue('feePercent', 0.00);
      setFieldValue('feeGuaranteedAmount', 0.00);
    }
  };

  const cardActions = [
    <AppIconButton
      key={'copy'}
      Icon={FileCopy}
      tooltip="Copy Previous Configuration"
      onClick={handleCopyPreviousCommissionRate}
      disabled={Boolean(values.id || values.isCopy)}
    />
  ];


  return (
    <div style={{ width: 400, height: '100vh' }}>
      <AppCard title={'Pay Configuration'}  action={cardActions}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <label htmlFor="commissionType">Commission Type</label>
            <AppDropdown
              id={'commissionType'}
              field={'label'}
              fieldIndex={'value'}
              options={CommissionTypeLabels}
              value={values.selectedCommissionType}
              onChange={handleCommissionTypeChange} />
          </Grid>
          <When condition={values.selectedCommissionType.value === 'fee'}>
            <Grid item xs={12}>
              <AppNumericInput
                id={'feePercent'}
                label={'Fee Percent'}
                value={values.feePercent}
                onChange={handleNumericFieldChange('feePercent')}
              />
              <AppValidationFailure
                message={errors.feePercent}
              />
            </Grid>
          </When>
          <When condition={values.selectedCommissionType.value === 'margin'}>
            <Grid item xs={12}>
              <AppNumericInput
                id={'marginPercent'}
                label={'Margin Percent'}
                value={values.marginPercent}
                onChange={handleNumericFieldChange('marginPercent')}
              />
              <AppValidationFailure
                message={errors.marginPercent}
              />
            </Grid>
          </When>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <Grid container spacing={1}>
              <Grid item><AppButton onClick={submitForm} disabled={!isValid || !isDirty}>Save</AppButton></Grid>
              <Grid item><AppButton onClick={values.onCancelClick}>Cancel</AppButton></Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppCard>
    </div>
  )
}

export default PayConfigurationDrawer;