import palette from '../palette';

export default {
    root: {
        color: palette.text,
        fontSize: 12,
        transform: 'none !important',
        transformOrigin: 'none !important',
        marginLeft: 2,
        lineHeight: 0,
        padding: '10px 10px 10px 0px !important',
    },

};
