import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import AppDateTimeText from '../../../../core/components/text/AppDateTimeText';
import AppCard from '../../../../core/components/cards/AppCard/AppMuiCard';

const CreditLimitChangesCard = (props) => {
  const {
    companyCreditLimitChanges
  } = props;

  return (
    <AppCard title={'Latest Credit Limit Changes'} height={'auto'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Limit
            </TableCell>
            <TableCell>
              Date
            </TableCell>
            <TableCell>
              Associate
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (!companyCreditLimitChanges || companyCreditLimitChanges.length === 0) &&
              <TableRow>
                <TableCell colSpan={3}>Customer has no credit limit changes.</TableCell>
              </TableRow>
          }
          {
            companyCreditLimitChanges && companyCreditLimitChanges.length > 0 &&
            companyCreditLimitChanges.map((record, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <AppCurrencyText value={record.creditLimit} />
                  </TableCell>
                  <TableCell>
                    <AppDateTimeText value={record.outcomeDate} />
                  </TableCell>
                  <TableCell>
                    {record.actioningAssociateFirstName} {record.actioningAssociateLastName} { record.automatic === true ? '(auto)' : ''}
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </AppCard>
  );
};

export default CreditLimitChangesCard;