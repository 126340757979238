import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const loadRmisCarrier = async (shell, mcNumber) => {

  const rmisCarrier = await shell.gateway.getRmisCarrier(mcNumber);

  return {
    type: ActionTypes.Carrier.Modification.RmisCarrierLoaded,
    rmisCarrier
  }
};

export default ActionBuilder
  .for(loadRmisCarrier)
  .build();