import ActionTypes from "../../../ActionTypes";

const qaReview = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Automation.RetrieveQaReview.Loaded:
      return action.qaReview;
    default:
      return state;
  }
};

export default qaReview;