import * as Redux from 'redux';

import affiliateCompany from './affiliateCompany';
import associates from './associates';
import company from './company';
import companyDocuments from './companyDocuments';
import companyFinancial from './companyFinancial';
import companyFinancialHealthSummary from './companyFinancialHealthSummary';
import companyInvoiceSettings from './companyInvoiceSettings';
import companyDisablementHistories from './companyDisablementHistories';
import companyDisablementHistoriesCount from './companyDisablementHistoriesCount';
import companyNotes from './companyNotes';
import companyAgingNotes from './companyAgingNotes';
import contacts from './contacts';
import creditStatus from './creditStatus';
import companyCreditLimitChanges from './companyCreditLimitChanges';
import firstShipment from './firstShipment';
import notes from './notes';
import companyAutofillFields from "./companyAutofillFields";

export default Redux.combineReducers({
    affiliateCompany,
    associates,
    company,
    companyDocuments,
    companyFinancial,
    companyFinancialHealthSummary,
    companyInvoiceSettings,
    companyDisablementHistories,
    companyDisablementHistoriesCount,
    companyNotes,
    companyAgingNotes,
    contacts,
    creditStatus,
    companyCreditLimitChanges,
    firstShipment,
    notes,
    companyAutofillFields
});