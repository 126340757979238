import {Grid} from "@material-ui/core";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {warningIcon} from "../../../../core/media/icons/exportCustomIcons";
import React from "react";

const AdjustedCostSection = (props) => {

    const { invoice } = props;

    return (
        <Grid container spacing={2} alignItems={"center"} style={{padding: 10}}>
            <Grid item>
                <p>Adj. Carrier Cost</p>
            </Grid>
            <Grid item>
                <div style={{position: "relative"}}>
                    <NumericTextBox
                        id={"postedAmountUsd"}
                        name={"postedAmountUsd"}
                        value={invoice.adjustedCarrierCost || 0}
                        format={"c2"}
                        spinners={false}
                        disabled={true}
                        style={{color: "#00152d", fontWeight: "500"}}
                    />
                    {!invoice.adjustedCarrierCost &&
                        <img style={{height: 19, width: 19, position: "absolute", top: 5, left: 135}} src={warningIcon} alt={"Adjusted carrier cost is $0"}/>
                    }
                </div>
            </Grid>
            <Grid item>
                <p>Adj. Customer Cost</p>
            </Grid>
            <Grid item>
                <div style={{position: "relative"}}>
                    <NumericTextBox
                        id={"postedAmountUsd"}
                        name={"postedAmountUsd"}
                        value={invoice.adjustedCustomerCost || 0}
                        format={"c2"}
                        spinners={false}
                        disabled={true}
                        style={{color: "#00152d", fontWeight: "500"}}
                    />
                    {!invoice.adjustedCustomerCost &&
                        <img style={{height: 19, width: 19, position: "absolute", top: 5, left: 135}} src={warningIcon} alt={"Adjusted customer cost is $0"}/>
                    }
                </div>
            </Grid>
        </Grid>
    )
}

export default AdjustedCostSection;