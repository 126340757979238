import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonGroup, Button } from '@material-ui/core';
import combineClassNames from "../../../../core/utils/combineClassNames";

/**
 * Provides the navigation bar for the LoadBoard page.
 */
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.tgfGrey.light,
      },
    },
    active: {
      textDecoration: 'none',
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    badge: {
      borderRadius: 5,
      marginLeft: 5,
      padding: '0 3px',
      color: theme.palette.white,
    },
    activeBadge: {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    },
    default: {
      backgroundColor: theme.palette.default.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
    }
  }
));

const ListingFilterBar = ({index, qaReviewRecordCount, qaFailedRecordCount}) => {
  const classes = useStyles();
  const params = useParams();
  const buttonList = [
    {
      title: 'Auto-Processed Archive',
      link: '/admin/auto-processed',
      value: null,
      style: 'primary',
      visible: true
    },
    {
      title: 'QA Review Queue',
      link: '/admin/qa-manual-review',
      value: `${qaReviewRecordCount}`,
      style: 'primary',
      visible: true
    },
    {
      title: 'QA Failed Queue',
      link: '/admin/qa-failed',
      value: `${qaFailedRecordCount}`,
      style: 'primary',
      visible: true
    },
    {
      title: 'Closed Archive',
      link: '/admin/closed',
      value: null,
      style: 'primary',
      visible: true
    },
  ];

  return (
    <ButtonGroup style={{padding: '20px'}} role="group" aria-label="CRM toolbar button group.">
      {
        buttonList.map((item, key) => {
          const activeClassName = key === index ? classes.active : classes.buttonLink;
          const activeBadge = classes[item.style];

          return (
            <Button
              component={Link}
              className={activeClassName}
              key={key}
              onClick={() => {}}
              to={item.link}>
              {item.title}
              <span className={combineClassNames(classes.badge, activeBadge)}>{item.value}</span>
            </Button>
          )
        })
      }
    </ButtonGroup>
  );
}

export default ListingFilterBar;
