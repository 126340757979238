import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyContacts = async (shell, companyId) => {

  const contacts = await shell.gateway.getCompanyContacts(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyContactsLoaded,
    contacts
  }
};

export default ActionBuilder
  .for(loadCompanyContacts)
  .build();