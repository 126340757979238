import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyAgingNotes = async (shell, companyId) => {

  const notes = await shell.gateway.getCompanyAgingNotes(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyAgingNotesLoaded,
    notes
  };

};

export default ActionBuilder
  .for(loadCompanyAgingNotes)
  .build();