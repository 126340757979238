import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from "../../ActionTypes";

const loadPayStatementDocument = async (shell, associateId, startDate) => {

  const document = await shell.gateway.getAssociatePayStatementDocument(associateId, startDate);

  return {
    type: ActionTypes.CommissionCenter.PayStatementLoaded,
    document
  };

};

export default ActionBuilder
  .for(loadPayStatementDocument)
  .build();