import ActionTypes from "../../../ActionTypes";

const monthlyAssociatePayments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Admin.CommissionSummary.Disposed:
      return [];
    case ActionTypes.Admin.CommissionSummary.PaymentsLoaded:
      return action.monthlyAssociatePayments;
    default:
      return state;
  }
};

export default monthlyAssociatePayments;