import * as Redux from 'redux';

import associateTotalStatement from './associateTotalStatement';
import associateCommissionRate from './associateCommissionRate';
import payPeriodAdjustments from './payPeriodAdjustments';
import payPeriodAdjustmentCount from './payPeriodAdjustmentCount';
import payPeriodShipments from './payPeriodShipments';
import payPeriodShipmentCount from './payPeriodShipmentCount';
import payStatementDocument from './payStatementDocument';

export default Redux.combineReducers({
  associateTotalStatement,
  associateCommissionRate,
  payPeriodAdjustments,
  payPeriodAdjustmentCount,
  payPeriodShipments,
  payPeriodShipmentCount,
  payStatementDocument
});