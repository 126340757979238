import React from "react";
import {Tooltip} from "@material-ui/core";
import {commentsIcon, loadCommodityIcon, loadDimensionsIcon} from "../../../../../core/media/icons/exportCustomIcons";

export const eqpNdsSpInfoCell = (cellProps) => {
  const {otherEquipmentNeeds, notes} = cellProps.dataItem;
  return (
      <td>
        <Tooltip title={
          <div className={"tgf-kendo-grid-tooltip"}>
            <p>Other Equipment Needs</p>
            <hr/>
            <p>{otherEquipmentNeeds ? otherEquipmentNeeds : ""}</p>
            <br/>
            <p>Special Information</p>
            <hr/>
            <p>{notes ? notes : ""}</p>
        </div>}>
          <img style={{height: 32, width: 32}} src={commentsIcon} alt={"equipment and special needs will be listed here"}/>
        </Tooltip>
      </td>
  );
}

export const commodityCell = (cellProps) => {
  const { commodity } = cellProps.dataItem;
  return (
      <td>
        <Tooltip title={
          <div className={"tgf-kendo-grid-tooltip"}>
            <p>Selected Commodity </p>
            <hr/>
            <p>{commodity ? commodity : ""}</p>
          </div>}>
          <img style={{height: 32, width: 32}} src={loadCommodityIcon} alt={"commodity will be listed here"}/>
        </Tooltip>
      </td>
  );
}

export const loadDimensionsCell = (cellProps) => {
  const { length, height, width } = cellProps.dataItem;
  return (
      <td>
          <Tooltip title={
            <div className={"tgf-kendo-grid-tooltip"}>
              <p>Dimensions </p>
              <hr/>
              <p>{length ? `Length: ${length} ft` : ''}</p>
              <p>{width ? `Width: ${width} ft` : ''}</p>
              <p>{height ? `Height: ${height} ft`: ''}</p>
            </div>
          }>
              <img style={{height: 24, width: 24}} src={loadDimensionsIcon} alt={"load dimensions will be listed here"}/>
          </Tooltip>
      </td>
  );
}

export const transportationModeCell = (cellProps) => {
  const { transportationModeId } = cellProps.dataItem;
  return (
      <td>
        <p>{transportationModeId === 1 ? "F" : "P"}</p>
      </td>
  );
}

export const loadOptionsCell = (props) => {
  let equipmentOptions = props.dataItem.equipmentOptions ?? null;
  equipmentOptions = equipmentOptions && equipmentOptions.split(",");
  return (
    <td>
        {equipmentOptions && equipmentOptions.map(option => <p style={{margin: 5}}>{option}</p>)}
    </td>
  )
}




