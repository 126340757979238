import React from 'react';
import {Box, Grid} from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import AppButton from '../../../../core/components/AppButton';
import InvoiceAuditNoteDialog from './InvoiceAuditNoteDialog';
import AppMuiSimpleTable from '../../../../core/components/AppTable/AppMuiSimpleTable';
import Core from '@atomos/core';
import AppDateTimeText from '../../../../core/components/text/AppDateTimeText';
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";

const InvoiceAuditNotes = (props) => {

  const {
    invoiceAuditNotes = [],
    onNoteAdded
  } = props;

  const [showNewNoteDialog, setShowNewNoteDialog] = React.useState(false);

  const handleNewNoteClick = (e) => {
    setShowNewNoteDialog(true);
  };

  const handleNewNoteDialogClose = (e) =>
    setShowNewNoteDialog(false);

  const handleNewNoteDialogSave = (noteContent) => {
    onNoteAdded(noteContent);
    setShowNewNoteDialog(false);
  };

  return (
    <Box mt={2} width={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppButton
            startIcon={<Add />}
            onClick={handleNewNoteClick}
          >
            New Note
          </AppButton>
        </Grid>
        <Grid item xs={12} md={11}>
          <AppMuiSimpleTable
            columns={Columns}
            tablePropMap={makeTableFixed}
            data={invoiceAuditNotes}
          />
        </Grid>
        {
          showNewNoteDialog && <InvoiceAuditNoteDialog
            onSave={handleNewNoteDialogSave}
            onClose={handleNewNoteDialogClose}
          />
        }
      </Grid>
    </Box>
  );
};

const makeTableFixed = (baseProps) =>
  Core.Utils.merge({}, baseProps, {
    style: { tableLayout: 'fixed' },
    width: '100%'
  });

const Columns = [
  {
    title: 'Note',
    headerPropMap(index, columns) {
      return { width: '62%' };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { overflowWrap: 'break-word' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.content
    }
  },
  {
    title: 'Date',
    headerPropMap(index, columns) {
      return { width: '18%', style: { textAlign: 'center' } };
    },
    valueMap(note, index, data, columns) {
      return <AppDateTimeText value={note.createDate} />;
    }
  },
  {
    title: 'Associate',
    headerPropMap(index, columns) {
      return { width: '20%', style: { textAlign: 'center' }  };
    },
    cellPropMap(note, index, data, columns)  {
      return {
        style: { textAlign: 'center'}
      };
    },
    valueMap(note, index, data, columns) {
      return note.associate ?
        <AgentTooltipComponent content={`${note.associate.firstName}  ${note.associate.lastName}`} agentId={note.associate.id}/>
        : '[BLANK]'
    }
  }
];

export default InvoiceAuditNotes;
