import React from 'react';
import { Hidden, TableCell, TableRow } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import Core from '@atomos/core';

import AppMenuButton from '../../../core/components/AppButton/AppMenuButton';
import AppIconButton from '../../../core/components/AppButton/AppIconButton';
import AppMuiSimpleTable from '../../../core/components/AppTable/AppMuiSimpleTable';

const CurrencyFormatter = new Intl.NumberFormat('en-US',
  { style: 'currency', currency: 'USD' }
);

const dateCell = (value) => {
  const dateValue = new Date(value);
  return value ?
    dateValue.toMoment().format('L') :
    '';
};

const formatUsdCell = (usdValue) => {
  return CurrencyFormatter.format(usdValue);
};

const createCellFunction = (column, item) => {

  const field = item.field;
  const ChildComponent = item.component;

  switch (item.dataType) {
    case 'date':
      return () => dateCell(column[field]);
    case 'currency':
      return () => formatUsdCell(column[field]);
    case 'component':
      return () => <ChildComponent rowData={column} />;
    case 'string':
    default:
      return () => {
        const value =  column[field];
        return Core.Utils.isNotVoid(value) ?
          String(value) : '';
      };
  }
};

const ContactListing = ({
    contacts = [],
    gridColumns,
    onDeleteClick,
    onEditClick,
    options
  }) => (
    <AppMuiSimpleTable columns={[...gridColumns.map(v => v.title), ""]}>
      {
        contacts.map((contact, index) => (
          <TableRow key={index}>
            {
              gridColumns.map((item, key) => {
                const cellData = createCellFunction(contact, item);
                return <TableCell key={key}>{cellData()}</TableCell>;
              })
            }
            <TableCell>
              <Hidden mdUp>
                <AppMenuButton
                  document={contact}
                  options={options}
                />
              </Hidden>
              <Hidden smDown>
                <AppIconButton
                  Icon={Edit}
                  onClick={() => onEditClick(contact)}
                />
                <AppIconButton
                  Icon={Delete}
                  color="danger"
                  onClick={() => onDeleteClick(contact)}
                />
              </Hidden>
            </TableCell>
          </TableRow>
        ))
      }
    </AppMuiSimpleTable>
  );

export default ContactListing;