import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const TgfDropDownList = (props) => {

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const onBlur = () => {
        setData(props.data);
        setFilter(null);
    };

    const onClose = () => {
        setData(props.data);
        setFilter(null);
    };

    const filterDropdownListData = (filter) => {
        const filteredData = props.data.slice();
        return filterBy(filteredData, filter);
    };
    const onFilterChange = (event) => {
        setFilter(event.filter.value);
        setData(filterDropdownListData(event.filter));
    };

    return (
        <>
            {props.label && <Label editorId={props.id}>{props.label}</Label>}
            <DropDownList
                {...props}
                id={props.id}
                name={props.name}
                data={data}
                value={data.find(x => x.id === props.value) ?? null}
                onChange={props.onChange}
                disabled={props.disabled}
                tabIndex={props.tabIndex}
                style={props.style}
                filterable={props.filterable}
                onFilterChange={onFilterChange}
                label={null} // we set this to null, because label is used in the Label component up above.
                onBlur={onBlur}
                onClose={onClose}
                filter={filter}
            />
        </>
    );
};

TgfDropDownList.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input
    id: PropTypes.string.isRequired,
    // Name of the input, this is used to reference in the event handler.
    name: PropTypes.string.isRequired,
    // List of data for the dropdown IE: [{id: 1, display: "displayValue}, {id: 2, display: "displayValue}]
    data: PropTypes.array.isRequired,
    // The reference key to the value we want to display from the object/s.
    textField: PropTypes.string.isRequired,
    // The reference key to the value we want to be chosen and stored as our actual form value from the object/s.
    dataItemKey: PropTypes.string.isRequired,
    // The current value of the input
    value: PropTypes.any,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Bool to make the input filterable.
    filterable: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,

};

export default TgfDropDownList;