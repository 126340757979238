import ActionTypes from "../../../ActionTypes";

const carrier = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Carrier.Modification.Disposed:
      return null;
    case ActionTypes.Carrier.Modification.CarrierLoaded:
    case ActionTypes.Carrier.Modification.CarrierSaved:
      return action.carrier;
    default:
      return state;
  }
};

export default carrier;