import { Button, Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import TgfTextBox from "../../../../core/kendo/form/inputs/TgfTextBox";
import DateOnlyPicker from "../../../../core/kendo/inputs/DateOnlyPicker";

const LockedDatesDialog = (props) => {

    const personalDispatch = useDispatch();
    const [date, setDate] = useState(null);
    const [reasonBlocked, setReasonBlocked] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    const handleDatePickerChange = (e) => {
        setDate(e.target.value);
        setIsDirty(true);
    };

    const handleReasonBlockedChange = (e) => {
        setReasonBlocked(e.target.value);
        setIsDirty(true);
    };

    useEffect(() => {
        if (props.data.blockedDate) setDate(new Date(props.data.blockedDate));
        if (props.data.reasonBlocked) setReasonBlocked(props.data.reasonBlocked);
    }, [props.data]);

    const handleUpdate = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('updateLockedDate'));

            const data = {
                id: props.data.id,
                blockedDate: date,
                reasonBlocked: reasonBlocked,
                lastUpdated: new Date(),
            };

            await window.shell.gateway.updateBlockedDate(data, props.type);

            setIsDirty(false);
            // close the dialog.
            props.onCloseDialog();


        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('updateLockedDate'));
        }
    };

    const handleDelete = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('deleteLockedDate'));

            await window.shell.gateway.deleteBlockedDate(props.data.id, props.type);
            // close the dialog.
            props.onCloseDialog();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('deleteLockedDate'));
        }
    };

    return (
        <TgfDialog title={"Edit Blocked Date"}
            onClose={props.onCloseDialog}
            width={1000}>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item>
                    <Label editorId={"blocked-date"}>Blocked Date</Label>
                    <DateOnlyPicker
                        name={"blocked-date"}
                        id={"blocked-date"}
                        value={date}
                        onChange={handleDatePickerChange}
                        allowClear={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Label editorId={"reasonBlocked"}>Locked Reason</Label>
                    <TgfTextBox
                        id={"reasonBlocked"}
                        name={"reasonBlocked"}
                        value={reasonBlocked}
                        onChange={handleReasonBlockedChange}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        type={"button"}
                        disabled={!isDirty}
                        onClick={handleUpdate}
                    >
                        Update
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        className={"delete-button"}
                        type={"button"}
                        // disabled={!hasChanged}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </Grid>
            </Grid>
        </TgfDialog>
    );

};

export default LockedDatesDialog;
