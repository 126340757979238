import MuiButton from './MuiButton';
import MuiDrawer from './MuiDrawer';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiSwitch from './MuiSwitch';
import MuiFormControl from './MuiFormControl';
import MuiFormLabel from './MuiFormLabel';
import MuiTableRow from './MuiTableRow';

export default {
  MuiButton,
  MuiDrawer,
  MuiFormControl,
  MuiFormLabel,
  MuiIconButton,
  MuiPaper,
  MuiSwitch,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTypography
};
