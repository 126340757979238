import * as React from "react";
import {CheckCircle} from "@material-ui/icons";

const CheckmarkCell = (cellProps) =>
    <td className={"center"}>
        {cellProps.dataItem[cellProps.field]
            ? <CheckCircle color={'primary'} fontSize={'small'}/>
            : null}
    </td>

export default CheckmarkCell;