import ActionTypes from '../../../ActionTypes';
import ActionBuilder from "../../../ActionBuilder2";

const load = async (shell, searchTerm, categoryTypes, offset, limit, sort, showDisabledCustomersOnly = null, filterAssociateId = null) => {

    // Filters are to be combined as a sql condition and query string with searchTerms and passed on to the api
    const filters = [
        {
            categoryTypeId: {
                $in: categoryTypes
            }
        }
    ];

    if (searchTerm) {
        filters.push({
            $or: [
                {companyName: {$like: `${searchTerm}%`}},
                {affiliateCompanyName: {$like: `${searchTerm}%`}},
            ]
        })
    }

    if (showDisabledCustomersOnly) {
        filters.push({
                    companyIsDisabled: 1
                })
    }

    if(filterAssociateId) {
        filters.push({associateId: filterAssociateId});
    }

    const queryParams = {
        filter: {$and: filters},
        offset,
        limit,
        sort
    };

    const {count, companies} = await shell.gateway.searchCompanies(queryParams);

    return {
        type: ActionTypes.AddressBook.Listing.Loaded,
        companies,
        companyCount: count
    };

};

export default ActionBuilder
  .for(load)
  .build();
