import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const authenticate = async (shell, username, password)  => {

  const authResult = await shell.gateway.authenticate(username, password);

  return {
    type: ActionTypes.Persona.Authenticated,
    status: authResult.status
  };

};

export default ActionBuilder
  .for(authenticate)
  .build();