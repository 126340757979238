import React from 'react';
import FullWidthLayout from "../../core/layouts/FullWidthLayout";
import {Grid} from "@material-ui/core";
import TgfDataGrid from "../../core/kendo/dataGrid/TgfDataGrid";
import TgfLabeledInput from "../../core/kendo/inputs/TgfLabeledInput";
import DebouncedTextBox from "../../core/kendo/inputs/DebouncedTextBox";
import AppNumberText from "../../core/components/text/AppNumberText";
import {useLocation} from "react-router-dom";

const PaymentApplicationsPage = (props) => {
    const ref = React.useRef();

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const initialFilter = query.get('filter') ? `=${query.get('filter')}` : '';
    console.log(initialFilter);

    const [rowCount, setRowCount] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState(initialFilter);

    React.useEffect(() => {
        ref.current.refresh();
    }, [searchTerm]);

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 50,
            skip: 0
        };

        if(searchTerm != null && searchTerm.length > 0) {
            if(searchTerm.startsWith('=')) {
                odata.filter = {
                    odata: searchTerm.substring(1)
                };
            }
            else {
                odata.filter.or = {
                    // paymentApplicationId: {eq: searchTerm},
                    // paymentApplicationAcumaticaUid: {eq: searchTerm},
                    acumaticaAdjustmentNumber: {eq: searchTerm},
                    paymentId: {eq: searchTerm},
                    paymentAmount: {eq: searchTerm},
                    paymentMethod: {eq: searchTerm},
                    paymentHold: {eq: searchTerm},
                    paymentStatus: {eq: searchTerm},
                    //invoiceId: {eq: searchTerm},
                    invoiceAcumaticaRef: {eq: searchTerm},
                    invoiceAmount: {eq: searchTerm},
                    invoiceCustomerOrder: {substringof: searchTerm},
                    invoiceType: {eq: searchTerm},
                    invoiceHold: {eq: searchTerm},
                    invoicePostPeriod: {eq: searchTerm},
                    invoiceBalance: {eq: searchTerm},
                    InvoiceDueDate: {eq: searchTerm},
                    //adjustingInvoiceMemoId: {eq: searchTerm},
                    adjustingInvoceMemoAcumaticaRef: {substringof: searchTerm},
                    //shipmentId: {eq: searchTerm},
                    bolNumber: {eq: searchTerm},
                    shipmentStatus: {eq: searchTerm},
                    associateId: {eq: searchTerm},
                    //customerId: {eq: searchTerm},
                    customerAcumaticaRef: {substringof: searchTerm},
                    customerName: {substringof: searchTerm},
                    amountPaidOnInvoice: {eq: searchTerm},
                    balance: {eq: searchTerm},
                    balanceWriteOff: {eq: searchTerm},
                    applicationPeriod: {eq: searchTerm},
                    batchNumber: {eq: searchTerm},
                    cashDiscountBalance: {eq: searchTerm},
                    cashDiscountDate: {eq: searchTerm},
                    cashDiscountTaken: {eq: searchTerm},
                    currency: {eq: searchTerm},
                    customerOrder: {substringof: searchTerm},
                    applicationDate: {eq: searchTerm},
                    description: {substringof: searchTerm},
                    dueDate: {eq: searchTerm},
                    postPeriod: {eq: searchTerm},
                    adjustsVat: {eq: searchTerm},
                    vatCreditMemo: {eq: searchTerm},
                    // createdDateTime: {eq: searchTerm},
                    // lastModifiedDateTime: {eq: searchTerm},
                };
            }
        }

        return odata;
    }

    const loadData = async (odata) => {
        let explicitFilter = null;
        if(typeof odata.filter.odata ) {
            explicitFilter = odata.filter.odata
        }

        const dataPage = await window.shell.gateway.getPaymentApplications(odata, explicitFilter);
        setRowCount(dataPage.totalItemCount);
        setPageCount(dataPage.totalPageCount);
        return dataPage;
    }

    const GridColumns = [
        {field: "paymentApplicationId", title: "ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentApplicationAcumaticaUid", title: "Acumatica UID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaAdjustmentNumber", title: "Adjustment Num", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},

        {field: "amountPaidOnInvoice", title: "Amount Applied To Invoice", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "balance", title: "Balance", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "balanceWriteOff", title: "Balance Write Off", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "applicationPeriod", title: "Application Period", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "batchNumber", title: "Batch Number", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},

        {field: "paymentId", title: "Payment ID", type: "link", url:(dataItem) => `/receivables/payments?filter=paymentId eq '${dataItem.paymentId}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentAmount", title: "Payment Amount", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentMethod", title: "Payment Method", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentRef", title: "Payment Ref", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentHold", title: "Payment Hold", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentStatus", title: "Payment Status", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},

        {field: "invoiceId", title: "Invoice ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceAcumaticaRef", title: "Invoice Acumatica Ref", type: "link", url:(dataItem) => `/receivables/invoice-memos?filter=acumaticaRef eq '${dataItem.invoiceAcumaticaRef}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceAmount", title: "Invoice Amount", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceType", title: "Invoice Type", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceHold", title: "Invoice Hold", type: "boolean" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoicePostPeriod", title: "Invoice Post Period", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceBalance", title: "Invoice Balance", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "InvoiceDueDate", title: "Invoice Due Date", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "adjustingInvoiceMemoId", title: "Adjusting Invoice ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "adjustingInvoceMemoAcumaticaRef", title: "Adjusting Acumatica Ref", type: "link", url:(dataItem) => `/receivables/invoice-memos?filter=acumaticaRef eq '${dataItem.adjustingInvoceMemoAcumaticaRef}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "shipmentId", title: "Shipment ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        
        {field: "invoiceCustomerOrder", title: "Invoice Customer Order", type: "link", url:(dataItem) => `/receivables/invoice-memos?filter=customerOrder eq '${dataItem.invoiceCustomerOrder}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerOrder", title: "Customer Order", type: "link", url:(dataItem) => `/receivables/invoice-memos?filter=customerOrder eq '${dataItem.invoiceCustomerOrder}'`, className: "show-all-text", headerClassName: "show-all-text"},

        {field: "bolNumber", title: "Bol Number", type: "link", url:(dataItem) => `/shipment/${dataItem.bolNumber}/details`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "shipmentStatus", title: "Shipment Status", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "associateId", title: "Associate ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerId", title: "Customer ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerAcumaticaRef", title: "Customer Acumatica Ref", type: "link", url:(dataItem) => `/address-book/${dataItem.customerId}/details`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerName", title: "Customer", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},

        {field: "cashDiscountBalance", title: "Cash Discount Balance", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "cashDiscountDate", title: "Cash Discount Date", type: "dateFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "cashDiscountTaken", title: "Cash Discount Taken", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "currency", title: "Currency", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "applicationDate", title: "Application Date", type: "dateFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "description", title: "Description", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "dueDate", title: "Due Date", type: "dateFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "postPeriod", title: "Post Period", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "adjustsVat", title: "Adjusts VAT", type: "boolean" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "vatCreditMemo", title: "VAT Credit Memo", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "createdDateTime", title: "CRM Created DateTime", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "lastModifiedDateTime", title: "CRM Modified DateTime", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"}
    ];

    const included = [
        // "paymentApplicationId",
        // "paymentApplicationAcumaticaUid",
        "acumaticaAdjustmentNumber",
        "paymentId",
        "paymentAmount",
        "paymentMethod",
        "paymentRef",
        // "paymentHold",
        // "paymentStatus",
        // "invoiceId",
        "invoiceAcumaticaRef",
        "invoiceAmount",
        "invoiceCustomerOrder",
        "invoiceType",
        //"invoiceHold",
        "invoicePostPeriod",
        "invoiceBalance",
        //"InvoiceDueDate",
        //"adjustingInvoiceMemoId",
        "adjustingInvoceMemoAcumaticaRef",
        //"shipmentId",
        "bolNumber",
        "shipmentStatus",
        //"associateId",
        //"customerId",
        "customerAcumaticaRef",
        "customerName",
        "amountPaidOnInvoice",
        "balance",
        "balanceWriteOff",
        "applicationPeriod",
        //"batchNumber",
        //"cashDiscountBalance",
        //"cashDiscountDate",
        //"cashDiscountTaken",
        //"currency",
        "customerOrder",
        "applicationDate",
        "description",
        "dueDate",
        "postPeriod",
        // "adjustsVat",
        // "vatCreditMemo",
        //"createdDateTime",
        //"lastModifiedDateTime"
    ];

    return (
        <FullWidthLayout title="Payments - Adjustments To Invoices">
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfLabeledInput
                        label={"Search"}
                        inputComponent={<DebouncedTextBox
                            id={"searchTerm"}
                            value={searchTerm}
                            onChange={handleSearchTerm}
                        />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfDataGrid
                        className={"tgf-data-grid"}
                        ref={ref}
                        onLoadDataPage={loadData}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                        onDataPageLoaded={props.onDataChanged}
                        gridColumns={GridColumns.filter(c => included.some(i => i === c.field))}
                        sort={[{field: "applicationDate", dir: "desc"}]}
                        pageable={{
                            pageSize: 20,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500]
                        }}
                    />
                    <div style={{ padding: 10, backgroundColor: '#74747422' }}>
                        <span style={{float: 'left'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={rowCount} /> Rows
                        </span>
                        <span style={{float: 'right'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={pageCount} /> Pages
                        </span>
                        <span style={{clear: 'both'}}>&nbsp;</span>
                    </div>
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
};
export default PaymentApplicationsPage