import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyCreditLimitChanges = async (shell, companyId) => {

  const creditLimitChanges = await shell.gateway.getCompanyCreditLimitChanges(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyCreditLimitChangesLoaded,
    creditLimitChanges
  };
};

export default ActionBuilder
  .for(loadCompanyCreditLimitChanges)
  .build();