import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";
import * as React from "react";
import { useEffect } from "react";


const DateOnlyPicker = React.forwardRef((props, ref) => {

    const [allowClear, setAllowClear] = React.useState(true);

    useEffect(() => {
        setAllowClear(props.allowClear !== null && props.allowClear !== undefined ? props.allowClear : true);
    }, [props.allowClear]);

    const clearDate = () => props.onChange({
        target: {
            value: null, name: props.name
        }
    });

    const disabledClass = props.disabled ? 'k-disabled' : '';
    const makeSafeUtcDate = (date) => {
        if (!date) {
            return null;
        }

        date = new Date(date);
        const utcDateStr = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
        const safeUtcDate = new Date(new Date(utcDateStr).setUTCHours(12, 0, 0, 0));
        return safeUtcDate;
    };

    const DisabledDateInput = (props) => {

        const getValue = () => props.value !== null ? `${props.value.getUTCMonth() + 1}/${props.value.getUTCDate()}/${props.value.getUTCFullYear()}` : '';

        return (<React.Fragment>
            <span className="k-textbox k-input k-input-md k-input-solid k-rounded-md k-disabled">
                <input
                    type="text"
                    className="k-input-inner"
                    readOnly={true}
                    disabled={true}
                    value={getValue()}
                />
            </span>
        </React.Fragment>);
    };

    const CustomToggleButton = (props) => {
        const { onClick, onMouseDown, ...others } = props;
        return (<>
            <span
                className={"k-icon k-font-icon k-i-calendar " + disabledClass}
                onClick={onClick}
                onMouseDown={onMouseDown}
                style={{ padding: "7px", margin: "7px" }}
            />
            {allowClear &&
                <>
                    <span
                        className={"k-link k-font-icon k-link-clear "}
                        aria-label="Clear the DatePicker"
                        onClick={clearDate}
                        style={{ verticalAlign: "center" }}
                    >
                        <span
                            unselectable="on"
                            className={"k-icon k-i-close " + disabledClass}
                            aria-controls="dtp_timeview"
                        />
                    </span>
                </>
            }


        </>);
    };

    return <>
        <Label>{props.label}</Label>
        <DatePicker
            {...props}
            label={''}
            fillMode={null}
            dateInput={DisabledDateInput}
            toggleButton={CustomToggleButton}
            DateRangePickerPopupSettings={{ animate: false }}
            value={makeSafeUtcDate(props.value)}
            min={props.min ? makeSafeUtcDate(props.min) : undefined}
            max={props.max ? makeSafeUtcDate(props.max) : undefined}
            ref={ref}
        />
    </>;
});

export default DateOnlyPicker;