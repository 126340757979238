import React from 'react';
import ComponentBuilder from '../../core/ComponentBuilder';
import AppSpinner from '../../core/components/AppSpinner';
const GlobalSpinner = (props) => {
  const { isLoading } = props;
  return (
    <AppSpinner isLoading={isLoading}/>
  )
};

const isLoading = (tree) => {
  return Object.values(tree)
    .some(o => o.isActive);
}
export default ComponentBuilder
  .wrap(GlobalSpinner)
  .stateToProps((state) => {
    return {
      isLoading: isLoading(state.sys.process)
    };
  })
  .build();