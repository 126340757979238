import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React, {useEffect, useMemo, useState} from "react";
import {Grid} from "@material-ui/core";
import TgfAssociateAutoComplete from "../../../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import "./LastShipmentChart.scss";

const LoadProcessName = 'Reporting.MarginContribution.Loaded';

const LastShipmentChart = () => {
    const baseLinkURL = "/reports/last-shipment-report";
    const personalDispatch = useDispatch();

    const globalState = useSelector(state => state);
    const associate = globalState.persona.associate;
    const associateRole = associate.roles[0].name;

    const [filterAssociate, setFilterAssociate] = useState("");
    const [counts, setCounts] = useState(null);

    const sortCounts = (counts) => {
        const sorted = {};
        if(!counts) return;
        counts.forEach((count) => {
            switch (count.groupingText) {
                case "0-30":
                    sorted._0To30 = count.count;
                    break;
                case "31-60":
                    sorted._31To60 = count.count;
                    break;
                case "61-90":
                    sorted._61To90 = count.count;
                    break;
                case "91-120":
                    sorted._91To120 = count.count;
                    break;
                case "120":
                    sorted._120Plus = count.count;
                    break;
            }
        })
        return sorted;
    }

    const sortedCounts = useMemo(() => sortCounts(counts), [counts]);

    const loadCounts = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart(LoadProcessName));

            const filter = {};

            if(filterAssociate) filter.agentId = filterAssociate.id;

            return await window.shell.gateway.getLastShipmentReportCounts(filter);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete(LoadProcessName));
        }
    }

    useEffect(() => {
        loadCounts().then(counts => setCounts(counts));
    }, [filterAssociate]);

    function handleFilterAssociateChange(e) {
        const value = e.target.value ?? "";
        setFilterAssociate(value);
    }

    return (
        <AppMuiCard title="Last Shipment Report" height={"480px"}>
            <Grid container spacing={1} direction={"row"} justifyContent={"center"}>
                {associateRole === "Ace" &&
                    <Grid item xs={12}>
                        <TgfAssociateAutoComplete
                            id={"filterAssociate"}
                            name={"filterAssociate"}
                            onChange={handleFilterAssociateChange}
                        />
                        <hr/>
                    </Grid>
                }
                <Grid item xs={12} className={"last-shipment-chart_heading-text"}>
                    <h3>Below day ranges are based off a customer's last shipment's pickup date</h3>
                    <p>You can click on the different ranges below to view the results in detail</p>
                </Grid>
                <Grid className={"last-shipment-chart_counts-div"} item>
                        <Link className={"last-shipment-chart_count-link"} to={`${baseLinkURL}?filter=0-30`}>
                            {sortedCounts?._0To30 ?? 0} = 0 to 30 Days Since Last Shipment
                        </Link>
                        <br/>
                        <Link className={"last-shipment-chart_count-link"} to={`${baseLinkURL}?filter=31-60`}>
                            {sortedCounts?._31To60 ?? 0} = 31 to 60 Days Since Last Shipment
                        </Link>
                        <br/>
                        <Link className={"last-shipment-chart_count-link"} to={`${baseLinkURL}?filter=61-90`}>
                            {sortedCounts?._61To90 ?? 0} = 61 to 90 Days Since Last Shipment
                        </Link>
                        <br/>
                        <Link className={"last-shipment-chart_count-link"} to={`${baseLinkURL}?filter=91-120`}>
                            {sortedCounts?._91To120 ?? 0} = 91 to 120 Days Since Last Shipment
                        </Link>
                        <br/>
                        <Link className={"last-shipment-chart_count-link"} to={`${baseLinkURL}?filter=120`}>
                            {sortedCounts?._120Plus ?? 0} = 121+ Days Since Last Shipment
                        </Link>
                        <br/>
                </Grid>
                <Grid item xs={12} className={"last-shipment-chart_footer-link"}>
                    <Link id={"last-shipment-chart_main-link"} to={`${baseLinkURL}?filter=0-30`}>
                        Click here to go to full report
                    </Link>
                </Grid>
            </Grid>


        </AppMuiCard>
    )
}

export default LastShipmentChart;
