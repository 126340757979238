import ActionTypes from "../../../ActionTypes";

const shipment = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.Disposed:
      return null;
    case ActionTypes.Shipment.Modification.ShipmentLoaded:
    case ActionTypes.Shipment.Modification.ShipmentSaved:
    case ActionTypes.Shipment.Modification.NewShipmentLoaded:
      return action.shipment;
    default:
      return state;
  }
};

export default shipment;