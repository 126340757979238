import AppDateTimeText from "../../../../../core/components/text/AppDateTimeText";
import {Check} from "@material-ui/icons";
import React from "react";
import AgentTooltipComponent from "../../../../../crm/components/agent-tooltip/AgentTooltipComponent";

export const RefNumCell = (props) => {
    return <td className={"k-table-td show-all-text"}><span>{(props.dataItem.refNum1Description ?? '') + ' ' + (props.dataItem.refNum1 ?? '')}</span></td>
}

export const TrackingNoteAuditCell = (props) => {

    if (!(props.dataItem.loadTrackingAssociateShortName && props.dataItem.loadTrackingCreateDate))
        return <td className={"k-table-td"}><span> </span></td>;
    return (
        <td className={"k-table-td"}>
          <span>
            <strong><AgentTooltipComponent content={props.dataItem.loadTrackingAssociateShortName} agentId={props.dataItem.loadTrackingAssociateId}/></strong> <AppDateTimeText
              value={props.dataItem.loadTrackingCreateDate}/>
          </span>
        </td>
    )
};

export const RateConCell = (props) => {
    const hasSignedRateCon = Boolean(props.dataItem?.signedRateCon === 1);
    return (
        <td className={"k-table-td"}>
            <span style={{color: "green"}}>
            {hasSignedRateCon && <Check />}
            </span>
        </td>
    );
}

export const PodCell = (props) => {
    const hasPod= Boolean(props.dataItem?.pod === 1);
    return (
        <td className={"k-table-td"}>
            <span style={{color: "blue"}}>
            {hasPod && <Check />}
            </span>
        </td>
    );
}

export const CarrierInvoiceCell = (props) => {
    const hasCarrierInvoice = Boolean(props.dataItem.carrierInvoice === 1);
    return (
        <td className={"k-table-td"}>
            <span style={{color: "red"}}>
            {hasCarrierInvoice && <Check />}
            </span>
        </td>
    );
}
