import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from "../../ActionTypes";

const loadCompanyDocumentTypes = async (shell) => {

  const companyDocumentTypes = await shell.gateway.getCompanyDocumentTypes();

  return {
    type: ActionTypes.Support.CompanyDocumentTypes,
    companyDocumentTypes
  };
};

export default ActionBuilder
  .for(loadCompanyDocumentTypes)
  .build();