import { DropDownList } from "@progress/kendo-react-dropdowns";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const TgfCollectorDropdownList = (props) => {
    const personalDispatch = useDispatch();
    const [collector, setCollector] = useState();
    const [options, setOptions] = useState([]);

    const handleChange = (e) => {
        setCollector(e.target.value);
        props.onChange(e);
    };


    const load = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadCollectors'));
            const filter = {
                collectionRole: true,
            };

            const options = {
                filter,
                roleId: [6],
            };

            const results = await window.shell.gateway.searchAllAssociates(options);
            let collectors = null;
            if (results) collectors = results.map(x => {
                if (x.id !== 703) {
                    return { display: x.fullName, id: x.id };
                } else return { display: "Unassigned At This Time", id: null };
            }
            );

            setOptions(collectors);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadCollectors'));
        }

    };

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (options.length === 0) return;
        if (props.value) {
            setCollector(options.find(x => x.id === props.value));
        }
        else {
            setCollector({ display: "Unassigned At This Time", id: null });
        }
    }, [options]);

    return (
        <>
            <DropDownList
                {...props}
                data={options}
                textField="display"
                dataItemKey="id"
                value={collector}
                onChange={handleChange}
                style={{ width: "100%" }}
            />
        </>
    );
};

TgfCollectorDropdownList.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input.
    id: PropTypes.string.isRequired,
    // Name of the input, this is sometimes used to reference in the event handler.
    name: PropTypes.string.isRequired,
    // { ...collectorData }
    value: PropTypes.string,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,
};

export default TgfCollectorDropdownList;