import ActionTypes from '../../../ActionTypes';
import ActionBuilder from "../../../ActionBuilder2";

const loadShipments = async (shell, startDate, endDate, customerId, associateId, sort, offset, limit) => {
  const { count, shipments } = await shell.gateway.getCustomerReport(
    startDate,
    endDate,
    customerId,
    associateId,
    sort,
    offset,
    limit
  );
  return {
    type: ActionTypes.Reporting.CustomerReport.Loaded,
    shipments,
    count
  };
};

export default ActionBuilder
  .for(loadShipments)
  .build();