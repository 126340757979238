
const StatusSetNames = {
  All: 'all',
  HoldInvoiceGenerated: 'hold-invoice-generated',
  PaymentsPending: 'payments-pending',
  CustomerPaid: 'customer-paid',
  Dispute: 'dispute',
  Claim: 'claim',
  Void: "void"
};

export default StatusSetNames;
