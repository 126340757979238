import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  main: {
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary
    }
  },
  inputBaseRoot: {
    position: 'relative',
    'label + &': {
      marginTop: 18,
    },
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.tgfGrey.fieldBorder,
    borderRadius: 4,
    height: '2.25rem',
    minWidth: 0,
    paddingLeft: 10,
    backgroundColor: theme.palette.white,
    '&:before': {
      borderBottom: `0px solid`,
      borderColor: theme.palette.tgfGrey.fieldBorder,
      left: 0,
      bottom: 0,
      content: '"\\00a0"',
      position: 'absolute',
      transition: theme.transitions.create('border-bottom-color', {
        duration: theme.transitions.duration.shorter,
      }),
      pointerEvents: 'none', 
    },
    '&:after': {
      borderWidth: 0
    },
    '& *, *::before, *::after, *:hover': {
      transition: 'none',
      borderBottom: 0,
      padding: 0,
      content: 'none',
      borderColor: 'none',
    }
  },
  mainInput: {
    backgroundColor: theme.palette.white,
    padding: 5,
    borderRadius: 4,
    border: '1px solid',
    height: '2.30rem',
    borderColor: theme.palette.tgfGrey.fieldBorder
  },
  mainInputDisabled: {
    backgroundColor: theme.palette.tgfGrey.disabled,
    color: 'red',
    padding: 5,
    borderRadius: 4,
    border: '1px solid',
    height: '2.30rem',
    borderColor: theme.palette.tgfGrey.fieldBorder
  },
  inputRoot: {
    position: 'relative',
    border: '1px solid',
    borderColor: theme.palette.tgfGrey.fieldBorder,
    borderRadius: 4,
    height: '2.25rem',
    width: '100%',
    '& *, *::before, *::after, *:hover': {
      transition: 'none',
      borderBottom: 0,
      padding: 0,
      content: 'none'
    }
  },
}));

const AppDatePicker = ({ format="MM/DD/YYYY", placeholder="MM/DD/YYYY", readOnly=false, ...otherProps }) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar 
        format={format}
        className={classes.main}
        placeholder={placeholder}
        InputProps={{ className: otherProps.disabled ? classes.mainInputDisabled : classes.inputBaseRoot, disableUnderline: true, readOnly }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  )
}
export default AppDatePicker;
