import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const saveShipmentInvoice = async (shell, invoice) => {

  const updateInvoice = await shell.gateway.saveInvoice(invoice);

  return {
    type: ActionTypes.Shipment.Modification.InvoiceSaved,
    invoice: updateInvoice
  };

};

export default ActionBuilder
  .for(saveShipmentInvoice)
  .build();