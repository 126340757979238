import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const deleteShipmentTruckLoadConfirmation = async (shell, bolNumber) => {

  // eslint-disable-next-line no-unused-vars
  const deleteTruckloadConfirmation = shell.gateway.deleteShipmentTruckLoadConfirmation(bolNumber);

  const truckloadValueConfirmation = shell.gateway.getShipmentTruckLoadConfirmation(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentTruckLoadConfirmationDeleted,
    truckloadValueConfirmation
  }
};

export default ActionBuilder
  .for(deleteShipmentTruckLoadConfirmation)
  .build();