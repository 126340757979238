import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {HighlightOff} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  fail: {
    color: theme.palette.danger.main
  }
}));

const FailIcon = (props) => {
  const classes = useStyles();
  return (
    <HighlightOff className={classes.fail} />
  );
};

export default FailIcon;