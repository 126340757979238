import {Grid} from "@material-ui/core";
import React from "react";
import AppDialog from "../../../../../core/components/AppDialog/AppMuiDialog";
import AppText from "../../../../../core/components/text/AppText";
import './AutoProcessedInvolvedDocumentsDialog.scss';
import TeraCrunchDialogTable from "../../includes/TeraCrunchDialogTable";
import AppDateTimeText from "../../../../../core/components/text/AppDateTimeText";
import {Link} from "react-router-dom";

const AutoProcessedInvolvedDocumentsDialog = (props) => {

  const {
    onExitClick, listAutomationDocuments, activeRecord
  } = props;
  const [documents, setDocuments] = React.useState(null);

  React.useEffect(() => {
    if (!documents) {
      listAutomationDocuments(activeRecord.bolNumber).then((data) => {
        setDocuments(data.documents);
      });
    }
  }, []);
  const actions = [{
    title: 'Close', action: onExitClick
  }];

  console.log(activeRecord);

  const handleOpenDownloadFileClick = (document, shouldOpen) => {
    const a = window.document.createElement('a');

    shouldOpen ? a.target = '_blank' : a.download = document.filename;

    a.href = `/api/automation/${document.id}/document`;
    a.click();
    a.remove();
  };

  return (
    <AppDialog
      title='Auto Process Documents and Review Dialog'
      width='md'
      open={true}
      actionButtons={actions}
    >
      <Grid container>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <p className={"qa-dialog-shipment-p"}>TeraCrunch Shipment # <Link to={`/shipment/${activeRecord.bolNumber}/details`} target={"_blank"}>{activeRecord.bolNumber}</Link></p>
            <AppText value={'RECEIVED DOCUMENTS'}/>
            {documents &&
              documents.map((doc) =>
                <Grid item xs={12} key={doc.id}>
                  <a
                    onClick={handleOpenDownloadFileClick.bind(this, doc, true)}>
                    {doc.filename}
                  </a>
                </Grid>
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {activeRecord &&
            <Grid
              container
              alignItems="center"
              justifyContent="center">
              <Grid item xs={10} alignItems="center"
                    justifyContent="center">
                <TeraCrunchDialogTable activeRecord={activeRecord}/>
                <br/>
                <div>
                  <table style={{}} className={'approved-by-table'}>
                    <tr>
                      <th scope="col" style={{backgroundColor: "#f0f0f0"}}>Approved By:</th>
                      <th scope="col" style={{backgroundColor: "#f0f0f0"}}>Approved Date/Time</th>
                    </tr>
                    <tr>
                      <td><AppText value={activeRecord.approvedBy}/></td>
                      <td><AppDateTimeText value={activeRecord.approvedDate}/></td>
                    </tr>
                  </table>
                </div>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>

    </AppDialog>
  )
};

export default AutoProcessedInvolvedDocumentsDialog;
