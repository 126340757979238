import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const AppNavDrawer = ({ isAce, navLinks, logout, addOpen, handleMenuToggle }) =>
    <List>
        {
            navLinks.map((item) =>
                item.isRestricted ?
                    isAce && <ListItem button component={Link} to={item.url} key={item.key}>
                        <ListItemText>{item.title}</ListItemText>
                    </ListItem>
                    :
                    <div key={item.key}>
                        <ListItem button component={Link} to={item.url} onClick={handleMenuToggle}>
                            <ListItemText>{item.title}</ListItemText>
                            {item.submenus && !addOpen && <ExpandLess />}
                            {item.submenus && addOpen && <ExpandMore />}
                        </ListItem>
                        {
                            item.submenus && addOpen &&
                            <Collapse in={addOpen} timeout="auto" unmountOnExit>
                                {
                                    item.submenus.map((mitem, key) =>
                                        <List component="div" disablePadding key={key}>
                                            <ListItem button
                                                component={Link}
                                                to={mitem.url || ''}
                                            >
                                                <ListItemText>{mitem.title}</ListItemText>
                                            </ListItem>
                                        </List>
                                    )
                                }
                            </Collapse>
                        }
                    </div>
            )
        }
        <ListItem button component={Link} onClick={logout}>
            <ListItemText>Logout</ListItemText>
        </ListItem>
    </List>

export default AppNavDrawer;