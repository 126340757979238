import ActionTypes from "../../../ActionTypes";

const rmisCarrier = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Carrier.Modification.Disposed:
      return null;
    case ActionTypes.Carrier.Modification.RmisCarrierLoaded:
      return action.rmisCarrier;
    default:
      return state;
  }
};

export default rmisCarrier;