import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyFinancial = async (shell, companyId) => {

  const companyFinancial = await shell.gateway.getCompanyFinancial(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyFinancialLoaded,
    companyFinancial
  };
};

export default ActionBuilder
  .for(loadCompanyFinancial)
  .build();