import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadAssociateGrossMarginBreakdown = async (shell, associateId, customerId, startDate, endDate) => {

  const associateGrossMarginBreakdown = await shell.gateway.getAssociateGrossMarginBreakdown(startDate, endDate, associateId, customerId);

  return {
    type: ActionTypes.Reporting.MonthlyGrossMargin.GrossMarginLoaded,
    associateGrossMarginBreakdown
  }

}

export default ActionBuilder
  .for(loadAssociateGrossMarginBreakdown)
  .build();