import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const dispose = async (context) => {
  return {
    type:ActionTypes.Shipment.Uncovered.Disposed
  }
};

export default ActionBuilder
  .for(dispose)
  .build();