import ActionTypes from '../../../ActionTypes';

const company = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.Disposed:
      return null;
    case ActionTypes.AddressBook.Modification.CompanyLoaded:
    case ActionTypes.AddressBook.Modification.CompanySaved:
    case ActionTypes.AddressBook.Modification.DetailsLoaded:
    case ActionTypes.AddressBook.Modification.DetailsSaved:
    case ActionTypes.AddressBook.Modification.CompanyDisabled:
      return action.company;
    default:
      return state;
  }
};

export default company;