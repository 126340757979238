import Core from '@atomos/core';
import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const sendSnackbarMessage = (shell, message) => {
  return {
    type: ActionTypes.Sys.ReceiveSnackbarMessage,
    message: {
      ...message,
      id: Core.Utils.uuid()
    }
  };
};

export default ActionBuilder
  .for(sendSnackbarMessage)
  .build();