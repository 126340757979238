import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import AppDocumentCard from './AppDocumentCard';

const useStyles = makeStyles(({
  root: {
    flexGrow: 1,
    '& .MuiCardContent-root': {
      paddingRight: 10,
      paddingLeft: 16,
      paddingTop: 10
    }
  }
}));

const AppDocumentCardGroup = (props) => {
  const {
    animateIn,
    cardSize = 4,
    cardColor,
    documents,
    borderLocation = { leftBorder: true },
    options,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {
          documents.map((document, key) =>
            <Grid item xs={12} sm={12} md={cardSize} key={key}>
              <AppDocumentCard
                animateIn={animateIn}
                borderLocation={borderLocation}
                cardColor={document.docType?.color || cardColor}
                document={document}
                options={options}
              />
            </Grid>
          )
        }
      </Grid>
    </div>
  );
};

export default AppDocumentCardGroup;