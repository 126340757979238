import React from "react";
import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import TgfTextBox from "../../../../../../core/kendo/form/inputs/TgfTextBox";
import {useDispatch} from "react-redux";
import TgfButtonBasic from "../../../../../../core/kendo/buttons/TgfButtonBasic";
import TgfPasswordInput from "../../../../../../core/kendo/form/inputs/TgfPasswordInput";

const DatCredsCard = (props) => {
    const personalDispatch = useDispatch();

    const handleTextBoxChange = (e) => {
        props.setFormValues({
            ...props.formValues,
            truckstopCredentials: {
                ...props.formValues.truckstopCredentials,
                [e.target.element.name]: e.target.value
            }
        });
        props.setIsDirty(true);
    }

    const verifyTruckstopCredentials = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('verifyTruckstopCredentials'));
            const result = await window.shell.gateway.verifyTruckstopCredentials(props.formValues?.truckstopCredentials?.username,
                props.formValues?.truckstopCredentials?.passwordDecrypted)
            if(result === true) {
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: "Credentials successfully authenticated"}))
            } else {
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                    content: 'Failed to authenticate',
                    color: 'error'
                }));
            }

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('verifyTruckstopCredentials'));
        }
    }

    return (
        <AppMuiCard title={"ITS Privilege - Credentials"}>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item xs={12}>
                    <TgfTextBox
                        label={"Email / Username"}
                        id={"username"}
                        name={"username"}
                        value={props.formValues?.truckstopCredentials?.username}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TgfPasswordInput
                        label={"Password"}
                        id={"passwordDecrypted"}
                        name={"passwordDecrypted"}
                        value={props.formValues?.truckstopCredentials?.passwordDecrypted}
                        onChange={handleTextBoxChange}
                        maxLength={50}
                    />
                </Grid>
                {/*Filling speace to push button to end.*/}
                <Grid item style={{flexGrow: 1}}></Grid>
                <Grid item>
                    <TgfButtonBasic
                        onClick={verifyTruckstopCredentials}
                    >VERIFY</TgfButtonBasic>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default DatCredsCard;