import ActionTypes from '../../ActionTypes';

const shipmentFreightClasses = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.ShipmentFreightClassesLoaded:
      return action.shipmentFreightClasses;
    default:
      return state;
  }
};

export default shipmentFreightClasses;