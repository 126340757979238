import React from 'react';
import {Grid} from '@material-ui/core';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppCard from '../../../core/components/cards/AppCard';
import ComponentBuilder from '../../../core/ComponentBuilder';
import AppMonthYear from '../../../core/components/inputs/AppDatePicker/AppMonthYear';
import GrossMarginTable from '../../../crm/components/associate/GrossMarginTable';
import LeftNav from '../LeftNav';
import computeMonthDateRange from '../../../core/utils/computeMonthDateRange';
import TotalMarginTable from "../../reporting/MonthlyGrossMargin/includes/TotalMarginTable";
import TotalMarginPercentage from "../../reporting/MonthlyGrossMargin/includes/TotalMarginPercentage";
import TotalMarginPaceTable from "../../../crm/components/tables/TotalMarginPaceTable";
import TotalRevenuePaceTable from "../../../crm/components/tables/TotalRevenuePaceTable";
import "./CompanyGrossMargin.scss";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Admin.CompanyGrossMargin.Load';

const CompanyGrossMarginPage = (props) => {

  const {
    grossMarginBreakdown,
    load,
    dispose
  } = props;

  usePageTitle("TGF: Company GM Report");
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
    const [startDate, endDate] = computeMonthDateRange(selectedDate);
    load(startDate, endDate);
  }, [selectedDate, load]);

  const handleMonthYearChange = (monthYearValue) => {
    if (monthYearValue.isValid()) {
      setSelectedDate(monthYearValue.toDate());
    }
  };

  return (
    <FullWidthLayout SideNav={LeftNav} title="Company Monthly Gross Margin Report">
      <Grid container spacing={2}>

        <Grid item xs={12} md={3}>
          <AppCard>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <label htmlFor="MonthYear">Month/Year:</label>
                <AppMonthYear
                  id="monthYear"
                  openTo="year"
                  views={["year", "month"]}
                  inputVariant="outlined"
                  value={selectedDate}
                  onChange={handleMonthYearChange}
                />
              </Grid>

            </Grid>
          </AppCard>

        </Grid>
        <Grid container xs={4} spacing={1}  className={"margin-container"}>
          <Grid item xs={12}>
            <TotalMarginTable
              date={selectedDate}
              grossMarginData={grossMarginBreakdown}
            />
          </Grid>
          <Grid item xs={12}>
            <TotalMarginPercentage
              date={selectedDate}
              grossMarginData={grossMarginBreakdown}
            />
          </Grid>
        </Grid>
        <Grid container xs={4} spacing={1}  className={"margin-container"}>
          <Grid item xs={12}>
            <TotalMarginPaceTable
              date={selectedDate}
              grossMarginData={grossMarginBreakdown}
            />
          </Grid>
          <Grid item xs={12}>
            <TotalRevenuePaceTable
              date={selectedDate}
              grossMarginData={grossMarginBreakdown}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <GrossMarginTable
              date={selectedDate}
              grossMarginData={grossMarginBreakdown}
          />
        </Grid>
      </Grid>

    </FullWidthLayout>
  )
};

export default ComponentBuilder
  .wrap(CompanyGrossMarginPage)
  .stateToProps((state, ownProps) => ({
    grossMarginBreakdown: state.reporting.monthlyGrossMargin.associateGrossMarginBreakdown,
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(startDate, endDate) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.monthlyGrossMargin.loadAssociateGrossMarginBreakdown(0, 0, startDate, endDate));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.reporting.monthlyGrossMargin.dispose());
      }
    }
  })
  .build();