import React, {useRef} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import { Grid} from "@material-ui/core";
import LeftNav from "../LeftNav";
import LockedDatesForm from "../LockedDates/includes/LockedDatesForm";
import LockedDatesGrid from "../LockedDates/includes/LockedDatesGrid/LockedDatesGrid";
import {useSelector} from "react-redux";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LockedDatesArBillingPage = (props) => {
    const state = useSelector(state => state);
    const canEditBlockedDatesArBilling = state.persona.agent.canEditBlockedDatesArBilling;
    usePageTitle("TGF: AR Automation Block Control");

    const ref = useRef();

    const reloadData = () => {
        ref.current.refresh();
    }

    return (
        canEditBlockedDatesArBilling ?
        <FullWidthLayout SideNav={LeftNav} title={`AR Automation Block Control`} className={"locked-dates-page"}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <LockedDatesForm reloadData={reloadData} type={'AR_BILLING'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LockedDatesGrid ref={ref} reloadData={reloadData} type={'AR_BILLING'}/>
                    </Grid>
                </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    )
};

export default LockedDatesArBillingPage;
