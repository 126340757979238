import React, {forwardRef, useEffect, useState} from "react";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {Grid} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import BillingContactsModal from "./BillingContactsModal";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import AppButton from "../../../../../core/components/AppButton/AppMuiButton";

const editCell = (props, onClickEditBillingContact, disableEdit) => {
    return <td>
        <AppIconButton
            Icon={Edit}
            tooltip={`Edit Date`}
            onClick={() => onClickEditBillingContact(props.dataItem, props.dataIndex)}
            disabled={!disableEdit}
        />
    </td>
}

const BillingContactsGrid =  forwardRef((props, ref) => {

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedBillingContact, setSelectedBillingContact] = useState(null);
    const [activeDataIndex, setActiveDataIndex] = useState(null);

    const onClickEditBillingContact = (data, dataIndex) => {
        setActiveDataIndex(dataIndex);
        setSelectedBillingContact(data);
        setEditDialogOpen(true);
    };

    const onClickAddBillingContact = () => {
        setEditDialogOpen(true);
    };

    const onCloseDialog = () => {
        setEditDialogOpen(false);
        setSelectedBillingContact(null);
        setActiveDataIndex(null)
        props.reload();
    }

    const loadData = async (odata) => {
        if(props.data.additionalData.sendToContacts) {
            const dataPage = {
                totalItemCount: props.data.additionalData.sendToContacts.length,
                totalPageCount: 0,
                pageIndex: 0,
                pageSize: odata?.take,
                items: props.data.additionalData.sendToContacts
            }
            return dataPage;
        }

        return {};
    }

    useEffect(() => {
        loadData()
        props.reload()
    }, [props.data])

    const gridColumns = [
        {
            title: 'First Name',
            field: 'firstName',
            type: 'text',
        },
        {
            title: 'Last Name',
            field: 'lastName',
            type: 'text',
        },
        {
            title: 'Direct Phone',
            field: 'directPhone',
            type: 'text',
        },
        {
            title: 'Mobile Phone',
            field: 'mobilePhone',
            type: 'text',
        },
        {
            title: 'Fax',
            field: 'fax',
            type: 'text',
        },
        {
            title: 'Email',
            field: 'email',
            type: 'text',
        },
        {
            title: 'TO/CC',
            field: 'emailOption',
            type: 'text',
        },
        // edit cell
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (cellProps) => editCell(cellProps, onClickEditBillingContact, props.disableEdit),
            width: 40,
            sortable: false
        },
    ];

    return (
        <AppMuiCard title={"Billing Contacts"}>
            <Grid container spacing={2}>
                <Grid item>
                    <AppButton
                        type={'submit'}
                        onClick={onClickAddBillingContact}
                        disabled={!props.disableEdit}
                    >+ New Contact
                    </AppButton>
                </Grid>
                {editDialogOpen &&
                    <BillingContactsModal
                        onCloseDialog={onCloseDialog}
                        cellData={selectedBillingContact}
                        data={props.data}
                        activeDataIndex={activeDataIndex}
                        setIsDirty={props.setIsDirty}
                    />
                }
                <Grid item xs={12}>
                    <TgfDataGrid
                        className={"billing-contacts_listing-grid"}
                        ref={ref}
                        onLoadDataPage={loadData}
                        gridColumns={gridColumns}
                        sort={[{field: "firstName", dir: "desc"}]}
                        clientSideSorting={true}
                        clientSidePaging={true}
                        pageable={{
                            pageSize: 20,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500]
                        }}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    )
})

export default BillingContactsGrid
