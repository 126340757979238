import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ReportNav from "../ReportNav";
import { useDispatch, useSelector } from "react-redux";
import TgfDataGrid from "../../../core/kendo/dataGrid/TgfDataGrid";
import LastShipmentReportNav from "./includes/LastShipmentReportNav";
import { useQuery } from "../../../core/hooks/usQuery";
import TgfAssociateAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Link } from "react-router-dom";
import "./LastShipmentReport.scss";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = "Reporting.MarginContribution.Loaded";

const LastShipmentReport = () => {
    const query = useQuery();
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    usePageTitle("TGF: Last Shipment Report");

    const cellLink = (path, title) => {
        const rowLink = <td><Link className={"tgf-grid-link-cell"} to={path} target={openLinksInNewTab ? "_blank" : "" } >{title}</Link></td>;
        return rowLink;
    }

    const GridColumns = [
        {
            field: 'rep',
            title: 'Rep',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem.agentId}/>
                </span>
              </td>,
            width: 60,
        },
        {
            field: 'companyName',
            title: 'Customer Name',
            type: 'custom',
            cell: (props) => cellLink(`/address-book/${props.dataItem.companyId}/details`, props.dataItem.companyName),
            width: 250,
        },
        {
            field: 'isDisabled',
            title: 'Disabled?',
            type: 'text'
        },
        {
            field: 'lastShipmentDate',
            title: 'Last Shipment Date',
            type: 'date'
        },
        {
            field: 'lifetimeShipments',
            title: 'Lifetime Shipments',
            type: 'text'
        },
        {
            field: 'lifetimeMargin',
            title: 'Lifetime Margin',
            type: 'currency'
        },
        {
            field: 'currentCreditLimit',
            title: 'Current Credit Limit',
            type: 'currency'
        },
        {
            field: 'creditRemaining',
            title: 'Current Available Credit',
            type: 'currency'
        },
    ];

    const personalDispatch = useDispatch();
    const ref = React.useRef();

    const globalState = useSelector(state => state);
    const associate = globalState.persona.associate;
    const associateRole = associate.roles[0].name;

    const [filterAssociate, setFilterAssociate] = useState(null);
    const [hideDisabled, setHideDisabled] = useState(false);
    const [showOnlyDisabled, setShowOnlyDisabled] = useState(false);
    const [counts, setCounts] = useState(null);

    const sortCounts = (counts) => {
        const sorted = {};
        if(!counts) return;
        counts.forEach((count) => {
          switch (count.groupingText) {
            case "0-30":
              sorted._0To30 = count.count;
              break;
            case "31-60":
              sorted._31To60 = count.count;
              break;
            case "61-90":
              sorted._61To90 = count.count;
              break;
            case "91-120":
              sorted._91To120 = count.count;
              break;
            case "120":
              sorted._120Plus = count.count;
              break;
          }
        })
        return sorted;
    }

    const sortedCounts = useMemo(() => sortCounts(counts), [counts]);

    useEffect(() => {
            loadCounts().then(counts => setCounts(counts));
    }, [filterAssociate, hideDisabled, showOnlyDisabled]);

    const refreshGrid = () => {
        ref.current.refresh();
    };

    const loadCounts = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart(LoadProcessName));

            const filter = {};

            if(filterAssociate) filter.agentId = filterAssociate.id;
            if(hideDisabled === true) filter.isDisabled = 'No';
            if(showOnlyDisabled === true) filter.isDisabled = 'Yes';

            return await window.shell.gateway.getLastShipmentReportCounts(filter);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete(LoadProcessName));
        }
    }

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};
        filter.groupingText = query.get("filter");
        if(filterAssociate) filter.agentId = filterAssociate.id;
        if(hideDisabled === true) filter.isDisabled = 'No';
        if(showOnlyDisabled === true) filter.isDisabled = 'Yes';

        odata.filter.and = filter;
        return odata;
    }

    const loadGridData = async (odata) => {
        return await window.shell.gateway.getLastShipmentReport(odata);
    };

    const handleFilterAssociate = async (e) => {
        const value = e.target.value ?? "";
        await setFilterAssociate(value);
        await ref.current.refresh();
    }

    const handleHideDisabled = async (e) => {
        const value = e.target.value ?? "";
        await setShowOnlyDisabled(false);
        await setHideDisabled(value);
        await ref.current.refresh();
    }

    const handleShowDisabledOnly = async (e) => {
        const value = e.target.value ?? "";
        await setHideDisabled(false);
        await setShowOnlyDisabled(value);
        await ref.current.refresh();
    }

    return (
        <FullWidthLayout SideNav={ReportNav} title="Last Shipment Report">
            <Grid container spacing={2} direction="column">
                <Grid container spacing={2} direction="row" alignItems={"center"}>

                    <Grid item >
                        <LastShipmentReportNav counts={sortedCounts} refreshGrid={refreshGrid} />
                    </Grid>
                    {associateRole === "Ace" &&
                        <Grid item >
                            <TgfAssociateAutoComplete
                                id={"filterAssociate"}
                                name={"filterAssociate"}
                                onChange={handleFilterAssociate}
                            />
                        </Grid>
                    }
                    <Grid item>
                        <Checkbox
                            value={hideDisabled}
                            id={"hideDisabled"}
                            name={"hideDisabled"}
                            label={"Hide Disabled Customers"}
                            onChange={handleHideDisabled}
                        />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            value={showOnlyDisabled}
                            id={"showOnlyDisabled"}
                            name={"showOnlyDisabled"}
                            label={"Show Disabled Customers Only"}
                            onChange={handleShowDisabledOnly}
                        />
                    </Grid>
                </Grid>

                <Grid item>
                    <TgfDataGrid
                        className={"last-shipment-report-grid"}
                        pageable={{
                            pageSize: 20,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500],
                        }}
                        ref={ref}
                        onLoadDataPage={loadGridData}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                        gridColumns={GridColumns}
                        sort={[{field: "lastShipmentDate", dir: "desc"}]}
                    />
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
}
export default LastShipmentReport
