import * as yup from "yup";

export const ShipmentTransactionValidationSchema =  yup.object().shape({
    amount: yup.number().test({
        test(value) {
            if (value !== null && value < 0.01) {
                return this.createError({message: "Must be greater than or equal to $0.01"})
            }
            if(value === null) {
                return  this.createError({message: "Cost Is Required"})
            }
            return true
        }
    }).nullable(),
    reason: yup.string().required("Reason is required").max(30)
});
