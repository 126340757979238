import React from 'react';
import { Grid } from '@material-ui/core';
import AppCard from '../../../core/components/cards/AppCard/AppMuiCard';
import ComplianceValueField from './ComplianceValueField';

const ComplianceValueList = (props) => {

  const {
    complianceValues = []
  } = props;

  return (
    <AppCard>
      <Grid container spacing={1}>
        {
          complianceValues.map(renderComplianceField)
        }
      </Grid>
    </AppCard>
  );
};

const renderComplianceField = (complianceValue, index) =>
  <ComplianceValueField
    key={index}
    {...complianceValue}
  />;

export default ComplianceValueList;