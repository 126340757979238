import Core from '@atomos/core';
import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const ytdMarginGoals = async (shell, associate) => {


  associate =  associate ? associate : await shell.gateway.whoami();

  let results;

  // If the associate has a parent, go ahead and fetch
  // it to have it throughout the life of the app.
  if (Core.Utils.isNumber(associate.id)) {
    results = await shell.gateway.calculateYearlyMarginGoals(associate.id);
  }

   return {
    type: ActionTypes.Persona.ytdMarginGoals,
    ytdMarginGoals: results
  };

};

export default ActionBuilder
  .for(ytdMarginGoals)
  .build();