import ActionTypes from '../../../ActionTypes';

const roles = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Admin.ManageUsers.Disposed:
      return [];
    case ActionTypes.Admin.ManageUsers.RolesLoaded:
      return action.roles;
    default:
      return state;
  }
};

export default roles;