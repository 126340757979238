import React from 'react';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import LeftNav from '../../LeftNav';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import {Grid} from '@material-ui/core';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import ListingFilterBar from "../Listing/ListingFilterBar";
import QaManualReviewDialog from "./includes/QaManualReviewDialog";
import {LibraryAddCheck as ReviewIcon} from '@material-ui/icons';
import computeColor from "../includes/computeColor";

const LoadProcessName = 'Admin.QaManualReviewReport.Load';
const AcceptProcessName = 'Admin.QaManualReviewReport.Accept';
const RejectProcessName = 'Admin.QaManualReviewReport.Reject';

const QaManualReviewReportPage = (props) => {
    const {
      qaReview,
      loadQaReview,
      setQaRejected,
      setQaAccepted,
      qaReviewRecordCount,
      qaFailedRecordCount,
      listAutomationDocuments,
      getFactoringCompanies
    } = props;


    const [currentInteractiveRecord, setCurrentInteractiveRecord] = React.useState(null);

    const ReviewCell = (partSelector) => {
      return ({rowData}) => {
        const value = partSelector(rowData);
        return (<AppIconButton
          Icon={ReviewIcon}
          onClick={onReviewClick}
          value={value}
        />);
      }
    }

    const onReviewClick = (e) => {
      setCurrentInteractiveRecord(JSON.parse(e.currentTarget.value))
    }

    const handleCloseDialog = () => {
      setCurrentInteractiveRecord(null)
    }

    const onReject = async (id) => {
      await setQaRejected(id);
      setModifyCount(modifyCount + 1);
    }

    const onAccept = async (id, remitToInputData) => {
      await setQaAccepted(id, remitToInputData);
      setModifyCount(modifyCount + 1);
    }


    const gridColumns = [
      {
        field: 'bolNumber',
        title: 'TC Ship #',
        styles: {
          width: '5%'
        },
        dataType: 'component',
        component: AppDenseGrid.Cells
          .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
      },
      {
        field: 'inputCarrierName',
        title: 'TC Carrier Name',
        styles: {
          width: '8%'
        },
        dataType: 'string'
      },
      {
        field: 'targetCarrierName',
        title: 'Carrier Name Check',
        styles: {
          width: '8%',
          backgroundColor: computeColor('targetCarrierName')
        },
        dataType: 'string',
      },
      {
        field: 'inputRemitName',
        title: 'TC Remit Name',
        styles: {
          width: '8%'
        },
        dataType: 'string'
      },
      {
        field: 'targetRemitName',
        title: 'Remit Name Check',
        styles: {
          width: '8%',
          backgroundColor: computeColor("targetRemitName")
        },
        dataType: 'string'
      },
      {
        field: 'inputAddress',
        title: 'TC Address 1',
        styles: {
          width: '7%'
        },
        dataType: 'string'
      },
      {
        field: 'targetAddress',
        title: 'Address 1 Check',
        styles: {
          width: '7%',
          backgroundColor: computeColor("targetAddress")
        },
        dataType: 'string'
      },
      {
        field: 'inputAddress2',
        title: 'TC Address 2',
        styles: {
          width: '7%'
        },
        dataType: 'string'
      },
      {
        field: 'targetAddress2',
        title: 'Address 2 Check',
        styles: {
          width: '7%',
          backgroundColor: computeColor("targetAddress2")
        },
        dataType: 'string'
      },
      {
        field: 'inputCity',
        title: 'TC City',
        styles: {
          width: '5%'
        },
        dataType: 'string'
      },
      {
        field: 'targetCity',
        title: 'City Check',
        styles: {
          width: '5%',
          backgroundColor: computeColor("targetCity")
        },
        dataType: 'string'
      },
      {
        field: 'inputState',
        title: 'TC State',
        styles: {
          width: '3%'
        },
        dataType: 'string'
      },
      {
        field: 'targetState',
        title: 'State Check',
        styles: {
          width: '3%',
          backgroundColor: computeColor("targetState")
        },
        dataType: 'string'
      },
      {
        field: 'inputZip',
        title: 'TC Zip',
        styles: {
          width: '3%'
        },
        dataType: 'string'
      },
      {
        field: 'targetZip',
        title: 'Zip Check',
        styles: {
          width: '3%',
          backgroundColor: computeColor("targetZip")
        },
        dataType: 'string'
      },
      {
        field: 'targetAmount',
        title: 'Matched Cost',
        styles: {
          width: '5%',
          backgroundColor: computeColor("targetAmount")
        },
        dataType: 'currency'
      },
      {
        field: '',
        title: 'Review',
        styles: {
          width: '3%'
        },
        dataType: 'component',
        component: ReviewCell((record) => {
          return JSON.stringify(record)
        }, null)
      }
    ];

    const [filter, setFilter] = React.useState({});
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [sort, setSort] = React.useState([['bolNumber', 'asc']]);
    const [modifyCount, setModifyCount] = React.useState(0);

    React.useEffect(() => {
      loadQaReview(
        {
          limit: limit,
          offset: offset,
          sort: sort,
        }
      );
    }, [filter, offset, limit, sort, loadQaReview, qaReviewRecordCount, modifyCount]);

    const handleChangePage = (e, page) => {
      setOffset(page * limit);
    };

    const handleSortChange = (column) => {

      const [existingColumn, existingOrder] = sort[0];

      const order = column === existingColumn ?
        existingOrder === 'asc' ? 'desc' : 'asc' :
        'asc';

      setSort([[column, order]]);
    };
    const handleChangeRowsPerPage = (e) => {
      setOffset(0);
      setLimit(e.target.value);
    };

    return (
      <FullWidthLayout title={''} SideNav={LeftNav}>
        <ListingFilterBar index={1} qaReviewRecordCount={qaReviewRecordCount}
                          qaFailedRecordCount={qaFailedRecordCount}></ListingFilterBar>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppDenseGrid
              columns={gridColumns}
              data={qaReview}
              count={qaReviewRecordCount}
              orderBy={sort[0][0]}
              order={sort[0][1]}
              rowsPerPage={limit}
              page={offset / limit}
              onSort={handleSortChange}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        {currentInteractiveRecord &&
          <QaManualReviewDialog
            onAccept={onAccept}
            onReject={onReject}
            handleCloseDialog={handleCloseDialog}
            listAutomationDocuments={listAutomationDocuments}
            activeRecord={currentInteractiveRecord}
            getFactoringCompanies={getFactoringCompanies}
          />}
      </FullWidthLayout>
    );
  }
;

export default ComponentBuilder
  .wrap(QaManualReviewReportPage)
  .stateToProps((state, ownProps) => ({
    qaReview: state.automation.qaReview.qaReview,
    qaReviewRecordCount: state.automation.qaReview.qaReviewCount,
    qaFailedRecordCount: state.automation.qaFailed.qaFailedCount,
    invoice: state.shipment.modification.shipmentInvoice,
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async loadQaReview(options) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.automation.qaReview.loadQaReview(options));
        // for record count
        dispatch(await shell.actions.automation.qaFailed.loadQaFailed());
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async setQaRejected(id) {
        dispatch(shell.actions.sys.processStart(RejectProcessName));
        dispatch(await shell.actions.automation.qaReview.setQaRejected(id));
        dispatch(shell.actions.sys.processComplete(RejectProcessName));
      },
      async setQaAccepted(record, remitToInputData) {
        dispatch(shell.actions.sys.processStart(AcceptProcessName));
        // adding rep and pickup date to inputData
        remitToInputData.rep = record.rep;
        remitToInputData.pickupDate = record.pickupDate;
        remitToInputData.invoicedAmount = record.inputAmount;
        remitToInputData.businessCategory = record.inputCarrierName === record.inputRemitName ? "Carrier" : "FactoringCompany";

        dispatch(await shell.actions.automation.qaReview.setQaAccepted(record.id, remitToInputData));
        dispatch(shell.actions.sys.processComplete(AcceptProcessName));
      },
      async listAutomationDocuments(bolNumber) {
        return shell.gateway.listAutomationDocuments(bolNumber);
      },
      async getFactoringCompanies() {
        const categoryTypes = await shell.gateway.getCompanyCategoryTypes();
        const fcType = categoryTypes.find(ct => ct.name === 'Factoring Company');
        const searchOptions = {
          filter: {
            categoryTypeId: fcType.id
          },
          limit: 1000,
          sort: [['companyName', 'asc']]
        };
        const { companies } = await shell.gateway.searchCompanies(searchOptions);
        return companies;
      }
    }
  })
  .build();