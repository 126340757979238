import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const load = async (shell, filter, offset, limit, sort) => {

  const { count, shipments } = await shell.gateway.getUncoveredShipmentListing({
    filter,
    offset,
    limit,
    sort
  });

  return {
    type: ActionTypes.Shipment.Uncovered.Loaded,
    shipments,
    count
  };
};

export default ActionBuilder
  .for(load)
  .build();
