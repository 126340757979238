import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const duplicateShipment = async (shell, bolNumber, duplicateCount) => {

  const bolNumbers = await shell.gateway.duplicateShipment(bolNumber, duplicateCount);

  return {
    type: ActionTypes.Shipment.Duplication.ShipmentDuplicated,
    bolNumbers
  };
};

export default ActionBuilder
  .for(duplicateShipment)
  .build();