import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ReportNav from '../ReportNav';
import {useSelector} from "react-redux";
import TgfDataGrid from "../../../core/kendo/dataGrid/TgfDataGrid";
import DateOnlyPicker from "../../../core/kendo/inputs/DateOnlyPicker";
import isAceSelector from "../../../hubs/persona/selectors/isAce";
import {useEffect} from "react";
import TgfMonthPicker from "../../../core/kendo/inputs/TgfMonthPicker/TgfMonthPicker";
import {Button} from "@progress/kendo-react-buttons";
import TgfAssociateAutoComplete from "../../../core/kendo/form/inputs/specialty/TgfAssociateAutoComplete";
import DateUtils from "../../../core/utils/dateUtils/dateUtils";
import "./MarginContribution.scss"
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const MarginContribution = () => {

    const state = useSelector(state => state);
    usePageTitle("TGF: Margin Contribution");

    const GridColumns = [
        {
            field: 'crmId',
            title: 'Rep',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.crmId} agentId={props.dataItem.AssociateId}/>
                </span>
              </td>,
            width: 60,
        },
        {
            field: 'companyName',
            title: 'Customer Name',
            type: 'link',
            url: (dataItem) => `/address-book/${dataItem.companyId}/details`,
            width: 250,
        },
        {
            field: 'shipmentCount',
            title: '# of Shipments',
            type: 'number',
        },
        {
            field: 'averageGM',
            title: 'Avg. GM',
            type: 'currency',
        },
        {
            field: 'margin',
            title: 'Total Margin',
            type: 'currency',
        },
        {
            field: 'totalMargin',
            title: '% of Involved Total',
            type: 'custom',
            cell: (props) => <td><span>{(props.dataItem.margin / props.dataItem.totalMargin).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 2
            }) ?? ' '}</span></td>
        },
    ];

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const ref = React.useRef();

    const activeAssociateId = useSelector(state => state.persona.associate.id);
    const isAce = useSelector(state => isAceSelector(state));

    const [filterAssociate, setFilterAssociate] = React.useState(null);

    const [startOfMonth, setStartOfMonth] = useState(DateUtils.getFirstDayOfCurrentMonth());
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [involvedMarginTotal, setInvolvedMarginTotal] = React.useState(0);

    useEffect(() => {
        const associateId = isAce ? filterAssociate : activeAssociateId
        startOfMonth && associateId && ref?.current?.refresh();
    }, [startOfMonth, filterAssociate]);

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};
        filter.associateId = isAce ? filterAssociate : activeAssociateId;

        if(startOfMonth) {
            // get end of selected month based on the selected start of month.
            const endOfSelectedMonth = new Date(startOfMonth.getUTCFullYear(), startOfMonth.getUTCMonth() + 1, 0);
            endOfSelectedMonth.setUTCHours(23, 59, 59, 999);
            filter.shipmentDate = {ge: startOfMonth.toISOString(), le: endOfSelectedMonth.toISOString()}
        }

        if(startDate && endDate) {
            const startOfDayDate = new Date(startDate + "Z");
            startOfDayDate.setUTCHours(0,0,0,0);
            const endOfDayDate = new Date(endDate + "Z");
            endOfDayDate.setUTCHours(23, 59, 59, 999)
            filter.shipmentDate = {ge: startOfDayDate.toISOString(), le: endOfDayDate.toISOString()}
        }

        odata.filter.and = filter;
        return odata;
    }

    const loadGridData = async (odata) => {
        const page = await window.shell.gateway.getMarginContribution(odata)

        if(page.items.length > 0)
        {
            setInvolvedMarginTotal(page.items[0].totalMargin);
        } else {
            setInvolvedMarginTotal(0);
        }

        return page;
    };

    const handleFilterAssociateChange = (e) => {
        if(!e.target?.value) return setFilterAssociate(null);
        setFilterAssociate(e.target.value.id);
    }

    const handleMonthChange = (e) => {
        // clear range values
        setStartDate(null);
        setEndDate(null);
        if(!e.target?.value) return setStartOfMonth(null);
        setStartOfMonth(e.target.value);
    }

    const handleStartDateChange = (e) => {
        // clear startOfMonth
        setStartOfMonth(null);
        if(!e.target?.value) return setStartDate(null);
        setStartDate(e.target.value);
    }

    const handleEndDateChange = (e) => {
        // clear startOfMonth
        setStartOfMonth(null);
        if(!e.target?.value) return setEndDate(null);
        setEndDate(e.target.value);
    }

    const handleSearchDateRangeClick = () => {
        ref.current.refresh();
    }

    return (
        state.persona.associate.canViewMarginContribution ?
      <FullWidthLayout SideNav={ReportNav} title="Margin Contribution">
          <Grid container spacing={2} alignItems={"flex-end"} style={{marginBottom: 20}}>
              <Grid item xs={2}>
                  <TgfMonthPicker
                      id={"startOfMonth"}
                      name={"startOfMonth"}
                      label={"Month/Year"}
                      value={startOfMonth || null}
                      onChange={handleMonthChange}
                  />
              </Grid>
              <Grid item xs={2}>
                  <DateOnlyPicker
                    id={"dateStart"}
                    name={"dateStart"}
                    label={"Pickup Date From"}
                    value={startDate || null}
                    onChange={handleStartDateChange}
                  />
              </Grid>
              <Grid item xs={2}>
                  <DateOnlyPicker
                    id={"dateEnd"}
                    name={"dateEnd"}
                    label={"Pickup Date To"}
                    value={endDate || null}
                    onChange={handleEndDateChange}
                    min={startDate}
                  />
              </Grid>
              <Grid item xs={1}>
                  <Button
                    onClick={handleSearchDateRangeClick}
                    disabled={(!startDate || !endDate)}
                  >Search Date Range</Button>
              </Grid>
              <Grid item xs={2}>
                  {
                    isAce &&
                    <TgfAssociateAutoComplete
                      id={"filterAssociate"}
                      name={"filterAssociate"}
                      onChange={handleFilterAssociateChange}
                    />
                  }
              </Grid>
              <Grid item xs={3} style={{textAlign: "right"}}>
                  <div className="margin-contribution-report-header-text">
                      <span className="margin-contribution-report-header-text-green">
                          {`Involved Margin Total: `}
                      </span>
                      <span>
                          {`${formatter.format(involvedMarginTotal)}`}
                      </span>
                  </div>
              </Grid>
          </Grid>
          <Grid container spacing={2} direction="column">
              <Grid item>
                  <TgfDataGrid
                      className={"margin-contribution-report-grid"}
                    ref={ref}
                    onLoadDataPage={loadGridData}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    gridColumns={GridColumns}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500],
                    }}
                    sort={[{field: "margin", dir: "desc"}]}
                  />
              </Grid>
          </Grid>
      </FullWidthLayout> : <AccessDeniedPage />
    )
}
export default MarginContribution
