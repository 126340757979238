import React from 'react';
import { Add } from '@material-ui/icons';

import AppButton from '../../../../core/components/AppButton/AppMuiButton';
import AppCard from '../../../../core/components/cards/AppCard/AppMuiCard';
import AppMuiSimpleTable from '../../../../core/components/AppTable/AppMuiSimpleTable';
import BillingContactListingGridColumns from './BillingContactListingGridColumns';
import {Grid} from '@material-ui/core';

const BillingContactListingCard = (props) => {

  const {
    onNewContactClick,
    onEditContactClick,
    onDeleteContactClick,
    contacts,
    disableEdit
  } = props;

  const gridColumns = BillingContactListingGridColumns(onEditContactClick, onDeleteContactClick, disableEdit);

  return (
    <AppCard title={"Billing Contacts"}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <AppButton disabled={disableEdit} startIcon={<Add />} onClick={onNewContactClick}>New Contact</AppButton>
        </Grid>
        <Grid item>
          <AppMuiSimpleTable disabledEdit={disableEdit} columns={gridColumns} data={contacts} />
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default BillingContactListingCard;