import AppInput from '../components/inputs/AppInput/AppMuiInput';
import FormFactorGraft from './FormFactorGraft';
import React from 'react';

const FormFactorValueInput = (props) => {

  const {
    field,
    as: AsComponent = AppInput,
    valueProp = 'value',
    inputEvent = 'onChange',
    eventValueMap = (e) => e.target.value,
    fieldPrefix = 'values',
    setMethodName = 'setFieldValue',
    additionalSlices = [],
    additionalGraft = () => {},
    valueDefault = '',
    ...otherProps
  } = props;

  const graftMap = ([value, setFieldValue, ...additionalArgs]) => {
    const baseProps = {
      [valueProp]: value || valueDefault,
      [inputEvent]: (...args) => setFieldValue(field, eventValueMap(...args))
    };
    return {
      ...baseProps,
      ...additionalGraft(additionalArgs)
    };
  };

  const fullyQualifiedField = [fieldPrefix, field]
    .join('.');

  return (
    <FormFactorGraft
      as={AsComponent}
      slices={[fullyQualifiedField, setMethodName].concat(additionalSlices)}
      graftMap={graftMap}
      {...otherProps}
    />
  );
};

export default FormFactorValueInput;