import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompany = async (shell, companyId) => {

  const company = await shell.gateway.getCompany(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyLoaded,
    company
  };

};

export default ActionBuilder
  .for(loadCompany)
  .build();