import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadQaFailed = async (shell, options) => {

  const { qaFailed, count } = await shell.gateway.getQaFailed(options);

  return {
    type: ActionTypes.Automation.RetrieveQaFailed.Loaded,
    qaFailed,
    count
  };
};

export default ActionBuilder
  .for(loadQaFailed)
  .build();
