import React from "react";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Edit, Info} from "@material-ui/icons";
import {
    loadFlagMultistopInactiveIcon,
    loadFlagMutlistopActiveIcon,
    loadFlagBlindActiveIcon,
    loadFlagBlindInactiveIcon,
    loadFlagHazardousActiveIcon,
    loadFlagHazardousInactiveIcon,
} from "../../../../core/media/icons/exportCustomIcons";
import {Link} from "react-router-dom";
import PostAge from "./PostAge";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {useSelector} from "react-redux";


const spanStyles = {
    padding: 4
}

const computeIcon = (bool, activeIcon, inactiveIcon, altMessage1, altMessage2) => {
    return bool ?
      <img style={{height: 19, width: 19}} src={activeIcon} alt={altMessage1}/> :
      <img style={{height: 19, width: 19}} src={inactiveIcon} alt={altMessage2}/>;
}

export const loadFlagsCell = (cellProps) => {
    const isMultiStop = cellProps.dataItem?.isMultiStop;
    const isHazardous = cellProps.dataItem?.isHazMat;
    const isBlind = cellProps.dataItem?.isBlind;
    return <td>
        { cellProps.dataItem?.bolNumber != null
            ?
            <>
                <div>
                    <span style={spanStyles}>{computeIcon(isMultiStop, loadFlagMutlistopActiveIcon, loadFlagMultistopInactiveIcon, "is multistop", "is not multistop")}</span>
                    <span style={spanStyles}>{computeIcon(isHazardous, loadFlagHazardousActiveIcon, loadFlagHazardousInactiveIcon, "is hazardous", "is not hazardous")}</span>
                    <span style={spanStyles}>{computeIcon(isBlind, loadFlagBlindActiveIcon, loadFlagBlindInactiveIcon, "is blind", "is not blind")}</span>
                </div>
            </>

            : <span style={{color: "red"}}>POSTING</span>
        }
    </td>
}

export const LoadFlagsHeaderCell = ({loadType}) => {
    return (
        <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
            <span>Load Flags</span>
            <InfoIconTooltip title={`This information is dependent upon how the shipment is built in the CRM and does not reflect how it may be posted in ${loadType}.  If you see "POSTING", it's because there is no shipment involved with the posting.`} />
        </div>
    )
}

export const EstMilesHeaderCell = () => {
    return (
        <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
            <span>Est. Miles</span>
            <InfoIconTooltip title={`The miles that are displayed are the approximate driving miles according to Google Maps and are based off the involved From and To cities and states.`} />
        </div>
    )
}


// Multi use Cells
export const loadDetailsCell = (props, onClickLoadDetails) => {
    return <td className={props.dataItem.isCovered ? "is-covered" : null}>
          <AppIconButton
            Icon={Info}
            onClick={() => onClickLoadDetails(props)}
            disabled={!props.dataItem?.bolNumber}
          />
      </td>;
}

// TODO we are going to pass in the whole row/record when we click the edit button.
export const editCell = (props, onClickEditButton) => {
    return (
      <td className={props.dataItem.isCovered === 1 ? "is-covered" : null}>
          <AppIconButton
            Icon={Edit}
            tooltip={`Edit Load`}
            onClick={() => onClickEditButton(props.dataItem)}
          />
      </td>
    );
}

export const ShipmentNumberCell = (cellProps) => {
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    const bolNumber = cellProps.dataItem?.bolNumber;
    return <td>
        { bolNumber
            ? <Link className={"tgf-grid-link-cell"} to={`/shipment/${bolNumber}/details`} target={openLinksInNewTab ? "_blank" : "" }>{bolNumber}</Link>
            : <span style={{color: "red"}}>POSTING</span>
        }
    </td>
}

export const postAgeCell = (cellProps, refreshPosting, disabled, disabledReason, tooltip) => {
    return <td>
        <PostAge
            dateRefreshable={cellProps.dataItem?.datRefreshable ? new Date(cellProps.dataItem?.datRefreshable) :  new Date(cellProps.dataItem?.truckstopRefreshable) }
            postId={cellProps.dataItem?.id}
            refreshPosting={refreshPosting}
            disabled={disabled}
            disabledReason={disabledReason}
            showText={false}
            tooltip={tooltip}
        />
    </td>;
}

