import React, {useEffect, useState} from "react";
import AppMuiCard from "../../../core/components/cards/AppCard";
import AppCurrencyText from "../../../core/components/text/AppCurrencyText";
import AppText from "../../../core/components/text/AppText";

function getDaysInCurrentMonth(selectedDate) {
  return new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  ).getDate();
}

const getTotalRevenuePace = (marginTotal) => {
  let today = new Date();
  const daysInMonth = getDaysInCurrentMonth(today);
  const currentDayOfMonth = today.getDate();
  return (marginTotal / currentDayOfMonth) * daysInMonth;
}

const TotalRevenuePaceTable = (props) => {
  const [monthYearValid, setMonthYearValid] = useState(false);
  const { date, grossMarginData } = props;
  // If selection is not equal to current month, then we return n / a
  useEffect(() => {
    if ( date && `${date.getFullYear()}${date.getMonth()}` === `${new Date().getFullYear()}${new Date().getMonth()}`) {
      setMonthYearValid(true)
    }
    else {
      setMonthYearValid(false)
    }
  }, [date])

  let totalRevenuePace;
  if(grossMarginData.total) {
    totalRevenuePace = getTotalRevenuePace(grossMarginData.total.customerCost);
  }

  return grossMarginData.total ? (
    <AppMuiCard>
      <h1>
        <AppText
          value={" Total Revenue Pace: "}
          style={
            grossMarginData.total.margin < 0
              ? { color: "red" }
              : { color: "blue" }
          }
        ></AppText>
        {monthYearValid ?
          <AppCurrencyText value={totalRevenuePace}></AppCurrencyText>
          :
          <AppCurrencyText value={grossMarginData.total.customerCost}></AppCurrencyText>
        }
      </h1>
    </AppMuiCard>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default TotalRevenuePaceTable;
