import Core from '@atomos/core';
import ActionBuilder from "../../ActionBuilder2";
import { UsStates, CanadianProvinces } from '@tgf-crm/geography';
import ActionTypes from '../../ActionTypes';

const stateProvinces = Core.Utils
  .toPairs(UsStates)
  .concat(Core.Utils.toPairs(CanadianProvinces));

const loadStateProvinces = (context) => {

  return {
    type: ActionTypes.Support.StateProvinces.Loaded,
    stateProvinces
  };

};

export default ActionBuilder
  .for(loadStateProvinces)
  .build();