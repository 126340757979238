import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipment = async (shell, bolNumber) => {

  const shipment = await shell.gateway.getShipment(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentLoaded,
    shipment
  }
};

export default ActionBuilder
  .for(loadShipment)
  .build();