import React from 'react';
import FormFactorValueInput from '../../../../core/FormFactor/FormFactorValueInput';
import AppCard from 'core/components/cards/AppCard';
import GeneralNoteCopyButton from './GeneralNoteCopyButton';

const BillingNotesCard = (props) => {

    const {
        disableEdit
    } = props;


    const copyAction = (
    <GeneralNoteCopyButton disableEdit={disableEdit} />
  );

  return (
    <AppCard title={'Billing Notes'} action={copyAction}>
      <FormFactorValueInput
        field={'generalNote'}
        id={'generalNote'}
        label={'General Billing Notes'}
        inputProps={{ maxLength: 1000, rowsMin: 12 }}
        multiline={true}
        disabled={disableEdit}
      />
    </AppCard>
  )
};

export default BillingNotesCard;