class OdataUtils {
    constructor() {
    }

    static odataOrderByToSequelizeSort (odataOrderBy) {
        if(!Array.isArray(odataOrderBy)) {
            odataOrderBy = [odataOrderBy];
        }
        return odataOrderBy.map((v) => v.split(" "));
    }

    static odataToQueryStringObjectWithoutFilters (odata) {
        return {
            pageIndex: odata.skip / odata.top,
            pageSize: odata.top,
            sort: OdataUtils.odataOrderByToSequelizeSort(odata.orderBy)
        }
    }
}

export default OdataUtils;