import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

/**
 * Retrieves the first shipment for a given customer.
 * @param {object} shell - A shell instance.
 * @param {number} customerId - The ID for a customer.
 */
const retrieveCustomerFirstShipment = async (shell, customerId) => {

  if (!customerId)
    return;

  const firstShipment = await shell.gateway.getCompanyFirstShipment(customerId);

  return {
    type: ActionTypes.Shipment.Customer.RetrievedCustomerFirstShipment,
    customerId,
    firstShipment
  };

};

export default ActionBuilder
  .for(retrieveCustomerFirstShipment)
  .build();
