import * as Redux from 'redux';

import associate from './associate';
import associates from './associates';
import associatesCount from './associatesCount';
import associateJacks from './associateJacks';
import associateNoteTypes from './associateNoteTypes';
import associateNotes from './associateNotes';
import parentAssociate from './parentAssociate';
import roles from './roles'

export default Redux.combineReducers({
  associate,
  associates,
  associatesCount,
  associateJacks,
  associateNotes,
  associateNoteTypes,
  parentAssociate,
  roles
});