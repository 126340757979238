import React from "react";


const FieldError = (props) => {
  const {children, shouldRender = true} = props;
  const styles = {
    color: "red",
    marginLeft: 3,
    marginBottom: -18,
    fontSize: ".6rem"
  }
  return children && shouldRender === true ? <div style={styles}>{children}</div> : null
}

export default FieldError;