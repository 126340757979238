import * as yup from "yup";

export const InvoiceConfigValidationSchema = yup.object().shape({
    address1: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    postalCode: yup.string().required(),
    automationDisabled: yup.string().required(),
    deliveryMethodNote: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "OTHER") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Other."})
                }
            }
            return true
        }
    }),
    batchSize: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),
    documentGrouping: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),
    includePod: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),
    /*

    Removing Document Validation Per Mike and Kim

    includeShipmentAgreement: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),
    includeScaleTickets: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),
    includeLumperReceipt: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),
    includeTallySheet: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),
    includeInterchangeSlips: yup.string().nullable().test({
        test(value) {
            if(this.parent.invoiceDeliveryMethod === "EMAIL") {
                if (!value) {
                    return this.createError({message: "Required if Delivery Method is Email."})
                }
            }
            return true
        }
    }),

    */


});
