import React from "react";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import AppMuiNumericInput from "../../../../core/components/inputs/AppNumericInput";

const Header = (props) => {

    const handleDuplicateCountChange = (e) => {
        const value = e.target.value && e.target.value.trim().length > 0 ?
          parseInt(e.target.value) : null;
        props.setDuplicateCount(value);
    };

    return(
      <>
          <Grid container item xs={12}>
              <AppMuiCard style={{width: "100%"}}>
                  <Grid item xs={12} style={{color: "red", fontSize: "larger"}}>
                      <strong>
                          Duplicated shipments will have today's date as the pickup date by default.
                          The Delivery Date will be left blank by default.
                          A Multi-Stop shipment's Arrival Date fields will also be left blank by default.
                          These fields MUST be adjusted as needed from within the new shipment(s).
                      </strong>
                  </Grid>
                  <br/>
                  <Grid item xs={12} style={{color: "green", fontSize: "larger"}}>
                      <strong>
                          The following fields will be duplicated from the original shipment:
                          Freight Category, Equipment Type, Customer, Customer Rep., Shipper, Consignee,
                          General Notes, Rate Con Notes, the Hazardous toggle,
                          the Blind toggle, the Generate Del. Order toggle, all Load Contents fields, and (if
                          applicable) Multi-Stop Locations/Stop/Notes.
                          Changes to this information MUST be made from within the new shipment(s).
                      </strong>
                  </Grid>
                  <br/>
                  <Grid item xs={12} style={{fontSize: "larger"}}>
                      <strong>
                          You can choose any of the additional fields to duplicate from the Additional
                          Duplication Options section below.
                          When you are finished, enter the number of duplicate shipments you are needing for
                          this shipment (maximum of 20) in the "Number of
                          Duplicates" field below and click "Duplicate" button at the top of the page. When
                          the duplication process is complete, the new shipment
                          numbers will appear below.
                          NOTE - If the "Duplicate" button and the Additional Duplicate Options are disabled,
                          then a Rule Check below has failed.
                      </strong>
                  </Grid>
                  <br/>
                  <Grid item xs={3}>
                      <AppMuiNumericInput
                        label={'Number of Duplicates'}
                        onChange={handleDuplicateCountChange}
                        value={props.duplicateCount}
                      />
                  </Grid>
              </AppMuiCard>
          </Grid>
      </>
    );
}

export default Header
