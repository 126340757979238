const CommissionTypeLabels = [
  {
    label: 'Gross Margin Percentage',
    value: 'margin'
  },
  {
    label: 'Fee Percentage',
    value: 'fee'
  }
];

export default CommissionTypeLabels;