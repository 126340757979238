import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadAssociateJacks = async (shell, associateId) => {

  const associateJacks = await shell.gateway.getAssociateJacks(associateId);

  return {
    type: ActionTypes.Admin.ManageUsers.AssociateJacksLoaded,
    associateJacks
  };

};
export default ActionBuilder
  .for(loadAssociateJacks)
  .build();