import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppCheckbox from '../../../../core/components/inputs/AppCheckbox';
import AppNumberText from '../../../../core/components/text/AppNumberText';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';

const TableTopBlock = (totalAmount, onChange, checked) => ({ count }) => {

  return (
    <div style={{ backgroundImage: 'linear-gradient(#FFFFFF, #e3e4e4)'}}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={6} style={{ paddingLeft: 10 }}>
          <Typography variant="h6">
            <AppNumberText value={count} minFractionDigits={0} maxFractionDigits={0} /> Associates - TOTAL: <strong><AppCurrencyText value={totalAmount} /></strong></Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
          <AppCheckbox
            label="Show all/past Agents"
            handleChange={onChange}
            checked={checked}
            color="primary"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TableTopBlock;