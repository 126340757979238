import React from 'react';
import Typography from '@material-ui/core/Typography';
import AppNumberText from "../../../../../core/components/text/AppNumberText";

const TableTopBlock = ({ count }) =>
  <div style={{ padding: 10, backgroundColor: '#74747422' }}>
    <Typography variant="h6">
      <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={count} /> Associates
    </Typography>
  </div>;

export default TableTopBlock;