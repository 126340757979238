import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyCreditStatus = async (shell, companyId) => {

  const creditStatus = await shell.gateway.getCompanyCreditStatus(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyCreditStatusLoaded,
    creditStatus
  };
};

export default ActionBuilder
  .for(loadCompanyCreditStatus)
  .build();