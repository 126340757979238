import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadAssociateNoteTypes = async (shell) => {
  const associateNoteTypes = await shell.gateway.getAssociateNoteTypes();

  return {
    type: ActionTypes.Admin.ManageUsers.AssociateNoteTypesLoaded,
    associateNoteTypes
  };
};

export default ActionBuilder
  .for(loadAssociateNoteTypes)
  .build();