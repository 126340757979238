import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadShipmentCarrierContact = async (shell, bolNumber) => {

  const shipmentCarrierContact = await shell.gateway.getCarrierContact(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.shipmentCarrierContactLoaded,
    shipmentCarrierContact
  }

};

export default ActionBuilder
  .for(loadShipmentCarrierContact)
  .build();