import React from 'react';
import When from '../../../../core/components/condtionals/When';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import { Typography } from '@material-ui/core';

const CarrierAddress = (props) => {

  const {
    name,
    mcNumber,
    ...otherProps
  } = props;

  return (
    <AppMuiCard title="Carrier Profile Address">
      {
        name && mcNumber && <Typography variant="h5">
          {name} - {mcNumber}
        </Typography>
      }
      <Address
        {...otherProps}
      />
    </AppMuiCard>
  );
};

const Address = (props) => {

  const {
    address1,
    address2 = '',
    city,
    stateProvince,
    postalCode
  } = props;

  const hasAddress = Boolean(
    address1 &&
    city &&
    stateProvince &&
    postalCode
  );

  if (!hasAddress)
    return null;

  return (
    <>
      <Typography variant="body1">
        {address1}
      </Typography>
      <When condition={address2}>
        {() =>
          <Typography variant="body1">
            {address2}
          </Typography>
        }
      </When>
      <Typography variant="body1">
        {city}, {stateProvince} {postalCode}
      </Typography>
    </>
  );
};

export default CarrierAddress;