import ActionTypes from '../../ActionTypes';

const factoringCompanyCategoryType = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.Support.CompanyCategoryTypesLoaded:
            return action.factoringCompanyCategoryType;
        default:
            return state;
    }
};

export default factoringCompanyCategoryType;