import * as Redux from 'redux';

import carrier from './carrier'
import carrierNotes from './carrierNotes';
import compliance from './compliance';
import history from './history'
import historyCount from './historyCount';
import rmisCarrier from './rmisCarrier';
import rmisCarrierContacts from './rmisCarrierContacts';
import carrierDocuments from './carrierDocuments';

export default Redux.combineReducers({
  carrier,
  carrierDocuments,
  carrierNotes,
  compliance,
  history,
  historyCount,
  rmisCarrier,
  rmisCarrierContacts,
});