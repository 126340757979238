import ActionTypes from '../../../ActionTypes';

const companyDocuments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Listing.Disposed:
      return [];
    case ActionTypes.AddressBook.Listing.LoadCompanyDocuments:
      return action.companyDocuments;
    case ActionTypes.AddressBook.Modification.CompanyDeleteDocument:
      return state.filter(sd => sd.id !== action.documentId);
    case ActionTypes.AddressBook.Modification.CompanyPublishDocument:
      return [
        ...state,
        action.document
      ]
    default:
      return state;
  }
};

export default companyDocuments;