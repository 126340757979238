import ActionTypes from '../../../ActionTypes';

const associate = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Admin.ManageUsers.Disposed:
      return null;
    case ActionTypes.Admin.ManageUsers.AssociateSaved:
    case ActionTypes.Admin.ManageUsers.AssociateLoaded:
      return action.associate;
    default:
      return state;
  }
};

export default associate;