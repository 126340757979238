import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const listAssociates = async (shell, loadAllAssociates = false, searchTerm = null) => {

    const options = {
        pageIndex: 0,
        pageSize: 100,
        filter: {},
        sort: []
    };

    if (!loadAllAssociates) {
        options.filter.isActive = true;
    }

    if(searchTerm != null && searchTerm.length >= 3) {
        searchTerm = `%${searchTerm}%`;

        options.filter['[Op.or]'] = [
                { firstName: {'[Op.like]': searchTerm} },
                { lastName: {'[Op.like]': searchTerm} },
                { businessPhoneExt: {'[Op.like]': searchTerm} },
                { emailAddress: {'[Op.like]': searchTerm} },
                { systemId: {'[Op.like]': searchTerm} }
            ];
    }

    const dataPage = await shell.gateway.listAssociates(options);
    dataPage.type = ActionTypes.Admin.ManageUsers.ListingLoaded;
    return dataPage;
};

export default ActionBuilder
    .for(listAssociates)
    .build();