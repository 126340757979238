import ActionTypes from '../../../ActionTypes';

const companyFinancial = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.AddressBook.Modification.Disposed:
      return null;
    case ActionTypes.AddressBook.Modification.CompanyFinancialLoaded:
    case ActionTypes.AddressBook.Modification.CompanyFinancialSaved:
      return action.companyFinancial;
    default:
      return state;
  }
};

export default companyFinancial;