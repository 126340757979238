import ActionTypes from "../../../ActionTypes";

const consignee = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.CreateDocs.Disposed:
      return null;
    case ActionTypes.Shipment.CreateDocs.ShipmentLoaded:
      return action.consignee;
    default:
      return state;
  }
};

export default consignee;