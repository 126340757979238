import React from 'react';
import {Grid} from "@material-ui/core";
import ComponentBuilder from "../../../../core/ComponentBuilder";

import AppNumericInput from "../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";
import SecureField from "../../permissions/SecureField";

const OverallCosts = (props) => {

  const {
    carrierCost,
    customerCost,
    adjustedCarrierCost,
    adjustedCustomerCost,
    carrierCostError,
    customerCostError,
    adjustedCarrierCostError,
    adjustedCustomerCostError,
    setFieldValue
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(`invoice.${key}`, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <AppMuiCard title={'Overall Costs'}>
          <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <label htmlFor={'carrierCost'}>Carrier Cost</label>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierCost'}
                as={AppNumericInput}
                decimalScale={2}
                id={'carrierCost'}
                value={carrierCost}
                onChange={handleFieldChange('carrierCost')}
              />
              <AppValidationFailure message={carrierCostError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor={'customerCost'}>Customer Cost</label>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'customerCost'}
                as={AppNumericInput}
                id={'carrierCost'}
                value={customerCost}
                decimalScale={2}
                onChange={handleFieldChange('customerCost')}
              />
              <AppValidationFailure message={customerCostError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor={'adjustedCarrierCost'}>Adj. Carrier Cost</label>
            </Grid>
            <Grid item xs={12} md={6}>
              <AppNumericInput
                id={'adjustedCarrierCost'}
                disabled={true}
                decimalScale={2}
                value={adjustedCarrierCost}
              />
              <AppValidationFailure message={adjustedCarrierCostError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor={'adjustedCustomerCost'}>Adj. Customer Cost</label>
            </Grid>
            <Grid item xs={12} md={6}>
              <AppNumericInput
                id={'adjustedCustomerCost'}
                disabled={true}
                decimalScale={2}
                value={adjustedCustomerCost}
              />
              <AppValidationFailure message={adjustedCustomerCostError}/>
            </Grid>
          </Grid>
        </AppMuiCard>
      </Grid>
    </React.Fragment>
  )
};

const propsAreEqual = (prevProps, nextProps) => {
  const isEqual = prevProps.carrierCost === nextProps.carrierCost &&
    prevProps.customerCost === nextProps.customerCost &&
    prevProps.adjustedCarrierCost === nextProps.adjustedCarrierCost &&
    prevProps.adjustedCustomerCost === nextProps.adjustedCustomerCost &&
    prevProps.carrierCostError === nextProps.carrierCostError &&
    prevProps.customerCostError === nextProps.customerCostError &&
    prevProps.adjustedCarrierCostError === nextProps.adjustedCarrierCostError &&
    prevProps.adjustedCustomerCostError === nextProps.adjustedCustomerCostError &&
    prevProps.actualDeliveryDateError === nextProps.actualDeliveryDateError;

  return isEqual;
};

export default React.memo(ComponentBuilder
    .wrap(OverallCosts)
    .build(),
  propsAreEqual
);