import React from 'react';
import {Grid} from '@material-ui/core';
import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';

const LockShipmentDialog = (props) => {

  const {
    confirmMessage,
    onSave,
    onCancel,
    canSave
  } = props;

  const dialogActions = [
    {
      title: 'Cancel',
      action: onCancel
    }
  ];

  if(canSave) dialogActions[1] = {
    title: 'Save',
    action: onSave
  }

  return (
    <AppDialog
      title={'Lock Shipment'}
      open={true}
      onClose={onCancel}
      width={'sm'}
      actionButtons={dialogActions}>

      <Grid container spacing={2}>
        <Grid item>
          {confirmMessage}
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default LockShipmentDialog;