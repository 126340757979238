import ActionTypes from '../../ActionTypes';

const shipmentTrackingPhases = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.ShipmentTrackingPhasesLoaded:
      return action.shipmentTrackingPhases;
    default:
      return state;
  }
};

export default shipmentTrackingPhases;