import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from '../../ActionTypes';

const dispose = async (context) => {
    return {
        type: ActionTypes.Dashboard.Disposed
    };
};

export default ActionBuilder
    .for(dispose)
    .build();