import React from 'react';
import ComponentBuilder from '../../core/ComponentBuilder';
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

const GlobalSnackbar = (props) => {

  const {
    message,
    clearMessage
  } = props;

  const {
    color = 'success',
    vertical = 'bottom',
    horizontal = 'right',
    timeout = 3000,
    content = ''
  } = (message || {});

  const [timerId, setTimerId] = React.useState(null);

  React.useEffect(() => {
    clearTimeout(timerId);

    if (message) {
      const newTimerId = setTimeout(() => {
        clearMessage();
      }, timeout);
      setTimerId(newTimerId);
    }

  }, [message]);

  return (
    <React.Fragment>
      {
        message &&
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical,
            horizontal
          }}
        >
          <Alert severity={color} variant={'filled'}>
            {content}
          </Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
};

export default ComponentBuilder
  .wrap(GlobalSnackbar)
  .stateToProps((state, ownProps) => {
    return {
      message: state.sys.snackbarMessage
    };
  })
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async clearMessage() {
        dispatch(await shell.actions.sys.clearSnackbarMessage());
      }
    };
  })
  .build();