import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';

const CostDescriptors = [
  { key: 'carrierCost', title: 'Carrier Cost' },
  { key: 'customerCost', title: 'Customer Cost' },
  { key: 'adjustedCarrierCost', title: 'Adj. Carrier Cost' },
  { key: 'adjustedCustomerCost', title: 'Adj. Customer Cost' }
];

const CostRow = ({ title, value }) => (
  <React.Fragment>
    <Grid item xs={6} style={{ textAlign: 'right' }}>
      {title}
    </Grid>
    <Grid item xs={6}>
      <Typography
        variant={'h5'}>
        {value}
      </Typography>
    </Grid>
  </React.Fragment>
);

const CostSummary = (props) => {

  const processedCosts = CostDescriptors
    .map(({ key, title }) => ({ title, value: (<AppCurrencyText value={props[key]} />) }));

  return (
    <AppMuiCard title="Cost Summary">
      <Grid container spacing={1}>
        {
          processedCosts.map((cost, i) =>
            <CostRow key={i} {...cost} />)
        }
      </Grid>
    </AppMuiCard >
  );
}

export default CostSummary;