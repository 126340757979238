import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';

const AppDrawer = ({ children, ...otherProps }) => {
    return (
        <Drawer {...otherProps}>
            {children}
        </Drawer>
    )
}

AppDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

export default AppDrawer;