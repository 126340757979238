import addressBookActions from '../../hubs/addressBook/actions';
import adminActions from '../../hubs/admin/actions';
import carrierActions from '../../hubs/carrier/actions';
import commissionActions from '../../hubs/commission/actions';
import dashboardActions from '../../hubs/dashboard/actions';
import personaActions from '../../hubs/persona/actions';
import reportingActions from '../../hubs/reporting/actions';
import shipmentActions from '../../hubs/shipment/actions';
import supportActions from '../../hubs/support/actions';
import sysActions from '../../hubs/sys/actions';
import automationActions from '../../hubs/automation/actions';
import utilitySlice from "../../hubs/utility/utilitySlice"

const createStateStoreActions = () => {
  return {
    addressBook: addressBookActions,
    admin: adminActions,
    carrier: carrierActions,
    commission: commissionActions,
    dashboard: dashboardActions,
    persona: personaActions,
    reporting: reportingActions,
    shipment: shipmentActions,
    support: supportActions,
    sys: sysActions,
    automation: automationActions,
    utility: utilitySlice.actions
  };
};

export default createStateStoreActions;