import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';

const grossMarginStyles = makeStyles((theme) => ({
  lessThanZero: {
    color: theme.palette.error.main
  }
}));

const GrossMarginCell = ({ rowData: shipment }) => {
  const classes = grossMarginStyles();
  const grossMargin = shipment.customerCost - shipment.carrierCost;
  const className = grossMargin < 0 ?
    classes.lessThanZero : '';
  return (
    <AppCurrencyText className={className} value={grossMargin} />
  );
};
const CustomerListingTable = (props) => {

  const {
    count,
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = props;

  const GridColumns = [
    {
      title: 'Ship #',
      field: 'bolNumber',
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
    },
    {
      title: 'Carrier Cost',
      field: 'carrierCost',
      styles: {
        width: 70,
      },
      dataType: 'currency',
    },
    {
      title: 'Customer Cost',
      field: 'customerCost',
      styles: {
        width: 70,
      },
      dataType: 'currency',
    },
    {
      title: 'Total Margin',
      field: 'totalMargin',
      noSort: true,
      styles: {
        width: 70,
      },
      dataType: 'component',
      component: GrossMarginCell
    },
    {
      title: 'Freight Category',
      field: 'freightCategory',
      styles: {
        width: 60,
      },
      dataType: 'string',
    },
    {
      title: 'Carrier Name',
      field: 'carrierName',
      styles: {
        width: 200,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/carrier/${record.carrierMcNumber}/details`, record.carrierName])
    },
    {
      title: 'Third Party',
      field: 'thirdPartyCompanyName',
      dataType: 'string',
      styles: {
        width: 90
      },
    },
    {
      title: 'Pickup Date',
      field: 'bolDate',
      styles: {
        width: 70,
      },
      dataType: 'date'
    },
    {
      title: 'State From',
      field: 'fromState',
      styles: {
        width: 50,
      },
      dataType: 'string',
    },
    {
      title: 'State To',
      field: 'toState',
      styles: {
        width: 50,
      },
      dataType: 'string',
    },
    {
      title: 'Equipment Type',
      field: 'equipmentType',
      styles: {
        width: 100,
      },
      dataType: 'string',
    }
  ];

  return (
    <AppDenseGrid
      count={count}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

export default CustomerListingTable;