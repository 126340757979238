import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveShipmentInvoiceAuditNote = async (shell, invoiceAuditNote) => {

  const updatedNotes = await shell.gateway
    .saveShipmentInvoiceAuditNote(invoiceAuditNote)

  return {
    type: ActionTypes.Shipment.Modification.ShipmentInvoiceAuditNoteSaved,
    invoiceAuditNotes: updatedNotes
  };
};

export default ActionBuilder
  .for(saveShipmentInvoiceAuditNote)
  .build();