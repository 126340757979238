import React from 'react';
import FormFactorGraft from './FormFactorGraft';
import AppDropdown from '../components/dropdowns/AppDropdown';

const FormFactorValueDropdown = (props) => {

  const {
    formField,
    optionsFormField,
    optionField,
    optionFieldIndex,
    formFieldPrefix = 'values',
    formFieldSetterName = 'setFieldValue',
    optionsFormFieldPrefix = 'supportingValues',
    additionalSlices = [],
    additionalGraft = () => {},
    ...otherProps
  } = props;

  const slices = [
    `${formFieldPrefix}.${formField}`,
    `${optionsFormFieldPrefix}.${optionsFormField}`,
    formFieldSetterName
  ];

  const graftMap = ([value, options, fieldSetter, ...additionalArgs]) => {
    const baseProps = {
      value: value || null,
      field: optionField,
      fieldIndex: optionFieldIndex,
      options: options || [],
      onChange: (e, value) => fieldSetter(formField, value)
    };
    return {
      ...baseProps,
      ...additionalGraft(additionalArgs)
    };
  };

  return (
    <FormFactorGraft
      as={AppDropdown}
      slices={slices.concat(additionalSlices)}
      graftMap={graftMap}
      {...otherProps}
    />
  );
};

export default FormFactorValueDropdown;