import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadAssociateMonthlyAdjustments = async (shell, associateId, startDate, endDate, offset, limit, sort) => {

  const {adjustments, count} = await shell.gateway.getAssociateMonthlyAdjustments(
    associateId,
    {
      startDate,
      endDate,
      offset,
      limit,
      sort
    }
  );

  return {
    type: ActionTypes.Reporting.MonthlyGrossMargin.MonthlyCommissionAdjustmentLoaded,
    monthlyAdjustments: adjustments,
    monthlyAdjustmentCount: count
  }

};

export default ActionBuilder
  .for(loadAssociateMonthlyAdjustments)
  .build();