import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const savePendingCarrier = async (shell, mcNumber, carrierLabel, associateId) => {

  const newPendingCarrier = shell.gateway.createPendingCarrier();
  newPendingCarrier.mcNumber = mcNumber;
  newPendingCarrier.label = carrierLabel;
  newPendingCarrier.associateId = associateId;

  const pendingCarrier = await shell.gateway.savePendingCarrier(newPendingCarrier);

  return {
    type: ActionTypes.Carrier.Pending.Saved,
    pendingCarrier
  }
};

export default ActionBuilder
  .for(savePendingCarrier)
  .build();
