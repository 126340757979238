import React, {useRef, useState} from "react";
import AppMuiCard from "../../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import {useDispatch} from "react-redux";
import TgfDataGrid from "../../../../../../../core/kendo/dataGrid/TgfDataGrid";
import AppMuiButton from "../../../../../../../core/components/AppButton";
import NewAssociateNoteModal from "./NewAssociateNoteModal";

const AssociateNotesListingGrid = (props) => {
    const personalDispatch = useDispatch();
    const [newNoteDialogOpen, setNewNoteDialogOpen] = useState(false);
    const ref = useRef();

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        odata.filter = {
            associateId: props.associateId,
        };

        return odata;
    }

    const loadAssociateNotes = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAssociateJacks'));
            return await window.shell.gateway.getPageOfAssociateNotes(odata);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAssociateJacks'));
        }
    }

    const onClickAddNote = () => {
        setNewNoteDialogOpen(true);
    };

    const onCloseDialog = () => {
        setNewNoteDialogOpen(false);
        ref.current.refresh();
    };

    const gridColumns = [
        {
            title: 'Category',
            field: 'category',
            type: 'text',
            sortable: true,
            width: 100
        },
        {
            title: 'Content',
            field: 'content',
            type: 'custom',
            cell: (props) => <td className={"k-table-tbody k-table-td show-all-text"}><span>{props.dataItem.content ?? ' '}</span></td>,
            sortable: false
        },
        {
            title: 'Created By',
            field: 'createdBy',
            type: 'text',
            sortable: true,
            width: 140
        },
        {
            title: 'Date',
            field: 'createDate',
            type: 'date',
            width: 80
        },

    ];

    return (
        <AppMuiCard title={"Notes"}>
            <Grid container spacing={2}>
                <Grid item>
                    <AppMuiButton type={"submit"} onClick={onClickAddNote} disabled={!props.associateId}>
                        + New Note
                    </AppMuiButton>
                </Grid>
                {newNoteDialogOpen && (
                    <NewAssociateNoteModal
                        onCloseDialog={onCloseDialog}
                        associateId={props.associateId}
                    />
                )}
                <Grid item xs={12}>
                    <TgfDataGrid
                        className={"manage-users__associate-notes-grid"}
                        ref={ref}
                        onLoadDataPage={loadAssociateNotes}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                        gridColumns={gridColumns}
                        sort={[{ field: "createDate", dir: "desc" }]}
                        pageable={{
                            pageSize: 10,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500],
                        }}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default AssociateNotesListingGrid;
