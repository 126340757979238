import React from 'react';
import { LibraryBooks } from '@material-ui/icons';

import FormFactorGraft from 'core/FormFactor/FormFactorGraft';
import AppIconButton from 'core/components/AppButton/AppIconButton';

const CopyTooltip = `Copy from company's primary notes field.`;

const buttonSlices = ['setFieldValue', 'supportingValues.company'];
const buttonGraftMap = ([setFieldValue, company]) => ({
  onClick: () => {
    setFieldValue('generalNote', company.note);
  }
});

const GeneralNoteCopy = (props) => {

  return (
    <FormFactorGraft
      as={AppIconButton}
      color={'primary'}
      key={'copy'}
      Icon={LibraryBooks}
      tooltip={CopyTooltip}
      slices={buttonSlices}
      graftMap={buttonGraftMap}
      disabled={props.disableEdit}
    />
  )
};

export default GeneralNoteCopy;