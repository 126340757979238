import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const dispose = () => {
  return {
    type: ActionTypes.Shipment.CreateDocs.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();