/**
 * Reusable way to combine many class names using rest/spread.
 * @param {...string} names - Collection of names to combine.
 * @return {string} The joined names.
 */
const combineClassNames = (...names) => {
  return names
    .filter(x => x)
    .join(' ')
    .trim();
};

export default combineClassNames;