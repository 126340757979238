import React from "react";
import ShipmentRecordNav from "../ShipmentRecordNav";
import {ShipmentTitle} from "../ShipmentTitle";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import {useParams} from "react-router-dom";
import FinancialForm from "./FinancialForm/FinancialForm";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";


const FinancialPage = () => {
    const urlParams = useParams();
    const bolNumber = parseInt(urlParams?.id);
    usePageTitle(`Ship ${bolNumber}`);

    return (
        <FullWidthLayout SideNav={ShipmentRecordNav} title={<ShipmentTitle bolNumber={bolNumber} title={`Shipments - Financial - ${bolNumber ? bolNumber : 'New'}`}/>} className={"shipments-styles"}>
            <FinancialForm />
        </FullWidthLayout>
    )
}

export default FinancialPage;