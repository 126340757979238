const Business = require('@tgf-crm/business');

const createFlagPropAction = (...propNames) => (shipment, invoice) => {
  const changedInvoice = {
    ...invoice,
  };
  propNames.forEach(propName => changedInvoice[propName] = false);
  return [shipment, changedInvoice];
};

const createNullInvoiceAction = (propName) => (shipment, invoice) => {
  const changedInvoice = {
    ...invoice,
    [propName]: null
  };
  return [shipment, changedInvoice];
}

class ShipmentReverter {

  constructor() {
    this.shipmentStatusTypes = null;
    this.stepStatuses = null;
    this.steps = null;
    this.isReady = false;
  }

  init(shipmentStatusTypes) {

    if (this.isReady)
      return;

    this.shipmentStatusTypes = shipmentStatusTypes;

    const custPaidStatusType = this.shipmentStatusTypes
      .find(st => st.name === Business.Shipment.ShipmentStatusNames.CustomerPaid);
    const payPendStatusType = this.shipmentStatusTypes
      .find(st => st.name === Business.Shipment.ShipmentStatusNames.PaymentsPending);
    const invGeneratedStatusType = this.shipmentStatusTypes
      .find(st => st.name === Business.Shipment.ShipmentStatusNames.InvoiceGenerated);
    const holdStatusType = this.shipmentStatusTypes
      .find(st => st.name === Business.Shipment.ShipmentStatusNames.Hold);

    this.stepStatuses = [
      custPaidStatusType,
      payPendStatusType,
      invGeneratedStatusType,
      holdStatusType
    ];

    const custPaidStep = new ShipmentReversionStep(custPaidStatusType, createFlagPropAction('customerWasPaid', 'dashboardCustomerWasPaid'), createNullInvoiceAction('customerPaidDate'));
    const payPendStep = new ShipmentReversionStep(payPendStatusType, createFlagPropAction('dashboardInvoiceWasSent'), createNullInvoiceAction('invoiceDate'), createNullInvoiceAction('invoiceDueDate'));
    const invGeneratedStep = new ShipmentReversionStep(invGeneratedStatusType, createFlagPropAction('dashboardInvoiceWasGenerated'));

    this.steps = [
      custPaidStep,
      payPendStep,
      invGeneratedStep
    ];

    this.isReady = true;
  }

  ensureInitialized() {
    if (!this.isReady)
      throw new Error('ShipmentReverter not initialized.');
  }

  canRevertStatus(statusId) {

    this.ensureInitialized();

    const statusIndex = this.stepStatuses
      .findIndex(st => st.id === statusId);

    // Return for any found status that isn't the last one (hold).
    // We cannot revert hold back further in this scenario.
    return statusIndex > -1 && statusIndex < this.stepStatuses.length - 1;
  }

  listAllowedStatuses(statusId) {

    this.ensureInitialized();

    const currentIndex = this.stepStatuses.findIndex(st => st.id === statusId);
    return currentIndex > -1 ?
      this.stepStatuses.slice(currentIndex + 1, this.stepStatuses.length) :
      [];
  }

  revert(shipment, invoice, revertToStatusTypeId) {

    this.ensureInitialized();

    const startingIndex = this.stepStatuses.findIndex(st => st.id === shipment.statusId);
    const endingIndex = this.stepStatuses.findIndex(st => st.id === revertToStatusTypeId);

    let changedShipment = shipment;
    let changedInvoice = invoice;
    for (let i = startingIndex; i < endingIndex; i++) {
      const step = this.steps[i];
      [changedShipment, changedInvoice] = step.invoke(changedShipment, changedInvoice);
    }

    changedShipment = {
      ...changedShipment,
      statusId: revertToStatusTypeId
    };

    return [changedShipment, changedInvoice];
  }

}

class ShipmentReversionStep {

  constructor(shipmentStatusType, ...actions) {
    this.shipmentStatusType = shipmentStatusType;
    this.actions = actions;
  }

  invoke(shipment, invoice) {
    let changedShipment = shipment;
    let changedInvoice = invoice;
    for (const action of this.actions) {
      [changedShipment, changedInvoice] = action(changedShipment, changedInvoice);
    }
    return [changedShipment, changedInvoice]
  }

}

export default ShipmentReverter;