import ActionTypes from "../../../ActionTypes";

const doNotPayCarrierCount = (state = 0, action) => {
  switch (action.type) {
    case ActionTypes.Automation.DoNotPayCarrier.Loaded:
      return action.count;
    default:
      return state;
  }
};

export default doNotPayCarrierCount;