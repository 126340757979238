import Core from '@atomos/core';
/**
 * Identifies whether a company ID represents
 * a new Company.
 * @param {number|undefined|null} companyId - The ID of a company.
 * @return {boolean}
 */
const isNewCompany = companyId => Core.Utils.isUndefined(companyId) ||
  companyId === 0;

export default isNewCompany;