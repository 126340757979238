import React from 'react';
import AppAlertStatic from '../../../core/components/AppAlertStatic';
import AppMuiCard from '../../../core/components/cards/AppCard';
import { Grid, Typography } from '@material-ui/core';

const CarrierLtlAlert = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <AppMuiCard>
          <AppAlertStatic>
            <Typography variant={'h6'}>
              This is an LTL carrier and does not require RMIS compliance; therefore no data will display on this
              page.
            </Typography>
          </AppAlertStatic>
        </AppMuiCard>
      </Grid>
    </Grid>
  );
};

export default CarrierLtlAlert;