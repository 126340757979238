import ActionTypes from "../../../ActionTypes";

const shipments = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Uncovered.Disposed:
      return [];
    case ActionTypes.Shipment.Uncovered.Loaded:
      return action.shipments;
    case ActionTypes.Shipment.Uncovered.Saved:
      return state.map(s =>
        s.shipmentBolNumber === action.shipment.shipmentBolNumber ?
          action.shipment : s);
    default:
      return state;
  }
};

export default shipments;