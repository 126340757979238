import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from "../../ActionTypes";

const loadFreightCategoryTypes = async (shell) => {

  const freightCategoryTypes = await shell.gateway.getFreightCategoryTypes();

  return {
    type: ActionTypes.Support.FreightCategoryTypesLoaded,
    freightCategoryTypes
  };
};

export default ActionBuilder
  .for(loadFreightCategoryTypes)
  .build();