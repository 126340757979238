import ActionTypes from '../../../ActionTypes';

const shipmentSearches = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Customer.SearchedCustomerShipments:
      return mergeSearchResults(state, action);
    case ActionTypes.Shipment.Customer.ClearedCustomerShipmentsSearch:
      return removeSearchResults(state, action);
    default:
      return state;
  }
};

const mergeSearchResults = (state, action) => {

  const {
    searchId,
    shipments,
    count
  } = action;

  return Object.assign({}, state, {
    [searchId]: {
      searchId,
      shipments,
      count
    }
  });

};

const removeSearchResults = (state, action) => {

  const {
    searchId
  } = action;

  return Object.assign({}, state, {
    [searchId]: null
  });

};

export default shipmentSearches;