import ActionTypes from "../../../ActionTypes";

const updateShipmentCompanyState = (state, key, shipmentCompany) => {

  return  Object.assign({}, state, {
    [key]: shipmentCompany
  })
}

const shipmentCompany = (state = {}, action) => {

  const {
    type,
    key,
    shipmentCompany
  } = action;

  switch (type) {
    case ActionTypes.Shipment.Modification.Disposed:
      return {};
    case ActionTypes.Shipment.Modification.ShipmentCompanyLoaded:
      return updateShipmentCompanyState(state, key, shipmentCompany);
    default:
      return state;
    }
};

export default shipmentCompany;