/**
 * Ensures a specific category type can be found in the
 * specified collection.
 * @param {object[]} categoryTypes - The collection of category types to search.
 * @param {string} name - The name of the type to search for.
 * @returns {object} A matching category type.
 * @throws {Error} - An error is thrown when the category type is not found by name.
 */
const ensureCompanyCategoryType = (categoryTypes, name) => {
  const matchingType = categoryTypes
    .find(ct => ct.name === name);
  if (!matchingType) {
    throw new Error(`The Company Type Category [${name}] was not found.`);
  }
  return matchingType;
};

export default ensureCompanyCategoryType;