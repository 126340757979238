import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipmentLockedAssociate = async (shell, associateId) => {

  const associate = await shell.gateway
    .getAssociate(associateId);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentLockedAssociateLoaded,
    associate
  };
};

export default ActionBuilder
  .for(loadShipmentLockedAssociate)
  .build();