import ActionTypes from '../../../ActionTypes';

const carrierNotes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Carrier.Modification.CarrierNotesLoaded:
    case ActionTypes.Carrier.Modification.CarrierNoteSaved:
      return action.notes;
    case ActionTypes.Carrier.Modification.Disposed:
      return [];
    default:
      return state;
  }
};

export default carrierNotes;