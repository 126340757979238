import Lodash from 'lodash';

/**
 * Selector function to identify if a named process is currently active (running).
 * @param {object} state - The full state tree.
 * @param {string} processName - The name of the process.
 * @param {string} [entryKey] - Optional predicate to additionally inspect the process data.
 */
const isProcessActive = (state, processName, entryKey) => {
  if (entryKey) {
    const process = Lodash.get(state, `sys.process["${processName}"]["${entryKey}"]`);
    return !!process &&
      process.isActive;
  }
  else {
    const process = Lodash.get(state, `sys.process["${processName}"]`);
    return !!process &&
      process.isActive;
  }
};

export default isProcessActive;