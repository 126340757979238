import * as React from "react";

const DisplayError = (props) => {

    let errorMessage = '';

    if (props.error?.isAxiosError) {
        if (props.error.response?.data?.errors) {
            errorMessage += 'Errors:';

            for (const [key, value] of Object.entries(props.error.response?.data?.errors)) {
                errorMessage += (' ' + value);
            }
        } else if (props.error.response?.data?.error) {
            errorMessage = props.error.response?.data?.error;
        } else {
            //worst case
            errorMessage = 'An error occurred.  Please contact IT.';
        }
    } else if (props.error != null || props.error != undefined){
        if (props.error.response?.data?.error) {
            errorMessage = props.error.response?.data?.error;
        } else {
            //worst case
            errorMessage = 'An error occurred.  Please contact IT.';
        }
    }

    return (

      errorMessage !== undefined
      && errorMessage !== null
      && errorMessage !== ''
      && errorMessage !== 'null'
      && errorMessage !== 'undefined'
      &&
      <p style={{color: 'red'}}>
          {`${errorMessage}`}
      </p>
    );

};

export default DisplayError;
