import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadAssociates = async (shell, options) => {

  const { associates } = await shell.gateway.getAllAssociates(options);

  return {
    type: ActionTypes.Admin.CommissionSummary.AssociatesLoaded,
    associates
  };
};

export default ActionBuilder
  .for(loadAssociates)
  .build();