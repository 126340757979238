import Core from '@atomos/core';
import Permissions from './Permissions';

const buildPermissionSetMatrix = (statusType, permissionSet, deciders) => {

  const actionsMatrix = permissionSet.actions
    .reduce((matrix, actionName) => {
      matrix[actionName] = deciders.reduce((currPermission, decider) => {
        return decider(statusType, permissionSet, 'action', actionName, currPermission);
      }, Permissions.None);
      return matrix;
    }, {});

  const fieldsMatrix = permissionSet.fields
    .reduce((matrix, fieldName) => {
      matrix[fieldName] = deciders.reduce((currPermission, decider) => {
        return decider(statusType, permissionSet, 'field', fieldName, currPermission);
      }, Permissions.None);
      return matrix;
    }, {});

  return {
    action: actionsMatrix,
    field: fieldsMatrix
  };

};

const BuildMatrix = (statusTypes, permissionSets, deciders) => {

  const statusPermissionTuples = statusTypes.reduce((list, statusType) => {
    permissionSets.forEach(permSet => list.push([statusType, permSet]));
    return list;
  }, []);

  return statusPermissionTuples.reduce((matrix, tuple) => {
    const [statusType, permSet] = tuple;
    const partialMatrix = buildPermissionSetMatrix(statusType, permSet, deciders);
    Core.Utils.set(matrix, `${statusType}.${permSet.name}`, partialMatrix);
    return matrix;
  }, {});

};

export default BuildMatrix;