import ActionTypes from '../../ActionTypes';

const DefaultCounts = {
  all: 0,
  hold: 0,
  invoiceGenerated: 0,
  paymentsPending: 0,
  customerPaid: 0,
  dispute: 0
};

const trackedCounts = (state = DefaultCounts, action) => {
  switch (action.type) {
    case ActionTypes.Dashboard.Disposed:
      return DefaultCounts;
    case ActionTypes.Dashboard.StatusCountLoaded:
      return action.statusSetCounts;
    default:
      return state;
  }
};

export default trackedCounts;