import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import TgfDropDownList from "../../../../../../core/kendo/form/inputs/TgfDropDownList";
import TgfPasswordInput from "../../../../../../core/kendo/form/inputs/TgfPasswordInput";
import TgfPhoneNumberInput from "../../../../../../core/kendo/form/inputs/TgfPhoneNumberInput";
import TgfTextBox from "../../../../../../core/kendo/form/inputs/TgfTextBox";
import TgfToggleButton from "../../../../../../core/kendo/form/inputs/TgfToggle";
import TgfAssociateComboBox from "../../../../../../core/kendo/form/inputs/specialty/TgfAssociateComboBox";
import TgfAssociateRoleDropdownList
    from "../../../../../../core/kendo/form/inputs/specialty/TgfAssociateRoleDropdownList";
import FieldError from "../../../../../../crm/components/inputs/FieldError";

const DetailsCard = (props) => {

    const handleTextBoxChange = (e) => {
        props.setFormValues({
            ...props.formValues,
            [e.target.element.name]: e.target.value
        });
        props.setIsDirty(true);
    };

    const handleAssociateRoleChange = (e) => {
        props.setFormValues({
            ...props.formValues,
            parentAssociateId: e.target?.value?.name !== "Jack" ? null : props.formValues.parentAssociateId,
            hasCarrierPrivilege: e.target?.value?.name !== "Ace" ? false : props.formValues.hasCarrierPrivilege,
            collectionRole: e.target?.value?.name !== "Ace" ? false : props.formValues.hasCarrierPrivilege,
            displayPayouts: e.target?.value?.name !== "Jack",
            roles: [e.target.value],
        });
        props.setIsDirty(true);
    };

    const handleParentAssociateChange = (e) => {
        props.setFormValues({
            ...props.formValues,
            parentAssociateId: e.target.value ?? null,
        });
        props.setIsDirty(true);
    };

    const handleCheckboxChange = (e) => {
        props.setFormValues({
            ...props.formValues,
            [e.target.name]: e.target.value
        });
        props.setIsDirty(true);
    };

    return (
        <AppMuiCard title={"Details"}>
            <form autoComplete="OFF">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TgfTextBox
                            label={"First Name"}
                            id={"firstName"}
                            name={"firstName"}
                            value={props.formValues.firstName}
                            onChange={handleTextBoxChange}
                            maxLength={50}
                        />
                        <FieldError>{props.fieldErrors.firstName}</FieldError>
                    </Grid>
                    <Grid item xs={6}>
                        <TgfTextBox
                            label={"Last Name"}
                            id={"lastName"}
                            name={"lastName"}
                            value={props.formValues.lastName}
                            onChange={handleTextBoxChange}
                            maxLength={50}
                        />
                        <FieldError>{props.fieldErrors.lastName}</FieldError>
                    </Grid>
                    <Grid item xs={8}>
                        <TgfTextBox
                            label={"Email Address"}
                            id={"emailAddress"}
                            name={"emailAddress"}
                            value={props.formValues.emailAddress}
                            onChange={handleTextBoxChange}
                            maxLength={50}
                        />
                        <FieldError>{props.fieldErrors.emailAddress}</FieldError>
                    </Grid>
                    <Grid item xs={4}>
                        <TgfTextBox
                            label={"CRM ID"}
                            id={"systemId"}
                            name={"systemId"}
                            value={props.formValues.systemId}
                            onChange={handleTextBoxChange}
                            maxLength={10}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfPasswordInput
                            label={"Password"}
                            id={"password"}
                            name={"password"}
                            value={props.formValues.password}
                            onChange={handleTextBoxChange}
                            maxLength={50}
                        />
                        <FieldError>{props.fieldErrors.password}</FieldError>
                    </Grid>
                    <Grid item xs={10}>
                        <TgfPhoneNumberInput
                            label={"Business Phone"}
                            id={"businessPhone"}
                            name={"businessPhone"}
                            value={props.formValues.businessPhone}
                            onChange={handleTextBoxChange}
                        />
                        <FieldError>{props.fieldErrors.businessPhone}</FieldError>
                    </Grid>
                    <Grid item xs={2}>
                        <TgfTextBox
                            label={"EXT"}
                            id={"businessPhoneExt"}
                            name={"businessPhoneExt"}
                            value={props.formValues.businessPhoneExt}
                            onChange={handleTextBoxChange}
                            maxLength={10}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TgfPhoneNumberInput
                            label={"Business Fax"}
                            id={"faxPhone"}
                            name={"faxPhone"}
                            value={props.formValues.faxPhone}
                            onChange={handleTextBoxChange}
                        />
                        <FieldError>{props.fieldErrors.faxPhone}</FieldError>
                    </Grid>
                    <Grid item xs={4}>
                        <TgfAssociateRoleDropdownList
                            label={"Role"}
                            id={"roles"}
                            name={"roles"}
                            value={props.formValues?.roles ? props.formValues.roles[0] : null}
                            onChange={handleAssociateRoleChange}
                        />
                        <FieldError>{props.fieldErrors.roles}</FieldError>
                    </Grid>
                    <Grid item xs={8}>
                        <TgfAssociateComboBox
                            label={"Queen"}
                            id={"parentAssociateId"}
                            name={"parentAssociateId"}
                            value={props.formValues.parentAssociateId}
                            includeRoles={["Queen"]}
                            onChange={handleParentAssociateChange}
                            disabled={(props.formValues.roles ? (props.formValues.roles[0]?.name !== "Jack") : true)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Account Active"}
                            id={"isActive"}
                            name={"isActive"}
                            checked={props.formValues.isActive}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Display Payouts"}
                            id={"displayPayouts"}
                            name={"displayPayouts"}
                            checked={props.formValues.displayPayouts}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Open Links In New Tab"}
                            id={"openLinksInNewTab"}
                            name={"openLinksInNewTab"}
                            checked={props.formValues.openLinksInNewTab}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Carrier Privilege"}
                            id={"hasCarrierPrivilege"}
                            name={"hasCarrierPrivilege"}
                            checked={props.formValues.hasCarrierPrivilege}
                            onChange={handleCheckboxChange}
                            disabled={(props.formValues.roles ? (props.formValues.roles[0]?.name !== "Ace") : true)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Collections Role"}
                            id={"collectionRole"}
                            name={"collectionRole"}
                            checked={props.formValues.collectionRole}
                            onChange={handleCheckboxChange}
                            disabled={(props.formValues.roles ? (props.formValues.roles[0]?.name !== "Ace") : true)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"View Margin Contribution"}
                            id={"canViewMarginContribution"}
                            name={"canViewMarginContribution"}
                            checked={props.formValues.canViewMarginContribution}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Locked Date Control"}
                            id={"canEditBlockedDates"}
                            name={"canEditBlockedDates"}
                            checked={props.formValues.canEditBlockedDates}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Edit AR Automation Block"}
                            id={"canEditBlockedDatesArBilling"}
                            name={"canEditBlockedDatesArBilling"}
                            checked={props.formValues.canEditBlockedDatesArBilling}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"View Accounting Tab"}
                            id={"canViewAccountingTab"}
                            name={"canViewAccountingTab"}
                            checked={props.formValues.canViewAccountingTab}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Edit Customer Billing Tab"}
                            id={"canEditCustomerBilling"}
                            name={"canEditCustomerBilling"}
                            checked={props.formValues.canEditCustomerBilling}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"Collectors Assignment"}
                            id={"canViewCollectionsAssignment"}
                            name={"canViewCollectionsAssignment"}
                            checked={props.formValues.canViewCollectionsAssignment}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TgfToggleButton
                            label={"View Back Office Help Docs"}
                            id={"canViewBackOfficeHelpDocs"}
                            name={"canViewBackOfficeHelpDocs"}
                            checked={props.formValues.canViewBackOfficeHelpDocs}
                            onChange={handleCheckboxChange}
                        />
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>

                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <h2>Rate Confirmation Information</h2>
                        <hr/>
                    </Grid>
                    <Grid item xs={10}>
                        <TgfPhoneNumberInput
                            label={"Business Phone"}
                            id={"rateConBusinessPhone"}
                            name={"rateConBusinessPhone"}
                            value={props.formValues.rateConBusinessPhone}
                            onChange={handleTextBoxChange}
                        />
                        <FieldError>{props.fieldErrors.rateConBusinessPhone}</FieldError>
                    </Grid>
                    <Grid item xs={2}>
                        <TgfTextBox
                            label={"EXT"}
                            id={"rateConBusinessPhoneExt"}
                            name={"rateConBusinessPhoneExt"}
                            value={props.formValues.rateConBusinessPhoneExt}
                            onChange={handleTextBoxChange}
                            maxLength={10}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TgfPhoneNumberInput
                            label={"Business Fax"}
                            id={"rateConFaxPhone"}
                            name={"rateConFaxPhone"}
                            value={props.formValues.rateConFaxPhone}
                            onChange={handleTextBoxChange}
                        />
                        <FieldError>{props.fieldErrors.rateConFaxPhone}</FieldError>
                    </Grid>
                    <Grid item xs={8}>
                        <TgfTextBox
                            label={"Email Address"}
                            id={"rateConEmailAddress"}
                            name={"rateConEmailAddress"}
                            value={props.formValues.rateConEmailAddress}
                            onChange={handleTextBoxChange}
                            maxLength={50}
                        />
                        <FieldError>{props.fieldErrors.rateConEmailAddress}</FieldError>
                    </Grid>
                </Grid>
            </form>
        </AppMuiCard>
    );
};

export default DetailsCard;
