import Lodash from 'lodash';
import washDate from './washDate';

const invoiceWash = invoice => {
  const washedInvoice = Lodash.cloneDeep(invoice);

  washDate(washedInvoice, 'auditDate');
  washDate(washedInvoice, 'invoiceDueDate');
  washDate(washedInvoice, 'auditFinalizedDate');

  return washedInvoice;
};

export default invoiceWash;