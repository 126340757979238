import Core from '@atomos/core';

const SearchTermStorageKey = 'help-search-terms';

let searchTermStore;

class SearchTermStore {

  static instance() {
    if (!searchTermStore) {
      searchTermStore = new SearchTermStore(SearchTermStorageKey);
    }
    return searchTermStore;
  }

  constructor(key, limit = 20) {
    this.key = key;
    this.limit = limit;
  }

  list() {
    const dataText = localStorage.getItem(this.key);
    const data = JSON.parse(dataText);
    return Array.isArray(data) ? data : [];
  }

  add(searchTerm) {
    const searchTerms = this.list();
    const termSet = new Set([searchTerm, ...searchTerms]);
    const newTermList = Core.Utils.take(Array.from(termSet), this.limit);
    const dataText = JSON.stringify(newTermList);
    localStorage.setItem(this.key, dataText);
    return this;
  }

  remove(searchTerm) {
    let newSearchList = [];
    const searchTerms = this.list();

    newSearchList = searchTerms.filter(item => item !== searchTerm);

    const termSet = new Set([...newSearchList]);
    const newTermList = Core.Utils.take(Array.from(termSet), this.limit);
    const dataText = JSON.stringify(newTermList);
    localStorage.setItem(this.key, dataText);
    return this;
  }

  clear() {
    localStorage.setItem(this.key, null);
    return this;
  }

}

export default SearchTermStore;