import ActionTypes from '../../ActionTypes';

const paymentTerms = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Support.PaymentTermsLoaded:
      return action.paymentTerms;
    default:
      return state;
  }
};

export default paymentTerms;