import * as Redux from 'redux';

import shipments from './shipments';
import shipmentCount from './shipmentCount';
import statusSetCounts from './statusSetCounts';

export default Redux.combineReducers({
  shipments,
  shipmentCount,
  statusSetCounts
});