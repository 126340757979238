import React from "react";
import AppDrawer from "../../../../core/components/AppDrawer";
import AppCard from '../../../../core/components/cards/AppCard';
import {Grid} from "@material-ui/core";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Delete} from "@material-ui/icons";
import AppMuiTable from "../../../../core/components/AppDenseGrid";
import AppMuiInput from '../../../../core/components/inputs/AppInput';

const BulkActionDrawer = (props) => {

  const {
    title,
    customer,
    shipments,
    isOpen,
    onBulkActionCancelClick,
    onRemoveShipmentClick,
    actionButtons
  } = props;

  const ControlsCell = ({ rowData: shipment }) => (
    <div style={{textAlign: 'center'}}>
      <AppIconButton Icon={Delete} tooltip={'Remove Shipment'} onClick={() => onRemoveShipmentClick(shipment)}/>
    </div>
  );

  const GridColumns = [
    {
      title: 'Ship #',
      field: 'shipmentBolNumber',
      dataType: 'string',
      styles: {
        width: '40%',
        textAlign: 'center'
      },
      noSort: true
    },
    {
      title: 'Customer Cost',
      field: 'adjustedCustomerCost',
      dataType: 'currency',
      styles: {
        width: '45%',
        textAlign: 'center'
      },
      noSort: true
    },
    {
      title: '',
      field: '',
      noSort: true,
      dataType: 'component',
      styles: {
        width: '15%',
        textAlign: 'center'
      },
      component: ControlsCell
    }
  ];

  return (
    <AppDrawer
      anchor={'right'}
      open={isOpen}
      onClose={onBulkActionCancelClick}>
      <div style={{width: 400, height: '100vh'}}>
        <AppCard title={title}>
          <Grid container spacing={1} justify="flex-end" alignItems="center">
            <Grid item xs={12}>
              <AppMuiInput
                readOnly
                disabled
                label={'Customer'}
                value={customer?.companyName || ''} />
            </Grid>

            <Grid item xs={12}>
              <AppMuiTable
                title=""
                count={1}
                data={shipments || []}
                columns={GridColumns}
                orderBy={''}
                order={'asc'}
                rowsPerPage={20}
                page={0 / 20}
              />
            </Grid>

          </Grid>
          <Grid container style={{marginTop: 25}}>
            {actionButtons}
          </Grid>
        </AppCard>
      </div>
    </AppDrawer>
  )

}

export default BulkActionDrawer;