import React from 'react';
import FullWidthLayout from "../../core/layouts/FullWidthLayout";
import {Grid} from "@material-ui/core";
import TgfDataGrid from "../../core/kendo/dataGrid/TgfDataGrid";
import TgfLabeledInput from "../../core/kendo/inputs/TgfLabeledInput";
import DebouncedTextBox from "../../core/kendo/inputs/DebouncedTextBox";
import AppNumberText from "../../core/components/text/AppNumberText";
import {useLocation} from "react-router-dom";

const PaymentsPage = (props) => {
    const ref = React.useRef();

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const initialFilter = query.get('filter') ? `=${query.get('filter')}` : '';
    console.log(initialFilter);

    const [rowCount, setRowCount] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState(initialFilter);

    React.useEffect(() => {
        ref.current.refresh();
    }, [searchTerm]);

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 50,
            skip: 0
        };

        if(searchTerm != null && searchTerm.length > 0) {
            if(searchTerm.startsWith('=')) {
                odata.filter = {
                    odata: searchTerm.substring(1)
                };
            }
            else {
                odata.filter.or = {
                    paymentId: {eq: searchTerm},
                    paymentAcumaticaUid: {eq: searchTerm},
                    paymentAcumaticaRef: {substringof: searchTerm},
                    customerId: {eq: searchTerm},
                    customerName: {substringof: searchTerm},
                    customerAcumaticaRef: {substringof: searchTerm},
                    applicationDate: {eq: searchTerm},
                    cardAccountNum: {eq: searchTerm},
                    cashAccount: {eq: searchTerm},
                    description: {substringof: searchTerm},
                    hold: {eq: searchTerm},
                    amount: {eq: searchTerm},
                    currency: {eq: searchTerm},
                    paymentMethod: {eq: searchTerm},
                    status: {eq: searchTerm},
                    type: {eq: searchTerm},
                    createdDateTime: {eq: searchTerm},
                    lastModifiedDateTime: {eq: searchTerm},
                    acumaticaLastModifiedDateTime: {eq: searchTerm},
                    acumaticaLastSyncDateTime: {eq: searchTerm}
                };
            }
        }

        return odata;
    }

    const loadData = async (odata) => {
        let explicitFilter = null;
        if(typeof odata.filter.odata ) {
            explicitFilter = odata.filter.odata
        }

        const dataPage = await window.shell.gateway.getPayments(odata, explicitFilter);
        setRowCount(dataPage.totalItemCount);
        setPageCount(dataPage.totalPageCount);
        return dataPage;
    }

    const GridColumns = [
        {field: "paymentId", title: "Payment ID", type: "link", url:(dataItem) => `/receivables/payment-applications?filter=paymentId eq '${dataItem.paymentId}'`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentAcumaticaUid", title: "Acumatica ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentAcumaticaRef", title: "Acumatica Ref", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerId", title: "Customer ID", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerName", title: "Customer", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerAcumaticaRef", title: "Cuatomer Acumatica Ref", type: "link", url:(dataItem) => `/address-book/${dataItem.customerId}/details`, className: "show-all-text", headerClassName: "show-all-text"},
        {field: "applicationDate", title: "Application Date", type: "dateFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "cardAccountNum", title: "Card Account Num", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "cashAccount", title: "Cash Account", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "description", title: "Description", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "hold", title: "Hold", type: "boolean" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "amount", title: "Amount", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "currency", title: "Currency", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentMethod", title: "Payment Method", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentRef", title: "Payment Ref", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "status", title: "Status", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "type", title: "Type", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "createdDateTime", title: "CRM Created DateTime", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "lastModifiedDateTime", title: "CRM Modified DateTime", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaLastModifiedDateTime", title: "Acumatica Modified DateTime", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "acumaticaLastSyncDateTime", title: "Acumatica Modified DateTime", type: "dateTimeFromUTCDateTime" , className: "show-all-text", headerClassName: "show-all-text"}
    ];

    const included = [
        "paymentId",
        // "paymentAcumaticaUid",
        "paymentAcumaticaRef",
        //"customerId",
        "customerName",
        "customerAcumaticaRef",
        "applicationDate",
        "cardAccountNum",
        "cashAccount",
        "description",
        "hold",
        "amount",
        "currency",
        "paymentMethod",
        "paymentRef",
        "status",
        "type",
        // "createdDateTime",
        // "lastModifiedDateTime",
        // "acumaticaLastModifiedDateTime",
        // "acumaticaLastSyncDateTime"
    ];

    return (
        <FullWidthLayout title="Payments">
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfLabeledInput
                        label={"Search"}
                        inputComponent={<DebouncedTextBox
                            id={"searchTerm"}
                            value={searchTerm}
                            onChange={handleSearchTerm}
                        />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid item xs={12}>
                    <TgfDataGrid
                        className={"tgf-data-grid"}
                        ref={ref}
                        onLoadDataPage={loadData}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                        onDataPageLoaded={props.onDataChanged}
                        gridColumns={GridColumns.filter(c => included.some(i => i === c.field))}
                        sort={[{field: "applicationDate", dir: "desc"}]}
                        pageable={{
                            pageSize: 20,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500]
                        }}
                    />
                    <div style={{ padding: 10, backgroundColor: '#74747422' }}>
                        <span style={{float: 'left'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={rowCount} /> Rows
                        </span>
                        <span style={{float: 'right'}}>
                            <AppNumberText minFractionDigits={0} maxFractionDigits={0} value={pageCount} /> Pages
                        </span>
                        <span style={{clear: 'both'}}>&nbsp;</span>
                    </div>
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
};
export default PaymentsPage