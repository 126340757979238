export default (function () {
    function dummy() { }

    const disable = {
        consoleAll: process.env.REACT_APP_DISABLE_CONSOLE_ALL?.toLowerCase() || 'false',
        reduxLogging: process.env.REACT_APP_DISABLE_REDUX_LOGGING?.toLowerCase() || 'false',
    };

    // ideally, we would override the console prototype and replace
    // the relevant functions however as this is a quick-fix hack to begin with
    // none of this will be necessary in v2.0.0
    if (disable.consoleAll === 'true') {

        // leaves ourselves an avenue to be able to bypass the override
        // however probably should not be well-known as well as good discipline
        // must be used to not allow any commits utilizing this.  Maybe a custom eslint rule later...
        console.backdoor = {};

        // this is intentionally an incomplete list - and maybe not even all these are desirable to override.
        // but it's a good place to begin, if we're using hacks already ^_^
        console.backdoor.log = console.log;
        console.log = dummy;

        console.backdoor.warn = console.warn;
        console.warn = dummy;

        console.backdoor.error = console.error;
        console.error = dummy;

        console.backdoor.clear = console.clear;
        console.clear = dummy;

        console.backdoor.debug = console.debug;
        console.debug = dummy;


        // if redux logging is enabled, we need the following to be left alone, otherwise, go ahead and reassign
        if (disable.reduxLogging === 'true') {
            console.backdoor.info = console.info;
            console.info = dummy;

            console.backdoor.group = console.group;
            console.group = dummy;

            console.backdoor.groupCollapsed = console.groupCollapsed;
            console.groupCollapsed = dummy;
        }
    }
})();