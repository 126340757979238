import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveAssociate = async (shell, associate) => {
  const updatedAssociate = await shell.gateway.saveAssociate(associate);

  const parentAssociate = updatedAssociate.parentAssociateId ?
    await shell.gateway.getAssociate(updatedAssociate.parentAssociateId)
    : null;
  
  return {
    type: ActionTypes.Admin.ManageUsers.AssociateSaved,
    associate: updatedAssociate,
    parentAssociate
  };
};

export default ActionBuilder
  .for(saveAssociate)
  .build();