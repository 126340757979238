import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from '../../../ActionTypes';

const disposeHistory = (context) => {
  return {
    type: ActionTypes.Carrier.Modification.CarrierHistory.Disposed
  };
};

export default ActionBuilder
  .for(disposeHistory)
  .build();