import React from 'react';

const FormFactorContext = React.createContext({
  values: {},
  touched: {},
  errors: {},
  isSubmitting: false,
  isValid: false,
  isDirty: false
});

export default FormFactorContext;