import Business from '@tgf-crm/business';

const ShipmentConstraintName = 'Shipment Rules';
const CompanyConstraintName = 'Company Rules';
const CarrierConstraintName = 'Carrier Rules';
const MultiStopConstraintName = 'Multi-Stop Rules';

const computeConstraints = (props) => {

  const {
    shipmentStatusTypes,
    shipmentFreightCategoryTypes,
    shipment,
    customer,
    customerContacts,
    shipper,
    shipperContacts,
    consignee,
    consigneeContacts,
    carrierContact,
    truckloadValueConfirmation,
    loadContents,
    multiStopLocations,
    multiStopCompanyContacts
  } = props;

  const shipmentAssertions = Business.Shipment.ShipmentAssertions(shipmentStatusTypes, shipmentFreightCategoryTypes);
  const isTlShipment = shipmentAssertions.isTruckload.matches(shipment);

  const timeConstraints = computeTimeConstraints(shipment, shipmentAssertions);
  const primaryCompanyConstraints = computePrimaryCompanyConstraints(customer, customerContacts, shipper, shipperContacts, consignee, consigneeContacts);
  const carrierConstraints = computeCarrierConstraints(shipment, carrierContact, shipmentAssertions);
  const thirdPartyConstraint = computeThirdPartyConstraint(shipment);
  const tlValueConfirmationConstraint = computeTlValueConfirmationConstraint(isTlShipment, truckloadValueConfirmation);
  const loadContentsConstraint = computeLoadContentsConstraint(loadContents);
  const multiStopConstraints = computeMultiStopLocationConstraints(multiStopLocations, multiStopCompanyContacts);

  const allConstraints = [
    ...timeConstraints,
    ...primaryCompanyConstraints,
    ...carrierConstraints,
    thirdPartyConstraint,
    ...tlValueConfirmationConstraint,
    loadContentsConstraint,
    ...multiStopConstraints
  ];

  const constraintGroups = allConstraints
    .reduce((groups, constraint) => {
      const constraints = groups.get(constraint.category) || [];
      constraints.push(constraint);
      groups.set(constraint.category, constraints);
      return groups;
    }, new Map());
  return [
    Array.from(constraintGroups),
    isTlShipment
  ];
};

const computeTimeConstraints = (shipment, shipmentAssertions) => {

  const pickupTimeConstraint = {
    title: 'Shipment has a pickup time.',
    passed: Boolean(shipment.pickupTime),
    category: ShipmentConstraintName
  };

  const needsDeliveryDateCheck = shipmentAssertions.isTruckload.matches(shipment) &&
    shipment.equipmentType !== Business.Shipment.EquipmentTypeNames.TlEquipmentTypeNames.IntermodalRail;

  const deliveryDateConstraint = needsDeliveryDateCheck ?
    {
      title: 'Shipment has a delivery date.',
      passed: Boolean(shipment.estimatedDeliveryDate),
      category: ShipmentConstraintName
    } :
    null;

  const deliveryTimeConstraint = {
    title: 'Shipment has a delivery time.',
    passed: Boolean(shipment.deliveryTime),
    category: ShipmentConstraintName
  };

  return [
    pickupTimeConstraint,
    deliveryDateConstraint,
    deliveryTimeConstraint
  ].filter(x => x);

};

const computePrimaryCompanyConstraints = (customer, customerContacts, shipper, shipperContacts, consignee, consigneeContacts) => {

  return [
    {
      title: 'Customer is active.',
      passed: isActive(customer),
      category: CompanyConstraintName
    },
    {
      title: 'Customer is not disabled.',
      passed: isNotDisabled(customer),
      category: CompanyConstraintName
    },
    {
      title: 'Customer has a primary contact.',
      passed: hasPrimaryContact(customerContacts),
      category: CompanyConstraintName
    },
    {
      title: 'Shipper is active.',
      passed: isActive(shipper),
      category: CompanyConstraintName
    },
    {
      title: 'Shipper has a primary contact.',
      passed: hasPrimaryContact(shipperContacts),
      category: CompanyConstraintName
    },
    {
      title: 'Consignee is active.',
      passed: isActive(consignee),
      category: CompanyConstraintName
    },
    {
      title: 'Consignee has a primary contact.',
      passed: hasPrimaryContact(consigneeContacts),
      category: CompanyConstraintName
    }
  ];

};

const computeCarrierConstraints = (shipment, carrierContact, shipmentAssertions) => {

  const openCarriers = Array.from(Object.values(Business.Carrier.OpenCarriers));

  const carrierAssignedConstraint = {
    title: 'Shipment has a carrier.',
    passed: !openCarriers
      .some(openCarrier => shipment.carrierMcNumber === openCarrier.McNumber),
    category: CarrierConstraintName
  };

  const isTruckload = shipmentAssertions.isTruckload.matches(shipment);

  const carrierRepConstraint = isTruckload ?
    {
      title: 'Carrier rep is assigned.',
      passed: Boolean(carrierContact?.repName),
      category: CarrierConstraintName
    } :
    null;

  const carrierRepPhoneConstraint = isTruckload ?
    {
      title: 'Carrier rep phone is assigned.',
      passed: Boolean(carrierContact?.repPhone),
      category: CarrierConstraintName
    } :
    null;

  const carrierRepEmailConstraint = isTruckload ?
    {
      title: 'Carrier rep email is assigned.',
      passed: Boolean(carrierContact?.repEmail),
      category: CarrierConstraintName
    } :
    null;

  return [
    carrierAssignedConstraint,
    carrierRepConstraint,
    carrierRepPhoneConstraint,
    carrierRepEmailConstraint
  ].filter(x => x);
};

const computeThirdPartyConstraint = (shipment) => {
  return {
    title: 'Shipment has a third-party.',
    passed: Boolean(shipment.thirdPartyId),
    category: CompanyConstraintName
  };
};

const computeTlValueConfirmationConstraint = (isTlShipment, tlValueConfirmation) => {
  return [
    isTlShipment ?
      {
        title: 'Truckload value confirmed.',
        passed: Boolean(tlValueConfirmation),
        category: ShipmentConstraintName
      } :
      null
  ].filter(x => x);
};

const computeLoadContentsConstraint = (loadContents) => {
  return {
    title: 'Load contents has been completed.',
    passed: loadContents?.length > 0,
    category: ShipmentConstraintName
  };
};

const computeMultiStopLocationConstraints = (multiStopLocations, multiStopCompanyContacts) => {

  const locationConstraints = multiStopLocations
    .map((location, index) => ({
      title: `Stop #${index + 1} has location selected.`,
      passed: Boolean(location.companyId),
      category: MultiStopConstraintName
    }));

  const locationCompanyConstraints = multiStopCompanyContacts
    .map((companyContacts, index) => ({
      title: `Stop #${index + 1} company has a primary contact.`,
      passed: companyContacts.some(contact => contact.isPrimary),
      category: MultiStopConstraintName
    }));

  return locationConstraints
    .concat(locationCompanyConstraints);
};

const isActive = (company) =>
  company?.isInactive === false;

const isNotDisabled = (company) =>
  company?.isDisabled === false;

const hasPrimaryContact = (contacts) =>
  contacts?.length > 0 &&
  contacts.some(c => c.isPrimary);

export default computeConstraints;