import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const deletePendingCarrier = async (shell, mcNumber) => {

  const  pendingCarrier = await shell.gateway.deletePendingCarrier(mcNumber);

  return {
    type: ActionTypes.Carrier.Pending.Deleted,
    pendingCarrier
  }
};

export default ActionBuilder
  .for(deletePendingCarrier)
  .build();
