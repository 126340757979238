const FinancialActions = [];
const FinancialFields = [
  'carrierCost',
  'customerCost',
  'adjustedCarrierCost',
  'adjustedCustomerCost',
  'detentionAmount',
  'layoverAmount',
  'reweighAdjustmentAmount',
  'reclassAdjustmentAmount',
  'carrierLumpersAmount',
  'carrierOtherAmountLabel',
  'carrierOtherAmount',
  'customerDetentionAmount',
  'customerLayoverAmount',
  'customerReweighAmount',
  'customerReclassAmount',
  'customerLumpersAmount',
  'customerOtherAmountLabel',
  'customerOtherAmount',
  'signedForBy',
  'actualDeliveryDate',
  'customerWasPaid',
  'carrierDeductedCost1Label',
  'carrierDeductedCost1',
  'carrierDeductedCost2Label',
  'carrierDeductedCost2',
  'customerDeductedCost1Label',
  'customerDeductedCost1',
  'customerDeductedCost2Label',
  'customerDeductedCost2',
  'invoiceNote',
  'financialNote'
];

const PermissionSet = {
  name: 'financial',
  actions: FinancialActions,
  fields: FinancialFields
};

export default PermissionSet;