import ActionTypes from '../../../ActionTypes';

const shipmentInvoiceAuditNotes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Modification.Disposed:
      return [];
    case ActionTypes.Shipment.Modification.ShipmentInvoiceAuditNotesLoaded:
    case ActionTypes.Shipment.Modification.ShipmentInvoiceAuditNoteSaved:
      return action.invoiceAuditNotes;
    default:
      return state;
  }
};

export default shipmentInvoiceAuditNotes;