import React from 'react';
import FullWidthLayout from "../../core/layouts/FullWidthLayout";
import {Grid} from "@material-ui/core";
import TgfDataGrid from "../../core/kendo/dataGrid/TgfDataGrid";

const ARShipmentFinancials = (props) => {
    const invoiceAgingRef = React.useRef();
    const invoiceMemosRef = React.useRef();
    const paymentsRef = React.useRef();
    const lineItemsRef = React.useRef();
    const bolNumber = parseInt(props.match.params.id);

    const [data, setData] = React.useState(null);
    const [invoiceMemoVisible, setInvoiceMemoVisible] = React.useState(true);
    const [lineItemVisible, setLineItemVisible] = React.useState(true);

    React.useEffect(() => {
        async function fetch() {
            const shipmentFinancials = await window.shell.gateway.getShipmentReceivables(bolNumber);
            setData(shipmentFinancials);
        }
        fetch();
    }, []);

    React.useEffect(() => {
        if(data) {
            invoiceAgingRef.current.refresh();
            invoiceMemosRef.current.refresh();
            paymentsRef.current.refresh();
            lineItemsRef.current.refresh();
        }
    }, [data]);

    const loadInvoiceData = (odata) => {
        const dataPage = {
            totalItemCount: 1,
            totalPageCount: 0,
            pageIndex:0,
            pageSize: odata.take,
            items: [data.invoiceAging]
        }
        return dataPage;
    }

    const loadInvoiceMemoData = (odata) => {
        const dataPage = {
            totalItemCount: data.invoiceMemos.length,
            totalPageCount: 0,
            pageIndex:0,
            pageSize: odata.take,
            items: data.invoiceMemos
        }
        return dataPage;
    }

    const loadPaymentData = (odata) => {
        const dataPage = {
            totalItemCount: data.payments.length,
            totalPageCount: 0,
            pageIndex:0,
            pageSize: odata.take,
            items: data.payments
        }
        return dataPage;
    }

    const loadLineItemData = (odata) => {
        const dataPage = {
            totalItemCount: data.lineItems.length,
            totalPageCount: 0,
            pageIndex:0,
            pageSize: odata.take,
            items: data.lineItems
        }
        return dataPage;
    }

    const toggleInvoiceMemo = () => {
        setInvoiceMemoVisible(!invoiceMemoVisible);
    }

    const toggleLineItem = () => {
        setLineItemVisible(!lineItemVisible);
    }

    const invoiceAgingGridColumns = [
        {field: "sentDate", title: "Send Date", type: "dateFromUTCDateTime", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "dueDate", title: "Due Date", type: "dateFromUTCDateTime", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "pastDue", title: "Past Due", type: "number", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paidOffDate", title: "Paid Off Date", type: "date", className: "show-all-text", headerClassName: "show-all-text"},
    ];

    const invoiceMemoGridColumns = [
        {field: "applicationDateTime", title: "Date", type: "date" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "postPeriod", title: "Post Period", type: "number" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "amount", title: "Amount", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "type", title: "Type", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerOrder", title: "Customer Order", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "dueDate", title: "Due Date", type: "date" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerName", title: "Customer Name", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "description", title: "Description", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
    ];

    const paymentGridColumns = [
        {field: "applicationDate", title: "Pay Date", type: "date" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentType", title: "Pay Type", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "amountPaidOnInvoice", title: "Pay Amount", type: "currency" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentMethod", title: "Pay Method", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "paymentRef", title: "Pay Reference", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "createdDateTime", title: "Logged Date", type: "date" , className: "show-all-text", headerClassName: "show-all-text"},
    ];

    const lineItemGridColumns = [
        {field: "invoiceDate", title: "Date", type: "date" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoicePostPeriod", title: "Post Period", type: "number" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "extendedPrice", title: "Extended Price", type: "currency", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceType", title: "Type", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "inventoryId", title: "Inventory ID", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceCustomerOrder", title: "Customer Order", type: "text", className: "show-all-text", headerClassName: "show-all-text"},
        {field: "invoiceDueDate", title: "Due Date", type: "date" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "customerName", title: "Customer Name", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
        {field: "description", title: "Description", type: "text" , className: "show-all-text", headerClassName: "show-all-text"},
    ];

    return (
        <FullWidthLayout title={`Shipments - Accounting Receivables - ${bolNumber}`}>
            <Grid container spacing={1} direction="column" justifyContent="flex-start">
                <Grid container spacing={1} direction="row" alignItems={"center"}>
                    <h1>Accounts Receivable</h1>
                </Grid>
                <Grid container spacing={1} direction="row" alignItems={"center"}>
                    <Grid item xs={3}>
                        <h2>{`Original Invoice Amount: $${data?.initialInvoiceTotal}`}</h2>
                    </Grid>
                    <Grid item xs={3}>
                        <h2>{`Adjusted Invoice Total: $${data?.adjustedInvoiceTotal}`}</h2>
                    </Grid>
                    <Grid item xs={3}>
                        <h2>{`Amount Remaining: $${data?.paymentsRemaining}`}</h2>
                    </Grid>
                </Grid>
            </Grid>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <Grid container spacing={1} direction="row" alignItems={"center"}>
                <Grid item xs={12}>
                    <h2>Invoice Aging Information</h2>
                    <TgfDataGrid
                        ref={invoiceAgingRef}
                        className={"tgf-data-grid"}
                        onLoadDataPage={loadInvoiceData}
                        gridColumns={invoiceAgingGridColumns}
                        sort={[{field: "sentDate", dir: "asc"}]}
                        pageable={false}
                        sortable={false}
                    />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" alignItems={"center"}>
                <Grid item xs={12}>
                    <h2>Payment History</h2>
                    <TgfDataGrid
                        ref={paymentsRef}
                        className={"tgf-data-grid"}
                        onLoadDataPage={loadPaymentData}
                        gridColumns={paymentGridColumns}
                        sort={[{field: "applicationDate", dir: "asc"}]}
                        sortable={false}
                        pageable={false}
                    />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" alignItems={"center"}>
                <Grid item xs={12}>
                    <h2 onClick={toggleInvoiceMemo}>Invoices and Memos</h2>
                    <TgfDataGrid
                        ref={invoiceMemosRef}
                        className={`tgf-data-grid ${!invoiceMemoVisible ? 'hidden' : ''}`}
                        onLoadDataPage={loadInvoiceMemoData}
                        gridColumns={invoiceMemoGridColumns}
                        sort={[{field: "billingSentDateTime", dir: "asc"}]}
                        sortable={false}
                        pageable={false}
                    />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" alignItems={"center"}>
                <Grid item xs={12}>
                    <h2 onClick={toggleLineItem}>Invoices and Memos by Line Item</h2>
                    <TgfDataGrid
                        ref={lineItemsRef}
                        className={`tgf-data-grid ${!lineItemVisible ? 'hidden' : ''}`}
                        onLoadDataPage={loadLineItemData}
                        gridColumns={lineItemGridColumns}
                        sort={[{field: "invoiceDate", dir: "asc"}]}
                        sortable={false}
                        pageable={false}
                    />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
}
export default ARShipmentFinancials;