import ActionTypes from "../../../ActionTypes";

const consigneeContacts = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.CreateDocs.Disposed:
      return [];
    case ActionTypes.Shipment.CreateDocs.ShipmentLoaded:
      return action.consigneeContacts;
    default:
      return state;
  }
};

export default consigneeContacts;