import React from 'react';
import {Grid} from "@material-ui/core";
import ComponentBuilder from "../../../../core/ComponentBuilder";

import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiCard from "../../../../core/components/cards/AppCard";
import AppNumericInput from "../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";

import '../financialPage.scss';
import SecureField from "../../permissions/SecureField";

const DeductedCarrierCosts = (props) => {

  const {
    isAce,
    carrierDeductedCost1Label,
    carrierDeductedCost1,
    carrierDeductedCost2Label,
    carrierDeductedCost2,
    carrierDeductedCost1LabelError,
    carrierDeductedCost1Error,
    carrierDeductedCost2LabelError,
    carrierDeductedCost2Error,
    setFieldValue
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(`invoice.${key}`, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <AppMuiCard title={'Deducted Carrier Costs'}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierDeductedCost1Label'}
                as={AppInput}
                id={'carrierDeductedCost1Description'}
                disabled={!isAce}
                value={carrierDeductedCost1Label || ''}
                inputProps={{
                  maxLength: 30
                }}
                onChange={handleFieldChange('carrierDeductedCost1Label')}
              />
              <AppValidationFailure message={carrierDeductedCost1LabelError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierDeductedCost1'}
                as={AppNumericInput}
                id={'carrierDeductedCost1Value'}
                disabled={!isAce}
                decimalScale={2}
                type={'text'}
                value={carrierDeductedCost1}
                onChange={handleFieldChange('carrierDeductedCost1')}
              />
              <AppValidationFailure message={carrierDeductedCost1Error}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierDeductedCost2Label'}
                as={AppInput}
                id={'carrierDeductedCost2Description'}
                disabled={!isAce}
                inputProps={{
                  maxLength: 30
                }}
                value={carrierDeductedCost2Label || ''}
                onChange={handleFieldChange('carrierDeductedCost2Label')}
              />
              <AppValidationFailure message={carrierDeductedCost2LabelError}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <SecureField
                pageName={'financial'}
                fieldName={'carrierDeductedCost2'}
                as={AppNumericInput}
                id={'carrierDeductedCost2Value'}
                disabled={!isAce}
                decimalScale={2}
                type={'text'}
                value={carrierDeductedCost2}
                onChange={handleFieldChange('carrierDeductedCost2')}
              />
              <AppValidationFailure message={carrierDeductedCost2Error}/>
            </Grid>
          </Grid>
        </AppMuiCard>
      </Grid>
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  const isEqual = prevProps.carrierCost === nextProps.carrierCost &&
    prevProps.carrierDeductedCost1Label === nextProps.carrierDeductedCost1Label &&
    prevProps.carrierDeductedCost1 === nextProps.carrierDeductedCost1 &&
    prevProps.carrierDeductedCost2Label === nextProps.carrierDeductedCost2Label &&
    prevProps.carrierDeductedCost2 === nextProps.carrierDeductedCost2 &&
    prevProps.carrierDeductedCost1LabelError === nextProps.carrierDeductedCost1LabelError &&
    prevProps.carrierDeductedCost1Error === nextProps.carrierDeductedCost1Error &&
    prevProps.carrierDeductedCost2LabelError === nextProps.carrierDeductedCost2LabelError &&
    prevProps.carrierDeductedCost2Error === nextProps.carrierDeductedCost2Error;

  return isEqual;
};

export default React.memo(ComponentBuilder
    .wrap(DeductedCarrierCosts)
    .build(),
  propsAreEqual
);