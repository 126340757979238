import React from 'react';
import {Grid} from '@material-ui/core';
import AppCard from '../../../core/components/cards/AppCard/AppMuiCard';
import {Typography} from '@material-ui/core';
import AppIntegerText from '../../../core/components/text/AppIntegerText';

const CarrierSummaryCard = (props) => {

  const {
    name,
    mcNumber,
    dotNumber,
    taxIdNumber,
    totalPowerUnits,
    tgfShipmentCount,
    lastShipmentDate
  } = props;

  return (
    <AppCard>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Typography variant={'h4'}>
            {name}
          </Typography>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={3}>
              <label>MC #</label>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant={'body1'}>
                {mcNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={3}>
              <label>DOT #</label>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant={'body1'}>
                {dotNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={3}>
              <label>Tax ID #</label>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant={'body1'}>
                {taxIdNumber}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <label>Total Power Units</label>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'body1'}>
                <AppIntegerText value={totalPowerUnits} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <label>TGF Shipment Count</label>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'body1'}>
                <AppIntegerText value={tgfShipmentCount} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <label>Last Shipment Date</label>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'body1'}>
                {lastShipmentDate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default CarrierSummaryCard;