import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveCompanyAutofillFields = async (shell, companyId, autofillData) => {

    const autofillFields = await shell.gateway.saveCompanyAutofillFields(companyId, autofillData);

    return {
        type: ActionTypes.AddressBook.Modification.CompanyAutofillFieldsSaved,
        autofillFields
    };

};

export default ActionBuilder
    .for(saveCompanyAutofillFields)
    .build();