
// rules for non aces
const permissions = {
    canAddNewShipmentAdj: ({userIsAce, shipment, entryType, disableEdit = false, maxCount, data}) => {
        if(data.length >= maxCount) return false;
        if(disableEdit) return false;
        return userIsAce ||
            ([1, 6, 17, 19].includes(shipment?.statusId) &&
            entryType.subType !== "reduction");
    },

    canEditDeleteShipmentAdj: (userIsAce, shipmentStatusId, payeeType, subType, disabled = false) => {
        if(disabled) return false;
        return userIsAce || payeeType !== "Miscellaneous" &&
            payeeType !== "Shipper/Consignee" &&
            [1, 6, 17, 19].includes(shipmentStatusId) &&
            subType !== "reduction";
    },
};

export default permissions;