import React, {useEffect, useState} from "react";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import LeftNav from "../LeftNav";
import {Grid} from "@material-ui/core";
import BadDebtGrid from "./includes/BadDebtGrid";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../core/hooks/usQuery";
import CrmAssociateDropDown from "../../../crm/components/CrmAssociateDropDown";
import AppInput from "../../../core/components/inputs/AppInput/AppMuiInput";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const BadDebtReport = () => {
    const gridRef = React.useRef();
    const history = useHistory();
    const query = useQuery();
    const globalState = useSelector(state => state);
    const associate = globalState.persona.associate;
    const associateRole = associate.roles[0].name;
    usePageTitle("TGF: Bad Debt Report");

    const [filterAgentId, setFilterAgentId] = useState(null);
    const [filterCustomerName, setFilterCustomerName] = useState(null);

    const handleFilterAgentIdChange = async (e) => {
        const value = e?.id ?? "";
        await setFilterAgentId(value);
        await reload();
    }

    const handleFilterCustomerNameChange = async (e) => {
        const value = e?.target?.value ?? "";
        await setFilterCustomerName(value);
        await reload();
    }

    const reload = async () => {
        gridRef.current.refresh()
    };

    useEffect(() => {
        reload();
    }, []);

    return (
        <FullWidthLayout SideNav={LeftNav} title="Bad Debt Report">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} >
                            <label htmlFor="customerName">Customer Name</label>
                            <AppInput id="customerName"
                                      name="customerName"
                                      value={filterCustomerName}
                                      onChange={handleFilterCustomerNameChange}
                                      tabIndex={1}
                                      className="form-control"
                                      maxLength="50" />
                        </Grid>
                        <Grid item xs={3} >
                            <label htmlFor="agent">Agent</label>
                            <CrmAssociateDropDown
                                id={'agent'}
                                onChangeAssociate={handleFilterAgentIdChange}
                                autoSelectFirst={false}
                                includeInactive={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <BadDebtGrid ref={gridRef} filters={{filterAgentId, filterCustomerName}} reload={reload} />
                </Grid>
            </Grid>
        </FullWidthLayout>
    )
}

export default BadDebtReport;
