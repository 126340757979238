import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { List, ListItemIcon, ListItem, ListItemText } from '@material-ui/core';
import { ImportContacts, Lock } from '@material-ui/icons';
import AppMenuButton from '../../../core/components/AppButton/AppMenuButton';

const useStyles = makeStyles(theme => ({
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.tgfGrey.light
    }
  },
  listDate: {
    textAlign: 'right'
  },
  helpFileDescription: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: theme.palette.tgfBlack.main
  }
}));

const HelpList = (props) => {
  const classes = useStyles();
  const {
    documents,
    options,
    permissions
  } = props;

  return (
    <>
      <List className={classes.listRoot}>
        {
          documents.map((document, index) =>
            <HelpDoc key={index} document={document} options={options} classes={classes} permissions={permissions}/>
          )
        }
      </List>
    </>
  )
};

const HelpDoc = ({ document, classes, options, permissions }) => {

  const uploadDateText = document.uploadDate ?
    `updated: ${document.uploadDate.toMoment().format('lll')}` :
    null;
  const filenameText = `filename: ${document.filename}`;
  const secondaryText = makeMultiLine(filenameText, uploadDateText);

  return (
    <ListItem
      className={classes.listItem}
      divider
    >
      <ListItemIcon>{document?.permBackOfficeOnly ? <Lock /> : <ImportContacts />}</ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.helpFileDescription
        }}
        primary={document.description}
        secondary={secondaryText}
        secondaryTypographyProps={{ component: 'div' }}
      />
      <ListItemIcon><AppMenuButton options={options} document={document} /></ListItemIcon>
    </ListItem>
  );
}

const makeMultiLine = (value1, value2) => (
  <div>
    { value1 && <div>{value1}</div> }
    { value2 && <div>{value2}</div> }
  </div>
);

export default HelpList;