import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import RoleNames from "@tgf-crm/business/src/associate/RoleNames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const TgfAssociateComboBox = (props) => {
    const personalDispatch = useDispatch();
    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [value, setValue] = useState(null);

    const loadAllData = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAssociate'));
            let roleIds = [];
            if (props.includeRoles) {
                if (props.includeRoles.includes(RoleNames.Ace)) roleIds.push(6);
                if (props.includeRoles.includes(RoleNames.Queen)) roleIds.push(8);
                if (props.includeRoles.includes(RoleNames.Jack)) roleIds.push(9);
            } else {
                roleIds.push(8); // queens only if none passed.
            }


            const options = {
                includeInactive: props.includeInactive ?? false,
                roleId: roleIds,
                sort: [['lastName', 'asc']]
            };

            let associates = await window.shell.gateway.searchAllAssociates(options);
            associates = associates.map((a) => {
                return { id: a.id, display: a.fullName };
            });
            setAllData(associates);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveAssociate'));
        }
    };

    useEffect(() => {
        loadAllData();
    }, []);

    useEffect(() => {
        setValue(allData.find(x => x.id === props.value) ?? null);
    }, [allData, props.value]);

    const filterData = filter => {
        const data = allData.slice();
        return filterBy(data, filter);
    };
    const filterChange = event => {
        setFilteredData(filterData(event.filter));
    };

    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);

    const handleChange = (e) => {
        setValue(e.target.value);
        // outside of this component, we only input/output associateId.
        props.onChange({ target: { value: e.target.value?.id ?? null } });
    };
    return (
        <div>
            {props.label && <Label editorId={props.id}>{props.label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox"}
                data={filteredData}
                dataItemKey={"id"}
                textField="display"
                filterable={true}
                onFilterChange={filterChange}
                onChange={handleChange}
                value={value}
                placeholder="Search Agents..."
                label={null}
            />
        </div>
    );
};

export default TgfAssociateComboBox;
