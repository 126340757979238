import ActionTypes from "../../../ActionTypes";

const qaFailed = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Automation.RetrieveQaFailed.Loaded:
      return action.qaFailed;
    default:
      return state;
  }
};

export default qaFailed;