import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadShipmentInvoiceAuditNotes = async (shell, bolNumber) => {

  const invoiceAuditNotes = await shell.gateway
    .getShipmentInvoiceAuditNotes(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentInvoiceAuditNotesLoaded,
    invoiceAuditNotes
  };
};

export default ActionBuilder
  .for(loadShipmentInvoiceAuditNotes)
  .build();