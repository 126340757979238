import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder2';

const dispose = async (shell) => {
  return {
    type: ActionTypes.Carrier.Pending.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();