import React from "react";

import AppButton from "../../../core/components/AppButton";
import {Grid, Typography} from "@material-ui/core";
import AppMuiInput from "../../../core/components/inputs/AppInput/AppMuiInput";
import CarrierOptionsCard from './CarrierOptionsCard';
import CarrierNumbersCard from './CarrierNumbersCard';
import CarrierAddressCard from './CarrierAddressCard';
import CarrierContactCard from './CarrierContactCard';
import CarrierInsuranceCard from './CarrierInsuranceCard';
import {Save, Undo} from "@material-ui/icons";
import AppValidationFailure from "../../../core/components/AppValidationFailure";

const DetailsForm = ({values, originalValues, errors, isValid, isDirty, getFieldValue, setFieldValue, resetForm}) => {

  // Don't allow editing on this page when they don't have the appropriate permission.
  const isDisabled = !values.hasCarrierPrivilege;

  const carrierNumbersCardProps = {
    errors,
    dotNumber: values.dotNumber,
    freightCategoryTypes: values.freightCategoryTypes,
    mcNumber: values.mcNumber,
    packetReceivedDate: values.packetReceivedDate,
    selectedFreightCategory: values.selectedFreightCategory,
    setFieldValue,
    vendorTaxId: values.vendorTaxId,
    isDisabled,
    isNew: values.isNew
  }

  const carrierAddressCardProps = {
    errors,
    address1: values.address1,
    city: values.city,
    postalCode: values.postalCode,
    selectedStateProvince: values.selectedStateProvince,
    setFieldValue,
    stateProvinces: values.stateProvinces,
    isDisabled
  };

  const carrierContactCardProps = {
    errors,
    alternatePhone: values.alternatePhone,
    businessPhone: values.businessPhone,
    emailAddress: values.emailAddress,
    faxPhone: values.faxPhone,
    repFirstName: values.repFirstName,
    repLastName: values.repLastName,
    setFieldValue,
    isDisabled
  };

  const carrierOptionsCardProps = {
    errors,
    blackListed: values.blackListed,
    isCertified: values.isCertified,
    tgfOverride: values.tgfOverride,
    setFieldValue,
    isDisabled
  }

  const carrierInsuranceCardProps = {
    errors,
    cargoLiabilityAmount: values.cargoLiabilityAmount,
    generalLiabilityAmount: values.generalLiabilityAmount,
    generalLiabilityExpirationDate: values.generalLiabilityExpirationDate,
    insuranceExpirationDate: values.insuranceExpirationDate,
    setFieldValue,
    isDisabled
  };

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value)
  };

  const handleResetClick = (e) =>
    resetForm();

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item>
          <AppButton type={'submit'} startIcon={<Save/>} disabled={!(isValid && isDirty)}>Save</AppButton>
        </Grid>
        <Grid item>
          <AppButton startIcon={<Undo/>} color={'warning'} disabled={!isDirty}
                     onClick={handleResetClick}>Reset</AppButton>
        </Grid>
        <Grid item>
          <Typography variant={'h5'}>
            This page is used by TGF management. Displayed information could be old or inaccurate.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={7}>
            <AppMuiInput
              id={'carrierName'}
              label={'Name'}
              disabled={isDisabled}
              value={values.name || ''}
              inputProps={{
                maxLength: 300
              }}
              onChange={handleFieldChange('name')}
            />
            <AppValidationFailure
              message={errors.name}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} spacing={2}>
          <Grid item xs={12}>
            <CarrierNumbersCard
              {...carrierNumbersCardProps}
            />
          </Grid>
          <Grid item xs={12}>
            <CarrierAddressCard
              {...carrierAddressCardProps}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} spacing={2}>
          <Grid item xs={12}>
            <CarrierContactCard
              {...carrierContactCardProps}
            />
          </Grid>
          <Grid item xs={12}>
            <CarrierInsuranceCard
              {...carrierInsuranceCardProps}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12} style={{marginLeft: 20}}>
              <CarrierOptionsCard
                {...carrierOptionsCardProps}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsForm;