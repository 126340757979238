import ActionTypes from '../../ActionTypes';

const companyCategoryTypes = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.Support.CompanyCategoryTypesLoaded:
      return action.companyCategoryTypes;
    default:
      return state;
  }
};

export default companyCategoryTypes;