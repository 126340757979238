import ActionTypes from '../../ActionTypes';

/**
 * Used to flag that a process has been completed.
 * @param {string} processName - The name of the process.
 * @return {object}
 */
const processComplete = (processName) => {
  return {
    type: ActionTypes.Sys.ProcessComplete,
    processName
  };
};

export default processComplete;