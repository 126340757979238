import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadCompanyAffiliate = async (shell, companyId) => {

  const company = await shell.gateway.getCompany(companyId);

  let affiliate = null;
  if (company && company.affiliateId) {
    const { companies: affiliates} = await shell.gateway.searchCompanies({
      filter: {
        companyId: company.affiliateId
      },
      limit: 1
    });
    if (affiliates.length === 1) {
      affiliate = affiliates[0];
    }
  }


  return {
    type: ActionTypes.AddressBook.Modification.CompanyAffiliateLoaded,
    affiliateCompany: affiliate
  };

};

export default ActionBuilder
  .for(loadCompanyAffiliate)
  .build();