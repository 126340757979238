import { Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";

const AgentTooltipComponent = (props) => {

    const {
        width = "fit-content",
        ...otherProps
    } = props;

    const [agent, setAgent] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const loadPageDependencies = async () => {
        try {
            if (isHovered) setAgent(await window.shell.gateway.getAssociate(props.agentId));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (props.agentId === 1) {
            setAgent({
                id: 1,
                firstName: "System",
                lastName: "Account",
                systemId: "SYS"
            });
        } else if (props.agentId && props.agentId !== 1) {
            loadPageDependencies();
        }
    }, [props.agentId, isHovered]);

    return (
        <div style={{ width: width }}>
            <Tooltip title={
                <>
                    <div>
                        {agent?.firstName} {agent?.lastName} {agent?.systemId ? '( ' + agent?.systemId + ' )' : ''}
                    </div>
                    <div>
                        {agent?.businessPhoneExt ? 'Ext ' + agent?.businessPhoneExt : ''}
                    </div>
                    <div>
                        {agent?.emailAddress}
                    </div>
                    {agent?.isActive === false &&
                        <div>
                            ***No Longer at TGF***
                        </div>
                    }
                </>
            }>
                {props.children ? <div
                    onMouseEnter={() => setIsHovered(true)}
                >{props.children}</div>
                    :
                    <div onMouseEnter={() => setIsHovered(true)}
                    >{props.content}</div>}
            </Tooltip>
        </div>
    );
};

export default AgentTooltipComponent;
