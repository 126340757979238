import { Button, Grid, Tooltip } from "@material-ui/core";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import ConditionalWrapper from "../../../../core/kendo/ConditionalWrapper";
import DisplayError from "../../../../core/kendo/display/DisplayError";
import TgfComboBox from "../../../../core/kendo/form/inputs/TgfComboBox";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";
import TgfTextBox from "../../../../core/kendo/form/inputs/TgfTextBox";
import "../../../../core/kendo/form/tgf-form.scss";
import DateOnlyPicker from "../../../../core/kendo/inputs/DateOnlyPicker";
import useFieldValidator from "../../../../crm/components/customHooks/form/validation/useFieldValidator";
import FieldError from "../../../../crm/components/inputs/FieldError";
import isAceSelector from "../../../../hubs/persona/selectors/isAce";
import EditDialog from "../dialog/EditDialog";
import PostAge from "../listingCells/PostAge";
import AutoDeletePostWarning from "./AutoDeletePostWarning";
import "./editDialog.scss";
import { datValidationSchema } from "./formValidationSchemas/datValidationSchema";

const AddEditDatPosting = (props) => {
    const personalDispatch = useDispatch();

    const isAce = useSelector(state => isAceSelector(state));
    const hasDatCredentials = useSelector((state) => {
        return state.persona.associate.hasDatCredentials;
    });

    const [formData, setFormData] = React.useState(null);
    const [isRenderable, setIsRenderable] = React.useState(false);
    const [formValues, setFormValues] = useState({});
    const [error, setError] = useState(null);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(!props.datLoadPostingId);
    const [enablePost, setEnablePost] = useState(false);
    const [postDeletedOrExpired, setPostDeletedOrExpired] = useState(false);
    const [duplicationModalOpen, setDuplicationModalOpen] = useState(false);
    const [duplicationFormValues, setDuplicationFormValues] = useState(null);

    const fieldErrors = useFieldValidator(datValidationSchema, formValues);

    const autoFocusRef = React.useRef(null);

    useEffect(() => {
        if (isRenderable) {
            autoFocusRef.current.element.focus();
        }
    }, [isRenderable, isEditMode]);

    // initial load
    useEffect(() => {
        loadPageDependencies();
    }, []);

    useEffect(() => {
        datValidationSchema.validate(formValues)
            .then(() => {
                setEnablePost(true);
            })
            .catch((e) => {
                //console.log(e);
                setEnablePost(false);
            });
    }, [formValues]);

    const loadPageDependencies = async (postingId = null, shipmentId = null) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));

            postingId = postingId ? postingId : (props.datLoadPostingId ?? 0);
            shipmentId = shipmentId ? shipmentId : props.openLoad?.shipmentId;

            let formData;
            try {
                formData = await window.shell.gateway.getDatPostingFormData(postingId, shipmentId);
            } catch (e) {
                if (e.response?.status === 404) {
                    setIsRenderable(false);
                    setPostDeletedOrExpired(true);
                    console.error(e.message);
                }
                throw e;
            }

            if (formData?.entity.datDeleted) {
                setIsRenderable(false);
                setPostDeletedOrExpired(true);
                return;
            }

            setFormData(formData);
            setFormValues({
                ...formValues,
                ...formData.entity,
                ...props.datDuplicationFormValues
            });
            setIsRenderable(true);
        } catch (e) {
            setIsRenderable(false);
            setPostDeletedOrExpired(true);
            console.error(e.message);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    };

    // refresh call
    const refreshDatPosting = (postingId) => {
        personalDispatch(window.shell.actions.sys.processStart('refreshDatPosting'));
        window.shell.gateway.refreshDatPosting(postingId).then(async () => {
            await loadPageDependencies(postingId);
        }).finally(() => {
            personalDispatch(window.shell.actions.sys.processComplete('refreshDatPosting'));
        });
    };

    const handleSubmit = async (event) => {
        setError(null);

        event.preventDefault();

        if (formValues) {
            try {
                personalDispatch(window.shell.actions.sys.processStart('saveDatPosting'));
                // strip out line breaks from commodity field
                if (formValues.commodity) formValues.commodity = formValues.commodity.replace(/[\r\n]/gm, '');
                let saveResponse;
                if (formValues.id) {
                    saveResponse = await window.shell.gateway.updateDatPosting(formValues);
                } else {
                    saveResponse = await window.shell.gateway.saveDatPosting(formValues);
                }

                await loadPageDependencies(saveResponse.id);
                setIsEditMode(!saveResponse.id);

                if (props.onDialogUpdate) {
                    props.onDialogUpdate();
                }
                if (props?.datDuplicationFormValues) {
                    props.closeDialog();
                    personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'DAT load duplicated' }));
                } else {
                    personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'DAT load saved' }));
                }
            } catch (e) {
                setError(e);
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                    content: 'DAT save failed',
                    color: 'error'
                }));
            } finally {
                personalDispatch(window.shell.actions.sys.processComplete('saveDatPosting'));
            }
        }
    };

    const onClickDeleteDATPosting = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('deleteDatPosting'));
            await window.shell.gateway.deleteDatPosting(formData.entityId);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'DAT load deleted' }));
            props.closeDialog();
        } catch (e) {
            setError(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'DAT load delete failed',
                color: 'error'
            }));
        } finally {
            closeDeleteConfirmationDialog();
            personalDispatch(window.shell.actions.sys.processComplete('deleteDatPosting'));
        }
    };

    const enterEditMode = () => {
        setIsEditMode(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setIsDeleteConfirmationVisible(false);
    };

    const OpenDeleteConfirmationDialog = () => {
        setIsDeleteConfirmationVisible(true);
    };

    const handleEditModeCancel = () => {
        setFormValues(formData.entity);
        setError(null);
        setIsEditMode(false);
    };

    const applyFormValue = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleNumericTextChange = (e) => {
        e.target.value != null ?
            applyFormValue(e.target.name ?? e.target.element.name, Number(e.target.value))
            : applyFormValue(e.target.name ?? e.target.element.name, null);
    };

    const handleCurrencyChange = (e) => {
        e.target.value !== null && e.target.value > 0 ?
            applyFormValue(e.target.name ?? e.target.element.name, Number(e.target.value))
            : applyFormValue(e.target.name ?? e.target.element.name, null);
    };

    const handleTextChange = (e) => {
        applyFormValue(e.target.name ?? e.target.element.name, e.target.value);
    };

    const handleDateOnlyPickerChange = (e) => {
        if (e.target.value !== null) {
            const v = new Date(e.target.value.setUTCHours(12, 0, 0, 0));
            applyFormValue(e.target.name, v);
        } else {
            applyFormValue(e.target.name, null);
        }
    };

    const handleStringIdChange = (e) => {
        applyFormValue(e.target.name, e.target.value?.id);
    };

    const handleIdChange = (e) => {
        applyFormValue(e.target.name, Number(e.target.value.id));
    };

    const handleCloseDuplicationDialog = () => {
        setDuplicationModalOpen(false);
        setDuplicationFormValues(null);
    };

    const handleDuplicateClick = () => {
        const formValuesClone = _.cloneDeep(formValues);
        const formValuesForDuplication = {
            earlyPickupDate: formValuesClone.earlyPickupDate,
            latePickupDate: formValuesClone.latePickupDate,
            pickupHours: formValuesClone.pickupHours,
            dropOffHours: formValuesClone.dropOffHours,
            originCity: formValuesClone.originCity,
            originState: formValuesClone.originState,
            destinationCity: formValuesClone.destinationCity,
            destinationState: formValuesClone.destinationState,
            weightLbs: formValuesClone.weightLbs,
            lengthFt: formValuesClone.lengthFt,
            equipmentType: formValuesClone.equipmentType,
            fullPartial: formValuesClone.fullPartial,
            preferredContactMethod: formValuesClone.preferredContactMethod,
            postedAmountUsd: formValuesClone.postedAmountUsd,
            comments: formValuesClone.comments,
            commodity: formValuesClone.commodity
        };
        setDuplicationFormValues(formValuesForDuplication);
        setDuplicationModalOpen(true);
    };

    return <React.Fragment>
        {isRenderable ?
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <div disabled={!isEditMode} style={{ borderStyle: "none", overflow: "hidden", padding: "10px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <DateOnlyPicker
                                    id={"earlyPickupDate"}
                                    name={"earlyPickupDate"}
                                    label={"Early Pickup Date"}
                                    value={formValues.earlyPickupDate}
                                    disabled={!isEditMode}
                                    min={new Date()}
                                    onChange={handleDateOnlyPickerChange}
                                    tabIndex={1}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.earlyPickupDate}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <DateOnlyPicker
                                    id={"latePickupDate"}
                                    name={"latePickupDate"}
                                    label={"Late Pickup Date"}
                                    value={formValues.latePickupDate}
                                    disabled={!isEditMode}
                                    min={formValues.earlyPickupDate}
                                    onChange={handleDateOnlyPickerChange}
                                    tabIndex={2}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.latePickupDate}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"pickupHours"}>P/U Hrs (20 max characters)</Label>
                                <TgfTextBox
                                    id={"pickupHours"}
                                    name={"pickupHours"}
                                    value={formValues.pickupHours}
                                    maxLength={20}
                                    onChange={handleTextChange}
                                    disabled={!isEditMode}
                                    tabIndex={3}
                                    ref={autoFocusRef} //set focus to the modal
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"dropOffHours"}>Del. Hrs (20 max characters)</Label>
                                <TgfTextBox
                                    id={"dropOffHours"}
                                    name={"dropOffHours"}
                                    value={formValues.dropOffHours}
                                    maxLength={20}
                                    onChange={handleTextChange}
                                    disabled={!isEditMode}
                                    tabIndex={4}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"originCity"}>From City</Label>
                                <TgfTextBox
                                    id={"originCity"}
                                    name={"originCity"}
                                    value={formValues.originCity}
                                    required={true}
                                    disabled={formData.entityId}
                                    onChange={handleTextChange}
                                    tabIndex={5}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.originCity}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <TgfComboBox
                                    label={"From State"}
                                    id={"originState"}
                                    name={"originState"}
                                    data={formData.additionalData.stateProvinces}
                                    value={formValues.originState}
                                    onChange={handleStringIdChange}
                                    disabled={Boolean(!isEditMode || formData.entityId)}
                                    tabIndex={6}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.originState}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"destinationCity"}>To City</Label>
                                <TgfTextBox
                                    id={"destinationCity"}
                                    name={"destinationCity"}
                                    value={formValues.destinationCity}
                                    required={true}
                                    disabled={formData.entityId}
                                    onChange={handleTextChange}
                                    tabIndex={7}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.destinationCity}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <TgfComboBox
                                    label={"To State"}
                                    id={"destinationState"}
                                    name={"destinationState"}
                                    data={formData.additionalData.stateProvinces}
                                    value={formValues.destinationState}
                                    onChange={handleStringIdChange}
                                    disabled={Boolean(!isEditMode || formData.entityId)}
                                    tabIndex={8}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.destinationState}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"weightLbs"}>Weight (lbs)</Label>
                                <NumericTextBox
                                    id={"weightLbs"}
                                    name={"weightLbs"}
                                    onChange={handleNumericTextChange}
                                    value={formValues.weightLbs}
                                    required
                                    disabled={!isEditMode}
                                    tabIndex={9}
                                    spinners={false}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.weightLbs}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"lengthFt"}>Length (ft)</Label>
                                <NumericTextBox
                                    id={"lengthFt"}
                                    name={"lengthFt"}
                                    onChange={handleNumericTextChange}
                                    value={formValues.lengthFt}
                                    maxLength={2}
                                    disabled={!isEditMode}
                                    required
                                    tabIndex={10}
                                    spinners={false}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.lengthFt}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <TgfComboBox
                                    label={"Equipment Type"}
                                    id={"equipmentType"}
                                    name={"equipmentType"}
                                    data={formData.additionalData.equipmentType}
                                    value={formValues.equipmentType}
                                    onChange={handleStringIdChange}
                                    disabled={Boolean(!isEditMode || formData.entityId)}
                                    tabIndex={11}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.equipmentType}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"fullPartial"}>Full/Partial</Label>
                                <DropDownList
                                    id={"fullPartial"}
                                    name={"fullPartial"}
                                    data={formData.additionalData.loadType}
                                    textField="display"
                                    dataItemKey="id"
                                    value={formData.additionalData.loadType.find(x => x.id === formValues.fullPartial)}
                                    onChange={handleStringIdChange}
                                    style={{
                                        width: "100%",
                                    }}
                                    disabled={!isEditMode}
                                    tabIndex={12}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"preferredContactMethod"}>Preferred Contact Method</Label>
                                <DropDownList
                                    id={"preferredContactMethod"}
                                    name={"preferredContactMethod"}
                                    data={formData.additionalData.preferredContactMethod}
                                    textField="display"
                                    dataItemKey="id"
                                    value={formData.additionalData.preferredContactMethod.find(x => x.id === formValues.preferredContactMethod)}
                                    onChange={handleStringIdChange}
                                    style={{
                                        width: "100%",
                                    }}
                                    disabled={!isEditMode}
                                    tabIndex={13}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.preferredContactMethod}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"postedBy"}>Original Poster</Label>
                                <DropDownList
                                    id={"postedBy"}
                                    name={"postedBy"}
                                    data={formData.additionalData.availablePosters}
                                    textField="display"
                                    dataItemKey="id"
                                    value={formData.additionalData.availablePosters.find(x => x.id === formValues.postedBy)}
                                    onChange={handleIdChange}
                                    style={{
                                        width: "100%",
                                    }}
                                    //disabled={(isAce && !props.openLoad) ? !isEditMode : true}
                                    disabled={true}
                                    tabIndex={14}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Label style={{ color: "green" }} editorId={"estMiles"}>Estimated Miles</Label>
                                <InfoIconTooltip title={"After a posting is completed, the miles that are displayed are the approximate driving miles according to Google Maps and are based off the involved From and To cities and states."} />
                                <NumericTextBox
                                    id={"estMiles"}
                                    name={"estMiles"}
                                    onChange={handleNumericTextChange}
                                    value={formValues.estMiles}
                                    disabled={true}
                                    spinners={false}
                                    tabIndex={15}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.estMiles}</FieldError>
                            </Grid>
                            <Grid item xs={3}>
                                <Label editorId={"postedAmountUsd"}>Posted Amount ($USD)</Label>
                                <NumericTextBox
                                    id={"postedAmountUsd"}
                                    name={"postedAmountUsd"}
                                    onChange={handleCurrencyChange}
                                    value={formValues.postedAmountUsd}
                                    disabled={!isEditMode}
                                    format={"c2"}
                                    tabIndex={16}
                                    spinners={false}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.postedAmountUsd}</FieldError>
                            </Grid>
                            <Grid item xs={6}>
                                <Label editorId={"comments"}>Comments (70 max characters)</Label>
                                <TgfTextArea
                                    id={"comments"}
                                    name={"comments"}
                                    onChange={handleTextChange}
                                    value={formValues.comments}
                                    required
                                    maxLength={70}
                                    minrows={4}
                                    disabled={!isEditMode}
                                    tabIndex={17}
                                />
                                <FieldError shouldRender={isEditMode}>{fieldErrors.comments}</FieldError>
                            </Grid>
                            <Grid item xs={6}>
                                <Label editorId={"commodity"}>Commodity (70 max characters)</Label>
                                <TgfTextArea
                                    id={"commodity"}
                                    name={"commodity"}
                                    value={formValues.commodity}
                                    disabled={!isEditMode}
                                    onChange={handleTextChange}
                                    maxLength={70}
                                    minrows={4}
                                    tabIndex={18}
                                />
                            </Grid>
                            {isEditMode &&
                                <Grid container spacing={3} style={{ paddingTop: 30, paddingLeft: 9 }}>
                                    <Grid item>
                                        <ConditionalWrapper
                                            condition={!hasDatCredentials}
                                            wrapper={children =>
                                                <Tooltip title={<div className={"tgf-kendo-grid-tooltip"}>
                                                    <p>{'You currently do not have valid DAT credentials.'}</p>
                                                </div>
                                                }>
                                                    <span>{children}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <Button variant="contained" color="primary" type="submit" disabled={!hasDatCredentials || !enablePost}>
                                                {formData.entityId ? "Update" : "Post Load"}
                                            </Button>
                                        </ConditionalWrapper>
                                    </Grid>
                                    {formData.entityId &&
                                        <>
                                            <Grid item>
                                                <Button variant="contained" color="primary" type="submit"
                                                    onClick={() => handleEditModeCancel()}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    className={"delete-button"}
                                                    type="button"
                                                    onClick={OpenDeleteConfirmationDialog}>
                                                    Delete DAT Posting
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={6}>
                                        <DisplayError error={error} />
                                    </Grid>
                                    {formData.entityId &&
                                        <Grid item>
                                            <PostAge
                                                dateRefreshable={new Date(formData.entity.datRefreshable)}
                                                postId={formValues.id}
                                                refreshPosting={refreshDatPosting}
                                                disabled={!hasDatCredentials}
                                                disabledReason={'You currently do not have valid DAT credentials.'}
                                                showText={true}
                                                tooltip={'When this countdown reaches 00:00, the posting can be refreshed by clicking this icon or by updating the posting in some manner.  DAT allows postings to be refreshed only once every 15 minutes.'}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            }
                            {!isEditMode &&
                                <Grid container spacing={3} style={{ paddingTop: 30, paddingLeft: 9, paddingRight: 9 }}>
                                    <Grid item>
                                        <ConditionalWrapper
                                            condition={!hasDatCredentials}
                                            wrapper={children =>
                                                <Tooltip title={<div className={"tgf-kendo-grid-tooltip"}>
                                                    <p>{'You currently do not have valid DAT credentials.'}</p>
                                                </div>
                                                }>
                                                    <span>{children}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <Button variant="contained" color="primary" disabled={!hasDatCredentials} onClick={() => enterEditMode()}>
                                                Edit
                                            </Button>
                                        </ConditionalWrapper>
                                    </Grid>
                                    {formData.entityId &&
                                        <Grid item>
                                            <PostAge
                                                dateRefreshable={new Date(formData.entity.datRefreshable)}
                                                postId={formValues.id}
                                                refreshPosting={refreshDatPosting}
                                                disabled={!hasDatCredentials}
                                                disabledReason={'You currently do not have valid DAT credentials.'}
                                                showText={true}
                                                tooltip={'When this countdown reaches 00:00, the posting can be refreshed by clicking this icon or by updating the posting in some manner.  DAT allows postings to be refreshed only once every 15 minutes.'}
                                            />
                                        </Grid>
                                    }
                                    {!isEditMode &&
                                        <>
                                            <Grid item className={"add-edit-posting_duplicate-btn"}>
                                                <Button disabled={!hasDatCredentials} variant="contained" color="primary" style={{ padding: "5px 20px" }} onClick={handleDuplicateClick}>
                                                    Duplicate
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            }

                            {
                                isDeleteConfirmationVisible &&
                                <Dialog title={"Please Confirm"}
                                    onClose={closeDeleteConfirmationDialog}
                                    height={100}>
                                    <span>
                                        Are you sure you want to delete this posting?
                                    </span>
                                    <DialogActionsBar>
                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                            onClick={closeDeleteConfirmationDialog}
                                        >
                                            No
                                        </button>
                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                            onClick={onClickDeleteDATPosting}
                                        >
                                            Yes
                                        </button>
                                    </DialogActionsBar>
                                </Dialog>
                            }
                            {formData.entityId &&
                                <Grid item >
                                    <AutoDeletePostWarning
                                        dateString={formData.entity.datExpiration}
                                        tooltip={
                                            <>
                                                <div>DAT automatically deletes postings at 11:59 pm Pacific Time on the date showing as the Late Pickup Date.  If a Late Pickup Date isn’t completed, the date entered as the Early Pickup Date will be considered the Late Pickup Date.</div>
                                                <br /><br />
                                                <div>Also, DAT only allows a posting to have a lifespan of exactly eight days from the moment it was originally posted.  Date changes, updates, and refreshes do not change this eight-day lifespan rule.  The displayed auto-delete date and time will always take this rule into consideration.  Delete the posting and enter a new posting as necessary when needed.</div>
                                            </>
                                        }
                                    >
                                    </AutoDeletePostWarning>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </React.Fragment>
            : postDeletedOrExpired ? <p style={{ color: "red", fontWeight: "bold" }}> The record you are trying to view is no longer available on the Open Loads page.  Please close out of this dialog to refresh the page.</p> : null
        }
        {duplicationModalOpen &&
            <EditDialog
                setEditDialogOpen={setDuplicationModalOpen}
                openedFrom={'DAT'}
                closeDialog={handleCloseDuplicationDialog}
                datDuplicationFormValues={duplicationFormValues}
            />
        }
    </React.Fragment>;
};

export default AddEditDatPosting;
