import palette from './palette';

export default {
    search: {
        border: 1, 
        borderStyle: 'solid',
        borderColor: palette.tgfGrey.fieldBorder,
        backgroundColor: palette.white, 
        borderRadius: 4, 
        padding: '5px 5px 2px 10px'
    },
    inputField: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: palette.white,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.tgfGrey.fieldBorder,
        width: '100%',
        padding: '9px 12px',
        boxShadow: 'none',
        '&:focus': {
          boxShadow: 'none',
          outline: 'none',
        },
    },
    numberField: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: palette.white,
        borderWidth: 1,
        borderStyle: 'solid',
        boxShadow: 'none',
        borderColor: palette.tgfGrey.fieldBorder,
        width: '100%',
        padding: '9px 12px',
        '&:focus': {
          boxShadow: 'none',
          outline: 'none',
        },
    }
}