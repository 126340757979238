import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const loadShipmentStatusCount = async (shell, startDate, endDate, customerId) => {

  const countStatusSetOptions = {
    startDate, endDate, customerId
  };

  const statusSetCounts = await shell.gateway.getDashboardShipmentStatusSetCounts(countStatusSetOptions);

  return {
    type: ActionTypes.Dashboard.StatusCountLoaded,
    statusSetCounts
  };

};

export default ActionBuilder
  .for(loadShipmentStatusCount)
  .build();