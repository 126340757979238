import React from 'react';
import AppCard from "../../../../core/components/cards/AppCard/AppMuiCard";
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import AppText from "../../../../core/components/text/AppText";
import AppPercentText from "../../../../core/components/text/AppPercentText";

const MarginCard = (props) => {
    const {
        text,
        value,
        type
    } = props;
    if (type === 'margin') {
        return (
            value ? <AppCard style={{backgroundColor: 'white', padding: 0}}>
                    <div style={{
                        textAlign: "center",
                        verticalAlign: "bottom",
                        padding: '0px',
                        fontWeight: '900',
                        fontSize: '18px'
                    }}>
                        <AppText value={text} style={value < 0 ? {
                            color: "red"
                        } : {color: "green"}}></AppText>
                    </div>
                    <div style={{
                        textAlign: "center",
                        verticalAlign: "top",
                        padding: '6px',
                        fontWeight: '900',
                        fontSize: '18px'
                    }}><AppCurrencyText value={value}></AppCurrencyText>
                    </div>
                </AppCard>
                :
                <React.Fragment></React.Fragment>
        )
    } else if (type === 'percent') {
        return (
            value ? <AppCard style={{backgroundColor: 'white', padding: 0}}>
                    <div style={{
                        textAlign: "center",
                        verticalAlign: "bottom",
                        padding: '0px',
                        fontWeight: '900',
                        fontSize: '18px'
                    }}>
                        <AppText value={text} style={value < 0 ? {
                            color: "red"
                        } : {color: "green"}}></AppText>
                    </div>
                    <div style={{
                        textAlign: "center",
                        verticalAlign: "top",
                        padding: '6px',
                        fontWeight: '900',
                        fontSize: '18px'
                    }}>
                        <AppPercentText value={value}></AppPercentText>
                    </div>
                </AppCard>
                :
                <React.Fragment></React.Fragment>
        )
    }
}
 export default MarginCard;
