import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadAutoProcessed = async (shell, options) => {

  const { autoProcessed, count } = await shell.gateway.getAutoProcessed(options);

  return {
    type: ActionTypes.Automation.RetrieveAutoProcessed.Loaded,
    autoProcessed,
    count
  };
};

export default ActionBuilder
  .for(loadAutoProcessed)
  .build();
