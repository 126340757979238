import React from "react";
import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {useSelector} from "react-redux";
import DateOnlyPicker from "../../../../../core/kendo/inputs/DateOnlyPicker";
import isAce from "../../../../../hubs/persona/selectors/isAce";
import "./css/InvoiceAgingCard.css";

const InvoiceAgingCard = (props) => {
    const state = useSelector(state => state);
    const userIsAce = isAce(state);


    const formatDate = (date) => {
        // note this is displaying in UTC.
        const dateOptions = { timeZone: 'UTC', month: 'numeric', day: 'numeric', year: 'numeric' };
        const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
        return date ? dateFormatter.format(new Date(date)) : null;
    }

    const handleInvoiceSentDateChange = async (e) => {
        props.setData({
            ...props.data,
            invoiceAging: {
                ...props.data.invoiceAging,
                sentDate: e.target.value
            },
            invoice: {
                ...props.data.invoice,
                invoiceDateTime: e.target.value
            }
        })
        props.setIsDirty(true);
    }

    return (
        <AppMuiCard
            title="Invoice Aging Information"
            height={"100px"}
        >
            <Grid container spacing={2} alignItems={"center"} className={"invoice-aging-card"}>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={"invoice-aging-card_row-header"}>
                        <Grid item xs={(userIsAce && !props.data?.invoiceAging?.paidOffDate) ? 4 : 3} className={"row-header-item"}>
                            <p>Sent Date</p>
                        </Grid>
                        <Grid item xs className={"row-header-item"}>
                            <p>Due Date</p>
                        </Grid>
                        <Grid item xs className={"row-header-item"}>
                            <p>Past Due</p>
                        </Grid>
                        <Grid item xs className={"row-header-item"}>
                            <p>Paid Date</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={"invoice-aging-card_row"} alignItems={"center"}>
                        <Grid item xs={(userIsAce && !props.data?.invoiceAging?.paidOffDate) ? 4 : 3} className={"row-item"}>
                            {userIsAce && !props?.data?.invoiceAging?.paidOffDate ?
                                <DateOnlyPicker
                                    id={"earlyPickupDate"}
                                    name={"earlyPickupDate"}
                                    value={props.data?.invoiceAging?.sentDate ? new Date(props.data?.invoiceAging?.sentDate) : null}
                                    onChange={handleInvoiceSentDateChange}
                                    disabled={props.disableEdit}
                                /> :
                                <p>{formatDate(props?.data?.invoiceAging?.sentDate) || "---"}</p>
                            }
                        </Grid>
                        <Grid item xs className={"row-item"}>
                            <p>{formatDate(props?.data?.invoiceAging?.dueDate) || "---"}</p>
                        </Grid>
                        <Grid item xs className={"row-item"}>
                            <p
                                style={{color: props?.data?.invoiceAging?.pastDue > 0 ? "red" : "gray"}}
                            >{props?.data?.invoiceAging?.sentDate ? props?.data?.invoiceAging?.pastDue || "0" : "---"}</p>
                        </Grid>
                        <Grid item xs className={"row-item"}>
                            <p>{formatDate(props?.data?.invoiceAging?.paidOffDate) || "---"}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}

export default InvoiceAgingCard;