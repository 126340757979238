import ActionTypes from "../../ActionTypes";

const associateCommissionRate = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.CommissionCenter.Disposed:
      return null;
    case ActionTypes.CommissionCenter.CommissionRateLoaded:
    case ActionTypes.CommissionCenter.CommissionRateSaved:
      return action.associateCommissionRate;
    default:
      return state;
  }
};

export default associateCommissionRate;