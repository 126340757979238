import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const saveInvoice = async (shell, invoice) => {

  const updatedInvoice = await shell.gateway.saveInvoice(invoice);

  return {
    type: ActionTypes.CommissionCenter.ShipmentInvoiceSaved,
    invoice: updatedInvoice
  };
};

export default ActionBuilder
  .for(saveInvoice)
  .build();