import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const saveCompanyContact = async (shell, contact) => {

  const updatedContact = await shell.gateway.saveCompanyContact(contact);

  const contacts = await shell.gateway.getCompanyContacts(updatedContact.companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyContactSaved,
    contact,
    contacts
  };

};

export default ActionBuilder
  .for(saveCompanyContact)
  .build();