import ActionTypes from '../../ActionTypes';

const deliveredCount = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Persona.LoadedDeliveredCount:
      return action.deliveredCount;
    default:
      return state;
  }
};

export default deliveredCount;