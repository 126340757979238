import { Button } from "@progress/kendo-react-buttons";
import { Calendar, DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import "./TgfMonthPicker.scss";

const CustomToggleButton = (props, onChange) => {
    // Note: This custom toggle button only gets used when allowClear={true} otherwise, defaults to native toggle button.
    return (
        <div className={"tgf-kendo-month-picker_custom-toggle-div"}>
            {/* Calendar button*/}
            <Button {...props}
                className="tgf-kendo-month-picker_custom-toggle-button"
            >
                {props.children}
            </Button>
            {/* Clear button*/}
            <Button
                icon={"close"}
                className="tgf-kendo-month-picker_custom-toggle-button"
                onClick={() => onChange({ value: null })}
            >
            </Button>
        </div>
    );
};

const CustomCalendar = (props) => {
    return (
        <Calendar
            {...props}
            bottomView="year"
            topView="year"
            value={props.value}
            onChange={props.onChange}
        />
    );
};

const DisabledDateInput = (props) => {
    return (
        <>
            <span className="k-textbox k-input k-input-md k-input-solid k-disabled">
                <DateInput
                    style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    fillMode={"flat"}
                    format={'MM/yyyy'}
                    disabled={true}
                    value={props.value}
                />
            </span>
        </>
    );
};

const TgfMonthPicker = (props) => {
    return (
        <>
            {props.label && <Label editorId={props.id}>{props.label}</Label>}
            <DatePicker
                {...props}
                className={"tgf-kendo-month-picker"}
                calendar={CustomCalendar}
                dateInput={DisabledDateInput}
                toggleButton={props.showClear === true ? (toggleProps) => CustomToggleButton(toggleProps, props.onChange) : null}
                label={null}
            />
        </>
    );
};

TgfMonthPicker.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input.
    id: PropTypes.string.isRequired,
    // Name of the input, this is sometimes used to reference in the event handler.
    name: PropTypes.string.isRequired,
    // show the clear button
    showClear: PropTypes.bool,
    // value of the input (JS Date Object)
    value: PropTypes.object,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,
};

export default TgfMonthPicker;

