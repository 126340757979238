import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const publishShipmentDocument = async (shell, bolNumber, shipmentDocument ) => {
  const document = await shell.gateway.publishShipmentDocument(bolNumber, shipmentDocument);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentPublishDocument,
    document
  }
};

export default ActionBuilder
  .for(publishShipmentDocument)
  .build();